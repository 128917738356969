import { NotAwardAuctionDto } from './../../../shared/models/user/NotAwardAuctionDto';
import { DashboardRedirectionService } from '../../../shared/services/dashboard-redirection.service';
import { AdminDashboardService } from './../../../shared/services/admin-dashboard.service';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AddAuctionHouseBannerComponent } from '../components/add-auction-house-banner/add-auction-house-banner.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AdminDataUIWrapperDto } from 'src/app/shared/models/user/AdminDataUIWrapperDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { UserService } from 'src/app/shared/services/user.service';
import { UserAuctionEligibleStatus } from 'src/app/shared/enums/UserAuctionEligibleStatus';
import { UserAuctionRegistrationsNotificationComponent } from 'src/app/shared/components/user-auction-registrations-notification/user-auction-registrations-notification.component';
import { AiDescriptionGeneratorComponent } from 'src/app/shared/components/ai-description-generator/ai-description-generator.component';
import { FirestoreListenerService } from 'src/app/shared/services/firestore-listener.service';

import { SourcingEventDto } from 'src/app/shared/models/user/SourcingEventDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.sass']
})
export class AdminDashboardComponent implements OnInit, AfterViewInit, OnDestroy {



  currentViewTabName: string = "AUCTION";

  adminUIWrapper?: AdminDataUIWrapperDto;
  adminDashboardDataSubscription$?: Subscription;
  currentViewSubscription$?: Subscription;
  subNavigationSubscription$?: Subscription;
  biddersListSubscription$?: Subscription;
  postAuctionsSubscription$?: Subscription;
  userRegistrationsSubscription$?: Subscription;

  renderView$ = new BehaviorSubject<boolean>(false);
  isShowAuctionHouseSetting$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');
  isNewMessagesAvailable$ = new BehaviorSubject<boolean>(false);

  countAdmins: number = 0
  countAgents: number = 0
  countBidders: number = 0
  countPostAuction: number = 0
  countPendingRegistration: number = 0;
  countAllAuctions: number = 0;

  notAwardLotsCount: number = 0;

  notAwardAuctionDtos: Array<NotAwardAuctionDto> = [];


  isLoading: boolean = false;
  isShowMessagingModal: boolean = false;

  pipActiveVideo?: any

  allAuctionsList: Array<SourcingEventDto> = [];

  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event: any) {
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler($event: any): void {
  }

  constructor(
    private dataRedirectionService: DashboardRedirectionService,
    public adminDashboardService: AdminDashboardService,
    private adminSourcingEventService: AdminSourcingEventsDataHolderService,
    private userService: UserService,
    private ngbModal: NgbModal,
    private _router: Router,
    private firestoreListenerService: FirestoreListenerService,
  ) {

  }

  ngOnInit(): void {
    this.renderView$.next(false)





    this.subNavigationSubscription$ = this.dataRedirectionService.getSubNavigation.subscribe((data) => {
      if (data) {

        // if (data == AuctionExtConstant.ADMIN_DASHBOARD_AUCTION_TAB) {
        //   this.currentViewTabName = "AUCTION";
        // }
        // else if (data == AuctionExtConstant.ADMIN_DASHBOARD_IN_HOUSE_ADMIN_TAB) {
        //   this.currentViewTabName = "IN_HOUSE_ADMIN";
        // }
        // else if (data == AuctionExtConstant.ADMIN_DASHBOARD_BIDDER_TAB) {
        //   this.currentViewTabName = "BIDDERS";
        // }
        // else if (data == AuctionExtConstant.ADMIN_DASHBOARD_POST_AUCTION_TAB) {
        //   this.currentViewTabName = "POST_AUCTION";
        // }

        // else if (data == AuctionExtConstant.ADMIN_DASHBOARD_BIDDERS_TAB) {
        //   this.currentViewTabName = "BIDDERS";
        // }
        // else if (data == AuctionExtConstant.ADMIN_DASHBOARD_AUCTION_HOUSE_SETTING) {
        //   this.currentViewTabName = "AUCTION_HOUSE_SETTING";
        // }
      }
    })

    this.userRegistrationsSubscription$ = this.adminDashboardService.getAllUserAuctionRegistrationsList$.subscribe((data) => {
      if (data) {
        let awaitingApprovals = data.filter(item => item.userAuctionRegistrationStatus == UserAuctionEligibleStatus.AWAITING_APPROVAL);
        this.countPendingRegistration = awaitingApprovals.length;
      }
    })
  }

  ngAfterViewInit(): void {
    let sessionInfoDto = this.userService.getSessionInfo;



    this.postAuctionsSubscription$ = this.adminSourcingEventService.getPostEventsList$.subscribe(data => {
      if (data) {
        this.countPostAuction = data.length ?? 0;
      }
    })

    Promise.resolve().then(()=>this.renderView$.next(true));
  }

  toggleCurrentViewTab(tabView: string) {
    this.currentViewTabName = tabView;


    // if (this.currentViewTabName == "AUCTION") {
    //   let selectedAuction = this.adminSourcingEventService.getSelectedSourcingEvent();
    //     this.dataRedirectionService.storeNavigationContext(
    //       AuctionExtConstant.ADMIN,
    //       AuctionExtConstant.AUCTION_CREATOR_PAGE,
    //       AuctionExtConstant.AUCTION_TAB,
    //       `${selectedAuction?.sequenceNo}`
    //     );
    // }
    // else if (this.currentViewTabName == "IN_HOUSE_ADMIN") {
    //   let selectedAuction = this.adminSourcingEventService.getSelectedSourcingEvent();
    //   this.dataRedirectionService.storeNavigationContext(
    //     AuctionExtConstant.ADMIN,
    //     AuctionExtConstant.AUCTION_CREATOR_PAGE,
    //     AuctionExtConstant.ADMIN_DASHBOARD_IN_HOUSE_ADMIN_TAB,
    //     `${selectedAuction?.sequenceNo}`
    //   );
    // }
    // else if (this.currentViewTabName == "POST_AUCTION") {
    //   let selectedAuction = this.adminSourcingEventService.getSelectedSourcingEvent();
    //   this.dataRedirectionService.storeNavigationContext(
    //     AuctionExtConstant.ADMIN,
    //     AuctionExtConstant.AUCTION_CREATOR_PAGE,
    //     AuctionExtConstant.POST_AUCTION_TAB,
    //     `${selectedAuction?.sequenceNo}`
    //   );
    // }
    // else if (this.currentViewTabName == "BIDDERS") {
    //   let selectedAuction = this.adminSourcingEventService.getSelectedSourcingEvent();
    //   this.dataRedirectionService.storeNavigationContext(
    //     AuctionExtConstant.ADMIN,
    //     AuctionExtConstant.AUCTION_CREATOR_PAGE,
    //     AuctionExtConstant.ADMIN_DASHBOARD_BIDDERS_TAB,
    //     `${selectedAuction?.sequenceNo}`
    //   );
    // }
    // else if (this.currentViewTabName == "AUCTION_HOUSE_SETTING") {
    //   let selectedAuction = this.adminSourcingEventService.getSelectedSourcingEvent();
    //   this.dataRedirectionService.storeNavigationContext(
    //     AuctionExtConstant.ADMIN,
    //     AuctionExtConstant.AUCTION_CREATOR_PAGE,
    //     AuctionExtConstant.ADMIN_DASHBOARD_AUCTION_HOUSE_SETTING,
    //     `${selectedAuction?.sequenceNo}`
    //   );
    // }

    // this.dataRedirectionService.handleDashboardAppNavigation();
  }

  openBidderDetailsModal(content: any) {
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openAdminUserInviteModal(content: any) {
    this.ngbModal.open(content, { centered: true, size: 'sm', backdrop: 'static', keyboard: false })
  }

  openAddAdminModal(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close()
    } else {
      this.ngbModal.dismissAll();
    }
  }

  navigateToAuctions() {
    this._router.navigateByUrl('/firstpwdreset')
  }

  uploadBannerImage() {
    this.ngbModal.open(AddAuctionHouseBannerComponent, { centered: true, size: 'md', backdrop: 'static', keyboard: false })
  }

  showOrHideAuctionHouseSetting(){
    this.isShowAuctionHouseSetting$.next(!this.isShowAuctionHouseSetting$.value);
  }

  openAuctionHouseSetting() {
    this.isShowAuctionHouseSetting$.next(true);
    window.scrollTo(0, 0);
  }

  openPendingRegistrationsModal() {
    this.ngbModal.open(UserAuctionRegistrationsNotificationComponent, {
       size: 'lg', backdrop: 'static', keyboard: false , centered: true
    })
  }

  openNotAwardAuctionModal(content: any) {
    this.ngbModal.open(content, {
       size: 'lg', backdrop: 'static', keyboard: false , centered: true
    })
  }

  navigateToPostAuction(auctionId: string) {
  this.toggleCurrentViewTab("POST_AUCTION");
  let auction = this.adminSourcingEventService.allEventsList$.value.find(item => item.eventId == auctionId) as AuctionEntityDto;
  this.adminDashboardService._notAwardedAuctionNotification$.next(auction)
  this.ngbModal.dismissAll();
  }



  ngOnDestroy(): void {
    if (this.currentViewSubscription$) {
      this.currentViewSubscription$.unsubscribe();
    }





    if (this.subNavigationSubscription$) {
      this.subNavigationSubscription$.unsubscribe();
    }

    if (this.biddersListSubscription$) {
      this.biddersListSubscription$.unsubscribe();
    }

    if (this.postAuctionsSubscription$) {
      this.postAuctionsSubscription$.unsubscribe();
    }
    if (this.userRegistrationsSubscription$) {
      this.userRegistrationsSubscription$.unsubscribe();
    }
  }

  openAiDescription() {
    this.ngbModal.open(AiDescriptionGeneratorComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    })
  }

  toggleShowMessagingModal() {
    this.isShowMessagingModal = !this.isShowMessagingModal;
  }

}
