<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Add Bidder</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">

      <form class="needs-validation" novalidate [formGroup]="formGroup">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="globalBidderFirstName" placeholder="First Name"
                formControlName="firstName"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.firstName.invalid}">
              <label for="globalBidderFirstName">First Name</label>
              <div
                *ngIf="fc.firstName.invalid && (_validationErrorPresent$ |async) ||fc.firstName.invalid && (fc.firstName.dirty || fc.firstName.touched)">
                <p class="text-danger" *ngIf="fc.firstName.errors?.required">Required
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="globalBidderLastName" placeholder="Last Name"
                formControlName="lastName"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.lastName.invalid}">
              <label for="globalBidderLastName">Last Name</label>
              <div
                *ngIf="fc.lastName.invalid &&  (_validationErrorPresent$ |async) || fc.lastName.invalid && (fc.lastName.dirty || fc.lastName.touched)">
                <p class="text-danger" *ngIf="fc.lastName.errors?.required">Required
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="globalBidderEmail" placeholder="Email" formControlName="emailId"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.emailId.invalid}">
              <label for="globalBidderEmail">Email (Username for Login)</label>
              <div
                *ngIf="fc.emailId.invalid && (_validationErrorPresent$ |async)|| fc.emailId.invalid && (fc.emailId.dirty || fc.emailId.touched)">
                <p class="text-danger" *ngIf="fc.emailId.errors?.required">Required</p>
                <p class="text-danger" *ngIf="fc.emailId.errors?.pattern">Invalid email address
                </p>
                <p class="text-danger" *ngIf="fc.emailId.value && fc.emailId.value.length > 50">
                  Maximum Length
                  Exceed</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-group">
              <span class="input-group-addon">
                <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                  [countryCode$]="countryCodeForMobileNo$"></app-country-code-select>
              </span>
              <div class="form-floating mb-3 ">
                <input type="text" class="form-control " id="globalBidderPhone" placeholder="Phone Number"
                  formControlName="mobileNo"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.mobileNo.invalid}">
                <label for="globalBidderPhone">Phone Number</label>

                <div
                  *ngIf="fc.mobileNo.invalid && (_validationErrorPresent$ |async) || fc.mobileNo.invalid && (fc.mobileNo.dirty || fc.mobileNo.touched)">
                  <p class="text-danger" *ngIf="fc.mobileNo.errors?.required">Required
                  </p>
                  <p class="text-danger" *ngIf="fc.mobileNo.errors?.pattern">Invalid</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="globalBidderCompany" placeholder="Company Name"
                formControlName="companyName"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.companyName.invalid}">
              <label for="globalBidderCompany">Company Name</label>
              <div
                *ngIf="fc.companyName.invalid && (_validationErrorPresent$ |async)|| fc.companyName.invalid && (fc.companyName.dirty || fc.companyName.touched)">
                <p class="text-danger" *ngIf="fc.companyName.errors?.required">Required</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="globalBidderCompanyUrl" placeholder="Company Url" formControlName="companyUrl">
              <label for="globalBidderCompanyUrl">Company Url (Optional)</label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="globalBidderDesignation" placeholder="Designation" formControlName="designation">
              <label for="globalBidderDesignation">Designation(Optional)</label>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="input-group">
              <span class="input-group-addon">
                <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                [countryCode$]="countryCodeForFaxNo$"></app-country-code-select>
              </span>
              <div class="form-floating mb-3 ">
                <input type="text" class="form-control " id="globalBidderFax" placeholder="Fax No" formControlName="faxNo"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.faxNo.invalid}">
                <label for="globalBidderFax">Fax No (Optional)</label>

                <div
                *ngIf="fc.faxNo.invalid && (_validationErrorPresent$ |async) || fc.faxNo.invalid && (fc.faxNo.dirty || fc.faxNo.touched)">
                <p class="text-danger" *ngIf="fc.faxNo.errors?.required">Required
                </p>
                <p class="text-danger" *ngIf="fc.faxNo.errors?.pattern">Invalid</p>
              </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-floating mb-3">
              <input ngx-google-places-autocomplete #placesRef="ngx-places" type="text" class="form-control"
                [ngClass]="{'is-invalid': fc.searchAddress.invalid && (fc.searchAddress.dirty || fc.searchAddress.touched)}"
                id="globalBidderSearch" placeholder="Search" formControlName="searchAddress"
                (onAddressChange)="handleAddressChange($event)">
              <label for="globalBidderSearch" >Search Address</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.searchAddress.invalid && (fc.searchAddress.dirty || fc.searchAddress.touched)">
                <p class="text-danger" *ngIf="fc.searchAddress.errors?.required">Required</p>
                <p class="text-danger" *ngIf="fc.searchAddress.errors?.address">Invalid</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="globalBidderAddressOne" placeholder="Address Line 1"
                formControlName="addressLine1"
                [ngClass]="{'is-invalid': fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)}">
              <label for="globalBidderAddressOne" > Address Line 1</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)">
                <p class="text-danger" *ngIf="fc.addressLine1.errors?.required">Required</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="globalBidderAddressTwo" placeholder="Address Line 2"
                formControlName="addressLine2">
              <label for="globalBidderAddressTwo" >Address Line 2</label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="globalBidderCity" placeholder="City" formControlName="city"
                [ngClass]="{'is-invalid': fc.city.invalid && (fc.city.dirty || fc.city.touched)}">
              <label for="globalBidderCity" >City</label>
              <div *ngIf="(_validationErrorPresent$ |async) || fc.city.invalid && (fc.city.dirty || fc.city.touched)">
                <p class="text-danger" *ngIf="fc.city.errors?.required">Required</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="globalBidderState" placeholder="State" formControlName="state"
                [ngClass]="{'is-invalid': fc.state.invalid && (fc.state.dirty || fc.state.touched)}">
              <label for="globalBidderState" >State</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.state.invalid && (fc.state.dirty || fc.state.touched)">
                <p class="text-danger" *ngIf="fc.state.errors?.required">Required</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="globalBidderCountry" placeholder="Country" formControlName="country"
                [ngClass]="{'is-invalid': fc.country.invalid && (fc.country.dirty || fc.country.touched)}">
              <label for="globalBidderCountry" >Country</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.country.invalid && (fc.country.dirty || fc.country.touched)">
                <p class="text-danger" *ngIf="fc.country.errors?.required">Required</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="globalBidderPin" placeholder="Country-zip"
                formControlName="zipCode"
                [ngClass]="{'is-invalid': fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)}">
              <label for="globalBidderPin" >PIN Code</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)">
                <p class="text-danger" *ngIf="fc.zipCode.errors?.required">Required</p>
                <p class="text-danger" *ngIf="fc.zipCode.errors?.pattern">Invalid</p>
              </div>
            </div>
          </div>

          <!-- Category Preference -->
          <div class="col-12 mt-3">
            <div class="border p-2 bg-white" [ngClass]="{'border-danger' : !selectedPreferenceCategory}">
              <div class="row mb-2">
                <div class="col-8">
                  <h6 class="heading-h5 mt-2">Category Preference</h6>
                </div>
                <div class="col-4 text-end">
                  <button class="btn-new-class btn btn-sm ms-2 mt-1" (click)="openCategoryModal()">
                    {{ selectedPreferenceCategory ? 'EDIT' : 'ADD' }}
                  </button>
                </div>
              </div>

              <div class="p-3 text-center" *ngIf="!selectedPreferenceCategory">
                <h6>Preference Category Not Selected!</h6>
              </div>

              <div class="scroll-category-data">
                <div *ngIf="selectedPreferenceCategory">
                  <label for="description">Selected Material</label>
                  <div class="table-responsive">
                    <table class="table table-bordered medium-font mb-0 align-middle">
                      <thead class="table-dark">
                        <tr class="text-center">
                          <th class=""><span>Label One</span></th>
                          <th class=""><span>Label Two</span></th>
                          <th class=""><span>Label Three</span></th>
                          <th class=""><span>Label Four</span></th>
                          <th class=""><span>Label Five</span></th>
                        
                        </tr>
                      </thead>
      
                      <tbody class="bg-white">
                        <tr>
                          <td>{{selectedPreferenceCategory.categoryName}}</td>
                          <td>{{selectedPreferenceCategory.categoryLevelOneName}}</td>
                          <td>{{selectedPreferenceCategory.categoryLevelTwoName}}</td>
                          <td>{{selectedPreferenceCategory.categoryLevelThreeName}}</td>
                          <td>{{selectedPreferenceCategory.categoryLevelFourName}}</td>
                         
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Email & Mobile -->
          <div></div>

          <!-- <div class="col-lg-6" >
            <div class="bg-secondary text-center mb-3">
              <google-map >
                <map-marker #markerElem="mapMarker" >
                </map-marker>
                <map-info-window></map-info-window>
              </google-map>
            </div>
          </div> -->
          <!-- <div class="col-lg-12">
            <div class="mb-2">
              <label for="floatingInput" class="text-area-label">Select Category
              </label>

              <div ngbDropdown class="dropdown " #ngbDropdownCategory="ngbDropdown" [autoClose]="true">

                <button class="btn select-box-btn-class text-start dropdown-toggle mb-2 rounded-0" type="button"
                  data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false" placeholder="Select category"
                  [ngClass]="{'is-invalid': fc.category.invalid && (fc.category.dirty || fc.category.touched) && fc.category.errors?.required}">
                  <div class="row">
                    <div class="col-9">
                      <div class="overflow-class-sort">
                        {{fc.category.value}}
                      </div>
                    </div>
                    <div class="col-3 text-end">
                      <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-Xsmall">
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu text-start shadow  w-100 p-2 " ngbDropdownMenu>
                  <!-- SYMBOL SEARCH --/>
                  <div class="row text-center margin px-3">
                    <div class="col-md-12 fix-inputGroup">
                      <div class="input-group input-group-sm mb-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text form-control-sm text-primary">
                            <fa-icon [icon]="['fas','magnifying-glass']"></fa-icon>
                          </span>
                        </div>
                        <input #input autocomplete="no" type="text" (keyup)="searchCategory(input.value)"
                          placeholder=" Search Category" autocomplete="nofill" class="form-control" />
                      </div>
                    </div>
                  </div>
                  <ul class="scroll-area-lg">
                    <li ngbDropdownItem (click)="populateSubCategoryList(item, ngbDropdownCategory)"
                      *ngFor="let item of (categories$ | async)" style="width: 100%; white-space: normal;">
                      {{item}}
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="fc.category.invalid && (fc.category.dirty || fc.category.touched)">
                <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fc.category.errors?.required">Required
                </p>
              </div>
              <div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-2">

              <label for="floatingInput" class="text-area-label">Sub Category 1
              </label>
              <div ngbDropdown class="dropdown " #ngbDropdownSubCategory="ngbDropdown" [autoClose]="true">
                <button class="btn select-box-btn-class text-start dropdown-toggle mb-2 rounded-0" type="button"
                  data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false" placeholder="Search Sub Category 1"
                  [ngClass]="{'is-invalid': fc.subCategory.invalid && (fc.subCategory.dirty || fc.subCategory.touched) && fc.subCategory.errors?.required}">
                  <div class="row">
                    <div class="col-9">
                      <div class="overflow-class-sort">
                        {{fc.subCategory.value}}
                      </div>
                    </div>
                    <div class="col-3 text-end">
                      <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-Xsmall">
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu text-start shadow  w-100 p-2 " ngbDropdownMenu>
                  <!-- SYMBOL SEARCH --/>
                  <div class="row text-center margin px-3">
                    <div class="col-md-12 fix-inputGroup">
                      <div class="input-group input-group-sm mb-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text form-control-sm text-primary">
                            <fa-icon [icon]="['fas','magnifying-glass']"></fa-icon>
                          </span>
                        </div>
                        <input #subcategory autocomplete="no" type="text" (keyup)="searchSubCategory(subcategory.value)"
                          placeholder=" Search Sub Category 1" autocomplete="nofill" class="form-control" />
                      </div>
                    </div>
                  </div>
                  <ul class="scroll-area-lg">
                    <li ngbDropdownItem *ngFor="let item of (subCategories$ | async)"
                      style="width: 100%; white-space: normal;"
                      (click)="populateSubCategoryLevelOneList(fc.category.value, item!, ngbDropdownSubCategory)">
                      {{item}}
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="fc.subCategory.invalid && (fc.subCategory.dirty || fc.subCategory.touched)">
                <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fc.subCategory.errors?.required">
                  Required</p>
              </div>
              <div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class=" mb-2">

              <label for="floatingInput" class="text-area-label">Sub Category 2
              </label>
              <div ngbDropdown class="dropdown " #ngbDropdownSubCategoryLevelOne="ngbDropdown" [autoClose]="true">
                <button class="btn select-box-btn-class text-start dropdown-toggle mb-2 rounded-0" type="button"
                  data-bs-toggle="dropdown" ngbDropdownToggle
                  [ngClass]="{'is-invalid': fc.subcategoryLevelOne.invalid && (fc.subcategoryLevelOne.dirty || fc.subcategoryLevelOne.touched) && fc.subcategoryLevelOne.errors?.required}"
                  aria-expanded="false" placeholder="Select category">
                  <div class="row">
                    <div class="col-9">
                      <div class="overflow-class-sort">
                        {{selectedSubCategoryLevelOneName}}
                      </div>
                    </div>
                    <div class="col-3 text-end">
                      <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-Xsmall">
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu text-start shadow  w-100 p-2 " ngbDropdownMenu>
                  <!-- SYMBOL SEARCH --/>
                  <div class="row text-center margin px-3">
                    <div class="col-md-12 fix-inputGroup">
                      <div class="input-group input-group-sm mb-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text form-control-sm text-primary">
                            <fa-icon [icon]="['fas','magnifying-glass']"></fa-icon>
                          </span>
                        </div>
                        <input #subcategoryLevelOne autocomplete="no" type="text"
                          (keyup)="searchSubCategoryLevelOne(subcategoryLevelOne.value)"
                          placeholder=" Search Sub Category 2" autocomplete="nofill" class="form-control" />
                      </div>
                    </div>
                  </div>
                  <ul class="scroll-area-lg">
                    <li ngbDropdownItem
                      (click)="setSubcategoryLevelOne(item.id!, item?.subcategoryLevelOne!, ngbDropdownSubCategoryLevelOne)"
                      *ngFor="let item of (this.subcategoryLevelOneList$ | async)"
                      style="width: 100%; white-space: normal;">
                      {{item?.subcategoryLevelOne}}
                    </li>
                  </ul>
                </div>
              </div>

              <div>
              </div>
            </div>
          </div> -->
        </div>

        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async"
            [message]="'Bidder added successfully.'"></app-message-toast>
          <app-message-toast *ngIf="(_showErrorToast$ | async)" [isError]="true"
            [message]="_showErrorMsg$ | async"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>



        <div class="col-12 text-end my-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-width-small btn-sm me-3" (click)="closeModal()">
            CANCEL
          </button>
          <button type="button" class="btn btn-new-class btn-width-small btn-sm" *ngIf="!selectedBidder"
            [disabled]="!(_isSaveButtonEnable$ | async)" (click)="handleValidSubmit()">
            ADD
          </button>
          <button type="button" class="btn btn-new-class btn-width-small btn-sm" *ngIf="selectedBidder"
            [disabled]="!(_isSaveButtonEnable$ | async)" (click)="handleValidSubmit()">
            UPDATE
          </button>

        </div>
      </form>
    </div>
  </div>
</div>
