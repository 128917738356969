import { Component, OnDestroy, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { BehaviorSubject, Subscription } from 'rxjs';
import { OrganizationUiDto } from 'src/app/shared/models/OrganizationUiDto';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';

@Component({
  selector: 'app-new-admin-dashboard',
  templateUrl: './new-admin-dashboard.component.html',
  styleUrls: ['./new-admin-dashboard.component.sass']
})
export class NewAdminDashboardComponent implements OnInit, OnDestroy {
  currentTab: string = 'TODAY';
  organizationUiDto?: OrganizationUiDto;
  organisationSubscription$?: Subscription;

  constructor(
    private onboardingService: OnboardingService,
  ){}

  ngOnInit(): void {
    this.organisationSubscription$ = this.onboardingService.getOrganizationUiDto$.subscribe(data => {
      if (data) {
        this.organizationUiDto = data;
      } else {
        this.organizationUiDto = undefined;
      }
    })
  }

  toggleDetailsTab(tab: string) {
    this.currentTab = tab;
  }

  ngOnDestroy(): void {
    if (this.organisationSubscription$) {
      this.organisationSubscription$.unsubscribe();
    }
    
  }

}
