<div class="bg-white-new">
  <div class="position-relative mx-2">
    <div class="col-12">
      <div class="row">
        <div class="col-md-1 bg-white border px-0 d-none d-md-inline-block fixed-sidebar-class ">
          <ul style="list-style: none;" class="ps-1 text-center">
            <li class="" [ngClass]="this.selectedSidebarView == 'DASHBOARD_PAGE' ? 'list-active' : 'my-1'"
              (click)="toggleSidebarView('DASHBOARD_PAGE')">
              <div>
                <img src="assets/icons/Home-Icon.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Dashboard
              </span>
            </li>
            <hr class="hr-light">
            <li class="" (click)="toggleSidebarView('PURCHASE_REQUEST_PAGE')"
              [ngClass]="this.selectedSidebarView == 'PURCHASE_REQUEST_PAGE' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/requisition-icon.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Requisition
              </span>
            </li>
            <hr class="hr-light">
            <li class="" [ngClass]="this.selectedSidebarView == 'PROJECTS_PAGE' ? 'list-active' : 'my-1'"
              (click)="toggleSidebarView('PROJECTS_PAGE')">
              <div>
                <img src="assets/icons/Projects.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Projects
              </span>
            </li>
            <hr class="hr-light">
            <li class="" [ngClass]="this.selectedSidebarView == 'SOURCING_PAGE' ? 'list-active' : 'my-1'"
              (click)="toggleSidebarView('SOURCING_PAGE')">
              <div>
                <img src="assets/icons/procurement-icon.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Procurement
              </span>
            </li>
            <hr class="hr-light">
            <li class="" (click)="toggleSidebarView('ADMIN_CATALOGUE_PAGE')"
              [ngClass]="this.selectedSidebarView == 'ADMIN_CATALOGUE_PAGE' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/Catalogue.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new  d-none d-md-inline-block">
                Catalogue
              </span>
            </li>
            <hr class="hr-light">
            <li class="" (click)="toggleSidebarView('ADMIN_PURCHASE_ORDER_PAGE')"
              [ngClass]="this.selectedSidebarView == 'ADMIN_PURCHASE_ORDER_PAGE' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/purchase-order.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new  d-none d-md-inline-block">
                Purchase Order
              </span>
            </li>
            <hr class="hr-light">
            <li class="" (click)="toggleSidebarView('ADMIN_INVOICE_PAGE')"
              [ngClass]="this.selectedSidebarView == 'ADMIN_INVOICE_PAGE' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/Invoice.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Invoice
              </span>
            </li>
            <hr class="hr-light">
            <!-- [ngClass]="{'list-active' : this.selectedSidebarView == 'BIDDER_NETWORK_PAGE'}" -->
            <li class="" (click)="toggleSidebarView('BIDDER_NETWORK_PAGE')"
              [ngClass]="this.selectedSidebarView == 'BIDDER_NETWORK_PAGE' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/Bidder-Network-Icon.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Vendor Network
              </span>
            </li>
            <hr class="hr-light">
            <li class="" (click)="toggleSidebarView('IN_HOUSE_USER_PAGE')"
              [ngClass]="this.selectedSidebarView == 'IN_HOUSE_USER_PAGE' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/InHouse-Users-Icon.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new  d-none d-md-inline-block">
                Users
              </span>
            </li>
            <hr class="hr-light">
            <li class="" (click)="toggleSidebarView('APPROVED_PAGE')"
              [ngClass]="this.selectedSidebarView == 'APPROVED_PAGE' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/check-double.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Approvals
              </span>
            </li>
            <!-- <hr class="hr-light">
            <li class="" [ngClass]="this.selectedSidebarView == 'ANALYTICS_PAGE' ? 'list-active' : 'my-1'"
              (click)="toggleSidebarView('ANALYTICS_PAGE')">
              <div>
                <img src="assets/icons/analytic-icon.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Analytics
              </span>
            </li> -->
            <hr class="hr-light">
            <!-- [ngClass]="{'list-active' : this.selectedSidebarView == 'SETTING_PAGE'}" -->
            <li class="" (click)="toggleSidebarView('ONBOARDING_PAGE')"
              [ngClass]="this.selectedSidebarView == 'ONBOARDING_PAGE' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/Settings-Icon.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Settings
              </span>
            </li>
            <hr class="hr-light">

            <!-- <li class="" (click)="toggleSidebarView('MATERIAL_PAGE')"
              [ngClass]="this.selectedSidebarView == 'MATERIAL_PAGE' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/Settings-Icon.svg" class="svg-icon-class-small ">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Materials
              </span>
            </li>
            <hr class="hr-light"> -->

          </ul>
        </div>
        <div class="col-md-11 col-12  bg-white-new margin-left-class">
          <app-breadcrumb></app-breadcrumb>
          <app-notifications></app-notifications>
          <div class="row d-block w-100 d-md-none border p-2 bg-white-new shadow sidebar-position">
            <div class=" col-12">
              <ul style="list-style: none;" class="ps-1 text-center mb-0">
                <!-- [ngClass]="this.selectedSidebarView == 'SOURCING_PAGE' ? 'list-active-mobile' : 'mx-2'" -->
                <li class="list-style-class list-padding-mobile"
                  [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'DASHBOARD_PAGE'}"
                  (click)="toggleSidebarView('DASHBOARD_PAGE')">
                  <div>
                    <img src="assets/icons/Home-Icon.svg" class="svg-icon-class-small ">
                  </div>
                  <span class="small-grey-font">
                    Dashboard
                  </span>
                </li>
                <li class="list-style-class list-padding-mobile"
                  [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'PURCHASE_REQUEST_PAGE'}"
                  (click)="toggleSidebarView('PURCHASE_REQUEST_PAGE')">
                  <div>
                    <img src="assets/icons/requisition-icon.svg" class="svg-icon-class-small ">
                  </div>
                  <span class="small-grey-font">
                    Requisition
                  </span>
                </li>
                <li class="list-style-class list-padding-mobile" (click)="toggleSidebarView('SOURCING_PAGE')"
                  [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'SOURCING_PAGE'}">
                  <div>
                    <img src="assets/icons/procurement-icon.svg" class="svg-icon-class-small ">
                  </div>
                  <span class="small-grey-font">
                    Procurement
                  </span>
                </li>
                <li class="list-style-class list-padding-mobile">
                  <div class="animate-dropdown" autoClose="true" ngbDropdown #menuDropdown1="ngbDropdown"
                    container="body">
                    <span ngbDropdownToggle data-bs-toggle="dropdown">
                      <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class-small">
                      <div class="small-grey-font mt-2">
                        More
                      </div>
                    </span>
                    <ul ngbDropdownMenu class="shadow mt-1 mb-5 text-center">

                      <li class="list-style-class list-padding-mobile"
                        (click)="toggleSidebarView('ADMIN_PURCHASE_ORDER_PAGE', menuDropdown1)"
                        [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'ADMIN_PURCHASE_ORDER_PAGE'}">
                        <div>
                          <img src="assets/icons/purchase-order.svg" class="svg-icon-class-small ">
                        </div>
                        <span class="small-grey-font">
                          Purchase Order
                        </span>
                      </li>
                      <hr class="my-1">
                      <!-- [ngClass]="this.selectedSidebarView == 'BIDDER_NETWORK_PAGE' ? 'list-active-mobile' : 'mx-2'" -->
                      <li class="list-style-class list-padding-mobile"
                        (click)="toggleSidebarView('ADMIN_INVOICE_PAGE', menuDropdown1)"
                        [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'ADMIN_INVOICE_PAGE'}">
                        <div>
                          <img src="assets/icons/Invoice.svg" class="svg-icon-class-small ">
                        </div>
                        <span class="small-grey-font">
                          Invoice
                        </span>
                      </li>
                      <hr class="my-1">
                      <li class="list-style-class list-padding-mobile"
                        (click)="toggleSidebarView('ADMIN_CATALOGUE_PAGE', menuDropdown1)"
                        [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'ADMIN_CATALOGUE_PAGE'}">
                        <div>
                          <img src="assets/icons/Catalogue.svg" class="svg-icon-class-small ">
                        </div>
                        <span class="small-grey-font">
                          Catalogue
                        </span>
                      </li>
                      <hr class="my-1">
                      <li class="list-style-class list-padding-mobile"
                        (click)="toggleSidebarView('BIDDER_NETWORK_PAGE', menuDropdown1)"
                        [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'BIDDER_NETWORK_PAGE'}">
                        <div>
                          <img src="assets/icons/Bidder-Network-Icon.svg" class="svg-icon-class-small ">
                        </div>
                        <span class="small-grey-font">
                          Vender Network
                        </span>
                      </li>
                      <hr class="my-1">
                      <li class="list-style-class list-padding-mobile"
                        (click)="toggleSidebarView('IN_HOUSE_USER_PAGE', menuDropdown1)"
                        [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'IN_HOUSE_USER_PAGE'}">
                        <div>
                          <img src="assets/icons/InHouse-Users-Icon.svg" class="svg-icon-class-small ">
                        </div>
                        <span class="small-grey-font">
                          Users
                        </span>
                      </li>
                      <hr class="my-1">
                      <li class="list-style-class list-padding-mobile"
                        (click)="toggleSidebarView('APPROVED_PAGE', menuDropdown1)"
                        [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'APPROVED_PAGE'}">
                        <div>
                          <img src="assets/icons/check-double.svg" class="svg-icon-class-small ">
                        </div>
                        <span class="small-grey-font">
                          Approval
                        </span>
                      </li>
                      <hr class="my-1">
                      <li class="list-style-class list-padding-mobile"
                        (click)="toggleSidebarView('ONBOARDING_PAGE',menuDropdown1)"
                        [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'ONBOARDING_PAGE'}">
                        <div>
                          <img src="assets/icons/Settings-Icon.svg" class="svg-icon-class-small ">
                        </div>
                        <span class="small-grey-font">
                          Settings
                        </span>
                      </li>
                    </ul>
                  </div>

                </li>

              </ul>
            </div>
          </div>

          <div *ngIf="isShowInternalPage">
            <ng-container [ngTemplateOutlet]="
              internalPageView == 'AUCTION_CREATOR_PAGE' ? creatorPage
              : internalPageView == 'RFX_CREATOR_PAGE' ? creatorPage
              : internalPageView == 'ADMIN_LINE_CATALOGUE_PAGE' ? lineCataloguePage
              : internalPageView == 'ADMIN_CATALOGUE_REQUISITION_PAGE' ? catalogueRequisition
              : internalPageView == 'AUCTION_ANALYTICS_DASHBOARD_PAGE' ? auctionAnalytics
              : internalPageView == 'AUCTION_GRAPH_PAGE' ? auctionGraph
              : empty">
            </ng-container>
          </div>

          <div *ngIf="!isShowInternalPage">
            <app-user-auction-registrations-notification></app-user-auction-registrations-notification>
            <ng-container [ngTemplateOutlet]="selectedSidebarView == 'SOURCING_PAGE' ? auctions
              : selectedSidebarView == 'DASHBOARD_PAGE' ? dashboard
              : selectedSidebarView == 'IN_HOUSE_USER_PAGE' ? inHouseAdmin
              : selectedSidebarView == 'BIDDER_NETWORK_PAGE' ? globalBidder
              : selectedSidebarView == 'ADMIN_CATALOGUE_PAGE' ? catalogue
              : selectedSidebarView == 'ADMIN_PURCHASE_ORDER_PAGE' ? purchaseOrder
              : selectedSidebarView == 'ADMIN_INVOICE_PAGE' ? invoice
              : selectedSidebarView == 'ANALYTICS_PAGE' ? analytics
              : selectedSidebarView == 'APPROVED_PAGE' ? approved
              : selectedSidebarView == 'PROJECTS_PAGE' ? projects
              : selectedSidebarView == 'PURCHASE_REQUEST_PAGE' ? purchaseRequestPage
              : selectedSidebarView == 'ONBOARDING_PAGE' ? onboarding
              : selectedSidebarView == 'MATERIAL_PAGE' ? materialPage: auctions">
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-chatbot></app-chatbot>

<ng-template #auctionAnalytics>
  <app-analytics-dashboard></app-analytics-dashboard>
</ng-template>
<ng-template #auctionGraph>
  <app-admin-generate-graph></app-admin-generate-graph>
</ng-template>

<ng-template #creatorPage>
  <app-event-stages [eventType]="eventType"></app-event-stages>
</ng-template>

<ng-template #dashboard>
  <app-new-admin-dashboard></app-new-admin-dashboard>
</ng-template>
<ng-template #projects>
  <app-admin-projects></app-admin-projects>
</ng-template>

<ng-template #auctions>
  <app-admin-auctions-section></app-admin-auctions-section>
</ng-template>
<ng-template #purchaseOrder>
  <app-admin-purchase-order></app-admin-purchase-order>
</ng-template>
<ng-template #invoice>
  <app-admin-invoice-page></app-admin-invoice-page>
</ng-template>
<ng-template #adminList>
  <app-admin-admin-list-section></app-admin-admin-list-section>
</ng-template>

<ng-template #onboarding>
  <app-onboarding-workflow></app-onboarding-workflow>
</ng-template>

<ng-template #materialPage>
  <app-admin-material></app-admin-material>
</ng-template>

<ng-template #inHouseAdmin>
  <!-- <app-admin-admin-list-section></app-admin-admin-list-section> -->
  <app-admin-auctioneer-section></app-admin-auctioneer-section>
</ng-template>

<ng-template #postAuction>
  <app-admin-post-sourcing-section></app-admin-post-sourcing-section>
</ng-template>

<ng-template #globalBidder>
  <app-bidders-list></app-bidders-list>
</ng-template>

<ng-template #catalogue>
  <app-admin-catalogue></app-admin-catalogue>
</ng-template>

<ng-template #lineCataloguePage>
  <app-catalogue-details></app-catalogue-details>
</ng-template>

<ng-template #catalogueCartPage>
  <app-catalogue-add-to-cart></app-catalogue-add-to-cart>
</ng-template>

<ng-template #catalogueRequisition>
  <app-catalogue-requisition></app-catalogue-requisition>
</ng-template>

<ng-template #approved>
  <app-purchase-order></app-purchase-order>
</ng-template>
<ng-template #analytics>
  <app-spend-analytics></app-spend-analytics>
</ng-template>
<ng-template #purchaseRequestPage>
  <app-admin-purchase-requests type="PR"></app-admin-purchase-requests>
</ng-template>

<!-- Empty Template -->
<ng-template #empty></ng-template>

<app-loader #appLoader></app-loader>