import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AuctionHouseDto } from '../../models/user/AuctionHouseDto';
import { Subscription } from 'rxjs';
import { LandingService } from '../../services/LandingAndBidder.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { CountryCodeDto } from '../../models/CountryCodeDto';
import { PrivacyAndTermsOfUseDto } from '../../models/PrivacyAndTermsOfUseDto';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit, OnDestroy {
  year: number = new Date().getFullYear();

  auctionHouseSubscription$?: Subscription;

  auctionHouse?: AuctionHouseDto;

  shareUrl: string = ''

  fbUsername?: string = "100087424403714"
  linkedInUsername?: string = "auctionext"
  whatsAppNumber?: string = "13024153184"
  twitterUsername?: string = "apple"

  privacyAndTermsOfUseDto?: PrivacyAndTermsOfUseDto;
  isLoggedIn: boolean = false;

  constructor(
    private landingService: LandingService,
    private ngbModal: NgbModal,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.userService.getUserEntity$.subscribe(data => {
      if (data) {
        this.isLoggedIn = data != null;
      } else {
        this.isLoggedIn = false
      }
    })

    this.shareUrl = 'https://' + ApplicationUtils.getSubDomainName();
    this.auctionHouseSubscription$ = this.landingService.getCurrentAuctionHouse$.subscribe((data) => {
      if (data) {
        this.auctionHouse = data;

        if (this.auctionHouse.privacyAndTermsOfUseList) {
          this.privacyAndTermsOfUseDto = this.auctionHouse.privacyAndTermsOfUseList.find(item => item.footerPolicy);
        }
      }
    })
  }

  openPrivacyModal(content: any) {
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openTCModal(content: any) {
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  moveToTop(ref: HTMLElement) {
    // window.scrollTo(0, 0)
    ref.scrollIntoView()
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

  ngOnDestroy(): void {
    if (this.auctionHouseSubscription$) {
      this.auctionHouseSubscription$.unsubscribe();
    }
  }

  // Footer Card Animation
  footerCardOneAnimationMouseOut() {
    let item = document.getElementById('footer-card-1-text');
    item!.style.transform = `translateX(0px) translateY(0px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)`
  }

  footerCardOneAnimationMouseOver(event: any) {
    let item = document.getElementById('footer-card-1');
    let itemText = document.getElementById('footer-card-1-text');
    var elHeight = item?.clientHeight! - 16;
    var elWidth = item?.clientWidth! - 16;

    let pageY = event.pageY - item!.offsetTop;
    let pageX = event.pageX - item!.offsetLeft;
    let mY = (((pageY / elHeight) * 100) - 50) * 0.3;
    let mX = (((pageX / elWidth) * 100) - 50) * 0.3;
    let rotate = mX * 0.2;

    itemText!.style.transform = `translateX(${mX}px) translateY(${mY}px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(${rotate}deg)`
  }

  footerCardTwoAnimationMouseOut() {
    let item = document.getElementById('footer-card-2-text');
    item!.style.transform = `translateX(0px) translateY(0px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)`
  }

  footerCardTwoAnimationMouseOver(event: any) {
    let item = document.getElementById('footer-card-2');
    let itemText = document.getElementById('footer-card-2-text');
    var elHeight = item?.clientHeight! - 16;
    var elWidth = item?.clientWidth! - 16;

    let pageY = event.pageY - item!.offsetTop;
    let pageX = event.pageX - item!.offsetLeft;
    let mY = (((pageY / elHeight) * 100) - 50) * 0.3;
    let mX = (((pageX / elWidth) * 100) - 50) * 0.3;
    let rotate = mX * 0.2;

    itemText!.style.transform = `translateX(${mX}px) translateY(${mY}px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(${rotate}deg)`
  }

  footerCardThreeAnimationMouseOut() {
    let item = document.getElementById('footer-card-3-text');
    item!.style.transform = `translateX(0px) translateY(0px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)`
  }
  footerCardThreeAnimationMouseOver(event: any) {
    let item = document.getElementById('footer-card-3');
    let itemText = document.getElementById('footer-card-3-text');
    var elHeight = item?.clientHeight! - 16;
    var elWidth = item?.clientWidth! - 16;

    let pageY = event.pageY - item!.offsetTop;
    let pageX = event.pageX - item!.offsetLeft;
    let mY = (((pageY / elHeight) * 100) - 50) * 0.3;
    let mX = (((pageX / elWidth) * 100) - 50) * 0.3;
    let rotate = mX * 0.2;

    itemText!.style.transform = `translateX(${mX}px) translateY(${mY}px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(${rotate}deg)`
  }



}
