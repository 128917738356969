import { NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from './../../../shared/services/file.service';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { FileInfoDto } from './../../../shared/models/FileInfoDto';
import { TranslateService } from './../../../shared/services/translate.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Currency } from './../../../shared/models/Currency';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnDestroy, OnInit, ChangeDetectorRef, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { DeploymentConfigurationDto } from 'src/app/shared/models/user/DeploymentConfigurationDto';
import { RankPriceConfigEnum } from 'src/app/shared/enums/RankPriceConfigEnum';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { RfxValidationErrorCodeDto } from 'src/app/shared/models/user/RfxValidationErrorCodeDto';
import { RfxValidationService } from 'src/app/shared/services/rfx-validation.service';
import { RfxSaveWrapperDto } from 'src/app/shared/models/user/RfxSaveWrapperDto';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';
import { TimeZoneDto } from 'src/app/shared/models/TimeZoneDto';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { Pattern } from 'src/app/shared/util/Patterns';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { UserService } from 'src/app/shared/services/user.service';
import { AngularEditorComponent, AngularEditorConfig } from 'src/assets/plugins/angular-editor/kolkov-angular-editor';
import { HtmlViewComponent } from 'src/app/shared/components/html-view/html-view.component';

@Component({
  selector: 'app-rfx-details',
  templateUrl: './rfx-details.component.html',
  styleUrls: ['./rfx-details.component.sass']
})
export class RfxDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  formGroup: FormGroup;

  rfxEntityDto?: RfxUiDto;
  nextClosingRfxSubcategory?: RfxSubcategoryUiDto;
  deploymentConfigurationDto?: DeploymentConfigurationDto;
  selectedCurrency?: Currency;

  currencies: Array<Currency> = [];
  timezones: Array<TimeZoneDto> = [];
  plantUiDtos: PlantUiDto[] = [];
  rfxValidationErrorCodeDtoList?: RfxValidationErrorCodeDto[];

  rankPriceConfigs: Array<any> = [
    RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION,
    RankPriceConfigEnum.SHOW_RANK_AND_CURRENT_PRICE_WITH_TRANSITION,
    RankPriceConfigEnum.SHOW_RANK_AND_NO_CURRENT_PRICE_WITH_TRANSITION
  ];

  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');


  isAllowToShowBidderHistoryConfig: boolean = false;

  isLoading: boolean = false;
  isSubmitted: boolean = false;
  selectedEditingConfig: boolean = false;
  selectedEditingType?: string;

  ngbPopover?: NgbPopover;
  startTime?: NgbTimeStruct;
  allowEditingConfirmModalRef?: NgbModalRef;

  plantsListSubscription$?: Subscription;
  rfxTimeZoneSubscription$?: Subscription;
  selectedRfxSubscription$?: Subscription;
  deploymentConfigurationDtoSubscription$?: Subscription;

  @ViewChild('allowEditingConfirmModalTemplate') allowEditingConfirmModalTemplate?: TemplateRef<any>;
  @ViewChild("descriptionEditor") descriptionEditor?: AngularEditorComponent;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '3rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '2',
    sanitize: false,
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode'
      ]
    ],
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'Page Divider',
        class: 'break',
        tag: 'div',
      },
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'hr',
      },
    ],
  };

  minDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  }

  minTime: NgbTimeStruct = {
    hour: new Date().getHours(),
    minute: new Date().getMinutes(),
    second: new Date().getSeconds()
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private transalteService: TranslateService,
    private datePipe: DatePipe,
    public adminService: AdminDashboardService,
    public adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private formBuilder: FormBuilder,
    private ngbModal: NgbModal,
    private rfxSubcategoryService: AdminRfxSubcategoryDataHolderService,
    private validationService: RfxValidationService,
    private currencyService: CurrencyService,
    private userService: UserService,
    private onboardingService: OnboardingService
  ) {
    this.formGroup = this.formBuilder.group({
      rfxTitle: new FormControl('', Validators.required),
      description: new FormControl(''),
      timeZone: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      startTime: new FormControl('', Validators.required),
      currency: new FormControl('', Validators.required),
      allowEditWhileUpcoming: new FormControl(true),
      allowEditWhileRunning: new FormControl(true),
      estimatedDeliveryDate: new FormControl('', Validators.required),

      plantCode: new FormControl('', Validators.required),
      costCenterCode: new FormControl('', Validators.required),
      leadTime: new FormControl('', [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]),
      estSpend: new FormControl('', Validators.required),
      estSaving: new FormControl('', Validators.required),
    })
  }

  ngOnInit(): void {
    let userEntityInfoDto = this.userService.getUserEntity();
    this.currencies = this.currencyService.getCurrencies;
    this.onboardingService.loadPlantUiDto(userEntityInfoDto?.orgCode!, userEntityInfoDto?.companyCode!);

    this.deploymentConfigurationDtoSubscription$ = this.adminService.getDeploymentConfiguration$.subscribe(data => {
      if (data) {
        this.deploymentConfigurationDto = data;
      }
    })

    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(rfx => {
      if (rfx) {
        this.rfxEntityDto = rfx!;
        this.changeDetectorRef.detectChanges();
      }
    })

    this.rfxTimeZoneSubscription$ = this.adminService.getMasterTimezones$.subscribe((data) => {
      if (data) {
        this.timezones = data;
        this.changeDetectorRef.detectChanges();
      }
    })

    this.plantsListSubscription$ = this.onboardingService.getPlantUiDtos$.subscribe(data => {
      if (data) {
        this.plantUiDtos = data;
      } else {
        this.plantUiDtos = [];
      }
    })

    this.formGroup.controls['startDate'].valueChanges.subscribe((val) => {
      if (val && !this.isSubmitted) {
        this.checkStartTimeValidation();
      }
    })
  }

  truncateText(input?: string) {
    if (input) {
      let formattedInput = input.replace(/<\/?[^>]+(>|$)/g, "");
      formattedInput = formattedInput.replace(/&#[0-9]+;/g, "");
      formattedInput = formattedInput.replace(/&nbsp;/g, ' ');
      formattedInput = formattedInput.replace(/&amp;/g, '&');
      return ApplicationUtils.truncateString(formattedInput, 101);
    }
    return '';
  }


  openShowMoreModal(rfxTitle: string, description: string) {
    let modalRef = this.ngbModal.open(HtmlViewComponent, { size: 'md', backdrop: 'static', keyboard: false, centered: true });
    modalRef.componentInstance.rfxTitle = rfxTitle;
    modalRef.componentInstance.content = description;
  }

  ngAfterViewInit(): void {
    ApplicationUtils.clearTextFormatOnPaste('descriptionEditor')
  }

  populateInitialRfxEntityDto() {
    let currentDate = new Date();

    this.formGroup.controls['rfxTitle'].setValue(this.rfxEntityDto?.rfxTitle);
    this.formGroup.controls['description'].setValue(this.rfxEntityDto?.description);
    this.formGroup.controls['timeZone'].setValue(this.rfxEntityDto?.timeZone);
    this.formGroup.controls['plantCode'].setValue(this.rfxEntityDto?.plantCode);
    this.formGroup.controls['costCenterCode'].setValue(this.rfxEntityDto?.costCenterCode);
    this.formGroup.controls['leadTime'].setValue(this.rfxEntityDto?.leadTime);

    this.selectedCurrency = this.rfxEntityDto?.currency;
    this.formGroup.controls['currency'].setValue(this.selectedCurrency?.name);

    if (this.rfxEntityDto?.startDate) {
      let startDate = ApplicationUtils.getNgbPickerDate(this.rfxEntityDto?.startDate!);
      this.formGroup.get('startDate')?.patchValue(startDate);

      this.setMinimumDateTime();
    }

    if (this.rfxEntityDto?.startTime) {
      this.startTime = ApplicationUtils.getNgbPickerTime(this.rfxEntityDto?.startTime!);
      let startTime12h = ApplicationUtils.convertTime24to12(this.rfxEntityDto?.startTime!, this.datePipe);
      this.formGroup.get('startTime')?.patchValue(startTime12h);
    } else {
      this.startTime = ApplicationUtils.getNgbPickerTime(`${currentDate.getHours()}:${currentDate.getMinutes()}`);
      let startTime12h = ApplicationUtils.convertTime24to12(`${currentDate.getHours()}:${currentDate.getMinutes()}`, this.datePipe);
      this.formGroup.get('startTime')?.patchValue(startTime12h);
    }

    if (this.rfxEntityDto?.estimatedDeliveryDate) {
      let estimatedDeliveryDate = ApplicationUtils.getNgbPickerDate(this.rfxEntityDto?.estimatedDeliveryDate!);
      this.formGroup.get('estimatedDeliveryDate')?.patchValue(estimatedDeliveryDate);
    }

    this.formGroup.controls['allowEditWhileUpcoming'].patchValue(this.rfxEntityDto?.allowEditWhileUpcoming);
    this.formGroup.controls['allowEditWhileRunning'].patchValue(this.rfxEntityDto?.allowEditWhileRunning);

    let estSpendValue = ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale!, this.rfxEntityDto?.estSpend);
    this.formGroup.get('estSpend')?.patchValue(estSpendValue);

    let estSavingValue = ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale!, this.rfxEntityDto?.estSaving);
    this.formGroup.get('estSaving')?.patchValue(estSavingValue);

    this.formGroup.updateValueAndValidity()
  }

  onlyAcceptInteger(event: any) {
    if (event.key === '.') { event.preventDefault(); }
  }

  setMinimumDateTime() {
    let currentDate = new Date();
    let currentDateNgbPicker = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() }
    let currentTimeNgbPicker = { hour: currentDate.getHours(), minute: currentDate.getMinutes(), second: currentDate.getSeconds() }

    if (this.rfxEntityDto?.startDate && this.rfxEntityDto?.startTime) {
      let sDate = ApplicationUtils.convertedDate(this.rfxEntityDto?.startDate!, this.rfxEntityDto?.startTime!)!;

      let startDateNgbPicker = ApplicationUtils.getNgbPickerDate(this.rfxEntityDto?.startDate!);
      let startTimeNgbPicker = ApplicationUtils.getNgbPickerTime(this.rfxEntityDto?.startTime!);

      // If Start Date is Upcoming Date then minDate is Current Date
      // If Start Date is Past Date then minDate is Start Date (Past Date)
      this.minDate = sDate > currentDate ? currentDateNgbPicker : startDateNgbPicker;
      this.minTime = sDate > currentDate ? currentTimeNgbPicker : startTimeNgbPicker;
    }
  }

  checkStartTimeValidation() {
    this.setMinimumDateTime();

    // Selected Date & Time
    let inputDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['startDate'].value);
    let inputTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['startTime'].value);

    // Min Date & Time
    let minimumDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.minDate);
    let minimumTimeIn12h = ApplicationUtils.getTimeFromNgTimePicker(this.datePipe, this.minTime);
    let minimumTime = ApplicationUtils.convertTime12to24(minimumTimeIn12h);

    let tempAllRfxSubcategory = [...this.rfxSubcategoryService.allRfxSubcategoryList ?? []];
    let allRfxSubcategory = tempAllRfxSubcategory.sort((a, b) => this.getRemainingTime(a) - this.getRemainingTime(b));
    if (allRfxSubcategory && allRfxSubcategory.length > 0) {
      this.nextClosingRfxSubcategory = allRfxSubcategory[0];
    }

    let subcategoryEndDateTime;

    if (inputDate && inputTime) {
      let _inputDateTime = ApplicationUtils.convertedDate(inputDate, inputTime);
      let _minimumDateTime = ApplicationUtils.convertedDate(minimumDate!, minimumTime!);

      if (this.nextClosingRfxSubcategory) {
        subcategoryEndDateTime = ApplicationUtils.convertedDate(this.nextClosingRfxSubcategory?.endDate!, this.nextClosingRfxSubcategory?.endTime!);
      }

      if (_inputDateTime && _minimumDateTime && (_inputDateTime < _minimumDateTime || (subcategoryEndDateTime && _inputDateTime >= subcategoryEndDateTime))) {
        this._showErrorToast$.next(true);

        if (subcategoryEndDateTime && _inputDateTime >= subcategoryEndDateTime) {
          this._errorMsg$.next("It is not possible to set a start date for the action that precedes the end date of its subcategory.".toUpperCase());
        } else {
          this._errorMsg$.next("RFX START DATE/TIME MUST BE AFTER CURRENT TIME");
        }

        return true;
      } else {
        this._showErrorToast$.next(false);
        this._errorMsg$.next("");
      }
    }
    return false;
  }

  populateRfxEntityDto(): RfxUiDto {
    let rfxEntityDto = ApplicationUtils.clone(this.rfxEntityDto);

    rfxEntityDto.rfxTitle = this.formGroup.controls['rfxTitle'].value;
    rfxEntityDto.description = this.formGroup.controls['description'].value;
    rfxEntityDto.timeZone = this.formGroup.controls['timeZone'].value;
    rfxEntityDto.plantCode = this.formGroup.controls['plantCode'].value;
    rfxEntityDto.costCenterCode = this.formGroup.controls['costCenterCode'].value;
    rfxEntityDto.leadTime = this.formGroup.controls['leadTime'].value;

    rfxEntityDto.startDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['startDate'].value)!;
    rfxEntityDto.startTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['startTime'].value);
    rfxEntityDto.allowEditWhileUpcoming = this.formGroup.controls['allowEditWhileUpcoming'].value;
    rfxEntityDto.allowEditWhileRunning = this.formGroup.controls['allowEditWhileRunning'].value;
    rfxEntityDto.currency = this.selectedCurrency;

    rfxEntityDto.estSpend = ApplicationUtils.resetPriceFormat(this.formGroup.controls['estSpend'].value).toString();
    rfxEntityDto.estSaving = ApplicationUtils.resetPriceFormat(this.formGroup.controls['estSaving'].value).toString();

    let estimatedDeliveryDate = ApplicationUtils.getDateFromNgDatePicker(
      this.datePipe,
      this.formGroup.controls['estimatedDeliveryDate'].value
    );
    rfxEntityDto.estimatedDeliveryDate = estimatedDeliveryDate!;

    return rfxEntityDto;
  }

  handleSubmit(saveForcefully: boolean) {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    if (this.checkStartTimeValidation()) {
      return;
    }

    if (!saveForcefully) {
      if (this.descriptionEditor?.focused) {
        return;
      }
    }

    this.rfxValidationErrorCodeDtoList = [];
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    this.updateRfx();
  }

  getFormattedPrice(price: any) {
    return ApplicationUtils.getFormattedPrice(this.selectedCurrency?.locale!, price);
  }

  invalidPriceValidator(item: AbstractControl | null) {
    if (item?.errors?.['pattern'] || item?.errors?.['forbiddenMin']) {
      return true;
    }
    return false;
  }

  formatEstSpendValue() {
    if (this.selectedCurrency) {
      let estSpendValue = this.formGroup.controls['estSpend'].value;
      let estSpendValueFinal = ApplicationUtils.getFormattedPrice(this.selectedCurrency?.locale!, estSpendValue);
      this.fc['estSpend'].patchValue(estSpendValueFinal);
    }
  }

  formatEstSavingValue() {
    if (this.selectedCurrency) {
      let estSavingValue = this.formGroup.controls['estSaving'].value;
      let estSavingValueFinal = ApplicationUtils.getFormattedPrice(this.selectedCurrency?.locale!, estSavingValue);
      this.fc['estSaving'].patchValue(estSavingValueFinal);
    }
  }

  // Currency
  onChangeCurrency(event: any) {
    let name = event.target.value;
    let currency = this.currencies.find(m => m.name == name);
    this.selectedCurrency = currency;
  }

  openTimepicker(p: NgbPopover, template: any) {
    if (this.ngbPopover?.isOpen) this.ngbPopover.close();

    if (!p.isOpen()) {
      this.ngbPopover = p;
      p.ngbPopover = template;
      p.open();
    } else {
      p.close();
    }
  }

  setTime(time: string) {
    if (time == 'startTime') {
      if (this.startTime?.hour != null && this.startTime?.minute != null && this.startTime?.second != null) {
        let convertedTime = ApplicationUtils.getTimeFromNgTimePicker(this.datePipe, this.startTime);
        this.formGroup.controls?.['startTime'].patchValue(convertedTime);
      }
    }
    if (this.ngbPopover?.isOpen()) this.ngbPopover?.close();
    this.formGroup.updateValueAndValidity();

    this.checkStartTimeValidation()
  }


  updateRfx() {
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");

    let rfxEntityDto = this.populateRfxEntityDto();

    this.adminService.saveRfxDetails(rfxEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let rfxSaveWrapperDto = apiResponseDto.data as RfxSaveWrapperDto;
          let rfxValidationErrorCodeDtoList = rfxSaveWrapperDto.rfxValidationErrorCodeDtos;
          if (!rfxValidationErrorCodeDtoList || rfxValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isLoading = false;
            setTimeout(() => {
              this._isSaveButtonEnable$.next(true);
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)
          } else {
            this.isLoading = false;
            this._isSaveButtonEnable$.next(true);
            this.closeModal();
            this.rfxValidationErrorCodeDtoList = rfxValidationErrorCodeDtoList;
            this.openErrorModel();
          }
        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving Rfx. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })

  }

  openErrorModel() {
    let errorModalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
    errorModalRef.componentInstance.auctionValidationErrorCodeDtoList = this.rfxValidationErrorCodeDtoList;
  }


  get fc(): any { return this.formGroup.controls; }


  openRfxDetailModal(content: any) {
    this.populateInitialRfxEntityDto();
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  closeModal() {
    this.isSubmitted = false;
    this.ngbModal.dismissAll();
  }

  getTimeZone(dbTimeZone: string) {
    if (this.timezones && this.timezones.length > 0) {
      let data = this.timezones.find((item) => item.locale == dbTimeZone);
      if (data) {
        return data.fullName;
      }
    }
    return "";
  }

  getErrorMsg(error: RfxValidationErrorCodeDto) {
    return ApplicationUtils.populateMessage(error, this.transalteService)
  }

  getDay(date?: string) {
    if (date != null) {
      return ApplicationUtils.getDisplayDay(date, this.datePipe);
    }
    return '';
  }

  getDate(date?: string) {
    if (date != null) {
      return ApplicationUtils.getDateInMMMdd(date, this.datePipe);
    }
    return '';
  }

  getYear(date?: string) {
    if (date != null) {
      return ApplicationUtils.getDisplayYear(date);
    }
    return '';
  }

  getTime(time?: string) {
    if (time != null) {
      return ApplicationUtils.getDisplayTimeInHHmm(time, this.datePipe);
    }
    return '';
  }

  getMeridiem(time?: string) {
    if (time != null) {
      return ApplicationUtils.getDisplayMeridiem(time, this.datePipe);
    }
    return '';
  }

  getRemainingTime(item: RfxSubcategoryUiDto) {
    return ApplicationUtils.getRemainingTime(item.endDate + " " + item.endTime, this.rfxEntityDto?.timeZone!);
  }

  ngOnDestroy(): void {
    if (this.rfxTimeZoneSubscription$) {
      this.rfxTimeZoneSubscription$.unsubscribe();
    }
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }
    if (this.deploymentConfigurationDtoSubscription$) {
      this.deploymentConfigurationDtoSubscription$.unsubscribe();
    }
    if (this.plantsListSubscription$) {
      this.plantsListSubscription$.unsubscribe();
    }
  }

  getDisplayDate() {
    return ApplicationUtils.getDisplayDateAdmin(this.rfxEntityDto?.startDate, this.rfxEntityDto?.startTime);
  }

  haveRfxDetailsErrors() {
    return this.validationService.haveRfxDetailsErrors(this.rfxEntityDto!);
  }

  getPlantName(plantCode: string) {
    if (this.plantUiDtos.length > 0) {
      let plant = this.plantUiDtos.find(item => item.plantCode == plantCode)
      return `${plant?.plantName}-(${plant?.plantCode})`
    }
    return '';
  }

  updateEditingConfig() {
    this.allowEditingConfirmModalRef?.close();
    if (this.selectedEditingType == RfxStatus.LIVE) {
      this.formGroup.controls['allowEditWhileRunning'].patchValue(this.selectedEditingConfig);
      this.formGroup.controls['allowEditWhileRunning'].updateValueAndValidity();
    }

    if (this.selectedEditingType == RfxStatus.LIVE_WAIT) {
      this.formGroup.controls['allowEditWhileUpcoming'].patchValue(this.selectedEditingConfig);
      this.formGroup.controls['allowEditWhileUpcoming'].updateValueAndValidity();
    }
  }

  resetEditingConfig() {
    this.allowEditingConfirmModalRef?.close();
    if (this.selectedEditingType == RfxStatus.LIVE) {
      this.formGroup.controls['allowEditWhileRunning'].patchValue(!this.selectedEditingConfig);
      this.formGroup.controls['allowEditWhileRunning'].updateValueAndValidity();
    }

    if (this.selectedEditingType == RfxStatus.LIVE_WAIT) {
      this.formGroup.controls['allowEditWhileUpcoming'].patchValue(!this.selectedEditingConfig);
      this.formGroup.controls['allowEditWhileUpcoming'].updateValueAndValidity();
    }
  }

  openEditingConfirmModal(config: boolean, status: string) {
    this.selectedEditingConfig = config;
    this.selectedEditingType = status;

    if (this.rfxEntityDto && this.rfxEntityDto.active
      && (this.rfxEntityDto.status == RfxStatus.LIVE || this.rfxEntityDto.status == RfxStatus.LIVE_WAIT)) {
      this.allowEditingConfirmModalRef = this.ngbModal.open(this.allowEditingConfirmModalTemplate, {
        size: 'md', keyboard: false, backdrop: 'static'
      });
      return;
    }
  }

}

