<div class="container">
  <div class="col-12 mt-4">
    <div class="border p-2 col mb-3 bg-white">
      <div class="row">
        <div class="col-auto mt-2">
          <img src="assets/icons/home_light.svg" class="svg-icon-class c-pointer" (click)="navigateToHome()">
          <span><img src="assets/icons/forward-icon.svg"
              class="svg-icon-class-small opacity-svg-icon c-pointer   ps-2"></span>
        </div>
        <div class="col-auto mt-2  ps-0">
          <span>
            <span class="btn-link span-class text-capitalize">
              Email Template Config
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="card bg-white">
      <div class="row p-2">
        <div class="col-md-5">

          <div class="row justify-content-center align-items-center my-2">
            <div class="col">
              Email 
              <!-- <img src="assets/icons/info_light.svg" class=" c-pointer svg-icon-class ms-2"> -->
            </div>
            <div class="col text-end">
              <button class="btn btn-sm btn-primary" (click)="openModal(resetAllConfirmationTemplate)">
                Reset All
              </button>
            </div>
          </div>
          <div class="list-group">
            <h4 class="heading-h4 text-capitalize">Application Specific </h4>
            <div *ngFor="let item of emailTemplates;">
            <a class="list-group-item list-group-item-action c-pointer" *ngIf="item.applicationSpecific"
              [ngClass]="{'active': item?.templateId == (activeTemplateId$ | async)}" aria-current="true"
               (click)="changeTemplate(item)">
              <img src="assets/icons/mail-footer.svg" class="svg-icon-xl me-2 c-pointer"> {{item?.templateName}}
              <ng-container [ngTemplateOutlet]="templatePopDescription" [ngTemplateOutletContext]="{item:item}"></ng-container>
            </a>
            <!-- <a class="list-group-item list-group-item-action c-pointer">
              <img src="assets/icons/settings_light.svg" class="svg-icon-xl me-2 c-pointer"> SMTP settings
            </a> -->
          </div>
          </div>
          <div class="list-group">
            <h4 class="mt-3 heading-h4 text-capitalize">Auction Specific </h4>
            <div *ngFor="let item of emailTemplates;">

            <a class="list-group-item list-group-item-action c-pointer"
              [ngClass]="{'active': item?.templateId == (activeTemplateId$ | async)}" aria-current="true" *ngIf="!item.applicationSpecific"
               (click)="changeTemplate(item)">
              <img src="assets/icons/mail-footer.svg" class="svg-icon-xl me-2 c-pointer"> {{item?.templateName}}
              <ng-container [ngTemplateOutlet]="templatePopDescription" [ngTemplateOutletContext]="{item:item}"></ng-container>
            </a>
            </div>
            <!-- <a class="list-group-item list-group-item-action c-pointer">
              <img src="assets/icons/settings_light.svg" class="svg-icon-xl me-2 c-pointer"> SMTP settings
            </a> -->
          </div>
        </div>
        <div class="col-md-7">
          <div class="mt-3">
            <h6>Email Template</h6>
          </div>
          <div>
          </div>
          <hr class="hr my-3" />
          <div class="mt-2" *ngIf="!isEditMode">
            <div class="row text-end justify-content-end align-items-start">
              <div class="col-auto">
                <img src="assets/icons/edit_light.svg" class="svg-icon-md me-2 c-pointer" (click)="toggleEditMode()">
              </div>
              <div class="col-auto" *ngIf="selectedEmailTemplate?.showEnableToggle">
                <span class="form-switch  mx-1">
                  <input class="form-check-input switch-toggle-margin-top c-pointer" type="checkbox" id="flexSwitchCheckChecked"
                    [formControl]="ctrlToggleTemplateStatus"
                    (click)="openChangeStatusModal(disableConfirmationTemplate)" />
                </span>
              </div>

              <div class="col-auto">
                <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                  <span ngbDropdownToggle data-bs-toggle="dropdown">
                    <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class-md">
                  </span>
                  <ul ngbDropdownMenu class="shadow p-2">
                    <li ngbDropdownItem class="c-pointer" (click)="openModal(testEmailTemplate)">
                      Send Test Email
                    </li>
                    <li ngbDropdownItem class="c-pointer" (click)="openModal(resetConfirmationTemplate)">
                      Reset Template
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 mt-2">
                <small> Subject</small>
                <div class="div-class">
                  {{selectedEmailTemplate?.subject}}
                </div>
              </div>
              <div class="col-12 mt-2">
                <small> Body</small>
                <div class="div-class">
                  <p [innerHTML]="selectedEmailTemplate?.body"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2" *ngIf="isEditMode">
            <form class="needs-validation" autocomplete="off" [formGroup]="formGroup">
              <div class="col-12 mt-2">
                <small> Subject</small>
                <div class="form-floating">
                  <input class="form-control" id="subject" formControlName="subject">
                  <label for="subject"></label>
                </div>
              </div>
              <div class="col-12 mt-2">
                <small>Body</small>
                <div class="">
                  <textarea #bodyEditor class="form-control" id="bodyEditor" id="body" formControlName="body"
                    rows="15"></textarea>
                  <label for="body"></label>
                </div>
              </div>

              <div class="my-3">
                <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
                  [message]="'Error while save template'"></app-message-toast>
                <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
                  message="Email template save successfully"></app-message-toast>
              </div>

              <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
                <div class="e-spinner"></div>
              </div>

              <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
                <button type="button" class="btn btn-new-class btn-sm me-3" (click)="toggleEditMode()">CANCEL</button>
                <button type="button" class="btn btn-sm btn-outline-primary" (click)="saveEmailTemplate()">SAVE</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #testEmailTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>SEND TEST EMAIL</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>

    <div class="model-body-top">
      <div class="m-3">
        <div class="col-12">
          EmailId
          <div class="form-floating">
            <input class="form-control" id="ctrlEmailTest" [formControl]="ctrlEmailTest">
            <label for="ctrlEmailTest">EmailId</label>
          </div>
        </div>
  
        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="'Error while sending test email.'"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" message="Test email sent successfully">
          </app-message-toast>
        </div>
  
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>
  
        <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-sm me-2" (click)="closeModal()">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="sendTestEmail()">SEND</button>
        </div>
  
      </div>
    </div>
  </div>
</ng-template>

<ng-template #resetConfirmationTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>RESET EMAIL TEMPLATE</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p>Do you want to reset {{selectedEmailTemplate?.templateName}}</p>
        </div>
  
        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="_errorMsg$ |async">
          </app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ | async" [isError]="false"
            message="Email Template reset successfully.">
          </app-message-toast>
        </div>
  
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>
  
        <div class="text-end mb-3" *ngIf="!isLoading&& !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-sm me-2" (click)="closeModal()">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="resetTemplate()">RESET</button>
        </div>
  
      </div>
    </div>
  </div>
</ng-template>

<ng-template #resetAllConfirmationTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>RESET ALL EMAIL TEMPLATE</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p>Do you want to reset all email templates?</p>
        </div>
  
        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="_errorMsg$ |async">
          </app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ | async" [isError]="false"
            message="Email Templates reset successfully.">
          </app-message-toast>
        </div>
  
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>
  
        <div class="text-end mb-3" *ngIf="!isLoading&& !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-sm me-3" (click)="closeModal()">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="resetAllTemplates()">RESET</button>
        </div>
  
      </div>
    </div>
  </div>
</ng-template>

<ng-template #disableConfirmationTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>{{templateStatus ? 'DISABLE' : 'ENABLE'}} EMAIL TEMPLATE</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <div class="">
        <p>Do you want to {{templateStatus ? 'disable' : 'enable' }} {{selectedEmailTemplate?.templateName}}</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="_errorMsg$ |async">
        </app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ | async" [isError]="false"
          message="Email Template {{selectedEmailTemplate?.enable ? 'enabled' : 'disabled'}} successfully.">
        </app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoading&& !(_showSuccessToast$ | async)">
        <button type="button" class="btn btn-new-class btn-sm me-2" (click)="closeModal()">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="changeEmailTemplateStatus('ENABLE')"
          *ngIf="!selectedEmailTemplate?.enable">ENABLE</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="changeEmailTemplateStatus('DISABLE')"
          *ngIf="selectedEmailTemplate?.enable">DISABLE</button>
      </div>

    </div>
  </div>


  </div>
</ng-template>

<ng-template #templatePopDescription let-item="item">
  <span [ngbPopover]="popContent" triggers="mouseenter:mouseleave" container="body" placement="top">
    <img src="assets/icons/info_light.svg" class=" c-pointer svg-icon-class ms-1">
  </span>
  <ng-template #popContent>
    {{item?.description}}
  </ng-template>
</ng-template>
