

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LandingService } from './shared/services/LandingAndBidder.service';
import { AuctionHouseDto } from './shared/models/user/AuctionHouseDto';
import { ApplicationUtils } from './shared/util/ApplicationUtils';
import { LocationStrategy } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from './shared/services/user.service';
import { DashboardRedirectionService } from './shared/services/dashboard-redirection.service';
import { SessionInfoDto } from './shared/models/SessionInfoDto';
import { RoutingService } from './shared/services/routing.service';
import { ApplicationListenerService } from './shared/services/application-listener.service';
import { AdminUserPrivilege } from './shared/enums/AdminUserPrivilege';
import { AuthenticationService } from './shared/services/authentication.service';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { ApplicationConstants } from './shared/util/ApplicationConstants';
import { OnboardingService } from './shared/services/onboarding.service';
import { OrganizationUiDto } from './shared/models/OrganizationUiDto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnDestroy, AfterViewInit {

  currentAuctionHouseSubscription$?: Subscription
  @ViewChild("ErrorTemp") errorTempRef!: NgbModal;

  @ViewChild('appLoader', { static: false })
  appLoader?: LoaderComponent;
  organizationUiDto?: OrganizationUiDto;

  constructor(
    private dataRedirectionService: DashboardRedirectionService,
    private userService: UserService,
    private ngbModal: NgbModal,
    // private location: LocationStrategy,
    private meta: Meta,
    private title: Title,
    private translate: TranslateService,
    // private landingService: LandingService,
    private onboardingService: OnboardingService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private applicationListenerService: ApplicationListenerService
  ) {
    this.translate.use("en");
  }

  async ngOnInit() {
    this.disableApplicationLoader();

    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.onboardingService.getOrganizationUiDto$.subscribe(data => {
      if (data) {
        this.updateMetaTag(data);

        if (data.primaryColor && data.secondaryColor) {
          document.documentElement.style.setProperty('--clr-primary', data.primaryColor!, 'important');
          document.documentElement.style.setProperty('--clr-secondary', data.secondaryColor!, 'important');
        }
      }
    })

    // history.pushState(null, null!, window.location.href);
    // // check if back or forward button is pressed.
    // this.location.onPopState(() => {
    //     this.openErrorTem();
    //     history.pushState(null, null!, window.location.href);

    // });
  }

  async ngAfterViewInit() {
    this.appLoader?.openLoaderIcon(ApplicationConstants.LOADER_NOTIFICATION, "Loading ...");
    await this.applicationListenerService.loadApplicationInitialData();

    // if param is not present, consider it as a page refresh event
    let sessionId = localStorage.getItem("AUC_SESSION_ID");
    if (sessionId) {
      this.redirectIfValidSession();
    } else {
      this.appLoader?.closeLoaderForcefully();
    }
  }

  private async redirectIfValidSession() {
    let sessionInfo = await this.dataRedirectionService.getUserSessionInfo(this.appLoader!);
    if (sessionInfo) {
      this.userService.setUser(sessionInfo);
      this.authenticationService.initializeListenerAndDoRouting(sessionInfo!);
    }
  }

  disableApplicationLoader() {
    let element = document.getElementById('idForDataLoader');
    if (element) {
      element.style.display = 'none';
      console.log("Loader disable successfully.")
    }
  }

  openErrorTem() {
    this.ngbModal.open(this.errorTempRef, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    })
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  updateMetaTag(organizationUiDto: OrganizationUiDto) {
    let metaTitle = this.truncateString(organizationUiDto.name!, 55);
    let metaDescription = this.truncateString(organizationUiDto.description!, 120);

    this.title.setTitle(metaTitle);
    this.meta.updateTag({ name: 'description', content: `${metaDescription}` });
  }

  truncateString(input: string, length: number) {
    if (input) {
      let formattedInput = input.replace(/<\/?[^>]+(>|$)/g, "");
      formattedInput = formattedInput.replace(/&#[0-9]+;/g, "");
      formattedInput = formattedInput.replace(/&nbsp;/g, ' ');
      formattedInput = formattedInput.replace(/&amp;/g, '&');
      return ApplicationUtils.truncateString(formattedInput, length);
    }
    return '';
  }

  ngOnDestroy(): void {
    if (this.currentAuctionHouseSubscription$) {
      this.currentAuctionHouseSubscription$.unsubscribe()
    }
  }

}
