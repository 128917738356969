<div class="border rounded bg-white" [ngClass]="{'border-danger border-2': haveLotCategoryErrors()}">
  <div class="">
    <header class="p-2">
      <div class="row ">
        <div class="col-md-6">
          <h5 class="heading-h5"> META DATA</h5>
        </div>
        <div class="col-md-6 text-md-end">
          <button class="btn btn-new-class btn-sm px-4 " (click)="openlotDetailsTwo(lotDetailsTwo)"
            [disabled]="!isAllowToEdit()">
            <!-- <img src="assets/icons/edit_dark.svg" class=" c-pointer svg-icon-class"> -->
            EDIT
          </button>
        </div>
      </div>
    </header>
    <hr class="hr" />
    <div class="col-12 div-class py-2 px-3">
      Additional Information
    </div>

    <div class="row px-3">
      <hr class="hr" />
      <div class="col-4 font-weight-normal py-2"
        [ngClass]="{'text-danger':!auctionLotEntityDto?.preferenceCategory}">
        Sub Category 1
      </div>
      <div class="col-8 div-class py-2">
        {{auctionLotEntityDto?.preferenceCategory?.categoryLevelOneName}}
      </div>
      <hr class="hr" />
      <div class="col-4 font-weight-normal py-2"
        [ngClass]="{'text-danger':!auctionLotEntityDto?.preferenceCategory}">
        Sub Category 2
      </div>
      <div class="col-8 div-class py-2">
        {{auctionLotEntityDto?.preferenceCategory?.categoryLevelTwoName}} - {{auctionLotEntityDto?.preferenceCategory?.categoryLevelThreeName}}
      </div>
      <hr class="hr" />
      <div class="col-4 font-weight-normal py-2"
        [ngClass]="{'text-danger':!auctionLotEntityDto?.preferenceCategory}">
        Sub Category 3
      </div>
      <div class="col-8 div-class py-2">
        {{auctionLotEntityDto?.preferenceCategory?.categoryLevelFourName}}
      </div>
      <hr class="hr" />
      <div class="col-4 font-weight-normal py-2">
        HashTags
      </div>
      <!-- <div class="col-8 div-class py-2">
        <span class="rounded-pill hash-1" *ngFor="let hashtag of auctionLotEntityDto?.hashtags">
          {{hashtag}}
        </span>
      </div> -->
      <div class="col-8 div-class py-2 ps-0">
        <ul class="animate-show-more" #hashtagRef
          [ngClass]="{'hide-description': !isShowMoreHashtag && hashtagRef.scrollHeight > 120 && auctionLotEntityDto?.hashtags}">
          <li *ngFor="let item of auctionLotEntityDto?.hashtags; index as i">
            {{item}}
          </li>
        </ul>
        <hr class="hr" *ngIf="isShowMoreHashtag" />
        <div class="col-12 text-end btn-link pb-2" (click)="ShowMoreHashtag()" *ngIf="hashtagRef.scrollHeight > 90">
          {{isShowMoreHashtag ? 'HIDE MORE' : 'SHOW MORE'}}
        </div>
      </div>
      <hr class="hr" />
      <div class="col-4 font-weight-normal py-2">
        Highlight
      </div>
      <div class="col-8 div-class py-2 ps-0">
        <ul class="animate-show-more" #highlightRef
          [ngClass]="{'hide-description': !isShowMoreHighlights && highlightRef.scrollHeight > 120 && auctionLotEntityDto?.highlights}">
          <li *ngFor="let item of auctionLotEntityDto?.highlights; index as i">
            {{item}}
          </li>
        </ul>
        <hr class="hr" *ngIf="isShowMoreHighlights" />
        <div class="col-12 text-end btn-link pb-2" (click)="showMoreHighlights()"
          *ngIf="highlightRef.scrollHeight > 90">
          {{isShowMoreHighlights ? 'HIDE MORE' : 'SHOW MORE'}}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #lotDetailsTwo>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>META DATA</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup">

          <app-categories-tree-view [selectionMode]="TreeSelectionMode.single"
            (onSelectedCategory)="onSelectedCategory($event)"></app-categories-tree-view>
          
          <div class="mb-3">
            <label for="hashtags" class="text-area-label">HashTags
            </label>
            <div class="form-group">
              <tag-input class="my-1" formControlName="hashtags" [modelAsStrings]="true" id="hashtags"
                [placeholder]="inputTagPlaceholder" [secondaryPlaceholder]="inputTagPlaceholder"
                [separatorKeyCodes]="separatorKeyCodes" [addOnPaste]="true"
                [pasteSplitPattern]="pasteSplitPattern"></tag-input>
            </div>
          </div>
          <div class="mb-3">
            <label for="highlights"  class="text-area-label">Highlights (add new line for the next highlight)
            </label>
            <div class="form-group">
              <textarea #propertyHighlightEditor formControlName="highlights" id="highlights" class="form-control" rows="2"></textarea>
            </div>
          </div>
          <div class="my-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async"
              [message]="'Lot saved successfully.'"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="_errorMsg$ |async"></app-message-toast>
          </div>
          <div class="mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>
          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class"
              [disabled]="!(this._isSaveButtonEnable$ | async) && !isAllowToEdit()"
              (click)="handleValidSubmit()">SAVE</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>