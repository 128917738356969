import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApplicationConstants } from '../util/ApplicationConstants';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { Observable, catchError, firstValueFrom, tap } from 'rxjs';
import { LandingService } from './LandingAndBidder.service';
import { AdminDashboardService } from './admin-dashboard.service';
import { AuctionLotEntityDto } from '../models/user/AuctionLotEntityDto';
import { AdminLotsDataHolderService } from './adminLotsDataHolderService.service';
import { AuctionEntityDto } from '../models/user/AuctionEntityDto';
import { AdminSourcingEventsDataHolderService } from './AdminSourcingEventsDataHolder.service ';
import { RfxUiDto } from '../models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from '../models/rfx/RfxSubcategoryUiDto';
import { AdminRfxSubcategoryDataHolderService } from './AdminRfxSubcategoryDataHolderService.service';
import { RfxQuestionnaireWrapperDto } from '../models/rfx/RfxQuestionnaireWrapperDto';
import { QuestionnaireService } from './questionnaire.service';

@Injectable({
  providedIn: 'root'
})
export class ServerDataFetchService {
  masterCacheServerUrl: string = "https://master-cache-pkc-dev.azurewebsites.net"

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private landingService: LandingService,
    private adminService: AdminDashboardService,
    private adminLotsDataHolderService: AdminLotsDataHolderService,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private questionnaireService: QuestionnaireService,
  ) { }

  /**
   * This method to be called when you need to load the data synchronous
   * Fetch the lots from server for a given auction for admin and agent.
   * This methods returns all lots except discarded
   * @param auctionId
   */
  async loadAllLotsOfAuctionForAdminSync(auctionId: string) {

    this.adminService.setAllLotsDataLoaded(false);
    try {
      let apiResponseDto = await firstValueFrom(this.getAuctionLotsByAuctionIdForAdmin(auctionId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let auctionLotEntityDtos = apiResponseDto.data as AuctionLotEntityDto[];

        this.adminLotsDataHolderService.updateAllLotsData(auctionLotEntityDtos);
        this.adminService.setAllLotsDataLoaded(true);
      } else {
        console.error("Error while calling getAuctionLotsByAuctionIdForAdmin : " + JSON.stringify(apiResponseDto));
      }
    } catch (error) {
      console.error(error);
    }
  }


  /**
 * This method to be called when you need to load the data synchronous
 * Fetch the lots from server for a given auction for admin and agent.
 * This methods returns all lots except discarded
 * @param rfx
 */
  async loadAllSubcategoriesOfRfxIdForAdminSync(rfxId: string) {

    this.adminService.setAllRfxSubcategoryDataLoaded(false);
    try {
      let apiResponseDto = await firstValueFrom(this.getRfxSubcategoriesByRfxIdForAdmin(rfxId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let rfxSubcategoryEntityDtos = apiResponseDto.data as RfxSubcategoryUiDto[];

        this.adminRfxSubcategoryDataHolderService.updateAllRfxSubcategoryData(rfxSubcategoryEntityDtos);
        this.adminService.setAllRfxSubcategoryDataLoaded(true);
      } else {
        console.error("Error while calling loadAllSubcategoriesOfRfxIdForAdminSync : " + JSON.stringify(apiResponseDto));
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
* This method to be called when you need to load the data synchronous
* Fetch the lots from server for a given auction for admin and agent.
* This methods returns all lots except discarded
* @param rfx
*/
  async loadRfxQuestionnaireWrapperDtoOfRfxIdForAdminSync(rfxId: string) {

    this.adminService.setAllRfxSubcategoryDataLoaded(false);
    try {
      let apiResponseDto = await firstValueFrom(this.getRfxQuestionnaireWrapperForAdmin(rfxId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let rfxQuestionnaireWrapperDto = apiResponseDto.data as RfxQuestionnaireWrapperDto;

        this.adminRfxSubcategoryDataHolderService.updateAllRfxSubcategoryData(rfxQuestionnaireWrapperDto.rfxSubcategoryUiDtos!);
        this.questionnaireService.updateQuestionnaireWrapperDtos(rfxQuestionnaireWrapperDto.questionnaireWrapperDtos ?? [])
        this.adminService.setAllRfxSubcategoryDataLoaded(true);
      } else {
        console.error("Error while calling loadAllSubcategoriesOfRfxIdForAdminSync : " + JSON.stringify(apiResponseDto));
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * This method to be called when you need to load the data synchronous
   * Fetch the Auction from server for a given auction for admin and agent.
   * This methods returns auction
   * @param auctionId
   */
  async loadAuctionForAdminSync(auctionId: string) {

    try {
      let apiResponseDto = await firstValueFrom(this.getAuctionByAuctionIdForAdmin(auctionId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let auctionEntityDto = apiResponseDto.data as AuctionEntityDto;
        // this.adminSourcingEventsDataHolderService.setSelectedSourcingEvent(auctionEntityDto);
        this.adminSourcingEventsDataHolderService.setSelectedAuction(auctionEntityDto);
      } else {
        console.error("Error while calling getAuctionLotsByAuctionIdForAdmin : " + JSON.stringify(apiResponseDto));
      }
    } catch (error) {
      console.error(error);
    }
  }


  /**
 * This method to be called when you need to load the data synchronous
 * Fetch the Auction from server for a given auction for admin and agent.
 * This methods returns auction
 * @param auctionId
 */
  async loadRfxForAdminSync(rfxId: string) {

    try {
      let apiResponseDto = await firstValueFrom(this.getRfxByRfxIdForAdmin(rfxId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let rfxEntityDto = apiResponseDto.data as RfxUiDto;
        this.adminSourcingEventsDataHolderService.setSelectedRfx(rfxEntityDto);
      } else {
        console.error("Error while calling loadRfxForAdminSync : " + JSON.stringify(apiResponseDto));
      }
    } catch (error) {
      console.error(error);
    }
  }

  loadAllLotsOfPostAuctions(auctionIdsSet: string[]) {
    this.getAllLotsOfPostAuctions(auctionIdsSet).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let lotsData = apiResponseDto.data as AuctionLotEntityDto[];
          this.adminLotsDataHolderService.updateAllPostAuctionLots(lotsData);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }


  getAllLotsOfPostAuctions(auctionIds: string[]) {
    return this.http.post<ServerAPIResponseDto>('auctionLotsByAuctionIdsForAdmin', { 'auctionIdsInString': auctionIds }).pipe(
      tap(_ => console.log("Get auctionLotsByAuctionIdsForAdmin")),
      catchError(this.errorService.handleError<any>("Error while getting auctionLotsByAuctionIdsForAdmin")));
  }




  /// API CALLS

  getAuctionHouseFromServer(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>('auctionHouse').pipe(
      tap(_ => console.log("Get AuctionHouse")),
      catchError(this.errorService.handleError<any>("Error while getting AuctionHouse")));
  }
  getAuctionHouseByAuctionHouseIdFromServer(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>('auctionHouse').pipe(
      tap(_ => console.log("Get auctionHouse")),
      catchError(this.errorService.handleError<any>("Error while getting auctionHouse")));
  }


  getDeploymentConfiguration(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>('deploymentConfiguration').pipe(
      tap(_ => console.log("Get DeploymentConfig")),
      catchError(this.errorService.handleError<any>("Error while getting applicationDeploymentConfig")));
  }

  /**
  * Get the timezones data from server.
  * This methods is called on application startup. Its a one time call.
  * @returns
  */
  getTimezonesDataFromServer(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(this.masterCacheServerUrl + "/" + 'timezones').pipe(
      tap(_ => console.log("Get timezones")),
      catchError(this.errorService.handleError<any>("Error while getting timezones")));
  }

  /**
  * Get the categories data from server.
  * This methods is called on application startup. Its a one time call.
  * @returns
  */
  getCategoriesDataFromServer(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(this.masterCacheServerUrl + "/" + 'categories').pipe(
      tap(_ => console.log("Get categories")),
      catchError(this.errorService.handleError<any>("Error while getting categories")));
  }

  /**
   * Get all the lots for a given auction.
   * This method is called when Bidder selects any auction card.
   * @param auctionId
   * @returns
   */
  getAuctionLotsByAuctionIdForBidder(auctionId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('auctionLotsByAuctionId', { params: params }).pipe(
      tap(_ => console.log("Get auctionLotsByLotIds")),
      catchError(this.errorService.handleError<any>("Error while getting auctionLotsByLotIds")));
  }

  /**
   * Get all the lots for a given auction.
   * This method is called when Admin selects any auction card.
   * @param auctionId
   * @returns
   */
  getAuctionLotsByAuctionIdForAdmin(auctionId: string): Observable<any> {

    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('auctionLotsByAuctionIdForAdmin', { params: params }).pipe(
      tap(_ => console.log("Get auctionLotsByAuctionIdForAdmin")),
      catchError(this.errorService.handleError<any>("Error while getting auctionLotsByAuctionIdForAdmin")));
  }

  /**
 * Get all the lots for a given auction.
 * This method is called when Admin selects any auction card.
 * @param auctionId
 * @returns
 */
  getRfxSubcategoriesByRfxIdForAdmin(rfxId: string): Observable<any> {

    let params = new HttpParams().set('rfxId', rfxId);
    return this.http.get<ServerAPIResponseDto>('rfxSubcategoriesByRfxIdForAdmin', { params: params }).pipe(
      tap(_ => console.log("Get rfxSubcategoriesByRfxIdForAdmin")),
      catchError(this.errorService.handleError<any>("Error while getting rfxSubcategoriesByRfxIdForAdmin")));
  }

  /**
* Get all the lots for a given auction.
* This method is called when Admin selects any auction card.
* @param auctionId
* @returns
*/
  getRfxQuestionnaireWrapperForAdmin(rfxId: string): Observable<any> {
    let params = new HttpParams().set('rfxId', rfxId);
    return this.http.get<ServerAPIResponseDto>('rfxSubcategoriesQuestionnaireWrapperForAdmin', { params: params }).pipe(
      tap(_ => console.log("Get rfxQuestionnaireWrapperForAdmin")),
      catchError(this.errorService.handleError<any>("Error while getting rfxQuestionnaireWrapperForAdmin")));
  }

  /**
   * Get Auction for a given auction.
   * This method is called when Admin selects any auction card.
   * @param auctionId
   * @returns
   */
  getAuctionByAuctionIdForAdmin(auctionId: string): Observable<any> {

    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('auctions', { params: params }).pipe(
      tap(_ => console.log("Get auctionLotsByAuctionIdForAdmin")),
      catchError(this.errorService.handleError<any>("Error while getting auctionLotsByAuctionIdForAdmin")));
  }


  /**
   * Get Auction for a given auction.
   * This method is called when Admin selects any auction card.
   * @param auctionId
   * @returns
   */
  getRfxByRfxIdForAdmin(rfxId: string): Observable<any> {
    let params = new HttpParams().set('rfxId', rfxId);
    return this.http.get<ServerAPIResponseDto>('rfxData', { params: params }).pipe(
      tap(_ => console.log("Get RfxByRfxIdForAdmin")),
      catchError(this.errorService.handleError<any>("Error while getting RfxByRfxIdForAdmin")));
  }

  /**
   * Get the AuctionCardWrapperDto for given auction Ids.
   * @param auctionIds
   * @returns
   */
  getAuctionCardWrapperDtosByIds(auctionIds: string[]): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('auctionCardWrapperDtosByIds', { auctionIdsInString: auctionIds }).pipe(
      tap(_ => console.log("Get auctionCardWrapperDtos")),
      catchError(this.errorService.handleError<any>("Error while getting auctionCardWrapperDtos")));
  }

  getAuctionLotsByLotIds(lotIds: string[]): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('auctionLotsByLotIds', { lotIdsInString: lotIds }).pipe(
      tap(_ => console.log("Get auctionLotsByLotIds")),
      catchError(this.errorService.handleError<any>("Error while getting auctionLotsByLotIds")));
  }


  getCurrentUserAuctionRegistrations() {
    return this.http.get<ServerAPIResponseDto>('userAuctionRegistrations').pipe(
      tap(_ => console.log("Get userAuctionRegistrations")),
      catchError(this.errorService.handleError<any>("Error while getting userAuctionRegistrations")));
  }

  getAllUserAuctionRegistrationsOfAuctionHouse() {
    return this.http.get<ServerAPIResponseDto>('userRegistrationsForAuctionHouse').pipe(
      tap(_ => console.log("Get userRegistrationsForAuctionHouse")),
      catchError(this.errorService.handleError<any>("Error while getting userRegistrationsForAuctionHouse")));
  }


  getAuctionCardWrapperDtosInitial() {
    let params = { pageSize: ApplicationConstants.PAGE_SIZE };
    return this.http.get<ServerAPIResponseDto>('auctionCardWrapperDtosInitial', { params: params }).pipe(
      tap(_ => console.log("Get AuctionCardWrapperDtosInitial")),
      catchError(this.errorService.handleError<any>("Error while getting AuctionCardWrapperDtosInitial")));
  }

}
