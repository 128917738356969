import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import { TreeSelectionMode } from 'src/app/shared/enums/TreeSelectionMode';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { MaterialCompanyUiDto, MaterialPlantUiDto, MaterialUiDto } from 'src/app/shared/models/MaterialUiDto';
import { OrganizationUiDto } from 'src/app/shared/models/OrganizationUiDto';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { PreferenceCategoryDto } from 'src/app/shared/models/user/PreferenceCategoryDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { FileService } from 'src/app/shared/services/file.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

interface NgTableData {
  companyCode: string
  companyName: string
  plantCode: string
  plantName: string
  currentLevel: number
  safetyMin: number
  safetyMax: number
}

@Component({
  selector: 'app-admin-material',
  templateUrl: './admin-material.component.html',
  styleUrls: ['./admin-material.component.sass']
})
export class AdminMaterialComponent implements OnInit, OnDestroy {
  formGroup: FormGroup

  TreeSelectionMode: typeof TreeSelectionMode = TreeSelectionMode;

  organizationUiDto?: OrganizationUiDto;
  companyUiDtos: CompanyUiDto[] = [];
  plantUiDtos: PlantUiDto[] = [];
  materialUiDtos: MaterialUiDto[] = [];
  isEditMaterial: boolean = false;
  
  ngTableDataList: NgTableData[] = []
  selectedMaterialUiDto?: MaterialUiDto;
  selectedPreferenceCategory?: PreferenceCategoryDto;

  isLoading: boolean = false;
  isDataLoading: boolean = false;
  errorMsg: string | undefined;

  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];

  currentFile?: File;
  imageFileName: string = '';
  fileUploadError: boolean = false;
  imageUrl?: string | ArrayBuffer;
  imageType?: string;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  materialsListSubscription$?: Subscription;
  plantsListSubscription$?: Subscription;
  companiesSubscription$?: Subscription;

  @ViewChild('materialImage') materialImage?: HTMLInputElement;

  constructor(
    private fb: FormBuilder,
    private ngbModal: NgbModal,
    private fileService: FileService,
    private onboardingService: OnboardingService,
    private adminDashboardService: AdminDashboardService
  ) {
    this.formGroup = this.fb.group({
      materialId: ['', [Validators.required , Validators.minLength(10), Validators.maxLength(10)]],
      materialName: ['', Validators.required],
      description: [''],
      image: [null],
      uom: [null, Validators.required],
      qty: [null, Validators.required],
    })
  }

  ngOnInit(): void {
    this.organizationUiDto = this.onboardingService.getOrganizationUiDto;
    this.loadInitData();

    this.materialsListSubscription$ = this.adminDashboardService.getMaterialUiDtos$.subscribe(data => {
      if (data) {
        this.materialUiDtos = data;
      } else {
        this.materialUiDtos = [];
      }
    })

    this.plantsListSubscription$ = this.onboardingService.getPlantUiDtos$.subscribe(data => {
      if (data) {
        this.plantUiDtos = data;
        this.initMaterialCompanyUiDtos();
      } else {
        this.plantUiDtos = [];
      }
    })

    this.companiesSubscription$ = this.onboardingService.getCompanyUiDtos$.subscribe(data => {
      if (data) {
        this.companyUiDtos = data;
      } else {
        this.companyUiDtos = [];
      }
    })
  }

  get fc() { return this.formGroup.controls; }

  async loadInitData() {
    this.isDataLoading = true;
    await this.adminDashboardService.loadMaterialUiDtosByOrgCode(this.organizationUiDto?.orgCode!);
    await this.onboardingService.loadCompanyUiDtosByOrgCode();
    await this.onboardingService.loadPlantUiDtosByOrgCode(this.organizationUiDto?.orgCode!);
    this.isDataLoading = false;
  }

  populateMaterialData() {
    if (this.selectedMaterialUiDto) {
      if (this.selectedMaterialUiDto.image) {
        this.imageUrl = `/downloadLandingBlob?fileId=${this.selectedMaterialUiDto.image?.fileId}`;
        this.imageFileName = this.selectedMaterialUiDto.image?.displayName as string;
        this.formGroup.get('image')?.setValue(this.selectedMaterialUiDto.image);
      }

      this.selectedPreferenceCategory = this.selectedMaterialUiDto.preferenceCategoryDto;
      this.populateMaterialCompanyUiDtos();

      this.formGroup.patchValue(this.selectedMaterialUiDto);
      this.formGroup.updateValueAndValidity();
    }
  }

  initMaterialCompanyUiDtos() {
    if (this.companyUiDtos.length == 0 || this.plantUiDtos.length == 0) {
      return;
    }

    this.ngTableDataList = [];

    this.plantUiDtos.forEach(plantUiDto => {
      let companyUiDto = this.companyUiDtos.find(companyUiDto => companyUiDto.companyCode == plantUiDto.companyCode);

      let ngTableData: NgTableData = {
        companyCode: companyUiDto?.companyCode!,
        companyName: companyUiDto?.companyName!,
        plantCode: plantUiDto?.plantCode!,
        plantName: plantUiDto?.plantName!,
        currentLevel: 0,
        safetyMin: 0,
        safetyMax: 0,
      }

      this.ngTableDataList.push(ngTableData);
    })
  }

  populateMaterialCompanyUiDtos() {
    this.ngTableDataList = [];

    let materialCompanyUiDtos = this.selectedMaterialUiDto?.materialCompanyUiDtos ?? [];

    materialCompanyUiDtos.forEach(materialCompanyUiDto => {
      let materialPlantUiDtos = materialCompanyUiDto.materialPlantUiDtos ?? [];

      materialPlantUiDtos.forEach(item => {
        let ngTableData: NgTableData = {
          companyCode: materialCompanyUiDto?.companyCode!,
          companyName: materialCompanyUiDto?.companyName!,
          plantCode: item?.plantCode!,
          plantName: item?.plantName!,
          currentLevel: item?.currentLevel!,
          safetyMin: item.safetyMin!,
          safetyMax: item.safetyMax!,
        }
  
        this.ngTableDataList.push(ngTableData);
      })
    })
  }

  openAddMaterialModal(content: any , selectedMaterialUiDto?: MaterialUiDto) {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = false;

    this.formGroup.reset();
    this.ngTableDataList = [];
    this.selectedMaterialUiDto = undefined;
    this.selectedPreferenceCategory = undefined

    if (selectedMaterialUiDto) {
      this.selectedMaterialUiDto = selectedMaterialUiDto
      this.isEditMaterial = true;
      this.populateMaterialData();
    } else{
      this.isEditMaterial = false
      this.initMaterialCompanyUiDtos()
    }
    
    this.ngbModal.open(content, {
      size: 'xl', backdrop: 'static', keyboard: false, centered: true
    });
  }

  toggleEditCategory() {
    this.selectedPreferenceCategory = undefined;
  }

  onSelectedCategory(preferenceCategory?: PreferenceCategoryDto) {
    this.selectedPreferenceCategory = preferenceCategory;
  }

  closeModal() {
    this.ngbModal.dismissAll();
    this.imageFileName = '';
    
  }

  openViewImageModal(imageUrl: any) {
    let modalRef = this.ngbModal.open(ImagePreviewComponent, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.imageUrl = imageUrl
  }

  chooseFile(event: any) {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    let currentFile = event.target.files[0];

    if (!this.imageTypes.includes(currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      this._showErrorToast$.next(true);
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(currentFile);
    reader.onload = (e) => {
      let size = (currentFile.size! / 1024) / 1024;

      // Create New Image
      var newImage = new Image();
      newImage.src = e.target!.result as string;

      newImage.onload = (el) => {
        this.imageType = newImage.width > newImage.height ? 'Rectangle' : 'Portrait';
        if (size > 1) {
          var canvas = document.createElement("canvas");
          canvas.width = newImage.width;
          canvas.height = newImage.height;

          var ctx = canvas.getContext("2d");
          ctx?.drawImage(el.target as CanvasImageSource, 0, 0, canvas.width, canvas.height);
          var srcEncoded;
          if (size >= 5) {
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.1);
          } else {
            // size less then 5 MB
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.5);
          }

          ApplicationUtils.base64toFile(srcEncoded, currentFile!.name, currentFile!.type)
            .then((file: File) => {
              this.currentFile = file;
              this.imageFileName = file?.name as string;
              this.formGroup.controls['image'].patchValue(this.currentFile);
            })
        } else {
          this.currentFile = currentFile;
          this.imageFileName = currentFile?.name as string;
          this.formGroup.controls['image'].patchValue(this.currentFile);
        }

        this.imageUrl = reader.result?.toString();
      }
    }
  }

  removeMaterialImage() {
    this.imageFileName = '';
    this.imageUrl = '';
    this.currentFile = undefined;
    this.formGroup.controls['image'].reset();
    this.materialImage?.setAttribute('value', '');
  }

  handleValidSubmit() {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this.errorMsg = '';

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    if (!this.selectedPreferenceCategory) {
      return;
    }

    if (!this.checkNgTableDataList()) {
      this._showErrorToast$.next(true);
      this.errorMsg = 'Please fill all fields of Current Level, Safety Min, Safety Max';
      return;
    }

    if (this.currentFile) {
      this.uploadFile();
    } else {
      this.saveMaterial();
    }
  }

  uploadFile() {
    this.isLoading = true;
    this.fileUploadError = false;
    this.errorMsg = "";

    let metaData = {
      'mimeType': this.currentFile?.type,
      'version': 0,
      'publicApi': true,
      'dataType': this.imageType
    };

    let formData = new FormData();
    formData.append("file", this.currentFile!);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;

          let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;

          let currentFileInfoDto = new FileInfoDto();
          currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
          currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
          currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
          currentFileInfoDto.dataType = fileSaveResponseDto.dataType;
          currentFileInfoDto.uploadDate = new Date().toDateString();
          currentFileInfoDto.fileSize = this.currentFile?.size.toString();
          currentFileInfoDto.fileType = this.currentFile?.type;

          this.formGroup.get('image')?.setValue(currentFileInfoDto);
          this.formGroup.updateValueAndValidity();

          this.saveMaterial();
        }
      } else {
        this.isLoading = false;
        this.fileUploadError = true;
      }
    })
  }

  mergeMaterialUiDto() {
    let formValue = this.formGroup.getRawValue();

    let materialUiDto = new MaterialUiDto();

    if(this.selectedMaterialUiDto) {
      materialUiDto = ApplicationUtils.clone(this.selectedMaterialUiDto) as MaterialUiDto;
    }

    materialUiDto.orgCode = this.organizationUiDto?.orgCode;
    materialUiDto.materialId = formValue.materialId;
    materialUiDto.materialName = formValue.materialName;
    materialUiDto.description = formValue.description;
    materialUiDto.uom = formValue.uom;
    materialUiDto.qty = formValue.qty;
    materialUiDto.image = formValue.image;
    materialUiDto.preferenceCategoryDto = this.selectedPreferenceCategory;

    if (!materialUiDto.materialCompanyUiDtos) {
      materialUiDto.materialCompanyUiDtos = [];
    }

    this.ngTableDataList.forEach(ngTable => {
      let materialCompanyUiDto = new MaterialCompanyUiDto();

      let existsMaterialCompany = materialUiDto.materialCompanyUiDtos!.find(item => item.companyCode == ngTable.companyCode)
      if (existsMaterialCompany) {
        materialCompanyUiDto = ApplicationUtils.clone(existsMaterialCompany) as MaterialCompanyUiDto;
      }

      materialCompanyUiDto.companyCode = ngTable.companyCode;
      materialCompanyUiDto.companyName = ngTable.companyName;

      // Material Plants
      if (!materialCompanyUiDto.materialPlantUiDtos) {
        materialCompanyUiDto.materialPlantUiDtos = []
      }

      let materialPlantUiDto = new MaterialPlantUiDto();

      let existsMaterialPlant = materialCompanyUiDto.materialPlantUiDtos!.find(item => item.plantCode == ngTable.plantCode)
      if (existsMaterialPlant) {
        materialPlantUiDto = ApplicationUtils.clone(existsMaterialPlant) as MaterialPlantUiDto;
      }

      materialPlantUiDto.plantCode = ngTable.plantCode;
      materialPlantUiDto.plantName = ngTable.plantName;
      materialPlantUiDto.currentLevel = ngTable.currentLevel;
      materialPlantUiDto.safetyMin = ngTable.safetyMin;
      materialPlantUiDto.safetyMax = ngTable.safetyMax;

      materialCompanyUiDto.materialPlantUiDtos!.push(materialPlantUiDto);

      // Final Data
      let index = materialUiDto.materialCompanyUiDtos!.findIndex(item => item.companyCode == ngTable.companyCode)
      if (index > -1) {
        Object.assign(materialUiDto.materialCompanyUiDtos![index], materialCompanyUiDto)
      } else {
        materialUiDto.materialCompanyUiDtos!.push(materialCompanyUiDto);
      }
    })

    return materialUiDto;
  }

  saveMaterial() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let materialUiDto = this.mergeMaterialUiDto()

    this.adminDashboardService.saveMaterialDetails(materialUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let data = apiResponseDto.data as MaterialUiDto;
          this.adminDashboardService.updateMaterialUiDtos(data);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 1000)
        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this.isDataLoading = false;

        this._showErrorToast$.next(true);
        this.errorMsg = "Error while saving material details. Try again.";
      }
    })
  }

  checkNgTableDataList(): boolean {
    for (let data of this.ngTableDataList) {
      if (data.currentLevel == 0 || data.safetyMin == 0 || data.safetyMax == 0) {
        return false;
      }
    }
    return true;
  }

  ngOnDestroy(): void {
    if (this.plantsListSubscription$) {
      this.plantsListSubscription$.unsubscribe();
    }
    if (this.companiesSubscription$) {
      this.companiesSubscription$.unsubscribe();
    }
    if (this.materialsListSubscription$) {
      this.materialsListSubscription$.unsubscribe();
    }
  }
}
