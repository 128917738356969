<div>
  <div class="border rounded bg-white">
    <header class=" p-2">
      <div class="row ">
        <div class="col-md-6">
          <h5 class="heading-h5">BANK DETAILS</h5>
        </div>
        <div class="col-md-6 text-md-end ">
          <button class="btn btn-new-class btn-sm px-4 " (click)="openBankDetailModal(bidderBankDetails)" [disabled]="!isAllowToEdit()">
           {{isEdit? 'EDIT' : 'ADD' }}
          </button>
        </div>
      </div>
    </header>
    <hr class="hr" />
    <div class="col-12 mb-3 scroll-question">
      <div class=" div-class p-2">
       Account details are required for invoice management
      </div>

    <hr class="hr" />
    <div class="col-12 mb-3">
      <div class="col-12">

        <div class="row p-2">
          <div class="col-md-4  font-weight-normal py-1">
            Bank Name
          </div>
          <div class=" col-md-8 div-class py-1" >
            {{auctionEntityDto?.bankName}}
          </div>
          <div class="col-md-4  font-weight-normal py-1">
            Account Number
          </div>
          <div class="col-md-8 div-class py-1">
            {{auctionEntityDto?.accountNo}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<ng-template #bidderBankDetails>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-10 d-flex align-items-center">
          <h2>Bank Details</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation"  [formGroup]="formGroup" (keyup.enter)="saveBankDetails()">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Bank Name" formControlName="bankName">
          <label for="floatingInput">Bank Name</label>
          <div *ngIf="fc.bankName.invalid && (fc.bankName.dirty || fc.bankName.touched)">
            <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fc.bankName.errors?.required">Required</p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <input type="text" class="form-control"  placeholder="Account Number" formControlName="accountNo">
          <label for="floatingInput">Account Number</label>
          <div *ngIf="fc.accountNo.invalid && (fc.accountNo.dirty || fc.accountNo.touched)">
            <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fc.accountNo.errors?.required">Required</p>
            <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fc.accountNo.errors?.pattern">Invalid</p>
          </div>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Bank Details saved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="'Error while saving Bank Details. Try again.'"></app-message-toast>
        </div>

        <div class="mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class" (click)="saveBankDetails()" [disabled]="!isAllowToEdit()">SAVE</button>
        </div>

      </form>
    </div>
   </div>
  </div>
</ng-template>
