import { CatalogueService } from './../../../shared/services/catalogue.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CatalogueCartWrapperDto } from 'src/app/shared/models/CatalogueCartWrapperDto';
import { CatalogueL1WrapperDto } from 'src/app/shared/models/CatalogueL1WrapperDto';
import { CatalogueL2EntityDto } from 'src/app/shared/models/CatalogueL2EntityDto';
import { CatalogueLineItemsEntityDto } from 'src/app/shared/models/CatalogueLineItemsEntityDto';
import { CatalogueRequisitionWrapper } from 'src/app/shared/models/CatalogueRequisitionWrapper';
import { CatalogueVendorDto } from 'src/app/shared/models/CatalogueVendorDto';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';

@Component({
  selector: 'app-catalogue-requisition',
  templateUrl: './catalogue-requisition.component.html',
  styleUrls: ['./catalogue-requisition.component.sass']
})
export class CatalogueRequisitionComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  catalogueRequisitionWrappers?: CatalogueRequisitionWrapper[]
  selectedCatalogueRequisitionWrapper?: CatalogueRequisitionWrapper;

  constructor(
    private catalogueService: CatalogueService,
    private ngbModal: NgbModal,
    private dataRedirectionService: DashboardRedirectionService
  ) {}

  ngOnInit(): void {
    this.catalogueService.getRequisitionWrapperList$.subscribe(dataList =>{

      if(dataList && dataList.length > 0){
         this.catalogueRequisitionWrappers = dataList;
      }else{
        this.catalogueRequisitionWrappers = [];
      }

    })
  }
  
  markToApprove(){
    this.selectedCatalogueRequisitionWrapper!.isApproved = true;
    this.catalogueService.updateCatalogueRequisitionWrapper(this.selectedCatalogueRequisitionWrapper!);
    this.closeModal();
  }

  getLowestVendor(item: CatalogueLineItemsEntityDto): CatalogueVendorDto {
    if (item.catalogueVendors!.length > 1) {
      let sortedVendors = item.catalogueVendors!.sort((a, b) => Number(a.amount!) - Number(b.amount!));
      return sortedVendors[0];
    }
    return item.catalogueVendors![0];
  }

  openAllItemsModal(content: any, selectedCatalogueRequisitionWrapper?: CatalogueRequisitionWrapper) {
    this.selectedCatalogueRequisitionWrapper = selectedCatalogueRequisitionWrapper;
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openArcApprovalModal(catalogueRequisitionWrapper: CatalogueRequisitionWrapper, content: any) {
    this.selectedCatalogueRequisitionWrapper = catalogueRequisitionWrapper;
    this.ngbModal.open(content, {
      size: 'sm', backdrop: 'static', keyboard: false , centered: true
    });
  }

  navigateToApprovalPage() {
    this.dataRedirectionService.storeNavigationContext(
      ApplicationConstants.ADMIN,
      ApplicationConstants.APPROVED_PAGE
    );
    this.dataRedirectionService.setUpdateBreadcrumbs(true);
  }


  closeModal() {
    this.ngbModal.dismissAll();
  }
  ngOnDestroy(): void {

  }
}
