import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { UserAuctionEligibleStatus } from '../../enums/UserAuctionEligibleStatus';
import { UserAuctionRegistrationDto } from '../../models/user/UserAuctionRegistrationDto';
import { Subscription } from 'rxjs';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { DashboardRedirectionService } from '../../services/dashboard-redirection.service';
import { Router } from '@angular/router';
import { AdminSourcingEventsDataHolderService } from '../../services/AdminSourcingEventsDataHolder.service ';
import { SourcingEventDto } from '../../models/user/SourcingEventDto';
import { NotAwardAuctionDto } from '../../models/user/NotAwardAuctionDto';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';

@Component({
  selector: 'app-user-auction-registrations-notification',
  templateUrl: './user-auction-registrations-notification.component.html',
  styleUrls: ['./user-auction-registrations-notification.component.sass']
})
export class UserAuctionRegistrationsNotificationComponent implements OnInit, OnDestroy {
  awaitingApprovalsList: UserAuctionRegistrationDto[] = [];
  allAuctionsList: SourcingEventDto[] = [];
  auctionIdsList: string[] = [];
  notAwardAuctionDtos: Array<NotAwardAuctionDto> = [];

  userRegistrationsSubscription$?: Subscription;
  countPendingRegistration: number = 0;
  notAwardLotsCount: number = 0;
  isShowNotification: boolean = false;
  isShowRegistration: boolean = false;
  isShowNotAwardAuction?: boolean = false;


  constructor(
    private activeModal: NgbActiveModal,
    private adminDashboardService: AdminDashboardService,
    private adminSourcingEventService: AdminSourcingEventsDataHolderService,
    private dataRedirectionService: DashboardRedirectionService,
    private router: Router,
    private ngbModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.adminDashboardService.getAllUserAuctionRegistrationsList$.subscribe((data) => {
      if (data) {
        this.awaitingApprovalsList = data.filter(item => item.userAuctionRegistrationStatus == UserAuctionEligibleStatus.AWAITING_APPROVAL);
        this.auctionIdsList = this.awaitingApprovalsList.map(item => item.auctionId!);
        this.auctionIdsList = [...new Set(this.auctionIdsList)];
        this.countPendingRegistration = this.awaitingApprovalsList.length;
        if(this.countPendingRegistration > 0){
          this.adminSourcingEventService.setNotificationAvailable(true);
        }
      }
    })

    this.adminSourcingEventService.getAllEventsList$.subscribe((data) => {
      if (data) {
        this.allAuctionsList = data;
      }
    })

    this.adminSourcingEventService.getShowNotification$.subscribe((isShowNotification) => {
      this.isShowNotification = isShowNotification;
      this.isShowRegistration = isShowNotification
      this.isShowNotAwardAuction = isShowNotification;

    });
  }

  openPendingRegistrationsModal(content: any) {
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    })
  }

  openNotAwardAuctionModal(content: any) {
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    })
  }

  getAuction(auctionId: string): SourcingEventDto {
    let auction = this.allAuctionsList.find(item => item.eventId == auctionId);
    return auction!;
  }

  getRegistrationCount(auctionId: string) {
    let awaitingApprovalsList = [...this.awaitingApprovalsList];
    let registrations = awaitingApprovalsList.filter(item => item.auctionId == auctionId);
    return registrations.length ?? 0;
  }

  navigateToPostAuction(auctionId: string) {
    this.toggleSidebarView();
    let auction = this.adminSourcingEventService.allEventsList$.value.find(item => item.eventId == auctionId) as AuctionEntityDto;
    this.adminDashboardService._notAwardedAuctionNotification$.next(auction)
    this.ngbModal.dismissAll();
  }

  toggleSidebarView(){
   let selectedAuction = this.adminSourcingEventService.getSelectedSourcingEvent();
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.AUCTION_CREATOR_PAGE,
        ApplicationConstants.POST_AUCTION_TAB,
        `${selectedAuction?.sequenceNo}`
      );
      this.dataRedirectionService.setUpdateBreadcrumbs(true);
  }

  // async navigateToRegistration(auctionId: string) {
  //   this.auctionService.setSelectedAuctionByAuctionId(auctionId);
  //   await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(auctionId!);
  //   this.adminDashboardService.loadBidderRegistrationList(auctionId);

  //   this.activeModal.close();

  //   this.dataRedirectionService.setCurrentPageInSession(AuctionExtConstant.ADMIN_DASHBOARD_REGISTRATION, auctionId);
  //   this.dataRedirectionService.setSubNavigation(AuctionExtConstant.ADMIN_DASHBOARD_REGISTRATION);
  //   this.router.navigate(['creator'], { skipLocationChange: true });
  // }

  async navigateToRegistration(eventNo: number) {
    // await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(auction.auctionId!);

    this.dataRedirectionService.storeNavigationContext(
      ApplicationConstants.ADMIN,
      ApplicationConstants.AUCTION_CREATOR_PAGE,
      ApplicationConstants.REGISTRATION_TAB,
      eventNo.toString(),
      undefined
    );

    this.router.navigate(['/redirectToAuction'], { skipLocationChange: true });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  closeRegistrationNotification(){
    this.isShowRegistration = false;
  }

  closeNotAwardNotification(){
    this.isShowNotAwardAuction = false;
  }

  ngOnDestroy(): void {
    if (this.userRegistrationsSubscription$) {
      this.userRegistrationsSubscription$.unsubscribe();
    }
  }
}
