<div class="bg-white-new">
  <div class="position-relative mx-2 mb-3">
    <div class="col-12 container mt-4 px-0">
      <div class=" nav-bg text-start" >
        <ul class="" style="list-style: none;" class="ps-0">
          <li class="list-class" (click)="toggleSidebarView('ONBOARDING_ORG_PROFILE')" [ngClass]="this.selectedSidebarView == 'ONBOARDING_ORG_PROFILE' ? 'btn-active' : 'btn-default'">
            Org Profile
          </li>
          <li class="list-class" (click)="toggleSidebarView('ONBOARDING_COMPANIES')" [ngClass]="this.selectedSidebarView == 'ONBOARDING_COMPANIES' ? 'btn-active' : 'btn-default'">
            Companies
          </li>
          <li class="list-class"  (click)="toggleSidebarView('ONBOARDING_PLANT')" [ngClass]="this.selectedSidebarView == 'ONBOARDING_PLANT' ? 'btn-active' : 'btn-default'">
           Plant
          </li>
          <li class="list-class"  (click)="toggleSidebarView('ONBOARDING_USERS')" [ngClass]="this.selectedSidebarView == 'ONBOARDING_USERS' ? 'btn-active' : 'btn-default'">
            Users
          </li>
          <li class="list-class"  (click)="toggleSidebarView('RULE_MATA_DATA')" [ngClass]="this.selectedSidebarView == 'RULE_MATA_DATA' ? 'btn-active' : 'btn-default'">
            Approvals 
          </li>
          <!-- <li class="list-class"  (click)="toggleSidebarView('SETTING_PAGE')" [ngClass]="this.selectedSidebarView == 'SETTING_PAGE' ? 'btn-active' : 'btn-default'">
            Other
          </li> -->
        </ul>
      </div>
  
    </div>

    <!-- <div class="col-12">
      <div class="row">
        <div class="col-md-1 bg-white border px-0 d-none d-md-inline-block fixed-sidebar-class ">
          <ul style="list-style: none;" class="ps-1 text-center h-100">
            <li class="" (click)="toggleSidebarView('ONBOARDING_ORG_PROFILE')"
              [ngClass]="this.selectedSidebarView == 'ONBOARDING_ORG_PROFILE' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/InHouse-Users-Icon.svg" class="svg-icon-class-small c-pointer mb-1">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Org Profile
              </span>
            </li>
            <hr class="hr-light">
            <li class="" (click)="toggleSidebarView('ONBOARDING_COMPANIES')"
              [ngClass]="this.selectedSidebarView == 'ONBOARDING_COMPANIES' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/Home-Icon.svg" class="svg-icon-class-small c-pointer mb-1">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Companies
              </span>
            </li>
            <hr class="hr-light">
            <li class="" (click)="toggleSidebarView('ONBOARDING_PLANT')"
              [ngClass]="this.selectedSidebarView == 'ONBOARDING_PLANT' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/Catalogue.svg" class="svg-icon-class-small c-pointer mb-1">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Plant
              </span>
            </li>
            <hr class="hr-light">
            <li class="" (click)="toggleSidebarView('ONBOARDING_USERS')"
              [ngClass]="this.selectedSidebarView == 'ONBOARDING_USERS' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/Catalogue.svg" class="svg-icon-class-small c-pointer mb-1">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Plant
              </span>
            </li>
            <hr class="hr-light">
          </ul>
        </div>

        <div class="col-md-11 col-12  bg-white-new margin-left-class">
          <!-- <app-breadcrumb></app-breadcrumb> 
          <div class="row d-block w-100 d-md-none border p-2 bg-white-new shadow sidebar-position">
            <div class=" col-12">
              <ul style="list-style: none;" class="ps-1 text-center mb-0">
                <li class="list-style-class list-padding-mobile" (click)="toggleSidebarView('ONBOARDING_ORG_PROFILE')"
                  [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'ONBOARDING_ORG_PROFILE'}">
                  <div>
                    <img src="assets/icons/InHouse-Users-Icon.svg" class="svg-icon-class-md c-pointer mb-1">
                  </div>
                  <span class="small-grey-font">
                    Org Profile
                  </span>
                </li>
                <li class="list-style-class list-padding-mobile" (click)="toggleSidebarView('ONBOARDING_COMPANIES')"
                  [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'ONBOARDING_COMPANIES'}">
                  <div>
                    <img src="assets/icons/Home-Icon.svg" class="svg-icon-class c-pointer mb-1">
                  </div>
                  <span class="small-grey-font">
                   Companies
                  </span>
                </li>
                <li class="list-style-class list-padding-mobile" (click)="toggleSidebarView('ONBOARDING_PLANT')"
                [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'ONBOARDING_PLANT'}">
                  <div>
                    <img src="assets/icons/Catalogue.svg" class="svg-icon-class c-pointer mb-1">
                  </div>
                  <span class="small-grey-font">
                    Plant
                  </span>
                </li>
                <li class="list-style-class list-padding-mobile" (click)="toggleSidebarView('ONBOARDING_USERS')"
                [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'ONBOARDING_USERS'}">
                  <div>
                    <img src="assets/icons/Catalogue.svg" class="svg-icon-class c-pointer mb-1">
                  </div>
                  <span class="small-grey-font">
                    Users
                  </span>
                </li>
              </ul>
            </div>
          </div>

      

        </div>
      </div>

    </div> -->

  </div>
  <div >
    <ng-container [ngTemplateOutlet]="selectedSidebarView == 'ONBOARDING_ORG_PROFILE' ? orgProfile
    : selectedSidebarView == 'ONBOARDING_COMPANIES' ? companies
    : selectedSidebarView == 'ONBOARDING_USERS' ? users
    : selectedSidebarView == 'RULE_MATA_DATA' ? rule
    : selectedSidebarView == 'ONBOARDING_PLANT' ? plant: empty">
    </ng-container>
  </div>
</div>



<ng-template #orgProfile>
  <app-onboarding-org-profile></app-onboarding-org-profile>
</ng-template>
<ng-template #companies>
  <app-onboarding-companies (onNavigateInternalPage)="toggleSidebarView($event)"></app-onboarding-companies>
</ng-template>
<ng-template #plant>
  <app-onboarding-plants></app-onboarding-plants>
</ng-template>
<ng-template #users>
  <app-onboarding-users></app-onboarding-users>
</ng-template>

<ng-template #rule>
  <app-rule-mata-data></app-rule-mata-data>
</ng-template>
<!-- Empty Template -->
<ng-template #empty></ng-template>