<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Reset Password</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModalAndLogout()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-2">
        <span>As you have logged in first time, you must change your password to proceed.&nbsp;</span>
        <button type="button" class="btn btn-link" (click)="closeModalAndLogout()">Logout</button>
      </div>
      <div class="row">

        <div class="col-12">
          <div class="m-2">
            <div class="m-2">
              <form [formGroup]="formGroup" autocomplete="off" class="needs-validation" novalidate
                (keyup.enter)="changePassword()">
                <div class="col-12">
                  <div class="input-group">
                    <div class="form-floating">
                      <input #oldPassword [type]="isOldPasswordVisible ? 'text' : 'password'" class="form-control border-end-0" id="oldPassword"
                        placeholder="Old Password" formControlName="oldPassword" autocomplete="new-password"
                        [ngClass]="{'is-invalid': isSubmitted && fc.oldPassword.invalid}">
                      <label for="oldPassword">Old Password</label>
                    </div>
                    <span class="input-group-text bg-white border-start-0"
                    [ngClass]="{'focus-border': (_isOldPasswordFocused$|async)}"
                     (click)="toggleOldPasswordVisibility()">
                      <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer"
                        *ngIf="!isOldPasswordVisible">
                      <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer"
                        *ngIf="isOldPasswordVisible">
                    </span>
                  </div>
                  <div *ngIf="isSubmitted || fc.oldPassword.invalid && (fc.oldPassword.dirty || fc.oldPassword.touched)">
                    <p class="text-danger" *ngIf="fc.oldPassword.errors?.required">Required</p>
                  </div>
                </div>
                <div class="input-group mt-3">
                  <div class="form-floating">
                    <input #newPassword [type]="isNewPasswordVisible ? 'text' : 'password'" class="form-control border-end-0" id="new-password"
                      formControlName="newPassword" id="newPassword" autocomplete="new-password" (keyup)="onChange()" (change)="onChange()"
                      [ngClass]="{'is-invalid': isSubmitted && fc.newPassword.invalid}" />
                    <label for="newPassword">New Password</label>
                  </div>
                  <span class="input-group-text bg-white border-start-0" [ngClass]="{'focus-border': (_isNewPasswordFocused$|async)}"
                   (click)="toggleNewPasswordVisibility()">
                    <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer"
                      *ngIf="!isNewPasswordVisible">
                    <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer"
                      *ngIf="isNewPasswordVisible">
                  </span>
                </div>
                <div *ngIf="isSubmitted || fc.newPassword.invalid && (fc.newPassword.dirty || fc.newPassword.touched)">
                  <p class="text-danger" *ngIf="fc.newPassword.errors?.required">
                    Required</p>
                  <p class="text-danger" *ngIf="fc.newPassword.invalid &&  (fc.newPassword.value)">
                    Password must be more than 8 characters, containing at least one capital letter, one lowercase letter,
                    one number and one special character.</p>
                </div>
                <div class="input-group mt-3">
                  <div class="form-floating">
                    <input #repeatPassword [type]="isRepeatPasswordVisible ? 'text' : 'password'" class="form-control border-end-0" id="retype-password"
                      formControlName="repeatPassword" autocomplete="new-password" id="repeatPassword"
                      [ngClass]="{'is-invalid': isSubmitted && fc.repeatPassword.invalid}" (keyup)="onChange()" (change)="onChange()"  />
                    <label for="repeatPassword">Retype New Password</label>
                  </div>
                  <span class="input-group-text bg-white border-start-0" (click)="toggleRepeatPasswordVisibility()"
                  [ngClass]="{'focus-border': (_isRepeatPasswordFocused$|async)}">
                    <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer"
                      *ngIf="!isRepeatPasswordVisible">
                    <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer"
                      *ngIf="isRepeatPasswordVisible">
                  </span>
                </div>
                <div class="mb-3"
                  *ngIf="isSubmitted || fc.repeatPassword.invalid && (fc.repeatPassword.dirty || fc.repeatPassword.touched)">
                  <p class="text-danger" *ngIf="fc.repeatPassword.errors?.required">Required</p>
                </div>
                <div class="mb-3">
                  <p class="text-danger" *ngIf="!fc.repeatPassword.invalid && !(_isPasswordMatch$ | async)">
                    Password Not Match
                  </p>
                </div>

                <div class="mb-3">
                  <app-message-toast *ngIf="_showSuccessToast$ | async"
                    [message]="'Password changed successfully'"></app-message-toast>
                  <app-message-toast *ngIf="(_showErrorToast$ | async)" [isError]="true"
                    [message]="errorMsg"></app-message-toast>
                </div>

                <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
                  <div class="e-spinner" *ngIf="isLoading"></div>
                </div>

                <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
                  <button type="button" class="btn btn-new-class" (click)="changePassword()">CHANGE PASSWORD</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
