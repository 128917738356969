import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { NgbDropdown,} from '@ng-bootstrap/ng-bootstrap';
import { Timestamp } from '@angular/fire/firestore';
import { TemplateRef} from '@angular/core';
import { DeploymentConfigurationDto } from 'src/app/shared/models/user/DeploymentConfigurationDto';
import { PrivacyAndTermsOfUseDto } from 'src/app/shared/models/PrivacyAndTermsOfUseDto';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { AuctionTimelineComponent } from 'src/app/layouts/admin/auction-timeline/auction-timeline.component';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { HtmlViewComponent } from 'src/app/shared/components/html-view/html-view.component';
import { AuctionManageLegalPolicyComponent } from '../../auction/auction-manage-legal-policy/auction-manage-legal-policy.component';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';
import { RfxValidationService } from 'src/app/shared/services/rfx-validation.service';
import { RfxValidationErrorCodeDto } from 'src/app/shared/models/user/RfxValidationErrorCodeDto';
import { RfxEnableWrapperDto } from 'src/app/shared/models/rfx/RfxEnableWrapperDto';
import { SubcategoryErrorValidationComponent } from 'src/app/shared/components/subcategory-error-validation/subcategory-error-validation.component';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { OrganizationUiDto } from 'src/app/shared/models/OrganizationUiDto';

@Component({
  selector: 'app-rfx-creator-setup',
  templateUrl: './rfx-creator-setup.component.html',
  styleUrls: ['./rfx-creator-setup.component.sass']
})
export class RfxCreatorSetupComponent implements OnInit, OnDestroy {

  rfxEntityDto? : RfxUiDto;
  organizationUiDto? : OrganizationUiDto;
  isLoading?: boolean = false;
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');
  rfxValidationErrorCodeDtoList?: RfxValidationErrorCodeDto[];
  selectedRfxSubscription$?: Subscription;
  auctionHouseSubscription$?: Subscription;
  rfxEnableStatus: boolean = false;
  buyItNowStatus: boolean = false;

  isShowConfidential$ = new BehaviorSubject<boolean>(false);
  isShowTermsConfidential$ = new BehaviorSubject<boolean>(false);
  rfxDiscardModalRef!: NgbModalRef;
  activateModalRef!: NgbModalRef;
  legalModalRef!: NgbModalRef;
  errorModalRef!: NgbModalRef;
  _isRfxInfoHide$= new BehaviorSubject<boolean>(false);
  noOfActiveSubcategories: number = 0;

  isShowCloseModalButton: boolean = false;

  deploymentConfigurationDtoSubscription$?: Subscription;
  deploymentConfigurationDto?: DeploymentConfigurationDto;

  constructor(
    private rfxValidationService: RfxValidationService,
    private adminService: AdminDashboardService,
    private adminSourcingEventsDataHolderService : AdminSourcingEventsDataHolderService,
    private ngbModal: NgbModal,
    public translate: TranslateService,
    private dashboardRedirectionService: DashboardRedirectionService,
    private onboardingService: OnboardingService,
  ) { }

  ngOnInit(): void {
    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(rfx => {
      if (rfx) {
        this.rfxEntityDto = rfx;
      }
    })

    this.deploymentConfigurationDtoSubscription$ = this.adminService.getDeploymentConfiguration$.subscribe((data) => {
      if (data) {
        this.deploymentConfigurationDto = data;
      }
    });

    this.auctionHouseSubscription$ = this.onboardingService.getOrganizationUiDto$.subscribe(data => {
      if (data) {
        this.organizationUiDto = data;
      }
    })
  }

  openActivateModal(content: any, refType: string) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.noOfActiveSubcategories = this.rfxEntityDto?.noOfActiveSubcategories!;

    if (refType == 'RFX') {
      this.rfxEnableStatus = this.rfxEntityDto?.active!;
    }

    this.activateModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });

    // this.activateModalRef.result.then(() => {
    //   if (refType == 'RFX') {
    //     toggleRef.checked = this.rfxEnableStatus;
    //   } else {
    //     toggleRef.checked = this.buyItNowStatus;
    //   }
    // })
  }

  getStatus(status: string){
    if (status == RfxStatus.DRAFT) {
      return "DRAFT";
    }else if(status == RfxStatus.LIVE_WAIT){
      return "UPCOMING";
    }else if(status == RfxStatus.LIVE){
      return "RUNNING";
    }else if(status == RfxStatus.CLOSE){
      return "CLOSED ";
    }
    return null;
  }

  getClassBasedOnStatus(status: string) {
    if (status == RfxStatus.DRAFT) {
      return "badge-draft-class";
    }else if(status == RfxStatus.LIVE_WAIT){
      return "badge-warning-class";
    }else if(status == RfxStatus.LIVE){
      return "badge-primary-class";
    }else if(status == RfxStatus.CLOSE){
      return "badge-danger-class";
    }
    else if(status == RfxStatus.DISCARD){
      return "btn-dark";
    }
    return null;
  }

  openRfxTimeline() {
    let modalRef =  this.ngbModal.open(AuctionTimelineComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.selectedAuction = this.rfxEntityDto;
  }

  changeRfxInfoHideOrShow(){
    this._isRfxInfoHide$.next(!this._isRfxInfoHide$.value)
  }

  openLegalModal() {
    this.legalModalRef = this.ngbModal.open(AuctionManageLegalPolicyComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openErrorModel() {
    this.errorModalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
    this.errorModalRef.componentInstance.auctionValidationErrorCodeDtoList = this.rfxValidationErrorCodeDtoList;
  }

  openRfxDiscardModel(content: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.rfxDiscardModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  closeModal(modalRef: NgbModalRef) {
    this._errorMsg$.next('');
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this.isLoading = false;
    this.isShowCloseModalButton = false;
    modalRef.close();
  }

  checkRfxSubcategoryValidationAndMarkActive(action: string) {
    this._errorMsg$.next('');
    this._showErrorToast$.next(false);

    let errorDataList = this.rfxValidationService.doRfxAndRfxSubcategoryValidation()
    let rfxErrors = errorDataList.filter(item => item.type == 'RFX' || item.type == 'QUESTIONNAIRE');

    if (rfxErrors.length > 0) {
      this.rfxValidationErrorCodeDtoList = rfxErrors;
      this.openErrorModel();
      return;
    }

    let validationModalRef = this.ngbModal.open(SubcategoryErrorValidationComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
    validationModalRef.componentInstance.errorDtoList = errorDataList;
    validationModalRef.componentInstance.onSubmit.subscribe((rfxSubcategoryIdsList: any) => {
      if (rfxSubcategoryIdsList && rfxSubcategoryIdsList.length > 0) {
        this.markRfxActiveOrInActive(action, rfxSubcategoryIdsList)
        validationModalRef.close();
      }
    })
  }

  markRfxActiveOrInActive(action: string, rfxSubcategoryIdsList?: string[]) {
    // if(errorDataList && errorDataList.length > 0){
    //   this.auctionValidationErrorCodeDtoList = errorDataList;
    //   this.closeModal(this.activateModalRef);
    //   this.openErrorModel(content);
    //   return;
    // }
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this._errorMsg$.next('');
    this.isLoading = true;

    let active = this.rfxEntityDto?.active;
    if (action == "ENABLE") {
      active = true;
    } else {
      active = false;
    }
    let rfxEntityDto = ApplicationUtils.clone(this.rfxEntityDto);
    rfxEntityDto!.active = active;

    let rfxEnableWrapper = new RfxEnableWrapperDto();
    rfxEnableWrapper.rfxUiDto = rfxEntityDto;
    rfxEnableWrapper.subcategoryIds = rfxSubcategoryIdsList;

    this.adminService.markRfxActiveOrInActive(rfxEnableWrapper).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {

          let rfxValidationErrorCodeDtoList =  apiResponseDto.data as RfxValidationErrorCodeDto[];
          if(!rfxValidationErrorCodeDtoList ||  rfxValidationErrorCodeDtoList.length == 0){
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.rfxEnableStatus = active!;
              this.closeModal(this.activateModalRef);
            }, 2000)
          }else{
            this.rfxValidationErrorCodeDtoList = rfxValidationErrorCodeDtoList;
            this.openErrorModel();
            this.isLoading = false;
          }
        } else {
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (err) => {
        console.error(err);
          this.isLoading = false;
          this._errorMsg$.next(`Error while ${active ? 'enabling' : 'disabling'} Rfx. Try again.`);
      }
    })
  }

  navigateToHomePage() {
    this.dashboardRedirectionService.storeNavigationContext(
      ApplicationConstants.ADMIN, 'SOURCING_PAGE'
    );
    this.dashboardRedirectionService.setUpdateBreadcrumbs(true);
  }

  discardRfx() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._errorMsg$.next('');
    this.isLoading = true;
    this._showErrorToast$.next(false);
    this.isShowCloseModalButton = false;

    this.adminService.rfxDiscard(this.rfxEntityDto?.rfxId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          this.navigateToHomePage();
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.rfxDiscardModalRef);
          }, 2000)

        } else {
          if (apiResponseDto.code == 'RFX-ERROR-115') {
            this.isShowCloseModalButton = true;
          }
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while discarding Rfx. Try again.");
      }
    })
  }

  openViewModal(title: string, content: string) {
    let modalRef = this.ngbModal.open(HtmlViewComponent, { size: 'md', backdrop: 'static', keyboard: false , centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.content = content;
  }

  truncateText(input?: string) {
    if (input) {
      let formattedInput = input.replace(/<\/?[^>]+(>|$)/g, "");
      formattedInput = formattedInput.replace(/&#[0-9]+;/g, "");
      formattedInput = formattedInput.replace(/&nbsp;/g, ' ');
      formattedInput = formattedInput.replace(/&amp;/g, '&');
      return ApplicationUtils.truncateString(formattedInput, 101);
    }
    return '';
  }

  getErrorMsg(error: RfxValidationErrorCodeDto) {
    return ApplicationUtils.populateMessage(error, this.translate)
  }

  ngOnDestroy(): void {
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }
    if (this.deploymentConfigurationDtoSubscription$) {
      this.deploymentConfigurationDtoSubscription$.unsubscribe();
    }
    if (this.auctionHouseSubscription$) {
      this.auctionHouseSubscription$.unsubscribe();
    }
  }

  convertNumberToWords(num: number) {
    return ApplicationUtils.convertNumberToWords(num)
  }

  openRfxCardMenu(event: any, dropdown: NgbDropdown) {
    event.stopPropagation();
    dropdown.isOpen() ? dropdown.open() : dropdown.close();
  }
  

  async navigateToGraphGeneratePage() {
    // this.adminSourcingEventsDataHolderService.setSelectedRfx(this.rfxEntityDto!);
    // await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(this.rfxEntityDto!.rfxId!);
    // this.dataRedirectionService.storeNavigationContext(
    //   ApplicationConstants.ADMIN,
    //   ApplicationConstants.AUCTION_GRAPH_PAGE
    // );
  }

  navigateToAnalyticsDashboardPage() {
    // this.adminSourcingEventsDataHolderService.setSelectedRfx(this.rfxEntityDto!);
    // this.dataRedirectionService.storeNavigationContext(
    //   ApplicationConstants.ADMIN,
    //   ApplicationConstants.AUCTION_ANALYTICS_DASHBOARD_PAGE
    // );
  }

  isShowRfxDashboardMenu() {
    if (this.rfxEntityDto &&
      (this.rfxEntityDto!.status == RfxStatus.LIVE || this.rfxEntityDto!.status == RfxStatus.CLOSE) &&
      this.rfxEntityDto!.noOfActiveSubcategories! > 1
    ) {
      return true;
    }
    return false;
  }


  getMetadataDate(timestamp?: Timestamp | null) {
    if (timestamp) {
      let createdDate = timestamp.toDate();
      let date = createdDate.toDateString();
      let time = createdDate.toTimeString();
      return ApplicationUtils.getDisplayDateAdmin(date, time);
    }
    return '';
  }




  closeAllModal() {
    this.ngbModal.dismissAll();
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.rfxEntityDto?.status == RfxStatus.LIVE_WAIT && !this.rfxEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.rfxEntityDto?.status == RfxStatus.LIVE && !this.rfxEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}

