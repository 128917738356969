<div class="container">
  <div class="">
    <div class="p-2">
      <div class="row align-items-center">
        <div class="col-md-3  text-start mb-2">
          <div class="mb-2 input-group">
            <input #search placeholder="Search" type="text" id="ctrlSearchPR" [formControl]="ctrlSearch"
              class="form-control border-end-0 rounded-0 border bg-white" />
            <span class="input-group-append">
              <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
              </button>
            </span>
          </div>
        </div>
        <div class="col-md-5 text-start mb-2">
        </div>
        <div class="col-md-4 mb-2 text-md-end">
          <button class="btn btn-sm btn-new-class mt-2 mt-sm-0" *ngIf="isShowPlantView" (click)="backToAllPlantsList()">
            BACK
          </button>
          <!-- <button class="btn btn-sm btn-new-class mt-2 mt-sm-0" (click)="openPlantDetail(plantDetail)"> ADD
          </button> -->
        </div>
      </div>
      <div class="col-12">
        <ng-container *ngTemplateOutlet="isShowPlantView ? plantDetailsTemplate : plantsListTemplate">
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #plantsListTemplate>
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-bordered medium-font mb-0 align-middle">
        <thead class="table-dark">
          <tr class="text-center">
            <th class="text-center"><span>SNo</span></th>
            <th class=""><span>Company Code</span></th>
            <th class=""><span>Plant Name</span></th>
            <th class=""><span>Action</span></th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr *ngIf="isDataLoading" class="align-middle">
            <td colspan="10" class="text-center">
              <div class="e-spinner"></div>
            </td>
          </tr>
          <tr class="text-center align-middle" *ngFor="let item of _filteredPlantEntityDtos$ | async ; index as i">
            <td class="">
              {{i+1}}
            </td>
            <td class="">
              {{item.companyCode}}
            </td>
            <td class="">
              {{item.plantName}}
            </td>
            <td class="">
              <a class="link-class" (click)="viewPlantDetails(item)">
                View
              </a>
            </td>
          </tr>
          <tr *ngIf="!isDataLoading && plantUiDtos.length == 0">
            <td colspan="10" class="text-center">
              <h5 class="heading-new">No Data Found</h5>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #plantDetailsTemplate>
  <div class="container  p-3">
    <div class="col-12">
      <div class="border rounded bg-white mb-3">
        <header class="p-2">
          <div class="row ">
            <div class="col-md-6">
              <h5 class="heading-h5">PLANT DETAILS</h5>
            </div>
            <div class="col-md-6 text-md-end">
              <button class="btn btn-new-class btn-sm px-4 " (click)="openPlantDetail(editPlantDetail)">
                EDIT
              </button>
            </div>
          </div>
        </header>
        <hr class="hr" />
        <div class="col-12">
          <div class="row mx-0">
            <div class="col-12 div-class py-2">
              Plant Information
            </div>
            <hr class="hr" />
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-4 font-weight-normal py-2">
                    Plant Name
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedPlantUiDto?.plantName}}
                  </div>
                  <hr class="hr" />

                  <div class="col-4 font-weight-normal py-2">
                    Account Title
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedPlantUiDto?.accountTitle}}
                  </div>
                  <hr class="hr" />

                  <div class="col-4 font-weight-normal py-2">
                    Account Holder Name
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedPlantUiDto?.accHolderName}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Account Code
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedPlantUiDto?.accCode}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Swift
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedPlantUiDto?.swift}}
                  </div>

                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-4 font-weight-normal py-2">
                    Plant ID
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedPlantUiDto?.plantCode}}
                  </div>
                  <hr class="hr" />

                  <div class="col-4 font-weight-normal py-2">
                    Account Number
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedPlantUiDto?.accNumber}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2 ">
                    Account Type
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedPlantUiDto?.accType}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Iban
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedPlantUiDto?.iban}}
                  </div>
                  <hr class="hr" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-3">
          <div class="border rounded bg-white mb-3">
            <header class="p-2">
              <div class="row ">
                <div class="col-md-6">
                  <h5 class="heading-h5">BILLING ADDRESS</h5>
                </div>
                <div class="col-md-6 text-md-end">
                  <!-- <button class="btn btn-new-class btn-sm px-4 " (click)="openAddBillingAddress(billingAddress)">
                    ADD
                  </button> -->
                </div>
              </div>
            </header>
            <hr class="hr" />
            <div class=" p-2 mb-1">
              <div class="row mx-0">
                <div class="col-12 div-class py-2">
                  Billing Address Info SNO.
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Title
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedBillingAddress?.addressType}}
                </div>
                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Address Line 1
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedBillingAddress?.addressLine1}}
                </div>
                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Address Line 2
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedBillingAddress?.addressLine2}}
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  City
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedBillingAddress?.city}}
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  State
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedBillingAddress?.state}}
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Country
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedBillingAddress?.country}}
                </div>

                <hr class="hr" />
                <div class="bg-secondary text-center border rounded" [ngClass]="{'py-5': billingLatitude == undefined}">
                  <google-map height="32vh" width="100%" [options]="options" [center]="positionBilling!" [zoom]="zoom"
                    *ngIf="billingLatitude != undefined">
                    <map-marker #billingMarker="mapMarker" [position]="positionBilling!" [title]="markerBilling.title"
                      [options]="markerBilling.options" (mapClick)="openMarkerInfo(billingMarker)">
                    </map-marker>
                    <map-info-window>{{ markerBilling.info }}</map-info-window>
                  </google-map>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="border rounded bg-white mb-3">
            <header class="p-2">
              <div class="row ">
                <div class="col-md-6">
                  <h5 class="heading-h5">SHIPPING ADDRESS</h5>
                </div>
                <div class="col-md-6 text-md-end">
                  <!-- <button class="btn btn-new-class btn-sm px-4 " (click)="openAddShippingAddress(shippingAddress)">
                    ADD
                  </button> -->
                </div>
              </div>
            </header>
            <hr class="hr" />
            <div class="p-2 mb-1">
              <div class="row mx-0">
                <div class="col-12 div-class py-2">
                  Billing Address Info SNO.
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Title
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedShippingAddress?.addressType}}
                </div>
                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Address Line 1
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedShippingAddress?.addressLine1}}
                </div>
                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Address Line 2
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedShippingAddress?.addressLine2}}
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  City
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedShippingAddress?.city}}
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  State
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedShippingAddress?.state}}
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Country
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedShippingAddress?.country}}
                </div>

                <hr class="hr" />
                <div class="bg-secondary text-center border rounded"
                  [ngClass]="{'py-5': shippingLatitude == undefined}">
                  <google-map height="32vh" width="100%" [options]="options" [center]="positionShipping!" [zoom]="zoom"
                    *ngIf="shippingLatitude != undefined">
                    <map-marker #shippingMarker="mapMarker" [position]="positionShipping!"
                      [title]="markerShipping.title" [options]="markerShipping.options"
                      (mapClick)="openMarkerInfo(shippingMarker)">
                    </map-marker>
                    <map-info-window>{{ markerShipping.info }}</map-info-window>
                  </google-map>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</ng-template>

<ng-template #editPlantDetail>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Plant Detail</h2>
        </div>
        <div class="col-auto text-end">
          <span [ngClass]="{'disabled' : isLoading }">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
              (click)="closeModal()"></span>
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="saveOnboardingPlants()">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="plantName" formControlName="plantName"
              [ngClass]="{'is-invalid': fc.plantName.invalid && (fc.plantName.dirty || fc.plantName.touched)}">
            <label for="plantName">Plant Name</label>
            <div class="invalid-feedback" *ngIf="fc.plantName.invalid && (fc.plantName.dirty || fc.plantName.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.plantName.errors?.required">Required</p>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="plantCode" formControlName="plantCode"
              [ngClass]="{'is-invalid': fc.plantCode.invalid && (fc.plantCode.dirty || fc.plantCode.touched)}">
            <label for="plantCode">Plant Id</label>
            <div class="invalid-feedback" *ngIf="fc.plantCode.invalid && (fc.plantCode.dirty || fc.plantCode.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.plantCode.errors?.required">Required</p>
            </div>
          </div>
          <div class="row">

            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="accountTitle" formControlName="accountTitle"
                  [ngClass]="{'is-invalid': fc.accountTitle.invalid && (fc.accountTitle.dirty || fc.accountTitle.touched)}">
                <label for="accountTitle">Account Title</label>
                <div class="invalid-feedback"
                  *ngIf="fc.accountTitle.invalid && (fc.accountTitle.dirty || fc.accountTitle.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.accountTitle.errors?.required">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="accNumber" formControlName="accNumber"
                  [ngClass]="{'is-invalid': fc.accNumber.invalid && (fc.accNumber.dirty || fc.accNumber.touched)}">
                <label for="accNumber">Account Number</label>
                <div class="invalid-feedback"
                  *ngIf="fc.accNumber.invalid && (fc.accNumber.dirty || fc.accNumber.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.accNumber.errors?.required">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="accHolderName" formControlName="accHolderName"
                  [ngClass]="{'is-invalid': fc.accHolderName.invalid && (fc.accHolderName.dirty || fc.accHolderName.touched)}">
                <label for="accHolderName">Account Holder Name</label>
                <div class="invalid-feedback"
                  *ngIf="fc.accHolderName.invalid && (fc.accHolderName.dirty || fc.accHolderName.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.accHolderName.errors?.required">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="accType" formControlName="accType"
                  [ngClass]="{'is-invalid': fc.accType.invalid && (fc.accType.dirty || fc.accType.touched)}">
                <label for="accType">Account Type</label>
                <div class="invalid-feedback" *ngIf="fc.accType.invalid && (fc.accType.dirty || fc.accType.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.accType.errors?.required">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="accCode" formControlName="accCode"
                  [ngClass]="{'is-invalid': fc.accCode.invalid && (fc.accCode.dirty || fc.accCode.touched)}">
                <label for="accCode">Account Code</label>
                <div class="invalid-feedback" *ngIf="fc.accCode.invalid && (fc.accCode.dirty || fc.accCode.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.accCode.errors?.required">Required</p>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="swift" formControlName="swift"
                  [ngClass]="{'is-invalid': fc.swift.invalid && (fc.swift.dirty || fc.swift.touched)}">
                <label for="swift">Swift</label>
                <div class="invalid-feedback" *ngIf="fc.swift.invalid && (fc.swift.dirty || fc.swift.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.swift.errors?.required">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="iban" formControlName="iban"
                  [ngClass]="{'is-invalid': fc.iban.invalid && (fc.iban.dirty || fc.iban.touched)}">
                <label for="iban">Iban</label>
                <div class="invalid-feedback" *ngIf="fc.iban.invalid && (fc.iban.dirty || fc.iban.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.iban.errors?.required">Required</p>
                </div>
              </div>
            </div>

            <div class="my-2">
              Billing Address
            </div>
            <div class="row" formGroupName="billingAddress">
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="Title" placeholder="Title"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('addressType')?.invalid && (fc['billingAddress'].get('addressType')?.dirty || fc['billingAddress'].get('addressType')?.touched)}"
                    formControlName="addressType">
                  <label for="Title">Title</label>
                  <div
                    *ngIf="fc['billingAddress'].get('addressType')?.invalid && (fc['billingAddress'].get('addressType')?.dirty || fc['billingAddress'].get('addressType')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('addressType')?.errors?.['required']">
                      Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input ngx-google-places-autocomplete #placesRef="ngx-places" type="text" class="form-control"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('searchAddress')?.invalid && (fc['billingAddress'].get('searchAddress')?.dirty || fc['billingAddress'].get('searchAddress')?.touched)}"
                    id="searchAddress" placeholder="Search" formControlName="searchAddress"
                    (onAddressChange)="handleBillingAddressChange($event)">
                  <label for="searchAddress">Search Address</label>
                  <div
                    *ngIf="fc['billingAddress'].get('searchAddress')?.invalid && (fc['billingAddress'].get('searchAddress')?.dirty || fc['billingAddress'].get('searchAddress')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('searchAddress')?.errors?.['required']">
                      Required</p>
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('searchAddress')?.errors?.['address']">
                      Invalid</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="addressLine1" placeholder="Address Line 1"
                    formControlName="addressLine1"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('addressLine1')?.invalid && (fc['billingAddress'].get('addressLine1')?.dirty || fc['billingAddress'].get('addressLine1')?.touched)}">
                  <label for="addressLine1"> Address Line 1</label>
                  <div
                    *ngIf="fc['billingAddress'].get('addressLine1')?.invalid && (fc['billingAddress'].get('addressLine1')?.dirty || fc['billingAddress'].get('addressLine1')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('addressLine1')?.errors?.['required']">
                      Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="adressLineTwo" formControlName="addressLine2">
                  <label for="adressLineTwo">Address Line 2</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="city" placeholder="City" formControlName="city"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('city')?.invalid && (fc['billingAddress'].get('city')?.dirty || fc['billingAddress'].get('city')?.touched)}">
                  <label for="city">City</label>
                  <div
                    *ngIf="fc['billingAddress'].get('city')?.invalid && (fc['billingAddress'].get('city')?.dirty || fc['billingAddress'].get('city')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('city')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="state" placeholder="State" formControlName="state"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('state')?.invalid && (fc['billingAddress'].get('state')?.dirty || fc['billingAddress'].get('state')?.touched)}">
                  <label for="state">State</label>
                  <div
                    *ngIf="fc['billingAddress'].get('state')?.invalid && (fc['billingAddress'].get('state')?.dirty || fc['billingAddress'].get('state')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('state')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="country" placeholder="Country" formControlName="country"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('country')?.invalid && (fc['billingAddress'].get('country')?.dirty || fc['billingAddress'].get('country')?.touched)}">
                  <label for="country">Country</label>
                  <div
                    *ngIf="fc['billingAddress'].get('country')?.invalid && (fc['billingAddress'].get('country')?.dirty || fc['billingAddress'].get('country')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('country')?.errors?.['required']">Required
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="zipCode" placeholder="Zip" formControlName="zipCode"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('zipCode')?.invalid && (fc['billingAddress'].get('zipCode')?.dirty || fc['billingAddress'].get('zipCode')?.touched)}">
                  <label for="zipCode">Zip</label>
                  <div
                    *ngIf="fc['billingAddress'].get('zipCode')?.invalid && (fc['billingAddress'].get('zipCode')?.dirty || fc['billingAddress'].get('zipCode')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('zipCode')?.errors?.['required']">Required
                    </p>
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('zipCode')?.errors?.['pattern']">Invalid</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="my-2">
              Shipping Address
            </div>
            <div class="row" formGroupName="shippingAddress">
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="Title" placeholder="Title"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('addressType')?.invalid && (fc['shippingAddress'].get('addressType')?.dirty || fc['shippingAddress'].get('addressType')?.touched)}"
                    formControlName="addressType">
                  <label for="Title">Title</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('addressType')?.invalid && (fc['shippingAddress'].get('addressType')?.dirty || fc['shippingAddress'].get('addressType')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('addressType')?.errors?.['required']">
                      Required
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input ngx-google-places-autocomplete #placesRef="ngx-places" type="text" class="form-control"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('searchAddress')?.invalid && (fc['shippingAddress'].get('searchAddress')?.dirty || fc['shippingAddress'].get('searchAddress')?.touched)}"
                    id="searchAddress" placeholder="Search" formControlName="searchAddress"
                    (onAddressChange)="handleShippingAddressChange($event)">
                  <label for="searchAddress">Search Address</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('searchAddress')?.invalid && (fc['shippingAddress'].get('searchAddress')?.dirty || fc['shippingAddress'].get('searchAddress')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('searchAddress')?.errors?.['required']">
                      Required</p>
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('searchAddress')?.errors?.['address']">
                      Invalid</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="addressLine1" placeholder="Address Line 1"
                    formControlName="addressLine1"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('addressLine1')?.invalid && (fc['shippingAddress'].get('addressLine1')?.dirty || fc['shippingAddress'].get('addressLine1')?.touched)}">
                  <label for="addressLine1"> Address Line 1</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('addressLine1')?.invalid && (fc['shippingAddress'].get('addressLine1')?.dirty || fc['shippingAddress'].get('addressLine1')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('addressLine1')?.errors?.['required']">
                      Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="adressLineTwo" formControlName="addressLine2">
                  <label for="adressLineTwo">Address Line 2</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="city" placeholder="City" formControlName="city"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('city')?.invalid && (fc['shippingAddress'].get('city')?.dirty || fc['shippingAddress'].get('city')?.touched)}">
                  <label for="city">City</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('city')?.invalid && (fc['shippingAddress'].get('city')?.dirty || fc['shippingAddress'].get('city')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('city')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="state" placeholder="State" formControlName="state"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('state')?.invalid && (fc['shippingAddress'].get('state')?.dirty || fc['shippingAddress'].get('state')?.touched)}">
                  <label for="state">State</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('state')?.invalid && (fc['shippingAddress'].get('state')?.dirty || fc['shippingAddress'].get('state')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('state')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="country" placeholder="Country" formControlName="country"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('country')?.invalid && (fc['shippingAddress'].get('country')?.dirty || fc['shippingAddress'].get('country')?.touched)}">
                  <label for="country">Country</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('country')?.invalid && (fc['shippingAddress'].get('country')?.dirty || fc['shippingAddress'].get('country')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('country')?.errors?.['required']">Required
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="zipCode" placeholder="Zip" formControlName="zipCode"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('zipCode')?.invalid && (fc['shippingAddress'].get('zipCode')?.dirty || fc['shippingAddress'].get('zipCode')?.touched)}">
                  <label for="zipCode">Zip</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('zipCode')?.invalid && (fc['shippingAddress'].get('zipCode')?.dirty || fc['shippingAddress'].get('zipCode')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('zipCode')?.errors?.['required']">Required
                    </p>
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('zipCode')?.errors?.['pattern']">Invalid</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async"
              [message]="'Onboarding Plant Saved'"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="(this._showErrorMsg$ | async)">
            </app-message-toast>
          </div>
          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner" *ngIf="isLoading"></div>
          </div>
          <div class="col-12 text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class c-pointer" (click)="saveOnboardingPlants()">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<!-- <ng-template #billingAddress>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Billing Address</h2>
        </div>
        <div class="col-auto text-end">
          <span [ngClass]="{'disabled' : isLoading }">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
              (click)="closeModal()"></span>
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate>
          <div class="col-md-12">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="title">
              <label for="title">Title</label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="searchAddress">
              <label for="searchAddress">Search Address</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="adressLineOne">
              <label for="adressLineOne">Address Line 1</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="adressLineTwo">
              <label for="adressLineTwo">Address Line 2</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="city">
              <label for="city">City</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="state">
              <label for="state">State</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="country">
              <label for="country">Country</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="zip">
              <label for="zip">Zip</label>
            </div>
          </div>
          <div class="col-12 text-end mb-3">
            <button type="button" class="btn btn-new-class c-pointer">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #shippingAddress>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Shipping Address</h2>
        </div>
        <div class="col-auto text-end">
          <span [ngClass]="{'disabled' : isLoading }">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
              (click)="closeModal()"></span>
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate>
          <div class="col-md-12">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="title">
              <label for="title">Title</label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="searchAddress">
              <label for="searchAddress">Search Address</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="adressLineOne">
              <label for="adressLineOne">Address Line 1</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="adressLineTwo">
              <label for="adressLineTwo">Address Line 2</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="city">
              <label for="city">City</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="state">
              <label for="state">State</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="country">
              <label for="country">Country</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="zip">
              <label for="zip">Zip</label>
            </div>
          </div>
          <div class="col-12 text-end mb-3">
            <button type="button" class="btn btn-new-class c-pointer">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</ng-template>  -->