
import { ServerAPIResponseDto } from './../../../../shared/models/ServerAPIResponseDto';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeploymentConfigurationDto } from './../../../../shared/models/user/DeploymentConfigurationDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionStatus } from './../../../../shared/models/user/AuctionStatus';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { BehaviorSubject, Observable, OperatorFunction, Subscription, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, TemplateRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddLotComponent } from '../add-lot/add-lot.component';
import { LotFeaturesDetailsComponent } from '../lot-features-details/lot-features-details.component';
import { AuctionValidationService } from 'src/app/shared/services/auction-validation.service';
import { AuctionValidationErrorCodeDto } from 'src/app/shared/models/user/AuctionValidationErrorCodeDto';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { AuctionLotSaveWrapperDto } from 'src/app/shared/models/user/AuctionLotSaveWrapperDto';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { HtmlViewComponent } from 'src/app/shared/components/html-view/html-view.component';
import { LotsErrorValidationComponent } from 'src/app/shared/components/lots-error-validation/lots-error-validation.component';
import { AuctionEnableWrapperDto } from 'src/app/shared/models/AuctionEnableWrapperDto';
import { BulkLotsVerificationComponent } from '../bulk-lots-verification/bulk-lots-verification.component';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileService } from 'src/app/shared/services/file.service';
import { ErrorCodeDto } from 'src/app/shared/models/user/ErrorCodeDto';
import SwiperCore, { SwiperOptions, Navigation, Pagination, Virtual } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { ServerDataFetchService } from 'src/app/shared/services/server-data-fetch.service';
import { BulkLotCreationStatus } from 'src/app/shared/models/BulkLotCreationStatus';
import { LotFeatures } from 'src/app/shared/models/user/LotFeatures';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';

SwiperCore.use([Virtual]);

@Component({
  selector: 'app-creator-lot-information',
  templateUrl: './creator-lot-information.component.html',
  styleUrls: ['./creator-lot-information.component.sass']
})
export class CreatorLotInformationComponent implements OnInit, AfterViewInit, OnDestroy {

  auctionLotEntityDtos?: AuctionLotEntityDto[];
  auctionLotEntityDto?: AuctionLotEntityDto;
  auctionEntityDto?: AuctionEntityDto;
  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];

  ctrlNoOfLots: FormControl = new FormControl('', [Validators.required, Validators.pattern("^[1-9]\\d*$")]);
  lotActiveCheckbox: FormControl = new FormControl(false);
  formGroupOthers: FormGroup;

  _isLotInfoHide$ = new BehaviorSubject<boolean>(false);
  deleteIndex?: number;

  isLoading: boolean = false;
  isLotFeaturesLoading: boolean = false;
  lotActiveStatus: boolean = false;

  _isOrderActive$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _errorMsg$ = new BehaviorSubject<string>("");
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  isShowInspectionDetail$ = new BehaviorSubject<boolean>(false);
  isShowPickUpDetails$ = new BehaviorSubject<boolean>(false);

  lotFeatures: LotFeatures[] = []

  IsTable = true;

  excelDocumentTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  fileUploadError: boolean = false;
  selectedBulkUploadFile?: File | null;
  isSubmittedBulkLotsFile: boolean = false;

  isSubmitted: boolean = true;
  isSingleLot: boolean = true;
  selectedLotDropdown?: string;
  selectedBulkLotsFileName: string = "";
  checkStatusBulkLotInterval: any;

  selectedLotSubscription$?: Subscription;
  selectedLotsSubscription$?: Subscription;
  selectedSubscription$?: Subscription;
  deploymentConfigurationDto?: DeploymentConfigurationDto;
  deploymentConfigurationDtoSubscription$?: Subscription;


  _isShowTableView$ = new BehaviorSubject<boolean>(false);

  @ViewChild('searchLot') searchLotField?: ElementRef<HTMLInputElement>;
  @ViewChild('bulkUploadSheetRef') bulkUploadSheetRef?: HTMLInputElement;
  @ViewChild('bulkLotErrorModel') bulkLotErrorModel?: TemplateRef<any>;
  @ViewChild('inspectionDetailRef') inspectionDetailRef?: ElementRef<HTMLParagraphElement>
  @ViewChild('pickUpDetailsRef') pickUpDetailsRef?: ElementRef<HTMLParagraphElement>

  bulkLotList: any[] = [];

  constructor(
    private fileService: FileService,
    private auctionLotService: AdminLotsDataHolderService,
    private ngbModal: NgbModal,
    public adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private validationService: AuctionValidationService,
    public translate: TranslateService,
    public adminService: AdminDashboardService,
    private fb: FormBuilder,
    private changeDetectRef: ChangeDetectorRef,
    private serverDataFetchService: ServerDataFetchService
  ) {
    this.formGroupOthers = this.fb.group({
      pickup: [''],
      inspection: ['']
    })
  }

  searchOption: string[] = [];

  public selectedValue: string = "";
  masterCategories: any[] = [];

  public screenWidth: any;


  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  search?: OperatorFunction<string, readonly string[]>;

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;

    this.search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map((term) =>
          term.length < 1 ? [] : this.filterLotList(term).slice(0, 10),
        ),
      );

    this.deploymentConfigurationDtoSubscription$ = this.adminService.getDeploymentConfiguration$.subscribe(data => {
      if (data) {
        this.deploymentConfigurationDto = data;
      }
    })

    this.selectedLotSubscription$ = this.auctionLotService._selectedAuctionLot$.subscribe(data => {
      if (data) {
        this.auctionLotEntityDto = data;
        this.lotActiveCheckbox.patchValue(this.auctionLotEntityDto?.active);

        this.selectedLotDropdown = this.auctionLotEntityDto.lotSequence + " - " + (this.auctionLotEntityDto.title ? this.auctionLotEntityDto.title : ' ');

        if (this.auctionLotEntityDtos) {
          this.auctionLotEntityDtos.sort((a, b) => a.lotSequence! - b.lotSequence!);
        }

        this.changeDetectRef.detectChanges()
      }
    })

    this.selectedLotsSubscription$ = this.auctionLotService._allLots$.subscribe(data => {
      this.auctionLotEntityDtos = [];
      if (data) {
        this.auctionLotEntityDtos = data;
        this.auctionLotEntityDtos.sort((a, b) => a.lotSequence! - b.lotSequence!);
        this.changeDetectRef.detectChanges()
      }
    })
    this.adminService.getMasterCategories$.subscribe((dataList) => {
      if (dataList) {
        this.masterCategories = dataList;
      } else {
        this.masterCategories = [];
      }
    })

    this.selectedSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe(data => {
      if (data) {
        this.auctionEntityDto = data!;
        this.isSingleLot = data.singleLot ?? true;

        if (this.isAllowToEdit()) {
          this.lotActiveCheckbox.enable();
          this.lotActiveCheckbox.updateValueAndValidity();
        } else {
          this.lotActiveCheckbox.disable();
          this.lotActiveCheckbox.updateValueAndValidity();
        }

        if (this.isSingleLot) {
          this._isShowTableView$.next(false);
        }
      }
    })
  }

  ngAfterViewInit(): void {
  }

  ngAfterViewChecked(): void {
  }

  get fcOther(): any { return this.formGroupOthers.controls; }

  getSelectedCategoryData(searchText: string, id: string,) {
    let tempList = [... this.masterCategories ?? []];

    let filterData = tempList.filter(item => (item.category.toString().toLowerCase().includes(searchText.trim().toLowerCase())
      || item.category.toString().toLowerCase().includes(searchText.trim().toLowerCase())) && (item.id == id));

    if (filterData && filterData.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  changeLotView() {
    this._isShowTableView$.next(!this._isShowTableView$.value);
  }

  filterLotList(searchText: string) {
    this.searchOption = [];
    if (searchText) {
      let tempAuctionLotEntityDtos = [... this.auctionLotEntityDtos ?? []];
      let filterList = tempAuctionLotEntityDtos.filter(item => item.lotSequence == Number(searchText.trim()) || item.title?.trim().toLocaleLowerCase().includes(searchText.trim().toLowerCase())
        || this.removeHtmlTag(item.description?.trim()).toLocaleLowerCase().includes(searchText.trim().toLowerCase())
        || item.preferenceCategory?.categoryName == searchText.trim()
        || item.preferenceCategory?.categoryLevelOneName == searchText.trim()
        || item.preferenceCategory?.categoryLevelTwoName == searchText.trim()
        || item.preferenceCategory?.categoryLevelThreeName == searchText.trim()
        || item.preferenceCategory?.categoryLevelFourName == searchText.trim()
        || item.hashtags && item.hashtags?.filter(item => item.trim().toLowerCase().includes(searchText.trim().toLowerCase())).length > 0
        || item.lotFeatures && item.lotFeatures?.filter(item => item.featureValue?.trim().toLowerCase().includes(searchText.trim().toLowerCase()) || item.featureKey?.trim().toLowerCase().includes(searchText.trim().toLowerCase())).length > 0
        || item.startingBid?.toString().includes(searchText) || item.reservePrice?.toString().includes(searchText)

      );

      if (filterList && filterList.length > 0) {
        this.searchOption = filterList.map(item => item.lotSequence + " : " + (item.title ?? ''));
      }

    }
    return this.searchOption;
  }

  getSelectAddressFromAuctionEntityDto(id: string) {
    if (id && this.auctionEntityDto && this.auctionEntityDto?.addresses && this.auctionEntityDto.addresses.length > 0) {
      return this.auctionEntityDto.addresses.find(item => item.id == id)?.addressType;
    }
    return "";
  }

  openCSVFile(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openAuctionLotDiscardModel(content: any) {
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  changeLotInfoHideOrShow() {
    this._isLotInfoHide$.next(!this._isLotInfoHide$.value)

  }

  openDisableUserModal(content: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this._errorMsg$.next("");
    this.lotActiveCheckbox.patchValue(this.auctionLotEntityDto?.active);
    this.lotActiveStatus = this.auctionLotEntityDto?.active!;
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  changeLotType() {
    this.isSingleLot = !this.isSingleLot;
  }

  getLotStatus() {
    if (this.auctionLotEntityDto?.active) {
      if (this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT) {
        return "UPCOMING"
      } else {
        let endDate = this.auctionLotEntityDto.endDate + " " + this.auctionLotEntityDto.endTime;
        if (ApplicationUtils.getRemainingTime(endDate, this.auctionEntityDto?.timeZone!) > 0) {
          return "RUNNING";
        } else {
          return "CLOSED";
        }
      }


    } else {
      return "DRAFT";
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.lotFeatures!, event.previousIndex, event.currentIndex);
  }

  changeCurrentLot(lotId: string) {
    let selectedLot = this.auctionLotEntityDtos?.find(item => item.lotId == lotId);
    this.auctionLotService.setSelectedAuctionLot(selectedLot!);
    this.auctionLotEntityDto = selectedLot;
    this.lotActiveCheckbox.patchValue(this.auctionLotEntityDto?.active);
  }


  changeCurrentLotById(selectedLotId: string) {
    let selectedLot = this.auctionLotEntityDtos?.find((item => item.lotId == selectedLotId));
    if (selectedLot) {
      this.auctionLotService.setSelectedAuctionLot(selectedLot);
      this.auctionLotEntityDto = selectedLot;
      this.lotActiveCheckbox.patchValue(this.auctionLotEntityDto?.active);
      this.selectedLotDropdown = this.auctionLotEntityDto.lotSequence + " - " + (this.auctionLotEntityDto.title ? this.auctionLotEntityDto.title : ' ');
    }
  }

  showOrHideInspectionDetail() {
    this.isShowInspectionDetail$.next(!this.isShowInspectionDetail$.value);
    if (!this.isShowInspectionDetail$.value) {
      this.inspectionDetailRef!.nativeElement.style.height = '120px'
    } else {
      this.inspectionDetailRef!.nativeElement.style.height = this.inspectionDetailRef!.nativeElement.scrollHeight + 'px';
    }
  }

  showOrHidePickUpDetails() {
    this.isShowPickUpDetails$.next(!this.isShowPickUpDetails$.value);
    if (!this.isShowPickUpDetails$.value) {
      this.pickUpDetailsRef!.nativeElement.style.height = '120px'
    } else {
      this.pickUpDetailsRef!.nativeElement.style.height = this.pickUpDetailsRef!.nativeElement.scrollHeight + 'px';
    }
  }

  changeCurrentLotBySearch() {
    if (this.selectedValue && this.selectedValue.includes(":")) {
      let lotSeq = this.selectedValue.substring(0, this.selectedValue.indexOf(':'));
      let selectedLot = this.auctionLotEntityDtos?.find((item => item.lotSequence == Number(lotSeq)));

      if (selectedLot) {
        this.auctionLotService.setSelectedAuctionLot(selectedLot);
        this.auctionLotEntityDto = selectedLot;
        this.lotActiveCheckbox.patchValue(this.auctionLotEntityDto?.active);
      }
    }
  }

  openLotFeature(mode: string, index: number) {
    let modalRef = this.ngbModal.open(LotFeaturesDetailsComponent, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.mode = mode;
    modalRef.componentInstance.index = index;
  }

  openLotModal(content: any) {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    this.formGroupOthers.controls['pickup'].setValue(this.auctionLotEntityDto?.pickUpDetails);
    this.formGroupOthers.controls['inspection'].setValue(this.auctionLotEntityDto?.inspectionDetails);
    this.checkUserInput();
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  discardAuctionLot() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._errorMsg$.next('');
    this._showErrorToast$.next(false);
    this.isLoading = true;

    this.adminService.auctionLotDiscard(this.auctionLotEntityDto?.auctionId!, this.auctionLotEntityDto?.lotId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(this.auctionLotEntityDto?.auctionId!);
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)

        } else {
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._errorMsg$.next("Error while discarding Lot. Try again.");
        this._showErrorToast$.next(false);
      }
    })
  }

  markAuctionLotActiveOrInActive(action: string) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);
    this._errorMsg$.next("")
    let errorDtoList: AuctionValidationErrorCodeDto[] = []

    this.validationService.doAuctionLotValidation(errorDtoList, this.auctionEntityDto!, this.auctionLotEntityDto!);

    if (errorDtoList && errorDtoList.length > 0) {
      this.auctionValidationErrorCodeDtoList = errorDtoList;
      this.closeModal();
      this.openErrorModel();
      return;
    }

    let lotEntityDto = ApplicationUtils.clone(this.auctionLotEntityDto);
    lotEntityDto!.active = action == "ENABLE";

    this.isLoading = true;

    this.adminService.markAuctionLotActiveOrInActive(lotEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let auctionValidationErrorCodeDtoList = apiResponseDto.data as Array<AuctionValidationErrorCodeDto>;
          if (!auctionValidationErrorCodeDtoList || auctionValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isLoading = false;
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.lotActiveStatus = action == "ENABLE";
              this.closeModal();
            }, 2000)
          } else {
            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
            this.isLoading = false;
          }
        } else {
          if (apiResponseDto.code == 'AUCTION-EXCEPTION-127') {
            this._errorMsg$.next("Error while enabling Lot. Enable Auction and try again.");
          } else {
            this._errorMsg$.next(apiResponseDto?.message!);
          }
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next(`Error while ${action == 'ENABLE' ? 'enabling' : 'disabling'} Lot. Try again.`);

      }
    })
  }

  saveLotFeature() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.isLotFeaturesLoading = true;
    this._isSaveButtonEnable$.next(false);

    let lotEntityDto = ApplicationUtils.clone(this.auctionLotEntityDto);
    lotEntityDto.lotFeatures = this.lotFeatures

    this.auctionLotService.saveAuctionLotDetails(lotEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let auctionLotSaveWrapperDto = apiResponseDto.data as AuctionLotSaveWrapperDto;
          let auctionValidationErrorCodeDtoList = auctionLotSaveWrapperDto.auctionValidationErrorCodeDtos;

          if (!auctionValidationErrorCodeDtoList || auctionValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isLotFeaturesLoading = false;

            setTimeout(() => {
              this._isSaveButtonEnable$.next(true);
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)

          } else {
            this.isLotFeaturesLoading = false;
            this._isSaveButtonEnable$.next(true);
            this.closeModal();

            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
          }

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLotFeaturesLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving lot. Try again.");
        this.isLotFeaturesLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })
  }

  checkUserInput(){
    if(this.formGroupOthers.controls['pickup'].value || this.formGroupOthers.controls['inspection'].value){
      this._isSaveButtonEnable$.next(true);
    }else{
      this._isSaveButtonEnable$.next(false);
    }
  }

  savePickupAndInspection() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.isSubmitted = true;

    this.auctionValidationErrorCodeDtoList = [];
    if (this.formGroupOthers.invalid) {
      return;
    }

    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    let lotEntityDto = ApplicationUtils.clone(this.auctionLotEntityDto);
    lotEntityDto.inspectionDetails = this.formGroupOthers.controls['inspection'].value;
    lotEntityDto.pickUpDetails = this.formGroupOthers.controls['pickup'].value;

    this.auctionLotService.saveAuctionLotDetails(lotEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let auctionLotSaveWrapperDto = apiResponseDto.data as AuctionLotSaveWrapperDto;
          let auctionValidationErrorCodeDtoList = auctionLotSaveWrapperDto.auctionValidationErrorCodeDtos;

          if (!auctionValidationErrorCodeDtoList || auctionValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            setTimeout(() => {
              this._isSaveButtonEnable$.next(true);
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)

          } else {
            this.isLoading = false;
            this._isSaveButtonEnable$.next(true);
            this.closeModal();

            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
          }

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving lot. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })
  }

  removeLotFeature(index: number) {
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.isLotFeaturesLoading = true;
    this._isSaveButtonEnable$.next(false);
    this.deleteIndex = index;

    let auctionLotEntityDto = ApplicationUtils.clone(this.auctionLotEntityDto)
    auctionLotEntityDto?.lotFeatures?.splice(index, 1);

    this.auctionLotService.saveAuctionLotDetails(auctionLotEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.deleteIndex = undefined
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isLotFeaturesLoading = false;
          this._isSaveButtonEnable$.next(true);
        } else {
          this.isLotFeaturesLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      },
      error: (error) => {
        console.error(error);
        this.isLotFeaturesLoading = false;
        this._isSaveButtonEnable$.next(true);
        this.deleteIndex = undefined
      }
    })
  }

  openAllLotsActivationModal(content: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");

    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  activateAllLots() {
    this._errorMsg$.next('');
    this._showErrorToast$.next(false);

    let errorDataList = this.validationService.doAuctionAndLotValidation()

    let validationModalRef = this.ngbModal.open(LotsErrorValidationComponent, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
    validationModalRef.componentInstance.errorDtoList = errorDataList;
    validationModalRef.componentInstance.onSubmit.subscribe((lotIdsList: any) => {
      if (lotIdsList && lotIdsList.length > 0) {
        this.activatePendingLots(lotIdsList);
        validationModalRef.close();
      }
    })
  }

  openBulkLotErrorModel(bulkLotList: Array<any>) {
    this.bulkLotList = [];
    this.bulkLotList = bulkLotList
    this.ngbModal.open(this.bulkLotErrorModel, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  getBulkLotMsg(item: any) {
    let errorDto = item as ErrorCodeDto
    if (errorDto.code) {
      return item.message
    } else {
      return "PASS";
    }
  }

  getBulkLotIndex(item: any) {
    let errorDto = item as ErrorCodeDto
    return errorDto.param1;
  }


  activatePendingLots(lotIdsList: string[]) {
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this._errorMsg$.next('');
    this.isLoading = true;

    let auctionEntityDto = ApplicationUtils.clone(this.auctionEntityDto);
    // auctionEntityDto!.active = active;

    let auctionEnableWrapper = new AuctionEnableWrapperDto();
    auctionEnableWrapper.auctionEntityDto = auctionEntityDto;
    auctionEnableWrapper.lotIds = lotIdsList;

    this.adminService.markLotsActive(auctionEnableWrapper).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {

          let auctionValidationErrorCodeDtoList = apiResponseDto.data as AuctionValidationErrorCodeDto[];
          if (!auctionValidationErrorCodeDtoList || auctionValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)
          } else {
            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
            this.isLoading = false;
          }
        } else {
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._errorMsg$.next(`Error while enabling Lots. Try again.`);
      }
    })
  }

  openLotFeatureSorting(content: any) {
    let auctionLotEntityDto = ApplicationUtils.clone(this.auctionLotEntityDto) as AuctionLotEntityDto;
    this.lotFeatures = auctionLotEntityDto.lotFeatures ?? [];
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openDescriptionModal(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openLotDetailsOne(mode: string) {
    if (!this.isAllowToEdit()) {
      return;
    }

    let modalRef = this.ngbModal.open(AddLotComponent, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.mode = mode;
  }

  openlotDetailsTwo(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openlotDetailsThree(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  closeModal() {
    this.isLotFeaturesLoading = false;
    this.lotFeatures = [];
    this.ngbModal.dismissAll();
    this.removeSelectedBulkLotsFile();
  }

  getErrorMsg(error: AuctionValidationErrorCodeDto) {
    return ApplicationUtils.populateMessage(error, this.translate)
  }

  removeHtmlTag(input?: string) {
    if (input) {
      let formattedInput = input.replace(/<\/?[^>]+(>|$)/g, "");
      formattedInput = formattedInput.replace(/&#[0-9]+;/g, "");
      formattedInput = formattedInput.replace(/&nbsp;/g, ' ');
      formattedInput = formattedInput.replace(/&amp;/g, '&');
      return formattedInput
    }
    return "";
  }

  truncateText(input?: string) {
    if (input) {
      let formattedInput = input.replace(/<\/?[^>]+(>|$)/g, "");
      formattedInput = formattedInput.replace(/&#[0-9]+;/g, "");
      formattedInput = formattedInput.replace(/&nbsp;/g, ' ');
      formattedInput = formattedInput.replace(/&amp;/g, '&');
      return ApplicationUtils.truncateString(formattedInput, 101);
    }
    return '';
  }

  ngOnDestroy(): void {
    if (this.selectedLotSubscription$) {
      this.selectedLotSubscription$.unsubscribe();
    }
    if (this.selectedLotsSubscription$) {
      this.selectedLotsSubscription$.unsubscribe();
    }
    if (this.selectedSubscription$) {
      this.selectedSubscription$.unsubscribe();
    }

    if (this.deploymentConfigurationDtoSubscription$) {
      this.deploymentConfigurationDtoSubscription$.unsubscribe();
    }

    clearInterval(this.checkStatusBulkLotInterval);
  }

  openErrorModel() {
    let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.auctionValidationErrorCodeDtoList;
  }

  openShowMoreModal(title: string, description: string) {
    let modalRef = this.ngbModal.open(HtmlViewComponent, { size: 'md', backdrop: 'static', keyboard: false, centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.content = description;
  }

  getDisplayDate(date?: string, time?: string) {
    return ApplicationUtils.getDisplayDateAdmin(date, time);
  }

  haveLotDetailsErrors() {
    if (this.auctionEntityDto) {
      return this.validationService.haveLotDetailsErrors(this.auctionEntityDto, this.auctionLotEntityDto!);
    }
    return false;
  }

  haveAnyErrorInEndDate() {
    return this.validationService.haveAnyErrorInEndDate(this.auctionEntityDto!, this.auctionLotEntityDto!);
  }

  haveAnyLotErrors(lotId: string) {
    let selectedLot = this.auctionLotEntityDtos?.find(item => item.lotId == lotId);
    if (this.auctionEntityDto) {
      return this.validationService.haveAnyLotErrors(this.auctionEntityDto!, selectedLot!);
    }
    return false;
  }

  truncateString(val?: string, maxLength?: number) {
    if (!val) {
      return '';
    }

    if (val.length > maxLength!) {
      return ApplicationUtils.truncateString(val, maxLength!);
    } else {
      return val;
    }
  }

  // Bulk Upload
  openBulkLotModal(content: any) {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this._errorMsg$.next('')

    this.ctrlNoOfLots.reset();
    this.ngbModal.open(content, {
      size: 'sm', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openUploadBulkLotsModal(content: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this._errorMsg$.next('')

    this.isSubmittedBulkLotsFile = false;
    this.closeModal();
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  chooseBulkLotsFile(event: any) {
    this._showErrorToast$.next(false);
    this.selectedBulkUploadFile = event.target.files[0];

    if (!this.excelDocumentTypes.includes(this.selectedBulkUploadFile!.type)) {
      this.fileUploadError = true;
      this._errorMsg$.next("Extension not supported");
      this._showErrorToast$.next(true);
      this.selectedBulkUploadFile = null;
      this.bulkUploadSheetRef?.setAttribute('value', '');
      this.selectedBulkLotsFileName = "";
      return;
    }

    this.selectedBulkLotsFileName = this.selectedBulkUploadFile?.name as string;
  }
  removeSelectedBulkLotsFile() {
    this.selectedBulkUploadFile = null;
    this.bulkUploadSheetRef?.setAttribute('value', '');
    this.selectedBulkLotsFileName = "";
  }


  generateBulkLotsExcel() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    this.isLoading = true;
    this.auctionLotService.downloadBulkLotExcel().subscribe({
      next: (data) => {
        this.closeModal();
        this.isLoading = false;
        let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);;
        link.download = this.auctionEntityDto?.sequenceNo! + ""; // Set the desired filename
        link.click();
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next(`Error while downloading Lots Excel Sheet. Try again.`);
      }
    })
  }

  isErrorInBulkLotUpload(dataList: any[]) {
    let toReturn = false;
    if (dataList) {
      for (let i = 0; i < dataList.length; i++) {
        let errorDto = dataList[i] as ErrorCodeDto;
        if (errorDto.code) {
          toReturn = true;
        }
      }
    }
    return toReturn;
  }

  checkUploadStatusOfBulkLot() {
    this.checkStatusBulkLotInterval = setInterval(async () => {
      let bulkLotCreationStatus = await this.auctionLotService.checkUploadStatusOfBulkLot(this.auctionEntityDto?.auctionId!);

      if (bulkLotCreationStatus && bulkLotCreationStatus.status == 'COMPLETED') {
        this.isLoading = false;
        clearInterval(this.checkStatusBulkLotInterval);

        this._showSuccessToast$.next(true);
        this.auctionLotService.removeFirstLotIfRequired();

        setTimeout(() => {
          this._showSuccessToast$.next(false);
          this.closeModal();
        }, 5000);

      } else {
        let lastIndexOfAllLots = this.auctionLotEntityDtos!.length - 1;
        if (lastIndexOfAllLots > -1) {
          let lastAuctionLotEntityDto = this.auctionLotEntityDtos![lastIndexOfAllLots];
          this.auctionLotService.setSelectedAuctionLot(lastAuctionLotEntityDto);
          this.auctionLotEntityDto = lastAuctionLotEntityDto;
          this.lotActiveCheckbox.patchValue(lastAuctionLotEntityDto.active);
        }
      }
    }, 2000)
  }

  uploadBulkLotsExcel() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.isSubmittedBulkLotsFile = true;

    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");

    if (!this.selectedBulkUploadFile) {
      return;
    }

    let fileName = this.selectedBulkUploadFile.name;
    if (fileName != this.auctionEntityDto?.sequenceNo + ".xlsx") {
      this._showErrorToast$.next(true);
      this._errorMsg$.next("Please Upload Correct File (" + this.auctionEntityDto?.sequenceNo + ".xlsx" + ")");
      return;
    }

    this.isLoading = true;
    let lotSizeBeforeUpload = this.auctionLotService._allLots$.value.length;


    this.auctionLotService.createLotsByExcel(this.selectedBulkUploadFile, this.auctionEntityDto?.auctionId!).subscribe({
      next: (serverApiResponse: ServerAPIResponseDto) => {
        if (serverApiResponse && serverApiResponse.code == ApplicationConstants.SUCCESS_CODE) {
          let data = serverApiResponse.data as any;
          this.isLoading = false;
          if (this.isErrorInBulkLotUpload(data)) {
            clearInterval(this.checkStatusBulkLotInterval);
            this.openBulkLotErrorModel(data);
          } else {
            this.auctionLotService.removeFirstLotIfRequired();
            this._showSuccessToast$.next(true);
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 5000);
          }
        }else{
          this.checkUploadStatusOfBulkLot();
        }
        // else {
        //   // check if new lots has been added
        //   let newLotSize = this.auctionLotService._allLots$.value.length;

        //   if (newLotSize > lotSizeBeforeUpload) {
        //     // consider as succesfull response scenario
        //     this._showSuccessToast$.next(true);
        //     this.auctionLotService.removeFirstLotIfRequired();
        //     setTimeout(() => {
        //       this._showSuccessToast$.next(false);
        //       // this.closeModal();
        //     }, 5000)
        //   }
        // }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next(`Error while downloading Lots Excel Sheet. Try again.`);
        clearInterval(this.checkStatusBulkLotInterval);
      }
    })
  }

  openBulkLotsVerificationModal() {
    this.closeModal();
    this.ngbModal.open(BulkLotsVerificationComponent, {
      size: 'xl', backdrop: 'static', keyboard: false, centered: true
    });
  }

  downloadFile(fileInfoDto: FileInfoDto) {
    this.fileService.downloadFile(fileInfoDto?.fileId!).subscribe({
      next: (response) => {
        if (response) {
          this.isLoading = false;
          let file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);;
          link.download = this.auctionEntityDto?.sequenceNo! + ""; // Set the desired filename
          link.click();
        }
      },
      error: (err) => {
        console.error(err);
        console.log("Error while previewing document");
      }
    });

  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT && !this.auctionEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE && !this.auctionEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }

}




