import { EncryptionAndDecryptionService } from './encryption-and-decryption-service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, Subscription, map } from 'rxjs';
import { UserRegistrationStatus } from '../enums/UserRegistrationStatus';
import { ChangePasswordDto } from '../models/ChangePasswordDto';
import { ForgotPasswordDto } from '../models/ForgotPasswordDto';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { SessionInfoDto } from '../models/SessionInfoDto';
import { AddUserDto } from '../models/user/AddAdminDto';
import { RegistrationUserModalDto } from '../models/user/RegistrationUserModalDto';

import { ErrorService } from './error.service';
import { VerificationWorkflowWrapper } from '../models/VerificationWorkflowWrapper';
import { PreferenceCategoryDto } from '../models/user/PreferenceCategoryDto';
import { UserEntityInfoDto } from '../models/user/UserEntityInfoDto';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy{
  _userEntity$ = new BehaviorSubject<UserEntityInfoDto | null>(null);
  _showLoginRegistrationButton$ = new BehaviorSubject<boolean>(false);

  private _sessionExpired$: Observable<boolean> = new Observable();

  private userEntity?: UserEntityInfoDto;
  private sessionInfoDto?: SessionInfoDto;
  sessionExpiredSubscription$? : Subscription;

  constructor(
    private enAndDec: EncryptionAndDecryptionService,
    private http: HttpClient,
    private errorService: ErrorService
  ) {

    this.checkLoginButtonContextSensitivity();

    this._sessionExpired$ = this.errorService.getSessionExpired$;
    this.sessionExpiredSubscription$  = this._sessionExpired$.subscribe(data => {
      if (data && data == true) {
        this.clear();
      }
    })
  }

  get getSessionInfo() { return this.sessionInfoDto; }
  get getUserEntity$() { return this._userEntity$.asObservable(); }
  get getLoginContextSensitivity$() { return this._showLoginRegistrationButton$.asObservable(); }

  checkLoginButtonContextSensitivity() {
    let auctionSessionId = localStorage.getItem('AUC_SESSION_ID');
    if (auctionSessionId) {
      this._showLoginRegistrationButton$.next(false);
    } else {
      this._showLoginRegistrationButton$.next(true);
    }
  }

  public getUserEntity(): UserEntityInfoDto {
    return this.userEntity!;
  }

  setUserDto(userEntityDto: UserEntityInfoDto) {
    this.userEntity = userEntityDto;
    this._userEntity$.next(this.userEntity);
  }


  // Set the user to this service after authentication
  public setUser(sessionInfo: SessionInfoDto): void {
    this.userEntity = sessionInfo.userEntityInfoDto;
    this._userEntity$.next(this.userEntity!);
    this.sessionInfoDto = sessionInfo;
    this._showLoginRegistrationButton$.next(false);
  }

  checkIfValidSession(sessionId: string) {
    const headers = new HttpHeaders().set('Authorization', "JSESSIONID=" + sessionId).
      set("Cache-Control", 'no-cache').
      set("Cache-control", 'no-store').
      set("Pragma", 'no-cache').
      set("Expires", '0');
    return this.http.get<string>('userDetails', { headers: headers }).pipe(
      catchError(this.errorService.returnNull<any>('User session is not valid ' + sessionId)))
  }

  registerUsers(
    registrationUserModalDto: RegistrationUserModalDto,
    emailId: string,
    password: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Basic ' + window.btoa(encodeURIComponent(emailId!) + ':' + password)
    });

    return this.http.post<ServerAPIResponseDto>('registerUsers', registrationUserModalDto, { headers })
      .pipe(
        tap(_ => console.log("User Registered Successfully : " + registrationUserModalDto.companyName)),
        catchError(this.errorService.handleError("Error while user registration : " + registrationUserModalDto.companyName)))
  }

  generateOTP(emailId: string, name: string, workflowName: string): Observable<any> {
    let params = new HttpParams().set('emailId', emailId!).set('name', name).set('workFlowName', workflowName);
    return this.http.get<ServerAPIResponseDto>('OTPS', { params }).pipe(
      tap(_ => console.log("OTP Generate Successfully : " + emailId)),
      catchError(this.errorService.handleError("Error while generate : " + emailId)))
  }

  resendOTP(emailId: string, name: string, otpId: string): Observable<any> {
    let params = new HttpParams().set('emailId', emailId!).set('name', name!).set("otpId", otpId);
    return this.http.get<ServerAPIResponseDto>('resendOTPS', { params }).pipe(
      tap(_ => console.log("Resend OTP Generate Successfully : " + emailId)),
      catchError(this.errorService.handleError("Error while generate : " + emailId)))
  }

  verifyOTP(otpId: string, userEnteredOtp: string): Observable<any> {
    let params = new HttpParams().set("otpId", otpId).set('userEnteredOtp', userEnteredOtp!);
    return this.http.get<ServerAPIResponseDto>('verifyEmail', { params }).pipe(
      tap(_ => console.log("OTP Verification Successfully : " + otpId)),
      catchError(this.errorService.handleError("Error while verification OTP : " + otpId)))
  }

  logout(email?: string): Observable<ServerAPIResponseDto> {
    let params = new HttpParams().set('emailId', email!);
    return this.http.get<any>('logout', { params }).pipe(
      tap(_ => console.log("logout successfully")),
      catchError(this.errorService.handleError<any>('Error while doing logout')))
  }

  changePassword(changePasswordDto: ChangePasswordDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('changePassword', changePasswordDto).pipe(
      tap(_ => console.log("Password Reset Successfully for User : " + changePasswordDto.userName)),
      catchError(this.errorService.handleError("Error while resetting password for user : " + changePasswordDto.userName)))
  }

  updateUserDetails(userEntityDto: UserEntityInfoDto): Observable<any> {
    let headers = new HttpHeaders()

    headers = this.enAndDec.setCustomEncryptionHeader(headers)
    let data = this.enAndDec.encryptObject(userEntityDto);
    return this.http.post<any>('users', data, {headers}).pipe(
      tap(_ => console.log("Update Details Successfully for User" + userEntityDto.name)),
      map(response => {
        return this.enAndDec.decryptObject(response);
      }),
      catchError(this.errorService.handleError<any>('Error while updating details for user' + userEntityDto)))
  }
  forgotPassword(forgotPasswordDto: ForgotPasswordDto, email: string, password: string): Observable<any> {
    let headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(encodeURIComponent(email) + ':' + password) });

    return this.http.post<ServerAPIResponseDto>('forgotPassword', forgotPasswordDto, { headers }).pipe(
      tap(_ => console.log("Forgot password successfully for User : " + email)),
      catchError(this.errorService.handleError("Error while forgot password for user : " + email)))
  }

  makeOffer(auctionId: string, auctionHouseId: string, lotId: string, userId: string, offerPrice: number): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId).set('auctionHouseId', auctionHouseId).set('lotId', lotId).set('userId', userId).set('offerPrice', offerPrice);
    return this.http.post<ServerAPIResponseDto>('makeOffer', null, { params }).pipe(
      tap(_ => console.log("Offer Placed Successfully for User : " + userId)),
      catchError(this.errorService.handleError("Error while placing offer for user : " + userId)))
  }

  getUserByUserId(userId: string): Observable<any> {
    let params = new HttpParams().set("userId", userId);
    return this.http.get<ServerAPIResponseDto>('userByUserId', { params }).pipe(
      tap(_ => console.log("User Data Fetch Successfully : " + userId)),
      catchError(this.errorService.handleError("Error while fetching user data : " + userId)))
  }

  getUserDetailsWithSession(sessionId: string) {
    const headers = new HttpHeaders().set('Authorization', "JSESSIONID=" + sessionId).
      set("Cache-Control", 'no-cache').
      set("Cache-control", 'no-store').
      set("Pragma", 'no-cache').
      set("Expires", '0');
    return this.http.get<string>('userDetails', { headers: headers }).pipe(
      tap(_ => console.log("got data for user by sessionId")),
      catchError(this.errorService.handleError<any>('Error while getting data for user by sessionId' + sessionId)))
  }

  generateOTPEmailMobileVerificationWorkflow(verificationWorkflowWrapper: VerificationWorkflowWrapper): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('generateOTPEmailMobileVerificationWorkflow', verificationWorkflowWrapper).pipe(
      tap(_ => console.log("OTP Generate Successfully : " + verificationWorkflowWrapper.newEmailId)),
      catchError(this.errorService.handleError("Error while generate : " + verificationWorkflowWrapper.newEmailId)))
  }

  verifyOTPEmailMobileVerificationWorkflow(verificationWorkflowWrapper: VerificationWorkflowWrapper): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('verifyOTPEmailMobileVerificationWorkflow', verificationWorkflowWrapper).pipe(
      tap(_ => console.log("OTP Verify Successfully : " + verificationWorkflowWrapper.newEmailId)),
      catchError(this.errorService.handleError("Error while verify : " + verificationWorkflowWrapper.newEmailId)))
  }

  subcategoryPreference(categoryIds: PreferenceCategoryDto[]): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('subcategoryPreference', categoryIds).pipe(
      tap(_ => console.log("Categories preference saved successfully : " + categoryIds.length)),
      catchError(this.errorService.handleError("Error while saving categories preference : " + categoryIds.length)))
  }

  clear() {
    this.sessionInfoDto = null!;
    this.userEntity = null!;
    this._userEntity$.next(this.userEntity!);
    this._showLoginRegistrationButton$.next(true);
  }


ngOnDestroy(): void {
  if(this.sessionExpiredSubscription$){
    this.sessionExpiredSubscription$.unsubscribe()
  }
}
}
