import { AWFChainDefineUiDto } from "./AWFChainDefineUiDto";
import { AWFRangeConditionDefineUiDto } from "./AWFRangeConditionDefineUiDto";

export class AWFDefineUiDto {
  orgCode?: string;
  approvalCode?: string;
  approvalId?: string;
  name?: string;
  type?: string;
  wfCode?: string;
  companyCode?: string;
  plantCode?: string;
  conditionType?: string;
  ruleActive?: boolean;

  conditionDefineUiDtos?: AWFRangeConditionDefineUiDto[];
  chainDefineUiDtos?: AWFChainDefineUiDto[];
}