<div class="container p-3">
  <div class="col-12">
    <div class="row align-items-center">
      <div class="col-11">
        <div class="me-3">
          <span class="me-3">Company:</span>
          <span>{{selectedUserRfxQuestionsEntityDto?.companyName}}</span>
        </div>

        <div>
          <span class="me-3">Event Name</span>
          <span>{{selectedSubcategorySubmissionDto?.subcategoryName}}</span>
        </div>
      </div>
      <div class="col-1 text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>

    <div class="mt-3 col-11">
      <div class="row align-items-center mb-3">
        <div class="col-4">
          <span class="me-3">
            <a class="c-pointer" [ngClass]="{'disabled' : isDisabledPrev()}" (click)="prevQuestion()">Previous</a>
          </span>
          <span class="me-3">Q {{currentQuestionIndex + 1}} to {{technicalQuestionsList.length}}</span>
          <span class="me-3">
            <a class="c-pointer" [ngClass]="{'disabled' : isDisabledNext()}" (click)="nextQuestion()">Next</a>
          </span>
        </div>
        <div class="col-4 text-center">
          Total Score {{totalEvaluationScore}}/{{maximumScore}}
        </div>
        <div class="col-4 text-end">
          <span class="badge-gray-class badge me-3">Incomplete-{{countIncompleteQuestions}}</span>
          <span class="badge-blue-class badge">Complete-{{countCompleteQuestions}}</span>
        </div>
      </div>
      <div class="mb-3">
        <div class="input-group">
          <div class="form-floating">
            <input class=" form-control" [formControl]="ctrlQuestion" id="ctrlQuestion">
            <label for="ctrlQuestion">Question Criteria
              <span class="ms-2 badge badge-white-class"
                *ngIf="selectedTechnicalQuestion.responseMandatory">Mandatory</span>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class=" col-md-5">
          <div class="">{{responseTypeTitle}}</div>
          <div class="small-grey-font">
            Bidder's Response is selected in Blue. To change the evaluation select a different response
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <!-- SINGLE_CHOICE -->
        <div class="col-md-5 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.SINGLE_CHOICE">
          <div class="border p-2 mb-2 c-pointer position-relative"
            [ngClass]="{'bidder-selected' : isOptionSelectedByBidder(item)}" (click)="selectEvaluationResponse(item)"
            *ngFor="let item of selectedTechnicalQuestion.questionOptions; index as i">
           <div class="row align-items-center">
          <div class="col-10">
        
          <small>Score-{{item.questionOptionScore}}</small><br>
          <span>
            {{item.optionPreSequence}}.{{item.optionSequence}} {{item.optionText}}
          </span>
          </div>
          <div class="col-2 text-center">
            <img class="close-icon-class-fb" src="assets/icons/check-evaluation.svg"
            *ngIf="evaluatorResponse?.optionSequence == item?.optionSequence" />
          </div>
           </div>
          </div>
          <div class="border p-2 mb-2 c-pointer position-relative"
            (click)="selectEvaluationResponse(getNoneQuestionOption())">
          <div class="row align-items-center">
            <div class="col-10">
         
            <small>Score-{{getNoneQuestionOption().questionOptionScore}}</small><br>
            <span>
              {{getNoneQuestionOption().optionPreSequence}}.{{getNoneQuestionOption().optionSequence}}
              {{getNoneQuestionOption().optionText}}
            </span>
            </div>
            <div class="col-2 text-center">
              <img class="close-icon-class-fb" src="assets/icons/check-evaluation.svg"
              *ngIf="evaluatorResponse?.optionSequence == getNoneQuestionOption()?.optionSequence" />
            </div>


          </div>


          </div>
        </div>

        <!-- MULTI_CHOICE -->
        <div class="col-md-5 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.MULTI_CHOICE">
          <div class="border p-2 mb-2 c-pointer position-relative"
            [ngClass]="{'bidder-selected' : isOptionSelectedByBidder(item)}" (click)="selectEvaluationResponse(item)"
            *ngFor="let item of selectedTechnicalQuestion.questionOptions; index as i">
            <div class="row align-items-center">
              <div class="col-10">
               
            <small>Score-{{item.questionOptionScore}}</small><br>
            <span>
              {{item.optionPreSequence}}.{{item.optionSequence}} {{item.optionText}}
            </span>
              </div>
              <div class="col-2 text-center">
                <img class="close-icon-class-fb" src="assets/icons/check-evaluation.svg"
                *ngIf="isEvaluatedMultiChoice(item?.optionSequence!)" />
              </div>
            </div>
          </div>
          <div class="border p-2 mb-2 c-pointer position-relative"
            (click)="selectEvaluationResponse(getNoneQuestionOption())">
            <div class="row align-items-center">
              <div class="col-10">
       
            <small>Score-{{getNoneQuestionOption().questionOptionScore}}</small><br>
            <span>
              {{getNoneQuestionOption().optionPreSequence}}.{{getNoneQuestionOption().optionSequence}}
              {{getNoneQuestionOption().optionText}}
            </span>
              </div>
              <div class="col-2 text-center">
                <img class="close-icon-class-fb" src="assets/icons/check-evaluation.svg"
                *ngIf="evaluatorResponse && evaluatorResponse[0]?.optionSequence == getNoneQuestionOption()?.optionSequence" />
              </div>
            </div>
          </div>
        </div>

        <!-- DATE -->
        <div class="col-md-5 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.DATE">
          <!-- <div class="form-floating mb-3 c-pointer" (click)="selectEvaluationResponse(singleBidderResponse!)">
            <input ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" type="text" class="form-control"
              [formControl]="ctrlDateStart">
            <label for="floatingInput">Score-{{singleBidderResponse?.questionOptionScore}}</label>
          </div> -->
          <div class="border p-2 mb-2 c-pointer position-relative bidder-selected"
            (click)="selectEvaluationResponse(singleBidderResponse!)">
          <div class="row align-items-center">
            <div class="col-10">
          
            <small>Score-{{singleBidderResponse?.questionOptionScore}}</small><br>
            <span>
              {{singleBidderResponse?.optionPreSequence}}.{{singleBidderResponse?.optionSequence}}
              {{selectedUserRfxQuestionsEntityDto?.bidderResponse}}
            </span>
            </div>
            <div class="col-2 text-center">
              <img class="close-icon-class-fb" src="assets/icons/check-evaluation.svg"
              *ngIf="evaluatorResponse?.optionSequence == singleBidderResponse?.optionSequence" />
            </div>
          </div>


          </div>
          <div class="border p-2 mb-2 c-pointer position-relative"
            (click)="selectEvaluationResponse(getNoneQuestionOption())">
          <div class="row align-items-center">
            <div class="col-10">
              
            <small>Score-{{getNoneQuestionOption().questionOptionScore}}</small><br>
            <span>
              {{getNoneQuestionOption().optionPreSequence}}.{{getNoneQuestionOption().optionSequence}}
              {{getNoneQuestionOption().optionText}}
            </span>
            </div>
            <div class="col-2 text-center">
              <img class="close-icon-class-fb" src="assets/icons/check-evaluation.svg"
              *ngIf="evaluatorResponse?.optionSequence == getNoneQuestionOption()?.optionSequence" />
            </div>

          </div>
          </div>
        </div>

        <!-- DATE_RANGE -->
        <div class="col-md-5 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.DATE_RANGE">
          <!-- <div class="form-floating mb-3 c-pointer" (click)="selectEvaluationResponse(singleBidderResponse!)">
            <input ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" type="text" class="form-control"
              [formControl]="ctrlDateStart">
            <label for="floatingInput">Score-{{singleBidderResponse?.questionOptionScore}}</label>
          </div>
          <div class="form-floating mb-3 c-pointer" (click)="selectEvaluationResponse(singleBidderResponse!)">
            <input ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" type="text" class="form-control"
              [formControl]="ctrlDateEnd">
            <label for="floatingInput">Score-{{singleBidderResponse?.questionOptionScore}}</label>
          </div> -->
          <div class="border p-2 mb-2 c-pointer position-relative bidder-selected"
            (click)="selectEvaluationResponse(singleBidderResponse!)">
           <div class="row align-items-center">
            <div class="col-10">
            <small>Score-{{singleBidderResponse?.questionOptionScore}}</small><br>
            <span>
              {{singleBidderResponse?.optionPreSequence}}.{{singleBidderResponse?.optionSequence}}
              {{selectedUserRfxQuestionsEntityDto?.bidderResponse[0]}} to
              {{selectedUserRfxQuestionsEntityDto?.bidderResponse[1]}}
            </span>
            </div>
            <div class="col-2 text-center">
              <img class="close-icon-class-fb" src="assets/icons/check-evaluation.svg"
              *ngIf="evaluatorResponse?.optionSequence == singleBidderResponse?.optionSequence" />
            </div>
           </div>
          </div>
          <div class="border p-2 mb-2 c-pointer position-relative"
            (click)="selectEvaluationResponse(getNoneQuestionOption())">
            <div class="row align-items-center">
              <div class="col-10">
             
            <small>Score-{{getNoneQuestionOption().questionOptionScore}}</small><br>
            <span>
              {{getNoneQuestionOption().optionPreSequence}}.{{getNoneQuestionOption().optionSequence}}
              {{getNoneQuestionOption().optionText}}
            </span>
              </div>
              <div class="col-2 text-center">
                <img class="close-icon-class-fb" src="assets/icons/check-evaluation.svg"
                *ngIf="evaluatorResponse?.optionSequence == getNoneQuestionOption()?.optionSequence" />
              </div>
            </div>
          </div>
        </div>

        <!-- ALPHANUMERIC -->
        <div class="col-md-5 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.ALPHANUMERIC">
          <!-- <div class="form-floating mb-3 c-pointer" (click)="selectEvaluationResponse(singleBidderResponse!)">
            <input type="text" class="form-control" [formControl]="ctrlAlphaNumeric">
            <label for="floatingInput">Score-{{singleBidderResponse?.questionOptionScore}}</label>
          </div> -->
          <div class="border p-2 mb-2 c-pointer position-relative bidder-selected"
            (click)="selectEvaluationResponse(singleBidderResponse!)">
          <div class="row align-items-center">
            <div class="col-10">
           
            <small>Score-{{singleBidderResponse?.questionOptionScore}}</small><br>
            <span>
              {{singleBidderResponse?.optionPreSequence}}.{{singleBidderResponse?.optionSequence}}
              {{selectedUserRfxQuestionsEntityDto?.bidderResponse}}
            </span>
            </div >
            <div class="col-2 text-center">
              <img class="close-icon-class-fb" src="assets/icons/check-evaluation.svg"
              *ngIf="evaluatorResponse?.optionSequence == singleBidderResponse?.optionSequence" />
            </div>
          </div>
          </div>
          <div class="border p-2 mb-2 c-pointer position-relative"
            (click)="selectEvaluationResponse(getNoneQuestionOption())">
           <div class="row">
            <div class="col-10">
            <small>Score-{{getNoneQuestionOption().questionOptionScore}}</small><br>
            <span>
              {{getNoneQuestionOption().optionPreSequence}}.{{getNoneQuestionOption().optionSequence}}
              {{getNoneQuestionOption().optionText}}
            </span>
            </div>
          <div class="col-2 text-center">
            <img class="close-icon-class-fb" src="assets/icons/check-evaluation.svg"
            *ngIf="evaluatorResponse?.optionSequence == getNoneQuestionOption()?.optionSequence" />
          </div>
           </div>
          </div>
        </div>

        <div class="col-md-7 mb-3">
          <div>Uploaded Documents</div>
          <div>
            <div class="row medium-grey-font" (click)="previewDoc(documentArchive, i)"
              *ngFor="let documentArchive of selectedUserRfxQuestionsEntityDto?.bidderDocuments; index as i">
              <div class="col-6">
                <img src="assets/icons/file_light.svg" class="svg-icon-class c-pointer">
                {{documentArchive.displayName}}
              </div>
              <div class="col-2 text-end">
                {{formatBytes(documentArchive.fileSize)}}
              </div>
              <div class="col-3 text-end">
                <!-- <span> Expiry</span>
                <span *ngIf="!documentArchive.expiryDate">NA</span>
                <span *ngIf="documentArchive.expiryDate"> <span>{{documentArchive.expiryDate}}</span></span> -->
              </div>
              <div class="col-1 text-end">
                <img src="assets/icons/view_light.svg" class="svg-icon-class-small c-pointer" *ngIf="currentFileIndex != i">
                <div class="spinner-border text-primary" role="status" *ngIf="currentFileIndex == i && isFileDownloading">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="text-end">
          <a class="link-class c-pointer" (click)="openBidderCommentModal(bidderCommentModalTemplate)">View Bidder's
            Comment</a>
        </div>
        <div class="input-group">
          <div class="form-floating">
            <input class=" form-control" id="ctrlEvaluatorComments" [formControl]="ctrlEvaluatorComments">
            <label for="ctrlEvaluatorComments">
              <div class="row">
                <div class="col-7">
                  Comment
                </div>
              </div>

            </label>
          </div>
        </div>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
          [message]="'Question verified successfully.'"></app-message-toast>
      </div>

      <div class="mb-3 text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="btn btn-sm btn-primary-clr-class" (click)="handleSubmit()">Verify</button>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
      </div>

    </div>
  </div>
</div>

<ng-template #bidderCommentModalTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Bidder's Comment</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(modalRefBidderComment)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p>{{selectedUserRfxQuestionsEntityDto?.bidderComments}}</p>
      </div>
    </div>
  </div>
</ng-template>