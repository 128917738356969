<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>My Details</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeUserDetailsModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">

      <app-user-profile *ngIf="!isProfileEditMode" [userDto]="userDto"
        (onEditProfile)="onEditProfileMode()"></app-user-profile>
    <div class="m-3">
      <div class="mb-2" *ngIf="userDto?.firstName && isProfileEditMode">
        <span> Welcome </span><span><strong>{{userDto?.firstName}}</strong></span>
      </div>
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()"
        *ngIf="isProfileEditMode">
        <div class="row">
          <div class="col-6 d-none d-md-block">

            <div class="form-floating mb-2">
              <input tabindex="4" type="text" class="form-control" id="primaryEmailId" placeholder="Email Id"
                formControlName="primaryEmailId">
              <label for="primaryEmailId">Email Id</label>
            </div>

            <div class="form-floating mb-2">
              <input tabindex="1" type="text" class="form-control" id="firstName" placeholder="First Name"
                formControlName="firstName">
              <label for="firstName">First Name</label>
              <div *ngIf="isSubmitted || fc.firstName.invalid && (fc.firstName.dirty || fc.firstName.touched)">
                <p class="text-danger" *ngIf="fc.firstName.errors?.required">Required</p>
              </div>
            </div>
            <div class="form-floating mb-2">
              <input tabindex="2" type="text" class="form-control" id="lastName" placeholder="Last Name"
                formControlName="lastName">
              <label for="lastName">Last Name</label>
              <div *ngIf="isSubmitted || fc.lastName.invalid && (fc.lastName.dirty || fc.lastName.touched)">
                <p class="text-danger" *ngIf="fc.lastName.errors?.required">Required</p>
              </div>
            </div>
            <div class="form-floating mb-2">
              <input tabindex="2" type="text" class="form-control" id="designation" placeholder="Designation"
                formControlName="designation">
              <label for="designation">Designation (Optional)</label>

            </div>
          </div>
          <div class="col-6 d-none d-md-block text-center">
            <img alt="" src="assets/images/avatar05.jpg" class="profile-image-class border" *ngIf="!imageUrl">
            <img [src]="imageUrl" alt="" class="profile-image-class" *ngIf="imageUrl">
            <div class="ms-2 mt-2">
              <input #profileImg name="profile_pic" onclick="this.value=null;" type="file" class="d-none"
                (change)="chooseFile($event)" />
              <button class="btn btn-link" *ngIf="!this.userId" (click)="profileImg.click()">Change</button>
            </div>
          </div>
          <div class="col-12 d-block d-md-none ">
            <img alt="" src="assets/images/avatar05.jpg" class="profile-image-class border" *ngIf="!imageUrl">
            <img [src]="imageUrl" alt="" class="profile-image-class" *ngIf="imageUrl">
            <div class=" mt-2">
              <input #profileImg name="profile_pic" onclick="this.value=null;" type="file" class="d-none"
                (change)="chooseFile($event)" />
              <button class="btn btn-link" *ngIf="!this.userId" (click)="profileImg.click()">Change</button>
            </div>
          </div>
          <div class="col-12 d-block d-md-none">


            <div class="form-floating mb-2">
              <input tabindex="4" type="text" class="form-control" id="primaryEmailId" placeholder="Email Id"
                formControlName="primaryEmailId">
              <label for="primaryEmailId">Email Id</label>
            </div>

            <div class="form-floating mb-2">
              <input tabindex="1" type="text" class="form-control" id="firstName" placeholder="First Name"
                formControlName="firstName">
              <label for="firstName">First Name</label>
              <div *ngIf="isSubmitted || fc.firstName.invalid && (fc.firstName.dirty || fc.firstName.touched)">
                <p class="text-danger" *ngIf="fc.firstName.errors?.required">Required</p>
              </div>
            </div>
            <div class="form-floating mb-2">
              <input tabindex="2" type="text" class="form-control" id="lastName" placeholder="Last Name"
                formControlName="lastName">
              <label for="lastName">Last Name</label>
              <div *ngIf="isSubmitted || fc.lastName.invalid && (fc.lastName.dirty || fc.lastName.touched)">
                <p class="text-danger" *ngIf="fc.lastName.errors?.required">Required</p>
              </div>
            </div>
            <div class="form-floating mb-2">
              <input tabindex="2" type="text" class="form-control" id="designation" placeholder="Designation"
                formControlName="designation">
              <label for="designation">Designation (Optional)</label>

            </div>

          </div>

          <hr class="hr mt-2 mb-4">
          <div>

            <div class="border p-2">
              <div class="row ">

                <div class="col-md-6">

                  <div class="form-floating mb-2">
                    <input tabindex="2" type="text" class="form-control" id="companyCode" placeholder="companyCode"
                      formControlName="companyCode">
                    <label for="companyCode">Company Code</label>
      
                  </div>

                  <div class="form-floating mb-2">
                    <input  type="text" class="form-control" id="companyUrl" placeholder="Company Url"
                      formControlName="companyUrl">
                    <label for="companyUrl">Company Url (Optional)</label>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToastProfile$ | async" [isError]="true"
            [message]="errorMsg"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToastProfile$ | async" [isError]="false"
            [message]="errorMsg"></app-message-toast>
        </div>

        <div class="d-grid gap-2 my-3" *ngIf="isUserProfileLoading">
          <div class="e-spinner center-spinner" style="margin-top: -20px !important"></div>
        </div>

        <div *ngIf="!userId">
          <div class="col-12 text-end my-3" *ngIf="(!isUserProfileLoading && !(_showSuccessToastProfile$ | async))">
            <button type="button" class="btn btn-new-class me-3" (click)="toggleEditProfileMode()">CANCEL</button>
            <button type="button" class="btn btn-new-class" (click)="handleValidSubmit()">SAVE</button>
          </div>
        </div>
      </form>

      <!-- <hr class="hr my-3" *ngIf="!isProfileEditMode"> -->

     <div class="bg-white-new">
      <div class="col-12 mt-3" *ngIf="!isProfileEditMode">
        <div class="row ">
          <div class="col-lg-6 pe-lg-0">
           <div class="border p-2 bg-white">
            <div class="row">
              <div class="col-6">
                <h6 class="heading-h5">Email</h6>
              </div>
              <div class="col-6 text-end">

                  <img src="assets/icons/addnew_light.svg" class=" c-pointer svg-icon-class"
                  (click)="openEmailModal(emailModal)">
              </div>
            </div>
            <hr class="hr my-2">

            <div class="mt-3 mx-2">
              <ul style="list-style: none;" class="ps-2">
                <li *ngFor="let item of userDto?.emailList;">
                  <div class="row my-3">
                    <div class="div-class col-8 ps-0">
                      {{item.email}}
                    </div>
                    <div class="col-4 text-end" *ngIf="item.primary">
                      <span class="badge bg-secondary-common" > Primary</span>
                    </div>
                    <div class="col-4 text-end" *ngIf="!item.primary">
                      <!-- <img src="assets/icons/edit_light.svg" class=" c-pointer svg-icon-class me-3"> -->
                      <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer"
                        (click)="openDeleteConfirmModal(deleteConfirmationModal, item, 'EMAIL')">
                    </div>
                  </div>
                </li>
              </ul>
            </div>
           </div>
          </div>
          <div class="col-lg-6  ps-lg-0">
           <div class="border p-2 bg-white">
            <div class="row">
              <div class="col-6 ">
                <h6 class="heading-h5">Contact</h6>
              </div>
              <div class="col-6 text-end">

                  <img src="assets/icons/addnew_light.svg" class=" c-pointer svg-icon-class" (click)="openContactModal(contactModal)">
              </div>
            </div>
            <hr class="hr my-2">
            <div class=" mt-3 mx-2">
              <ul style="list-style: none;" class="ps-2">
                <li *ngFor="let item of userDto?.mobileList;">
                  <div class="row my-3">
                    <div class="div-class col-8 ps-0">
                      {{getCountryCode(item.countryCode!)}} {{item.mobileNo}}

                    </div>

                    <div class="col-4 text-end" *ngIf="item.primary">
                      <span class="badge bg-secondary-common" > Primary</span>
                    </div>

                    <div class="col-4 text-end" *ngIf="!item.primary">
                      <!-- <img src="assets/icons/edit_light.svg" class=" c-pointer svg-icon-class me-3"> -->
                      <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer"
                        (click)="openDeleteConfirmModal(deleteConfirmationModal, item, 'MOBILE')">
                    </div>
                  </div>
                </li>
              </ul>
            </div>
           </div>
          </div>
        </div>
      </div>
     </div>

    </div>
  </div>
</div>

<ng-template #contactModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>ADD CONTACT</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(addMobileModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">

        <form class="needs-validation" novalidate (keyup.enter)="addContactNo()">
          <div class="input-group">
            <span class="input-group-addon">
              <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                [countryCode$]="countryCode$"></app-country-code-select>
            </span>
            <div class="form-floating mb-3">
              <input type="email" class="form-control" id="ctrlMobileNo" placeholder="contact"
                [formControl]="ctrlMobileNo" />
              <label for="ctrlMobileNo">Contact No</label>
              <div *ngIf="ctrlMobileNo.invalid && (ctrlMobileNo.dirty || ctrlMobileNo.touched)">
                <p class="text-danger" *ngIf="ctrlMobileNo.errors?.['required']">Required</p>
                <p class="text-danger" *ngIf="ctrlMobileNo.errors?.['pattern']">Invalid</p>
              </div>
            </div>
          </div>

          <div class="my-3">
            <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
              [message]="errorMsg"></app-message-toast>
            <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
              [message]="'Contact number saved successfully.'"></app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class btn-sm " (click)="addContactNo()">ADD</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emailModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>ADD EMAIL</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(addEmailModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">

        <form class="needs-validation" novalidate (keyup.enter)="otpId ? verifyEmailId() : addEmailId()">
          <div class="form-floating mb-3" *ngIf="!otpId">
            <input type="email" class="form-control" id="ctrlEmail" placeholder="name@example.com"
              [formControl]="ctrlEmail" />
            <label for="ctrlEmail">EMAIL ADDRESS</label>
            <div *ngIf="ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)">
              <p class="text-danger" *ngIf="ctrlEmail.errors?.['required']">Required</p>
              <p class="text-danger" *ngIf="ctrlEmail.errors?.['pattern']">Invalid</p>
            </div>
          </div>
          <div *ngIf="otpId" class="mb-2">
            Enter the One Time Password (OTP) which was just sent to the email address you registered with. Be sure to check your spam folder.
          </div>
          <div class="form-floating mb-3" *ngIf="otpId">
            <input type="email" class="form-control" id="ctrlOtp" placeholder="otp" [formControl]="ctrlOtp" />
            <label for="ctrlOtp">OTP</label>
            <div *ngIf="ctrlOtp.invalid && (ctrlOtp.dirty || ctrlOtp.touched)">
              <p class="text-danger" *ngIf="ctrlOtp.errors?.['required']">Required</p>
            </div>
          </div>

          <div class="my-3">
            <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
              [message]="errorMsg"></app-message-toast>
            <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
              [message]="'Email id saved successfully.'"></app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="mb-3 col-12 text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class btn-sm " *ngIf="!otpId" (click)="addEmailId()">ADD</button>
            <button type="button" class="btn btn-new-class btn-sm me-3" *ngIf="otpId"
              (click)="cancelEmailVerify()">CANCEL</button>
            <button type="button" class="btn btn-new-class btn-sm " *ngIf="otpId"
              (click)="verifyEmailId()">VERIFY</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteConfirmationModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Delete {{selectedWrapperType == 'EMAIL' ? 'Email Id' : 'Mobile Number'}}</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(deleteModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p>
          Are you sure you want to delete this {{selectedWrapperType == 'EMAIL' ? 'Email Id' : 'Contact'}}
        </p>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="{{selectedWrapperType == 'EMAIL' ? 'Email' : 'Contact'}} deleted successfully."></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="mb-3 text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-sm me-3" (click)="closeModal(deleteModalRef)">
            CANCEL
          </button>
          <button type="button" class="btn btn-new-class btn-sm " (click)="deleteEmailOrMobile()">
            OK
          </button>
        </div>

      </div>
    </div>
  </div>
</ng-template>
