import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';
import { ServerDataFetchService } from '../../services/server-data-fetch.service';
import { DashboardRedirectionService } from '../../services/dashboard-redirection.service';
import { ApplicationNotificationEntityDto } from '../../models/ApplicationNotificationEntityDto';
import { NotificationType } from '../../enums/NotificationType';
import { DatePipe } from '@angular/common';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { AdminRfxSubcategoryDataHolderService } from '../../services/AdminRfxSubcategoryDataHolderService.service';
import { AdminSourcingEventsDataHolderService } from '../../services/AdminSourcingEventsDataHolder.service ';
import { ApplicationConstants } from '../../util/ApplicationConstants';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass']
})
export class NotificationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() layoutType: string = 'DASHBOARD';

  currentIndex: number = -1;
  isNavLoading: boolean = false;
  isNotificationAvailable: boolean = false;
  countAllNotifications: number = 0
  countNewNotifications: number = 0

  allApplicationNotifications: ApplicationNotificationEntityDto[] = [];
  newApplicationNotifications: ApplicationNotificationEntityDto[] = [];

  applicationNotificationsSubscription$?: Subscription;

  constructor(
    private userService: UserService,
    private adminDashboardService: AdminDashboardService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
    private serverDataFetchService: ServerDataFetchService,
    private dashboardRedirectionService: DashboardRedirectionService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.applicationNotificationsSubscription$ = this.adminDashboardService.getApplicationNotificationsList$.subscribe(data => {
      if (data) {
        this.allApplicationNotifications = data;
        this.newApplicationNotifications = data.filter(item => !item.readStatus!);

        this.countAllNotifications = this.allApplicationNotifications.length;
        this.countNewNotifications = this.newApplicationNotifications.length;
      } else {
        this.allApplicationNotifications = [];
        this.newApplicationNotifications = [];

        this.countAllNotifications = 0;
        this.countNewNotifications = 0;
      }
    })
  }

  ngAfterViewInit(): void {
    if (this.layoutType == 'HEADER') {
      this.getApplicationNotifications()
    }
  }

  async getApplicationNotifications() {
    let userEntityDto = this.userService.getUserEntity();
    await this.adminDashboardService.loadApplicationNotifications(userEntityDto.userId!);
  }

  async navigateToEventDetails(applicationNotification: ApplicationNotificationEntityDto, index: number) {
    this.currentIndex = index;
    this.isNavLoading = true;

    await this.serverDataFetchService.loadRfxForAdminSync(applicationNotification.resourceParentId!);
    await this.serverDataFetchService.loadAllSubcategoriesOfRfxIdForAdminSync(applicationNotification.resourceParentId!);

    let allSubcategoriesList = this.adminRfxSubcategoryDataHolderService.allRfxSubcategoryList ?? [];

    let rfxEntityDto = this.adminSourcingEventsDataHolderService.getSelectedRfx();
    let selectedSubcategory = allSubcategoriesList.find(item => item.subcategoryId == applicationNotification.resourceId);

    this.adminRfxSubcategoryDataHolderService.setSelectedRfxSubcategory(selectedSubcategory!);
    this.adminDashboardService.setSelectedApplicationNotificationDto(applicationNotification);

    this.dashboardRedirectionService.storeNavigationContext(
      ApplicationConstants.ADMIN,
      ApplicationConstants.RFX_CREATOR_PAGE,
      ApplicationConstants.RFX_TAB,
      rfxEntityDto?.sequenceNo?.toString(),
      selectedSubcategory?.subcategorySequence?.toString()
    );

    this.dashboardRedirectionService.setUpdateBreadcrumbs(true);

    this.isNavLoading = false;
    this.currentIndex = -1;
  }

  showNotification() { }

  getDisplayDate(createdTimeUtc: number) {
    return this.datePipe.transform(createdTimeUtc, 'dd/MM/yy hh:mm a');
  }

  ngOnDestroy(): void {
    if (this.applicationNotificationsSubscription$) {
      this.applicationNotificationsSubscription$.unsubscribe();
    }

    this.currentIndex = -1;
  }

  demo(): ApplicationNotificationEntityDto {
    return {
      "id": "42e8dc3f-aa79-4664-bd3f-ee9e25f76a1d",
      "code": "APPLICATION_NOTIFICATION_CODE_101",
      "message": "APPLICATION_NOTIFICATION_CODE_101",
      "type": NotificationType.NORMALIZE_REQUESTED_TYPE,
      "resourceParentId": "ec5b2173-e9d6-699e-dce3-ba3e4f7fd190",
      "resourceId": "4bd1b6a8-2581-47c6-bae8-3adf9116e000",
      "attributes": undefined,
      "companyId": "00d1bdb2-5c3e-4218-805a-b6f530bea68e",
      "emailId": "nehat131194@gmail.com",
      "companyName": "MITS",
      "expiredDate": undefined,
      "readStatus": false,
      "createdTimeUtc": 1715672482547,
      "createdTimeUtcMillis": 1715672482547
    }
  }
}
