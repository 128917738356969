
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { AdminUserPrivilege } from 'src/app/shared/enums/AdminUserPrivilege';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { UserEntityInfoDto } from 'src/app/shared/models/user/UserEntityInfoDto';import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { LandingService } from 'src/app/shared/services/LandingAndBidder.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { NavigationBackService } from 'src/app/shared/services/navigation-back.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { OrganizationUiDto } from '../../models/OrganizationUiDto';
import { OnboardingService } from '../../services/onboarding.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, AfterViewInit{
  public screenWidth: any;
  @Input() auctionName?: string;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }
  IsAnonymous = true;
  isAdmin = false;
  isSuperAdmin = false;
  IsAuctionNeer = false;

  isNotificationAvailable: boolean = false;

  navToggle?: HTMLInputElement | null;
  primaryNav?: HTMLInputElement | null;

  isShowProgressBar: boolean = false;

  userEntitySubscription$?: Subscription;
  organizationSubscription$?: Subscription;
  lastMessageSubscription$?: Subscription;

  organizationUiDto?: OrganizationUiDto;
  userEntityDto?: UserEntityInfoDto;
  auctionEntityDto?: AuctionEntityDto | null;

  @ViewChild('menuDropdown') menuDropdown?: NgbDropdown;
  @ViewChild('headerPrimary') headerPrimary?: ElementRef<HTMLElement>;
  @ViewChild('headerSecondary') headerSecondary?: ElementRef<HTMLElement>;
  @ViewChild('appLoader') appLoader?: LoaderComponent;

  progress: number = 0;
  enableCustomHeader: boolean = false;
  currentScrollHeight: number = 0;
  scrollHeight: number = 0;

  @HostListener('window:scroll', ['$event']) // Listen for scroll events on the window
  onWindowScroll() {
    const currentScrollHeight = window.scrollY;
    this.currentScrollHeight = currentScrollHeight;

    if (currentScrollHeight > 75 && this.enableCustomHeader) {
      this.headerPrimary!.nativeElement.style.opacity = '0';
      this.headerPrimary!.nativeElement.style.zIndex = '-1';
      this.headerSecondary!.nativeElement.style.opacity = '1';
      this.headerSecondary!.nativeElement.style.zIndex = '99';
      this.isShowProgressBar = true;
    } else {
      this.headerPrimary!.nativeElement.style.opacity = '1';
      this.headerPrimary!.nativeElement.style.zIndex = '99';
      this.headerSecondary!.nativeElement.style.opacity = '0';
      this.headerSecondary!.nativeElement.style.zIndex = '-1';
      this.isShowProgressBar = false;
    }

    this.getProgressBarValue();
  }

  constructor(
    private router: Router,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private ngbModal: NgbModal,
    private _router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private onboardingService: OnboardingService,
    public navigationBackService: NavigationBackService,
    private dataRedirectionService: DashboardRedirectionService  ) {}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe((auctionEntityDto) => {
      this.auctionEntityDto = auctionEntityDto;
    });

    this.adminSourcingEventsDataHolderService.getNotificationAvailable$.subscribe((isNotificationAvailable) => {
      this.isNotificationAvailable = isNotificationAvailable;
      this.adminSourcingEventsDataHolderService.setShowNotification(isNotificationAvailable);
    });
  }

  ngAfterViewInit() {
    this.headerPrimary!.nativeElement.style.opacity = '1';
    this.headerPrimary!.nativeElement.style.zIndex = '99';
    this.headerSecondary!.nativeElement.style.opacity = '0';
    this.headerSecondary!.nativeElement.style.zIndex = '-1';

    this.clickNavToggle();

    this.userEntitySubscription$ = this.userService.getUserEntity$.subscribe(
      (userEntity) => {
        if (userEntity) {
          this.userEntityDto = userEntity;
          this.IsAnonymous = false;
          this.isAdmin = true;
          this.isSuperAdmin = ApplicationUtils.checkIfRoleIsAssigned(userEntity.userPrivileges! , AdminUserPrivilege.SUPER_ADMIN);
          this.IsAuctionNeer = false;
        } else {
          this.IsAnonymous = true;
          this.isAdmin = false;
          this.isSuperAdmin = false;
          this.IsAuctionNeer = false;
        }
      }
    );

    this.organizationSubscription$ =
      this.onboardingService.getOrganizationUiDto$.subscribe((data) => {
        if (data) {
          this.organizationUiDto = data;
        }
      });
  }

  showNotification(){
    this.adminSourcingEventsDataHolderService.setShowNotification(true);
  }

  getProgressBarValue() {
    var winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    var height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    this.progress = scrolled;
  }

  getAuctionName() {
    let auction = this.adminSourcingEventsDataHolderService.getSelectedAuction();
    if (auction) {
      return auction.title;
    }
    return '';
  }

  clickNavToggle() {
    this.primaryNav = document.querySelector(
      '.primary-navigation'
    ) as HTMLInputElement | null;
    this.navToggle = document.querySelector(
      '.mobile-nav'
    ) as HTMLInputElement | null;
    // const navToggle = document.querySelector(".mobile-nav-toggle") as HTMLInputElement | null
    this.navToggle?.addEventListener('click', () => {
      const visibility = this.primaryNav?.getAttribute('data-visible');
      // console.log(primaryNav);
      // console.log(navToggle);
      // console.log(visibility);
      if (visibility === 'false') {
        this.primaryNav?.setAttribute('data-visible', 'true');
        this.navToggle?.setAttribute('aria-expanded', 'true');
      } else if (visibility === 'true') {
        this.primaryNav?.setAttribute('data-visible', 'false');
        this.navToggle?.setAttribute('aria-expanded', 'false');
      }

      const navButtonState = this.navToggle?.getAttribute('data-state');
      if (!navButtonState || navButtonState === 'closed') {
        this.navToggle?.setAttribute('data-state', 'opened');
      } else {
        this.navToggle?.setAttribute('data-state', 'closed');
      }
    });
  }

  navigateToPage(page: string) {
    this.closeNavMenu();
    this.dataRedirectionService.clearSessionCookies();

    if(page == "/calender"){
    this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['/calender'], { skipLocationChange: true }));
    }



      this.router.navigate(['/redirectToAuction'], { skipLocationChange: true });
  }

  navigateToHome() {
    this.dataRedirectionService.clearSessionCookies();
    this.closeNavMenu();
    this._router.navigateByUrl('/', { skipLocationChange: true });
  }



  openLoginModal(content: any) {
    this.closeNavMenu();
    this.ngbModal.open(content, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }
  openevaluationPageModal(content: any) {
    this.closeNavMenu();
    this.ngbModal.open(content, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  openMyDetailsModal(content: any , ngbDropdown?: NgbDropdown) {
    this.closeNavMenu();
    if (ngbDropdown) ngbDropdown.close();
    this.ngbModal.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  openRegistrationModal(content: any) {
    this.closeNavMenu();
    this.ngbModal.open(content, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });
  }

  openMyRegistration(dropdown: NgbDropdown) {
    this.closeNavMenu();
    dropdown.close();
    this.dataRedirectionService.storeNavigationContext(
      ApplicationConstants.SUPPLIER,
      ApplicationConstants.MY_DASHBOARD,
      '',
      this.auctionEntityDto?.sequenceNo?.toString() ?? '',
      undefined
    );

    this.router.navigate(['/redirectToAuction'], { skipLocationChange: true });
  }

  closeNavMenu() {
    this.primaryNav?.setAttribute('data-visible', 'false');
    this.navToggle?.setAttribute('aria-expanded', 'false');
    this.navToggle?.setAttribute('data-state', 'closed');
    this.menuDropdown?.close();
  }

  logout(ngbDropdown?: NgbDropdown) {
    this.appLoader?.openLoaderIcon(
      ApplicationConstants.USER_REGISTRATION_PROGRESS,
      'Please wait logging out...'
    );
    this.authService.logout(this.appLoader);
    this.closeNavMenu();
    if (ngbDropdown) ngbDropdown.close();
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  openMessageDialog(content: any) {
    this.ngbModal.open(content, { centered: true });
  }

  ngOnDestroy(): void {
    if (this.userEntitySubscription$) {
      this.userEntitySubscription$.unsubscribe();
    }
    if (this.organizationSubscription$) {
      this.organizationSubscription$.unsubscribe();
    }
    if (this.lastMessageSubscription$) {
      this.lastMessageSubscription$.unsubscribe();
    }
  }
}



