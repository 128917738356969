<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>ADD PROJECT</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleSubmit()">
        <div class="form-floating mb-3">
          <input type="text" class="form-control"  formControlName="rfxTitle" id="rfxTitle"
            [ngClass]="{'is-invalid': fc.rfxTitle.invalid && (fc.rfxTitle.dirty || fc.rfxTitle.touched)}">
          <label for="rfxTitle">Project Title</label>
          <div *ngIf="isSubmitted || fc.rfxTitle.invalid && (fc.rfxTitle.dirty || fc.rfxTitle.touched)">
            <p class="text-danger" *ngIf="fc.rfxTitle.errors?.required">Required
            </p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <select id="plantCode" class="form-control form-select" formControlName="plantCode"
            [ngClass]="{'is-invalid': fc['plantCode'].invalid && (fc['plantCode'].dirty || fc['plantCode'].touched)}">
            <option value="" disabled>Select . . .</option>
            <option [value]="item.plantCode" *ngFor="let item of plantUiDtos">{{item.plantName}}-({{item.plantCode}})
            </option>
          </select>
          <label for="plantCode">Plant</label>
          <div *ngIf="fc['plantCode'].invalid && (fc['plantCode'].dirty || fc['plantCode'].touched)">
            <p class="text-danger" *ngIf="fc['plantCode'].errors?.['required']">Required</p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <select class="form-control form-select" formControlName="costCenterCode" id="costCenterCode"
            [ngClass]="{'is-invalid': fc['costCenterCode'].invalid && (fc['costCenterCode'].dirty || fc['costCenterCode'].touched)}">
            <option value="" disabled>Select . . .</option>
            <option value="ADMIN">Administrative</option>
            <option value="HR">Human Resources</option>
            <option value="FIN">Finance</option>
            <option value="IT">Information Technology (IT)</option>
            <option value="SALES">Sales and Marketing</option>
            <option value="PROD">Production</option>
            <option value="LOG">Logistics</option>
            <option value="FAC">Facilities</option>
            <option value="PROC">Procurement</option>
            <option value="CS">Customer Support</option>
            <option value="TRNDEV">Training and Development</option>
            <option value="COMP">Compliance and Audit</option>
            <option value="HSE">Health and Safety</option>
            <option value="RND">Research and Development</option>
            <option value="LEGAL">Legal</option>
            <option value="MKTG">Marketing</option>
            <option value="CSR">Corporate Social Responsibility (CSR)</option>
            <option value="PM">Project Management</option>
            <option value="QM">Quality Management</option>
          </select>

          <label for="costCenterCode">Cost Center</label>
          <div *ngIf="fc['costCenterCode'].invalid && (fc['costCenterCode'].dirty || fc['costCenterCode'].touched)">
            <p class="text-danger" *ngIf="fc['costCenterCode'].errors?.['required']">Required</p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <select (change)="onChangeCurrency($event)" name="Select Timezone" class="form-control form-select"
            formControlName="currency" id="currency"
            [ngClass]="{'is-invalid': fc.currency.invalid && (fc.currency.dirty || fc.currency.touched)}">
            <option value="" disabled>Select . . .</option>
            <option [value]="item.name" [selected]="item.name == selectedCurrency?.name"
              *ngFor="let item of currencies">{{item.name}}</option>
          </select>

          <label for="currency">Currency {{selectedCurrency?.symbol}}</label>
          <div *ngIf="isSubmitted || fc.currency.invalid && (fc.currency.dirty || fc.currency.touched)">
            <p class="text-danger" *ngIf="fc.currency.errors?.required">Required</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="estSpend" id="estSpend" (blur)="formatEstSpendValue()"
                [ngClass]="{'is-invalid': fc.estSpend.invalid && (fc.estSpend.dirty || fc.estSpend.touched)}">
              <label for="estSpend" >Est Spend</label>
              <div *ngIf="isSubmitted || fc.estSpend.invalid && (fc.estSpend.dirty || fc.estSpend.touched)">
                <p class="text-danger" *ngIf="fc.estSpend.errors?.required">Required</p>
                <p class="text-danger" *ngIf="invalidPriceValidator(fc.estSpend)">Invalid</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-3">
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="estSaving" id="estSaving" (blur)="formatEstSavingValue()"
                [ngClass]="{'is-invalid': fc.estSaving.invalid && (fc.estSaving.dirty || fc.estSaving.touched)}">
              <label for="estSaving">Est Saving</label>
              <div *ngIf="isSubmitted || fc.estSaving.invalid && (fc.estSaving.dirty || fc.estSaving.touched)">
                <p class="text-danger" *ngIf="fc.estSaving.errors?.required">Required</p>
                <p class="text-danger" *ngIf="invalidPriceValidator(fc.estSaving)">Invalid</p>
              </div>
            </div>
          </div>
        </div>

        <div class="form-floating mb-3">
          <select name="Select Timezone" class="form-control form-select" id="timeZone" formControlName="timeZone"
            [ngClass]="{'is-invalid': fc.timeZone.invalid && (fc.timeZone.dirty || fc.timeZone.touched)}">
            <option value="" disabled>Select . . .</option>
            <option [value]="item?.locale" *ngFor="let item of timezones">{{item?.fullName}}</option>
          </select>
          <label for="timeZone">Select Timezone</label>
          <div *ngIf="isSubmitted || fc.timeZone.invalid && (fc.timeZone.dirty || fc.timeZone.touched)">
            <p class="text-danger" *ngIf="fc.timeZone.errors?.required">Required</p>
          </div>
        </div>

        <!-- <div class="row mt-3 mb-3" >
          <div class="col-md-8">Allow Edit While Project is Upcoming:</div>
          <div class="col-md-2 col-md-6">
            <input type="radio" formControlName="allowEditWhileUpcoming" id="allowEditWhileUpcoming1" [value]="true" (click)="openEditingConfirmModal(true, 'LIVE_WAIT')">
            <label class="ms-3" for="allowEditWhileUpcoming1">Yes</label><br>
          </div>
          <div class="col-md-2 col-md-6">
            <input type="radio" formControlName="allowEditWhileUpcoming" id="allowEditWhileUpcoming2" [value]="false" (click)="openEditingConfirmModal(false, 'LIVE_WAIT')">
            <label class="ms-3" for="allowEditWhileUpcoming2">No</label><br>
          </div>
        </div>

        <div class="row mt-3 mb-3" >
          <div class="col-md-8">Allow Edit While Project is Running:</div>
          <div class="col-md-2 col-md-6">
            <input type="radio" formControlName="allowEditWhileRunning" id="allowEditWhileRunning1" [value]="true" (click)="openEditingConfirmModal(true, 'LIVE')">
            <label class="ms-3" for="allowEditWhileRunning1">Yes</label><br>
          </div>
          <div class="col-md-2 col-md-6">
            <input type="radio" formControlName="allowEditWhileRunning" id="allowEditWhileRunning2" [value]="false" (click)="openEditingConfirmModal(false, 'LIVE')">
            <label class="ms-3" for="allowEditWhileRunning2">No</label><br>
          </div>
        </div> -->

        <div class="row mb-3">
          <div class="col-md-6 mb-3">
            <div class="input-group mb-3 mb-md-0">
              <span class="border  border-end-0 pt-3 px-1"
                [ngClass]="{'border-danger': fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)}">
                <!-- <span class="material-symbols-outlined ">work</span> -->
                <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
              </span>

              <div class="form-floating  ">

                <input type="text" ngbDatepicker [minDate]="minDate" #startDate="ngbDatepicker"
                  (click)="startDate.toggle()" class="form-control border-start-0" placeholder="Start Date"
                  formControlName="startDate" id="startDate"
                  [ngClass]="{'is-invalid': fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)}">
                <label for="startDate" class="">Start Date</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)">
              <p class="text-danger" *ngIf="fc.startDate.errors?.required">Required
              </p>
            </div>
          </div>
          
          <div class="col-md-6 mb-3">
            <div class="input-group mb-3 mb-md-0">
              <span class="border  border-end-0 pt-3 px-1"
                [ngClass]="{'border-danger': fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)}">
                <!-- <span class="material-symbols-outlined ">work</span> -->
                <img src="assets/icons/time_light.svg" class="svg-icon-class c-pointer">
              </span>
              <div class="form-floating ">
                <input type="text" class="form-control border-start-0" ngbPopover [autoClose]="'outside'"
                  placement="left" triggers="manual" #p1="ngbPopover" (click)="openTimepicker(p1, startTimeModal)"
                  placeholder="Start Time" formControlName="startTime" id="startTime"
                  [ngClass]="{'is-invalid': fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)}">
                <label for="startTime">Start Time</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)">
              <p class="text-danger" *ngIf="fc.startTime.errors?.required">Required</p>
              <p class="text-danger" *ngIf="fc.startTime.errors?.pattern">Invalid</p>
            </div>

          </div>
          <div class="col-md-6 mb-3">
            <div class="form-floating">
              <input type="text" class="form-control" id="leadTime" formControlName="leadTime"
                [ngClass]="{'is-invalid': fc['leadTime'].invalid && (fc['leadTime'].dirty || fc['leadTime'].touched)}">
              <label for="leadTime">Lead Time (in days)</label>
              <div *ngIf="fc['leadTime'].invalid && (fc['leadTime'].dirty || fc['leadTime'].touched)">
                <p class="text-danger" *ngIf="fc['leadTime'].errors?.['required']">Required</p>
                <p class="text-danger" *ngIf="invalidPriceValidator(fc['leadTime'])">Invalid</p>
              </div>
            </div>
          </div>
  
          <div class="col-md-6 mb-3 ">
            <div class="input-group mb-md-0">
              <span class="border border-end-0 pt-3 px-1"
                [ngClass]="{'border-danger': fc.estimatedDeliveryDate.invalid && (fc.estimatedDeliveryDate.dirty || fc.estimatedDeliveryDate.touched)}">
                <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
              </span>
  
              <div class="form-floating">
                <input type="text" ngbDatepicker [minDate]="minDate" #estimatedDeliveryDate="ngbDatepicker"
                  (click)="estimatedDeliveryDate.toggle()" class="form-control border-start-0" placeholder="Start Date"
                  formControlName="estimatedDeliveryDate" id="estimatedDeliveryDate"
                  [ngClass]="{'is-invalid': fc.estimatedDeliveryDate.invalid && (fc.estimatedDeliveryDate.dirty || fc.estimatedDeliveryDate.touched)}">
                <label for="estimatedDeliveryDate" class="">Estimated Delivery Date</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.estimatedDeliveryDate.invalid && (fc.estimatedDeliveryDate.dirty || fc.estimatedDeliveryDate.touched)">
              <p class="text-danger" *ngIf="fc.estimatedDeliveryDate.errors?.required">Required
              </p>
            </div>
          </div>
        </div>

      
        
        <div class="my-3">
          <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            [message]="'Project created successfully.'"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class" (click)="handleSubmit()">ADD</button>
        </div>
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>
      </form>
    </div>
  </div>
</div>


<!-- NGB TIME MODAL -->
<ng-template #startTimeModal>
  <ngb-timepicker [(ngModel)]="startTime" [meridian]="true"></ngb-timepicker>
  <button class="btn btn-new-class btn-sm" (click)="setTime('startTime')">SAVE</button>
</ng-template>
