<!-- <div class="col-12 mb-3" *ngIf="currentView == 'AUCTION_HOUSE_SETTING'">
  <div class="row">
    <div class="col-6">
      <h5>Auction House</h5>
    </div>
    <div class="col-6 text-end">
      <button class="btn btn-outline-primary btn-sm px-4" (click)="openEditAuctionHouse()">EDIT</button>
    </div>
  </div>
</div> -->
<div class="mt-3 ">
  <div class="">
    <div class="row">
      <div class="col-md-5">
    <h5 class="heading-h5 me-3">CLIENT PORTAL:</h5>
    <h5 class="heading-h5 text-capitalize ">
      {{auctionHouse?.name}}
    </h5>
  </div>
  <div class="col-md-7 text-md-end">
      <button class="btn btn-new-class btn-sm px-4 mb-2" (click)="openEditAuctionHouse()">
        EDIT
        <!-- <span> <img src="assets/icons/edit_dark.svg" class=" c-pointer svg-icon-class"></span> -->
      </button>
  </div>
  </div>

    <!-- <span> <img src="assets/icons/settings_dark.svg" class=" c-pointer svg-icon-class"  *ngIf="currentViewTab != 'AUCTION_HOUSE_SETTING'"></span> -->
    <div class="border bg-white p-2">
      <div class="row">
        <div class="col-md-10  mb-2">

          <p class="p-class animate-show-more" #descriptionRef [innerHTML]="auctionHouse?.description ?? '' | safe: 'html'" [ngClass]="{'hide-description': !(isShowDescription$ |async)}"></p>
          <div class="bold-border my-2" *ngIf="descriptionRef.scrollHeight > 120"></div>
          <div class="col-12 text-end ">
            <span class="btn-link"  (click)="showOrHideDescription()" *ngIf="descriptionRef.scrollHeight > 120">{{(isShowDescription$ |async) ? 'HIDE MORE' : 'SHOW MORE'}}</span>
          </div>
          <div class="div-class">
            Corporate Website Link : <a [href]="auctionHouse?.corporateUrl" target="_blank"><span class="btn-link text-lowercase">{{auctionHouse?.corporateUrl}}</span></a>
          </div>
        </div>
        <div class="col-md-2 text-md-end text-start mb-2">
          <img src="/downloadLandingBlob?fileId={{auctionHouse?.bannerImage?.fileId}}" class="logo-image"
            [alt]="auctionHouse?.name" *ngIf="auctionHouse?.bannerImage">
          <img src="assets/logo/auctionext-2.png" class="logo-image" [alt]="auctionHouse?.name"
            *ngIf="!auctionHouse?.bannerImage">
        </div>

      </div>
    </div>
  </div>
</div>
