import { UserRatingDto } from './../models/user/UserRatingDto';
import { Injectable, OnDestroy } from '@angular/core';
import { collection, doc, DocumentData, Firestore, getDoc, onSnapshot, query, setDoc, where } from '@angular/fire/firestore';
import { AdminDashboardService } from './admin-dashboard.service';
import { UserService } from './user.service';
import { LandingService } from './LandingAndBidder.service';
import { AuctionQuestionDto } from '../models/user/AuctionQuestionDto';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { EmailTemplateDto } from '../models/user/EmailTemplateDto';
import { SuperAdminDashboardService } from './super-admin-dashboard.service';
import { Unsubscribe } from '@angular/fire/auth';
import { MasterDataDto } from '../models/MasterDataDto';
import { ConfigConsoleService } from './config-console.service';
import { DeploymentConfigurationDto } from '../models/user/DeploymentConfigurationDto';

@Injectable({
  providedIn: 'root'
})
export class FirestoreListenerService implements OnDestroy{
  collectionAuctions: string = "AUCTIONS";
  collectionAuctionLots: string = "AUCTION_LOTS";
  collectionAuctionHouses: string = "AUCTION_HOUSES";
  collectionMasterData: string = "MASTER_DATA";
  collectionUserMaxBids: string = "USER_MAX_BIDS";
  collectionUserBids: string = "USER_BIDS";
  collectionBidsHighestData: string = "BIDS_HIGHEST_DATA";
  collectionUserAuctionRegistration: string = "USER_AUCTION_REGISTRATION";
  collectionUserOffer: string = "USER_OFFER";
  collectionUserLotFavourite: string = "USER_LOT_FAVOURITE";
  collectionDeploymentConfiguration: string = "DEPLOYMENT_CONFIGURATION";
  collectionUserRating: string = "USER_RATING";
  collectionAuctionQuestions: string = "AUCTION_QUESTIONS";
  collectionEmailTemplate: string = "EMAIL_TEMPLATE";



  auctionHouseUnsubscribe: Unsubscribe | undefined;
  allLotsOfPostAuctionsUnsubscribe: Unsubscribe | undefined;
  maxOfferPriceOnLotUnsubscribe: Unsubscribe | undefined;
  userOfferPriceOnLotUnsubscribe: Unsubscribe | undefined;
  userLotFavoriteUnsubscribe: Unsubscribe | undefined;
  userAuctionRegistrationUnsubscribe: Unsubscribe | undefined;
  myAuctionRegistrationsUnsubscribe: Unsubscribe | undefined;
  allUserAuctionRegistrationsUnsubscribe: Unsubscribe | undefined;
  userBidsOfLotUnsubscribe: Unsubscribe | undefined;
  userMaxBidsOfLotUnsubscribe: Unsubscribe | undefined;
  highestDataOfLotUnsubscribe: Unsubscribe | undefined;
  totalBidsOfAuctionUnsubscribe: Unsubscribe | undefined;
  auctionQuestionsUnsubscribe: Unsubscribe | undefined;
  userRatingsUnsubscribe: Unsubscribe | undefined;
  emailTemplateUnsubscribe: Unsubscribe | undefined;
  auctionQuestionsForAdminUnsubscribe: Unsubscribe | undefined;

  constructor(
    private firestore: Firestore,
    private userService: UserService,
    private adminService: AdminDashboardService,
    private landingService: LandingService,
    private superAdminService: SuperAdminDashboardService,
    private configConsoleService: ConfigConsoleService
  ) { }


  // loadInitialData() {
  //   // this.serverDataFetchService.loadMasterDataFromServer();
  //   // this.getAllAuctionsBasedOnDomainName();
  //   this.queryAuctionHouseBasedOnDomainName();

  //   logEvent(getAnalytics(), 'loadInitialData');
  // }



  // initializeBidsListener(auctionId: string) {
  //   this.bidderService.clearAllBidsDataLoaded();

  //   this.listenUserBidsOfLot(auctionId);
  //   this.listenUserMaxBidsOfLot(auctionId);
  //   this.listenUserAuctionRegistration(auctionId);
  // }

  // initializeLotListener(auctionId: string, lotId: string) {
  //   this.listenMaxOfferPriceOnLot(auctionId, lotId);
  //   this.listenHighestBidDataOfLot(auctionId, lotId);
  //   this.listenUserLotFavorite(auctionId, lotId);
  //   if (this.userService.getUserEntity()) {
  //     this.listenUserOfferPriceOnLot(auctionId, lotId, this.userService.getUserEntity().userId!);
  //   }
  // }

    // Save Auction Questions
    async saveAuctionQuestion(auctionQuestionDto: AuctionQuestionDto) {

      logEvent(getAnalytics(), 'saveAuctionQuestion');

      let docId = auctionQuestionDto.id;

      let auctionQuestionRef = doc(this.firestore, this.collectionAuctionQuestions, docId!);
      return setDoc(auctionQuestionRef, Object.assign({}, auctionQuestionDto), { merge: true });
    }



  /**
   * Read the auction house based on auction house Id.
   */
  // queryAuctionHouseBasedOnDomainName() {

  //   logEvent(getAnalytics(), 'queryAuctionHouseBasedOnDomainName');

  //   this.adminService.setAuctionHouseDataLoaded(false);
  //   let domainName = ApplicationUtils.getSubDomainName();
  //   if (domainName) {
  //     let auctionHouseRef = collection(this.firestore, this.collectionAuctionHouses);
  //     const auctionHouseQuery = query(auctionHouseRef, where('domainName', '==', domainName), where('active', '==', true));

  //   this.auctionHouseUnsubscribe =  onSnapshot(auctionHouseQuery, (querySnapshot) => {
  //       let auctionsHouses = querySnapshot.docs.map(item => item.data());

  //       logEvent(getAnalytics(), 'queryAuctionHouseBasedOnDomainName Data Received size : ' +  auctionsHouses.length);
  //       console.log("queryAuctionHouseBasedOnDomainName Data Received size: " + auctionsHouses.length);

  //       let auctionHouse = auctionsHouses[0] as AuctionHouseDto;

  //       if (auctionHouse == undefined) {
  //         auctionHouse = new AuctionHouseDto();
  //         auctionHouse.isAvailable = false;
  //       } else {
  //         auctionHouse.isAvailable = true;
  //         // this.listenTotalBidsOfAuction(auctionHouse.auctionHouseId!);
  //       }

  //       this.adminService.updateCurrentAuctionHouse(auctionHouse)
  //       this.bidderService.updateCurrentAuctionHouse(auctionHouse)
  //       this.adminService.setAuctionHouseDataLoaded(true);
  //     })
  //   }
  // }

  /// This method has been commented out because we do not want to retrieve all the auctions for the auction house from the Firestore database in a single operation.
  /**
   * Read all the auctions listed against auction house.
   * Auction house Id is available in session Info, as user can login to only one
   * URL to Bid.
   */
  // getAllAuctionsOfAuctionHouse() {
  //   this.adminService.setAllAuctionDataLoaded(false);
  //   let sessionInfoDto = this.userService?.getSessionInfo;
  //   if (sessionInfoDto) {
  //     let auctionRef = collection(this.firestore, this.collectionAuctions);
  //     const auctionQuery = query(auctionRef, where('auctionHouseId', '==', sessionInfoDto!.auctionHouseId), where('status', '!=', 'DISCARD'));

  //     onSnapshot(auctionQuery, (querySnapshot) => {
  //       let auctionsList = querySnapshot.docs.map(item => item.data());
  //       this.auctionService.updateAuctionData(auctionsList);
  //       this.adminService.setAllAuctionDataLoaded(true);
  //     })
  //   }
  // }


  /// This method has been commented out because we do not want to retrieve all the auctions for the auction house from the Firestore database in a single operation.
  /**
 * Read all the auctions listed against auction house.
 * Auction house Id is available in session Info, as user can login to only one
 * URL to Bid.
 */
  // getAllAuctionsOfAuctionHouseAndAgent() {
  //   this.adminService.setAllAuctionDataLoaded(false);
  //   let sessionInfoDto = this.userService?.getSessionInfo;
  //   if (sessionInfoDto) {
  //     let auctionRef = collection(this.firestore, this.collectionAuctions);
  //     const auctionQuery = query(auctionRef, where('auctionHouseId', '==', sessionInfoDto!.auctionHouseId), where('createdBy', '==', sessionInfoDto.userEntityDto?.emailId), where('status', '!=', 'DISCARD'));

  //     onSnapshot(auctionQuery, (querySnapshot) => {
  //       let auctionsList = querySnapshot.docs.map(item => item.data());
  //       this.auctionService.updateAuctionData(auctionsList);
  //       this.adminService.setAllAuctionDataLoaded(true);
  //     })
  //   }
  // }

  // async queryAuctionBasedOnAuctionId(auctionId: string) {

  //   logEvent(getAnalytics(), 'queryAuctionBasedOnAuctionId');

  //   let auctionRef = collection(this.firestore, this.collectionAuctions);
  //   let auctionQuery = query(auctionRef, where('auctionId', '==', auctionId));
  //   let auctions = await getDocs(auctionQuery);

  //   logEvent(getAnalytics(), 'queryAuctionBasedOnAuctionId Data Received size : ' +  auctions.docs.length);
  //   console.log("queryAuctionBasedOnAuctionId Data Received size: " + auctions.docs.length);

  //   if (auctions.docs.length > 0) {
  //     return auctions.docs.map(item => item.data())[0];
  //   }
  //   return undefined;
  // }

  // async queryLotBasedOnAuctionIdAndLotId(auctionId: string, lotId: string) {

  //   logEvent(getAnalytics(), 'queryLotBasedOnAuctionIdAndLotId');

  //   let lotRef = collection(this.firestore, this.collectionAuctionLots);
  //   let lotQuery = query(lotRef, where('auctionId', '==', auctionId), where('lotId', '==', lotId));
  //   let lots = await getDocs(lotQuery);

  //   logEvent(getAnalytics(), 'queryLotBasedOnAuctionIdAndLotId Data Received size : ' +  lots.docs.length);
  //   console.log("queryLotBasedOnAuctionIdAndLotId Data Received size: " + lots.docs.length);

  //   if (lots.docs.length > 0) {
  //     return lots.docs.map(item => item.data())[0];
  //   }
  //   return undefined;
  // }

  // async queryLotBasedOnAuctionHouseIdAndCategorySubcategoryId(auctionHouseId: string, categorySubcategoryId: string, auctionIds: Array<string>) {

  //   logEvent(getAnalytics(), 'queryLotBasedOnAuctionHouseIdAndCategorySubcategoryId');

  //   let lotRef = collection(this.firestore, this.collectionAuctionLots);
  //   let lotQuery = query(lotRef, where('auctionHouseId', '==', auctionHouseId), where('categorySubcategoryId', '==', categorySubcategoryId),  where('auctionId', 'in', auctionIds),  where('active', '==', true));
  //   let lots = await getDocs(lotQuery);

  //   logEvent(getAnalytics(), 'queryLotBasedOnAuctionHouseIdAndCategorySubcategoryId Data Received size : ' +  lots.docs.length);
  //   console.log("queryLotBasedOnAuctionHouseIdAndCategorySubcategoryId Data Received size: " + lots.docs.length);

  //   if (lots.docs.length > 0) {
  //     return lots.docs.map(item => item.data());
  //   }
  //   return undefined;
  // }

  // async queySingleLotBasedOnAuctionId(auctionId: string) {

  //   logEvent(getAnalytics(), 'queySingleLotBasedOnAuctionId');

  //   let lotRef = collection(this.firestore, this.collectionAuctionLots);
  //   let lotQuery = query(lotRef, where('auctionId', '==', auctionId), where('active', '==', true));
  //   let lots = await getDocs(lotQuery);

  //   logEvent(getAnalytics(), 'queySingleLotBasedOnAuctionId Data Received size : ' +  lots.docs.length);
  //   console.log("queySingleLotBasedOnAuctionId Data Received size: " + lots.docs.length);

  //   if (lots.docs.length > 0) {
  //     return lots.docs.map(item => item.data())[0];
  //   }
  //   return undefined;
  // }

  // async queryAuctionBasedOnAuctionSequence(sequenceNo: number) {
  //   logEvent(getAnalytics(), 'queryAuctionBasedOnAuctionSequence');
  //   let auctionRef = collection(this.firestore, this.collectionAuctions);
  //   let auctionQuery = query(auctionRef, where('sequenceNo', '==', sequenceNo));
  //   let auctions = await getDocs(auctionQuery);

  //   logEvent(getAnalytics(), 'queryAuctionBasedOnAuctionSequence Data Received size : ' +  auctions.docs.length);
  //   console.log("queryAuctionBasedOnAuctionSequence Data Received size: " + auctions.docs.length);

  //   if (auctions.docs.length > 0) {
  //     return auctions.docs.map(item => item.data())[0];
  //   }
  //   return undefined;
  // }

  // async queryLotBasedOnAuctionIdAndLotSequence(auctionId: string, lotSequence: number) {

  //   logEvent(getAnalytics(), 'queryLotBasedOnAuctionIdAndLotSequence');

  //   let lotRef = collection(this.firestore, this.collectionAuctionLots);
  //   let lotQuery = query(lotRef, where('auctionId', '==', auctionId), where('lotSequence', '==', lotSequence));
  //   let lots = await getDocs(lotQuery);

  //   logEvent(getAnalytics(), 'queryLotBasedOnAuctionIdAndLotSequence Data Received size : ' +  lots.docs.length);
  //   console.log("queryLotBasedOnAuctionIdAndLotSequence Data Received size: " + lots.docs.length);

  //   if (lots.docs.length > 0) {
  //     return lots.docs.map(item => item.data())[0];
  //   }
  //   return undefined;
  // }

  /// This method has been commented out because we do not want to retrieve all the auctions for the auction house from the Firestore database in a single operation.
  // getAllAuctionsBasedOnDomainName() {
  //   let domainName = ApplicationUtils.getSubDomainName();
  //   if (domainName) {
  //     let auctionRef = collection(this.firestore, this.collectionAuctions);
  //     const auctionQuery = query(auctionRef, where('domainName', '==', domainName), where('active', '==', true), where('status', '!=', 'DISCARD'));

  //     onSnapshot(auctionQuery, (querySnapshot) => {
  //       let auctionsList = querySnapshot.docs.map(item => item.data());
  //       this.bidderService.updateAuctionData(auctionsList);
  //     })
  //   }
  // }

  /**
   * Read all the lots of given auction and auction house.  (Use for Admin)
   * @param auctionId
   */
  // getAllLotsOfAuction(auctionId: string) {
  //   this.adminService.setAllLotsDataLoaded(false);
  //   let auctionRef = collection(this.firestore, this.collectionAuctionLots);
  //   const auctionLotQuery = query(auctionRef, where('auctionId', '==', auctionId), where('discardedLot', '==', false));

  //   onSnapshot(auctionLotQuery, (querySnapshot) => {
  //     let auctionLotList = querySnapshot.docs.map(item => AuctionLotEntityDto.parseAuctionLotEntityDto(item.data()));
  //     this.auctionLotService.updateLotsData(auctionLotList);
  //     this.adminService.setAllLotsDataLoaded(true);
  //   })
  // }

  /**
   * All Active Lots list (Use for Bidder)
   * @param auctionId
   */
  // getAllActiveLotsOfAuction(auctionId: string) {
  //   this.bidderService.clearLotsDataLoaded();

  //   let auctionRef = collection(this.firestore, this.collectionAuctionLots);
  //   const auctionLotQuery = query(auctionRef, where('auctionId', '==', auctionId), where('active', '==', true), where('discardedLot', '==', false));

  //   onSnapshot(auctionLotQuery, (querySnapshot) => {
  //     let auctionLotList = querySnapshot.docs.map(item => AuctionLotEntityDto.parseAuctionLotEntityDto(item.data()));
  //     this.bidderService.updateAllLotsList(auctionLotList);
  //   })
  // }

  // listenAllLotsOfPostAuctions(auctionIdsSet: string[]) {

  //   logEvent(getAnalytics(), 'listenAllLotsOfPostAuctions');

  //   let lotRef = collection(this.firestore, this.collectionAuctionLots);

  //   const lotsQuery = query(lotRef, where('auctionId', 'in', Array.from(auctionIdsSet)), where('discardedLot', '==', false));
  //   this.allLotsOfPostAuctionsUnsubscribe = onSnapshot(lotsQuery, querySnapshots => {
  //     const lotsData = querySnapshots.docs.map((doc) => doc.data());

  //     logEvent(getAnalytics(), 'listenAllLotsOfPostAuctions Data Received size : ' +  lotsData.length);
  //     console.log("listenAllLotsOfPostAuctions Data Received size: " + lotsData.length);

  //     this.auctionLotService.updateAllPostAuctionLots(lotsData);
  //   });
  // }

  // async queryNextClosingLotOfAuction(auctionId: string){

  //   logEvent(getAnalytics(), 'queryNextClosingLotOfAuction');

  //   let auctionRef = collection(this.firestore, this.collectionAuctionLots);
  //   const auctionLotQuery = query(auctionRef, where('auctionId', '==', auctionId), where('active', '==', true),
  //     where('discardedLot', '==', false), where('endDateUTC', '>', new Date()), orderBy('endDateUTC', 'asc'), limit(1));

  //   return (await getDocs(auctionLotQuery)).docs.map(item => item.data());
  // }


  // listenMaxOfferPriceOnLot(auctionId: string, lotId: string) {
  //   logEvent(getAnalytics(), 'listenMaxOfferPriceOnLot');
  //   if (auctionId && lotId) {
  //     let offerRef = collection(this.firestore, this.collectionUserOffer);
  //     const offerQuery = query(offerRef, where('auctionId', '==', auctionId), where('lotId', '==', lotId));

  //     this.maxOfferPriceOnLotUnsubscribe = onSnapshot(offerQuery, (querySnapshot) => {
  //       let docs = querySnapshot.docs.map(item => item.data()) as UserOfferEntityDto[];

  //       logEvent(getAnalytics(), 'listenMaxOfferPriceOnLot Data Received size : ' +  docs.length);
  //       console.log("listenMaxOfferPriceOnLot Data Received size: " + docs.length);

  //       if (docs.length > 0) {
  //         docs.sort((a, b) => Number(b.offerPrice) - Number(a.offerPrice));
  //         this.bidderService.updateMaxOfferEntityDto(docs[0]);
  //       } else {
  //         this.bidderService.updateMaxOfferEntityDto(undefined);
  //       }
  //     })
  //   }
  // }

  // listenUserOfferPriceOnLot(auctionId: string, lotId: string, userId: string) {

  //   logEvent(getAnalytics(), 'listenUserOfferPriceOnLot');

  //   if (auctionId && lotId) {
  //     let offerRef = collection(this.firestore, this.collectionUserOffer);
  //     const offerQuery = query(offerRef, where('auctionId', '==', auctionId), where('lotId', '==', lotId), where('userId', '==', userId), orderBy("offerPrice", "desc"), limit(1));

  //     this.userOfferPriceOnLotUnsubscribe =  onSnapshot(offerQuery, (querySnapshot) => {
  //       let docs = querySnapshot.docs.map(item => item.data());

  //       logEvent(getAnalytics(), 'listenUserOfferPriceOnLot Data Received size : ' +  docs.length);
  //       console.log("listenUserOfferPriceOnLot Data Received size: " + docs.length);

  //       if (docs.length > 0) {
  //         this.bidderService.updateUserOfferEntityDto(docs[0]);
  //       } else {
  //         this.bidderService.updateUserOfferEntityDto(undefined);
  //       }
  //     })
  //   }
  // }

  // listenUserLotFavorite(auctionId: string, lotId: string) {

  //   logEvent(getAnalytics(), 'listenUserLotFavorite');

  //   let userEntityDto = this.userService?.getUserEntity();
  //   if (userEntityDto) {
  //     let auctionRef = collection(this.firestore, this.collectionUserLotFavourite);
  //     const auctionLotQuery = query(auctionRef, where('auctionId', '==', auctionId), where('lotId', '==', lotId), where('userId', '==', userEntityDto.userId));

  //     this.userLotFavoriteUnsubscribe  =  onSnapshot(auctionLotQuery, (querySnapshot) => {
  //       let auctionLotFavoriteData = querySnapshot.docs.map(item => item.data());

  //       logEvent(getAnalytics(), 'listenUserLotFavorite Data Received size : ' +  auctionLotFavoriteData.length);
  //       console.log("listenUserLotFavorite Data Received size: " + auctionLotFavoriteData.length);

  //       if (auctionLotFavoriteData.length > 0) {
  //         this.bidderService.updateCurrentLotFavStatus(true);
  //       } else {
  //         this.bidderService.updateCurrentLotFavStatus(false);
  //       }
  //     })
  //   }
  // }


  // listenUserAuctionRegistration(auctionId: string) {

  //   logEvent(getAnalytics(), 'listenUserAuctionRegistration');

  //   let userEntityDto = this.userService?.getUserEntity();

  //   if (userEntityDto) {
  //     let collectionRef = collection(this.firestore, this.collectionUserAuctionRegistration);
  //     const registrationQuery = query(collectionRef, where('auctionId', '==', auctionId), where('userId', '==', userEntityDto?.userId));

  //     this.userAuctionRegistrationUnsubscribe =  onSnapshot(registrationQuery, (querySnapshot) => {
  //       let registration = querySnapshot.docs.map(item => item.data());

  //       logEvent(getAnalytics(), 'listenUserAuctionRegistration Data Received size : ' +  registration.length);
  //       console.log("listenUserAuctionRegistration Data Received size: " + registration.length);

  //       if (registration && registration.length > 0) {
  //         this.bidderService.updateUserAuctionRegistration(registration[0]);
  //       } else {
  //         this.bidderService.updateUserAuctionRegistration(null)
  //       }
  //     })
  //   }
  // }

  // /**
  //  * Read all the user bids of given auction.
  //  * @param auctionId
  //  */
  // listenUserBidsOfLot(auctionId: string) {

  //   logEvent(getAnalytics(), 'listenUserBidsOfLot');

  //   let userEntityDto = this.userService?.getUserEntity();
  //   if (userEntityDto) {
  //     let auctionRef = collection(this.firestore, this.collectionUserBids);
  //     const userBidsQuery = query(auctionRef, where('auctionId', '==', auctionId), where('userId', '==', userEntityDto?.userId));

  //     this.userBidsOfLotUnsubscribe = onSnapshot(userBidsQuery, (querySnapshot) => {
  //       let userBids = querySnapshot.docs.map(item => item.data());

  //       logEvent(getAnalytics(), 'listenUserBidsOfLot Data Recieved size : ' +  userBids.length);
  //       console.log("listenUserBidsOfLot Data Recieved size: " + userBids.length);

  //       this.bidderService.updateUserBids(userBids);
  //     })
  //   }
  // }

  // /**
  //  * Read all the user max bids of given auction.
  //  * @param auctionId
  //  */
  // listenUserMaxBidsOfLot(auctionId: string) {

  //   logEvent(getAnalytics(), 'listenUserMaxBidsOfLot');

  //   let userEntityDto = this.userService?.getUserEntity();
  //   if (userEntityDto) {
  //     let auctionRef = collection(this.firestore, this.collectionUserMaxBids);
  //     const userBidsQuery = query(auctionRef, where('auctionId', '==', auctionId), where('userId', '==', userEntityDto?.userId));

  //     this.userMaxBidsOfLotUnsubscribe = onSnapshot(userBidsQuery, (querySnapshot) => {
  //       let userMaxBids = querySnapshot.docs.map(item => item.data());

  //       logEvent(getAnalytics(), 'listenUserMaxBidsOfLot Data Recieved size : ' +  userMaxBids.length);
  //       console.log("listenUserMaxBidsOfLot Data Recieved size: " + userMaxBids.length);

  //       this.bidderService.updateUserMaxBids(userMaxBids);
  //     })
  //   }
  // }

  /**
   * Read Highest Bid Data Of Auction & Lot Id
   * @param auctionId
   */
  // listenHighestBidDataOfLot(auctionId: string, lotId: string) {

  //   logEvent(getAnalytics(), 'listenHighestBidDataOfLot');

  //   let auctionRef = collection(this.firestore, this.collectionBidsHighestData);
  //   const userBidsQuery = query(auctionRef, where('auctionId', '==', auctionId), where('lotId', '==', lotId));

  //   this.highestDataOfLotUnsubscribe = onSnapshot(userBidsQuery, (querySnapshot) => {
  //     let bidsHighestData = querySnapshot.docs.map(item => AuctionBidsHighestData.parseAuctionBidsHighestData(item.data()));

  //     logEvent(getAnalytics(), 'listenHighestBidDataOfLot Data Recieved size : ' +  bidsHighestData.length);
  //     console.log("listenHighestBidDataOfLot Data Recieved size: " + bidsHighestData.length);

  //     //this.bidderService.updateBidsHighestData(bidsHighestData[0]);
  //   })
  // }

  // listenTotalBidsOfAuction(auctionHouseId: string) {

  //   logEvent(getAnalytics(), 'listenTotalBidsOfAuction');

  //   let auctionRef = collection(this.firestore, this.collectionBidsHighestData);
  //   const userHighestBidsQuery = query(auctionRef, where('auctionHouseId', '==', auctionHouseId));

  //   this.totalBidsOfAuctionUnsubscribe = onSnapshot(userHighestBidsQuery, (querySnapshot) => {
  //     let bidsHighestData = querySnapshot.docs.map(item => AuctionBidsHighestData.parseAuctionBidsHighestData(item.data()));

  //     logEvent(getAnalytics(), 'listenTotalBidsOfAuction Data Recieved size : ' +  bidsHighestData.length);
  //     console.log("listenTotalBidsOfAuction Data Recieved size: " + bidsHighestData.length);

  //     this.bidderService.updateBidsHighestDataOfAuctionHouse(bidsHighestData);
  //   })
  // }


  // Listen Data using auction house
  // async queryMyBidDetailsData() {

  //   logEvent(getAnalytics(), 'queryMyBidDetailsData');

  //   let sessionInfoDto = this.userService?.getSessionInfo;

  //   if (sessionInfoDto) {
  //     let auctionHouseId = sessionInfoDto.auctionHouseId;
  //     let userId = sessionInfoDto.userEntityDto?.userId;

  //     let auctionRef = collection(this.firestore, this.collectionAuctions);
  //     let lotRef = collection(this.firestore, this.collectionAuctionLots);
  //     let userBidsRef = collection(this.firestore, this.collectionUserBids);
  //     let userMaxBidsRef = collection(this.firestore, this.collectionUserMaxBids);
  //     let bidsHighestDataRef = collection(this.firestore, this.collectionBidsHighestData);

  //     const userBidsQuery = query(userBidsRef, where('auctionHouseId', '==', auctionHouseId), where('userId', '==', userId));
  //     const userMaxBidsQuery = query(userMaxBidsRef, where('auctionHouseId', '==', auctionHouseId), where('userId', '==', userId));
  //     const bidsHighestDataQuery = query(bidsHighestDataRef, where('auctionHouseId', '==', auctionHouseId));

  //     Promise.all([
  //       await getDocs(userBidsQuery),
  //       await getDocs(userMaxBidsQuery),
  //       await getDocs(bidsHighestDataQuery),
  //     ]).then(async (querySnapshots) => {
  //       const userBidsData = querySnapshots[0].docs.map((doc) => doc.data());
  //       const userMaxBidsData = querySnapshots[1].docs.map((doc) => doc.data());
  //       const bidsHighestData = querySnapshots[2].docs.map((doc) => doc.data());

  //       logEvent(getAnalytics(), 'userBidsData  Recieved size : ' +  userBidsData.length);
  //       console.log("userBidsData Recieved size: " + userBidsData.length);

  //       logEvent(getAnalytics(), 'userMaxBidsData  Recieved size : ' +  userMaxBidsData.length);
  //       console.log("userMaxBidsData  Recieved size: " + userMaxBidsData.length);

  //       logEvent(getAnalytics(), 'bidsHighestData  Recieved size : ' +  bidsHighestData.length);
  //       console.log("bidsHighestData  Recieved size: " + bidsHighestData.length);

  //       let auctionIdsUserBids = userBidsData.map((item) => item?.['auctionId']);
  //       let auctionIdsUserMaxBids = userMaxBidsData.map((item) => item?.['auctionId']);
  //       let lotIdsUserBids = userBidsData.map((item) => item?.['lotId']);
  //       let lotIdsUserMaxBids = userMaxBidsData.map((item) => item?.['lotId']);

  //       let auctionIdsSet = new Set([...auctionIdsUserBids, ...auctionIdsUserMaxBids]);
  //       let lotIdsSet = new Set([...lotIdsUserBids, ...lotIdsUserMaxBids]);

  //       if (auctionIdsSet.size == 0) {
  //         let myBidsData = new MyBidsData();
  //         myBidsData.userBidsData = userBidsData;
  //         myBidsData.userMaxBidsData = userMaxBidsData;
  //         myBidsData.bidsHighestData = bidsHighestData;
  //         myBidsData.auctionsData = [];
  //         myBidsData.lotsData = [];

  //         this.bidderService.updateMyBidsData(myBidsData);
  //         return;
  //       }

  //       const auctionQuery = query(auctionRef, where('auctionId', 'in', Array.from(auctionIdsSet)), where('status', '!=', 'DISCARD'));
  //       const lotQuery = query(lotRef, where('lotId', 'in', Array.from(lotIdsSet)), where('discardedLot', '==', false));

  //       Promise.all([
  //         await getDocs(auctionQuery),
  //         await getDocs(lotQuery),
  //       ]).then((querySnapshots2) => {
  //         const auctionsData = querySnapshots2[0].docs.map((doc) => doc.data());
  //         const lotsData = querySnapshots2[1].docs.map((doc) => doc.data());

  //         let myBidsData = new MyBidsData();
  //         myBidsData.userBidsData = userBidsData;
  //         myBidsData.userMaxBidsData = userMaxBidsData;
  //         myBidsData.bidsHighestData = bidsHighestData;
  //         myBidsData.auctionsData = auctionsData;
  //         myBidsData.lotsData = lotsData;

  //         this.bidderService.updateMyBidsData(myBidsData);
  //       }).catch((err) => {
  //         console.error(err);
  //       })

  //     }).catch((err) => {
  //       console.error(err);
  //     })
  //   }
  // }

  // Listen Data using auction house
  // async queryMyFavouriteLots() {
  //   logEvent(getAnalytics(), 'queryMyFavouriteLots');
  //   let sessionInfoDto = this.userService?.getSessionInfo;

  //   if (sessionInfoDto) {
  //     let auctionHouseId = sessionInfoDto.auctionHouseId;
  //     let userId = sessionInfoDto.userEntityDto?.userId;

  //     let lotsRef = collection(this.firestore, this.collectionAuctionLots);
  //     let favouriteDataRef = collection(this.firestore, this.collectionUserLotFavourite);

  //     const favouriteDataQuery = query(favouriteDataRef, where('auctionHouseId', '==', auctionHouseId), where('userId', '==', userId));
  //     const querySnapshotsOfFavourite = await getDocs(favouriteDataQuery);
  //     const favouriteData = querySnapshotsOfFavourite.docs.map((doc) => doc.data());

  //     let lotIds = favouriteData.map((item) => item?.['lotId']);
  //     let lotIdsSet = new Set([...lotIds]);

  //     if (lotIdsSet && lotIdsSet.size == 0) {
  //       return
  //     }
  //     const lotsQuery = query(lotsRef, where('lotId', 'in', Array.from(lotIdsSet)), where('discardedLot', '==', false));
  //     const querySnapshotsOfLots = await getDocs(lotsQuery);
  //     const allAuctionLots = querySnapshotsOfLots.docs.map((doc) => doc.data());

  //     this.bidderService.updateAllLotsList(allAuctionLots);
  //     this.bidderService.updateMyFavoriteAllLots(favouriteData);
  //   }
  // }

  //save rating
  async saveUserRating(userRatingDto: UserRatingDto) {

    logEvent(getAnalytics(), 'saveUserRating');

    let docId = userRatingDto.id;
    let userRatingRef = doc(this.firestore, this.collectionUserRating, docId!);
    return setDoc(userRatingRef, Object.assign({}, userRatingDto), { merge: true });
  }


  // Email Template
  async saveEmailTemplate(emailTemplateDto: EmailTemplateDto) {

    logEvent(getAnalytics(), 'saveEmailTemplate');

    let documentRef = doc(this.firestore, this.collectionEmailTemplate, emailTemplateDto?.documentId!);
    return await setDoc(documentRef, Object.assign({}, emailTemplateDto), { merge: true });

  }

  listenEmailTemplate(orgCode: string) {

    logEvent(getAnalytics(), 'listenEmailTemplate');

    let emailTemplateRef = collection(this.firestore, this.collectionEmailTemplate);
    let emailTemplateQuery = query(emailTemplateRef, where('orgCode', '==', orgCode));

    this.emailTemplateUnsubscribe = onSnapshot(emailTemplateQuery, querySnapshot => {
      let emailTemplates: DocumentData[] = [];

      logEvent(getAnalytics(), 'listenEmailTemplate Data Recieved size : ' +  emailTemplates.length);
      console.log("listenEmailTemplate Data Recieved size: " + emailTemplates.length);

      querySnapshot.docs.map(item => {
        let updatedItem = item.data();
        updatedItem['documentId'] = item.id;
        emailTemplates.push(updatedItem);
      });

      this.superAdminService.updateEmailTemplates(emailTemplates as Array<EmailTemplateDto>);
    })
  }

  listenAuctionQuestionsForAdmin(auctionId: string) {

    logEvent(getAnalytics(), 'listenAuctionQuestionsForAdmin');

    let auctionQuestionRef = collection(this.firestore, this.collectionAuctionQuestions);
    let auctionQuestionQuery = query(auctionQuestionRef, where('auctionId', '==', auctionId));
    this.auctionQuestionsForAdminUnsubscribe = onSnapshot(auctionQuestionQuery, querySnapshot => {
      let auctionQuestions = querySnapshot.docs.map(item => item.data());

      logEvent(getAnalytics(), 'listenAuctionQuestionsForAdmin Data Recieved size : ' +  auctionQuestions.length);
        console.log("listenAuctionQuestionsForAdmin Data Recieved size: " + auctionQuestions.length);

      this.adminService.updateAuctionQuestions(auctionQuestions);
    })
  }

  // Similar Auctions
  // async querySimilarAuctions(companyId: string) {

  //   logEvent(getAnalytics(), 'querySimilarAuctions');

  //   let collectionRef = collection(this.firestore, this.collectionAuctions);
  //   let auctionQuery = query(collectionRef, where('companyId', '==', companyId), where('status', 'in', ['LIVE', 'LIVE_WAIT']));

  //   let querySnapshots = await getDocs(auctionQuery);
  //   let auctions = querySnapshots.docs.map(item => item.data());

  //   logEvent(getAnalytics(), 'querySimilarAuctions Data Recieved size : ' +  auctions.length);
  //   console.log("querySimilarAuctions Data Recieved size: " + auctions.length);

  //   this.bidderService.updateSimilarAuctionsList(auctions ?? [])
  // }

  /**
   * Clear all the snapshots used for firestore
   */
  clearUserFirestoreSnapshotListener() {

    if(this.allLotsOfPostAuctionsUnsubscribe){
      this.allLotsOfPostAuctionsUnsubscribe();
    }

    if(this.maxOfferPriceOnLotUnsubscribe){
      this.maxOfferPriceOnLotUnsubscribe();
    }

    if(this.userOfferPriceOnLotUnsubscribe){
      this.userOfferPriceOnLotUnsubscribe();
    }

    if(this.userLotFavoriteUnsubscribe){
      this.userLotFavoriteUnsubscribe();
    }

    if(this.userAuctionRegistrationUnsubscribe){
      this.userAuctionRegistrationUnsubscribe();
    }

    if(this.myAuctionRegistrationsUnsubscribe){
      this.myAuctionRegistrationsUnsubscribe();
    }

    if(this.allUserAuctionRegistrationsUnsubscribe){
      this.allUserAuctionRegistrationsUnsubscribe();
    }

    if(this.userBidsOfLotUnsubscribe){
      this.userBidsOfLotUnsubscribe();
    }

    if(this.userMaxBidsOfLotUnsubscribe){
      this.userMaxBidsOfLotUnsubscribe();
    }

    if(this.highestDataOfLotUnsubscribe){
      this.highestDataOfLotUnsubscribe();
    }

    if(this.totalBidsOfAuctionUnsubscribe){
      this.totalBidsOfAuctionUnsubscribe();
    }

    if(this.auctionQuestionsUnsubscribe){
      this.auctionQuestionsUnsubscribe();
    }

    if(this.userRatingsUnsubscribe){
      this.userRatingsUnsubscribe();
    }

    if(this.emailTemplateUnsubscribe){
      this.emailTemplateUnsubscribe();
    }

    if(this.auctionQuestionsForAdminUnsubscribe){
      this.auctionQuestionsForAdminUnsubscribe();
    }
  }

  listenDeploymentConfig() {
    let documentRef = doc(this.firestore, this.collectionDeploymentConfiguration, 'DEPLOYMENT_CONFIGURATION');
    onSnapshot(documentRef, documentSnapshot => {
      let deploymentConfig = documentSnapshot.data() as DeploymentConfigurationDto;
      console.log(deploymentConfig);
      if (deploymentConfig) {
        this.configConsoleService.updateDeploymentConfig(deploymentConfig);
      }
    })

  }

  // listenMasterData() {
  //   let collectionRef = collection(this.firestore, this.collectionMasterData);
  //   onSnapshot(collectionRef, querySnapshot => {
  //     let documentSnapshots = querySnapshot.docs.map(item => item.data());

  //     if (documentSnapshots) {
  //       let masterData = new MasterDataDto();

  //       documentSnapshots.forEach(item => {
  //         for (let key in item) {
  //           if (key == 'auction_features') {
  //             masterData.auctionFeatures = item[key];
  //           } else if (key == 'currencies') {
  //             masterData.currencies = item[key];
  //           } else if (key == 'timezones') {
  //             masterData.timezones = item[key];
  //           }
  //         }
  //       })

  //       this.configConsoleService.updateMasterData(masterData)
  //     }
  //   })
  // }

  async updateMasterData(updatedMasterData: MasterDataDto, masterDataType: string) {
    let documentName = '';
    let updatedDocument = {}
    if (masterDataType == 'AUCTION_FEATURES') {
      documentName = 'auction_features';
      updatedDocument = { auction_features: updatedMasterData.auctionFeatures };
    } else if (masterDataType == 'CURRENCIES') {
      documentName = 'currencies';
      updatedDocument = { currencies: updatedMasterData.currencies };
    } else if (masterDataType == 'TIMEZONES') {
      documentName = 'timezones';
      updatedDocument = { timezones: updatedMasterData.timezones };
    }

    let documentRef = doc(this.firestore, this.collectionMasterData, documentName);
    await setDoc(documentRef, updatedDocument, { merge: true });
  }

  async updateAllMasterData(initialMasterData: MasterDataDto) {
    let auctionFeaturesRef = doc(this.firestore, this.collectionMasterData, 'auction_features');
    let currenciesRef = doc(this.firestore, this.collectionMasterData, 'currencies');
    let timezonesRef = doc(this.firestore, this.collectionMasterData, 'timezones');

    await setDoc(auctionFeaturesRef, { auction_features : initialMasterData.auctionFeatures }, { merge: true });
    await setDoc(currenciesRef, { currencies: initialMasterData.currencies }, { merge: true });
    await setDoc(timezonesRef, { timezones: initialMasterData.timezones }, { merge: true });
  }

  async updateDeploymentConfig(deploymentConfig: DeploymentConfigurationDto) {
    let documentRef = doc(this.firestore, this.collectionDeploymentConfiguration, 'DEPLOYMENT_CONFIGURATION');
    await setDoc(documentRef, Object.assign({}, deploymentConfig), { merge: true });
  }

  // Add Master Data to Firestore
  _createInitialMasterData() {



    // setDoc(deploymentConfigRef, DEPLOYMENT_CONFIGURATION, {merge: true});
    // setDoc(auctionFeaturesRef, auction_features, { merge: true });
    // setDoc(currenciesRef, currencies, { merge: true });
    // setDoc(timezonesRef, timezones, { merge: true });
    // setDoc(categoriesRef, categories, { merge: true });
  }

  ngOnDestroy(): void {
    this.clearUserFirestoreSnapshotListener();
  }

}
