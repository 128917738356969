<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>ADD AUCTION </h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
          (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleSubmit()">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="addAuctionTitle" placeholder="Auction Title" formControlName="auctionName"
            [ngClass]="{'is-invalid': fc.auctionName.invalid && (fc.auctionName.dirty || fc.auctionName.touched)}">
          <label for="addAuctionTitle">Auction Title</label>
          <div *ngIf="isSubmitted || fc.auctionName.invalid && (fc.auctionName.dirty || fc.auctionName.touched)">
            <p class="text-danger" *ngIf="fc.auctionName.errors?.required">Required
            </p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <select name="Select Timezone" class="form-control form-select" formControlName="auctionBiddingMethod" id="addAuctionBiddingMethod"
            [ngClass]="{'is-invalid': fc.auctionBiddingMethod.invalid && (fc.auctionBiddingMethod.dirty || fc.auctionBiddingMethod.touched)}"
            (change)="onAuctionBiddingMethodChange()">
            <option value="FORWARD_AUCTION">Forward Auction</option>
            <option value="REVERSE_AUCTION">Reverse Auction</option>
          </select>
          <label for="addAuctionBiddingMethod">Auction Bidding Method</label>
          <div
            *ngIf="isSubmitted || fc.auctionBiddingMethod.invalid && (fc.auctionBiddingMethod.dirty || fc.auctionBiddingMethod.touched)">
            <p class="text-danger" *ngIf="fc.auctionBiddingMethod.errors?.required">Required
            </p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <select name="Select Timezone" class="form-control form-select" formControlName="auctionType" id="auctionType"
            [ngClass]="{ 'is-invalid':  fc.auctionType.invalid && (fc.auctionType.dirty || fc.auctionType.touched)}">
            <option value="" disabled>Select . . .</option>
            <option value="Reserve">Reserve</option>
            <option value="Absolute">Absolute</option>
            <option value="Subject to Confirmation">Subject to Confirmation</option>
          </select>
          <label for="auctionType">Auction Type</label>
          <div *ngIf="isSubmitted || fc.auctionType.invalid && (fc.auctionType.dirty || fc.auctionType.touched)">
            <p class="text-danger" *ngIf="fc.auctionType.errors?.required">Required
            </p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <select (change)="onChangeCurrency($event)" name="Select Currency" class="form-control form-select"
            formControlName="currency" id="currency"
            [ngClass]="{'is-invalid': fc.currency.invalid && (fc.currency.dirty || fc.currency.touched)}">
            <option value="" disabled>Select . . .</option>
            <option [value]="item.name" [selected]="item.name == selectedCurrency?.name"
              *ngFor="let item of currencies">{{item.name}}</option>
          </select>

          <label for="currency">Currency {{selectedCurrency?.symbol}}</label>
          <div *ngIf="isSubmitted || fc.currency.invalid && (fc.currency.dirty || fc.currency.touched)">
            <p class="text-danger" *ngIf="fc.currency.errors?.required">Required</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="estSpend" (blur)="formatEstSpendValue()" id="estSpend"
                [ngClass]="{'is-invalid': fc.estSpend.invalid && (fc.estSpend.dirty || fc.estSpend.touched)}">
              <label for="estSpend">Est Spend</label>
              <div *ngIf="isSubmitted || fc.estSpend.invalid && (fc.estSpend.dirty || fc.estSpend.touched)">
                <p class="text-danger" *ngIf="fc.estSpend.errors?.required">Required</p>
                <p class="text-danger" *ngIf="invalidPriceValidator(fc.estSpend)">Invalid</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-3">
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="estSaving" id="estSaving" (blur)="formatEstSavingValue()"
                [ngClass]="{'is-invalid': fc.estSaving.invalid && (fc.estSaving.dirty || fc.estSaving.touched)}">
              <label for="estSaving">Est Saving</label>
              <div *ngIf="isSubmitted || fc.estSaving.invalid && (fc.estSaving.dirty || fc.estSaving.touched)">
                <p class="text-danger" *ngIf="fc.estSaving.errors?.required">Required</p>
                <p class="text-danger" *ngIf="invalidPriceValidator(fc.estSaving)">Invalid</p>
              </div>
            </div>
          </div>
        </div>
        <div class="form-floating mb-3">
          <select name="Select Timezone" class="form-control form-select" id="timeZone" formControlName="timeZone"
            [ngClass]="{'is-invalid': fc.timeZone.invalid && (fc.timeZone.dirty || fc.timeZone.touched)}">
            <option value="" disabled>Select . . .</option>
            <option [value]="item?.locale" *ngFor="let item of timezones">{{item?.fullName}}</option>
          </select>
          <label for="timeZone">Select Timezone</label>
          <div *ngIf="isSubmitted || fc.timeZone.invalid && (fc.timeZone.dirty || fc.timeZone.touched)">
            <p class="text-danger" *ngIf="fc.timeZone.errors?.required">Required</p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <select name="Select Region" class="form-control form-select" formControlName="region" id="region"
            [ngClass]="{'is-invalid': fc.region.invalid && (fc.region.dirty || fc.region.touched)}">
            <option value="" disabled>Select . . .</option>
            <option value="North America">North America</option>
            <option value="Latin America (LATAM)">Latin America (LATAM)</option>
            <option value="Europe, Middle East, and Africa (EMEA)">Europe, Middle East, and Africa (EMEA)</option>
            <option value="Asia-Pacific (APAC)">Asia-Pacific (APAC)</option>
            <option value="Commonwealth of Independent States (CIS)">Commonwealth of Independent States (CIS)</option>
            <option value="Sub-Saharan Africa">Sub-Saharan Africa</option>
          </select>

          <label for="region">Region</label>
          <div *ngIf="isSubmitted || fc.region.invalid && (fc.region.dirty || fc.region.touched)">
            <p class="text-danger" *ngIf="fc.region.errors?.required">Required</p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <select name="Select Cost Centers" class="form-control form-select" formControlName="costCenters" id="costCenters"
            [ngClass]="{'is-invalid': fc.costCenters.invalid && (fc.costCenters.dirty || fc.costCenters.touched)}">
            <option value="" disabled>Select . . .</option>
            <option value="Human Resources (HR)">Human Resources (HR)</option>
            <option value="Finance and Accounting">Finance and Accounting</option>
            <option value="Legal">Legal</option>
            <option value="Executive Management">Executive Management</option>
            <option value="Information Technology (IT)">Information Technology (IT)</option>
            <option value="Facilities Management">Facilities Management</option>
            <option value="Procurement/Purchasing">Procurement/Purchasing</option>
            <option value="Research and Development (R&D)">Research and Development (R&D)</option>
            <option value="Quality Assurance (QA)">Quality Assurance (QA)</option>
            <option value="Marketing">Marketing</option>
            <option value="Sales Support">Sales Support</option>
            <option value="Manufacturing/Production">Manufacturing/Production</option>
            <option value="Logistics and Transportation">Logistics and Transportation</option>
            <option value="Customer Service/Support">Customer Service/Support</option>
            <option value="Training and Development">Training and Development</option>
            <option value="Corporate Communications">Corporate Communications</option>
            <option value="Environmental, Health, and Safety (EHS)">Environmental, Health, and Safety (EHS)</option>
            <option value="Corporate Social Responsibility (CSR)">Corporate Social Responsibility (CSR)</option>
            <option value="Internal Audit">Internal Audit</option>
            <option value="Risk Management">Risk Management</option>
          </select>

          <label for="costCenters">Cost Centers</label>
          <div *ngIf="isSubmitted || fc.costCenters.invalid && (fc.costCenters.dirty || fc.costCenters.touched)">
            <p class="text-danger" *ngIf="fc.costCenters.errors?.required">Required</p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <select name="Bidder Approval" class="form-control form-select" formControlName="bidderApproval" id="bidderApproval"
            [ngClass]="{'is-invalid': fc.bidderApproval.invalid && (fc.bidderApproval.dirty || fc.bidderApproval.touched)}">
            <option value="" disabled>Select . . .</option>
            <option value="Auto Approve">Auto Approve</option>
            <option value="Approve Manually">Approve Manually</option>
          </select>
          <label for="floatingInput">Bidder Approval</label>
          <div
            *ngIf="isSubmitted || fc.bidderApproval.invalid && (fc.bidderApproval.dirty || fc.bidderApproval.touched)">
            <p class="text-danger" *ngIf="fc.bidderApproval.errors?.required">
              Required</p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <div class="input-group mb-3">
              <span class="border  border-end-0 pt-3 px-1"
                [ngClass]="{'border-danger': fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)}">
                <!-- <span class="material-symbols-outlined ">work</span> -->
                <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
              </span>

              <div class="form-floating  ">

                <input type="text" ngbDatepicker [minDate]="minDate" #startDate="ngbDatepicker" id="startDate"
                  (click)="startDate.toggle()" class="form-control border-start-0" placeholder="Start Date"
                  formControlName="startDate"
                  [ngClass]="{'is-invalid': fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)}">
                <label for="startDate" class="">Start Date</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)">
              <p class="text-danger" *ngIf="fc.startDate.errors?.required">Required
              </p>
            </div>

          </div>
          <div class="col-6">
            <div class="input-group mb-3">
              <span class="border  border-end-0 pt-3 px-1"
                [ngClass]="{'border-danger': fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)}">
                <!-- <span class="material-symbols-outlined ">work</span> -->
                <img src="assets/icons/time_light.svg" class="svg-icon-class c-pointer">
              </span>
              <div class="form-floating ">
                <input type="text" class="form-control border-start-0" ngbPopover [autoClose]="'outside'" id="startTime"
                  placement="left" triggers="manual" #p1="ngbPopover" (click)="openTimepicker(p1, startTimeModal)"
                  placeholder="Start Time" formControlName="startTime"
                  [ngClass]="{'is-invalid': fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)}">
                <label for="startTime">Start Time</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)">
              <p class="text-danger" *ngIf="fc.startTime.errors?.required">Required</p>
              <p class="text-danger" *ngIf="fc.startTime.errors?.pattern">Invalid</p>
            </div>
          </div>
        </div>

        <div class="mb-3 input-group">
          <div class="form-floating">
            <select placeholder="Soft Close" class="form-control form-select" formControlName="antiSnipe" id="antiSnipe">
              <option value="" disabled>Select . . .</option>
              <option [value]="1">1</option>
              <option [value]="2">2</option>
              <option [value]="3">3</option>
              <option [value]="4">4</option>
              <option [value]="5">5</option>
              <option [value]="6">6</option>
              <option [value]="7">7</option>
              <option [value]="8">8</option>
              <option [value]="9">9</option>
              <option [value]="10">10</option>
              <option [value]="11">11</option>
              <option [value]="12">12</option>
              <option [value]="13">13</option>
              <option [value]="14">14</option>
              <option [value]="15">15</option>
            </select>
            <label for="antiSnipe">Soft Close(Number Of minutes)</label>
          </div><button type="button" class="btn btn-grey border">Minutes</button>
        </div>

        <div
          *ngIf="isSubmitted && fc.antiSnipe.invalid || fc.antiSnipe.invalid &&  (fc.antiSnipe.dirty || fc.antiSnipe.touched)">
          <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fc.antiSnipe.errors?.min">Invalid</p>
          <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fc.antiSnipe.errors?.max">Invalid</p>
        </div>

        <div class="row" hidden>
          <div class="col-5">Premium Auction :</div>
          <div class="col-2">
            <input type="radio" formControlName="premiumAuction" id="t1" [value]="true">
            <label class="ms-3">Yes</label><br>
          </div>
          <div class="col-2">
            <input type="radio" formControlName="premiumAuction" id="t2" [value]="false">
            <label class="ms-3">No</label><br>
          </div>
        </div>

        <div class="row mt-3" *ngIf="fc.auctionBiddingMethod.value == 'FORWARD_AUCTION'">
          <div class="col-5">Buy It Now (Pre Offer) :</div>
          <div class="col-2">
            <input type="radio" formControlName="preOffer"  id="t5" [value]="true">
            <label class="ms-3">Yes</label><br>
          </div>
          <div class="col-2">
            <input type="radio" formControlName="preOffer" id="t6" [value]="false">
            <label class="ms-3">No</label><br>
          </div>
        </div>


        <div class="my-3 input-group">
          <div class="form-floating">
            <select placeholder="Display Rank - Market Price" class="form-control form-select" id="rankCurrentPriceConfig"
              formControlName="rankCurrentPriceConfig" (change)="changeRankConfig()"
              [ngClass]="{'is-invalid': fc.rankCurrentPriceConfig.invalid || fc.rankCurrentPriceConfig.invalid && (fc.rankCurrentPriceConfig.dirty || fc.rankCurrentPriceConfig.touched)}">
              <option value="" disabled>Select . . .</option>
              <option *ngFor="let item of rankPriceConfigs" [value]="item">{{getRankPriceConfigName(item)}}
              </option>
            </select>
            <label for="rankCurrentPriceConfig">Display Rank - Market Price</label>
          </div>
        </div>

        <div class="row mt-3" *ngIf="isAllowToShowBidderHistoryConfig">
          <div class="col-md-12">Show Bidder History To Bidder :</div>
          <div class="col-md-12">
            <div class="row mt-2">
              <div class="col-md-3">
                <input type="radio" formControlName="showBidderHistory" id="m1" [value]="'ALWAYS'">
                <label for="m1" class="ms-3">Always</label><br>
              </div>
              <div class="col-md-3">
                <input type="radio" formControlName="showBidderHistory" id="m2" [value]="'NEVER'">
                <label for="m2" class="ms-3">Never</label><br>
              </div>
              <div class="col-md-6">
                <input type="radio" formControlName="showBidderHistory" id="m3" [value]="'AFTER_AUCTION_CLOSE'">
                <label for="m3" class="ms-3">After Auction Close </label><br>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-5">Mask Bidder Details:</div>
          <div class="col-2">
            <input type="radio" formControlName="maskBidderDetails" id="m11" [value]="true">
            <label for="m11" class="ms-3">Yes</label><br>
          </div>
          <div class="col-2">
            <input type="radio" formControlName="maskBidderDetails" id="m22" [value]="false">
            <label for="m22" class="ms-3">No</label><br>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-8">Allow Edit While Auction is Upcoming:</div>
          <div class="col-md-2 col-6">
            <input type="radio" formControlName="allowEditWhileUpcoming" id="allowEditWhileUpcoming1" [value]="true">
            <label for="allowEditWhileUpcoming1" class="ms-3">Yes</label><br>
          </div>
          <div class="col-md-2 col-6">
            <input type="radio" formControlName="allowEditWhileUpcoming" id="allowEditWhileUpcoming2" [value]="false">
            <label for="allowEditWhileUpcoming2" class="ms-3">No</label><br>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-8">Allow Edit While Auction is Running:</div>
          <div class="col-md-2 col-6">
            <input type="radio" formControlName="allowEditWhileRunning" id="allowEditWhileRunning1" [value]="true">
            <label for="allowEditWhileRunning1" class="ms-3">Yes</label><br>
          </div>
          <div class="col-md-2 col-6">
            <input type="radio" formControlName="allowEditWhileRunning" id="allowEditWhileRunning2" [value]="false">
            <label for="allowEditWhileRunning2" class="ms-3">No</label><br>
          </div>
        </div>

        <div class="row mt-3" *ngIf="deploymentConfigurationDto?.enableBlockchain">
          <div class="col-5">Blockchain Enable :</div>
          <div class="col-2">
            <input type="radio" formControlName="enableBlockchain" id="t10" [value]="true">
            <label for="t10" class="ms-3">Yes</label><br>
          </div>
          <div class="col-2">
            <input type="radio" formControlName="enableBlockchain" id="t12" [value]="false">
            <label for="t12" class="ms-3">No</label><br>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <div class="form-floating mt-3">
              <input type="text" class="form-control" (click)="auctionImg.click()" id="profilePic1"
                [value]="this._fileDisplayName$ | async"
                [ngClass]="{'is-invalid': isSubmitted && !(this._fileDisplayName$ | async)}">

              <input #auctionImg name="profile_pic" onclick="this.value=null;" type="file" class="d-none"
                (change)="chooseFile($event)"  id="profilePic2">

              <label for="profilePic1">{{currentFile ? 'Replace Image' : 'Add Image'}}</label>
              <p class="text-danger" *ngIf="isSubmitted && !(this._fileDisplayName$ | async)">Required</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mt-3" *ngIf="imageUrl">
              <div class="col-7">
                <img alt="" [src]="imageUrl"
                  [ngClass]="isActiveImage ? 'modal-logo-image-large' : 'modal-logo-image'" />
              </div>
              <div class="col-2 me-2 mt-3">
                <button class="btn btn-sm btn-new-class" (click)="openViewImageModal(imageUrl)">
                  <img src="assets/icons/view_dark.svg" class="svg-icon-class c-pointer" *ngIf="!isActiveImage">
                  <img src="assets/icons/zoomout_dark.svg" class="svg-icon-class c-pointer" *ngIf="isActiveImage">
                </button>
              </div>
              <div class="col-2 mt-3">
                <span (click)="deleteImage()" [ngClass]="{'disabled' : isLoading }">
                  <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                </span>
              </div>
            </div>
          </div>
        </div>


        <div class="my-3">
          <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            [message]="'Auction created successfully.'"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class" (click)="handleSubmit()">ADD</button>
        </div>
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>
      </form>
    </div>
  </div>
</div>


<!-- NGB TIME MODAL -->
<ng-template #startTimeModal>
  <ngb-timepicker [(ngModel)]="startTime" [meridian]="true"></ngb-timepicker>
  <button class="btn btn-new-class btn-sm" (click)="setTime('startTime')">SAVE</button>
</ng-template>
