import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserCategorySelectionComponent } from 'src/app/shared/components/user-category-selection/user-category-selection.component';
import { AddressDto } from 'src/app/shared/models/Address';
import { BidderDto } from 'src/app/shared/models/BidderDto';
import { CategoryDto } from 'src/app/shared/models/CategoryDto';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { GlobalBidderEntityDto } from 'src/app/shared/models/GlobalBidderEntityDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { PurchaseRequestUiDto } from 'src/app/shared/models/rfx/PurchaseRequestUiDto';
import { EmailWrapper } from 'src/app/shared/models/user/EmailWrapper';
import { MobileWrapper } from 'src/app/shared/models/user/MobileWrapper';
import { PreferenceCategoryDto } from 'src/app/shared/models/user/PreferenceCategoryDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-add-global-bidder-modal',
  templateUrl: './add-global-bidder-modal.component.html',
  styleUrls: ['./add-global-bidder-modal.component.sass']
})
export class AddGlobalBidderModalComponent implements OnInit {
  @Input() selectedBidder?: BidderDto;

  selectedPreferenceCategory?: PreferenceCategoryDto;

  // selectedCategoryName?: string;
  // selectedSubCategoryName?: string;
  // selectedSubCategoryLevelOneName?: string;

  isLoading: boolean = false;

  auctionCategoriesSubscription$?: Subscription;

  masterCategories: CategoryDto[] = [];
  // categories: any[] = [];
  // categories$ = new BehaviorSubject<any[]>([]);

  // subCategories: string[] = [];
  // subCategories$ = new BehaviorSubject<string[]>([]);

  // preferenceCategories: PreferenceCategoryDto[] = []
  // subcategoryLevelOneList: CategoryDto[] = [];
  // subcategoryLevelOneList$ = new BehaviorSubject<CategoryDto[]>([]);
  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);

  formGroup: FormGroup


  countryCodeForFaxNo$ = new BehaviorSubject<CountryCodeDto>(CountryCodeDto.default());
  countryCodeForMobileNo$ = new BehaviorSubject<CountryCodeDto>(CountryCodeDto.default());

  latitude?: number;
  longitude?: number;
  countryShortName?: string

  zoom = 12;
  infoContent = ''
  position?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  marker = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _showErrorMsg$ = new BehaviorSubject<string>("");

  selectedCountryCode = CountryCodeDto.default();
  selectedFaxNoCountryCode = CountryCodeDto.default();


  constructor(private adminDashboardService: AdminDashboardService, private formBuilder: FormBuilder, private ngbModal: NgbModal) {

    this.formGroup = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      designation: new FormControl(''),
      companyName: new FormControl('', [Validators.required]),
      companyUrl: new FormControl(''),
      emailId: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
      mobileNo: new FormControl('', Validators.pattern(Pattern.mobile)),
      faxNo: new FormControl('', Validators.pattern(Pattern.mobile)),

      searchAddress: new FormControl('', [Validators.required]),
      addressLine1: new FormControl('', [Validators.required]),
      addressLine2: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required]),

      // category: new FormControl('', Validators.required),
      // subCategory: new FormControl('', Validators.required),
      // subcategoryLevelOne: new FormControl('', Validators.required),

      mobileList: this.formBuilder.array([]),
      emailList: this.formBuilder.array([]),
    })

  }
  ngOnInit(): void {
    this.auctionCategoriesSubscription$ = this.adminDashboardService.getMasterCategories$.subscribe((dataList) => {
      if (dataList) {
        this.masterCategories = dataList;
        // if (this.masterCategories && this.masterCategories.length > 0) {
        //   this.masterCategories.forEach((item => {
        //     if (Boolean(item.active) == true && !this.categories.includes(item.category)) {
        //       this.categories.push(item.category);
        //     }
        //     // this.searchCategory("");
        //   }))
        // }

      } else {
        // this.categories = [];
        this.masterCategories = [];
      }
    })

    this.handleCountryCodeEvent(this.selectedCountryCode);
    this.handleCountryCodeEventForFaxNo(this.selectedFaxNoCountryCode);

    if (this.selectedBidder) {
      this.populateFormGroup();
    }
  }

  populateFormGroup() {
    this.formGroup.controls['firstName'].patchValue(this.selectedBidder?.firstName);
    this.formGroup.controls['lastName'].patchValue(this.selectedBidder?.lastName);
    this.formGroup.controls['designation'].patchValue(this.selectedBidder?.designation);
    this.formGroup.controls['companyName'].patchValue(this.selectedBidder?.companyName);
    this.formGroup.controls['companyUrl'].patchValue(this.selectedBidder?.companyUrl);

    let primaryEmail = this.selectedBidder?.emailList?.find(item => item.primary);
    let primaryMobile = this.selectedBidder?.mobileList?.find(item => item.primary);

    this.formGroup.controls['emailId'].patchValue(primaryEmail?.email);
    this.formGroup.controls['emailId'].disable();

    this.selectedCountryCode = primaryMobile?.countryCode ?? CountryCodeDto.default();
    this.formGroup.controls['mobileNo'].patchValue(primaryMobile?.mobileNo);

    this.selectedFaxNoCountryCode = this.selectedBidder?.faxNoCountryCode ?? CountryCodeDto.default();
    this.formGroup.controls['faxNo'].patchValue(this.selectedBidder?.faxNo);

    this.handleCountryCodeEvent(this.selectedCountryCode);
    this.handleCountryCodeEventForFaxNo(this.selectedFaxNoCountryCode);

    this.formGroup.controls['searchAddress'].patchValue(this.selectedBidder?.address?.searchAddress);
    this.formGroup.controls['addressLine1'].patchValue(this.selectedBidder?.address?.addressLine1);
    this.formGroup.controls['addressLine2'].patchValue(this.selectedBidder?.address?.addressLine2);
    this.formGroup.controls['city'].patchValue(this.selectedBidder?.address?.city);
    this.formGroup.controls['state'].patchValue(this.selectedBidder?.address?.state);
    this.formGroup.controls['country'].patchValue(this.selectedBidder?.address?.country);
    this.formGroup.controls['zipCode'].patchValue(this.selectedBidder?.address?.zipCode);

    if (this.selectedBidder?.preferenceCategories && this.selectedBidder?.preferenceCategories.length >0) {
    this.selectedPreferenceCategory = this.selectedBidder?.preferenceCategories[0];
    }

    if (this.selectedBidder?.address) {
      this.latitude = Number(this.selectedBidder?.address?.latitude);
      this.longitude = Number(this.selectedBidder?.address?.longitude);
      this.countryShortName = this.selectedBidder?.address?.countryShortName;
    }


    // let categoryDto = this.masterCategories.find(item => item.id == this.selectedGlobalBidder!.preferenceCategories![0]);

    // this.formGroup.controls['category'].patchValue(categoryDto?.category);

    // this.populateSubCategoryLevelOneList(categoryDto?.category!, categoryDto?.subcategory!);
    // this.setSubcategoryLevelOne(categoryDto?.id!, categoryDto?.subcategoryLevelOne!);
  }

  openCategoryModal() {
    let modalRef = this.ngbModal.open(UserCategorySelectionComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    })
    modalRef.componentInstance.isGlobalBidder = true;
    modalRef.componentInstance.userDto = this.selectedBidder;
    modalRef.componentInstance.onSelectedCategories.subscribe((categories: PreferenceCategoryDto) => {
      this.selectedPreferenceCategory = categories;
    })
  }

  handleCountryCodeEvent(countryCodeDto: CountryCodeDto) {
    // {
    //   countryCode: '(+61)'
    //   countryFlag: 'language-icon me-2 fi fi-au'
    //   countryName: 'Australia'
    //   passwordLength: '9'
    // }
    this.selectedCountryCode = countryCodeDto;
    this.countryCodeForMobileNo$.next(countryCodeDto)

    let mobileNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + mobileNoLength + "}"

    this.formGroup.controls['mobileNo'].clearValidators();
    this.formGroup.controls['mobileNo'].setValidators(Validators.pattern(pattern));
    this.formGroup.controls['mobileNo'].updateValueAndValidity();
  }

  handleCountryCodeEventForFaxNo(countryCodeDto: CountryCodeDto) {

    this.selectedFaxNoCountryCode = countryCodeDto;
    this.countryCodeForFaxNo$.next(countryCodeDto)

    let faxNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + faxNoLength + "}"

    this.formGroup.controls['faxNo'].clearValidators();
    this.formGroup.controls['faxNo'].setValidators(Validators.pattern(pattern));
    this.formGroup.controls['faxNo'].updateValueAndValidity();
  }

  handleAddressChange(address: Address) {
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();

    let city = ApplicationUtils.getAddressByType(address, 'locality');
    if (!city) {
      city = ApplicationUtils.getAddressByType(address, 'neighborhood');
    }
    let state = ApplicationUtils.getAddressByType(address, 'administrative_area_level_1');
    let zip = ApplicationUtils.getAddressByType(address, 'postal_code');
    let addressPart1 = ApplicationUtils.getAddressByType(address, 'street_number');
    let addressPart2 = ApplicationUtils.getAddressByType(address, 'route');
    let country = ApplicationUtils.getAddressByType(address, 'country');
    this.countryShortName = ApplicationUtils.getAddressShortNameByType(address, 'country');

    // Map View Init
    this.position = { lat: this.latitude, lng: this.longitude }
    this.marker.label.text = address.formatted_address;
    this.marker.title = address.formatted_address;
    this.marker.info = address.formatted_address;

    if (addressPart1 == '' || addressPart2 == '') {
      if (address.formatted_address.includes(',')) {
        let data = address.formatted_address.split(',')[0]
        this.formGroup.controls['addressLine1'].patchValue(data);
      } else {
        this.formGroup.controls['addressLine1'].patchValue(address.formatted_address)
      }
    } else {
      this.formGroup.controls['addressLine1'].patchValue(addressPart1 + " " + addressPart2);
    }
    this.formGroup.controls['searchAddress'].patchValue(address.formatted_address);
    this.formGroup.controls['state'].patchValue(state);
    this.formGroup.controls['city'].patchValue(city);
    this.formGroup.controls['country'].patchValue(country);
    this.formGroup.controls['zipCode'].patchValue(zip);
    this.formGroup.updateValueAndValidity();
  }

  get fc(): any { return this.formGroup.controls; }


  // searchCategory(searchInput: any) {
  //   let tempList = ApplicationUtils.clone(this.categories) as any[];
  //   if (searchInput == "") {
  //     this.categories$.next(tempList)
  //   } else {
  //     let tempMasterList = ApplicationUtils.clone(this.masterCategories) as CategoryDto[];
  //     let filterList = tempMasterList.filter(item => item.category!.trim().toLowerCase().includes(searchInput.trim().toLowerCase()) ||
  //       item.subcategory!.trim().toLowerCase().includes(searchInput.trim().toLowerCase()) ||
  //       item.subcategoryLevelOne!.trim().toLowerCase().includes(searchInput.trim().toLowerCase())).map(item => item.category);
  //     this.categories$.next(Array.from(new Set(filterList)))
  //   }
  // }

  // searchSubCategory(searchInput: any) {
  //   let tempList = ApplicationUtils.clone(this.subCategories) as string[];
  //   if (searchInput == "") {
  //     this.subCategories$.next(tempList)
  //   } else {
  //     let filterList = tempList.filter(item => item.trim().toLowerCase().includes(searchInput.trim().toLowerCase()));
  //     this.subCategories$.next(filterList)
  //   }
  // }

  // searchSubCategoryLevelOne(searchInput: any) {
  //   let tempList = ApplicationUtils.clone(this.subcategoryLevelOneList) as CategoryDto[];
  //   if (searchInput == "") {
  //     this.subcategoryLevelOneList$.next(tempList)
  //   } else {
  //     let filterList = tempList.filter(item => item.subcategoryLevelOne!.trim().toLowerCase().includes(searchInput.trim().toLowerCase()));
  //     this.subcategoryLevelOneList$.next(filterList)
  //   }
  // }


  // populateSubCategoryList(categoryName: string, dropdown?: NgbDropdown) {
  //   this.formGroup.controls['category'].patchValue(categoryName);
  //   if (dropdown) {
  //     dropdown.close();
  //   }

  //   this.subCategories = [];
  //   if (this.masterCategories && this.masterCategories.length > 0) {
  //     this.subCategories = this.masterCategories.filter(item => item.category == categoryName).map(item => item.subcategory!);
  //     this.subCategories = this.subCategories.filter((item, index, self) => self.indexOf(item) === index);

  //     if (this.subCategories.length > 0) {
  //       this.formGroup.controls['subCategory'].patchValue(this.subCategories[0]);
  //       this.populateSubCategoryLevelOneList(categoryName, this.subCategories[0])
  //     }
  //     this.searchSubCategory("");
  //   }
  // }

  // populateSubCategoryLevelOneList(categoryName: string, subCategoryName: string, dropdown?: NgbDropdown) {
  //   this.formGroup.controls['subCategory'].patchValue(subCategoryName);
  //   if (dropdown) {
  //     dropdown.close();
  //   }

  //   this.subcategoryLevelOneList = [];
  //   if (this.masterCategories && this.masterCategories.length > 0) {
  //     this.subcategoryLevelOneList = this.masterCategories.filter(item => item.category == categoryName && item.subcategory == subCategoryName);
  //     if (this.subcategoryLevelOneList.length > 0) {
  //       this.formGroup.controls['subcategoryLevelOne'].patchValue(this.subcategoryLevelOneList[0].id);
  //       this.selectedSubCategoryLevelOneName = this.subcategoryLevelOneList[0].subcategoryLevelOne;
  //     }
  //     this.searchSubCategoryLevelOne("");
  //   }
  // }

  // setSubcategoryLevelOne(id: string, subcategoryLevelOne: string, dropdown?: NgbDropdown) {
  //   this.selectedSubCategoryLevelOneName = subcategoryLevelOne;

  //   this.formGroup.controls['subcategoryLevelOne'].patchValue(id);
  //   if (dropdown) {
  //     dropdown.close();
  //   }
  // }

  populateData() {
    let bidderDto = new BidderDto();

    if (this.selectedBidder) {
      bidderDto = ApplicationUtils.clone(this.selectedBidder);
    }

    bidderDto.firstName = this.formGroup.controls['firstName'].value;
    bidderDto.lastName = this.formGroup.controls['lastName'].value;
    bidderDto.designation = this.formGroup.controls['designation'].value;
    bidderDto.companyName = this.formGroup.controls['companyName'].value;
    bidderDto.companyUrl = this.formGroup.controls['companyUrl'].value;

    bidderDto!.name = bidderDto.firstName +" "+ bidderDto.lastName;


    bidderDto.primaryEmailId = this.formGroup.controls['emailId'].value;
    let emailWrapper = new EmailWrapper();
    emailWrapper.email = bidderDto?.primaryEmailId;
    emailWrapper.primary = true;

    if (!bidderDto.emailList) {
      bidderDto.emailList = [];
    }

    bidderDto.emailList.push(emailWrapper);

    let mobileWrapper = new MobileWrapper();
    mobileWrapper.mobileNo = this.formGroup.controls['mobileNo'].value;
    mobileWrapper.countryCode = this.selectedCountryCode;
    mobileWrapper.primary = true;
    mobileWrapper.verified = true;

    if (!bidderDto.mobileList) {
      bidderDto.mobileList = [];
    }

    bidderDto.mobileList.push(mobileWrapper);

    bidderDto.faxNo = this.formGroup.controls['faxNo'].value;
    bidderDto.faxNoCountryCode = this.selectedFaxNoCountryCode;

    if (this.selectedPreferenceCategory) {
      if (!bidderDto.preferenceCategories) {
        bidderDto.preferenceCategories = []
      }
      bidderDto.preferenceCategories.push(this.selectedPreferenceCategory) ;
      
    }

    // Address
    let address = new AddressDto();

    if (this.selectedBidder?.address) {
      address.id = this.selectedBidder.address!.id;
    } else {
      address.id = UUID.UUID().toString();
    }

    address.addressType = 'Company Address';
    address.searchAddress = this.formGroup.controls['searchAddress'].value;
    address.addressLine1 = this.formGroup.controls['addressLine1'].value;
    address.addressLine2 = this.formGroup.controls['addressLine2'].value;
    address.city = this.formGroup.controls['city'].value;
    address.state = this.formGroup.controls['state'].value;
    address.country = this.formGroup.controls['country'].value;
    address.zipCode = this.formGroup.controls['zipCode'].value;
    address.latitude = this.latitude?.toString();
    address.longitude = this.longitude?.toString();
    address.countryShortName = this.countryShortName;

    bidderDto.address = address;

    return bidderDto;
  }

  formValidationFailed() {
    this._validationErrorPresent$.next(false);
    if (this.formGroup.invalid) {
      this._validationErrorPresent$.next(true);
      return true;
    }
    if (!this.selectedPreferenceCategory) {
      return true;
    }
    return false;
  }

  handleValidSubmit() {
    this._showErrorMsg$.next("");
    this._showErrorToast$.next(false);
    this.isLoading = false;

    if (this.formValidationFailed()) {
      return;
    }

    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);
    let bidderDto = this.populateData();
    bidderDto!.bidderType = 'REGISTERED_BIDDER';

    this.adminDashboardService.addAndUpdateBidder(bidderDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.adminDashboardService._bidderLoadDataEvent$.next(true);
          this._showSuccessToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this._isSaveButtonEnable$.next(true);
            this.closeModal();
          }, 2000)
        } else {

          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this._showErrorMsg$.next("Error while adding global bidder. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })

  }

  closeModal() {
    this.ngbModal.dismissAll()
  }


  // Form Array Configs
  get mobileNoList(): FormArray {
    return this.formGroup.get("mobileList") as FormArray
  }

  newMobileWrapperFormGroup(): FormGroup {
    return this.formBuilder.group({
      countryCode: [null, Validators.required],
      mobileNo: ['', [Validators.required, Validators.pattern(Pattern.mobile)]],
      primary: [false],
      verified: [false]
    });
  }

  updateMobileNoFormGroup(mobileWrapper: MobileWrapper): FormGroup {
    return this.formBuilder.group({
      countryCode: [mobileWrapper.countryCode, Validators.required],
      mobileNo: [mobileWrapper.mobileNo, [Validators.required, Validators.pattern(Pattern.mobile)]],
      primary: [mobileWrapper.primary],
      verified: [mobileWrapper.verified]
    })
  }

  addMobileNoFormGroup() {
    this.mobileNoList.push(this.newMobileWrapperFormGroup());
  }

  toggleEditCategory() {
    this.selectedPreferenceCategory = undefined;
  }

  onSelectedCategory(preferenceCategory?: PreferenceCategoryDto) {
    this.selectedPreferenceCategory = preferenceCategory;
  }
}
