<!-- AWARDED BIDDER LIST -->
<div class="row justify-content-between align-items-center my-3">
  <div class="col">
    <h4 class="heading-h5">AWARDED BIDDERS</h4>
  </div>
  <div class="col-md-6 text-md-end">
    <div class="row justify-content-end">
      <div class="col-2">
        <button class="btn btn-new-class btn-sm me-2 mt-1" (click)="loadAwardedBidders()">
          <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class">
        </button>
      </div>
      <div class="col-6">
        <div class="input-group">
          <input type="text" placeholder="Search" id="ctrlSearchBidders" [formControl]="ctrlSearchBidders" (keyup)="searchBiddersList()"
            class="form-control border-end-0 rounded-0 border bg-white" />
          <span class="input-group-append">
            <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
              <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <div class="table-responsive">
    <table class="table table-bordered medium-font  align-middle">
      <thead class="table-dark">
        <tr>
          <th class=""><span>S No</span></th>
          <th class=""><span>Name</span></th>
          <th class=""><span>Bidder No</span></th>
          <th class=""> <span>No of lots</span></th>
          <th class=""> <span>Action</span></th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr *ngIf="awardedBiddersLoading">
          <td colspan="5">
            <div class="d-grid gap-2 mb-3">
              <div class="e-spinner"></div>
            </div>
          </td>
        </tr>
        <tr *ngIf="!awardedBiddersLoading && (awardedBidders$ | async)?.length == 0">
          <td colspan="5">
            <div class="text-center">
              <h5 class="heading-new text-dark">No Awarded Bidders Available</h5>
            </div>
          </td>
        </tr>
        <tr *ngFor="let item of awardedBidders$ | async; index as i" class="c-pointer">
          <td>{{i+1}}</td>
          <td>{{item?.bidderName}}</td>
          <td>#{{item?.paddleNo}}</td>
          <td>
            <span class="me-1 " *ngIf="currentIndex != i">
              # {{item?.awardedLots![0].lotSequenceNo}}
              {{item?.awardedLots![0].lotName}} </span>
            <span class="ms-3 btn-link" *ngIf="item?.awardedLots!.length > 1 && currentIndex != i"
              (click)="showLotsMore(i)">
              {{item?.awardedLots!.length - 1}} more
            </span>

            <div *ngIf="currentIndex == i">
              <div *ngFor="let lot of getLotsList(item?.awardedLots!); index as i">
                <span>
                  # {{lot.lotSequenceNo}}
                  {{lot.lotName}}
                </span>
              </div>
            </div>

          </td>
          <td>
            <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
              <span ngbDropdownToggle data-bs-toggle="dropdown">
                <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class">
              </span>
              <ul ngbDropdownMenu class="text-center shadow">
                <li ngbDropdownItem class="c-pointer" (click)="openBiddersAwardedLots(item?.userId)">
                  Generate Invoice
                </li>
                <li>
                  <hr class="dropdown-divider ">
                </li>
                <li ngbDropdownItem class="c-pointer" (click)="openGeneratedInvoiceData(item?.userId!)">Generated
                  Invoice</li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row justify-content-between" *ngIf="(awardedBidders$ | async)!.length > 0">
    <div class="col">
      <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
        <span class="span-class">Show rows per page </span>
        <span ngbDropdownToggle data-bs-toggle="dropdown">
          <button class="btn btn-sm btn-outline-secondary px-2">{{pageSizeBidders}}</button>
        </span>
        <ul ngbDropdownMenu class="text-center shadow">
          <li ngbDropdownItem class="c-pointer" (click)="changePageSize(50, 'BIDDERS')">50</li>
          <li>
            <hr class="dropdown-divider ">
          </li>
          <li ngbDropdownItem class="c-pointer" (click)="changePageSize(100, 'BIDDERS')">100</li>
        </ul>
      </div>
    </div>
    <div class="col-auto">
      <ngb-pagination [collectionSize]="awardedBidders.length!" [(page)]="pageBidders" [pageSize]="pageSizeBidders"
        (pageChange)="paginateBiddersList()">
      </ngb-pagination>
    </div>
  </div>
</div>

<hr class="hr mb-3 mt-1" *ngIf="currentAuction && showGeneratedView" />

<!-- Not AWARDED Lot LIST -->
<div class="row justify-content-between align-items-center my-3">
  <div class="col">
    <h4 class="heading-h5">NOT AWARDED LOTS</h4>
  </div>

  <div class="col-md-6 text-md-end">
    <div class="row justify-content-end">
      <div class="col-2">
        <button class="btn btn-new-class btn-sm me-2 mt-1" (click)="loadNotAwardedLots()">
          <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class">
        </button>
      </div>
      <div class="col-6">
        <div class="input-group">
          <input type="text" placeholder="Search" [formControl]="ctrlSearchNotAwardedLots"
            (keyup)="searchNotAwardedLotList()" class="form-control border-end-0 rounded-0 border bg-white" />
          <span class="input-group-append">
            <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
              <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="currentAuction && !showGeneratedView">
  <div class="table-responsive">
    <table class="table table-bordered medium-font  align-middle">
      <thead class="table-dark">
        <tr>
          <th class=""><span>Lot Name</span></th>
          <th class=""><span>No Of Bids</span></th>
          <th class=""> <span>Last Bid Price</span></th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr *ngIf="notAwardedLotsLoading">
          <td colspan="5">
            <div class="d-grid gap-2 mb-3">
              <div class="e-spinner"></div>
            </div>
          </td>
        </tr>
        <tr *ngIf="!notAwardedLotsLoading && (notAwardedList$ | async)?.length == 0">
          <td colspan="5">
            <div class="text-center">
              <h5 class="heading-new text-dark">All Lots Have Already Been Awarded</h5>
            </div>
          </td>
        </tr>
        <tr *ngFor="let item of notAwardedList$ | async; index as i" class="c-pointer">
          <td># {{item?.lotSequenceNo}} {{item?.lotName}}</td>
          <td>{{item?.noOfBids}}</td>
          <td>{{getFormattedPrice(item?.lastBidPrice, item?.currency)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row justify-content-between" *ngIf="(notAwardedList$ | async)!.length > 0">
    <div class="col">
      <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
        <span class="span-class">Show rows per page </span>
        <span ngbDropdownToggle data-bs-toggle="dropdown">
          <button class="btn btn-sm btn-outline-secondary px-2">{{pageSizeNotAwarded}}</button>
        </span>
        <ul ngbDropdownMenu class="text-center shadow">
          <li ngbDropdownItem class="c-pointer" (click)="changePageSize(50, 'NOT_AWARDED_LOT')">50</li>
          <li>
            <hr class="dropdown-divider ">
          </li>
          <li ngbDropdownItem class="c-pointer" (click)="changePageSize(100, 'NOT_AWARDED_LOT')">100</li>
        </ul>
      </div>
    </div>
    <div class="col-auto">
      <ngb-pagination [collectionSize]="notAwardedList.length!" [(page)]="pageNotAwarded"
        [pageSize]="pageSizeNotAwarded" (pageChange)="paginateNotAwardedLotList()">
      </ngb-pagination>
    </div>
  </div>
</div>

<hr class="hr mb-3 mt-1" *ngIf="currentAuction && showGeneratedView" />
<div *ngIf="currentAuction && showGeneratedView">
  <app-generated-invoice [selectedAuctionHouseId]="currentAuction.orgCode"
    [selectedAuctionId]="currentAuction.auctionId" [selectedUserId]="awardUserId"></app-generated-invoice>
</div>
