import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AuctionSearchData } from '../../models/user/AuctionSearchData';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { LandingService } from '../../services/LandingAndBidder.service';
import { AdminUserPrivilege } from '../../enums/AdminUserPrivilege';
import { AuctionHouseDto } from '../../models/user/AuctionHouseDto';
import { FormControl } from '@angular/forms';
import { AdminSourcingEventsDataHolderService } from '../../services/AdminSourcingEventsDataHolder.service ';
import { ApplicationUtils } from '../../util/ApplicationUtils';

@Component({
  selector: 'app-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.sass']
})
export class SearchCardComponent implements OnInit, OnDestroy {

  @Input()
  selectedStatusList$ = new BehaviorSubject<string[]>([]);
  selectedStatusList?: string[]

  auctionHouse?: AuctionHouseDto | null;
  auctionHouseSubscription?: Subscription;
  isLoading?: boolean = false;

  auctionSearchDataList: AuctionSearchData[] = [];
  auctionSearchDataList$ = new BehaviorSubject<AuctionSearchData[]>([]);
  ctrlSearchAuction: FormControl = new FormControl('');

  constructor(private adminDashboardService: AdminDashboardService, private userService: UserService,
    private landingService: LandingService, private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService) {

  }

  ngOnInit(): void {

    this.selectedStatusList$.subscribe(item => {
      if (item && item.length > 0) {
        this.selectedStatusList = item;
        this.loadAuctionSearchData();
      } else {
        this.selectedStatusList = []
        this.auctionSearchDataList = [];
        this.auctionSearchDataList$.next([]);
      }
    })

    this.ctrlSearchAuction.valueChanges.subscribe(() => {
      this.filterBySearchText()
    })

    this.auctionHouseSubscription = this.adminDashboardService._currentAuctionHouse$.subscribe(auctionHouse => {
      this.auctionHouse = auctionHouse;
    })

  }

  filterBySearchText() {
    let tempAuctionList: AuctionSearchData[] = [];

    if (this.ctrlSearchAuction.value != '') {
      let searchValue = this.ctrlSearchAuction.value.trim().toLowerCase();
      tempAuctionList = this.auctionSearchDataList.filter(item => item.auctionName?.toLowerCase().includes(searchValue)
        || item.companyName?.toLowerCase()?.includes(searchValue.toLowerCase())
        || item.sequenceNo?.toString().includes(searchValue) || item.auctionType?.toLowerCase().includes(searchValue)
        || item.listingContactDto?.name?.toLowerCase().includes(searchValue)
        || item.notOfLots?.toString().includes(searchValue) || item.noOfActiveLots?.toString().includes(searchValue)
        || item.addresses?.includes(searchValue)
      );
    } else {
      tempAuctionList = this.auctionSearchDataList;
    }


    this.auctionSearchDataList$.next(tempAuctionList);
  }


  loadAuctionSearchData() {
    if (this.auctionSearchDataList.length > 0) {
      return;
    }

    this.isLoading = true;
    let auctionHouseId = null;
    let user = this.userService.getUserEntity();
    if (user && ApplicationUtils.checkIfRoleIsAssigned(user.userPrivileges! , AdminUserPrivilege.IT_SETUP)) {
      auctionHouseId = this.adminDashboardService._currentAuctionHouse$.value?.orgCode;
    } else {
      auctionHouseId = this.landingService._currentAuctionHouse$.value?.orgCode
    }

    this.adminSourcingEventsDataHolderService.getAuctionSearchData(auctionHouseId!, this.selectedStatusList!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isLoading = false
          this.auctionSearchDataList = [];
          if (apiResponseDto.data) {
            this.auctionSearchDataList = apiResponseDto.data as AuctionSearchData[];
            this.auctionSearchDataList$.next(this.auctionSearchDataList);
          }

        } else {
          this.isLoading = false
        }
      },
      error: (error) => {
        this.isLoading = false
      }
    })

  }

  ngOnDestroy(): void {
    if (this.auctionHouseSubscription) {
      this.auctionHouseSubscription.unsubscribe();
    }
  }



}
