import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DashboardRedirectionService } from '../../services/dashboard-redirection.service';
import { Subscription } from 'rxjs';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { AdminSourcingEventsDataHolderService } from '../../services/AdminSourcingEventsDataHolder.service ';
import { RfxUiDto } from '../../models/rfx/RfxUiDto';
import { CatalogueL1EntityDto } from '../../models/CatalogueL1EntityDto';
import { CatalogueL2EntityDto } from '../../models/CatalogueL2EntityDto';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { UserService } from '../../services/user.service';
import { AdminUserPrivilege } from '../../enums/AdminUserPrivilege';
import { CatalogueService } from '../../services/catalogue.service';
import { UserEntityInfoDto } from '../../models/user/UserEntityInfoDto';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  userEntityDto?: UserEntityInfoDto

  selectedAuction?: AuctionEntityDto;
  selectedRfx?: RfxUiDto;
  selectedCatalogueL1?: CatalogueL1EntityDto
  selectedCatalogueL2?: CatalogueL2EntityDto

  isShowEventStagePage: boolean = false;
  pageName: string | null = ApplicationConstants.DASHBOARD_PAGE;

  updateBreadcrumbsSubscription$?: Subscription

  // catalogueL1?: string 
  // catalogueL2?: string

  constructor(
    private dashboardRedirectionService: DashboardRedirectionService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private catalogueService: CatalogueService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.userEntityDto = this.userService.getUserEntity();
    this.updateBreadcrumbsSubscription$ = this.dashboardRedirectionService.getUpdateBreadcrumbs$.subscribe(data => {
      if (data) {
        this.pageName = sessionStorage.getItem('PAGE_NAME');

        if (this.pageName == ApplicationConstants.ADMIN_CATALOGUE_PAGE ||
          this.pageName == ApplicationConstants.ADMIN_LINE_CATALOGUE_PAGE ||
          this.pageName == ApplicationConstants.ADMIN_CATALOGUE_REQUISITION_PAGE
        ) {
          this.selectedCatalogueL1 = this.catalogueService.selectedCatalogueL1;
          this.selectedCatalogueL2 = this.catalogueService.selectedCatalogueL2;
        }

        if (this.pageName == 'AUCTION_CREATOR_PAGE') {
          this.selectedAuction = this.adminSourcingEventsDataHolderService.getSelectedAuction();
        }

        if (this.pageName == 'RFX_CREATOR_PAGE') {
          this.selectedRfx = this.adminSourcingEventsDataHolderService.getSelectedRfx();
        }
      }
    })
  }

  navigateToPage(pageName: string) {
    if (pageName == ApplicationConstants.ADMIN_CATALOGUE_PAGE) {
      this.catalogueService.updateSelectedCatalogueL1EntityDto(undefined);
      this.catalogueService.updateSelectedCatalogueL2EntityDto(undefined);
    }

    if (this.userEntityDto?.userPrivileges?.includes(AdminUserPrivilege.SUPER_ADMIN)) {
      this.dashboardRedirectionService.storeNavigationContext(
        ApplicationConstants.SUPERADMIN, pageName
      );
    } else {
      this.dashboardRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN, pageName
      );
    }

    this.dashboardRedirectionService.setUpdateBreadcrumbs(true);
  }

  navigateToSuperAdminPage() {
    this.dashboardRedirectionService.storeNavigationContext(
      ApplicationConstants.SUPERADMIN,
      ApplicationConstants.ORGANIZATION_PAGE
    );
    this.dashboardRedirectionService.setUpdateBreadcrumbs(true);
  }

  navigateToCatalogueL1() {
    this.catalogueService.updateSelectedCatalogueL2EntityDto(undefined);

    if (this.userEntityDto?.userPrivileges?.includes(AdminUserPrivilege.SUPER_ADMIN)) {
      this.dashboardRedirectionService.storeNavigationContext(
        ApplicationConstants.SUPERADMIN,
        ApplicationConstants.ADMIN_CATALOGUE_PAGE
      );
    } else {
      this.dashboardRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.ADMIN_CATALOGUE_PAGE
      );
    }
    this.dashboardRedirectionService.setUpdateBreadcrumbs(true);
  }

  ngOnDestroy(): void {
    if (this.updateBreadcrumbsSubscription$) {
      this.updateBreadcrumbsSubscription$.unsubscribe();
    }
  }
}
