import { FileInfoDto } from "./FileInfoDto";
import { PreferenceCategoryDto } from "./user/PreferenceCategoryDto";

export class MaterialUiDto {
  orgCode?: string;

  //Material Details
  materialId?: string;
  materialName?: string;
  description?: string;
  image?: FileInfoDto;
  preferenceCategoryDto?: PreferenceCategoryDto;
  uom?: string;
  qty?: number;
  version?: number;


  //Company plants Details
  materialCompanyUiDtos?: MaterialCompanyUiDto[];
}

export class MaterialCompanyUiDto {
  companyCode?: string;
  companyName?: string;
  materialPlantUiDtos?: MaterialPlantUiDto[];
}

export class MaterialPlantUiDto {
  plantCode?: string;
  plantName?: string;
  currentLevel?: number;
  safetyMin?: number;
  safetyMax?: number;
}