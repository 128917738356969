import { Timestamp } from "@angular/fire/firestore";
import { PurchaseRequestStatus } from "../../enums/rfx/PurchaseRequestStatus";
import { FileInfoDto } from "../FileInfoDto";
import { PreferenceCategoryDto } from "../user/PreferenceCategoryDto";

export class PurchaseRequestUiDto {
  orgCode?: string;
  projectCode?: string;
  companyCode?: string;
  plantCode?: string;
  costCenterCode?: string;
  prId?: string;
  prCode?: string;
  title?: string;
  description?: string;
  status?: PurchaseRequestStatus;
  fileInfoDtos?: FileInfoDto[];
  preferenceCategoryDto?: PreferenceCategoryDto;
  estimatedDeliveryDate?: string;
  leadTime?: number;
  materialId?: string;
  uom?: string;
  qty?: number;
  expectedPrice?: number;
  updateTimeUtc?: number | Timestamp;
}
