<div>
  <div class=" input-group mb-2 mb-md-0">
    <input id="typeahead-basic" type="text"  [formControl]="ctrlLotSearch" id="ctrlLotSearch" class="form-control border-end-0 rounded-0 border bg-white" placeholder="Search by Auction Sequence or Name"/>
    <span class="input-group-append">
      <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
        <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
      </button>
    </span>
  </div>


  <div class=" mt-3 scroll-calendar-data" >
    <h5 class="heading-h5 mb-2 text-uppercase">Auctions are sorted by start date.</h5>
    <div class="text-center mt-5" *ngIf="!(auctionMap$ |async) || (auctionMap$ |async)!.size == 0">
      <h5>No Auctions available for this month!</h5>
    </div>
    <div *ngIf="(auctionMap$ |async) && (auctionMap$ |async)!.size > 0">
      <div  class="card border-0   mb-2 bg-white " *ngFor="let map of (auctionMap$ |async)  | keyvalue: originalOrder">

        <div class="ps-3 py-1 hover-class">
          <div class=" c-pointer mb-2">
            <b>  {{map.key! }}</b>
          </div>
         <!-- <hr class="hr " /> -->
          <ul style="list-style-type:none " class="ps-3">
            <div *ngFor="let auction of map.value as AuctionEntityDto; index as i">
              <div class="row mt-4">
                <div class="col-9">
                  <li  class=" c-pointer hover-class1  li-font-size " (click)="changeAuctionData(auction)">
                    <span class="pe-1" [ngClass]="{'border-start-upcoming' : auction?.status == 'LIVE_WAIT', 'border-start-live': auction?.status == 'LIVE'}"></span>
                    {{ auction.sequenceNo }} # {{ auction.auctionName }}
                  </li>
                </div>
                <div class="col-3 text-end pe-4 text-danger">
                  <b ngbTooltip="End Date">{{getEndDate(auction.endDate!, auction.endTime!) }}</b>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
