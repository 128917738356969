<div class="container">
  <div class="">
    <div class="p-2">
      <div class="row align-items-center">
        <div class="col-md-3  text-start mb-2">
          <div class="mb-2 input-group">
            <input #search placeholder="Search" type="text" id="ctrlSearchPR" [formControl]="ctrlSearch"
              class="form-control border-end-0 rounded-0 border bg-white" />
            <span class="input-group-append">
              <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
              </button>
            </span>
          </div>
        </div>
        <div class="col-md-5 text-start mb-2">
        </div>
        <div class="col-md-4 mb-2 text-md-end">
          <button class="btn btn-sm btn-new-class mt-2 mt-sm-0" *ngIf="isShowUserView" (click)="backToAllUsersList()">
            BACK
          </button>
          <!-- <button class="btn btn-sm btn-new-class mt-2 mt-sm-0" (click)="openUserDetail(userDetail)"> ADD
          </button> -->
        </div>
      </div>
      <div class="col-12">
        <ng-container *ngTemplateOutlet="isShowUserView ? userDetailsTemplate : usersListTemplate">
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #usersListTemplate>
  <div class="table-responsive">
    <table class="table table-bordered medium-font mb-0 align-middle">
      <thead class="table-dark">
        <tr class="text-center">
          <th class="text-center"><span>SNo</span></th>
          <th class=""><span>User Name</span></th>
          <th class=""><span>Designation</span></th>
          <th class=""><span>Email Id</span></th>
          <th>Status</th>
          <th class=""><span>Notification</span></th>
          <th class=""><span>Action</span></th>
        </tr>
      </thead>

      <tbody class="bg-white">
        <tr *ngIf="isDataLoading" class="align-middle">
          <td colspan="10" class="text-center">
            <div class="e-spinner"></div>
          </td>
        </tr>
        <tr class="text-center align-middle" *ngFor="let item of _filteredUserEntityInfoDtos$|async; index as i">
          <td class="">
            {{i+1}}
          </td>
          <td class="">
            {{item.firstName}} {{item.lastName}}
          </td>
          <td class="">
            {{item.designation}}
          </td>
          <td class="">
            {{item.primaryEmailId}}
          </td>
          <td class="">
            {{item.active ? 'Active' : 'Inactive'}}
          </td>
          <td class="">
            {{item.notificationActive ? 'Yes' : 'No'}}
          </td>
          <td class="">
            <a class="link-class" (click)="viewUserDetails(item)">
              View
            </a>
          </td>
        </tr>
        <tr *ngIf="!isDataLoading && userEntityInfoDtos.length == 0">
          <td colspan="10" class="text-center">
            <h5 class="heading-new">No Data Found</h5>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #userDetailsTemplate>
  <div class="container  p-3">
    <div class="col-12">
      <div class="border rounded bg-white mb-3">
        <header class="p-2">
          <div class="row ">
            <div class="col-md-6">
              <h5 class="heading-h5">USER DETAILS</h5>
            </div>
            <div class="col-md-6 text-md-end">
              <button class="btn btn-new-class btn-sm px-4 " (click)="openUserDetail(editUserDetailTemplate)">
                EDIT
              </button>
            </div>
          </div>
        </header>
        <hr class="hr" />
        <div class="col-12">
          <div class="row mx-0">
            <div class="col-12 div-class py-2">
              User Information
            </div>
            <hr class="hr" />
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-4 font-weight-normal py-2">
                    Employee Code
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedUserEntityInfoDto?.empCode}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    User Name
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedUserEntityInfoDto?.firstName}} {{selectedUserEntityInfoDto?.lastName}}
                  </div>
                  <hr class="hr" />

                  <div class="col-4 font-weight-normal py-2">
                    Email Id
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedUserEntityInfoDto?.primaryEmailId}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Designation
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedUserEntityInfoDto?.designation}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Company Code
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedUserEntityInfoDto?.companyCode}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Notification Active
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedUserEntityInfoDto?.notificationActive ? 'Yes' : 'No'}}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-12 text-center">
                    <img src="/downloadLandingBlob?fileId=${{selectedUserEntityInfoDto?.profileImage?.fileId}}"
                      [alt]="selectedUserEntityInfoDto?.firstName" class="profile-image-class"
                      *ngIf="selectedUserEntityInfoDto?.profileImage">
                    <img alt="" src="assets/images/avatar05.jpg" class="profile-image-class"
                      *ngIf="!selectedUserEntityInfoDto?.profileImage">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editUserDetailTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>User Detail</h2>
        </div>
        <div class="col-auto text-end">
          <span [ngClass]="{'disabled' : isLoading }">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
              (click)="closeModal()"></span>
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="updateUser()">
          <div class="row">
            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="firstName" placeholder="First Name"
                  formControlName="firstName"
                  [ngClass]="{'is-invalid': fc['firstName'].invalid && (fc['firstName'].dirty || fc['firstName'].touched)}">
                <label for="firstName">First Name</label>
                <div *ngIf="fc['firstName'].invalid && (fc['firstName'].dirty || fc['firstName'].touched)">
                  <p class="text-danger" *ngIf="fc['firstName'].errors?.['required']">Required
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="lastName" placeholder="Last Name" formControlName="lastName"
                  [ngClass]="{'is-invalid': fc['lastName'].invalid && (fc['lastName'].dirty || fc['lastName'].touched)}">
                <label for="lastName">Last Name</label>
                <div *ngIf="fc['lastName'].invalid && (fc['lastName'].dirty || fc['lastName'].touched)">
                  <p class="text-danger" *ngIf="fc['lastName'].errors?.['required']">Required
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="primaryEmailId" placeholder="name@example.com"
                  formControlName="primaryEmailId"
                  [ngClass]="{'is-invalid': fc['primaryEmailId'].invalid && (fc['primaryEmailId'].dirty || fc['primaryEmailId'].touched)}">
                <label for="primaryEmailId">Email</label>
                <div
                  *ngIf="fc['primaryEmailId'].invalid && (fc['primaryEmailId'].dirty || fc['primaryEmailId'].touched)">
                  <p class="text-danger" *ngIf="fc['primaryEmailId'].errors?.['required']">Required</p>
                  <p class="text-danger" *ngIf="fc['primaryEmailId'].errors?.['pattern']">
                    Invalid email address
                  </p>
                  <p class="text-danger" *ngIf="fc['primaryEmailId'].value && fc['primaryEmailId'].value.length > 50">
                    Maximum Length Exceed
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="empCode" formControlName="empCode" placeholder="Employee Code">
                <label for="empCode">Employee Code</label>
              </div>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select name="Select Designation" class="form-control form-select" formControlName="designation"
              id="designation"
              [ngClass]="{'is-invalid': fc['designation'].invalid && (fc['designation'].dirty || fc['designation'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="designation?.name"
                *ngFor="let designation of applicationStartupDto?.cacheDesignationsDtos;">
                {{designation?.name}}
              </option>
            </select>
            <label for="designation">Designation</label>
            <div *ngIf="fc['designation'].invalid && (fc['designation'].dirty || fc['designation'].touched)">
              <p class="text-danger" *ngIf="fc['designation'].errors?.['required']">Required
              </p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <p-multiSelect [options]="(selectedCompanyUiDto?.plantUiDtos ?? [])" formControlName="plantCodes"
              optionLabel="plantName" optionValue="plantCode"></p-multiSelect>
          </div>

          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Onboarding User Saved'">
            </app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="(this._showErrorMsg$ | async)">
            </app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="col-12 text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class c-pointer" (click)="updateUser()">Update</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</ng-template>