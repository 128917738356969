<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>ADD APPROVAL WORKFLOWS</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <p-steps [model]="roleWorkflowSteps" [readonly]="false" [activeIndex]="currentStep"
        (activeIndexChange)="onChangeCurrentStep($event)">
      </p-steps>
      <ng-container *ngTemplateOutlet="currentStep == 1 ? stepTwoTemplate : stepOneTemplate">
      </ng-container>
    </div>
  </div>
</div>

<ng-template #stepOneTemplate>
  <div class="mt-5">
    <div class="stepper">
      <div class="step">
        <div class="step-header">
          <div class="step-number">1</div>
          <div class="step-title">Rule Name
          </div>
        </div>
        <div class="mt-3 ms-5">
          <div class="step-content" [class.active]="stepOneMode == 'RULE_NAME'">
            <ng-container *ngIf="stepOneMode == 'RULE_NAME'">
              <div class="col-12">
                <div class="col-6">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="ruleName" [formControl]="ctrlRuleName"
                      [ngClass]="{'is-invalid': ctrlRuleName.invalid && (ctrlRuleName.dirty || ctrlRuleName.touched)}">
                    <label for="ruleName">Rule Name</label>
                    <div *ngIf="ctrlRuleName.invalid && (ctrlRuleName.dirty || ctrlRuleName.touched)">
                      <p class="text-danger" *ngIf="ctrlRuleName.errors?.['required']">Required
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-end my-3">
                  <button class="btn btn-sm btn-new-class" (click)="nextStep()">Next</button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="step">
        <div class="step-header">
          <div class="step-number">2</div>
          <div class="step-title">Select Workflow
          </div>
        </div>
        <div class="mt-3 ms-5">
          <div class="step-content" [class.active]="stepOneMode == 'SELECT_WORKFLOW'">
            <ng-container *ngIf="stepOneMode == 'SELECT_WORKFLOW'">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-3">
                    <div class="card bg-white ">
                      <div class="py-2 grey-background outline-border-2">
                        <div class="row px-2">
                          <div class="col-12 text-center">
                            <b class="medium-font mt-1" *ngIf="!selectedApprovalWorkflow?.code">
                              Select Workflow
                            </b>
                            <span *ngIf="selectedApprovalWorkflow?.code">
                              <img src="assets/icons/approved_green.svg" class="svg-icon-class-small">
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="p-2 scroll-hidden-scroll">
                        <div class="mb-1" *ngFor="let workflow of onboardingWorkflows;">
                          <span class="badge me-3 mb-2 p-2 select-event"
                            [ngClass]="{'selected-event' : workflow.code == selectedApprovalWorkflow?.code}"
                            (click)="onChangeApprovalWorkflow(workflow)">
                            <div class="row">
                              <div class="col-10 text-center overflow-class-small">
                                {{workflow.name}}
                              </div>
                              <div class="col-1 text-center" ngbTooltip="{{workflow.name}}" container="body"
                                placement="top">
                                <span *ngIf="!selectedApprovalWorkflow?.code">
                                  <img src="assets/icons/info_light.svg" class="svg-icon-class-small">
                                </span>
                                <span *ngIf="selectedApprovalWorkflow?.code">
                                  <img src="assets/icons/info_dark.svg" class="svg-icon-class-small">
                                </span>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Select Company -->
                  <div class="col-md-3" *ngIf="selectedApprovalWorkflow?.code">
                    <div class="card bg-white ">
                      <div class="py-2 grey-background outline-border-2">
                        <div class="row px-2">
                          <div class="col-10 px-0 text-center">
                            <b class="medium-font mt-1" *ngIf="!selectedApprovalCompany?.companyCode">
                              Select Company
                            </b>
                            <span *ngIf="selectedApprovalCompany?.companyCode">
                              <img src="assets/icons/approved_green.svg" class="svg-icon-class-small">
                            </span>
                          </div>
                          <div class="col-2 px-0 text-start" *ngIf="selectedApprovalCompany">
                            <img src="assets/icons/add-plus-square.svg" class="svg-icon-class-small"
                              *ngIf="!isShowPlantsList" (click)="togglePlants()">
                            <img src="assets/icons/minus-square.svg" class="svg-icon-class-small"
                              *ngIf="isShowPlantsList" (click)="togglePlants()">
                          </div>
                        </div>
                      </div>
                      <div class="p-2 scroll-hidden-scroll">
                        <div *ngIf="isCompanyLoading" class="text-center">
                          <div class="e-spinner"></div>
                        </div>
                        <div class="mb-1" *ngFor="let company of companiesList;">
                          <span class="badge me-3 mb-2 p-2 select-event"
                            [ngClass]="{'selected-event' : company.companyCode == selectedApprovalCompany?.companyCode}"
                            (click)="onChangeApprovalCompany(company)">
                            <div class="row">
                              <div class="col-10 text-center overflow-class-small">
                                {{company.companyName}}-({{company.companyCode}})
                              </div>
                              <div class="col-1 text-center" ngbTooltip="{{company.companyName}}" container="body"
                                placement="top">
                                <span *ngIf="!selectedApprovalCompany?.companyCode">
                                  <img src="assets/icons/info_light.svg" class="svg-icon-class-small">
                                </span>
                                <span *ngIf="selectedApprovalCompany?.companyCode">
                                  <img src="assets/icons/info_dark.svg" class="svg-icon-class-small">
                                </span>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Select Plants -->
                  <div class="col-md-3" *ngIf="selectedApprovalCompany && isShowPlantsList">
                    <div class="card bg-white ">
                      <div class="py-2 grey-background outline-border-2">
                        <div class="row px-2">
                          <div class="col-12 text-center">
                            <b class="medium-font mt-1" *ngIf="!selectedApprovalPlant?.plantCode">
                              Select Plant
                            </b>
                            <span *ngIf="selectedApprovalPlant?.plantCode">
                              <img src="assets/icons/approved_green.svg" class="svg-icon-class-small">
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="p-2 scroll-hidden-scroll">
                        <div *ngIf="isPlantLoading" class="text-center">
                          <div class="e-spinner"></div>
                        </div>
                        <div class="mb-1" *ngFor="let plant of allPlantsList;">
                          <span class="badge me-3 mb-2 p-2 select-event"
                            [ngClass]="{'selected-event' : plant.plantCode == selectedApprovalPlant?.plantCode}"
                            (click)="onChangeApprovalPlant(plant)">
                            <div class="row">
                              <div class="col-10 text-center overflow-class-small">
                                {{plant.plantName}}-({{plant.plantCode}})
                              </div>
                              <div class="col-1 text-center" ngbTooltip="{{plant.plantName}}" container="body"
                                placement="top">
                                <span *ngIf="!selectedApprovalPlant?.plantCode">
                                  <img src="assets/icons/info_light.svg" class="svg-icon-class-small">
                                </span>
                                <span *ngIf="selectedApprovalPlant?.plantCode">
                                  <img src="assets/icons/info_dark.svg" class="svg-icon-class-small">
                                </span>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Select Approval Type -->
                  <!-- <div class="col-md-3" *ngIf="selectedApprovalCompany">
                    <div class="card bg-white ">
                      <div class="py-2 grey-background">
                        <div class="row px-2">
                          <div class="col-12 text-center">
                            <b class="medium-font mt-1" *ngIf="!selectedApprovalType">
                              Select Type
                            </b>
                            <span *ngIf="selectedApprovalType">
                              <img src="assets/icons/approved_green.svg" class="svg-icon-class-small ms-1">
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="p-2">
                        <span class="me-3">
                          <input type="radio" value="SEQUENCE" [(ngModel)]="selectedApprovalType">
                          Sequence
                        </span>
                        <span>
                          <input type="radio" value="VETO" [(ngModel)]="selectedApprovalType">
                          Veto
                        </span>
                      </div>
                    </div>
                  </div> -->
                </div>
                <div class="col-12 text-end my-3">
                  <button class="btn btn-sm btn-new-class" (click)="nextStep()">Next</button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="step">
        <div class="step-header">
          <div class="step-number"> 3</div>
          <div class="step-title">Select Designation</div>
        </div>
        <div class="step-content ms-5" [class.active]="stepOneMode == 'SELECT_DESIGNATION'">
          <ng-container *ngIf="stepOneMode == 'SELECT_DESIGNATION'">
            <div class="row">
              <div class="col-md-4">
                <div class="card bg-white ">
                  <div class="py-2 grey-background outline-border-2">
                    <div class="row px-2">
                      <div class="col-10 px-0 text-center">
                        <b class="medium-font mt-1" *ngIf="!selectedDesignationOne?.code">
                          Select Designation
                        </b>
                        <span *ngIf="selectedDesignationOne?.code">
                          <img src="assets/icons/approved_green.svg" class="svg-icon-class-small ms-1">
                        </span>
                      </div>
                      <div class="col-2 px-0 text-start" *ngIf="selectedDesignationOne">
                        <img src="assets/icons/add-plus-square.svg" class="svg-icon-class-small"
                          *ngIf="!isShowDesignationTwo" (click)="refreshDesignationWorkflow(2)">
                        <img src="assets/icons/minus-square.svg" class="svg-icon-class-small"
                          *ngIf="isShowDesignationTwo" (click)="refreshDesignationWorkflow(1)">
                      </div>
                    </div>
                  </div>
                  <div class="p-2 scroll-hidden-scroll">
                    <div class="mb-1" *ngFor="let designation of firstDesignationList;">
                      <span class="badge me-3 mb-2 p-2 select-event"
                        [ngClass]="{'selected-event' : designation.code == selectedDesignationOne?.code}"
                        (click)="onChangeDesignation(designation, 1)">
                        <div class="row">
                          <div class="col-10 text-center overflow-class-small">
                            {{designation.name}}
                          </div>
                          <div class="col-1 text-center" ngbTooltip="{{designation.name}}" container="body"
                            placement="top">
                            <span *ngIf="!selectedDesignationOne?.code">
                              <img src="assets/icons/info_light.svg" class="svg-icon-class-small">
                            </span>
                            <span *ngIf="selectedDesignationOne?.code">
                              <img src="assets/icons/info_dark.svg" class="svg-icon-class-small">
                            </span>
                          </div>

                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4" *ngIf="isShowDesignationTwo">
                <div class="card bg-white ">
                  <div class="py-2 grey-background outline-border-2">
                    <div class="row px-2">
                      <div class="col-10 px-0 text-center">
                        <b class="medium-font mt-1" *ngIf="!selectedDesignationTwo?.code">
                          Select Designation
                        </b>
                        <span *ngIf="selectedDesignationTwo?.code">
                          <img src="assets/icons/approved_green.svg" class="svg-icon-class-small ms-1">
                        </span>
                      </div>
                      <div class="col-2 px-0 text-start" *ngIf="selectedDesignationTwo">
                        <img src="assets/icons/add-plus-square.svg" class="svg-icon-class-small"
                          *ngIf="!isShowDesignationThree" (click)="refreshDesignationWorkflow(3)">
                        <img src="assets/icons/minus-square.svg" class="svg-icon-class-small"
                          *ngIf="isShowDesignationThree" (click)="refreshDesignationWorkflow(2)">
                      </div>
                    </div>
                  </div>
                  <div class="p-2 scroll-hidden-scroll">
                    <div class="mb-1" *ngFor="let designation of secondDesignationList;">
                      <span class="badge me-3 mb-2 p-2 select-event"
                        [ngClass]="{'selected-event' : designation.code == selectedDesignationTwo?.code}"
                        (click)="onChangeDesignation(designation, 2)">
                        <div class="row">
                          <div class="col-10 text-center overflow-class-small">
                            {{designation.name}}
                          </div>
                          <div class="col-1 text-center" ngbTooltip="{{designation.name}}" container="body"
                            placement="top">
                            <span *ngIf="!selectedDesignationTwo?.code">
                              <img src="assets/icons/info_light.svg" class="svg-icon-class-small">
                            </span>
                            <span *ngIf="selectedDesignationTwo?.code">
                              <img src="assets/icons/info_dark.svg" class="svg-icon-class-small">
                            </span>
                          </div>

                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4" *ngIf="isShowDesignationThree">
                <div class="card bg-white ">
                  <div class="py-2 grey-background outline-border-2">
                    <div class="row px-2">
                      <div class="col-12 text-center">
                        <b class="medium-font mt-1" *ngIf="!selectedDesignationThree?.code">
                          Select Designation
                        </b>
                        <span *ngIf="selectedDesignationThree?.code">
                          <img src="assets/icons/approved_green.svg" class="svg-icon-class-small ms-1">
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 scroll-hidden-scroll">
                    <div class="mb-1" *ngFor="let designation of thirdDesignationList;">
                      <span class="badge me-3 mb-2 p-2 select-event"
                        [ngClass]="{'selected-event' : designation.code == selectedDesignationThree?.code}"
                        (click)="onChangeDesignation(designation, 3)">
                        <div class="row">
                          <div class="col-10 text-center overflow-class-small">
                            {{designation.name}}
                          </div>
                          <div class="col-1 text-center" ngbTooltip="{{designation.name}}" container="body"
                            placement="top">
                            <span *ngIf="!selectedDesignationThree?.code">
                              <img src="assets/icons/info_light.svg" class="svg-icon-class-small">
                            </span>
                            <span *ngIf="selectedDesignationThree?.code">
                              <img src="assets/icons/info_dark.svg" class="svg-icon-class-small">
                            </span>
                          </div>

                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-end my-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
                <button class="btn btn-sm btn-new-class" (click)="nextStep()">Next</button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="my-3">
      <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
        [message]="responseMessage"></app-message-toast>
      <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
        [message]="responseMessage"></app-message-toast>
    </div>
    <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner"></div>
    </div>

  </div>
</ng-template>

<ng-template #stepTwoTemplate>
  <div>
    <div class="m-3">
      <div class="row d-none">
        <div class="col-6 col-md-3 mb-2">
          {{selectedApprovalWorkflow?.name}}
          <div class="small-font">
            Workflow Type
          </div>
        </div>
        <div class="col-6 col-md-3 mb-2">
          {{selectedApprovalCompany?.companyName}}-({{selectedApprovalCompany?.companyCode}})
          <div class="small-font">
            Company Name
          </div>
        </div>
        <div class="col-6 col-md-3 mb-2">
          {{selectedApprovalPlant != undefined ? selectedApprovalPlant.plantName + '-(' +
          selectedApprovalPlant.plantName + ')' : 'ALL'}}
          <div class="small-font">
            Plant Name
          </div>
        </div>
        <div class="col-6 col-md-3 mb-2">
          {{selectedApprovalType}}
          <div class="small-font">
            Type
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col-md-4">
            <div class="card bg-white ">
              <div class="py-2 grey-background outline-border">
                <div class="row px-2">
                  <div class="">
                    <b>
                      {{selectedDesignationOne?.name}}
                    </b>
                  </div>
                </div>
                <hr class="hr my-1">
              </div>
              <div class="p-2">
                <div class="card p-2 bg-white" *ngFor="let item of firstAppliedUiDtos;">
                  <div class="row">
                    <div class="col-1 ">
                      <img [alt]="item?.name" src="assets/images/avatar05.jpg" class="profile-image-xsm mt-0"
                        *ngIf="!item?.profileImage">
                      <img [alt]="item?.name" src="/downloadLandingBlob?fileId={{item?.profileImage}}"
                        class="profile-image-xsm mt-0" *ngIf="item?.profileImage">
                    </div>

                    <div class="col-md-5 div-class">
                      {{item.name}}
                      <div class="small-font">
                        {{item.emailId}}
                      </div>
                    </div>

                    <div class="col-md-6 text-end">
                      <input type="radio" name="ctrlDesignationOne" [value]="item.userId"
                        [formControl]="ctrlDesignationOne">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" *ngIf="selectedDesignationTwo">
            <div class="card bg-white ">
              <div class="py-2 grey-background outline-border">
                <div class="row px-2">
                  <div class="">
                    <b>
                      {{selectedDesignationTwo.name}}
                    </b>
                  </div>
                </div>
                <hr class="hr my-1">
              </div>
              <div class="p-2">
                <div class="card p-2 bg-white" *ngFor="let item of secondAppliedUiDtos;">
                  <div class="row">
                    <div class="col-1 ">
                      <img [alt]="item?.name" src="assets/images/avatar05.jpg" class="profile-image-xsm mt-0"
                        *ngIf="!item?.profileImage">
                      <img [alt]="item?.name" src="/downloadLandingBlob?fileId={{item?.profileImage}}"
                        class="profile-image-xsm mt-0" *ngIf="item?.profileImage">
                    </div>

                    <div class="col-md-5 div-class">
                      {{item.name}}
                      <div class="small-font">
                        {{item.emailId}}
                      </div>
                    </div>

                    <div class="col-md-6 text-end">
                      <input type="radio" name="ctrlDesignationTwo" [value]="item.userId"
                        [formControl]="ctrlDesignationTwo">
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" *ngIf="selectedDesignationThree">
            <div class="card bg-white ">
              <div class="py-2 grey-background outline-border">
                <div class="row px-2">
                  <div class="">
                    <b>
                      {{selectedDesignationThree.name}}
                    </b>
                  </div>
                </div>
                <hr class="hr my-1">
              </div>
              <div class="p-2">
                <div class="card p-2 bg-white" *ngFor="let item of thirdAppliedUiDtos;">
                  <div class="row">
                    <div class="col-1 ">
                      <img [alt]="item?.name" src="assets/images/avatar05.jpg" class="profile-image-xsm mt-0"
                        *ngIf="!item?.profileImage">
                      <img [alt]="item?.name" src="/downloadLandingBlob?fileId={{item?.profileImage}}"
                        class="profile-image-xsm mt-0" *ngIf="item?.profileImage">
                    </div>

                    <div class="col-md-5 div-class">
                      {{item.name}}
                      <div class="small-font">
                        {{item.emailId}}
                      </div>
                    </div>

                    <div class="col-md-6 text-end">
                      <input type="radio" name="ctrlDesignationThree" [value]="item.userId"
                        [formControl]="ctrlDesignationThree">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-3">
      <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
        [message]="responseMessage"></app-message-toast>
      <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
        [message]="responseMessage"></app-message-toast>
    </div>

    <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner"></div>
    </div>

    <div class="col-12 text-end my-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
      <button class="btn btn-sm btn-new-class" (click)="saveWorkflow()">Save</button>
    </div>

  </div>
</ng-template>



<ng-template #addDocs>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Add Comments/Docs</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <label>Comments</label>
      <div class="mb-3">
        <textarea type="text" class="form-control" rows="4"></textarea>
      </div>
      <div class="form-floating mb-3">
        <input id="documentDoc1" type="text" class="form-control" />
        <input id="documentDoc2" #documentDoc class="d-none" />
        <label for="workFlow">Add Docs</label>
      </div>
      <div class="text-end col-12 ">
        <button class="btn btn-sm btn-new-class">Save</button>
      </div>
    </div>
  </div>
</ng-template>


<!-- <ng-template #workflow>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>SELECT WORKFLOW</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="col-12">
        <div class="row">
          <div class="col-md-3">
            <div class="card bg-white ">
              <div class="py-2 grey-background outline-border-2">
                <div class="row px-2">
                  <div class="col-8">
                    <b class="medium-font mt-1">
                      Select Workflow
                    </b>
                  </div>
                  <div class="col-4 text-end">
                  </div>
                </div>
              </div>
              <div class="p-2 scroll-hidden-scroll">
                <div class="mb-1">
                  <span class="badge me-3 mb-2 p-2 selected-event select-event">
                    <div class="row">
                      <div class="col-10 text-center">
                        Sequence
                      </div>
                      <div class="col-2 text-center">
                        <img src="assets/icons/info_light.svg" class="svg-icon-class-small" ngbToolTip="Sequence"> 
                  <img src="assets/icons/info_dark.svg" class="svg-icon-class-small" ngbToolTip="Sequence">
                  </div>
                  </div>
                  </span>
                  </div>
                  <div class="mb-1">
                    <span class="badge me-3 mb-2 p-2 select-event">
                          <div class="row">
                            <div class="col-10 text-center">
                              Veto
                            </div>
                            <div class="col-2 text-center">
                              <img src="assets/icons/info_light.svg" class="svg-icon-class-small" ngbToolTip="Sequence">
                              <img src="assets/icons/info_dark.svg" class="svg-icon-class-small" ngbToolTip="Sequence"> 
                            </div>
                          </div>
                        </span>
                      </div>
                      </div>
                        </div>
                        </div>
                        <div class="col-md-3">
                          <div class="card bg-white ">
                            <div class="py-2 grey-background outline-border-2">
                              <div class="row px-2">
                                <div class="col-8">
                                  <b class="medium-font mt-1">
                                    Select Company
                                  </b>
                                </div>
        <div class="col-4 text-end">
        </div>
      </div>
    </div>
    <div class="p-2 scroll-hidden-scroll">
      <div class="mb-1">
        <span class="badge me-3 mb-2 p-2 selected-event select-event">
          <div class="row">
            <div class="col-10 text-center">
              Sequence
            </div>
            <div class="col-2 text-center">
              <img src="assets/icons/info_light.svg" class="svg-icon-class-small" ngbToolTip="Sequence"> 
              <img src="assets/icons/info_dark.svg" class="svg-icon-class-small" ngbToolTip="Sequence">
            </div>
          </div>
        </span>
      </div>
      <div class="mb-1">
        <span class="badge me-3 mb-2 p-2 select-event">
          <div class="row">
            <div class="col-10 text-center">
              Veto
            </div>
            <div class="col-2 text-center">
              <img src="assets/icons/info_light.svg" class="svg-icon-class-small" ngbToolTip="Sequence">
               <img src="assets/icons/info_dark.svg" class="svg-icon-class-small" ngbToolTip="Sequence"> 
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="col-md-3">
  <div class="card bg-white ">
    <div class="py-2 grey-background outline-border-2">
      <div class="row px-2">
        <div class="col-8">
          <b class="medium-font mt-1">
            Select Plant
          </b>
        </div>
        <div class="col-4 text-end">
        </div>
      </div>
    </div>
    <div class="p-2 scroll-hidden-scroll">
      <div class="mb-1">
        <span class="badge me-3 mb-2 p-2 selected-event select-event">
          <div class="row">
            <div class="col-10 text-center">
              Sequence
            </div>
            <div class="col-2 text-center">
              <img src="assets/icons/info_light.svg" class="svg-icon-class-small" ngbToolTip="Sequence"> 
              <img src="assets/icons/info_dark.svg" class="svg-icon-class-small" ngbToolTip="Sequence">
            </div>
          </div>
        </span>
      </div>
      <div class="mb-1">
        <span class="badge me-3 mb-2 p-2 select-event">
          <div class="row">
            <div class="col-10 text-center">
              Veto
            </div>
            <div class="col-2 text-center">
              <img src="assets/icons/info_light.svg" class="svg-icon-class-small" ngbToolTip="Sequence">
              <img src="assets/icons/info_dark.svg" class="svg-icon-class-small" ngbToolTip="Sequence"> 
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="col-md-3">
  <div class="card bg-white ">
    <div class="py-2 grey-background">
      <div class="row px-2">
        <div class="col-8">
          <b class="medium-font mt-1">
            Select Type
          </b>
        </div>
        <div class="col-4 text-end">
        </div>
      </div>
    </div>
    <div class="p-2">
      <span class="me-3">
        <input type="radio" name="" id="">
        Sequence
      </span>
      <span>
        <input type="radio" name="" id="">
        Veto
      </span>
    </div>
  </div>
</div>
</div>
</div>
<div class="text-end col-12 mt-3">
  <button class="btn btn-sm btn-new-class">Save</button>
</div>
      </div>
      </div>
</ng-template>  -->