import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal, NgbPopover, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AngularEditorComponent, AngularEditorConfig } from 'src/assets/plugins/angular-editor';
import { AiDescriptionGeneratorComponent } from 'src/app/shared/components/ai-description-generator/ai-description-generator.component';
import { AuctionValidationService } from 'src/app/shared/services/auction-validation.service';
import { AddressDto } from 'src/app/shared/models/Address';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { RfxSubcategorySaveWrapperDto } from 'src/app/shared/models/user/RfxSubcategorySaveWrapperDto';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';
import { RfxValidationErrorCodeDto } from 'src/app/shared/models/user/RfxValidationErrorCodeDto';
import { OrganizationUiDto } from 'src/app/shared/models/OrganizationUiDto';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';

@Component({
  selector: 'app-add-subcategory',
  templateUrl: './add-subcategory.component.html',
  styleUrls: ['./add-subcategory.component.sass']
})
export class AddSubcategoryComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() mode?: string;

  formGroup: FormGroup;
  isLoading?: boolean;
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>("");
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  organizationUiDto?: OrganizationUiDto;
  rfxEntityDto?: RfxUiDto;
  endTime?: NgbTimeStruct;
  ngbPopover?: NgbPopover;
  rfxSubcategoryEntityDto?: RfxSubcategoryUiDto;
  selectedRfxSubscription$?: Subscription;
  selectedRfxSubcategorySubscription$?: Subscription;

  isSubmitted: boolean = false;


  minDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  }

  rfxValidationErrorCodeDtoList?: RfxValidationErrorCodeDto[];

  @ViewChild("descriptionEditor") descriptionEditor?: AngularEditorComponent;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '3rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '2',
    sanitize: false,
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode'
      ]
    ],
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'Page Divider',
        class: 'break',
        tag: 'div',
      },
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'hr',
      },
    ],
  };

  constructor(
    public validationService: AuctionValidationService,
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    public adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    public onboardingService: OnboardingService,
    public datePipe: DatePipe,
    public subcategoryService: AdminRfxSubcategoryDataHolderService,
    private ngbModal: NgbModal,
    private checkDetectRef: ChangeDetectorRef
  ) {
    this.formGroup = formBuilder.group({
      title: new FormControl('', Validators.required),
      description: new FormControl(''),
      endDate: new FormControl('', Validators.required),
      endTime: new FormControl('', Validators.required),
      address: new FormControl('')
    })
  }

  ngOnInit(): void {
    let currentDate = new Date();

    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(rfx => {
      if (rfx) {
        this.rfxEntityDto = rfx;

        /**
         * This check is required to make sure that dropdown does not re-render.
         * As re-rendering the dropdown makes the default selection i.e the first element.
         *
         * If user has selected the element which is of index > 0 , the method checks if there is any update then only
         * assign addresses to variable so that it can re-render dropdown, else it will not re-render and user selection
         * will remain intact.
         */

        this.checkDetectRef.detectChanges();
      }
    })

    this.selectedRfxSubcategorySubscription$ = this.subcategoryService.selectedRfxSubcategory$.subscribe(subcategory => {
      if (subcategory) {
        if (subcategory && this.mode == "EDIT") {
          this.rfxSubcategoryEntityDto = subcategory;

          this.formGroup.patchValue(this.rfxSubcategoryEntityDto);

          if(this.rfxSubcategoryEntityDto?.endTime){
            let endDate = ApplicationUtils.getNgbPickerDate(this.rfxSubcategoryEntityDto?.endDate!);
            this.formGroup.get('endDate')?.patchValue(endDate);
          }

          if (this.rfxSubcategoryEntityDto?.endTime) {
            this.endTime = ApplicationUtils.getNgbPickerTime(this.rfxSubcategoryEntityDto?.endTime!);
            let endTime12h = ApplicationUtils.convertTime24to12(this.rfxSubcategoryEntityDto?.endTime!, this.datePipe);
            this.formGroup.get('endTime')?.patchValue(endTime12h);
          } else {
            this.endTime = ApplicationUtils.getNgbPickerTime(`${currentDate.getHours()}:${currentDate.getMinutes()}`);
          }
        } else {
          this.endTime = ApplicationUtils.getNgbPickerTime(`${currentDate.getHours()}:${currentDate.getMinutes()}`);
        }
        this.formGroup.updateValueAndValidity()

      }
    })

    this.organizationUiDto = this.onboardingService.getOrganizationUiDto;

    this.formGroup.controls['endDate'].valueChanges.subscribe((val) => {
      if (val && !this.isSubmitted) {
        this.checkEndTimeValidation();
      }
    })
  }

  ngAfterViewInit(): void {
    ApplicationUtils.clearTextFormatOnPaste('descriptionEditor')
  }

  haveAnyErrorInEndDate(){
    if(this.checkEndTimeHTMLValidation() && !this.rfxSubcategoryEntityDto?.active){
      return true
    }
    return false
  }

  get fc(): any {return this.formGroup.controls;}

  openTimepicker(p: NgbPopover, template: any) {
    if (this.ngbPopover?.isOpen) this.ngbPopover.close();
    if (!p.isOpen()) {
      this.ngbPopover = p;
      p.ngbPopover = template;
      p.open();
    } else {
      p.close();
    }
  }

  setTime(time: string) {
    if (time == 'endTime') {
      if (this.endTime?.hour != null && this.endTime?.minute != null && this.endTime?.second != null) {
        let convertedTime = ApplicationUtils.getTimeFromNgTimePicker(this.datePipe, this.endTime);
        this.formGroup.controls?.['endTime'].patchValue(convertedTime);
      }
    }
    if (this.ngbPopover?.isOpen()) this.ngbPopover?.close();
    this.formGroup.updateValueAndValidity();

    this.checkEndTimeValidation()
  }

  populateSubcategoryData() : RfxSubcategoryUiDto {
    let subcategoryEntityDto: RfxSubcategoryUiDto = this.rfxSubcategoryEntityDto ? ApplicationUtils.clone(this.rfxSubcategoryEntityDto) : new RfxSubcategoryUiDto();

    if (!this.rfxSubcategoryEntityDto) {
      subcategoryEntityDto = new RfxSubcategoryUiDto();
      subcategoryEntityDto.orgCode = this.organizationUiDto?.orgCode;
      subcategoryEntityDto.rfxId = this.rfxEntityDto!.rfxId;
      subcategoryEntityDto.timeZone = this.rfxEntityDto!.timeZone;

      subcategoryEntityDto.subcategorySequence = this.getSubcategorySequence();
    }

    subcategoryEntityDto.title = this.formGroup.controls['title'].value;
    subcategoryEntityDto.description = this.formGroup.controls['description'].value;
    subcategoryEntityDto.endDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['endDate'].value)!;
    subcategoryEntityDto.endTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['endTime'].value);
    subcategoryEntityDto.sealedBid = 'UPCOMING_LIVE_CS';

    return subcategoryEntityDto;
  }

  checkEndTimeHTMLValidation() {
    if (this.formGroup.controls['endDate'].value && this.formGroup.controls['endTime'].value) {
      let endDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['endDate'].value);
      let endTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['endTime'].value);
      if (endDate && endTime) {
        let _sDate = ApplicationUtils.convertedDate(this.rfxEntityDto?.startDate!, this.rfxEntityDto?.startTime!);
        let _eDate = ApplicationUtils.convertedDate(endDate, endTime);

        let currentDate = new Date();

        if (_sDate && _eDate && (_sDate >= _eDate || currentDate >= _eDate)) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  checkEndTimeValidation() {
    if (this.formGroup.controls['endDate'].value && this.formGroup.controls['endTime'].value) {
      let endDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['endDate'].value);
      let endTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['endTime'].value);
      if (endDate && endTime) {
        let _sDate = ApplicationUtils.convertedDate(this.rfxEntityDto?.startDate!, this.rfxEntityDto?.startTime!);
        let _eDate = ApplicationUtils.convertedDate(endDate, endTime);

        let currentDate = new Date();

        if (_sDate && _eDate && (_sDate >= _eDate || currentDate >= _eDate)) {
          this._showErrorToast$.next(true);
          if (currentDate >= _eDate) {
            this._errorMsg$.next("THE LOT END DATE/TIME MUST BE AFTER THE CURRENT DATE/TIME");
          } else {
            this._errorMsg$.next("THE LOT END DATE/TIME MUST BE AFTER THE START DATE/TIME");
          }
          return true;
        } else {
          this._showErrorToast$.next(false);
          this._errorMsg$.next("");
        }
      }
      return false;
    }
    return true;
  }

  handleValidSubmit(saveForcefully: boolean) {
    if (!this.isAllowToEdit()) {
      return;
    }

    if (!saveForcefully) {
      if (this.descriptionEditor?.focused) {
        return;
      }
    }

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    if (this.checkEndTimeValidation()) {
      return;
    };

    this.rfxValidationErrorCodeDtoList = [];

    this.isSubmitted = true;
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);


    let subcategoryEntityDto = this.populateSubcategoryData();

    this.subcategoryService.saveRfxSubcategoryDetails(subcategoryEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data! as RfxSubcategorySaveWrapperDto;

          if(this.subcategoryService._selectedRfxSubcategory$.value?.subcategoryId  !=  data.rfxSubcategoryUiDto?.subcategoryId){
            this.subcategoryService.setSelectedRfxSubcategory(data.rfxSubcategoryUiDto!);

          }
          let rfxSubcategorySaveWrapperDto = apiResponseDto.data  as RfxSubcategorySaveWrapperDto;
          let rfxValidationErrorCodeDtoList =  rfxSubcategorySaveWrapperDto.rfxValidationErrorCodeDtos;

          if(!rfxValidationErrorCodeDtoList ||  rfxValidationErrorCodeDtoList.length == 0){
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            // this.lotService.addLot(auctionLotSaveWrapperDto.auctionLotEntityDto!);

            setTimeout(() => {
              this._isSaveButtonEnable$.next(true);
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)

          } else {
            this.isLoading = false;
            this._isSaveButtonEnable$.next(true);
            this.closeModal();

            this.rfxValidationErrorCodeDtoList = rfxValidationErrorCodeDtoList;
            this.openErrorModel();
          }

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving lot. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }
    if (this.selectedRfxSubcategorySubscription$) {
      this.selectedRfxSubcategorySubscription$.unsubscribe();
    }
  }

  openErrorModel() {
    let modalRef = this.modalService.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.rfxValidationErrorCodeDtoList;
  }

  getSubcategorySequence() {
    let toReturn = 1;
    let allRfxSubcategoryList = this.subcategoryService._allRfxSubcategoryList$.value;
    if (allRfxSubcategoryList && allRfxSubcategoryList.length > 0) {
      allRfxSubcategoryList.sort((a, b) => Number(b.subcategorySequence!) - Number(a.subcategorySequence!));
      toReturn = Number(allRfxSubcategoryList[0].subcategorySequence!) + 1
    }
    return toReturn;
  }
  openAiDescription() {
    this.ngbModal.open(AiDescriptionGeneratorComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    })
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.rfxEntityDto?.status == RfxStatus.LIVE_WAIT && !this.rfxEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.rfxEntityDto?.status == RfxStatus.LIVE && !this.rfxEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}
