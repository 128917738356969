import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { NewChatBotDto } from '../../models/chat-bot/NewChatBotDto';
import { UserService } from '../../services/user.service';

import { ChatBotActivity } from '../../models/chat-bot/ChatBotActivityDto';
import * as AdaptiveCards from 'adaptivecards';
import { OverlayPanel } from 'primeng/overlaypanel'
import { UserEntityInfoDto } from '../../models/user/UserEntityInfoDto';
interface Message {
  content: string;
  isUser: boolean;
}


@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.sass']
})
export class ChatbotComponent implements OnInit, AfterViewInit {

  chatBotMessages: ChatBotActivity[] = [];
  teamMessages: Message[] = [];
  newMessage: string = '';

  userEntityDto?: UserEntityInfoDto;
  newChatBotDto?: NewChatBotDto;

  overlayVisible: boolean = false;

  @ViewChild('messagesContainer') private messagesContainer?: ElementRef;
  @ViewChildren('adaptiveCardContainer') adaptiveCardContainers!: QueryList<ElementRef>;

  constructor(
    private chatService: ChatService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.chatService.updateNewChatBotDto(this.setTestData());
  }

  ngAfterViewInit(): void {
    this.userEntityDto = this.userService.getUserEntity();

    this.chatService.getNewChatBotDto$.subscribe((data) => {
      if (data) {
        this.newChatBotDto = data;
      } else {
        this.newChatBotDto = undefined;
      }
    });

    this.chatService.getChatBotMessages$.subscribe((data) => {
      if (data) {
        this.chatBotMessages = data;
        this.scrollToBottom();

        setTimeout(() => {
          this.renderAdaptiveCards(); // Render cards after a delay to ensure DOM is ready
        });
      } else {
        this.chatBotMessages = [];
      }
    });
  }

  toggle(op: OverlayPanel, event: any) {
    this.overlayVisible = !this.overlayVisible;
    op.toggle(event);

    if (!this.newChatBotDto && this.overlayVisible) {
      this.chatService.startNewConversation();
    }

    setTimeout(() => {
      this.renderAdaptiveCards(); // Render cards after a delay to ensure DOM is ready
    });
  }

  sendMessage() {
    if (this.newMessage.trim() === '') return;
    this.chatService.sendMessageInConversation(this.newMessage);
    this.newMessage = '';

    setTimeout(() => {
      this.scrollToBottom();
    }, 500);
  }

  private scrollToBottom(): void {
    if (this.messagesContainer) {
      try {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      } catch (err) {
        console.error('Error scrolling to bottom:', err);
      }
    }
  }

  private renderAdaptiveCards() {
    if (!this.adaptiveCardContainers) return; // Ensure containers are available

    this.chatBotMessages.forEach((message, messageIndex) => {
      if (!message || !message.attachments || message.attachments.length === 0) return; // Check for attachments

      message.attachments.forEach((attachment, attachmentIndex) => {
        if (attachment.contentType === 'application/vnd.microsoft.card.adaptive') {
          const adaptiveCard = new AdaptiveCards.AdaptiveCard();
          adaptiveCard.parse(attachment.content);
          const containerId = `adaptiveCard${messageIndex}-${attachmentIndex}`;
          const container = this.adaptiveCardContainers.find((_, i) => i === this.getContainerIndex(containerId));
          if (container) {
            adaptiveCard.onExecuteAction = (action) => {
              this.handleCardAction(action);
            };

            container.nativeElement.innerHTML = ''; // Clear container before rendering
            adaptiveCard.render(container.nativeElement);
          }
        }
      });
    })
  }
  
  private getContainerIndex(containerId: string): number {
    return this.adaptiveCardContainers.toArray().findIndex(container => container.nativeElement.id === containerId);
  }

  private handleCardAction(action: any) {
    console.log('Action executed:', action);
    const actionData = action.data;
    let text = '';

    if (actionData.action == 'proceed') {
      text = `Title: ${actionData.title}\nStart Date: ${actionData.startDate}\nEnd Date: ${actionData.endDate}`
    } else {
      text = actionData.rfxName;
    }
    
    this.chatService.sendMessageInConversation({text: text, value: actionData});
  }

  setTestData() {
    return {
      "conversationId": "7R0GjtFyHDeKwwLcNBQc7C-in",
      "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImxZd3JkMzFhdmtEckhnQ2Z5bmdCZVM4T196byIsIng1dCI6ImxZd3JkMzFhdmtEckhnQ2Z5bmdCZVM4T196byIsInR5cCI6IkpXVCJ9.eyJib3QiOiJwcm9jdXJlZXh0Ym90Iiwic2l0ZSI6IjE1VFY4ZDN3UUdZIiwiY29udiI6IjdSMEdqdEZ5SERlS3d3TGNOQlFjN0MtaW4iLCJuYmYiOjE3MTc1NzM2MDYsImV4cCI6MTcxNzU3NzIwNiwiaXNzIjoiaHR0cHM6Ly9kaXJlY3RsaW5lLmJvdGZyYW1ld29yay5jb20vIiwiYXVkIjoiaHR0cHM6Ly9kaXJlY3RsaW5lLmJvdGZyYW1ld29yay5jb20vIn0.bMs1R0cWBbp-qOq1H7_gIbE4MkanOof1Jauw4TOz4uz0Y6fx9XgdOENFF6Q1p41n1f5ym_03b9ae4E9okL0vJ7hMop0OKT_Zd988NpJzAz-Ab1dBBqIR1qKOAv2XIr733CMI74fmXYQMWdIU2i-eHiUZFvQUVdKESecFK9NS_0sqKcg1_xYxXB-aGgbDzCrykiwm8r_3Ulg0m1cB_zt0j_H4MEPKnnQr6sgBVNEaphYlt2xu2PN40549xLymVu2AzN5u-GiMbgiE-bAJgkN_Bdg7QCtDnTCjJ7Az02oIxlEYHhG6htJ0cduDxPBAYS0pNAReK9jPMyaBZO8RRxqsfQ",
      "expires_in": 3600,
      "streamUrl": "wss://india.directline.botframework.com/v3/directline/conversations/7R0GjtFyHDeKwwLcNBQc7C-in/stream?watermark=-&t=eyJhbGciOiJSUzI1NiIsImtpZCI6ImxZd3JkMzFhdmtEckhnQ2Z5bmdCZVM4T196byIsIng1dCI6ImxZd3JkMzFhdmtEckhnQ2Z5bmdCZVM4T196byIsInR5cCI6IkpXVCJ9.eyJib3QiOiJwcm9jdXJlZXh0Ym90Iiwic2l0ZSI6IjE1VFY4ZDN3UUdZIiwiY29udiI6IjdSMEdqdEZ5SERlS3d3TGNOQlFjN0MtaW4iLCJuYmYiOjE3MTc1NzM2MDYsImV4cCI6MTcxNzU3MzY2NiwiaXNzIjoiaHR0cHM6Ly9kaXJlY3RsaW5lLmJvdGZyYW1ld29yay5jb20vIiwiYXVkIjoiaHR0cHM6Ly9kaXJlY3RsaW5lLmJvdGZyYW1ld29yay5jb20vIn0.B1ybbKFh4cnmSHLGa7dyjNQnxPLewBNiRGkHu57ohvLyYbif1uB7EdYtYR8Lrx_zR3OUXyE9YshHdqpFYv_BBcmHw1L4GqKgFFUwmzQkxuXKj-N0PixMP0SCguEraevRtBlsTh6DPH6xR7Gdj-S38pALPIAvTGv2fOcUYh7zGtfv0f37eOOci-AxlLNbORYLkFfwCV-KIzHmKgVaKxBLLqm_m_NI0t_b4Dc7Wgo50DqjVZEljFnQIvXEWWgHJ0y-6Dh-LZtzjLLFW_MGoJzBghNzhRKs5Gw1sIs317Q5eztA2g3TPlN3y5OasA3Sp3cFmedTauUkKtmiWwquuLsAMw",
      "referenceGrammarId": "80957e2b-730d-bd55-a676-3af20c728f6e"
    }
  }

}
