import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { SessionInfoDto } from 'src/app/shared/models/SessionInfoDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { LotFeatures } from 'src/app/shared/models/user/LotFeatures';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';

@Component({
  selector: 'app-lot-features-details',
  templateUrl: './lot-features-details.component.html',
  styleUrls: ['./lot-features-details.component.sass']
})
export class LotFeaturesDetailsComponent implements OnInit, OnDestroy {

  @Input() mode?: string;
  @Input() index?: number;

  formGroup: FormGroup;
  isLoading?: boolean;
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>("");
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  sessionInfoDto?: SessionInfoDto;
  auctionEntityDto?: AuctionEntityDto;
  auctionLotEntityDto?: AuctionLotEntityDto;
  selectedAuctionSubscription$?: Subscription;
  selectedLotSubscription$?: Subscription;
  _validationErrorPresent$ =  new BehaviorSubject<boolean>(false);

  constructor(
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    public lotService: AdminLotsDataHolderService,
    public adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    public userService: UserService,
  ) {
    this.formGroup = formBuilder.group({
      featureKey: new FormControl('', [Validators.required]),
      featureValue: new FormControl('', [Validators.required])
    })
  }

  ngOnInit(): void {
    this.selectedAuctionSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe(auction => {
      if (auction) {
        this.auctionEntityDto = auction;
      }
    })
    this.selectedLotSubscription$ = this.lotService.selectedAuctionLot$.subscribe(lot => {
      if (lot) {
        this.auctionLotEntityDto = lot;
        if (this.auctionLotEntityDto && this.mode == "EDIT") {
          if (this.auctionLotEntityDto.lotFeatures && this.auctionLotEntityDto.lotFeatures.length > 0) {
            let selectedLotFeature = this.auctionLotEntityDto.lotFeatures[this.index!];
            this.formGroup.patchValue(selectedLotFeature);
          }
        }
      }
    })
    this.sessionInfoDto = this.userService.getSessionInfo;
  }

  get fc(): any { return this.formGroup.controls; }

  populateLotFeaturesData() {
    let auctionLotEntityDto: AuctionLotEntityDto = ApplicationUtils.clone(this.auctionLotEntityDto);

    if (!auctionLotEntityDto?.lotFeatures) {
      auctionLotEntityDto!.lotFeatures = [];
    }

    if (this.mode == 'EDIT') {
      let lotFeature = auctionLotEntityDto.lotFeatures[this.index!];
      lotFeature.featureKey = this.formGroup.controls['featureKey'].value;
      lotFeature.featureValue = this.formGroup.controls['featureValue'].value;
      auctionLotEntityDto?.lotFeatures.splice(this.index!, 1);
      auctionLotEntityDto?.lotFeatures.push(lotFeature);
    } else {
      let lotFeatures = new LotFeatures();
      lotFeatures.featureKey = this.formGroup.controls['featureKey'].value;
      lotFeatures.featureValue = this.formGroup.controls['featureValue'].value;
      auctionLotEntityDto?.lotFeatures.push(lotFeatures);
    }

    return auctionLotEntityDto;
  }

  handleValidSubmit() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this._validationErrorPresent$.next(false);

    if(this.formGroup.invalid){
      this._validationErrorPresent$.next(true);
      return;
    }

    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    let auctionLotEntityDto = this.populateLotFeaturesData();

    this.lotService.saveAuctionLotDetails(auctionLotEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          setTimeout(() => {
            this._isSaveButtonEnable$.next(true);
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;


        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while updating lot features. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })
  }

  closeModal() {
    this._validationErrorPresent$.next(false);
    this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    this._validationErrorPresent$.next(false);
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }
    if (this.selectedLotSubscription$) {
      this.selectedLotSubscription$.unsubscribe();
    }
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT && !this.auctionEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE && !this.auctionEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}
