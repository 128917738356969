<div class=" p-2 my-3">
  <div class="col-12 mt-2">
    <div class="row">
      <div class="col-md-5 col-12 text-start ">
        <h5 class="heading-h5">AUCTION SETUP
          <span class="" *ngIf="!(_isAuctionInfoHide$ | async)"><a href="#bottom"><span
                class="btn-link heading-h5">(Total Lots - {{auctionEntityDto?.notOfLots}})</span></a></span>
          <span *ngIf="(_isAuctionInfoHide$ | async)">({{auctionEntityDto?.sequenceNo}} #
            {{auctionEntityDto?.auctionName}})</span>
        </h5>
        <span class="ms-3"
        [ngClass]="getClassBasedOnStatus(auctionEntityDto?.status!)">{{getStatus(auctionEntityDto?.status!)}}</span>
      </div>
      <div class="col-md-7 col-12 text-md-end text-start mt-2 mt-md-0">
       
        <span class="span-class me-2">{{(_isAuctionInfoHide$ | async)? 'Show Auction Details' : 'Hide Auction Details'
          }}</span>
        <span class="form-switch">
          <input class="form-check-input c-pointer" type="checkbox" id="flexSwitchCheckChecked"
            (click)="changeAuctionInfoHideOrShow()" [checked]="_isAuctionInfoHide$ |async" /></span>
            <button class="btn-new-class btn-sm btn mt-2 ms-3 mt-md-0"
            (click)="openActivateModal(enableDisabledAuction, 'AUCTION')">
            {{auctionEntityDto?.active ? 'ACTIVE' : 'INACTIVE'}}
          </button>
      </div>
    </div>
  </div>
  <hr class="hr mt-1 mb-3" />
  <div *ngIf="!(_isAuctionInfoHide$ | async)">
    <!-- <div class="col-10 m-auto my-2">
      <div class="row">
        <div class="col-md-5 text-start mb-2">
          <span class=""
            [ngClass]="getClassBasedOnStatus(auctionEntityDto?.status!)">{{getStatus(auctionEntityDto?.status!)}}</span>
        </div>
        <div class="col-md-7 text-md-end text-start mb-2">
          <span class="span-class me-2">
            Bidders can see/ search only active auctions
          </span>
          <span class="form-switch ">
            <input #toggleRefAuction class="form-check-input switch-toggle-margin-top c-pointer" type="checkbox"
              id="flexSwitchCheckChecked1" [disabled]="!isAllowToEdit()"
              (click)="openActivateModal(enableDisabledAuction, toggleRefAuction, 'AUCTION')"
              [checked]="auctionEntityDto?.active" />
          </span>
          <span class="span-class ms-2 me-3">
            <b>{{auctionEntityDto?.active ? 'Active' : 'Inactive'}}</b>
          </span>
        </div>
      </div>
    </div> -->
    <div class="col-10 m-auto">
      <div class="row">
        <div class="col-md-6 mb-2">
          <app-auction-creation-details></app-auction-creation-details>
        </div>
        <div class="col-md-6 mb-2 ">
        <div class="mb-2">
          <app-bank-details></app-bank-details>
        </div>
          <!-- <app-bidder-registration-question></app-bidder-registration-question> -->
          <app-questionnaire-project></app-questionnaire-project>
        </div>
        <div class="col-md-6 mb-2 ">
          <app-auction-manage-address></app-auction-manage-address>
        </div>
        <div class="col-md-6 mb-2">
          <app-auction-manage-documents></app-auction-manage-documents>
        </div>
        <div class="col-md-6 mb-2 ">
          <app-auction-manage-contacts></app-auction-manage-contacts>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #enableDisabledBuyItNow>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 >{{auctionEntityDto?.buyItNowEnable ? 'DISABLE' : 'ENABLE'}} BUY IT NOW </h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal(activateModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p >Do you Want to  {{auctionEntityDto?.buyItNowEnable ? 'Disable' : 'Enable' }} Buy It Now</p>
      </div>

      <div class="my-3" >
        <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" message="Auction BUY-IT-NOW {{auctionEntityDto?.buyItNowEnable ? 'Enable' : 'Disable'}}  Successfully'"></app-message-toast>
      </div>

      <div class="text-end mb-3" *ngIf="auctionEntityDto && !isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="btn btn-new-class btn-sm me-2" (click)="closeModal(activateModalRef)">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="markBuyItNowActiveOrInActive('ENABLE', auctionValidationsModal)"
        *ngIf="!auctionEntityDto?.buyItNowEnable" [disabled]="(_showSuccessToast$ | async) || (_showErrorToast$ | async)">ENABLE</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="markBuyItNowActiveOrInActive('DISABLE', auctionValidationsModal)"
        *ngIf="auctionEntityDto?.buyItNowEnable" [disabled]="(_showSuccessToast$ | async) || (_showErrorToast$ | async)" >DISABLE</button>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
      </div>
    </div>
  </div>
</ng-template> -->

<ng-template #discardAuctionModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Auction Discard</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(auctionDiscardModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p>Are you sure you want to discard the selected auction? you will not be able to work on this auction once
            discarded and all lots associated with auction will also get discarded.</p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="Auction discarded successfully."></app-message-toast>
        </div>

        <div class="text-end mb-3" *ngIf="auctionEntityDto && !isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-sm me-3" (click)="closeModal(auctionDiscardModalRef)"
            *ngIf="!isShowCloseModalButton">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="discardAuction()"
            *ngIf="!isShowCloseModalButton" [disabled]="!isAllowToEdit()">DISCARD</button>

          <button type="button" class="btn btn-sm btn-outline-primary" (click)="closeModal(auctionDiscardModalRef)"
            *ngIf="isShowCloseModalButton">Close</button>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #enableDisabledAuction>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center" ngbAutoFocus>
          <h2>{{this.auctionEnableStatus ? 'DISABLE' : 'ENABLE'}} AUCTION </h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(activateModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p *ngIf="!auctionEnableStatus">Do you Want to Enable Auction</p>
          <p *ngIf="auctionEnableStatus">
            Would you like to deactivate the auction?<br>
            Currently, there {{noOfActiveLots == 1 ?
            'is' : 'are'}} <b>{{convertNumberToWords(noOfActiveLots!)}}</b> active
            {{noOfActiveLots == 1 ?
            'lot' : 'lots'}}, and deactivating the auction will
            also deactivate {{noOfActiveLots == 1 ?
            'it' : 'them'}}
          </p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="Auction {{auctionEntityDto?.active ? 'enabled' : 'disabled'}} successfully."></app-message-toast>
        </div>

        <div class="text-end mb-3" *ngIf="auctionEntityDto && !isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-sm me-3"
            (click)="closeModal(activateModalRef)">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary"
            (click)="checkLotsValidationAndMarkActive('ENABLE')" *ngIf="!auctionEntityDto?.active"
            [disabled]="!(_isSaveButtonEnable$ | async)">ENABLE</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="markAuctionActiveOrInActive('DISABLE')"
            *ngIf="auctionEntityDto?.active" [disabled]="!(_isSaveButtonEnable$ | async)">DISABLE</button>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
