<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>{{organizationUiDto ? 'EDIT' : 'ADD'}} ORGANIZATION</h2>
      </div>
      <div class="col-auto text-end">
        <span [ngClass]="{'disabled' : isLoading }">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
            (click)="closeModal()"></span>
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <form [formGroup]="formGroup" class="needs-validation" novalidate (keyup.enter)="saveOrganization()">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="name" placeholder="name" formControlName="name"
            [ngClass]="{'is-invalid': fc.name.invalid && (fc.name.dirty || fc.name.touched)}">
          <label for="name">Organization Name</label>
          <div class="invalid-feedback" *ngIf="fc.name.invalid && (fc.name.dirty || fc.name.touched)">
            <p class="e-fielderror e-fielderror-required" *ngIf="fc.name.errors?.required">Required</p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="orgCode" placeholder="Org Code" formControlName="orgCode"
            [ngClass]="{'is-invalid': fc.orgCode.invalid && (fc.orgCode.dirty || fc.orgCode.touched)}">
          <label for="url">Organization Code</label>
          <div class="invalid-feedback" *ngIf="fc.orgCode.invalid && (fc.orgCode.dirty || fc.orgCode.touched)">
            <p class="e-fielderror e-fielderror-required" *ngIf="fc.orgCode.errors?.required">Required</p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="adminDomainName" placeholder="adminDomainName"
            formControlName="adminDomainName" (change)="onChangeAdminDomainName()"
            [ngClass]="{'is-invalid': fc.adminDomainName.invalid && (fc.adminDomainName.dirty || fc.adminDomainName.touched)}">
          <label for="adminDomainName">Admin Domain</label>
          <div class="invalid-feedback"
            *ngIf="fc.adminDomainName.invalid && (fc.adminDomainName.dirty || fc.adminDomainName.touched)">
            <p class="e-fielderror e-fielderror-required" *ngIf="fc.adminDomainName.errors?.required">Required</p>
            <p class="e-fielderror e-fielderror-required" *ngIf="fc.adminDomainName.errors?.pattern">Invalid</p>
          </div>
        </div>

        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="bidderDomainName" placeholder="bidderDomainName"
            formControlName="bidderDomainName" (change)="onChangeBidderDomainName()"
            [ngClass]="{'is-invalid': fc.bidderDomainName.invalid && (fc.bidderDomainName.dirty || fc.bidderDomainName.touched)}">
          <label for="bidderDomainName">Bidder Domain</label>
          <div class="invalid-feedback"
            *ngIf="fc.bidderDomainName.invalid && (fc.bidderDomainName.dirty || fc.bidderDomainName.touched)">
            <p class="e-fielderror e-fielderror-required" *ngIf="fc.bidderDomainName.errors?.required">Required</p>
            <p class="e-fielderror e-fielderror-required" *ngIf="fc.bidderDomainName.errors?.pattern">Invalid</p>
          </div>
        </div>

        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Organization Saved'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="(this._showErrorMsg$ | async)">
          </app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class c-pointer" (click)="saveOrganization()">
            {{organizationUiDto ? 'UPDATE' : 'ADD'}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>