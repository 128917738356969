<div class="container mt-5">
  <div class="row ">
    <div class="col-lg-3 mb-2">
    <h4 class="heading-h5  text-behind-svg-icon">BIDDER DIRECTORY</h4>
      <button class="btn-new-class btn btn-sm ms-3 " (click)="openAddBidderModal()">
      ADD</button>
    </div>
    <div class="col-lg-9 mb-2 text-lg-end text-start">
      <div class="row  align-items-center justify-content-lg-end">
        <div class="col-lg-6">
        </div>
        
        <div class=" col-lg-4 mb-2 text-lg-end">
          <div class="mb-2  input-group">
            <input #search placeholder="Search" type="text" [formControl]="ctrlSearch"
              class="form-control border-end-0 rounded-0 border bg-white" id="ctrlSearch" />
            <span class="input-group-append">
              <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-bordered mb-0 medium-font">
        <thead class="table-dark">
          <tr class="text-center">
            <th class="text-center"><span>#</span></th>
            <th class=""><span >Company Name</span></th>
            <th class=""><span>Name</span></th>
            <th class=""><span >Email Id</span></th>
            <th class=""><span >Contact No</span></th>
            <th class=""><span >Country</span></th>
            <th class=""><span >Type</span></th>
            <th class=""><span >Category</span></th>
            <th class=""><span>Notification</span></th>
            <th class=""><span>Action</span></th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr *ngIf="isLoading" class="align-middle">
            <td colspan="10" class="text-center">
              <div class="e-spinner"></div>
            </td>
          </tr>
          <tr *ngIf=" !isLoading&& (_filteredBidderEntityDtos$ | async)?.length == 0">
            <td class="text-center" colspan="9" >
              <h5 class="heading-new"> No Data Available</h5>
            </td>
          </tr>

          <tr class="align-middle text-center" *ngFor="let item of _filteredBidderEntityDtos$ | async ; index as i">
            <td class=" text-center">{{i+1}}</td>
            <td class="">{{item.companyName}}</td>
            <td class="">{{item.name}}</td>
            <td class=" text-lowercase">
              <a class="btn-link" href="mailto:{{item.primaryEmailId}}"></a>
              {{item.primaryEmailId}}
            </td>
            <td class=" ">
              {{getCountryCode(item.mobileList!)}} {{getPrimaryMobileNo(item.mobileList!)}}
            </td>
            <td>{{item.address?.country}}</td>
            <td>{{item?.bidderType == 'REGISTERED_BIDDER'? 'Registered Bidder' : 'Global Bidder' }}</td>
            <td>{{item?.category }}</td>
            <td>
              <span class="badge badge-class " [ngClass]="item?.notificationActive? 'bg-success' : 'bg-danger'">
                {{item?.notificationActive ? 'ON' : 'OFF'}}
              </span>
            </td>
            <td>
              <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                <span ngbDropdownToggle data-bs-toggle="dropdown">
                  <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class">
                </span>
                <ul ngbDropdownMenu class="shadow mt-4">
                  <li ngbDropdownItem class="c-pointer" *ngIf="isAllowToShow(item.bidderType!)" (click)="openEditBidderModal(item)">
                    <span>
                     Edit
                    </span>
                  </li>
                  <li *ngIf="isAllowToShow(item.bidderType!)">
                    <hr class="dropdown-divider ">
                  </li>
                  <li ngbDropdownItem class="c-pointer" (click)="openBidderDetailsModal(item)">
                    <span>
                     Bidder Detail
                    </span>
                  </li>
                  <li>
                    <hr class="dropdown-divider ">
                  </li>
                  <li ngbDropdownItem class="c-pointer" (click)="openNotificationModal(notificationTemplate, item)">
                    <span>
                      Notifications
                    </span>
                  </li>
                </ul>
              </div>
            </td>
            <!-- <td class=" c-pointer">
            <img src="assets/icons/detail.svg" class="svg-icon-class c-pointer"
              (click)="openGlobalBidderDetailsModal(item)">
          </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #notificationTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Notification {{selectedBidderDto?.notificationActive ? 'disable' : 'enable'}} </h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p>Do you want to {{selectedBidderDto?.notificationActive ? 'disable' : 'enable'}} Notification</p>
        </div>
        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async"
            message="Notification {{selectedBidderDto?.notificationActive ? 'disable' : 'enable'}} successfully."></app-message-toast>
          <app-message-toast *ngIf="(_showErrorToast$ | async)" [isError]="true"
            [message]="_showErrorMsg$ | async"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isModelLoading">
          <div class="e-spinner" *ngIf="isModelLoading"></div>
        </div>



        <div class="col-12 text-end my-3" *ngIf="!isModelLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-width-small btn-sm me-3" (click)="closeModal()">
            CANCEL
          </button>
          <button type="button" class="btn btn-new-class btn-width-small btn-sm" [disabled]="!(_isSaveButtonEnable$ | async)" (click)="handleValidSubmit()">
            {{selectedBidderDto?.notificationActive ? 'OFF' : 'ON'}}
          </button>

        </div>

      </div>
    </div>
  </div>
</ng-template>
