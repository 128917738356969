<div class="p-2 mt-5">
  <div class="row align-items-center">

    <div class="col-md-3  text-start mb-2">

      <div class="mb-2 input-group">
        <input #search placeholder="Search" type="text" id="ctrlSearchPR" [formControl]="ctrlSearchPR"
          class="form-control border-end-0 rounded-0 border bg-white" />
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
    </div>
    <div class="col-md-5 text-start mb-2">
    </div>
    <div class="col-md-4 mb-2 text-md-end" *ngIf="type == 'PR'">
      <button class="btn btn-sm btn-primary-clr-class mt-2 mt-sm-0" (click)="openCreatePrModal(addPrModal)">
        <span class="svg-icon-class-small">+</span> <span class="ms-2">PR Request</span>
      </button>
    </div>
  </div>

  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-bordered medium-font mb-0 align-middle">
        <thead class="table-dark">
          <tr class="text-center">
            <th class="text-center"><span>#</span></th>
            <th class=""><span>Name</span></th>
            <th class=""><span>Company</span></th>
            <th class=""><span>Plant</span></th>
            <th>Qty</th>
            <th class=""><span>UoM</span></th>
            <th class=""><span>Status</span></th>
            <th class="" *ngIf="type == 'APPROVAL'"><span>Action</span></th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr *ngIf="isDataLoading" class="align-middle">
            <td colspan="10" class="text-center">
              <div class="e-spinner"></div>
            </td>
          </tr>

          <tr *ngIf="(filteredPurchaseRequestsList$ | async)!.length == 0 && !isDataLoading">
            <td colspan="10" class="text-center">
              <h5 class="heading-new">No Data Found</h5>
            </td>
          </tr>

          <tr class="text-center align-middle"
            *ngFor="let purchaseRequest of (filteredPurchaseRequestsList$ | async); index as i">
            <td class="">
              {{i + 1 + (pageSize * (page - 1))}}
            </td>
            <td class="">
              <a class="link-class" (click)="openCreatePrModal(addPrModal, purchaseRequest)">
                {{purchaseRequest?.title}}</a>
            </td>
            <td class="">
              {{purchaseRequest.companyCode}}
            </td>
            <td class="">
              {{getPlantName(purchaseRequest.companyCode!,  purchaseRequest.plantCode!)}}
            </td>
            <td class="">
              {{purchaseRequest?.qty}}
            </td>
            <td class="">
              {{purchaseRequest?.uom}}
            </td>
            <td class="ps-0 ">
              <div class="">
                <span class="ms-2">{{formatPrStatus(purchaseRequest?.status!)}}</span>
              </div>
            </td>
            <td *ngIf="type == 'APPROVAL'">
              <div>
                <button class="btn btn-sm btn-primary-clr-class-small" *ngIf="purchaseRequest.status == 'APPROVAL_PENDING'"
                  (click)="openApprovalWorkflowModal(purchaseRequest, approvalWorkflowTemplate)">Approve</button>
                  <button class="btn btn-sm btn-primary-clr-class-small" *ngIf="purchaseRequest?.status == 'APPROVAL_APPROVED'"
                (click)="openApprovalWorkflowModal(purchaseRequest, approvalWorkflowTemplate)">
                View
              </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row justify-content-between mt-3" *ngIf="tempPurchaseRequests.length > 0">
      <div class="col">
        <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
          <span class="span-class">Show rows per page </span>
          <span ngbDropdownToggle data-bs-toggle="dropdown">
            <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
          </span>
          <ul ngbDropdownMenu class="text-center shadow">
            <li ngbDropdownItem class="c-pointer" (click)="changePageSize(50)">50</li>
            <li>
              <hr class="dropdown-divider ">
            </li>
            <li ngbDropdownItem class="c-pointer" (click)="changePageSize(100)">100</li>
          </ul>
        </div>
      </div>
      <div class="col-auto">
        <ngb-pagination [collectionSize]="tempPurchaseRequests.length!" [(page)]="page" [pageSize]="pageSize"
          (pageChange)="doPaginate()">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>


<ng-template #addPrModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>{{isEdit ? 'EDIT' : 'ADD'}} PURCHASE REQUEST</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="title" formControlName="title"
              [ngClass]="{'is-invalid': fc['title'].invalid && (fc['title'].dirty || fc['title'].touched)}">
            <label for="title">Title</label>
            <div *ngIf="fc['title'].invalid && (fc['title'].dirty || fc['title'].touched)">
              <p class="text-danger" *ngIf="fc['title'].errors?.['required']">Required
              </p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select id="plantCode" class="form-control form-select" formControlName="plantCode"
              [ngClass]="{'is-invalid': fc['plantCode'].invalid && (fc['plantCode'].dirty || fc['plantCode'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="item.plantCode" *ngFor="let item of plantUiDtosForForm">{{item.plantName}}-({{item.plantCode}})
              </option>
            </select>

            <label for="plantCode">Plant</label>
            <div *ngIf="fc['plantCode'].invalid && (fc['plantCode'].dirty || fc['plantCode'].touched)">
              <p class="text-danger" *ngIf="fc['plantCode'].errors?.['required']">Required</p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select class="form-control form-select" formControlName="costCenterCode" id="costCenterCode"
              [ngClass]="{'is-invalid': fc['costCenterCode'].invalid && (fc['costCenterCode'].dirty || fc['costCenterCode'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option value="ADMIN">Administrative</option>
              <option value="HR">Human Resources</option>
              <option value="FIN">Finance</option>
              <option value="IT">Information Technology (IT)</option>
              <option value="SALES">Sales and Marketing</option>
              <option value="PROD">Production</option>
              <option value="LOG">Logistics</option>
              <option value="FAC">Facilities</option>
              <option value="PROC">Procurement</option>
              <option value="CS">Customer Support</option>
              <option value="TRNDEV">Training and Development</option>
              <option value="COMP">Compliance and Audit</option>
              <option value="HSE">Health and Safety</option>
              <option value="RND">Research and Development</option>
              <option value="LEGAL">Legal</option>
              <option value="MKTG">Marketing</option>
              <option value="CSR">Corporate Social Responsibility (CSR)</option>
              <option value="PM">Project Management</option>
              <option value="QM">Quality Management</option>
            </select>

            <label for="costCenterCode">Cost Center</label>
            <div *ngIf="fc['costCenterCode'].invalid && (fc['costCenterCode'].dirty || fc['costCenterCode'].touched)">
              <p class="text-danger" *ngIf="fc['costCenterCode'].errors?.['required']">Required</p>
            </div>
          </div>

          <div class="row">
            <div class="col-6 mb-3">
              <div class="form-floating">
                <input type="text" class="form-control" id="expectedPrice" formControlName="expectedPrice"
                  [ngClass]="{'is-invalid': fc['expectedPrice'].invalid && (fc['expectedPrice'].dirty || fc['expectedPrice'].touched)}">
                <label for="expectedPrice">Expected Price</label>
                <div *ngIf="fc['expectedPrice'].invalid && (fc['expectedPrice'].dirty || fc['expectedPrice'].touched)">
                  <p class="text-danger" *ngIf="invalidPriceValidator(fc['expectedPrice'])">Invalid</p>
                </div>
              </div>
            </div>

            <div class="col-6 mb-3">
              <div class="form-floating">
                <input type="text" class="form-control" id="leadTime" formControlName="leadTime"
                  [ngClass]="{'is-invalid': fc['leadTime'].invalid && (fc['leadTime'].dirty || fc['leadTime'].touched)}">
                <label for="leadTime">Lead Time(in days)</label>
                <div *ngIf="fc['leadTime'].invalid && (fc['leadTime'].dirty || fc['leadTime'].touched)">
                  <p class="text-danger" *ngIf="fc['leadTime'].errors?.['required']">Required</p>
                  <p class="text-danger" *ngIf="invalidPriceValidator(fc['leadTime'])">Invalid</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 mb-3">
              <div class="form-floating">
                <input type="number" class="form-control" id="qty" formControlName="qty"
                  [ngClass]="{'is-invalid': fc['qty'].invalid && (fc['qty'].dirty || fc['qty'].touched)}">
                <label for="qty">Quantity</label>
                <div *ngIf="fc['qty'].invalid && (fc['qty'].dirty || fc['qty'].touched)">
                  <p class="text-danger" *ngIf="fc['qty'].errors?.['required']">Required</p>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div class="form-floating">
                <input type="text" class="form-control" formControlName="uom" id="uom"
                  [ngClass]="{'is-invalid': fc['uom'].invalid && (fc['uom'].dirty || fc['uom'].touched)}">
                <label for="uom" class="">UoM</label>
              </div>
              <div *ngIf="fc['uom'].invalid && (fc['uom'].dirty || fc['uom'].touched)">
                <p class="text-danger" *ngIf="fc['uom'].errors?.['required']">Required
                </p>
              </div>
            </div>

            <div class="col-md-4 mb-3 ">
              <div class="input-group mb-md-0">
                <span class="border border-end-0 pt-3 px-1"
                  [ngClass]="{'border-danger': fc['estimatedDeliveryDate'].invalid && (fc['estimatedDeliveryDate'].dirty || fc['estimatedDeliveryDate'].touched)}">
                  <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
                </span>
    
                <div class="form-floating">
                  <input type="text" ngbDatepicker [minDate]="minDate" #estimatedDeliveryDate="ngbDatepicker"
                    (click)="estimatedDeliveryDate.toggle()" class="form-control border-start-0" placeholder="Start Date"
                    formControlName="estimatedDeliveryDate" id="estimatedDeliveryDate"
                    [ngClass]="{'is-invalid': fc['estimatedDeliveryDate'].invalid && (fc['estimatedDeliveryDate'].dirty || fc['estimatedDeliveryDate'].touched)}">
                  <label for="estimatedDeliveryDate" class="">Estimated Delivery Date</label>
                </div>
              </div>
              <div *ngIf="isSubmitted || fc['estimatedDeliveryDate'].invalid && (fc['estimatedDeliveryDate'].dirty || fc['estimatedDeliveryDate'].touched)">
                <p class="text-danger" *ngIf="fc['estimatedDeliveryDate'].errors?.['required']">Required
                </p>
              </div>
            </div>
          </div>

          <label for="description">Description</label>
          <div class="mb-3">
            <textarea type="text" class="form-control" id="description" formControlName="description" rows="3"
              [ngClass]="{'is-invalid': fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)}"></textarea>
            <div *ngIf="fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)">
              <p class="text-danger" *ngIf="fc['description'].errors?.['required']">Required
              </p>
            </div>
          </div>

          <div class="row align-items-center mb-3">
            <div class="col-6">
              <div class="form-floating">
                <input type="text" (click)="documentDoc.click()" [value]="_fileDisplayName$ |async" class="form-control"
                  id="addExternalPr"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && !(_fileDisplayName$ |async)}" />
                <input #documentDoc onclick="this.value=null;" type="file" (change)="chooseFile($event)"
                  class="d-none" />
                <label for="addExternalPr" *ngIf="!(currentFile || currentFileInfoDto)">Additional Document</label>
                <label for="addExternalPr" *ngIf="currentFile || currentFileInfoDto">Additional Document</label>
                <div>
                  <p class="text-danger" *ngIf="(_validationErrorPresent$ |async) && !(_fileDisplayName$ |async)">
                    Required
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6" *ngIf="currentFileInfoDto || currentFile">
              <div class="ms-3">
                <button class="btn btn-sm btn-new-class me-2" *ngIf="currentFileInfoDto"
                  (click)="previewAuctionDoc('SERVER')" ngbTooltip="{{currentFileInfoDto.displayName}}">
                  <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class">
                </button>
                <button class="btn btn-sm btn-new-class me-2" *ngIf="currentFile && !currentFileInfoDto"
                  (click)="previewAuctionDoc('LOCAL')">
                  <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class ">
                </button>
                <span [ngClass]="{'disabled':_isSaveButtonDisable$ |async}" (click)="removeAuctionDoc()">
                  <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                </span>
              </div>
            </div>
          </div>

          <hr class="hr my-2">

          <div *ngIf="!selectedPreferenceCategory">
            <label for="description">Select Material</label>
            <div class="col-9 mx-auto mb-3"
              [ngClass]="{'border border-danger': fc['materialId'].invalid && (fc['materialId'].dirty || fc['materialId'].touched)}">
              <app-categories-tree-view [selectionMode]="TreeSelectionMode.single"
                (onSelectedCategory)="onSelectedCategory($event)"></app-categories-tree-view>
            </div>
          </div>

          <div *ngIf="selectedPreferenceCategory">
            <label for="description">Selected Material</label>
            <div class="table-responsive">
              <table class="table table-bordered medium-font mb-0 align-middle">
                <thead class="table-dark">
                  <tr class="text-center">
                    <th class=""><span>Label One</span></th>
                    <th class=""><span>Label Two</span></th>
                    <th class=""><span>Label Three</span></th>
                    <th class=""><span>Label Four</span></th>
                    <th class=""><span>Label Five</span></th>
                    <th class=""><span>Action</span></th>
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <tr>
                    <td>{{selectedPreferenceCategory.categoryName}}</td>
                    <td>{{selectedPreferenceCategory.categoryLevelOneName}}</td>
                    <td>{{selectedPreferenceCategory.categoryLevelTwoName}}</td>
                    <td>{{selectedPreferenceCategory.categoryLevelThreeName}}</td>
                    <td>{{selectedPreferenceCategory.categoryLevelFourName}}</td>
                    <td><a class="link-class" (click)="toggleEditCategory()">Edit</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="my-3">
            <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async">
            </app-message-toast>
            <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
              [message]="'Project created successfully.'">
            </app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class" (click)="handleValidSubmit()">
              {{isEdit ? 'UPDATE' : 'ADD'}}
            </button>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #approveConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Purchase Request Approval</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(approvalModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p>Are you sure you want to {{approveRequest == 'APPROVE' ? 'approve' : 'reject' }}?</p>
        <div class="d-grid gap-2 mb-3" *ngIf="isApprovalLoading">
          <div class="e-spinner"></div>
        </div>
        <div class="my-3">
          <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async">
          </app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            [message]="'PR approved successfully.'">
          </app-message-toast>
        </div>

        <div class="col-12 text-end mt-3" *ngIf="!isApprovalLoading && !(_showSuccessToast$ | async)">
          <button class="btn btn-sm btn-primary-clr-class" (click)="approvePurchaseRequest()">OK</button>
        </div>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #approvalWorkflowTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Purchase Request Approval</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="row">
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.orgCode}}
            <div class="small-font">
              Organization Code
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            <span *ngIf="!selectedPurchaseRequestUiDto?.projectCode">NA</span> 
            <span *ngIf="selectedPurchaseRequestUiDto?.projectCode">{{selectedPurchaseRequestUiDto?.projectCode}}</span> 
            <div class="small-font">
              Project Code
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.companyCode}}
            <div class="small-font">
              Company Code
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.plantCode}}
            <div class="small-font">
              Plant Code
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.costCenterCode}}
            <div class="small-font">
              Cost Center Code
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.prCode}}
            <div class="small-font">
              PR Code
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.title}}
            <div class="small-font">
              title
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.description}}
            <div class="small-font">
              description
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.preferenceCategoryDto?.categoryLevelFourName}}
            <div class="small-font">
              Material Name
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.uom}}
            <div class="small-font">
              UoM
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.qty}}
            <div class="small-font">
              Quantity
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.expectedPrice}}
            <div class="small-font">
              Expected Price
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.leadTime}}
            <div class="small-font">
              Lead Time (in days)
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>
          <div class="row" *ngIf="!isLoading && appWorkflowUiDtoList.length > 0">
            <div class="col-md-4">
              <div class="card bg-white ">
                <div class="py-2 bg-grey1 outline-border">
                  <div class="row px-2">
                    <div class="">
                      <b>
                        Project Executive - Sourcing
                      </b>
                    </div>
                  </div>
                  <hr class="hr my-1">
                  <!-- <div class="row px-2">
                    <div class="col-8">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm mt-0">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm  mt-0">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm  mt-0">
                    </div>
                    <div class="col-4 text-end">
                      <img src="assets/icons/addnew_light.svg" class=" c-pointer svg-icon-small ">
                    </div>
                  </div> -->
                </div>
                <div class="p-2">
                  <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-8 div-class">
                        {{appWorkflowUiDtoList[0].name}}<br>
                        {{appWorkflowUiDtoList[0].emailId}}
                      </div>
                      <div class="col-md-4 text-end">
                        <span [ngClass]="getApprovalWorkflowStatusClass(appWorkflowUiDtoList[0].status)">
                          {{appWorkflowUiDtoList[0].status | titlecase}}
                        </span>
                        <div class="small-font">
                          {{appWorkflowUiDtoList[0].approvedDate}}
                        </div>
                      </div>
                      <div class="overflow-class-small small-font">
                        {{appWorkflowUiDtoList[0].comment}}
                      </div>
                    </div>

                    <div class="col-12 mt-3" *ngIf="isShowApprovedButton(0)">
                      <label for="ctrlComments">Comments</label>
                      <div class="mb-3">
                        <textarea type="text" class="form-control" id="ctrlComments" [formControl]="ctrlComments" rows="3">
                        </textarea>
                      </div>
                    </div>

                    <div class="col-12 text-end" *ngIf="isShowApprovedButton(0)">
                      <a class="text-danger me-3 c-pointer"
                        (click)="openPrApprovalConfirmModal(approveConfirmModal, 'REJECT')">Reject</a>
                      <a class="text-success c-pointer"
                        (click)="openPrApprovalConfirmModal(approveConfirmModal, 'APPROVE')">Approve</a>
                    </div>
                  </div>
                  <!-- <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-6 div-class">
                        Akshay Raj
                      </div>
                      <div class="col-md-6 text-end">
                        <span class="text-warning">Pending</span>
                        <div class="small-font">
                          12/11/24
                        </div>
                      </div>
                      <div class="overflow-class-small small-font">
                        Comments
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-6 div-class">
                        Prashant Gupta
                      </div>
                      <div class="col-md-6 text-end">
                        <span class="text-danger"> Rejected</span>
                        <div class="small-font">
                          12/11/24
                        </div>
                      </div>
                      <div class="overflow-class-small small-font">
                        Comments
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card bg-white ">
                <div class="py-2 bg-grey1 outline-border">
                  <div class="row px-2">
                    <div class="">
                      <b> Project Manager</b>
                    </div>
                  </div>
                  <hr class="hr my-1">
                  <!-- <div class="row px-2">
                    <div class="col-8">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm mt-0">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm  mt-0">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm  mt-0">
                    </div>
                    <div class="col-4 text-end">
                      <img src="assets/icons/addnew_light.svg" class=" c-pointer svg-icon-small ">

                    </div>
                  </div> -->
                </div>
                <div class="p-2">
                  <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-8 div-class">
                        {{appWorkflowUiDtoList[1].name}}<br>
                        {{appWorkflowUiDtoList[1].emailId}}
                      </div>
                      <div class="col-md-4 text-end">
                        <span [ngClass]="getApprovalWorkflowStatusClass(appWorkflowUiDtoList[1].status)">
                          {{appWorkflowUiDtoList[1].status | titlecase}}
                        </span>
                        <div class="small-font">
                          {{appWorkflowUiDtoList[1].approvedDate}}
                        </div>
                      </div>
                      <div class="overflow-class-small small-font" *ngIf="appWorkflowUiDtoList[1].comment">
                        {{appWorkflowUiDtoList[1].comment}}
                      </div>
                    </div>
                    <div class="col-12 mt-3" *ngIf="isShowApprovedButton(1)">
                      <label for="ctrlComments">Comments</label>
                      <div class="mb-3">
                        <textarea type="text" class="form-control" id="ctrlComments" [formControl]="ctrlComments" rows="3">
                        </textarea>
                      </div>
                    </div>

                    <div class="col-12 text-end" *ngIf="isShowApprovedButton(1)">
                      <a class="text-danger me-3 c-pointer"
                        (click)="openPrApprovalConfirmModal(approveConfirmModal, 'REJECT')">Reject</a>
                      <a class="text-success c-pointer"
                        (click)="openPrApprovalConfirmModal(approveConfirmModal, 'APPROVE')">Approve</a>
                    </div>
                  </div>
                  <!-- <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-6 div-class">
                        Akshay Raj
                      </div>
                      <div class="col-md-6 text-end">
                        <span class="text-warning">Pending</span>
                        <div class="small-font">
                          12/11/24
                        </div>
                      </div>
                      <div class="overflow-class-small small-font">
                        Comments
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-6 div-class">
                        Prashant Gupta
                      </div>
                      <div class="col-md-6 text-end">
                        <span class="text-danger"> Rejected</span>
                        <div class="small-font">
                          12/11/24
                        </div>
                      </div>
                      <div class="overflow-class-small small-font">
                        Comments
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card bg-white ">
                <div class="py-2 bg-grey1">
                  <div class="row px-2">
                    <div class="">
                      <b>
                        Chief Procurement Officer
                      </b>
                    </div>
                  </div>
                  <hr class="hr my-1">
                  <!-- <div class="row px-2">
                    <div class="col-8">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm mt-0">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm  mt-0">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm  mt-0">
                    </div>
                    <div class="col-4 text-end">
                      <img src="assets/icons/addnew_light.svg" class=" c-pointer svg-icon-small ">
                      <img src="assets/icons/edit_light.svg" class=" c-pointer svg-icon-small">
                    </div>
                  </div> -->
                </div>
                <div class="p-2">
                  <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-8 div-class">
                        {{appWorkflowUiDtoList[2].name}}<br>
                        {{appWorkflowUiDtoList[2].emailId}}
                      </div>
                      <div class="col-md-4 text-end">
                        <span [ngClass]="getApprovalWorkflowStatusClass(appWorkflowUiDtoList[2].status)">
                          {{appWorkflowUiDtoList[2].status | titlecase}}
                        </span>
                        <div class="small-font">
                          {{appWorkflowUiDtoList[2].approvedDate}}
                        </div>
                      </div>
                      <div class="overflow-class-small small-font" *ngIf="appWorkflowUiDtoList[2].comment">
                        {{appWorkflowUiDtoList[2].comment}}
                      </div>
                    </div>
                    <div class="col-12 mt-3" *ngIf="isShowApprovedButton(2)">
                      <label for="ctrlComments">Comments</label>
                      <div class="mb-3">
                        <textarea type="text" class="form-control" id="ctrlComments" [formControl]="ctrlComments" rows="3">
                        </textarea>
                      </div>
                    </div>

                    <div class="col-12 text-end" *ngIf="isShowApprovedButton(2)">
                      <a class="text-danger me-3 c-pointer"
                        (click)="openPrApprovalConfirmModal(approveConfirmModal, 'REJECT')">Reject</a>
                      <a class="text-success c-pointer"
                        (click)="openPrApprovalConfirmModal(approveConfirmModal, 'APPROVE')">Approve</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
