import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuctionQuestionStatus } from 'src/app/shared/enums/AuctionQuestionStatus';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { AuctionQuestionDto } from 'src/app/shared/models/user/AuctionQuestionDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { FirestoreListenerService } from 'src/app/shared/services/firestore-listener.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-questions-update',
  templateUrl: './questions-update.component.html',
  styleUrls: ['./questions-update.component.sass']
})
export class QuestionsUpdateComponent implements OnInit, OnDestroy {

  ctrlAnswerQuestion: FormControl = new FormControl('', Validators.required);
  ctrlSearch: FormControl = new FormControl('');

  selectedAuction?: AuctionEntityDto;
  selectedAuctionQuestion?: AuctionQuestionDto;

  auctionQuestionsList: Array<AuctionQuestionDto> = []
  allLotsList: Array<AuctionLotEntityDto> = []

  isLoading: boolean = false;
  selectedFilterType: string = 'ALL';

  filteredAuctionQuestionsList$ = new BehaviorSubject<AuctionQuestionDto[]>([]);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  selectedAuctionSubscription$?: Subscription;
  auctionQuestionsSubscription$?: Subscription;
  allLotsSubscription$?: Subscription;

  constructor(
    private adminService: AdminDashboardService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private lotService: AdminLotsDataHolderService,
    private userService: UserService,
    private firestoreService: FirestoreListenerService,
    private datePipe: DatePipe,
    private ngbModal: NgbModal
  ) { }

  ngOnInit(): void {
    this.selectedAuctionSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe((data) => {
      if (data) {
        this.selectedAuction = data;
        this.firestoreService.listenAuctionQuestionsForAdmin(this.selectedAuction.auctionId!)
      }
    })

    this.auctionQuestionsSubscription$ = this.adminService.getAuctionQuestionsList$.subscribe((data) => {
      if (data) {
        this.auctionQuestionsList = data;
        this.filterQuestions('');
      }
    })

    this.allLotsSubscription$ = this.lotService.getAllLots$.subscribe((data) => {
      if (data) {
        this.allLotsList = data;
      }
    })

    this.ctrlSearch.valueChanges.subscribe((val) => {
      this.filterQuestions(val);
    })
  }

  getLot(lotId: string) {
    if (this.selectedAuction?.auctionId && lotId) {
      return this.allLotsList.find((item) => item.auctionId == this.selectedAuction?.auctionId && item.lotId == lotId);
    }
    return undefined;
  }

  getDisplayDate(timestamp: Timestamp) {
    return this.datePipe.transform(timestamp.toDate(), 'dd MMMM ha')
  }

  openAnswerModal(answerModalContent: any, auctionQuestion: AuctionQuestionDto) {
    if (this.isLoading) return;
    this.ctrlAnswerQuestion.reset();
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    this.selectedAuctionQuestion = ApplicationUtils.clone(auctionQuestion);

    this.ctrlAnswerQuestion.patchValue(this.selectedAuctionQuestion?.answer);

    this.ngbModal.open(answerModalContent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  handleValidSubmit() {
    if (this.ctrlAnswerQuestion.invalid) {
      this.ctrlAnswerQuestion.markAsTouched();
      return;
    }

    this.saveAnswer('ANSWERED', this.selectedAuctionQuestion!)
  }

  saveAnswer(type: string, _auctionQuestion: AuctionQuestionDto) {
    this.selectedAuctionQuestion = ApplicationUtils.clone(_auctionQuestion);
    let auctionQuestion: AuctionQuestionDto = ApplicationUtils.clone(_auctionQuestion);

    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    let isAnswered = type == 'ANSWERED';

    this.isLoading = true;

    let userEntityDto = this.userService.getUserEntity();

    auctionQuestion.published = isAnswered ? true : false;
    auctionQuestion.status = type == 'PASS' ? AuctionQuestionStatus.PASS
      : type == 'ACKNOWLEDGE' ? AuctionQuestionStatus.ACKNOWLEDGE
        : AuctionQuestionStatus.ANSWERED;

    auctionQuestion.answer = this.ctrlAnswerQuestion.valid ? this.ctrlAnswerQuestion.value.trim() : '';
    auctionQuestion.adminId = userEntityDto.userId;
    auctionQuestion.adminEmailId = userEntityDto.primaryEmailId;
    auctionQuestion.answeredTimestamp = Timestamp.now();

    this.firestoreService.saveAuctionQuestion(auctionQuestion)
      .then(() => {
        this._showSuccessToast$.next(true);
        this.isLoading = false;

        setTimeout(() => {
          this._showSuccessToast$.next(false);
          if (auctionQuestion.status == 'ANSWERED') {
            this.closeModal();
          }
        }, 2000);

      }).catch((err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
      });
  }

  updateFilterType(type: string) {
    this.selectedFilterType = type;
    this.ctrlSearch.patchValue(this.ctrlSearch.value);
  }

  filterQuestions(searchText: string) {
    let _allAuctionQuestionsList = [...this.auctionQuestionsList];
    let tempAuctionQuestionsList: AuctionQuestionDto[] = [];

    if (this.selectedFilterType == 'PASS') {
      tempAuctionQuestionsList = _allAuctionQuestionsList.filter(item => item.status == AuctionQuestionStatus.PASS);
    } else if (this.selectedFilterType == 'ACKNOWLEDGE') {
      tempAuctionQuestionsList = _allAuctionQuestionsList.filter(item => item.status == AuctionQuestionStatus.ACKNOWLEDGE);
    } else if (this.selectedFilterType == 'ANSWERED') {
      tempAuctionQuestionsList = _allAuctionQuestionsList.filter(item => item.status == AuctionQuestionStatus.ANSWERED);
    } else {
      tempAuctionQuestionsList = _allAuctionQuestionsList;
    }

    if (searchText.length > 0) {
      let filterList = tempAuctionQuestionsList.filter(item => item.question?.toLowerCase()?.includes(searchText.toLowerCase())
        || item.bidderName?.toLowerCase()?.includes(searchText.toLowerCase())
        || this.getLot(item.lotId!)?.title?.toLowerCase()?.includes(searchText.toLowerCase()));

      this.filteredAuctionQuestionsList$.next(filterList);
    } else {
      this.filteredAuctionQuestionsList$.next(tempAuctionQuestionsList);
    }
  }

  closeModal() {
    this.ngbModal.dismissAll();
    this.isLoading = false;
    this.ctrlAnswerQuestion.reset();
  }

  truncateString(input?: string) {
    if (input) {
      if (input.length > 30) {
        return ApplicationUtils.truncateString(input, 30);
      } else {
        return input;
      }
    } else {
      return ''
    }
  }

  getStatusText(status?: string): string {
    return status == 'ACKNOWLEDGE' ? 'Accept'
    : status == 'ANSWERED' ? 'Replied'
    : status == 'PASS' ? 'Pass'
    : status == 'ALL' ? 'All'
    : ''
  }

  ngOnDestroy(): void {
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }
    if (this.auctionQuestionsSubscription$) {
      this.auctionQuestionsSubscription$.unsubscribe();
    }
    if (this.allLotsSubscription$) {
      this.allLotsSubscription$.unsubscribe();
    }
  }
}
