<div class="p-2 mt-5">
  <div class="row align-items-center">
    <div class="col-md-3  text-start mb-2">
      <div class="mb-2 input-group">
        <input placeholder="Search" id="adminCatalogueSearch" type="text" class="form-control border-end-0 rounded-0 border bg-white" [formControl] ="ctrlSearchCatalogueL1" />
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
    </div>
    <div class="col-md-5 text-start mb-2">
      <div class="row justify-content-md-start">
        <div class="col-xl-1 col-md-2 col-3 text-center mb-2 ">
          <div class="small-font">
            Filter
          </div>
          <img src="assets/icons/Filter-Icon.svg" class="svg-icon-small c-pointer">
        </div>
        <div class="col-xl-1 col-md-2 col-3 text-center mb-2">
          <div class="small-font">
            Sort
          </div>
          <div class="">
            <div ngbDropdown class="dropdown " #statusNgbDropdown1="ngbDropdown" [autoClose]="false">
              <span data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
                <img src="assets/icons/Sorting-Icon.svg" class="svg-icon-small">
              </span>
              <ul class="dropdown-menu text-start shadow mt-3 dropdown-width1 " ngbDropdownMenu>
                <li>
                  <div class="col-12 text-end px-2">

                    <img src="assets/icons/close_light.svg" class="svg-icon-class c-pointer"
                      (click)="statusNgbDropdown1.close()">
                  </div>
                </li>

              </ul>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="mb-3" *ngIf="isLoadingList$ | async">
    <div class="e-spinner"></div>
  </div>
  <div class="col-md-11 m-md-auto text-center mt-3">
    <div class="row" *ngIf="selectedPageName == 'CATALOGUE_L1'">
      <div class="col-6 col-md-4 col-lg-3 col-xl-2 px-0 bg-white c-pointer" *ngFor="let item of filterCatalogueL1WrapperList$ |async" (click)="navigateToCatalogueL2(item!)">

        <div class="pic border-hover">
          <img src="assets/images/demo.jpg" alt="" class="catalogue-image-size" *ngIf="!(item.catalogueL1EntityDto?.fileInfoDto)">
          <img src="/downloadLandingBlob?fileId={{item.catalogueL1EntityDto.fileInfoDto?.fileId}}" alt="" class="catalogue-image-size p-4" 
          *ngIf="item.catalogueL1EntityDto">
          
          <div class="medium-font my-3">{{item.catalogueL1EntityDto?.name}}</div>
          <!-- <div class="overlay"></div>
          <div class="info w-100" [ngClass]="isHover ? 'd-block' : 'd-none'" *ngIf="item.catalogueL1EntityDto?.fileInfoDto">
            <img src="assets/images/demo.jpg" alt="" class="catalogue-image-size">
            <div class="hover-text">
              <div>Total item -200

              </div>
              <div>
                Total vendors -200

              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row"  *ngIf="selectedPageName == 'CATALOGUE_L2'">
      <div class="col-6 col-md-4 col-lg-3 col-xl-2 px-0 bg-white c-pointer" *ngFor="let item of filterCatalogueL2List$ |async" >
        <div class="pic border-hover" (click)="navigateToCatalogueDetails(item)">
          <img src="assets/images/demo.jpg" alt="" class="catalogue-image-size" *ngIf="!(item.fileInfoDto)">
          <img src="/downloadLandingBlob?fileId={{item.fileInfoDto!.fileId}}" alt="" class="catalogue-image-size p-4" *ngIf="item.fileInfoDto"
          >
          <!-- [ngClass]="{'img-portrait': item.fileInfoDto!.dataType == 'Portrait'}" -->
          <div class="medium-font my-3">{{item.name}}</div>
          <!-- <div class="overlay"></div>
          <div class="info w-100" [ngClass]="isHover ? 'd-block' : 'd-none'" *ngIf="item.fileInfoDto">
            <img src="assets/images/demo.jpg" alt="" class="catalogue-image-size">
            <div class="hover-text">
              <div>Total item -200

              </div>
              <div>
                Total vendors -200

              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
