<!-- <div class="row mt-2">

  <div class=" col-3 div-class  mb-1">
    <img [alt]="userDto?.firstName" src="assets/images/avatar05.jpg" class="profile-image-large border mt-0"
      *ngIf="!userDto?.profileImage">
    <img [alt]="userDto?.firstName" src="/downloadLandingBlob?fileId={{userDto?.profileImage?.fileId}}"
      class="profile-image-class border" *ngIf="userDto?.profileImage">
  </div>
  <div class="col-lg-7 col-6">
    <div class="div-class ">
      <h5 class="heading-h5 mb-0">
        {{userDto?.firstName}} {{userDto?.lastName}}
      </h5>
    </div>
    <div class="div-class">
      <b>{{userDto?.designation}}</b>
    </div>

  </div>
  <div class="col-3   col-lg-2  text-end">
    <button class="btn btn-new-class btn-sm" (click)="toggleEditMode()">
      EDIT
    </button>
  </div>
</div> -->
<!-- <div class="row">
  <div class="col-3 div-class  my-2 ">
    Company Name
  </div>

<div class="div-class my-2  col-9" >
  <b>{{userDto?.companyName}}</b>
</div>
<div class="col-3 div-class  my-2  "  *ngIf="userDto?.companyUrl">
  Company Url
</div>
<div class="div-class  col-9" *ngIf="userDto?.companyUrl">
 <a [href]="userDto?.companyUrl"> {{userDto?.companyUrl}}</a>
</div>
<div class="col-3 div-class  my-2 ">
Address
</div>
<div class="div-class my-2  col-9">

<span *ngIf="userDto?.address?.addressLine1">
  {{userDto?.address?.addressLine1}}, {{userDto?.address?.addressLine2 ? userDto?.address?.addressLine2 + ', ' : ''}}
  {{userDto?.address?.city}}, {{userDto?.address?.state}}, {{userDto?.address?.country}} - {{userDto?.address?.zipCode}}
</span>
</div>
<div class="col-3 div-class  my-2 " *ngIf="userDto?.faxNo">
Fax No
</div>
<div class="div-class my-2  col-9" *ngIf="userDto?.faxNo">
{{getCountryCode(userDto?.faxNoCountryCode!)}} {{userDto?.faxNo}}
</div>
</div> -->

<div class="bg-white-new">
  <div class="row">

    <div class=" col-3 div-class  mb-1 ms-3 mt-3" >
      <img [alt]="userDto?.firstName" src="assets/images/avatar05.jpg" class="user-profile-image border mt-0"
        *ngIf="!userDto?.profileImage">
      <img [alt]="userDto?.firstName" src="/downloadLandingBlob?fileId={{userDto?.profileImage?.fileId}}"
        class="user-profile-image border" *ngIf="userDto?.profileImage"
        [ngClass]="{'img-portrait': userDto?.profileImage?.dataType == 'Portrait'}">
    </div>
    <div class="col-lg-6 col-5  me-3 text-start mt-3">
      <div class="div-class ">
        <h5 class="heading-h5 mb-0">
          {{userDto?.firstName}} {{userDto?.lastName}}
        </h5>
      </div>
      <div class="div-class">
        <b> {{userDto?.designation}}</b>
      </div>
     <div>
      <small class="small-class">
        {{userDto?.companyName}}
      </small>
     </div>
     <div>
      <small class=""  *ngIf="userDto?.companyUrl">
        <a [href]="userDto?.companyUrl" target="_blank"> {{userDto?.companyUrl}}</a>
       </small>
     </div>
    
      
  
    </div>
    <div class="col-2   col-lg-2  text-end  pe-0 mt-3">
      <button class="btn btn-new-class btn-sm" (click)="toggleEditMode()">
        EDIT
      </button>
    </div>
  </div>

</div>



<!-- <div>
  <div class=" ">
    <div class="row bg-grey1">

      <div class=" col-3 div-class  mb-1 mt-2 ms-3" >
        <img [alt]="userDto?.firstName" src="assets/images/avatar05.jpg" class="user-profile-image mt-0"
          *ngIf="!userDto?.profileImage">
        <img [alt]="userDto?.firstName" src="/downloadLandingBlob?fileId={{userDto?.profileImage?.fileId}}"
          class="profile-image-class border" *ngIf="userDto?.profileImage">
      </div>
      <div class="col-lg-6 col-5 mt-3 me-3">
        <div class="div-class ">
          <h5 class="heading-h5 mb-0">
            {{userDto?.firstName}} {{userDto?.lastName}}
          </h5>
        </div>
        <div class="">
          <small>{{userDto?.designation}}</small>
        </div>
    
      </div>
      <div class="col-2   col-lg-2  text-end mt-3 pe-0">
        <button class="btn btn-new-class btn-sm" (click)="toggleEditMode()">
          EDIT
        </button>
      </div>
    </div>

  </div>
  <div class="m-3">
    <div class="text-center">
     <div class="div-class">
      <b> {{userDto?.companyName}}</b>
     </div>
     <div class="div-class"  *ngIf="userDto?.companyUrl">
      <a [href]="userDto?.companyUrl" target="_blank"> {{userDto?.companyUrl}}</a>
     </div>
     <div class="div-class" >
       <span *ngIf="userDto?.address?.addressLine1">
         {{userDto?.address?.addressLine1}}, {{userDto?.address?.addressLine2 ? userDto?.address?.addressLine2 + ', ' : ''}}
         {{userDto?.address?.city}}, {{userDto?.address?.state}}, {{userDto?.address?.country}} - {{userDto?.address?.zipCode}}
       </span>
      </div>
      <div class="div-class"  *ngIf="userDto?.faxNo">
      <span>Fax No.</span> {{getCountryCode(userDto?.faxNoCountryCode!)}} {{userDto?.faxNo}}
      </div>
 
    </div>
  </div>

</div> -->


