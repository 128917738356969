<div class="p-2 mt-5">
  <div class="row align-items-center">
    <div class="col-md-3  text-start mb-2">
      <div class="mb-2 input-group">
        <input placeholder="Search" type="text" class="form-control border-end-0 rounded-0 border bg-white"
          id="ctrlSearchCatalogue" [formControl]="ctrlSearchCatalogue" />
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
    </div>
    <div class="col-md-5 text-start mb-2">
      <div class="row justify-content-md-start">
        <!-- <div class="col-xl-1 col-md-2 col-3 text-center mb-2 ">
          <div class="small-font">
            Filter
          </div>
          <img src="assets/icons/Filter-Icon.svg" class="svg-icon-small c-pointer">
        </div>
        <div class="col-xl-1 col-md-2 col-3 text-center mb-2">
          <div class="small-font">
            Sort
          </div>
          <div class="">
            <div ngbDropdown class="dropdown " #statusNgbDropdown1="ngbDropdown" [autoClose]="false">
              <span data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
                <img src="assets/icons/Sorting-Icon.svg" class="svg-icon-small">
              </span>
              <ul class="dropdown-menu text-start shadow mt-3 dropdown-width1 " ngbDropdownMenu>
                <li>
                  <div class="col-12 text-end px-2">

                    <img src="assets/icons/close_light.svg" class="svg-icon-class c-pointer"
                      (click)="statusNgbDropdown1.close()">
                  </div>
                </li>

              </ul>
            </div>
            <div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="col-md-4 mb-2 text-md-end">
      <button class="btn btn-sm btn-new-class mt-2 mt-sm-0" (click)="openAddCatalogueModal1(addCatalogue1)"
        *ngIf="selectedPageName == 'CATALOGUE_L1'">
        <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-small me-1">Add
      </button>
      <button class="btn btn-sm btn-new-class mt-2 mt-sm-0" (click)="openAddCatalogueModal2(addCatalogue2)"
        *ngIf="selectedPageName == 'CATALOGUE_L2'">
        <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-small me-1"> Add
      </button>
      <!-- <button class="btn btn-sm btn-primary-clr-class mt-2 mt-sm-0" (click)="openAddCatalogueModal2(addCatalogue2)">Add2</button> -->
    </div>
  </div>
  <div class="mb-3" *ngIf="isLoadingList$ | async">
    <div class="e-spinner"></div>
  </div>
  <div class="col-md-11 m-md-auto text-center mt-3">
    <div class="row" *ngIf="selectedPageName == 'CATALOGUE_L1'">
      <div class="col-6 col-md-4 col-lg-3 col-xl-2 px-0 bg-white c-pointer"
        *ngFor="let item of filterCatalogueL1List$ |async" (click)="navigateToCatalogueL2(item!)">

        <div class="pic border-hover">
          <img src="assets/images/demo.jpg" alt="" class="catalogue-image-size" *ngIf="!(item.fileInfoDto)">
          <img src="/downloadLandingBlob?fileId={{item.fileInfoDto!.fileId}}" alt="" class="catalogue-image-size p-4"
            *ngIf="item.fileInfoDto">
          <!-- [ngClass]="{'img-portrait': item.fileInfoDto!.dataType == 'Portrait'}" -->
          <span class="follow-icon-position">
            <button class="follow-btn" (click)="selectCatalogueL1(item, addCatalogue1, $event)">
              <img src="assets/icons/edit_light.svg" class="svg-icon-class-small c-pointer">
            </button>
          </span>
          <div class="medium-font my-3">{{item.name}}</div>
          <!-- <div class="overlay"></div>
          <div class="info w-100" [ngClass]="isHover ? 'd-block' : 'd-none'" *ngIf="item.fileInfoDto">
            <img src="assets/images/demo.jpg" alt="" class="catalogue-image-size">
            <div class="hover-text">
              <div>Total item -200

              </div>
              <div>
                Total vendors -200

              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="row" *ngIf="selectedPageName == 'CATALOGUE_L2'">
      <div class="col-6 col-md-4 col-lg-3 col-xl-2 px-0 bg-white c-pointer"
        *ngFor="let item of filterCatalogueL2List$ |async">

        <div class="pic border-hover" (click)="navigateToLineCatalogue(item)">
          <img src="assets/images/demo.jpg" alt="" class="catalogue-image-size" *ngIf="!(item.fileInfoDto)">
          <img src="/downloadLandingBlob?fileId={{item.fileInfoDto!.fileId}}" alt="" class="catalogue-image-size"
            *ngIf="item.fileInfoDto">
          <!-- [ngClass]="{'img-portrait': item.fileInfoDto!.dataType == 'Portrait'}" -->
          <span class="follow-icon-position">
            <button class="follow-btn" (click)="selectCatalogueL2(item, addCatalogue2, $event)">
              <img src="assets/icons/edit_light.svg" class="svg-icon-class-small c-pointer">
            </button>
          </span>
          <div class="medium-font my-3">{{item.name}}</div>
          <!-- <div class="overlay"></div>
          <div class="info w-100" [ngClass]="isHover ? 'd-block' : 'd-none'" *ngIf="item.fileInfoDto">
            <img src="assets/images/demo.jpg" alt="" class="catalogue-image-size">
            <div class="hover-text">
              <div>Total item -200

              </div>
              <div>
                Total vendors -200

              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #addCatalogue1>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h5 class="text-capitalize">Add new L1 Catalogue</h5>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation">
          <div class="col-11 m-auto">
            <div class="input-group mb-5">
              <div class="form-floating">
                <input #title class=" form-control" id="ctrlCategoryL1Name" [formControl]="ctrlCategoryL1Name">
                <label for="ctrlCategoryL1Name">Category Display Name
                </label>
              </div>
              <div class="text-start"
                *ngIf="ctrlCategoryL1Name.invalid && (ctrlCategoryL1Name.dirty || ctrlCategoryL1Name.touched)">
                <p class="text-danger" *ngIf="ctrlCategoryL1Name.errors">Required
                </p>
              </div>
            </div>
            <div class="col-md-7 m-md-auto mb-3">
              <div class="col-12 mb-3">
                <label for="file-upload" class="custom-file-upload">
                  <div>
                    <img src="assets/icons/cloud-upload.svg" class="svg-icon-large c-pointer me-2">
                  </div>
                  Drag or drop here or browse file to upload
                </label>
                <input type="file" id="file-upload" onclick="this.value=null;" (change)="chooseFile($event)" />
              </div>
              <div>
                <div class="col-12 mb-3" *ngIf="(_fileDisplayName$ | async) &&(_fileSize$ | async)">
                  <div class="row medium-grey-font">
                    <div class="col-8">
                      <img src="assets/icons/file_danger.svg" class="svg-icon-class c-pointer">
                      {{_fileDisplayName$ |async}}
                    </div>
                    <div class="col-3 text-end">
                      {{formatBytes(_fileSize$ | async)}}
                    </div>
                    <div class="col-1 text-end ps-0">
                      <img src="assets/icons/delete_dark.svg" class="svg-icon-class-small c-pointer"
                        (click)="deleteFile()">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3" *ngIf="isLoading$ | async">
                <div class="e-spinner"></div>
              </div>
              <div class="mb-3">
                <div class="text-danger" *ngIf="_showErrorToast$ | async">
                  <img src="assets/icons/close_red.svg" class="svg-icon-class-md c-pointer me-2"> {{_errorMsg$ | async}}
                </div>

                <div class="text-success" *ngIf="_showSuccessToast$ | async">
                  <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2"> Catalogue L1 is
                  saved
                </div>
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-white-clr-class me-3 mb-3" [disabled]="isLoading$ | async" (click)="clearL1()">
                  Reset
                </button>
                <button class="btn btn-sm btn-primary-clr-class mb-3" [disabled]="isLoading$ | async"
                  (click)="handleSubmitCatalogueL1()">
                  Upload
                </button>
              </div>

            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addCatalogue2>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h5 class="text-capitalize">Add new L2 Catalogue</h5>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation">
          <div class="col-11 m-auto">
            <div class="input-group mb-5">
              <div class="form-floating">
                <input #title class=" form-control" [formControl]="ctrlCategoryL2Name" id="ctrlCategoryL2Name">
                <label for="ctrlCategoryL2Name">Category Display Name
                </label>
              </div>
              <div class="text-start"
                *ngIf="ctrlCategoryL2Name.invalid && (ctrlCategoryL2Name.dirty || ctrlCategoryL2Name.touched)">
                <p class="text-danger" *ngIf="ctrlCategoryL2Name.errors">Required
                </p>
              </div>
            </div>
            <div class="col-md-7 m-md-auto mb-3">
              <div class="col-12 mb-3">
                <label for="file-upload" class="custom-file-upload">
                  <div>
                    <img src="assets/icons/cloud-upload.svg" class="svg-icon-large c-pointer me-2">
                  </div>
                  Drag or drop here or browse file to upload
                </label>
                <input type="file" id="file-upload" onclick="this.value=null;" (change)="chooseFile($event)" />
              </div>
              <div>
                <div class="col-12 mb-3" *ngIf="(_fileDisplayName$ | async) &&(_fileSize$ | async)">
                  <div class="row medium-grey-font">
                    <div class="col-8">
                      <img src="assets/icons/file_danger.svg" class="svg-icon-class c-pointer">
                      {{_fileDisplayName$ |async}}
                    </div>
                    <div class="col-3 text-end">
                      {{formatBytes(_fileSize$ | async)}}
                    </div>
                    <div class="col-1 text-end ps-0">
                      <img src="assets/icons/delete_dark.svg" class="svg-icon-class-small c-pointer"
                        (click)="deleteFile()">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3" *ngIf="isLoading$ | async">
                <div class="e-spinner"></div>
              </div>
              <div class="mb-3">
                <div class="text-danger" *ngIf="_showErrorToast$ | async">
                  <img src="assets/icons/close_red.svg" class="svg-icon-class-md c-pointer me-2"> {{_errorMsg$ | async}}
                </div>
                <div class="text-success" *ngIf="_showSuccessToast$ | async">
                  <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2"> Catalogue L2 is
                  saved
                </div>
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-white-clr-class me-3 mb-3" [disabled]="isLoading$ | async" (click)="clearL2()">
                  Reset
                </button>
                <button class="btn btn-sm btn-primary-clr-class mb-3" [disabled]="isLoading$ | async"
                  (click)="handleSubmitCatalogueL2()">
                  Upload
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>