<div class="container">
  <div class="col-md-11 mx-auto">
    <div class="card p-2 bg-white mb-3 ">
      <div class="row">
        <div class="col-8">
          <h5 class="heading-h5 mt-1">
            APPROVAL WORKFLOWS
          </h5>
        </div>
        <div class="col-4 text-end">
          <button class="btn-new-class btn btn-sm ms-2 mt-1" (click)="openPurchaseRequestModal()">
            <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-small"></button>
        </div>
      </div>
      <hr class="hr my-2" />
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-bordered mb-0 medium-font">
            <thead class="table-dark">
              <tr class="text-center">
                <th class="text-center"><span>SNo</span></th>
                <th class=""><span>Rule</span></th>
                <th class=""><span>Workflow Code</span></th>
                <th class=""><span>Company Code</span></th>
                <th class=""><span>Plant Code</span></th>
                <th class=""><span>
                    Action
                  </span>
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr *ngIf="isDataLoading" class="align-middle">
                <td colspan="9" class="text-center">
                  <div class="e-spinner"></div>
                </td>
              </tr>
              <tr>
                <td class="text-center" colspan="9" *ngIf="!isDataLoading && approvalWorkflowUiDtos.length == 0">
                  <h5 class="heading-new"> No Data Available</h5>
                </td>
              </tr>

              <tr class="align-middle text-center" *ngFor="let item of approvalWorkflowUiDtos; index as i">
                <td class=" text-center">{{i+1}}</td>
                <td class="">{{item.awfDefineUiDto?.name}}</td>
                <td class="">{{getOnboardingWorkflow(item.awfDefineUiDto?.wfCode!)}}</td>
                <td class="">{{item.awfDefineUiDto?.companyCode}}</td>
                <td class="">{{item.awfDefineUiDto?.plantCode}}</td>
                <td class="">
                  <a class="link-class" (click)="openMetaDataView(item)">View</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>