import { AdminUserPrivilege } from "../../enums/AdminUserPrivilege";
import { UserRegistrationStatus } from "../../enums/UserRegistrationStatus";
import { AddressDto } from "../Address";
import { CountryCodeDto } from "../CountryCodeDto";
import { FileInfoDto } from "../FileInfoDto";
import { EmailWrapper } from "./EmailWrapper";
import { MobileWrapper } from "./MobileWrapper";
import { PreferenceCategoryDto } from "./PreferenceCategoryDto";

export class UserEntityInfoDto {
  emailList?: EmailWrapper[];
  mobileList?: MobileWrapper[];
  companyName?: string;
  companyUrl?: string;
  designation?: string;
  primaryEmailId?: string;
  userId?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  empCode?: string;
  profileImage?: FileInfoDto;
  active: boolean = true;
  version?: number;
  registrationAuctionHouseId?: string;
  parentUserId?: string;
  mobileWrapper?: Array<MobileWrapper>;
  orgCode?: string;
  companyCode?: string;
  domainName?: string;
  appAdminCode?: number;
  plantCodes?: Array<string>;
  userPrivileges?: Array<AdminUserPrivilege>;
  notificationActive?: boolean;
  forcePasswordChange?: boolean;
}
