import { Router } from '@angular/router';
import { TranslateService } from './../../../shared/services/translate.service';
import { AuctionValidationErrorCodeDto } from './../../../shared/models/user/AuctionValidationErrorCodeDto';
import { AuctionSaveWrapperDto } from './../../../shared/models/user/AuctionSaveWrapperDto';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { AddressDto } from 'src/app/shared/models/Address';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';

@Component({
  selector: 'app-auction-manage-address',
  templateUrl: './auction-manage-address.component.html',
  styleUrls: ['./auction-manage-address.component.sass']
})
export class AuctionManageAddressComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;

  auctionEntityDto?: AuctionEntityDto;
  selectedAddress?: AddressDto;
  selectedCountryCode = CountryCodeDto.default();

  isEditAddress?: boolean = false;
  isLoading?: boolean = false;
  isDataLoading?: boolean = false;
  latitude?: number;
  longitude?: number;
  currentIndex?: number;
  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _isOrderActive$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonDisable$ = new BehaviorSubject<boolean>(false);
  countryCode$ = new BehaviorSubject<CountryCodeDto|null>(null);

  selectedAuctionSubscription$?: Subscription;
  countryShortName? : string
  addressList: AddressDto[]= [];

  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  zoom = 12;
  infoContent = ''
  position?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  marker = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }

  constructor(
    private router: Router,
    private transalteService: TranslateService,
    private fb: FormBuilder,
    private ngbModal: NgbModal,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminDashboardService: AdminDashboardService
  ) {

    this.formGroup = this.fb.group({
      addressType: ['', Validators.required],
      searchAddress: ['', Validators.required],
      addressLine1: ['', [Validators.required, this.validLocation()]],
      addressLine2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      zipCode: ['', Validators.pattern(Pattern.zipCode)],
      mobileNo: ['', [Validators.required, Validators.pattern(Pattern.mobile)]],
    })
  }

  ngOnInit(): void {
    this.selectedAuctionSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe(auction => {
      this.auctionEntityDto = auction!;
    })
  }

  get fc(): any { return this.formGroup.controls; }

  validLocation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValidLocation = this.latitude != undefined;
      return control.value && !isValidLocation ? { address: { value: control.value } } : null;
    }
  }



  populateAddressData() {
    this.latitude = Number(this.selectedAddress?.latitude);
    this.longitude = Number(this.selectedAddress?.longitude);
    this.countryShortName = this.selectedAddress?.countryShortName;
    this.selectedCountryCode = this.selectedAddress?.countryCode ?? CountryCodeDto.default();
    this.countryCode$.next(this.selectedCountryCode);

    if (this.latitude && this.longitude) {
      this.position = { lat: this.latitude!, lng: this.longitude! }
      this.marker.label.text = this.selectedAddress?.searchAddress!;
      this.marker.title = this.selectedAddress?.searchAddress!;
      this.marker.info = this.selectedAddress?.searchAddress!;
    }

    this.formGroup.patchValue(this.selectedAddress!);
    this.formGroup.updateValueAndValidity();
  }

   openGoogleMap(type: string, address: AddressDto) {


    this.router.navigate(['/map-view'], {
      queryParams: {
        lat: address!.latitude,
        lng: address!.longitude,
        viewType: type,
        title: address!.city! + ", " + address!.countryShortName!
      }, skipLocationChange: true
    });
  }

  openModal(content: any, address?: AddressDto) {
    this.latitude = undefined;
    this.longitude = undefined;
    this.formGroup.reset();

    this._isOrderActive$.next(false);

    if (address) {
      this.selectedAddress = address;
      this.isEditAddress = true;
      this.populateAddressData();
    }

    this.ngbModal.open(content, { size: 'lg', backdrop: 'static', keyboard: false , centered: true })
  }

  openSortingModal(content: any) {
    if(this.auctionEntityDto && this.auctionEntityDto.addresses &&this.auctionEntityDto.addresses?.length! > 0 ){
      this.addressList = ApplicationUtils.clone(this.auctionEntityDto).addresses;
    }
    this._isOrderActive$.next(true);
    this.ngbModal.open(content, { size: 'md', backdrop: 'static', keyboard: false  , centered: true})
  }

  closeModal() {
    this.formGroup.reset();
    this.selectedCountryCode = CountryCodeDto.default();
    this.countryCode$.next(this.selectedCountryCode);
    this.ngbModal.dismissAll();
  }

  formValidationFailed() {
    this._validationErrorPresent$.next(false);
    if (this.formGroup.invalid) {
      this._validationErrorPresent$.next(true);
      return true;
    }
    return false;
  }

  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker)
  }

  handleAddressChange(address: Address) {
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();

    let city = ApplicationUtils.getAddressByType(address , 'locality');
    if(!city){
      city = ApplicationUtils.getAddressByType(address , 'neighborhood');
    }
    let state = ApplicationUtils.getAddressByType(address, 'administrative_area_level_1');
    let country = ApplicationUtils.getAddressByType(address, 'country');
    this.countryShortName  = ApplicationUtils.getAddressShortNameByType(address, 'country');

    let zip = ApplicationUtils.getAddressByType(address, 'postal_code');
    let addressPart1 =  ApplicationUtils.getAddressByType(address, 'street_number');
    let addressPart2 =  ApplicationUtils.getAddressByType(address, 'route');

    // Map View Init
    this.position = { lat: this.latitude, lng: this.longitude }
    this.marker.label.text = address.formatted_address;
    this.marker.title = address.formatted_address;
    this.marker.info = address.formatted_address;


    if (addressPart1 == '' || addressPart2 == '') {
      if(address.formatted_address.includes(',')){
        let data = address.formatted_address.split(',')[0]
        this.formGroup.controls['addressLine1'].patchValue(data);
      }else{
        this.formGroup.controls['addressLine1'].patchValue(address.formatted_address)
      }
    } else {
      this.formGroup.controls['addressLine1'].patchValue( addressPart1 + " " + addressPart2);
    }

    this.formGroup.controls['searchAddress'].patchValue(address.formatted_address);
    this.formGroup.controls['city'].patchValue(city);
    this.formGroup.controls['state'].patchValue(state);
    this.formGroup.controls['country'].patchValue(country);
    this.formGroup.controls['zipCode'].patchValue(zip);
    this.formGroup.updateValueAndValidity();
  }

  mergeAuctionHouseAddress(formValue: any) {
    let auctionData = ApplicationUtils.clone(this.auctionEntityDto) as AuctionEntityDto;

    if (this.selectedAddress == null) {
      this.selectedAddress = new AddressDto()
    }
    this.selectedAddress!.addressType = formValue.addressType;
    this.selectedAddress!.searchAddress = formValue.searchAddress;
    this.selectedAddress!.addressLine1 = formValue.addressLine1;
    this.selectedAddress!.addressLine2 = formValue.addressLine2;
    this.selectedAddress!.city = formValue.city;
    this.selectedAddress!.state = formValue.state;
    this.selectedAddress!.country = formValue.country;
    this.selectedAddress!.zipCode = formValue.zipCode;
    this.selectedAddress!.mobileNo = formValue.mobileNo;
    this.selectedAddress!.latitude = this.latitude?.toString();
    this.selectedAddress!.longitude = this.longitude?.toString();
    this.selectedAddress!.countryShortName = this.countryShortName;
    this.selectedAddress!.countryCode = this.selectedCountryCode;

    if (this.isEditAddress) {
      let index = auctionData?.addresses?.findIndex(item => item.id == this.selectedAddress?.id);
      if (index != null && index != -1) {
        auctionData?.addresses?.splice(index, 1);
        auctionData?.addresses?.push(this.selectedAddress);
      }
    } else {
      if (auctionData?.addresses == null) {
        auctionData!.addresses = [];
      }

      this.selectedAddress!.id = UUID.UUID().toString();
      auctionData?.addresses?.push(this.selectedAddress);
    }
    return auctionData;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.addressList!, event.previousIndex, event.currentIndex);
  }

  deleteAddress(index: number) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.currentIndex = index;

    let auctionEntityDto = ApplicationUtils.clone(this.auctionEntityDto);
    auctionEntityDto?.addresses?.splice(index, 1);

    this.isDataLoading = true;

    this.saveAddress(auctionEntityDto, true)
  }

  handleSaveAddress() {
    if (!this.isAllowToEdit()) {
      return;
    }

    let auctionData = null;
    if (this._isOrderActive$.value == false) {

      if (this.formValidationFailed()) {
        return;
      }

      this.isLoading = true;

      let formValue = this.formGroup.value;
       auctionData = this.mergeAuctionHouseAddress(formValue);

    } else {
       auctionData = ApplicationUtils.clone(this.auctionEntityDto);
       auctionData.addresses = [];
       auctionData.addresses = this.addressList;


      this.isDataLoading = true;
    }

    this.saveAddress(auctionData!, false);
  }

  getErrorMsg(error: AuctionValidationErrorCodeDto) {
    return ApplicationUtils.populateMessage(error, this.transalteService)
  }

  saveAddress(auctionEntityDto: AuctionEntityDto, isDelete: boolean) {
    this._isSaveButtonDisable$.next(true);
    this._showErrorToast$.next(false);

    this.adminDashboardService.saveAuctionDetails(auctionEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let auctionSaveWrapperDto = apiResponseDto.data  as AuctionSaveWrapperDto;
          let auctionValidationErrorCodeDtoList =  auctionSaveWrapperDto.auctionValidationErrorCodeDtos;
          if(!auctionValidationErrorCodeDtoList ||  auctionValidationErrorCodeDtoList.length == 0){
            if (!isDelete) {
              this._showSuccessToast$.next(true);
            }
            this.isLoading = false;
            this.isDataLoading = false;
            setTimeout(() => {
              this._isSaveButtonDisable$.next(false);
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)
          }else{
            this.isLoading = false;
            this.isDataLoading = false;
            this._isSaveButtonDisable$.next(false);
            this.closeModal();
            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
          }
        } else {
          this._isSaveButtonDisable$.next(false);
          if (!isDelete) {
            this._showErrorToast$.next(true);
          }
          this.isDataLoading = false;
          this.isLoading = false;
          this.hideLoader();
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
        this.isDataLoading = false;
        this.hideLoader();
      }
    })
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

  openErrorModel() {
    let errorModalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
      });
    errorModalRef.componentInstance.auctionValidationErrorCodeDtoList = this.auctionValidationErrorCodeDtoList;
  }

  hideLoader() {
    this._isSaveButtonDisable$.next(false);
    this._isOrderActive$.next(false);
    this.isLoading = false;
    this.isDataLoading = false;
    this.currentIndex = undefined;
  }

  handleCountryCodeEvent(countryCodeDto: CountryCodeDto) {
    this.selectedCountryCode = countryCodeDto;

    let mobileNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + mobileNoLength+ "}"

    this.formGroup.controls['mobileNo'].clearValidators();
    this.formGroup.controls['mobileNo'].setValidators([Validators.required,  Validators.pattern(pattern)]);
    this.formGroup.controls['mobileNo'].updateValueAndValidity();
  }

  ngOnDestroy(): void {
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT && !this.auctionEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE && !this.auctionEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }

}
