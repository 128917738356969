import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CatalogueL1EntityDto } from 'src/app/shared/models/CatalogueL1EntityDto';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { FileService } from 'src/app/shared/services/file.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { CatalogueL1WrapperDto } from 'src/app/shared/models/CatalogueL1WrapperDto';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { CatalogueL2EntityDto } from 'src/app/shared/models/CatalogueL2EntityDto';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';


@Component({
  selector: 'app-admin-catalogue',
  templateUrl: './admin-catalogue.component.html',
  styleUrls: ['./admin-catalogue.component.sass']
})
export class AdminCatalogueComponent implements OnInit {
  isHover: boolean = false;


  currentFileInfoDto?: FileInfoDto;
  currentFile?: File | null;

  fileUploadError: boolean = false;
  imageUrl?: string | ArrayBuffer | SafeUrl | null;
  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  _fileDisplayName$ = new BehaviorSubject<string | null>('');
  _fileUploaded$ = new BehaviorSubject<boolean>(false);
  isLoading$ = new BehaviorSubject<boolean>(false);
  _fileSize$ = new BehaviorSubject<string | null>("");

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');

  isSubmitted: boolean = false;
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);

  ctrlCategoryL1Name: FormControl = new FormControl('');

  catalogueL1WrapperList?: CatalogueL1WrapperDto[] = [];
  filterCatalogueL1WrapperList$ = new BehaviorSubject<CatalogueL1WrapperDto[]>([]);
  selectedCatalogueL1Wrapper?: CatalogueL1WrapperDto;
  selectedCatalogueL1?: CatalogueL1EntityDto
  selectedCatalogueL2?: CatalogueL2EntityDto

  catalogueL2List?: CatalogueL2EntityDto[] = [];
  filterCatalogueL2List$ = new BehaviorSubject<CatalogueL2EntityDto[]>([]);

  ctrlSearchCatalogueL1: FormControl = new FormControl('');
  isLoadingList$ = new BehaviorSubject<boolean>(false);

  updateBreadcrumbsSubscription$?: Subscription;

  selectedPageName?: string = "CATALOGUE_L1";

  constructor(
    private dataRedirectionService: DashboardRedirectionService,
    private adminDashboardService: AdminDashboardService,
    private catalogueService: CatalogueService,
    private formBuilder: FormBuilder,
    private fileService: FileService,
    private ngbModal: NgbModal,) {

  }


  ngOnInit(): void {
    this.loadCatalogueL1List();

    this.ctrlSearchCatalogueL1.valueChanges.subscribe((value) => {
      if (this.selectedPageName == 'CATALOGUE_L1') {
        this.searchCatalogueL1(value)
      } else {
        this.searchCatalogueL2(value)
      }

    });


    this.updateBreadcrumbsSubscription$ = this.dataRedirectionService.getUpdateBreadcrumbs$.subscribe(data => {
      if (data) {
        let pageName = sessionStorage.getItem('PAGE_NAME')!;

        if (pageName == ApplicationConstants.ADMIN_CATALOGUE_PAGE) {          
          this.selectedCatalogueL1 = this.catalogueService.getSelectedCatalogueL1$.value;
          this.selectedPageName = this.selectedCatalogueL1 ? 'CATALOGUE_L2' : 'CATALOGUE_L1';
        }
      }
    })
  }



  openAddCatalogueModal2(content: any, event: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.ngbModal.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }
  openAddCatalogueModal1(content: any) {
    this.clear()
    this.ngbModal.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }



  // LOAD DATA
  loadCatalogueL1List() {
    this.isLoadingList$.next(true);
    this.adminDashboardService._getCatalogueL1WrapperList().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let catalogueL1List = apiResponseDto.data as CatalogueL1WrapperDto[];
          this.catalogueL1WrapperList = catalogueL1List;
          this.searchCatalogueL1("");
          this.isLoadingList$.next(false);
        }
      },
      error: (error: any) => {
        console.error(error);
        this.isLoadingList$.next(false);
      }
    })
  }

  searchCatalogueL1(value: string) {
    let tempList = ApplicationUtils.clone(this.catalogueL1WrapperList) as CatalogueL1WrapperDto[];
    if (value == "") {
      this.filterCatalogueL1WrapperList$.next(tempList)
    } else {
      tempList = tempList.filter(item => item.catalogueL1EntityDto?.name!.trim().toLowerCase().includes(value.trim().toLowerCase()));
      this.filterCatalogueL1WrapperList$?.next(tempList);
    }

  }

  searchCatalogueL2(value: string) {
    let tempList = ApplicationUtils.clone(this.catalogueL2List) as CatalogueL2EntityDto[];
    if (value == "") {
      this.filterCatalogueL2List$.next(tempList)
    } else {
      tempList = tempList.filter(item => item.name?.trim().toLowerCase().includes(value.trim().toLowerCase()));
      this.filterCatalogueL2List$?.next(tempList);
    }
  }

  async navigateToCatalogueL2(categoryL1Wrapper: CatalogueL1WrapperDto) {
    this.selectedCatalogueL1Wrapper = categoryL1Wrapper;
    this.catalogueService.updateSelectedCatalogueL1EntityDto(categoryL1Wrapper.catalogueL1EntityDto!);

    this.selectedPageName = "CATALOGUE_L2";
    await this.loadCatalogueL2List(categoryL1Wrapper.catalogueL1EntityDto?.categoryL1Id!);

    this.dataRedirectionService.storeNavigationContext(
      ApplicationConstants.ADMIN,
      ApplicationConstants.ADMIN_CATALOGUE_PAGE
    );
    this.dataRedirectionService.setUpdateBreadcrumbs(true);
  }

  async loadCatalogueL2List(categoryL1Id: string) {
    this.isLoadingList$.next(true);
    await this.adminDashboardService._getCatalogueL2List(categoryL1Id!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let catalogueL2List = apiResponseDto.data as CatalogueL2EntityDto[];
          this.catalogueL2List = catalogueL2List;
          this.searchCatalogueL2("");
          this.isLoadingList$.next(false);
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoadingList$.next(false);
      }
    })
  }

  navigateToCatalogueDetails(catalogueL2: CatalogueL2EntityDto) {
    this.selectedCatalogueL2 = catalogueL2;
    this.catalogueService.updateSelectedCatalogueL2EntityDto(catalogueL2);

    this.dataRedirectionService.storeNavigationContext(
      ApplicationConstants.ADMIN,
      ApplicationConstants.ADMIN_LINE_CATALOGUE_PAGE
    );
    this.dataRedirectionService.setUpdateBreadcrumbs(true);
  }

  clear() {
    this.selectedCatalogueL1Wrapper = undefined;
    this.ctrlCategoryL1Name.reset();
    this.deleteFile();
    this.isLoading$.next(false);
  }

  deleteFile() {
    this._fileDisplayName$.next('');
    this._fileSize$.next('');
    this.currentFile = null;

  }


  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }





  closeModal() {
    this.ngbModal.dismissAll();
  }
}

