<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>LOT DETAILS</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit(false)">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Title" formControlName="title" id="title">
          <label for="title">Title</label>
          <div *ngIf="fc.title.invalid && (fc.title.dirty || fc.title.touched)">
            <p class="text-danger" *ngIf="fc.title.errors?.required">Required</p>
          </div>
        </div>
        <div class="mb-3">
          <div class="row mb-2">
            <div class="col-md-7">
              <label for="description" class="text-area-label">Description
              </label>
            </div>


          </div>
          <div class="form-group position-relative">

            <angular-editor #descriptionEditor id="descriptionEditor" formControlName="description" class="form-control" [config]="config"></angular-editor>
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-3">
            <div class="input-group">
              <span class="border  border-end-0 pt-3 px-1" [ngClass]="{'border-danger': fc.endDate.value &&  haveAnyErrorInEndDate()}">
                <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
              </span>
            <div class="form-floating ">

              <input type="text" ngbDatepicker [minDate]="minDate" #endDate="ngbDatepicker" id="endDate" (click)="endDate.toggle()"
                [ngClass]="{'border-danger': fc.endDate.value && haveAnyErrorInEndDate()}"
                class="form-control border-start-0"  placeholder="End Date" formControlName="endDate">
              <label for="endDate" class="">End Date</label>
            </div>
          </div>
            <div *ngIf="fc.endDate.invalid && (fc.endDate.dirty || fc.endDate.touched)">
              <p class="text-danger" *ngIf="fc.endDate.errors?.required">Required</p>
            </div>
          </div>
          <div class="col-6 mb-3">
            <div class=" input-group">
              <span class="border  border-end-0 pt-3 px-1" [ngClass]="{'border-danger': fc.endTime.value &&  haveAnyErrorInEndDate()}">
                <img src="assets/icons/time_light.svg" class="svg-icon-class c-pointer">
              </span>
              <div class="form-floating">

                <input type="text" ngbPopover [autoClose]="'outside'" triggers="manual" #p1="ngbPopover"
                [ngClass]="{'border-danger': fc.endTime.value && haveAnyErrorInEndDate()}" id="endTime"
                  (click)="openTimepicker(p1, endTimeModal)" class="form-control border-start-0" placeholder="End Time"
                  formControlName="endTime">
                <label for="endTime" class="">End Time</label>
              </div>
            </div>

            <div *ngIf="fc.endTime.invalid && (fc.endTime.dirty || fc.endTime.touched)">
              <p class="text-danger" *ngIf="fc.endTime.errors?.required">Required</p>
            </div>
          </div>
        </div>
        <div class="form-floating mb-3">
          <select name="Select Timezone" class="form-control form-select" formControlName="address" id="address">
            <option value="" disabled>Select . . .</option>
            <option [value]="item?.id" *ngFor="let item of addresses">{{item.addressType}}</option>
          </select>
          <label for="address">Address</label>
        </div>
        <div class="my-3" >
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" [message]="'Lot saved successfully.'"></app-message-toast>
        </div>
        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class" (click)="handleValidSubmit(true)" [disabled]="!isAllowToEdit()">SAVE</button>
        </div>
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" ></div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- NgbTimePicker -->
<ng-template #endTimeModal>
  <ngb-timepicker style="z-index: 99;" [(ngModel)]="endTime" [meridian]="true"></ngb-timepicker>
  <button class="btn btn-sm btn-new-class" (click)="setTime('endTime')">SAVE</button>
  <!-- <a class="e-buttontext ee-size-s mt-2 w-100 ee-vis-l ea-insert" >Save</a> -->
</ng-template>
