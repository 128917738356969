import { Component } from '@angular/core';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';

@Component({
  selector: 'app-onboarding-workflow',
  templateUrl: './onboarding-workflow.component.html',
  styleUrls: ['./onboarding-workflow.component.sass']
})
export class OnboardingWorkflowComponent {
  selectedSidebarView: string = "ONBOARDING_ORG_PROFILE";

  constructor(
    private dataRedirectionService: DashboardRedirectionService,
  
  ) { }

  toggleSidebarView(tabView: string) {
    this.selectedSidebarView = tabView;

    // if (this.selectedSidebarView == ApplicationConstants.ONBOARDING_ORG_PROFILE) {
    //   this.dataRedirectionService.storeNavigationContext(
    //     ApplicationConstants.ADMIN,
    //     ApplicationConstants.ONBOARDING_ORG_PROFILE
    //   );
    // } else if (this.selectedSidebarView == ApplicationConstants.ONBOARDING_COMPANIES) {
    //   this.dataRedirectionService.storeNavigationContext(
    //     ApplicationConstants.ADMIN,
    //     ApplicationConstants.ONBOARDING_COMPANIES
    //   );
    // } else if (this.selectedSidebarView == ApplicationConstants.ONBOARDING_PLANT) {
    //   this.dataRedirectionService.storeNavigationContext(
    //     ApplicationConstants.ADMIN,
    //     ApplicationConstants.ONBOARDING_PLANT
    //   );
    // }else if (this.selectedSidebarView == ApplicationConstants.ONBOARDING_USERS) {
    //   this.dataRedirectionService.storeNavigationContext(
    //     ApplicationConstants.ADMIN,
    //     ApplicationConstants.ONBOARDING_USERS
    //   );
    // }
    // else if (this.selectedSidebarView == ApplicationConstants.SETTING_PAGE) {
    //   this.dataRedirectionService.storeNavigationContext(
    //     ApplicationConstants.ADMIN,
    //     ApplicationConstants.SETTING_PAGE
    //   );
    // }
    // else if (this.selectedSidebarView == ApplicationConstants.RULE_MATA_DATA) {
    //   this.dataRedirectionService.storeNavigationContext(
    //     ApplicationConstants.ADMIN,
    //     ApplicationConstants.RULE_MATA_DATA
    //   );
    // }
  }
}
