import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { UserEntityInfoDto } from './../models/user/UserEntityInfoDto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap } from 'rxjs';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { ErrorService } from './error.service';
import { SuperAdminDataUIWrapperDto } from '../models/user/SuperAdminDataUIWrapperDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { AddUserDto } from '../models/user/AddAdminDto';
import { EmailTemplateDto } from '../models/user/EmailTemplateDto';
import { OrganizationUiDto } from '../models/OrganizationUiDto';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminDashboardService {

  _selectedOrganization$ = new BehaviorSubject<OrganizationUiDto | null>(null);
  _superAdminDashboardData$ = new BehaviorSubject<SuperAdminDataUIWrapperDto | null>(null);
  _emailTemplatesList$ = new BehaviorSubject<EmailTemplateDto[]>([]);

  get getSuperAdminDashboardData$() { return this._superAdminDashboardData$; };
  get getEmailTemplatesList$() { return this._emailTemplatesList$; };
  get getSelectedOrganization$() { return this._selectedOrganization$; };

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
  ) { }

  // LOAD DATA
  loadSuperAdminDashboardData() {
    this._getSuperAdminDashboardData().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let _superAdminDashboardData = apiResponseDto.data as SuperAdminDataUIWrapperDto;
          this._superAdminDashboardData$.next(_superAdminDashboardData);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  updateOrganizationList(organization: OrganizationUiDto, isEdit: boolean) {
    let superAdminDashboardData = this._superAdminDashboardData$.value;

    if (isEdit) {
      let index = superAdminDashboardData?.organizationList?.findIndex(item => item.orgCode == organization.orgCode);
      if (index! > -1) {
        superAdminDashboardData?.organizationList?.splice(index!, 1);
        superAdminDashboardData?.organizationList?.push(organization);
      }
    } else {
      superAdminDashboardData?.organizationList?.push(organization);
    }

    this._superAdminDashboardData$.next(superAdminDashboardData);
  }

  setSelectedOrganization(organization: OrganizationUiDto) {
    this._selectedOrganization$.next(organization);
  }

  updateEmailTemplates(emailTemplates: Array<EmailTemplateDto>) {
    this._emailTemplatesList$.next(emailTemplates);
  }

  /// API CALLS
  saveOrganizationUiDto(organizationUiDto: OrganizationUiDto): Observable<ServerAPIResponseDto> {
    return this.httpClient.post<ServerAPIResponseDto>(`organizations`, organizationUiDto).pipe(
      tap(_ => console.log("Save organizations")),
      catchError(this.errorService.handleError<any>("Error while saving organizations")));
  }

  _getSuperAdminDashboardData() {
    return this.httpClient.get<ServerAPIResponseDto>('superAdminDashboardData').pipe(
      tap(_ => console.log("Getting Super Admin Dashboard Data")),
      catchError(this.errorService.handleError<any>("Error while getting superAdminDashboardData")))
  }

  addSuperAdmin(addAdminDto: AddUserDto): Observable<any> {
    return this.httpClient.post<ServerAPIResponseDto>('addUser', addAdminDto).pipe(
      tap(_ => console.log("Super Admin Add Successfully : " + addAdminDto.emailId)),
      catchError(this.errorService.handleError("Error while adding super admin : " + addAdminDto.emailId)))
  }

  markResourceActiveOrInActive(resourceId: string, active: boolean, actionName: string) {
    let params = new HttpParams().set('resourceId', resourceId).set('active', active).set('actionName', actionName);
    return this.httpClient.post<ServerAPIResponseDto>('markResourceActiveOrInActive', null, { params }).pipe(
      tap(_ => console.log("User active status change" + resourceId)),
      catchError(this.errorService.handleError<any>("Error while changing user active status" + resourceId)))
  }

}
