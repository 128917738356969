<div class="model-body ">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>
          Technical Questionnaire
        </h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3 height-of-modal">
      <div class="row">
        <div class="col-md-4 border-end mb-3">
          <!-- <div *ngIf="isSelectOption">
            <div class="medium-font">
              Select Section
            </div>
            <div class="text-center py-1" [ngClass]="isTechnicalActive? 'active': ''">
              A. Section Title
            </div>
          </div> -->
          <div>
            <div class="col-12 text-end ">
              <a class="link-class text-end" (click)="addNewSection()">Add Section</a>
            </div>
            <hr class="hr my-2">
            <div class="border p-2" [ngClass]="{'border-danger' : sectionScoreValidationFailed}">
              <div class=" row">
                <div class="medium-font col-6 ">
                  Name
                </div>
                <div class="text-center col-4"
                  *ngIf="this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate">
                  Score
                </div>
                <div class="col-2 text-center medium-font">
                </div>
              </div>
              <div cdkDropList (cdkDropListDropped)="drop($event)">
                <div class="col-12 border mb-2 c-pointer" *ngFor="let item of sectionTemplateList"
                  [ngClass]="item.sectionId == selectedSectionTemplate?.sectionId ? 'active-border': ''"
                  (click)="changeSection(item)" cdkDrag cdkDragLockAxis="y" [cdkDragDisabled]="!item.sectionName">
                  <div class="row p-2 align-items-center">
                    <div class="medium-font" [ngClass]="this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate ? 'col-6' : 'col-10'">
                      {{item.preSequenceText}}. {{item.sectionName}}
                    </div>
                    <div class="text-center col-4 border-end"
                      *ngIf="this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate">
                      {{item.sectionScore}}
                    </div>
                    <div class="col-2 text-center" cdkDragHandle>
                      <img src="assets/icons/reorder_light.svg" class="svg-icon-class-small c-pointer">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="mt-2 text-end">
            <button class="btn btn-sm btn-primary-clr-class" (click)="addNewSection()">
              Add Section
            </button>
          </div> -->
          <!-- <div class="mt-5 text-center">
            <button class="btn btn-sm btn-grey-clr-class ">
              <span class="small-font text-white">
                Save as template
              </span>
            </button>
          </div> -->
        </div>
        <div class="col-md-8 mb-1">
          <div class="row mb-2">
            <div class="col-md-6 mb-2">
              <div class="row">
                <div class="col-11 pe-0">
                  <div class="input-group">
                    <div class="form-floating">
                      <input #title class=" form-control" [formControl]="ctrlSectionTitle" id="ctrlSectionTitle"
                        [ngClass]="{'border-danger': ctrlSectionTitle.invalid || (ctrlSectionTitle.invalid && title.focus)}">
                      <label for="ctrlSectionTitle">Title</label>
                    </div>
                    <span class="input-group-text bg-white border-start-0 " [ngClass]="{'border-danger': ctrlSectionTitle.invalid}">
                      <a class="link-class-small c-pointer" (click)="saveSectionTemplate()"
                      [ngClass]="{'disabled': selectedAuction?.status == 'LIVE' || selectedAuction?.status == 'LIVE_WAIT' || ctrlSectionTitle.invalid}">Save</a>
                    </span>
                  </div>
                  <div *ngIf="ctrlSectionTitle.invalid && (ctrlSectionTitle.dirty || ctrlSectionTitle.touched)">
                    <p class="text-danger" *ngIf="ctrlSectionTitle.errors?.['required']">Required</p>
                  </div>
                </div>
                <div class="col-1 mt-2">

                </div>
              </div>
            </div>
            <div class=" col-md-6 mb-2">
              <div class="row">
                <div class="col-9 col-md-10" *ngIf="this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate">
                  <div class="input-group">
                    <div class="form-floating">
                      <input #sectionScore class="form-control" [formControl]="ctrlSectionScore" id="ctrlSectionScore"
                        [ngClass]="{'border-danger': ctrlSectionScore.invalid}">
                      <label for="ctrlSectionScore">Score</label>
                    </div>
                    <!-- <span class="input-group-text bg-white border-start-0" [ngClass]="{'border-danger': ctrlSectionScore.invalid}">
                      <a class="link-class-small" (click)="saveSectionTemplate()">Save</a>
                    </span> -->
                  </div>
                  <div *ngIf="ctrlSectionScore.invalid && (ctrlSectionScore.dirty || ctrlSectionScore.touched)">
                    <p class="text-danger" *ngIf="ctrlSectionScore.errors?.['required']">Required</p>
                    <p class="text-danger" *ngIf="ctrlSectionScore.errors?.['pattern']">Invalid</p>
                  </div>
                </div>
                <div class="col-2 col-md-1 text-end ps-0 mt-2" *ngIf="selectedSectionTemplate?.sectionName">
                  <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer" (click)="openDeleteConfirmModal(deleteConfirmModal)">
                </div>
              </div>
            </div>
          </div>


          <!-- <div class="col-md-6 text-md-end mb-2">
                  <span class="medium-font me-2">All Question View</span>
                  <span class="form-switch c-pointer">
                    <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" />
                  </span>
                </div>
              </div>
            </div>
          </div>-->
          <div>





          <div class="hr-class my-2"></div>
          <app-technical-question></app-technical-question>
          <div class="my-1">
            <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
            <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" [message]="'Section saved successfully.'"></app-message-toast>
          </div>
          <div class="d-grid gap-2 my-1" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>
          <div class="col-12 mt-2 text-end">
            <button class="btn btn-sm btn-new-class"
              [ngClass]="{'disabled': selectedAuction?.status == 'LIVE' || selectedAuction?.status == 'LIVE_WAIT'}"
              (click)="saveSectionTemplate()" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
              Save
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #deleteConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>DELETE SECTION</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoadingForModal}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal(deleteConfirmModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Are you sure you want to delete this section?</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToastForModal$ |async" [isError]="true" [message]="errorMsgForModal"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToastForModal$ |async" [isError]="false" message="Section deleted successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoadingForModal">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoadingForModal && !(_showSuccessToastForModal$ | async)">
        <button type="button" class="btn btn-new-class btn-sm me-3" (click)="closeModal(deleteConfirmModalRef)">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="deleteSectionTemplate()">OK</button>
      </div>
    </div>
  </div>
</ng-template>

<app-loader #appLoader></app-loader>