import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { RoutingService } from './routing.service';
import { UserService } from 'src/app/shared/services/user.service';
import { LandingService } from 'src/app/shared/services/LandingAndBidder.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AdminUserPrivilege } from '../enums/AdminUserPrivilege';
import { ServerDataFetchService } from './server-data-fetch.service';
import { FirestoreAggregationService } from './firestore-aggregation.service';
import { ApplicationUtils } from '../util/ApplicationUtils';
import { DatePipe } from '@angular/common';
import { SessionInfoDto } from '../models/SessionInfoDto';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { AuthenticationResponseDto } from '../models/AuthenticationResponseDto';
import { LoaderComponent } from '../components/loader/loader.component';



@Injectable({
  providedIn: 'root'
})
export class DashboardRedirectionService {

  // isRedirection?: boolean = false;
  // _isRedirection$ = new BehaviorSubject<boolean>(false);

  _subNavigation$ = new BehaviorSubject<string>("");

  selectedAuctionHouseId?: string | null;
  selectedAuctionId?: string | null;
  selectedLotId?: string | null;

  refreshCalled: boolean = false;

  private _updateBreadcrumbs$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private userService: UserService,
    private datePipe: DatePipe

  ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const currentRoute = event.url;
        const hashString = ApplicationUtils.getSubDomainName() + ':' + this.datePipe.transform(new Date(), 'ddMMyyyy')
        let encryptHash = ApplicationUtils.encryptSHA256String(hashString);
        console.log(currentRoute + ":" + encryptHash);
        if (currentRoute === `/SuperAdmin/debugConsole?p=${encryptHash}`) {
          sessionStorage.setItem("NAVIGATION_NAME", ApplicationConstants.DEBUG_CONSOLE);
        } else if (currentRoute === '/SuperAdmin/logConsole') {
          sessionStorage.setItem("NAVIGATION_NAME", ApplicationConstants.LOG_CONSOLE);
        } else if (currentRoute === `/SuperAdmin/configConsole?p=${encryptHash}`) {
          sessionStorage.setItem("NAVIGATION_NAME", ApplicationConstants.CONFIG_CONSOLE);
        }
      }
    });

  }

  get getSubNavigation() { return this._subNavigation$.asObservable() }
  get getUpdateBreadcrumbs$() { return this._updateBreadcrumbs$.asObservable() }

  setUpdateBreadcrumbs(value: boolean) {
    this._updateBreadcrumbs$.next(value);
  }

  async getUserSessionInfo(appLoader: LoaderComponent): Promise<SessionInfoDto | undefined> {
    let sessionId = localStorage.getItem("AUC_SESSION_ID");
    let user = this.userService.getUserEntity();

    if (sessionId && !user) {
      try {
        let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.userService.checkIfValidSession(sessionId));
        appLoader?.closeLoaderIcon(ApplicationConstants.LOADER_NOTIFICATION);
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as AuthenticationResponseDto;
          let sessionInfo = data.sessionInfoDto as SessionInfoDto;
          return sessionInfo;
        } else {
          return undefined;
        }
      } catch (error) {
        appLoader?.closeLoaderIcon(ApplicationConstants.LOADER_NOTIFICATION);
        return undefined;
      }
    } else {
      appLoader?.closeLoaderIcon(ApplicationConstants.LOADER_NOTIFICATION);
      return undefined;
    }
  }

  async storeNavigationContext(contextName: string, pageName: string, tabName?: string, resourceNo?: string, subResourceNo?: string) {
     //clear old session cookies
     this.clearSessionCookies()

     sessionStorage.setItem("CONTEXT_NAME", contextName);
     sessionStorage.setItem("PAGE_NAME", pageName);

     if(tabName){
       sessionStorage.setItem("TAB_NAME", tabName);
     }
     if(resourceNo){
       sessionStorage.setItem("RESOURCE_NO", resourceNo);
     }
     if (subResourceNo) {
       sessionStorage.setItem("SUB_RESOURCE_NO", subResourceNo);
     }
   }


  public navigationToHomePage() {
    this.clearSessionCookies();

    if (this.userService.getUserEntity() && this.userService.getUserEntity().userId) {
      let adminUserPrivilege = this.userService.getUserEntity().userPrivileges;
      if (ApplicationUtils.checkIfRoleIsAssigned(adminUserPrivilege! , AdminUserPrivilege.SUPER_ADMIN)) {
        this.router.navigate(['SuperAdmin/Dashboard'], { skipLocationChange: true })
      } else {
        this.router.navigate(['Admin/Dashboard'], { skipLocationChange: true })
      }
    } else {
      this.router.navigate(['/'], { skipLocationChange: true });
    }
  }

  clearSessionCookies(){
    sessionStorage.removeItem("CONTEXT_NAME");
    sessionStorage.removeItem("PAGE_NAME");
    sessionStorage.removeItem("TAB_NAME");
    sessionStorage.removeItem("RESOURCE_NO");
    sessionStorage.removeItem("SUB_RESOURCE_NO");
    sessionStorage.removeItem("AUC_SESSION_ID");
  }


  clearLotData() {
    sessionStorage.removeItem("LOT_ID");
    sessionStorage.removeItem("LOT_SEQ_NO");
  }
}
