<div class="container">
  <div *ngIf="isDataLoading">
    <div class="d-grid gap-2 mb-3">
      <div class="e-spinner"></div>
    </div>
  </div>
  <div class="my-3 row justify-content-end align-items-center">
    <div class="col-md-4 col-6 text-md-end">
      <button class="btn btn-sm btn-primary-clr-class me-2" *ngIf="!isDataLoading"
        [disabled]="!comparisonSheetWrapperDto?.compFinancialWrapperDtos"
        (click)="openDownloadExcelModal(downloadExcelModalTemplate)">Download Excel</button>
      <button class="btn btn-sm btn-primary-clr-class" *ngIf="!isDataLoading && !isPurchaseOrderGenerated"
        [disabled]="!comparisonSheetWrapperDto?.compFinancialWrapperDtos"
        (click)="openGeneratePoModalModal(generatePoModalTemplate)">
        Generate PO
      </button>
    </div>
    <div class="col-md-2 col-6 text-md-start" *ngIf="!isDataLoading && isPurchaseOrderGenerated">
      <span class="badge badge-green-class ">
        PO Generated {{subcategorySubmissionDto?.contractAlgo == 'HCS' ? 'H' :
        'L'}}{{comparisonSheetWrapperDto?.purchaseOrderEntityDto?.compSequence}}
        ({{comparisonSheetWrapperDto?.purchaseOrderEntityDto?.purchaseOrderNumber}})
      </span>
    </div>
  </div>
  <div class="table-responsive table-container" *ngIf="!isDataLoading && comparisonSheetWrapperDto?.compFinancialWrapperDtos">
    <div class="table-wrapper border bg-white pt-48px">
      <div class="text-end me-4">
        <b class="b-class">{{getFormattedPrice(normalizePrice)}}</b>
      </div>
      <table class="table medium-font table-bordered mb-0">
        <thead class="font-weight-bold">
          <tr class="align-middle">
            <th scope="col" class="text-center">
              Item
            </th>
            <th scope="col" class="text-center">
              UoM
            </th>
            <th scope="col" class="text-center">
              Qty
            </th>
            <th scope="col" class="text-center">
              Tax
            </th>
            <th scope="col" class="text-center">
              Lowest Cost by Price
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr class="align-middle" *ngFor="let item of financialQuestionTemplates; index as i">
            <td class="text-center">{{item.questionText}}</td>
            <td class="text-center">{{item.uom}}</td>
            <td class="text-center">{{item.quantity}}</td>
            <td class="text-center">{{item.tax ? item.tax + '%' : ''}}</td>
            <td class="text-center">{{getNormalizePriceOfItem(item.questionId!)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-wrapper  border" [ngClass]="isEvenOddValue(i) ? 'bg-white' : ' table-2-bg'"
      *ngFor="let table of compFinancialWrapperDtos; index as i">
      <div class="text-center div-class my-2">
        <h5 class="">{{table.companyName}}(Rank-L{{table.compSequence}})</h5>

      </div>
      <div class="text-center div-class me-3">
        <b class="b-class">Total Bid: {{getTotalPricesOfAllItems(table.companyId!)}}</b>

      </div>
      <table class="table medium-font table-bordered mb-0 mt-2">
        <thead class="font-weight-bold">
          <tr class="align-middle">
            <th scope="col" class="text-center">
              Unit Price
            </th>
            <th scope="col" class="text-center">
              Subtotal
            </th>
            <th scope="col" class="text-center">
              Tax Amount
            </th>
            <th scope="col" class="text-center">
              Additional
            </th>
            <th scope="col" class="text-center">
              Total Bid
            </th>
          </tr>
        </thead>
        <tbody class="" [ngClass]="isEvenOddValue(i) ? 'bg-white' : ' table-2-bg'">
          <tr class="align-middle" *ngFor="let item of financialQuestionTemplates; index as i">
            <td class="text-center">{{getFormattedPrice(getUserRfxQuestionDto(item.questionId!,
              table.companyId!)?.bidderResponse)}}</td>
            <td class="text-center">{{getFormattedPrice(getUserRfxQuestionDto(item.questionId!,
              table.companyId!)?.unitPriceQtyTotal)}}</td>
            <td class="text-center">{{getTaxAmountOfItem(item.questionId!, table.companyId!)}}</td>
            <td class="text-center">{{getAdditionalAmountOfItem(item.questionId!, table.companyId!)}}</td>
            <td class="text-center">{{getFormattedPrice(getUserRfxQuestionDto(item.questionId!,
              table.companyId!)?.unitPriceQtyTaxAdditionalPricesTotal)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #generatePoModalTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Generate PO</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="table-responsive table-container border p-2" *ngIf="!isDataLoading">
          <div class="table-wrapper  ">
            <table class="table medium-font table-bordered mb-0 mt-2">
              <thead class="font-weight-bold">
                <tr class="align-middle">
                  <th scope="col" class="text-center">
                    #
                  </th>
                  <th scope="col" class="text-center">
                    Bidder
                  </th>
                  <th scope="col" class="text-center">
                    Rank
                  </th>
                  <th scope="col" class="text-center">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody class="">
                <tr class="align-middle" *ngFor="let item of compFinancialWrapperDtos; index as i">
                  <td>
                    <input type="radio" id="ctrlCompFinancialCompany" [value]="item?.companyId" [formControl]="ctrlCompFinancialCompany">
                  </td>
                  <td>
                    {{item.companyName}}
                  </td>
                  <td>
                    L{{item.compSequence}}
                  </td>
                  <td>
                    {{getTotalPricesOfAllItems(item.companyId!)}}
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-wrapper mt-2 ms-3">
            <table class="table medium-font table-bordered  mb-0">
              <thead class="font-weight-bold">
                <tr class="align-middle">
                  <th scope="col" class="text-center">
                    Item
                  </th>
                </tr>
              </thead>
              <tbody class=" scroll-card-multilot-hidden-scroll">
                <tr class="align-middle" *ngFor="let item of financialQuestionTemplates; index as i">
                  <td class="text-center">{{item.questionText}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="m-3">
          <app-message-toast *ngIf="(_showErrorToast$ | async)" [isError]="true" [message]="errorMsg"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" message="PO generated successfully.">
          </app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="col-12 my-3 text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-outline-primary btn-sm "
            (click)="generatePurchaseRequest()">Generate</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #downloadExcelModalTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Download Excel</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div *ngIf="isDownloading">
          <div class="d-grid gap-2 mb-3">
            <div class="e-spinner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
