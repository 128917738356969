import { AdminUserPrivilege } from './../enums/AdminUserPrivilege';
import { SessionInfoDto } from 'src/app/shared/models/SessionInfoDto';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationUtils } from '../util/ApplicationUtils';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private router: Router
  ) { }

  doRouting(session: SessionInfoDto) {
    if (ApplicationUtils.checkIfRoleIsAssigned(session.userPrivileges!, AdminUserPrivilege.SUPER_ADMIN)) {
      this.router.navigate(['SuperAdmin/Dashboard'], { skipLocationChange: true });
    } else {
      this.router.navigate(['Admin/Dashboard'], { skipLocationChange: true });
    }
  }
}
