import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal, NgbPopover, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { SessionInfoDto } from 'src/app/shared/models/SessionInfoDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { AuctionLotSaveWrapperDto } from 'src/app/shared/models/user/AuctionLotSaveWrapperDto';
import { AuctionValidationErrorCodeDto } from 'src/app/shared/models/user/AuctionValidationErrorCodeDto';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AngularEditorComponent, AngularEditorConfig } from 'src/assets/plugins/angular-editor';
import { AiDescriptionGeneratorComponent } from 'src/app/shared/components/ai-description-generator/ai-description-generator.component';
import { AuctionValidationService } from 'src/app/shared/services/auction-validation.service';
import { AddressDto } from 'src/app/shared/models/Address';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';

@Component({
  selector: 'app-add-lot',
  templateUrl: './add-lot.component.html',
  styleUrls: ['./add-lot.component.sass']
})
export class AddLotComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() mode?: string;

  formGroup: FormGroup;
  isLoading?: boolean;
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>("");
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  sessionInfoDto?: SessionInfoDto;
  auctionEntityDto?: AuctionEntityDto;
  endTime?: NgbTimeStruct;
  ngbPopover?: NgbPopover;
  auctionLotEntityDto?: AuctionLotEntityDto;
  selectedAuctionSubscription$?: Subscription;
  selectedLotSubscription$?: Subscription;

  isSubmitted: boolean = false;
  addresses: Array<AddressDto> = []


  minDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  }

  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];

  @ViewChild("descriptionEditor") descriptionEditor?: AngularEditorComponent;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '3rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '2',
    sanitize: false,
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode'
      ]
    ],
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'Page Divider',
        class: 'break',
        tag: 'div',
      },
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'hr',
      },
    ],
  };

  constructor(
    public validationService: AuctionValidationService,
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    public adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    public userService: UserService,
    public datePipe: DatePipe,
    public lotService: AdminLotsDataHolderService,
    private ngbModal: NgbModal,
    private checkDetectRef: ChangeDetectorRef
  ) {
    this.formGroup = formBuilder.group({
      title: new FormControl('', Validators.required),
      description: new FormControl(''),
      endDate: new FormControl('', Validators.required),
      endTime: new FormControl('', Validators.required),
      address: new FormControl('')
    })
  }

  ngOnInit(): void {
    let currentDate = new Date();

    this.selectedAuctionSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe(auction => {
      if (auction) {
        this.auctionEntityDto = auction;

        /**
         * This check is required to make sure that dropdown does not re-render.
         * As re-rendering the dropdown makes the default selection i.e the first element.
         *
         * If user has selected the element which is of index > 0 , the method checks if there is any update then only
         * assign addresses to variable so that it can re-render dropdown, else it will not re-render and user selection
         * will remain intact.
         */

        if (this.isChangeInAddressListFound()) {
          this.addresses = this.auctionEntityDto.addresses ?? [];
        }

        this.checkDetectRef.detectChanges();
      }
    })

    this.selectedLotSubscription$ = this.lotService.selectedAuctionLot$.subscribe(lot => {
      if (lot) {
        if (lot && this.mode == "EDIT") {
          this.auctionLotEntityDto = lot;
          if (this.auctionLotEntityDto.address == null) {
            this.auctionLotEntityDto.address = '';
          }

          this.formGroup.patchValue(this.auctionLotEntityDto);

          if(this.auctionLotEntityDto?.endTime){
            let endDate = ApplicationUtils.getNgbPickerDate(this.auctionLotEntityDto?.endDate!);
            this.formGroup.get('endDate')?.patchValue(endDate);
          }

          if (this.auctionLotEntityDto?.endTime) {
            this.endTime = ApplicationUtils.getNgbPickerTime(this.auctionLotEntityDto?.endTime!);
            let endTime12h = ApplicationUtils.convertTime24to12(this.auctionLotEntityDto?.endTime!, this.datePipe);
            this.formGroup.get('endTime')?.patchValue(endTime12h);
          } else {
            this.endTime = ApplicationUtils.getNgbPickerTime(`${currentDate.getHours()}:${currentDate.getMinutes()}`);
          }
        } else {
          this.endTime = ApplicationUtils.getNgbPickerTime(`${currentDate.getHours()}:${currentDate.getMinutes()}`);
        }
        this.formGroup.updateValueAndValidity()

      }
    })

    this.sessionInfoDto = this.userService.getSessionInfo;

    this.formGroup.controls['endDate'].valueChanges.subscribe((val) => {
      if (val && !this.isSubmitted) {
        this.checkEndTimeValidation();
      }
    })
  }

  ngAfterViewInit(): void {
    ApplicationUtils.clearTextFormatOnPaste('descriptionEditor')
  }

  isChangeInAddressListFound(): boolean {
    // Check if the lengths are different
    if (this.addresses!.length !== this.auctionEntityDto?.addresses?.length) {
      return true;
    }

    // Check if each item in addresses is present in addresses
    for (let i = 0; i < this.auctionEntityDto?.addresses!.length; i++) {
      let address = this.auctionEntityDto?.addresses[i];

      if (!this.addresses.find(item => item.id == address.id)) {
        return true;
      }
    }

    // If all checks pass, the lists are equal
    return false;
  }



  haveAnyErrorInEndDate(){
    if(this.checkEndTimeHTMLValidation() && !this.auctionLotEntityDto?.active){
      return true
    }
    return false
  }

  get fc(): any {return this.formGroup.controls;}

  openTimepicker(p: NgbPopover, template: any) {
    if (this.ngbPopover?.isOpen) this.ngbPopover.close();
    if (!p.isOpen()) {
      this.ngbPopover = p;
      p.ngbPopover = template;
      p.open();
    } else {
      p.close();
    }
  }

  setTime(time: string) {
    if (time == 'endTime') {
      if (this.endTime?.hour != null && this.endTime?.minute != null && this.endTime?.second != null) {
        let convertedTime = ApplicationUtils.getTimeFromNgTimePicker(this.datePipe, this.endTime);
        this.formGroup.controls?.['endTime'].patchValue(convertedTime);
      }
    }
    if (this.ngbPopover?.isOpen()) this.ngbPopover?.close();
    this.formGroup.updateValueAndValidity();

    this.checkEndTimeValidation()
  }

  populateLotData() : AuctionLotEntityDto {
    let lotEntityDto = this.auctionLotEntityDto ? ApplicationUtils.clone(this.auctionLotEntityDto) : new AuctionLotEntityDto();

    if (!this.auctionLotEntityDto) {
      lotEntityDto = new AuctionLotEntityDto();
      lotEntityDto.auctionHouseId = this.sessionInfoDto?.auctionHouseId;
      lotEntityDto.auctionId = this.auctionEntityDto!.auctionId;
      lotEntityDto.timeZone = this.auctionEntityDto!.timeZone;
      lotEntityDto.domainName = this.auctionEntityDto?.domainName;

      lotEntityDto.lotSequence = this.getLotSequence();
    }

    lotEntityDto.title = this.formGroup.controls['title'].value;
    lotEntityDto.description = this.formGroup.controls['description'].value;
    lotEntityDto.endDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['endDate'].value)!;
    lotEntityDto.endTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['endTime'].value);
    lotEntityDto.address = this.formGroup.controls['address'].value ;

    return lotEntityDto;
  }

  checkEndTimeHTMLValidation() {
    if (this.formGroup.controls['endDate'].value && this.formGroup.controls['endTime'].value) {
      let endDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['endDate'].value);
      let endTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['endTime'].value);
      if (endDate && endTime) {
        let _sDate = ApplicationUtils.convertedDate(this.auctionEntityDto?.startDate!, this.auctionEntityDto?.startTime!);
        let _eDate = ApplicationUtils.convertedDate(endDate, endTime);

        let currentDate = new Date();

        if (_sDate && _eDate && (_sDate >= _eDate || currentDate >= _eDate)) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  checkEndTimeValidation() {
    if (this.formGroup.controls['endDate'].value && this.formGroup.controls['endTime'].value) {
      let endDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['endDate'].value);
      let endTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['endTime'].value);
      if (endDate && endTime) {
        let _sDate = ApplicationUtils.convertedDate(this.auctionEntityDto?.startDate!, this.auctionEntityDto?.startTime!);
        let _eDate = ApplicationUtils.convertedDate(endDate, endTime);

        let currentDate = new Date();

        if (_sDate && _eDate && (_sDate >= _eDate || currentDate >= _eDate)) {
          this._showErrorToast$.next(true);
          if (currentDate >= _eDate) {
            this._errorMsg$.next("THE LOT END DATE/TIME MUST BE AFTER THE CURRENT DATE/TIME");
          } else {
            this._errorMsg$.next("THE LOT END DATE/TIME MUST BE AFTER THE START DATE/TIME");
          }
          return true;
        } else {
          this._showErrorToast$.next(false);
          this._errorMsg$.next("");
        }
      }
      return false;
    }
    return true;
  }

  handleValidSubmit(saveForcefully: boolean) {
    if (!this.isAllowToEdit()) {
      return;
    }

    if (!saveForcefully) {
      if (this.descriptionEditor?.focused) {
        return;
      }
    }

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    if (this.checkEndTimeValidation()) {
      return;
    };

    this.auctionValidationErrorCodeDtoList = [];

    this.isSubmitted = true;
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);


    let lotEntityDto = this.populateLotData();

    this.lotService.saveAuctionLotDetails(lotEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data! as AuctionLotSaveWrapperDto;

          if(this.lotService._selectedAuctionLot$.value?.lotId  !=  data.auctionLotEntityDto?.lotId){
            this.lotService.setSelectedAuctionLot(data.auctionLotEntityDto!);

          }
          let auctionLotSaveWrapperDto = apiResponseDto.data  as AuctionLotSaveWrapperDto;
          let auctionValidationErrorCodeDtoList =  auctionLotSaveWrapperDto.auctionValidationErrorCodeDtos;

          if(!auctionValidationErrorCodeDtoList ||  auctionValidationErrorCodeDtoList.length == 0){
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            // this.lotService.addLot(auctionLotSaveWrapperDto.auctionLotEntityDto!);

            setTimeout(() => {
              this._isSaveButtonEnable$.next(true);
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)

          } else {
            this.isLoading = false;
            this._isSaveButtonEnable$.next(true);
            this.closeModal();

            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
          }

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving lot. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }
    if (this.selectedLotSubscription$) {
      this.selectedLotSubscription$.unsubscribe();
    }
  }

  openErrorModel() {
    let modalRef = this.modalService.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.auctionValidationErrorCodeDtoList;
  }

  getLotSequence() {
    let toReturn = 1;
    let allLots = this.lotService._allLots$.value;
    if (allLots && allLots.length > 0) {
      allLots.sort((a, b) => Number(b.lotSequence!) - Number(a.lotSequence!));
      toReturn = Number(allLots[0].lotSequence!) + 1
    }
    return toReturn;
  }
  openAiDescription() {
    this.ngbModal.open(AiDescriptionGeneratorComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    })
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT && !this.auctionEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE && !this.auctionEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}
