import { LotsErrorValidationComponent } from 'src/app/shared/components/lots-error-validation/lots-error-validation.component';

import { AdminUserPrivilege } from 'src/app/shared/enums/AdminUserPrivilege';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';
import { AuctionValidationService } from './../../../../shared/services/auction-validation.service';
import { AuctionValidationErrorCodeDto } from './../../../../shared/models/user/AuctionValidationErrorCodeDto';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { HtmlViewComponent } from './../../../../shared/components/html-view/html-view.component';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AuctionManageLegalPolicyComponent } from './../../auction-manage-legal-policy/auction-manage-legal-policy.component';

import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { AuctionEnableWrapperDto } from 'src/app/shared/models/AuctionEnableWrapperDto';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { NgbDropdown,} from '@ng-bootstrap/ng-bootstrap';
import { ServerDataFetchService } from 'src/app/shared/services/server-data-fetch.service';
import { Timestamp } from '@angular/fire/firestore';
import { FirestoreListenerService } from 'src/app/shared/services/firestore-listener.service';
import { TemplateRef} from '@angular/core';
import { DeploymentConfigurationDto } from 'src/app/shared/models/user/DeploymentConfigurationDto';
import { PrivacyAndTermsOfUseDto } from 'src/app/shared/models/PrivacyAndTermsOfUseDto';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { AuctionTimelineComponent } from 'src/app/layouts/admin/auction-timeline/auction-timeline.component';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';

@Component({
  selector: 'app-creator-auction-title',
  templateUrl: './creator-auction-title.component.html',
  styleUrls: ['./creator-auction-title.component.sass']
})
export class CreatorAuctionTitleComponent implements OnInit, OnDestroy {

  auctionEntityDto? : AuctionEntityDto;
  auctionHouseDto? : AuctionHouseDto;
  isLoading?: boolean = false;
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');
  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];
  selectedAuctionSubscription$?: Subscription;
  auctionHouseSubscription$?: Subscription;
  auctionEnableStatus: boolean = false;
  buyItNowStatus: boolean = false;

  isShowConfidential$ = new BehaviorSubject<boolean>(false);
  isShowTermsConfidential$ = new BehaviorSubject<boolean>(false);
  auctionDiscardModalRef!: NgbModalRef;
  activateModalRef!: NgbModalRef;
  legalModalRef!: NgbModalRef;
  errorModalRef!: NgbModalRef;
  _isAuctionInfoHide$= new BehaviorSubject<boolean>(false);
  noOfActiveLots: number = 0;

  isShowCloseModalButton: boolean = false;

  privacyAndTermsConfirmModalRef?: NgbModalRef;

  deploymentConfigurationDtoSubscription$?: Subscription;
  deploymentConfigurationDto?: DeploymentConfigurationDto;


  @ViewChild('termsConditionsRef') termsConditionsRef?: ElementRef<HTMLParagraphElement>
  @ViewChild('confidentialRef') confidentialRef?: ElementRef<HTMLParagraphElement>

  constructor(
    private dataRedirectionService: DashboardRedirectionService,
    private router: Router,
    private auctionValidationService: AuctionValidationService,
    private adminService: AdminDashboardService,
    private adminSourcingEventsDataHolderService : AdminSourcingEventsDataHolderService,
    private ngbModal: NgbModal,
    public translate: TranslateService,
    private serverDataFetchService: ServerDataFetchService,
  ) {

  }

  ngOnInit(): void {
    this.selectedAuctionSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe(auction => {
      this.auctionEntityDto = auction!;
    })

    this.deploymentConfigurationDtoSubscription$ = this.adminService.getDeploymentConfiguration$.subscribe((data) => {
      if (data) {
        this.deploymentConfigurationDto = data;
      }
    });

    this.auctionHouseSubscription$ = this.adminService.getCurrentAuctionHouse$.subscribe(data => {
      if (data) {
        this.auctionHouseDto = data;
      }
    })
  }

  openActivateModal(content: any, refType: string) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.noOfActiveLots = this.auctionEntityDto?.noOfActiveLots!;

    if (refType == 'AUCTION') {
      this.auctionEnableStatus = this.auctionEntityDto?.active!;
    }

    this.activateModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });

    // this.activateModalRef.result.then(() => {
    //   if (refType == 'AUCTION') {
    //     toggleRef.checked = this.auctionEnableStatus;
    //   } else {
    //     toggleRef.checked = this.buyItNowStatus;
    //   }
    // })
  }

  getStatus(status: string){
    if (status == AuctionStatus.DRAFT) {
      return "DRAFT";
    }else if(status == AuctionStatus.LIVE_WAIT){
      return "UPCOMING";
    }else if(status == AuctionStatus.LIVE){
      return "RUNNING";
    }else if(status == AuctionStatus.CLOSE){
      return "CLOSED ";
    }
    return null;
  }

  getClassBasedOnStatus(status: string) {
    if (status == AuctionStatus.DRAFT) {
      return "badge-draft-class";
    }else if(status == AuctionStatus.LIVE_WAIT){
      return "badge-warning-class";
    }else if(status == AuctionStatus.LIVE){
      return "badge-primary-class";
    }else if(status == AuctionStatus.CLOSE){
      return "badge-danger-class";
    }
    else if(status == AuctionStatus.DISCARD){
      return "btn-dark";
    }
    return null;
  }

  openAuctionTimeline() {
    let modalRef =  this.ngbModal.open(AuctionTimelineComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.selectedAuction = this.auctionEntityDto;
  }

  changeAuctionInfoHideOrShow(){
    this._isAuctionInfoHide$.next(!this._isAuctionInfoHide$.value)
  }

  openLegalModal() {
    this.legalModalRef = this.ngbModal.open(AuctionManageLegalPolicyComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openErrorModel() {
    this.errorModalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
    this.errorModalRef.componentInstance.auctionValidationErrorCodeDtoList = this.auctionValidationErrorCodeDtoList;
  }

  openAuctionDiscardModel(content: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.auctionDiscardModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  closeModal(modalRef: NgbModalRef) {
    this._errorMsg$.next('');
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this.isLoading = false;
    this.isShowCloseModalButton = false;
    modalRef.close();
  }

  // markBuyItNowActiveOrInActive(action: string, content: any) {
  //   this._errorMsg$.next('');
  //   this.isLoading = true;
  //   this._showErrorToast$.next(false);

  //   let buyItNowEnable = this.auctionEntityDto?.buyItNowEnable;
  //   if (action == "ENABLE") {
  //     buyItNowEnable = true;
  //   } else {
  //     buyItNowEnable = false;
  //   }
  //   this.auctionEntityDto!.buyItNowEnable = buyItNowEnable;
  //   this.adminService.saveAuctionDetails(this.auctionEntityDto!).subscribe({
  //     next: (apiResponseDto: ServerAPIResponseDto) => {
  //       if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
  //         let auctionSaveWrapperDto = apiResponseDto.data  as AuctionSaveWrapperDto;
  //         let auctionValidationErrorCodeDtoList =  auctionSaveWrapperDto.auctionValidationErrorCodeDtos;
  //         if(!auctionValidationErrorCodeDtoList ||  auctionValidationErrorCodeDtoList.length == 0){
  //           this._showSuccessToast$.next(true);
  //           this.isLoading = false;
  //           setTimeout(() => {
  //             this._isSaveButtonEnable$.next(true);
  //             this._showSuccessToast$.next(false);
  //             this.buyItNowStatus = buyItNowEnable!;
  //             this.closeModal(this.activateModalRef);
  //           }, 2000)
  //         }else{
  //           this.isLoading = false;
  //           this._isSaveButtonEnable$.next(true);
  //           this.closeModal(this.activateModalRef);
  //           this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
  //           this.openErrorModel();
  //         }
  //       } else {
  //         this._errorMsg$.next(apiResponseDto?.message!);
  //         this._showErrorToast$.next(true);
  //         this.isLoading = false;

  //       }
  //     },
  //     error: (err) => {
  //       console.error(err);
  //         this.isLoading = false;
  //         this._errorMsg$.next("Error while Enable/Disabled Auction");
  //     }
  //   })
  // }

  checkLotsValidationAndMarkActive(action: string) {
    this._errorMsg$.next('');
    this._showErrorToast$.next(false);

    let errorDataList = this.auctionValidationService.doAuctionAndLotValidation()
    let auctionErrors = errorDataList.filter(item => item.type == 'AUCTION' || item.type == 'QUESTIONNAIRE');

    if (auctionErrors.length > 0) {
      this.auctionValidationErrorCodeDtoList = auctionErrors;
      this.openErrorModel();
      return;
    }

    let validationModalRef = this.ngbModal.open(LotsErrorValidationComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
    validationModalRef.componentInstance.errorDtoList = errorDataList;
    validationModalRef.componentInstance.onSubmit.subscribe((lotIdsList: any) => {
      if (lotIdsList && lotIdsList.length > 0) {
        this.markAuctionActiveOrInActive(action, lotIdsList)
        validationModalRef.close();
      }
    })
  }

  markAuctionActiveOrInActive(action: string, lotIdsList?: string[]) {
    // if(errorDataList && errorDataList.length > 0){
    //   this.auctionValidationErrorCodeDtoList = errorDataList;
    //   this.closeModal(this.activateModalRef);
    //   this.openErrorModel(content);
    //   return;
    // }
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this._errorMsg$.next('');
    this.isLoading = true;

    let active = this.auctionEntityDto?.active;
    if (action == "ENABLE") {
      active = true;
    } else {
      active = false;
    }
    let auctionEntityDto = ApplicationUtils.clone(this.auctionEntityDto);
    auctionEntityDto!.active = active;

    let auctionEnableWrapper = new AuctionEnableWrapperDto();
    auctionEnableWrapper.auctionEntityDto = auctionEntityDto;
    auctionEnableWrapper.lotIds = lotIdsList;

    this.adminService.markAuctionActiveOrInActive(auctionEnableWrapper).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {

          let auctionValidationErrorCodeDtoList =  apiResponseDto.data as AuctionValidationErrorCodeDto[];
          if(!auctionValidationErrorCodeDtoList ||  auctionValidationErrorCodeDtoList.length == 0){
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.auctionEnableStatus = active!;
              this.closeModal(this.activateModalRef);
            }, 2000)
          }else{
            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
            this.isLoading = false;
          }
        } else {
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (err) => {
        console.error(err);
          this.isLoading = false;
          this._errorMsg$.next(`Error while ${active ? 'enabling' : 'disabling'} Auction. Try again.`);
      }
    })
  }

  navigateToHomePage() {
    this.router.navigate(['Admin/Dashboard'], { skipLocationChange: true })
  }

  discardAuction() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._errorMsg$.next('');
    this.isLoading = true;
    this._showErrorToast$.next(false);
    this.isShowCloseModalButton = false;

    this.adminService.auctionDiscard(this.auctionEntityDto?.auctionId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          this.navigateToHomePage();
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.auctionDiscardModalRef);
          }, 2000)

        } else {
          if (apiResponseDto.code == 'AUCTION-ERROR-115') {
            this.isShowCloseModalButton = true;
          }
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while discarding Auction. Try again.");
      }
    })
  }

   showOrHideTermsConfidential(){
    this.isShowTermsConfidential$.next(!this.isShowTermsConfidential$.value);
    if (!this.isShowTermsConfidential$.value) {
      this.termsConditionsRef!.nativeElement.style.height = '120px'
    } else {
      this.termsConditionsRef!.nativeElement.style.height = this.termsConditionsRef!.nativeElement.scrollHeight + 'px';
    }
   }

   showOrHideConfidential(){
    this.isShowConfidential$.next(!this.isShowConfidential$.value);
    if (!this.isShowConfidential$.value) {
      this.confidentialRef!.nativeElement.style.height = '120px'
    } else {
      this.confidentialRef!.nativeElement.style.height = this.confidentialRef!.nativeElement.scrollHeight + 'px';
    }
   }

  openViewModal(title: string, content: string) {
    let modalRef = this.ngbModal.open(HtmlViewComponent, { size: 'md', backdrop: 'static', keyboard: false , centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.content = content;
  }

  truncateText(input?: string) {
    if (input) {
      let formattedInput = input.replace(/<\/?[^>]+(>|$)/g, "");
      formattedInput = formattedInput.replace(/&#[0-9]+;/g, "");
      formattedInput = formattedInput.replace(/&nbsp;/g, ' ');
      formattedInput = formattedInput.replace(/&amp;/g, '&');
      return ApplicationUtils.truncateString(formattedInput, 101);
    }
    return '';
  }

  getErrorMsg(error: AuctionValidationErrorCodeDto) {
    return ApplicationUtils.populateMessage(error, this.translate)
  }

  ngOnDestroy(): void {
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }
    if (this.deploymentConfigurationDtoSubscription$) {
      this.deploymentConfigurationDtoSubscription$.unsubscribe();
    }
    if (this.auctionHouseSubscription$) {
      this.auctionHouseSubscription$.unsubscribe();
    }
  }

  convertNumberToWords(num: number) {
    return ApplicationUtils.convertNumberToWords(num)
  }

  openAuctionCardMenu(event: any, dropdown: NgbDropdown) {
    event.stopPropagation();
    dropdown.isOpen() ? dropdown.open() : dropdown.close();
  }

  async navigateToGraphGeneratePage() {
    this.adminSourcingEventsDataHolderService.setSelectedAuction(this.auctionEntityDto!);
    await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(this.auctionEntityDto!.auctionId!);
    this.dataRedirectionService.storeNavigationContext(
      ApplicationConstants.ADMIN,
      ApplicationConstants.AUCTION_GRAPH_PAGE
    );
  }

  navigateToAnalyticsDashboardPage() {
    this.adminSourcingEventsDataHolderService.setSelectedAuction(this.auctionEntityDto!);
    this.dataRedirectionService.storeNavigationContext(
      ApplicationConstants.ADMIN,
      ApplicationConstants.AUCTION_ANALYTICS_DASHBOARD_PAGE
    );
  }

  isShowAuctionDashboardMenu() {
    if (this.auctionEntityDto &&
      (this.auctionEntityDto!.status == AuctionStatus.LIVE || this.auctionEntityDto!.status == AuctionStatus.CLOSE) &&
      this.auctionEntityDto!.noOfActiveLots! > 1
    ) {
      return true;
    }
    return false;
  }

  getMetadataDate(timestamp?: Timestamp | null) {
    if (timestamp) {
      let createdDate = timestamp.toDate();
      let date = createdDate.toDateString();
      let time = createdDate.toTimeString();
      return ApplicationUtils.getDisplayDateAdmin(date, time);
    }
    return '';
  }




  closeAllModal() {
    this.ngbModal.dismissAll();
  }



  isAllowToEdit() {
    let toReturn = true;

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT && !this.auctionEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE && !this.auctionEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}
