<div class="card p-2 bg-white">
  <div class=" row">
    <div class="col-6">
      <h5 class="heading-h5 text-behind-svg-icon">OTHER CONFIGURATION</h5>
    </div>
    <div class="col-6 text-end">
      <button (click)="openColorConfigurationModal(openColorConfiguration)" class="btn btn-new-class btn-sm px-4 ms-3">
        <!-- <img src="assets/icons/edit_dark.svg" class=" c-pointer svg-icon-class "> -->
        EDIT
      </button>
    </div>
  </div>
  <hr class="hr my-2" />
  <div>
    <div class="col-12 mt-2">
      <div class="row mb-2 align-items-center align-items-center">
        <div class="col-6 div-class">
          Primary Color:
        </div>
        <div class="col-4 text-uppercase">
          {{organizationUiDto?.primaryColor}}
        </div>
        <div class="col-2 text-end">
          <div class="color-view" [style.background]="organizationUiDto?.primaryColor"></div>
        </div>
      </div>

      <div class="row mb-2 align-items-center align-items-center">
        <div class="col-6 div-class">
          Secondary Color:
        </div>
        <div class="col-4 text-uppercase">
          {{organizationUiDto?.secondaryColor}}
        </div>
        <div class="col-2 text-end">
          <div class="color-view" [style.background]="organizationUiDto?.secondaryColor"></div>
        </div>
      </div>

    </div>

  </div>
</div>


<ng-template #openColorConfiguration>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-10 d-flex align-items-center">
          <h2 ngbAutoFocus>Other Configuration</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
        <div class="col-12 mb-3">

          <!-- Primary Color -->
          <div class="input-group">
            <div class="form-floating">
              <input type="text" class="form-control" id="primaryColor" placeholder="#FFFFFF" formControlName="primaryColor" />
              <label for="primaryColor">Primary Color</label>
            </div>
            <span class="input-group-text w-48" [style.background]="fc.primaryColor.value"
             [(colorPicker)]="primaryColor!" (colorPickerChange)="changeColor($event, 'PRIMARY')"></span>
          </div>
          <div *ngIf="fc.primaryColor.invalid && (fc.primaryColor.dirty || fc.primaryColor.touched)">
            <p class="text-danger" *ngIf="fc.primaryColor.errors?.required">Required</p>
            <p class="text-danger" *ngIf="fc.primaryColor.errors?.invalidColor">Invalid Color</p>
          </div>

          <!-- Secondary Color -->
          <div class="input-group mt-3">
            <div class="form-floating">
              <input type="text" class="form-control" id="secondaryColor" placeholder="Secondary Color" formControlName="secondaryColor" />
              <label for="secondaryColor">Secondary Color</label>
            </div>
            <span class="input-group-text w-48" [style.background]="fc.secondaryColor.value"
            [(colorPicker)]="secondaryColor!" (colorPickerChange)="changeColor($event, 'SECONDARY')"></span>
          </div>
          <div *ngIf="fc.secondaryColor.invalid && (fc.secondaryColor.dirty || fc.secondaryColor.touched)">
            <p class="text-danger" *ngIf="fc.secondaryColor.errors?.required">Required</p>
            <p class="text-danger" *ngIf="fc.secondaryColor.errors?.invalidColor">Invalid Color</p>
          </div>

        </div>

        <div class="my-3" >
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" [message]="'Auction House saved successfully.'"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class" (click)="handleValidSubmit()" ngbAutoFocus>SAVE</button>
        </div>
      </form>
    </div>
   </div>
  </div>
</ng-template>
