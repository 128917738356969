<header class=" bg-white main-header head-pos" #headerPrimary>
  <!-- bg-white main-header py-md-1 py-xl-0 head-pos -->
  <div class="header-divider" *ngIf="!IsAnonymous">
    <div class="col-12">
      <div class="row  align-items-center justify-content-between ps-3 pe-5 ps-md-0 pe-md-2">
        <div class="col-auto">
          <a>
            <!-- <img src="assets/logo/procurext-logo.svg" alt="Procurext"
              class="application-logo | img-fluid my-1 ms-md-5" /> -->
              <img src="/downloadLandingBlob?fileId={{organizationUiDto?.logo?.fileId}}" [alt]="organizationUiDto?.name" class="application-logo | img-fluid my-1 ms-md-5" />
          </a>
        </div>
        <div class="d-block d-md-none col-auto text-end">
          <span ngbDropdown #profileDropdown1="ngbDropdown" autoClose="false" container="body" placement="bottom-right">
            <span ngbDropdownToggle data-bs-toggle="dropdown">
              <span class="icon-wrapper icon-wrapper-alt c-pointer">
                <button class="header-button-ui btn btn-sm">
                  <img src="assets/icons/hamberger.svg" class=" c-pointer svg-icon-class-small  hamburger">
                </button>
              </span>
            </span>
            <ul ngbDropdownMenu class="text-start mt-3 p-2 margin-right dropdown-menu shadow">
              <li ngbDropdownItem (click)="openMyDetailsModal(myDetails, profileDropdown1)">
                <span class="me-3" container="body" placement="top" >
                  <img src="/downloadLandingBlob?fileId={{userEntityDto?.profileImage?.fileId}}" 
                    container="body" alt="{{userEntityDto?.firstName}}" class="header-image"
                    *ngIf="userEntityDto?.profileImage">
                  <img src="./assets/images/avatar_default.png" ngbTooltip="My Profile" 
                    alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="!userEntityDto?.profileImage">
                  My Profile
                </span>
              </li>
              <hr class="hr my-1">
              <li ngbDropdownItem class="c-pointer" (click)="navigateToPage('/calendar')">
                <span class="me-3 ">
                  <img src="assets/icons/date_light.svg" class="svg-icon-class-md ">
                  Auction Calendar
                </span>
              </li>

              <hr class="hr my-1">
              <li ngbDropdownItem class="c-pointer">
                <span class="me-3 ">
                  <img src="assets/icons/notification-bell.svg" class="svg-icon-small me-1">
                  Notifications
                </span>

              </li>
              <hr class="hr my-1">
              <li ngbDropdownItem (click)="logout(profileDropdown1)" class="c-pointer">
                <span class="me-3 text-danger">
                  <img src="assets/icons/logout.svg" class="svg-icon-class">
                  Logout1
                </span>
              </li>
            </ul>
          </span>
        </div>
        <div class="col-auto text-end d-none d-md-block">

          <span class="span-class me-3" *ngIf="!IsAnonymous">
            Hi, {{userEntityDto?.firstName}} {{userEntityDto?.lastName}}
          </span>
          <!-- <span class="me-3 ">
            <img src="assets/icons/date_light.svg" class="svg-icon-class-md c-pointer " ngbTooltip="Calendar"
              container="body" (click)="openevaluationPageModal(evaluationPage)">
          </span> -->
          <!-- <span class="me-3 " ngbDropdown #profileDropdown1="ngbDropdown" autoClose="true" container="body" placement="bottom-right">
            <span ngbDropdownToggle data-bs-toggle="dropdown">
            <img src="assets/icons/notification-bell.svg" class="svg-icon-small c-pointer" ngbTooltip="Notification"
              container="body" *ngIf="!isNotificationAvailable">
            <img src="assets/icons/notifications.gif" class="svg-icon-class-md c-pointer" ngbTooltip="Notification"
              container="body" *ngIf="isNotificationAvailable" (click)="showNotification()">
            </span>
            <ul ngbDropdownMenu class="text-start mt-3 margin-right dropdown-menu scroll-notification shadow">
              <li  class="c-default li-white  my-2 px-2">
                <div class="row">
                  <div class="col-9 div-class">
                    Notification (25)
                  </div>
                  <div class="col-3 text-end">
                    <span class=" new-notification-badge">New
                      <span class="position-absolute blink top-10 start-97 translate-middle badge rounded-pill bg-danger">
                        9
                      </span>
                    </span>
                  </div>
                </div>
                <hr class="hr my-2">
              </li>
              <li ngbDropdownItem class="c-pointer px-2">
                <div class="card mb-2 p-2">
                  <div class="row">
                    <div class="col-12 medium-font">
                      New Notification Arrival 
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-6 small-font">
                      13/05/24 12:48:00 PM
                    </div>
                    <div class="col-6 text-end small-font">
                     <span class="text-danger">Expiry: 13/05/24 12:48:00 AM </span>
                    </div>
                  </div>
                </div>
              </li>  
              </ul>
          </span> -->
          <app-notifications layoutType="HEADER"></app-notifications>
          
          <span class="me-3 c-pointer" ngbTooltip="My Profile"  container="body" placement="top" (click)="openMyDetailsModal(myDetails, profileDropdown1)">
            <img src="/downloadLandingBlob?fileId={{userEntityDto?.profileImage?.fileId}}"  alt="{{userEntityDto?.firstName}}" class="header-image"
              *ngIf="userEntityDto?.profileImage">
            <img src="./assets/images/avatar_default.png"
              alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="!userEntityDto?.profileImage">
          </span>
          <span class="me-3 c-pointer " ngbTooltip="Logout" container="body" placement="top"  (click)="logout()">
            <img src="assets/icons/logout.svg" class="svg-icon-class " >
          </span>
        </div>
        <!-- <div class="col-xl-1 col-2 text-end" >
        <div>
          <span ngbDropdown #profileDropdown1="ngbDropdown" autoClose="true" container="body" placement="bottom-right">
            <span ngbDropdownToggle data-bs-toggle="dropdown">
              <span class="icon-wrapper icon-wrapper-alt c-pointer">

                  <button class="header-button-ui btn btn-sm">
                    <img src="assets/icons/hamberger.svg" class=" c-pointer svg-icon-class-small  hamburger">
                    <span *ngIf="!IsAnonymous" class="ms-2">
                      <img
                    src="/downloadLandingBlob?fileId={{userEntityDto?.profileImage?.fileId}}"
                    alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="userEntityDto?.profileImage"  >
                  <img  src="./assets/images/avatar_default.png"
                    alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="!userEntityDto?.profileImage" >
                    </span>
                  </button>
              </span>
            </span>

            <ul ngbDropdownMenu class="text-start mt-3 p-2 margin-right dropdown-menu shadow">

              <li ngbDropdownItem class="c-pointer" *ngIf="IsAnonymous" (click)="openLoginModal(loginModal)">
                <a class="text-info">
                  <img src="assets/icons/login_light.svg" class="svg-icon-class-md c-pointer me-2">LOGIN</a>
              </li>
              <hr class="hr my-1" *ngIf="IsAnonymous">
              <li ngbDropdownItem class="c-defoult" *ngIf="!IsAnonymous">
                <div class="row">
                  <div class="col-12">
                    <small class="small-class">Logged In as </small>
                    <div *ngIf="userEntityDto?.firstName">
                      {{userEntityDto?.firstName}} {{userEntityDto?.lastName}}
                    </div>
                    <div *ngIf="!userEntityDto?.firstName">
                      {{userEntityDto?.primaryEmailId}}
                    </div>
                  </div>
                </div>
                <div class="col-12 text-center my-2 ">
                  <span class="button-class me-2 badge-primary-class c-pointer"
                    (click)="openMyDetailsModal(myDetails, profileDropdown1)">
                    My Profile
                  </span>
                </div>
              </li>
              <hr class="hr my-1">
              <li ngbDropdownItem class="c-pointer" (click)="navigateToPage('/calendar')">
                <a>
                  <img src="assets/icons/date_light.svg" class="svg-icon-class-md c-pointer me-2">
                  <small class="ps-1">Auction Calendar</small></a>
              </li>
              <hr class="hr my-1" *ngIf="!IsAnonymous">
              <li ngbDropdownItem class="c-pointer" *ngIf="!IsAnonymous" (click)="logout(profileDropdown1)">
                <a class="text-danger"><img src="assets/icons/logout.svg"
                    class="svg-icon-class-md c-pointer me-2"><b>LOGOUT</b></a>
              </li>
            </ul>
          </span>
        </div>
      </div>  -->
      </div>
    </div>
  </div>

</header>
<div #headerSecondary>

</div>


<ng-template #loginModal>
  <app-user-login></app-user-login>
</ng-template>
<ng-template #changePasswordModal>
  <app-user-password-change></app-user-password-change>
</ng-template>

<!-- Templates -->
<ng-template #messageDialog>
  <app-message-dialog></app-message-dialog>
</ng-template>
<ng-template #myDetails>
  <app-user-details></app-user-details>
</ng-template>

<app-loader #appLoader></app-loader>

<!-- <ng-template #evaluationPage>
  <div class="container p-3">
    <div class="col-12">
      <div class="row align-items-center">
        <div class="col-11">
          <div class="me-3">
            <span class="me-3">Company:</span>
            <span>ABC Corp</span>
          </div>

          <div>
            <span class="me-3">Event Name</span>
            <span>RFQ 23</span>
          </div>
        </div>
        <div class="col-1 text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>

      <div class="mt-3 col-11">
        <div class="row align-items-center mb-3">
          <div class="col-4">
            <span class="me-3">
              <a class="c-pointer">Previous</a>
            </span>
            <span class="me-3">Q 1 to 2</span>
            <span class="me-3">
              <a class="c-pointer">Next</a>
            </span>
          </div>
          <div class="col-4 text-center">
            Total Score 82/100
          </div>
          <div class="col-4 text-end">
            <span class="badge-gray-class badge me-3">Incomplete-2</span>
            <span class="badge-blue-class badge">Complete-1</span>
          </div>
        </div>
        <div class="mb-3">
          <div class="input-group">
            <div class="form-floating">
              <input class=" form-control">
              <label for="floatingInput">Question Criteria
                <span class="ms-2 badge badge-white-class">Mandatory</span>
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class=" col-md-5">
            <div class="">Single Choice Response</div>
            <div class="small-grey-font">
              Bidder's Response is selected in Blue. To change the evaluation select a different response
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-5 mb-3">
            <div class="input-group">
              <div class="form-floating">
                <input class=" form-control">
                <label for="floatingInput">Score-10
                </label>
              </div>
            </div>

          </div>
          <div class="col-md-7 mb-3">
            <div>Uploaded Documents</div>
            <div>
              <div class="row medium-grey-font" *ngFor="let item of [1,2,3];">
                <div class="col-6">
                  <img src="assets/icons/file_light.svg" class="svg-icon-class c-pointer">
                  test 123
                </div>
                <div class="col-2 text-end">
                  123 kb
                </div>
                <div class="col-3 text-end">
                  <span> Expiry</span>
                  <span class="d-none">NA</span>
                  <span> <span>12/2/24</span></span>
                </div>
                <div class="col-1 text-end">
                  <img src="assets/icons/delete_dark.svg" class="svg-icon-class-small c-pointer">
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 mb-3">
            <div class="input-group">
              <div class="form-floating">
                <input class=" form-control">
                <label for="floatingInput">Score-10
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-7">
          </div>
          <div class="col-md-5 mb-3">
            <div class="input-group">
              <div class="form-floating">
                <input class=" form-control">
                <label for="floatingInput">Score-10
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-7">
          </div>
        </div>

        <div class="mb-3">
          <div class="input-group">
            <div class="form-floating">
              <input class=" form-control">
              <label for="floatingInput">
                <div class="row">
                  <div class="col-7">
                    Comment
                  </div>
                  <div class="col-5 text-end">
                    <a class="link-class">View Bidder's Comment</a>
                  </div>
                </div>

              </label>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</ng-template> -->