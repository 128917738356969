<div class="container p-2 mt-5">
  <div class="row align-items-end">
    <div class="col-md-3  text-start mb-2">
      <h5 class="heading-h5 text-top-margin">MATERIALS</h5>
    </div>
    <div class="col-md-5 text-start mb-2">
    </div>
    <div class="col-md-4 mb-2 text-md-end">
      <button class="btn btn-sm btn-primary-clr-class mt-2 mt-sm-0" (click)="openAddMaterialModal(addMaterialModal )">
         <span class="ms-2">Add Material</span>
      </button>
    </div>
  </div>

  <div class="col-12">
    <div class="card">
      <p-table [value]="materialUiDtos" [tableStyle]="{ 'min-width': '50rem' }" [loading]="isDataLoading">
        <ng-template pTemplate="header">
          <tr>
            <th>#</th>
            <th>Material Id</th>
            <th>Material Name</th>
            <th>Quantity</th>
            <th>UoM</th>
            <th>Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-material let-i="rowIndex">
          <tr>
            <td>{{ i+1 }}</td>
            <td>{{ material.materialId }}</td>
            <td>{{ material.materialName }}</td>
            <td>{{ material.qty }}</td>
            <td>{{ material.uom }}</td>
            <td>
              <a class="link-class" (click)="openAddMaterialModal(addMaterialModal, material )">Edit</a>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<ng-template #addMaterialModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Material</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-floating">
                <input type="text" class="form-control" id="materialId" formControlName="materialId"
                  [ngClass]="{'is-invalid': fc['materialId'].invalid && (fc['materialId'].dirty || fc['materialId'].touched)}">
                <label for="materialId">Material Id</label>
                <div *ngIf="fc['materialId'].invalid && (fc['materialId'].dirty || fc['materialId'].touched)">
                  <p class="text-danger" *ngIf="fc['materialId'].errors?.['required']">Required</p>
                  <p class="text-danger" *ngIf="fc['materialId'].errors?.['minlength']">Invalid</p>
                  <p class="text-danger" *ngIf="fc['materialId'].errors?.['maxlength']">Invalid</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-floating">
                <input type="text" class="form-control" id="materialName" formControlName="materialName"
                  [ngClass]="{'is-invalid': fc['materialName'].invalid && (fc['materialName'].dirty || fc['materialName'].touched)}">
                <label for="materialName">Material Name</label>
                <div *ngIf="fc['materialName'].invalid && (fc['materialName'].dirty || fc['materialName'].touched)">
                  <p class="text-danger" *ngIf="fc['materialName'].errors?.['required']">Required</p>
                </div>
              </div>
            </div>
          </div>

          <label for="description">Description</label>
          <div class="mb-3">
            <textarea type="text" class="form-control" id="description" formControlName="description" rows="3"
              [ngClass]="{'is-invalid': fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)}"></textarea>
            <div *ngIf="fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)">
              <p class="text-danger" *ngIf="fc['description'].errors?.['required']">Required
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-floating">
                <input type="number" class="form-control" id="qty" formControlName="qty"
                  [ngClass]="{'is-invalid': fc['qty'].invalid && (fc['qty'].dirty || fc['qty'].touched)}">
                <label for="qty">Quantity</label>
                <div *ngIf="fc['qty'].invalid && (fc['qty'].dirty || fc['qty'].touched)">
                  <p class="text-danger" *ngIf="fc['qty'].errors?.['required']">Required</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3">
              <div class="form-floating">
                <input type="text" class="form-control" formControlName="uom" id="uom"
                  [ngClass]="{'is-invalid': fc['uom'].invalid && (fc['uom'].dirty || fc['uom'].touched)}">
                <label for="uom" class="">UoM</label>
              </div>
              <div *ngIf="fc['uom'].invalid && (fc['uom'].dirty || fc['uom'].touched)">
                <p class="text-danger" *ngIf="fc['uom'].errors?.['required']">Required
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input name="" type="text" id="image" (click)="materialImage.click()" [value]="imageFileName"
                  class="form-control c-pointer"
                  [ngClass]="{'is-invalid': fc['image'].invalid && (fc['image'].dirty || fc['image'].touched)}" />
                <input #materialImage type="file" onclick="this.value=null" (change)="chooseFile($event)"
                  class="d-none" />
                <label for="image">Add Material Image</label>
                <div *ngIf="fc['image'].invalid && (fc['image'].dirty || fc['image'].touched)">
                  <p class="text-danger" *ngIf="fc['image'].errors?.['image']">Required</p>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row" *ngIf="imageUrl">
                <div class="col-6">
                  <img class="modal-logo-image" alt="" [src]="imageUrl" />
                </div>

                <div class="col-6 mt-3 text-end">
                  <button class="btn btn-sm btn-new-class me-2" (click)="openViewImageModal(imageUrl)">
                    <img src="assets/icons/view_dark.svg" class="svg-icon-class c-pointer">
                  </button>

                  <span (click)="removeMaterialImage()" [ngClass]="{'disabled' : isLoading }">
                    <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!selectedPreferenceCategory">
            <label class="mb-1" for="category">Select UNSPSC Category</label>
            <div class="col-9 mx-auto mb-3"
              [ngClass]="{'border border-danger': !selectedPreferenceCategory}">
              <app-categories-tree-view [selectionMode]="TreeSelectionMode.single"
                (onSelectedCategory)="onSelectedCategory($event)"></app-categories-tree-view>
            </div>
          </div>

          <div *ngIf="selectedPreferenceCategory">
            <label class="mb-1" for="selectedCategory">Selected UNSPSC Category</label>
            <div class="table-responsive">
              <table class="table table-bordered medium-font mb-3 align-middle">
                <thead class="table-dark">
                  <tr class="text-center">
                    <th class=""><span>Label One</span></th>
                    <th class=""><span>Label Two</span></th>
                    <th class=""><span>Label Three</span></th>
                    <th class=""><span>Label Four</span></th>
                    <th class=""><span>Label Five</span></th>
                    <th class=""><span>Action</span></th>
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <tr>
                    <td>{{selectedPreferenceCategory.categoryName}}</td>
                    <td>{{selectedPreferenceCategory.categoryLevelOneName}}</td>
                    <td>{{selectedPreferenceCategory.categoryLevelTwoName}}</td>
                    <td>{{selectedPreferenceCategory.categoryLevelThreeName}}</td>
                    <td>{{selectedPreferenceCategory.categoryLevelFourName}}</td>
                    <td><a class="link-class" (click)="toggleEditCategory()">Edit</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </form>

        <label class="mb-1" for="safetyLevels">Safety Levels</label>
        <div class="card">
          <p-table [value]="ngTableDataList" dataKey="id" [tableStyle]="{ 'min-width': '50rem' }" [loading]="isDataLoading">
            <ng-template pTemplate="header">
              <tr>
                <th style="width:15%">#</th>
                <th style="width:20%">Company</th>
                <th style="width:20%">Plant</th>
                <th style="width:15%">Current Level</th>
                <th style="width:15%">Safety Min</th>
                <th style="width:15%">Safety Max</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tableData let-editing="editing" let-i="rowIndex">
              <tr>
                <td>{{ i+1 }}</td>
                <td>{{ tableData.companyCode }}</td>
                <td>{{ tableData.plantCode }}</td>
                <td [pEditableColumn]="tableData.currentLevel" pEditableColumnField="currentLevel">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="text" [(ngModel)]="tableData.currentLevel" />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ tableData.currentLevel }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td [pEditableColumn]="tableData.safetyMin" pEditableColumnField="safetyMin">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="text"  [(ngModel)]="tableData.safetyMin" required />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ tableData.safetyMin }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td [pEditableColumn]="tableData.safetyMax" pEditableColumnField="safetyMax">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText [(ngModel)]="tableData.safetyMax" />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ tableData.safetyMax }}
                    </ng-template>
                  </p-cellEditor>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$|async" [isError]="true" [message]="errorMsg">
          </app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            [message]="'Material saved successfully.'">
          </app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class" (click)="handleValidSubmit()">
           
            {{isEditMaterial ? 'EDIT' : 'ADD'}}
            
          </button>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

      </div>
    </div>
  </div>
</ng-template>