import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationStartupDto } from 'src/app/shared/models/ApplicationStartupDto';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserEntityInfoDto } from 'src/app/shared/models/user/UserEntityInfoDto';
import { ApplicationStartupCacheService } from 'src/app/shared/services/application-startup-cache.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-onboarding-users',
  templateUrl: './onboarding-users.component.html',
  styleUrls: ['./onboarding-users.component.sass']
})
export class OnboardingUsersComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  ctrlSearch: FormControl = new FormControl('')

  isDataLoading: boolean = true;
  isLoading: boolean = false;
  isShowUserView: boolean = false;

  applicationStartupDto?: ApplicationStartupDto;
  selectedCompanyUiDto?: CompanyUiDto;
  selectedUserEntityInfoDto?: UserEntityInfoDto;
  userEntityInfoDtos: UserEntityInfoDto[] = [];
  filteredUserEntityInfoDtos: UserEntityInfoDto[] = [];

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _filteredUserEntityInfoDtos$ = new BehaviorSubject<UserEntityInfoDto[]>([]);

  usersListSubscription$?: Subscription;
  selectedCompanySubscription$?: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private changeDetectRef: ChangeDetectorRef,
    private onboardingService: OnboardingService,
    private appStartupService: ApplicationStartupCacheService
  ) {
    this.formGroup = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      designation: ['', Validators.required],
      empCode: [''],
      primaryEmailId: ['', [Validators.required, Validators.pattern(Pattern.email)]],
      plantCodes: ['']
    })
  }

  ngOnInit(): void {
    this.loadUserEntityInfoDto();

    this.applicationStartupDto = this.appStartupService.getApplicationStartupDto;

    this.usersListSubscription$ = this.onboardingService.getUserEntityInfoDtos$.subscribe(data => {
      if (data) {
        this.userEntityInfoDtos = data;
        this.refreshUsersList();

        if (this.selectedUserEntityInfoDto) {
          this.selectedUserEntityInfoDto = this.userEntityInfoDtos.find(item => item.userId == this.selectedUserEntityInfoDto?.userId);
        }
      } else {
        this.userEntityInfoDtos = [];
        this.filteredUserEntityInfoDtos = this.userEntityInfoDtos;
        this._filteredUserEntityInfoDtos$.next(this.filteredUserEntityInfoDtos);
      }
    })

    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;
        this.refreshUsersList();
      } else {
        this.selectedCompanyUiDto = undefined;
      }
    })

    this.ctrlSearch.valueChanges.subscribe((val) => {
      this.search(val);
    })
  }

  get fc(): any { return this.formGroup.controls; }

  async loadUserEntityInfoDto() {
    let orgCode = this.onboardingService.getOrgCode();
    this.isDataLoading = true;
    await this.onboardingService.loadOnboardingUsersList(orgCode!);
    this.isDataLoading = false;
  }

  refreshUsersList() {
    if (this.selectedCompanyUiDto) {
      this.filteredUserEntityInfoDtos = this.userEntityInfoDtos.filter(item => item.companyCode == this.selectedCompanyUiDto?.companyCode);
      this._filteredUserEntityInfoDtos$.next(this.filteredUserEntityInfoDtos);
    } else {
      this.filteredUserEntityInfoDtos = this.userEntityInfoDtos;
      this._filteredUserEntityInfoDtos$.next(this.filteredUserEntityInfoDtos);
    }
  }

  search(searchText: string) {
    let userEntityInfoDtos = [...this.userEntityInfoDtos ?? []];

    if (searchText.trim() != '') {
      let filteredUserEntityInfoDtos = userEntityInfoDtos.filter((item) =>
        item.firstName?.toLowerCase().includes(searchText.trim().toLowerCase())
        || item.lastName?.toLowerCase()?.includes(searchText.trim())
        || item.companyName?.toLowerCase()?.includes(searchText.trim())
        || item.companyCode?.toLowerCase()?.includes(searchText.trim())
        || item.primaryEmailId?.toLowerCase()?.includes(searchText.trim())
      );

      this.filteredUserEntityInfoDtos = filteredUserEntityInfoDtos;
    } else {
      this.filteredUserEntityInfoDtos = userEntityInfoDtos;
    }

    this._filteredUserEntityInfoDtos$.next(this.filteredUserEntityInfoDtos);
  }

  populateUserDetails() {
    this.formGroup.controls['firstName'].patchValue(this.selectedUserEntityInfoDto?.firstName);
    this.formGroup.controls['lastName'].patchValue(this.selectedUserEntityInfoDto?.lastName);
    this.formGroup.controls['primaryEmailId'].patchValue(this.selectedUserEntityInfoDto?.primaryEmailId);
    this.formGroup.controls['designation'].patchValue(this.selectedUserEntityInfoDto?.designation);
    this.formGroup.controls['empCode'].patchValue(this.selectedUserEntityInfoDto?.empCode);
    this.formGroup.controls['plantCodes'].patchValue(this.selectedUserEntityInfoDto?.plantCodes);

    this.formGroup.updateValueAndValidity();
    this.changeDetectRef.detectChanges();
  }

  viewUserDetails(userEntityInfoDto: UserEntityInfoDto) {
    this.isShowUserView = true;
    this.selectedUserEntityInfoDto = userEntityInfoDto;
    this.populateUserDetails()
  }

  backToAllUsersList() {
    this.isShowUserView = false;
    this.selectedUserEntityInfoDto = undefined;
  }

  openUserDetail(content: any) {
    this.ngbModal.open(content, { size: 'lg', backdrop: 'static', keyboard: false, centered: true })
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  mergeUserEntityInfoDto() {
    let formValue = this.formGroup.getRawValue();

    let organizationUiDto = this.onboardingService.getOrganizationUiDto;

    let userEntityInfoDto = ApplicationUtils.clone(this.selectedUserEntityInfoDto) as UserEntityInfoDto;
    userEntityInfoDto.orgCode = organizationUiDto?.orgCode;
    userEntityInfoDto.companyCode = this.selectedCompanyUiDto?.companyCode;
    userEntityInfoDto.domainName = organizationUiDto?.adminDomainName;
    userEntityInfoDto.firstName = formValue.firstName;
    userEntityInfoDto.lastName = formValue.lastName;
    userEntityInfoDto.primaryEmailId = formValue.primaryEmailId;
    userEntityInfoDto.designation = formValue.designation;
    userEntityInfoDto.empCode = formValue.empCode;
    userEntityInfoDto.plantCodes = formValue.plantCodes;

    return userEntityInfoDto;
  }

  updateUser() {
    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    let userEntityInfoDto = this.mergeUserEntityInfoDto()
    this.isLoading = true;

    this.onboardingService.saveUserEntityInfoDto(userEntityInfoDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let newUserEntityInfoDto = apiResponseDto.data as UserEntityInfoDto;
          this.onboardingService.updateUserEntityInfoDto(newUserEntityInfoDto)

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving User");
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.usersListSubscription$) {
      this.usersListSubscription$.unsubscribe();
    }
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }
  }
}
