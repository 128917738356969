import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Firestore, Timestamp, Unsubscribe, collection, onSnapshot, query, where } from '@angular/fire/firestore';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { AuctionSorting } from 'src/app/shared/enums/AuctionSorting';
import { Currency } from 'src/app/shared/models/Currency';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { TimeZoneDto } from 'src/app/shared/models/TimeZoneDto';
import { PurchaseRequestUiDto } from 'src/app/shared/models/rfx/PurchaseRequestUiDto';
import { AuctionValidationErrorCodeDto } from 'src/app/shared/models/user/AuctionValidationErrorCodeDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { PurchaseRequestStatus } from 'src/app/shared/enums/rfx/PurchaseRequestStatus';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileService } from 'src/app/shared/services/file.service';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { OrganizationUiDto } from 'src/app/shared/models/OrganizationUiDto';
import { UserService } from 'src/app/shared/services/user.service';
import { UserEntityInfoDto } from 'src/app/shared/models/user/UserEntityInfoDto';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { Pattern } from 'src/app/shared/util/Patterns';
import { PreferenceCategoryDto } from 'src/app/shared/models/user/PreferenceCategoryDto';
import { TreeSelectionMode } from 'src/app/shared/enums/TreeSelectionMode';
import { logEvent, getAnalytics } from '@angular/fire/analytics';
import { AppWorkflowUiDto } from 'src/app/shared/models/AppWorkflowUiDto';
import { ApprovalWorkflowStatusEnum } from 'src/app/shared/enums/ApprovalWorkflowStatusEnum';
import { RfxPurchaseInvoiceDto } from 'src/app/shared/models/rfx/RfxPurchaseInvoiceDto';

@Component({
  selector: 'app-admin-invoice-workflow',
  templateUrl: './admin-invoice-workflow.component.html',
  styleUrls: ['./admin-invoice-workflow.component.sass']
})
export class AdminInvoiceWorkflowComponent implements OnInit, OnDestroy, AfterViewInit {

  selectedSortBy: string = AuctionSorting.AUCTION_CREATED_DATE_DESC;
  isEdit: boolean = false;
  isLoading: boolean = false;
  isApprovalLoading: boolean = false;
  isDataLoading: boolean = true;
  isSubmitted: boolean = false;
  page: number = 1;
  pageSize: number = 50;
  approveRequest?: string;

  ctrlSearchPR: FormControl = new FormControl('');
  ctrlComments: FormControl = new FormControl('');

  filterModalRef?: NgbModalRef;
  approvalModalRef?: NgbModalRef;

  currencies: Array<Currency> = [];
  timezones: Array<TimeZoneDto> = [];
  selectedCurrency?: Currency;
  selectedPurchaseInvoiceDto?: RfxPurchaseInvoiceDto;
  organizationUiDto?: OrganizationUiDto;
  userEntityInfoDto?: UserEntityInfoDto;

  TreeSelectionMode: typeof TreeSelectionMode = TreeSelectionMode;
  selectedPreferenceCategory?: PreferenceCategoryDto;
  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];
  appWorkflowUiDtoList: AppWorkflowUiDto[] = [];
  currentAppWorkflowUiDto?: AppWorkflowUiDto;

  allPurchaseInvoices: RfxPurchaseInvoiceDto[] = [];
  tempPurchaseInvoices: RfxPurchaseInvoiceDto[] = [];
  filteredInvoicesList$ = new BehaviorSubject<RfxPurchaseInvoiceDto[]>([]);


  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');
  isMultiLineError$ = new BehaviorSubject<boolean>(false);

  plantsListSubscription$?: Subscription;
  auctionTimeZoneSubscription$?: Subscription;
  organizationUiDtoSubscription$?: Subscription;

  externalPRTitle: FormControl = new FormControl('', Validators.required);
  documentTypes = ['application/pdf'];
  fileUploadError: boolean = false;
  errorMsg?: string | undefined;
  _isSaveButtonDisable$ = new BehaviorSubject<boolean>(false);

  @ViewChild('externalPR') externalPR?: HTMLInputElement;

  collectionPurchaseRequests: string = "PURCHASE_REQUEST";
  prListenerUnsubscribe: Unsubscribe | undefined;

  minDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  constructor(
    private ngbModal: NgbModal,
    private userService: UserService,
    private currencyService: CurrencyService,
    private adminDashboardService: AdminDashboardService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    public onboardingService: OnboardingService,
    private firestore: Firestore,
  ) {

  }

  ngOnInit(): void {
    this.currencies = this.currencyService.getCurrencies;
    this.userEntityInfoDto = this.userService.getUserEntity();

    this.ctrlSearchPR.valueChanges.subscribe(() => {
      this.handleFilterAndSortData()
    });


    this.organizationUiDtoSubscription$ = this.onboardingService.getOrganizationUiDto$.subscribe((data) => {
      if (data) {
        this.organizationUiDto = data;
        this.getAndLoadPurchaseInvoices();
      }
    });



    this.auctionTimeZoneSubscription$ = this.adminDashboardService.getMasterTimezones$.subscribe((data) => {
      if (data) {
        this.timezones = data;
      }
    });

  }



  getFormattedPrice(currency: Currency, price: any) {
    return currency.symbol + ApplicationUtils.getFormattedPrice(currency.locale, price);
  }

  getFileName(fileName: string) {
    let extensionName = fileName?.slice(fileName.lastIndexOf('.'), fileName.length)
    let name = fileName?.slice(0, fileName.lastIndexOf('.'));
    let finalFileName = ApplicationUtils.removeSpecialCharacters(name!) as string
    return finalFileName + "" + extensionName;
  }






  ngAfterViewInit(): void {
    this.attachPurchaseRequestChangeListener();
  }

  async getAndLoadPurchaseInvoices() {
    console.log(this.userEntityInfoDto);

    this.isDataLoading = true;
  
    this.adminDashboardService.getPurchaseInvoices(this.organizationUiDto?.orgCode!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isDataLoading = false;
          if (apiResponseDto.data) {
            let data = apiResponseDto.data as RfxPurchaseInvoiceDto[]
            this.allPurchaseInvoices = data;
            this.handleFilterAndSortData();
          }
        }
      },
      error: (error: any) => {
        console.error(error);
        this.isDataLoading = false;
      }
    })
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.ngbModal.dismissAll();
    }

    this.isSubmitted = false;
    this._validationErrorPresent$.next(false);
  }

  openApprovalWorkflowModal(purchaseInvoice: RfxPurchaseInvoiceDto, content: any){
    this.selectedPurchaseInvoiceDto = purchaseInvoice;
    this.ngbModal.open(content, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });

    this.getApprovalWorkflowUiDtos();
  }


  openFilterModal(content: any) {
    this.filterModalRef = this.ngbModal.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  toggleEditCategory() {
    this.selectedPreferenceCategory = undefined;
  }


  invalidPriceValidator(item: AbstractControl | null) {
    if (item?.errors?.['pattern'] || item?.errors?.['forbiddenMin']) {
      return true;
    }
    return false;
  }


  approvePurchaseInvoice() {
    this._showErrorToast$.next(false);
    this._errorMsg$.next('');

    this.isApprovalLoading = true;

    let appWorkflowUiDto = ApplicationUtils.clone(this.currentAppWorkflowUiDto) as AppWorkflowUiDto;
    appWorkflowUiDto.comment = this.ctrlComments.value;

    if (this.approveRequest == 'APPROVE') {
      appWorkflowUiDto.status = ApprovalWorkflowStatusEnum.APPROVED;
    } else {
      appWorkflowUiDto.status = ApprovalWorkflowStatusEnum.REJECTED;
    }

    this.adminDashboardService.updateApprovalWorkflowUiDtos(appWorkflowUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as AppWorkflowUiDto;

          Object.assign(this.currentAppWorkflowUiDto!, data);
          let index = this.appWorkflowUiDtoList.findIndex(item => item.emailId == this.userEntityInfoDto?.primaryEmailId);
          if (index > -1) {
            Object.assign(this.appWorkflowUiDtoList[index], data);
          }

          this._showSuccessToast$.next(true);
          this.isApprovalLoading = false;
          this._isSaveButtonEnable$.next(true);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.approvalModalRef);
          }, 2000);

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isApprovalLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next('Error while updating PR. Try again.');
        this.isApprovalLoading = false;
        this._isSaveButtonEnable$.next(true);
      },
    });
  }

  openPrApprovalConfirmModal(content: any, approveRequest: string) {
    this.approveRequest = approveRequest;
    this.approvalModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  isShowApprovedButton(index: number) {
    let sequence = this.currentAppWorkflowUiDto?.sequence ?? 0;
    if (sequence == 1 && index == 0) {
      return this.currentAppWorkflowUiDto?.status == 'PENDING';
    }

    if (sequence == 2 && index == 1) {
      let firstApproved = this.appWorkflowUiDtoList[0].status == 'APPROVED';
      return this.currentAppWorkflowUiDto?.status == 'PENDING' && firstApproved;
    }

    if (sequence == 3 && index == 2) {
      let firstApproved = this.appWorkflowUiDtoList[0].status == 'APPROVED';
      let secondApproved = this.appWorkflowUiDtoList[1].status == 'APPROVED';
      return this.currentAppWorkflowUiDto?.status == 'PENDING' && firstApproved && secondApproved;
    }

    return false;
  }

  async getApprovalWorkflowUiDtos() {
    this.isLoading = true;
    try {
      let apiResponseDto = await firstValueFrom(this.adminDashboardService.getApprovalWorkflowUiDtos(this.selectedPurchaseInvoiceDto?.invoiceId!, 'PURCHASE_INVOICE'));

      this.isLoading = false;

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.appWorkflowUiDtoList = apiResponseDto.data as AppWorkflowUiDto[];
        this.refreshApprovalWorkflowUi();
      } else {
        console.error("Error while calling getApprovalWorkflowUiDtos : " + JSON.stringify(apiResponseDto));
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  refreshApprovalWorkflowUi() {
    this.appWorkflowUiDtoList = this.appWorkflowUiDtoList.filter(item => item.entityId == this.selectedPurchaseInvoiceDto?.invoiceId);
    this.appWorkflowUiDtoList = this.appWorkflowUiDtoList.sort((a, b) => Number(a.sequence) - Number(b.sequence));
    this.currentAppWorkflowUiDto = this.appWorkflowUiDtoList.find(item => item.emailId == this.userEntityInfoDto?.primaryEmailId);
  }

  attachPurchaseRequestChangeListener() {
    let lastFetchTimestamp = Timestamp.now();

    logEvent(getAnalytics(), "attachPurchaseRequestChangeListener");

    let orgCode = this.onboardingService.getOrgCode();
    if (orgCode) {
      let auctionRef = collection(this.firestore, this.collectionPurchaseRequests);
      let auctionQuery = query(auctionRef,
        where('orgCode', '==', orgCode),
        where('orgCode', '==', orgCode),
        where('updateTimeUtc', '>', lastFetchTimestamp));

      this.prListenerUnsubscribe = onSnapshot(auctionQuery, documentSnapshots => {
        let purchaseRequestList = documentSnapshots.docChanges().map(change => change.doc.data() as PurchaseRequestUiDto);

        logEvent(getAnalytics(), 'attachPurchaseRequestChangeListener Data Received size : ' + purchaseRequestList.length);
        console.log("attachPurchaseRequestChangeListener Data Received size: " + purchaseRequestList.length);

        if (purchaseRequestList && purchaseRequestList.length > 0) {
          purchaseRequestList.forEach(updatedAuctionDto => {
            let timestamp = updatedAuctionDto.updateTimeUtc as Timestamp;
            updatedAuctionDto.updateTimeUtc = (timestamp.seconds * 1000);
          })

          this.adminSourcingEventsDataHolderService.updatePurchaseRequestsList(purchaseRequestList);
        }
      });
    }
  }

  formatPrStatus(status: string) {
    if (status == PurchaseRequestStatus.APPROVAL_PENDING) {
      return 'Approval Pending';
    } else if (status == PurchaseRequestStatus.APPROVAL_APPROVED) {
      return 'Approval Approved';
    } else if (status == PurchaseRequestStatus.COMPLETED) {
      return 'Completed';
    } else if (status == PurchaseRequestStatus.SUBMITTED) {
      return 'Submitted';
    }
    return ''
  }

  getApprovalWorkflowStatusClass(status?: ApprovalWorkflowStatusEnum) {
    if (status == ApprovalWorkflowStatusEnum.APPROVED) {
      return 'text-success';
    } else if (status == ApprovalWorkflowStatusEnum.REJECTED) {
      return 'text-danger';
    } else {
      return 'text-warning';
    }
  }

  changePageSize(size: number) {
    this.pageSize = size;
  }

  isFilterApplied() {
    // return this.selectedEventStatusFilter.length > 0 || this.selectedEventTypeFilter.length > 0;
    return false;
  }

  clearAllFilters() {
    // this.selectedEventStatusFilter = [];
    // this.selectedEventTypeFilter = [];
    this.ctrlSearchPR.patchValue('');

    this.ctrlSearchPR.reset();

    this.handleFilterAndSortData();
  }

  handleFilterAndSortData() {
    this.tempPurchaseInvoices = this.allPurchaseInvoices;

    // Do Filter
    this.doFilter();

    // Do Search
    this.doSearch();

    // Sorting
    // this.doSorting()

    this.doPaginate();

    if (this.filterModalRef) {
      this.filterModalRef.close();
    }
  }

  doFilter() {
    // if (this.selectedEventTypeFilter.length > 0) {
    //   this.tempPurchaseRequests = this.tempPurchaseRequests.filter(item => this.selectedEventTypeFilter.includes(item.type!));
    // }
    // if (this.selectedEventStatusFilter.length > 0) {
    //   this.tempPurchaseRequests = this.tempPurchaseRequests.filter(item => this.selectedEventStatusFilter.includes(item.status!));
    // }
  }

  doSearch() {
    if (this.ctrlSearchPR.value != null && this.ctrlSearchPR.value != '') {
      let searchValue = this.ctrlSearchPR.value.trim().toLowerCase();

      let tempAuctionList = this.tempPurchaseInvoices.filter(
        (item) => item.invoiceNumber?.toString().includes(searchValue)
      );

      this.tempPurchaseInvoices = tempAuctionList;
    }
  }


  doPaginate() {
    if (this.tempPurchaseInvoices && this.tempPurchaseInvoices.length > 0) {
      this.filteredInvoicesList$.next(this.tempPurchaseInvoices.map((history, i) => ({ id: i + 1, ...history })).slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize,
      ));
    } else {
      this.filteredInvoicesList$.next([]);
    }
  }

  getDisplayDate(date?: string, time?: string) {
    return ApplicationUtils.getSortDisplayDateAdmin(date, time);
  }

  getCreatedDate(timestamp?: string) {
    if (timestamp) {
      var stillUtc = moment.utc(timestamp).toDate();
      var localDate = moment(stillUtc).local()

      let date = localDate.toDate().toDateString();
      let time = localDate.toDate().toTimeString();
      return ApplicationUtils.getSortDisplayDateAdmin(date, time);
    }
    return '';
  }

  getMetadataDate(timestamp?: Timestamp | null) {
    if (timestamp) {
      let createdDate = timestamp.toDate();
      let date = createdDate.toDateString();
      let time = createdDate.toTimeString();
      return ApplicationUtils.getSortDisplayDateAdmin(date, time);
    }
    return '';
  }

  getTimeZone(dbTimeZone: string) {
    if (dbTimeZone && this.timezones.length > 0) {
      let utcCode = this.timezones.find((item) => item.locale == dbTimeZone)?.utcCode;
      return utcCode?.replaceAll('UTC', '');
    }
    return ''
  }

  ngOnDestroy(): void {
    if (this.plantsListSubscription$) {
      this.plantsListSubscription$.unsubscribe();
    }
  }
}
