<div class="container my-4" >
  <div class="col-12 text-end">
    
    <span  *ngIf="selectedCatalogueRequisitionWrapper && selectedCatalogueRequisitionWrapper.isApproved">
      <button class="btn btn-sm btn-dark-clr-class my-3 me-3" (click)="backDetails()">Back</button>
      <button class="btn btn-sm btn-primary-clr-class my-3" (click)="generatePdf()">PDF</button>
    </span>
  </div>

  <div class="col-12 p-2"
    *ngIf="!selectedCatalogueRequisitionWrapper || !selectedCatalogueRequisitionWrapper.isApproved">
    <div class="table-responsive">
      <table class="table table-bordered medium-font mb-0">
        <thead class="table-dark">
          <tr class="text-center">
            <th class=""><span>SNo</span></th>
            <th class=""><span>Category L1</span></th>
            <th class=""><span>Category L2</span></th>
            <th class="text-start"><span>Items</span></th>
            <th class=""><span>Total Cost</span></th>
            <th class=""><span>Action</span></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr *ngIf="isLoading" class="align-middle">
            <td colspan="9" class="text-center">
              <div class="e-spinner"></div>
            </td>
          </tr>

          <tr *ngIf="!isLoading && catalogueRequisitionWrappers!.length == 0">
            <td class="text-center" colspan="9">
              <h5 class="heading-new"> No Data Available</h5>
            </td>
          </tr>

          <tr class="text-center align-middle" *ngFor="let item of catalogueRequisitionWrappers; index as i">
            <td class="">
              {{i+1}}
            </td>
            <td class="">
              {{item.categoryL1Name}}
            </td>
            <td class="">
              {{item.categoryL2Name}}
            </td>
            <td class="text-start">
              {{item!.catalogueCartWrapperDtos![0].catalogueLineItemsEntityDto?.title}}

              <div *ngIf="item!.catalogueCartWrapperDtos!.length > 1">
                <a class="link-class ms-2" (click)="openAllItemsModal(allItems, item!)">
                  +{{item!.catalogueCartWrapperDtos!.length-1}} more items
                </a>
              </div>
            </td>
            <td class="">
              <b>{{item!.catalogueCartWrapperDtos![0].currency?.symbol}}{{item!.totalPrice}}</b>
            </td>
            <td class="">
              <button class="btn btn-sm btn-primary-clr-class-small" *ngIf="!item.isApproved"
                (click)="openArcApprovalModal(item, arcApprove)">Approve</button>
              <button class="btn btn-sm btn-primary-clr-class-small" *ngIf="item.isApproved" (click)="showDetails(item)">
                Create PO
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="col-12" id="pdfContent"
    *ngIf="selectedCatalogueRequisitionWrapper && selectedCatalogueRequisitionWrapper.isApproved">
    <div class="row align-items-center">
      <div class="col-6">
        <h4 class="heading-h4">
          Purchase Order
        </h4>
      </div>
      <div class="col-6 text-end">
        <img src="/downloadLandingBlob?fileId={{organizationUiDto?.logo?.fileId}}" [alt]="companyUiDto?.companyName"
        class="application-logo | img-fluid my-1 ms-md-5" />
      </div>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col-md-6 mb-3 ">
          <div class="card p-2 bg-white ro-height ">
            <div class=" row">
              <div class="col-12 text-center">
                <h5 class="heading-h5 mt-1">Buyer Details</h5>
              </div>

            </div>
            <hr class="hr my-2" />
            <div class="mb-2">
              <b>{{companyUiDto?.companyName}}</b>
            </div>
            <div class="div-class mb-2">
              {{billingAddress?.addressLine1}},
              {{billingAddress?.addressLine2 ? billingAddress?.addressLine2 + ',' : ''}}
              {{billingAddress?.city}},
            </div>
            <div class="div-class row mb-2">
              <div class=" col-5">State</div>
              <div class=" col-7">
                {{billingAddress?.state}}
              </div>
            </div>
            <div class="div-class row mb-2">
              <div class=" col-5">Pincode</div>
              <div class=" col-7">
                {{billingAddress?.zipCode}}
              </div>
            </div>
            <div class="div-class row mb-2">
              <div class=" col-5">Mobile No.</div>
              <div class=" col-7">
                {{companyUiDto?.phoneNumber}}
              </div>
            </div>
         
            <!-- <div class="div-class row mb-2">
              <div class=" col-5">Gst No.</div>
              <div class=" col-7">17AAGCA9080R1ZW</div>
            </div>
            <div class="div-class row mb-2">
              <div class=" col-5">PAN</div>
              <div class=" col-7">AAGCA9080R</div>
            </div> -->


          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="card p-2 bg-white ro-height ">
            <div class=" row">
              <div class="col-12 text-center">
                <h5 class="heading-h5 mt-1">Vendor Details</h5>
              </div>

            </div>
            <hr class="hr my-2" />

            <div class="mb-2">
              <b>{{selectedCatalogueRequisitionWrapper.catalogueCartWrapperDtos![0].catalogueVendor?.companyName}}</b> 
              <!-- <b>Indusuno Online Pvt Ltd</b> -->
            </div>
            <div class="div-class row mb-2">
              <div class=" col-5">Vendor Code</div>
              <div class=" col-7">100182</div>
            </div>
            <div class="div-class">
              Office 501, Al Thuraya Tower 1,
            </div>
            <div class="div-class row mb-2">
              <div class=" col-5">City</div>
              <div class=" col-7">Dubai Media City</div>
            </div>
            <div class="div-class row mb-2">
              <div class=" col-5">State</div>
              <div class=" col-7">Dubai</div>
            </div>
            <div class="div-class row mb-2">
              <div class=" col-5">Country</div>
              <div class=" col-7">United Arab Emirates</div>
            </div>
            <div class="div-class row mb-2">
              <div class=" col-5">Zip Code</div>
              <div class=" col-7">123456</div>
            </div>
            <!-- <div class="div-class row mb-2">
              <div class=" col-5">Gst No.</div>
              <div class=" col-7">07AADCI9220N1ZA</div>
            </div>
            <div class="div-class row mb-2">
              <div class=" col-5">PAN</div>
              <div class=" col-5">AADCI9220N1</div>
            </div> -->


          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div class="table-responsive">
        <table class="table table-bordered medium-font mb-0">
          <thead class="table-dark">
            <tr class="text-center">
              <th class="" colspan="4"><span>PO Details</span></th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr *ngIf="isLoading" class="align-middle">
              <td colspan="9" class="text-center">
                <div class="e-spinner"></div>
              </td>
            </tr>
            <tr class="text-center align-middle">
              <td class="">
                <b>PO Number</b>
              </td>
              <td class="">
                10012
              </td>

              <td class="">
                <b>PO Date</b>

              </td>
              <td class="">
                {{currentDate}}
              </td>
            </tr>
            <!-- <tr class="text-center align-middle">
              <td class="">
                <b>PO Date</b>

              </td>
              <td class="">
                {{currentDate}}
              </td>

              <td class="">
                <b>Enquiry Date</b>
              </td>
              <td class="">
                21/05/2024
              </td>
            </tr> -->

          </tbody>
        </table>
      </div>
      <div class="table-responsive mt-3">
        <table class="table table-bordered medium-font mb-0">
          <thead class="table-dark">
            <tr class="text-center">
              <th class=""><span>SNo</span></th>
              <th class=""><span>Item</span></th>
              <th class=""><span>Description</span></th>
              <th class=""><span>Amount</span></th>

            </tr>
          </thead>
          <tbody class="bg-white">
            <tr *ngIf="isLoading" class="align-middle">
              <td colspan="9" class="text-center">
                <div class="e-spinner"></div>
              </td>
            </tr>
            <tr class="text-center align-middle"
              *ngFor="let item of selectedCatalogueRequisitionWrapper?.catalogueCartWrapperDtos; index as i">
              <td class="">
                {{i+1}}
              </td>
              <td class="">
                {{item.catalogueLineItemsEntityDto?.title}}
              </td>
              <td class="">
                {{item.catalogueLineItemsEntityDto?.description}}
              </td>

              <td class="">
                {{item!.currency?.symbol}} {{item.quantity! * item.catalogueVendor!.amount!}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row justify-content-end">
        <div class="col-md-6 ">
          <div class="table-responsive mt-3">
            <table class="table table-bordered medium-font mb-0">
              <thead class="table-dark">
                <tr class="text-center">
                  <th class="" colspan="2">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr class="text-center align-middle">
                  <td class="">
                    <b>Total Amount</b>
                  </td>
                  <td>
                    <b>{{currency?.symbol}}{{selectedCatalogueRequisitionWrapper.totalPrice! -
                      selectedCatalogueRequisitionWrapper.totalTaxAmount!}}</b>

                  </td>
                </tr>
                <tr class="text-center align-middle">
                  <td class="">
                    <b>Tax</b>
                    @ {{catalogueVendor?.tax}}%
                  </td>
                  <td>
                    <b>{{currency?.symbol}}{{selectedCatalogueRequisitionWrapper.totalTaxAmount!}}</b>
                  </td>
                </tr>
                <tr class="text-center align-middle">
                  <td class="">
                    <b>Total Amount with Tax</b>
                  </td>
                  <td>
                    <b>{{currency?.symbol}}{{selectedCatalogueRequisitionWrapper.totalPrice!}}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #allItems>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>REQUISITION</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <table class="table table-bordered text-center medium-font">
          <thead class="font-weight-bold">
            <tr>
              <th scope="col" class="">#</th>
              <th scope="col" class="">Items</th>
              <th scope="col" class="">Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of selectedCatalogueRequisitionWrapper?.catalogueCartWrapperDtos; index as i">
              <th scope="row" class="">{{i+1}}</th>
              <td class="">{{item.catalogueLineItemsEntityDto?.title}}</td>
              <td class="">
                {{selectedCatalogueRequisitionWrapper?.catalogueCartWrapperDtos![0].currency?.symbol}}{{item.quantity! *
                item.catalogueVendor?.amount!}}</td>
            </tr>

          </tbody>
        </table>
        <div class="col-10 mx-auto">
          <div class="mt-2 row">
            <div class="col-5">
              <b>Total</b>
            </div>
            <div class="col-7 text-end">
              <b>{{selectedCatalogueRequisitionWrapper?.catalogueCartWrapperDtos![0].currency?.symbol}}{{selectedCatalogueRequisitionWrapper?.totalPrice}}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #arcApprove>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Requisition Approval</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p>Are you sure you want to approve</p>
        <div class="col-12 text-end mt-3">
          <button class="btn btn-sm btn-primary-clr-class" (click)="markToApprove()">OK</button>
        </div>
      </div>

    </div>
  </div>
</ng-template>