<div class="col-12 mt-4">
  <div class="row px-2">
    <div class="col-9 mb-2">
    </div>
    <div class="col-md-3 text-end">
      <div class="mb-2  input-group">
        <input #search placeholder="Search" type="text" id="search" class="form-control border-end-0 rounded-0 border bg-white" />
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="col-12 p-2">
  <div class="table-responsive">
    <table class="table table-bordered medium-font mb-0">
      <thead class="table-dark">
        <tr class="text-center">
          <th class=""><span>SNo</span></th>
          <th class=""><span>Category L1</span></th>
          <th class=""><span>Category L2</span></th>
          <th class="text-start"><span>Items</span></th>
          <th class=""><span>Total Cost</span></th>
          <th class=""><span>Action</span></th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr *ngIf="isLoading" class="align-middle">
          <td colspan="9" class="text-center">
            <div class="e-spinner"></div>
          </td>
        </tr>
        <tr class="text-center align-middle" *ngFor="let item of catalogueRequisitionWrappers; index as i">
          <td class="">
            {{i+1}}
          </td>
          <td class="">
            {{item.categoryL1Name}}
          </td>
          <td class="">
            {{item.categoryL2Name}}
          </td>
          <td class="text-start">
            {{item!.catalogueCartWrapperDtos![0].catalogueLineItemsEntityDto?.title}}

            <div *ngIf="item!.catalogueCartWrapperDtos!.length > 1">
              <a class="link-class ms-2" (click)="openAllItemsModal(allItems, item!)">
                +{{item!.catalogueCartWrapperDtos!.length-1}} more items
              </a>
            </div>
          </td>
          <td class="">
            <b>{{item!.catalogueCartWrapperDtos![0].currency?.symbol}}{{item?.totalPrice}}</b>
          </td>
          <td class="">
            <button class="btn btn-sm btn-primary-clr-class" (click)="openArcApprovalModal(item, arcApprove)"
              *ngIf="!item.isApproved">Approve</button>
            <button class="btn btn-sm btn-primary-clr-class" *ngIf="item.isApproved" (click)="navigateToApprovalPage()">
              Create PO
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #allItems>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>REQUISITION</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <table class="table table-bordered text-center medium-font">
          <thead class="font-weight-bold">
            <tr>
              <th scope="col" class="">#</th>
              <th scope="col" class="">Items</th>
              <th scope="col" class="">Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of selectedCatalogueRequisitionWrapper?.catalogueCartWrapperDtos; index as i">
              <th scope="row" class="">{{i+1}}</th>
              <td class="">{{item.catalogueLineItemsEntityDto?.title}}</td>
              <td class="">
                {{selectedCatalogueRequisitionWrapper?.catalogueCartWrapperDtos![0].currency?.symbol}}{{item.quantity! *
                item.catalogueVendor?.amount!}}</td>
            </tr>

          </tbody>
        </table>
        <div class="col-10 mx-auto">
          <div class="mt-2 row">
            <div class="col-5">
              <b>Total</b>
            </div>
            <div class="col-7 text-end">
              <b>{{selectedCatalogueRequisitionWrapper?.catalogueCartWrapperDtos![0].currency?.symbol}}{{selectedCatalogueRequisitionWrapper?.totalPrice}}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #arcApprove>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Requisition Approval</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p>Are you sure you want to approve</p>
        <div class="col-12 text-end mt-3">
          <button class="btn btn-sm btn-primary-clr-class" (click)="markToApprove()">OK</button>
        </div>
      </div>

    </div>
  </div>
</ng-template>