import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbModal, NgbModalRef, NgbPopover, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import { RankPriceConfigEnum } from 'src/app/shared/enums/RankPriceConfigEnum';
import { ShowBidderHistoryEnum } from 'src/app/shared/enums/ShowBidderHistoryEnum';
import { Currency } from 'src/app/shared/models/Currency';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { OrganizationUiDto } from 'src/app/shared/models/OrganizationUiDto';
import { ProjectUiDto } from 'src/app/shared/models/ProjectUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { PurchaseRequestUiDto } from 'src/app/shared/models/rfx/PurchaseRequestUiDto';
import { AdminDataUIWrapperDto } from 'src/app/shared/models/user/AdminDataUIWrapperDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { AuctionValidationErrorCodeDto } from 'src/app/shared/models/user/AuctionValidationErrorCodeDto';
import { DeploymentConfigurationDto } from 'src/app/shared/models/user/DeploymentConfigurationDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { FileService } from 'src/app/shared/services/file.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ServerDataFetchService } from 'src/app/shared/services/server-data-fetch.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { StringMinValidator } from 'src/app/shared/validators/string-min.directive';

@Component({
  selector: 'app-add-auction-modal',
  templateUrl: './add-auction-modal.component.html',
  styleUrls: ['./add-auction-modal.component.sass']
})
export class AddAuctionModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() projectUiDto?: ProjectUiDto;

  formGroup: FormGroup;
  isAllowToShowBidderHistoryConfig: boolean = false;

  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];
  startTime?: NgbTimeStruct;
  minEndDate?: NgbDateStruct;
  currencies: Array<Currency> = [];
  timezones: Array<any> = [];
  selectedCurrency?: Currency;
  organizationDto?: OrganizationUiDto;
  deploymentConfigurationDto?: DeploymentConfigurationDto;
  purchaseRequestUiDto?: PurchaseRequestUiDto;

  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  fileUploadError: boolean = false;
  errorMsg: string | undefined;
  currentFile?: File;
  isSubmitted: boolean = false;
  ngbPopover?: NgbPopover;
  isActiveImage: boolean = false;
  isLoading: boolean = false;

  currentFileInfoDto?: FileInfoDto;
  imageUrl?: string | ArrayBuffer | SafeUrl | null;
  _fileDisplayName$ = new BehaviorSubject<string | null>('');
  _fileUploaded$ = new BehaviorSubject<boolean>(false);

  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');
  isMultiLineError$ = new BehaviorSubject<boolean>(false);
  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);

  auctionTimeZoneSubscription$?: Subscription;
  organizationSubscription$?: Subscription;
  deploymentConfigurationDtoSubscription$?: Subscription;

  rankPriceConfigs: Array<any> = [
    RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION,
    RankPriceConfigEnum.SHOW_RANK_AND_CURRENT_PRICE_WITH_TRANSITION,
    RankPriceConfigEnum.SHOW_RANK_AND_NO_CURRENT_PRICE_WITH_TRANSITION
  ];

  minDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private ngbModal: NgbModal,
    private currencyService: CurrencyService,
    private adminDashboardService: AdminDashboardService,
    private serverDataFetchService: ServerDataFetchService,
    private dataRedirectionService: DashboardRedirectionService,
    private fileService: FileService,
    private userService: UserService,
    public onboardService: OnboardingService
  ) {
    this.formGroup = this.formBuilder.group({
      auctionName: new FormControl('', Validators.required),
      timeZone: new FormControl('', Validators.required),
      auctionType: new FormControl('', Validators.required),
      auctionBiddingMethod: new FormControl('FORWARD_AUCTION', Validators.required),
      currency: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      startTime: new FormControl('', Validators.required),
      bidderApproval: new FormControl('', Validators.required),
      rankCurrentPriceConfig: new FormControl('', Validators.required),
      premiumAuction: new FormControl(false),
      preOffer: new FormControl(false),
      maskBidderDetails: new FormControl(false),
      showBidderHistory: new FormControl(''),
      antiSnipe: new FormControl('', [Validators.min(1), Validators.max(15)]),
      auctionImage: new FormControl(null),
      enableBlockchain: new FormControl(false),
      allowEditWhileUpcoming: new FormControl(true),
      allowEditWhileRunning: new FormControl(true),

      region: new FormControl('', Validators.required),
      costCenters: new FormControl('', Validators.required),
      estSpend: new FormControl('', Validators.required),
      estSaving: new FormControl('', Validators.required),
    });
  }
  ngOnInit(): void {
    if (this.formGroup) {
      this.formGroup.reset();
      this.setDefaultValue();
      this.populateBidderHistoryConfig()

      this.selectedCurrency = new Currency();
      this.deleteImage();
    }

    this.currencies = this.currencyService.getCurrencies;

    this.auctionTimeZoneSubscription$ = this.adminDashboardService.getMasterTimezones$.subscribe((data) => {
      if (data) {
        this.timezones = data;
      }
    });

    this.startTime = ApplicationUtils.getNgbPickerTime(
      `${new Date().getHours()}:${new Date().getMinutes()}`
    );

    this.formGroup.controls['startDate'].valueChanges.subscribe((val) => {
      if (val) {
        this.checkStartTimeValidation();
      }
    });

    this.organizationSubscription$ = this.onboardService.getOrganizationUiDto$.subscribe((data) => {
      if (data) {
        this.organizationDto = data;
      }
    });

    this.deploymentConfigurationDtoSubscription$ = this.adminDashboardService.getDeploymentConfiguration$.subscribe((data) => {
      if (data) {
        this.deploymentConfigurationDto = data;
      }
    });
  }

  populateDataFromPurchaseRequest() {
    this.purchaseRequestUiDto = this.projectUiDto?.purchaseRequestUiDto!;
    this.formGroup.patchValue({
      auctionName: this.purchaseRequestUiDto?.title,
      companyName: this.purchaseRequestUiDto?.companyCode,
      costCenters: this.purchaseRequestUiDto?.costCenterCode,
    })
  }

  ngAfterViewInit(): void {
  }

  get fc(): any {
    return this.formGroup.controls;
  }

  setDefaultValue() {
    this.formGroup.controls['auctionBiddingMethod'].setValue(ApplicationConstants.FORWARD_AUCTION);
    this.formGroup.controls['preOffer'].setValue(false);
    this.formGroup.controls['maskBidderDetails'].setValue(false);
    this.formGroup.controls['showBidderHistory'].setValue(ShowBidderHistoryEnum.ALWAYS);
    this.formGroup.controls['rankCurrentPriceConfig'].setValue(RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION);
    this.formGroup.controls['enableBlockchain'].setValue(false);
    this.formGroup.controls['auctionType'].setValue('');
    this.formGroup.controls['auctionType'].enable();

    this.formGroup.controls['allowEditWhileUpcoming'].setValue(true);
    this.formGroup.controls['allowEditWhileRunning'].setValue(true);
  }

  onAuctionBiddingMethodChange() {
    let auctionBiddingMethod = this.formGroup.controls['auctionBiddingMethod'].value;
    if (auctionBiddingMethod == ApplicationConstants.REVERSE_AUCTION) {
      this.formGroup.controls['preOffer'].setValue(false);
      this.formGroup.controls['auctionType'].setValue('Subject to Confirmation');
      this.formGroup.controls['auctionType'].disable();
    } else {
      this.formGroup.controls['auctionType'].setValue('');
      this.formGroup.controls['auctionType'].enable();
    }
    this.formGroup.updateValueAndValidity()
  }

  openViewImageModal(imageUrl: any) {
    let modalRef = this.ngbModal.open(ImagePreviewComponent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.imageUrl = imageUrl;
  }

  changeRankConfig() {
    this.populateBidderHistoryConfig();
  }

  populateBidderHistoryConfig() {
    let rankCurrentPriceConfigValue = this.formGroup.controls['rankCurrentPriceConfig'].value
    if ((rankCurrentPriceConfigValue == RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION) || (rankCurrentPriceConfigValue == RankPriceConfigEnum.SHOW_RANK_AND_CURRENT_PRICE_WITH_TRANSITION)) {
      this.isAllowToShowBidderHistoryConfig = true;
      this.formGroup.controls['showBidderHistory'].setValue("ALWAYS");
    } else {
      this.isAllowToShowBidderHistoryConfig = false;
      this.formGroup.controls['showBidderHistory'].setValue("NEVER");
    }
    this.formGroup.controls['showBidderHistory'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.ngbModal.dismissAll();
    }

    this.isSubmitted = false;
    this._validationErrorPresent$.next(false);
  }

  // Currency
  onChangeCurrency(event: any) {
    let name = event.target.value;
    let currency = this.currencies.find((m) => m.name == name);
    this.selectedCurrency = currency;

    this.fc['estSpend'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.selectedCurrency?.regex!)]);
    this.fc['estSpend'].updateValueAndValidity();
    this.fc['estSaving'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.selectedCurrency?.regex!)]);
    this.fc['estSaving'].updateValueAndValidity();

    this.formatEstSpendValue();
    this.formatEstSavingValue();
  }

  deleteImage() {
    this.currentFileInfoDto = null!;
    this.currentFile = null!;
    this.imageUrl = null!;
    this.formGroup.controls['auctionImage'].reset();
    this._fileDisplayName$.next(null);
    this._fileUploaded$.next(false);
  }

  activeImage() {
    this.isActiveImage = !this.isActiveImage;
  }

  invalidPriceValidator(item: AbstractControl | null) {
    if (item?.errors?.['pattern'] || item?.errors?.['forbiddenMin']) {
      return true;
    }
    return false;
  }

  formatEstSpendValue() {
    if (this.selectedCurrency) {
      let estSpendValue = this.formGroup.controls['estSpend'].value;
      let estSpendValueFinal = ApplicationUtils.getFormattedPrice(this.selectedCurrency?.locale!, estSpendValue);
      this.fc['estSpend'].patchValue(estSpendValueFinal);
    }
  }

  formatEstSavingValue() {
    if (this.selectedCurrency) {
      let estSavingValue = this.formGroup.controls['estSaving'].value;
      let estSavingValueFinal = ApplicationUtils.getFormattedPrice(this.selectedCurrency?.locale!, estSavingValue);
      this.fc['estSaving'].patchValue(estSavingValueFinal);
    }
  }

  openTimepicker(p: NgbPopover, template: any) {
    if (this.ngbPopover?.isOpen) this.ngbPopover.close();

    if (!p.isOpen()) {
      this.ngbPopover = p;
      p.ngbPopover = template;
      p.open();
    } else {
      p.close();
    }
  }

  setTime(time: string) {
    if (time == 'startTime') {
      if (
        this.startTime?.hour != null &&
        this.startTime?.minute != null &&
        this.startTime?.second != null
      ) {
        let convertedTime = ApplicationUtils.getTimeFromNgTimePicker(
          this.datePipe,
          this.startTime
        );
        this.formGroup.controls?.['startTime'].patchValue(convertedTime);
      }
    }
    if (this.ngbPopover?.isOpen()) this.ngbPopover?.close();
    this.formGroup.updateValueAndValidity();
    this.checkStartTimeValidation();
  }

  mergeAuctionEntityDto(formValue: any) {
    let userEntityInfoDto = this.userService.getUserEntity();

    let auctionEntityDto = new AuctionEntityDto();
    auctionEntityDto.auctionId = UUID.UUID().toString();
    auctionEntityDto.parentId = auctionEntityDto.auctionId;

    auctionEntityDto.singleLot = true;
    auctionEntityDto.auctionName = formValue.auctionName;
    auctionEntityDto.orgCode = userEntityInfoDto?.orgCode;
    auctionEntityDto.projectCode = this.projectUiDto?.projectCode;
    auctionEntityDto.companyId = userEntityInfoDto.companyCode;

    auctionEntityDto.timeZone = formValue.timeZone;
    auctionEntityDto.auctionType = formValue.auctionType;
    auctionEntityDto.auctionBiddingMethod = formValue.auctionBiddingMethod;
    auctionEntityDto.currency = this.selectedCurrency;
    auctionEntityDto.antiSnipe = formValue.antiSnipe;
    auctionEntityDto.startDate = formValue.startDate;
    auctionEntityDto.bidderApproval = formValue.bidderApproval;
    auctionEntityDto.rankCurrentPriceConfig = formValue.rankCurrentPriceConfig;
    auctionEntityDto.premiumAuction = formValue.premiumAuction;
    auctionEntityDto.buyItNowEnable = formValue.preOffer;
    auctionEntityDto.maskBidderDetails = formValue.maskBidderDetails;
    auctionEntityDto.showBidderHistory = formValue.showBidderHistory;
    auctionEntityDto.enableBlockchain = formValue.enableBlockchain;

    auctionEntityDto.allowEditWhileUpcoming = formValue.allowEditWhileUpcoming;
    auctionEntityDto.allowEditWhileRunning = formValue.allowEditWhileRunning;

    auctionEntityDto.startTime = ApplicationUtils.convertTime12to24(formValue.startTime);
    auctionEntityDto.domainName = this.organizationDto?.adminDomainName;
    auctionEntityDto.orgCode = this.organizationDto?.orgCode;
    auctionEntityDto.auctionHouseName = this.organizationDto?.name;
    auctionEntityDto!.auctionImage = this.currentFileInfoDto;

    auctionEntityDto!.region = formValue.region;
    auctionEntityDto!.costCenters = formValue.costCenters;
    auctionEntityDto!.estSpend = ApplicationUtils.resetPriceFormat(formValue.estSpend).toString();
    auctionEntityDto!.estSaving = ApplicationUtils.resetPriceFormat(formValue.estSaving).toString();

    return auctionEntityDto;
  }

  checkStartTimeValidation() {
    if (
      this.formGroup.controls['startDate'].value &&
      this.formGroup.controls['startTime'].value
    ) {
      let startDate = ApplicationUtils.getDateFromNgDatePicker(
        this.datePipe,
        this.formGroup.controls['startDate'].value
      );
      let startTime = ApplicationUtils.convertTime12to24(
        this.formGroup.controls['startTime'].value
      );

      if (startDate && startTime) {
        let _sDate = ApplicationUtils.convertedDate(startDate, startTime);

        if (_sDate && _sDate < new Date()) {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(
            'AUCTION START DATE/TIME MUST BE AFTER CURRENT TIME'
          );
          return true;
        } else {
          this._showErrorToast$.next(false);
          this._errorMsg$.next('');
        }
      }
      return false;
    }
    return true;
  }

  chooseFile(event: any) {
    this._showErrorToast$.next(false);
    this.currentFile = event.target.files[0];

    if (!this.imageTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = 'Extension not supported';
      this._showErrorToast$.next(true);
      return;
    }

    this._fileDisplayName$.next(this.currentFile?.name as string);
    this._fileUploaded$.next(true);
    this.formGroup.get('auctionImage')?.setValue(this.currentFile?.name);
    let reader = new FileReader();
    reader.readAsDataURL(this.currentFile!);
    reader.onload = () => {
      this.imageUrl = reader.result?.toString();
    };
  }

  handleSubmit() {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    if (!this._fileDisplayName$.value) {
      return;
    }

    if (this.checkStartTimeValidation()) {
      return;
    }

    this.auctionValidationErrorCodeDtoList = [];
    this._showErrorToast$.next(false);
    this._errorMsg$.next('');
    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    if (this.currentFile) {
      this.uploadFile(this.currentFile!);
    } else {
      this.addAuction();
    }
  }

  uploadFile(currentFile: File) {
    this.isLoading = true;
    this.fileUploadError = false;
    this.errorMsg = '';

    let metaData = {
      mimeType: currentFile.type,
      version: 0,
      publicApi: true,
      dataType: 'Square',
    };

    let formData = new FormData();
    formData.append('file', currentFile);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe((apiResponseDto) => {
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;

          this.currentFileInfoDto = new FileInfoDto();
          this.currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
          this.currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.fileSize = currentFile.size.toString();
          this.currentFileInfoDto.fileType = currentFile.type;
          this.currentFileInfoDto.uploadDate = new Date().toDateString();

          this.formGroup
            .get('auctionImage')
            ?.setValue(this.currentFileInfoDto.displayName);
          this.formGroup.updateValueAndValidity();
          this.addAuction();
        }
      } else {
        this.isLoading = false;
        this.fileUploadError = true;
      }
    });
  }

  addAuction() {
    this._validationErrorPresent$.next(false);
    this._showErrorToast$.next(false);
    this.isMultiLineError$.next(false);
    this._errorMsg$.next('');

    let formValue = this.formGroup.getRawValue();

    if (this.formGroup.invalid) {
      this._validationErrorPresent$.next(true);
      return;
    }
    if (typeof formValue.startDate != 'object') {
      return;
    }

    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    let startDate = ApplicationUtils.getDateFromNgDatePicker(
      this.datePipe,
      formValue.startDate
    );

    formValue.startDate = startDate;

    let auctionEntityDto = this.mergeAuctionEntityDto(formValue);

    this.adminDashboardService.saveInitialAuctionDetails(auctionEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as AuctionEntityDto;
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000);

          this.navigateToCreatorDashboard(data.auctionId!);

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next('Error while saving Auction. Try again.');
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      },
    });
  }

  async navigateToCreatorDashboard(eventId: string) {
    await this.serverDataFetchService.loadAuctionForAdminSync(eventId);
    await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(eventId);

    this.dataRedirectionService.storeNavigationContext(
      ApplicationConstants.ADMIN,
      ApplicationConstants.AUCTION_CREATOR_PAGE,
      ApplicationConstants.AUCTION_TAB
    );
    this.dataRedirectionService.setUpdateBreadcrumbs(true);
  }

  getRankPriceConfigName(rankPriceConfig: string) {
    let toReturn = "";
    if (RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION == rankPriceConfig) {
      toReturn = "Show Market Price Only"
    } else if (RankPriceConfigEnum.SHOW_RANK_AND_CURRENT_PRICE_WITH_TRANSITION == rankPriceConfig) {
      toReturn = "Show Rank & Market Price"
    } else if (RankPriceConfigEnum.SHOW_RANK_AND_NO_CURRENT_PRICE_WITH_TRANSITION == rankPriceConfig) {
      toReturn = "Show Rank Only"
    }
    return toReturn;
  }

  checkAntiSnipeInputIsInteger() {
    if (this.formGroup.controls['antiSnipe'].value) {
      let val = this.formGroup.controls['antiSnipe'].value;
      let updateVal = Math.floor(val);
      if (updateVal) {
        this.formGroup.controls['antiSnipe'].patchValue(updateVal);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.auctionTimeZoneSubscription$) {
      this.auctionTimeZoneSubscription$.unsubscribe();
    }
    if (this.organizationSubscription$) {
      this.organizationSubscription$.unsubscribe();
    }

    if (this.deploymentConfigurationDtoSubscription$) {
      this.deploymentConfigurationDtoSubscription$.unsubscribe();
    }
  }
}
