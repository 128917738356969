<div class="border rounded bg-white" [ngClass]="{'border-danger border-2': haveRfxSubcategoryErrors()}">
  <div class="">
    <header class="p-2">
      <div class="row ">
        <div class="col-md-6">
          <h5 class="heading-h5"> META DATA</h5>
        </div>
        <div class="col-md-6 text-md-end">
          <button class="btn btn-new-class btn-sm px-4 " (click)="openMetaDataModal(subcategoryMetaDataModal)" [disabled]="!isAllowToEdit()">
            <!-- <img src="assets/icons/edit_dark.svg" class=" c-pointer svg-icon-class"> -->
            EDIT
          </button>
        </div>
      </div>
    </header>
    <hr class="hr" />
    <div class="col-12 div-class py-2 px-3">
      Additional Information
    </div>

    <div class="row px-3">
      <hr class="hr" />
      <div class="col-4 font-weight-normal py-2"
        [ngClass]="{'text-danger':!rfxSubcategoryEntityDto?.preferenceCategory}">
        Category
      </div>
      <div class="col-8 div-class py-2">
        {{rfxSubcategoryEntityDto?.preferenceCategory?.categoryName}}
      </div>
      <hr class="hr" />
      <div class="col-4 font-weight-normal py-2"
        [ngClass]="{'text-danger':!rfxSubcategoryEntityDto?.preferenceCategory}">
        Sub Category 1
      </div>
      <div class="col-8 div-class py-2">
        {{rfxSubcategoryEntityDto?.preferenceCategory?.categoryLevelOneName}}
      </div>
      <hr class="hr" />
      <div class="col-4 font-weight-normal py-2"
        [ngClass]="{'text-danger':!rfxSubcategoryEntityDto?.preferenceCategory}">
        Sub Category 2
      </div>
      <div class="col-8 div-class py-2">
        {{rfxSubcategoryEntityDto?.preferenceCategory?.categoryLevelTwoName}} - {{rfxSubcategoryEntityDto?.preferenceCategory?.categoryLevelThreeName}}
      </div>
      <hr class="hr" />
      <div class="col-4 font-weight-normal py-2"
        [ngClass]="{'text-danger':!rfxSubcategoryEntityDto?.preferenceCategory}">
        Sub Category 3
      </div>
      <div class="col-8 div-class py-2">
        {{rfxSubcategoryEntityDto?.preferenceCategory?.categoryLevelFourName}}
      </div>
      <hr class="hr" />
      <div class="col-4 font-weight-normal py-2"
        [ngClass]="{'text-danger':!rfxSubcategoryEntityDto?.uom}">
        Uom
      </div>
      <div class="col-8 div-class py-2">
        {{rfxSubcategoryEntityDto?.uom}}
      </div>
      <hr class="hr" />
      <div class="col-4 font-weight-normal py-2"
        [ngClass]="{'text-danger':!rfxSubcategoryEntityDto?.qty}">
        Qty
      </div>
      <div class="col-8 div-class py-2">
        {{rfxSubcategoryEntityDto?.qty}}
      </div>
    </div>
  </div>
</div>

<ng-template #subcategoryMetaDataModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>META DATA</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup">
          
          <app-categories-tree-view [selectionMode]="TreeSelectionMode.single"
            (onSelectedCategory)="onSelectedCategory($event)"></app-categories-tree-view>
            <div class="input-group mt-3">
              <div class="form-floating">
                <input name="uom" type="text" placeholder="Uom" formControlName="uom"
                  class="form-control"  id="uom"/>
                <label for="uom">Uom</label>
              </div>
            </div>
            <div class="input-group mt-3">
              <div class="form-floating">
                <input name="qty" type="number" placeholder="Qty" formControlName="qty"
                  class="form-control"  id="qty"/>
                <label for="qty">Quantity</label>
              </div>
            </div>
          <div class="my-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async"
              [message]="'Event saved successfully.'"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="_errorMsg$ |async"></app-message-toast>
          </div>
          <div class="mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>
          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class" [disabled]="!(this._isSaveButtonEnable$ | async) && !isAllowToEdit()"
              (click)="handleValidSubmit()">SAVE</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
