import { CountryCodeDto } from "./CountryCodeDto";
import { Currency } from "./Currency";
import { FileInfoDto } from "./FileInfoDto";
import { PrivacyAndTermsOfUseDto } from "./PrivacyAndTermsOfUseDto";

export class OrganizationUiDto {
  name?: string;
  corporateUrl?: string;
  orgCode?: string;
  description?: string;
  adminDomainName?: string;
  bidderDomainName?: string;
  logo?: FileInfoDto;
  active?: boolean;
  bannerImage?: FileInfoDto;
  supportEmail?: string;
  phoneNo?: string;
  countryCode?: CountryCodeDto;
  currencyDto?: Currency
  primaryColor?: string;
  secondaryColor?: string;
  privacyAndTermsOfUseList?: Array<PrivacyAndTermsOfUseDto>;
  
  demoAccount?: boolean
}