import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { ColorValidators } from 'src/app/shared/validators/color.validators';
import { OrganizationUiDto } from 'src/app/shared/models/OrganizationUiDto';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';

@Component({
  selector: 'app-color-configuration',
  templateUrl: './color-configuration.component.html',
  styleUrls: ['./color-configuration.component.sass']
})
export class ColorConfigurationComponent implements OnInit, OnDestroy {
  formGroup: FormGroup

  organizationUiDto?: OrganizationUiDto;


  isLoading?: boolean;

  _errorMsg$ = new BehaviorSubject<string>("");
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);

  organizationUiDtoSubscription$?: Subscription;

  primaryColor?: string
  secondaryColor?: string

  constructor(
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
  ) {
    this.formGroup = this.fb.group({
      primaryColor: ['', [Validators.required, ColorValidators()]],
      secondaryColor: ['', [Validators.required, ColorValidators()]],
 
    })
  }

  ngOnInit(): void {
    this.onboardingService.getOrganizationUiDto$.subscribe(data => {
      if (data) {
        this.organizationUiDto = data;
        this.populateData();
      }
    })
  }

  get fc(): any {return this.formGroup.controls;}

  openColorConfigurationModal(content: any) {
    this.formGroup.reset();
    this.populateData();
    
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  populateData() {
    this.primaryColor = this.organizationUiDto?.primaryColor
    this.secondaryColor = this.organizationUiDto?.secondaryColor

    this.formGroup.patchValue(this.organizationUiDto!);
    this.formGroup.updateValueAndValidity();
  }

  handleValidSubmit() {
    this._showErrorToast$.next(false);

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched()
      return;
    }

    this.isLoading = true;
    let formValue = this.formGroup.value;

    let organizationUiDto: OrganizationUiDto = ApplicationUtils.clone(this.organizationUiDto);
    organizationUiDto.primaryColor = formValue.primaryColor;
    organizationUiDto.secondaryColor = formValue.secondaryColor;
  

    this.onboardingService.saveOrganizationUiDto(organizationUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          let newOrganizationUiDto = apiResponseDto.data as OrganizationUiDto;
          this.onboardingService.updateOrganizationUiDto(newOrganizationUiDto)
          
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto?.message ?? "Error while saving data. Try again.");
        }
      },
      error: (error) => {
        console.error(error);

        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving data. Try again.");
      }
    })
  }

  changeColor(selectedColor: any, colorType: string) {
    if (colorType == 'PRIMARY') {
      this.formGroup.controls['primaryColor'].patchValue(selectedColor);
    } else {
      this.formGroup.controls['secondaryColor'].patchValue(selectedColor);
    }
    this.formGroup.updateValueAndValidity()
  }
  
  ngOnDestroy(): void {
    if (this.organizationUiDtoSubscription$) {
      this.organizationUiDtoSubscription$.unsubscribe();
    }
  }
}
