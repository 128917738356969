import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ChangePasswordDto } from 'src/app/shared/models/ChangePasswordDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';
import { UserEntityInfoDto } from 'src/app/shared/models/user/UserEntityInfoDto';
@Component({
  selector: 'app-user-password-first-time',
  templateUrl: './user-password-first-time.component.html',
  styleUrls: ['./user-password-first-time.component.sass']
})
export class UserPasswordFirstTimeComponent {
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _isPasswordMatch$ = new BehaviorSubject<boolean>(false);


  @ViewChild('oldPassword') oldPassword?: ElementRef<HTMLInputElement>
  @ViewChild('newPassword') newPassword?: ElementRef<HTMLInputElement>
  @ViewChild('repeatPassword') repeatPassword?: ElementRef<HTMLInputElement>

  errorMsg?: string;
  isLoading: boolean = false;
  isOldPasswordVisible: boolean = false;
  isNewPasswordVisible: boolean = false;
  isRepeatPasswordVisible: boolean = false;
  isSubmitted: boolean = false;
  _isOldPasswordFocused$ = new BehaviorSubject<boolean>(false);
  _isNewPasswordFocused$ = new BehaviorSubject<boolean>(false);
  _isRepeatPasswordFocused$ = new BehaviorSubject<boolean>(false);

  formGroup = this.formBuilder.group({
    oldPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(9), PasswordStrengthValidator]),
    repeatPassword: new FormControl('', Validators.required)
  });

  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    private userService: UserService,
    private authService: AuthenticationService,
    private routingService: RoutingService
  ) { }

  get fc(): any { return this.formGroup.controls; }

  ngOnInit() {
    this.formGroup.controls['newPassword'].valueChanges.subscribe(data => {
      this._showErrorToast$.next(false);
    })

    this.formGroup.controls['repeatPassword'].valueChanges.subscribe(data => {
      this._showErrorToast$.next(false);
    })
  }

  toggleOldPasswordVisibility() {
    this.isOldPasswordVisible = !this.isOldPasswordVisible;
  }

  toggleNewPasswordVisibility() {
    this.isNewPasswordVisible = !this.isNewPasswordVisible;
  }

  toggleRepeatPasswordVisibility() {
    this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
  }

  onChange() {
    this.authService.errorMsg = null!;
    this._showErrorToast$.next(false);
    
    if (this.formGroup.controls['newPassword'].value == this.formGroup.controls['repeatPassword'].value) {
      this._isPasswordMatch$.next(true);
    } else {
      this._isPasswordMatch$.next(false);
    }
  }

  changePassword() {
    this._showErrorToast$.next(false);
    this.isSubmitted = true;

    if (this.formGroup.invalid || this._isPasswordMatch$.value == false) {
      return;
    }
    if (this.formGroup.controls['oldPassword'].value == this.formGroup.controls['newPassword'].value) {
      this.errorMsg = "Old Password is Same as New Password";
      this._showErrorToast$.next(true);
      return;
    }
    if (this.formGroup.controls['newPassword'].value != this.formGroup.controls['repeatPassword'].value) {
      this.errorMsg = "Password Mismatch";
      this._showErrorToast$.next(true);
      return;
    }
    this.isLoading = true;
    let changePasswordDto = new ChangePasswordDto();
    changePasswordDto.userName = this.userService.getUserEntity().name;
    changePasswordDto.emailId = this.userService.getUserEntity().primaryEmailId;
    changePasswordDto.oldPassword = this.formGroup.controls['oldPassword'].value!;
    changePasswordDto.newPassword = this.formGroup.controls['newPassword'].value!;

    this.userService.changePassword(changePasswordDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isLoading = false;
          this.userService.setUserDto(apiResponseDto.data as UserEntityInfoDto);
          this._showSuccessToast$.next(true);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();

            this.routingService.doRouting(this.userService.getSessionInfo!);
            // this.dataRedirection.handleDashboardAppNavigation();
          }, 2000)

        } else {
          this.isLoading = false;
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);

        }
      },
      error: (err: any) => {
        this.isLoading = false;
        this.errorMsg = "Error while changing password";
        this._showErrorToast$.next(true);

        // this.toasterService.openErrorToster("Error while changing user password");
      }
    })
  }

  closeModalAndLogout() {
    this.authService.logout();
    this.modalService.dismissAll();
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  ngAfterViewInit(): void {
    this.oldPassword!.nativeElement.onfocus = (e) => {
      this._isOldPasswordFocused$.next(true);
    }
    this.oldPassword!.nativeElement.onblur = (e) => {
      this._isOldPasswordFocused$.next(false);
    }
    this.newPassword!.nativeElement.onfocus = (e) => {
      this._isNewPasswordFocused$.next(true);
    }
    this.newPassword!.nativeElement.onblur = (e) => {
      this._isNewPasswordFocused$.next(false);
    }
    this.repeatPassword!.nativeElement.onfocus = (e) => {
      this._isOldPasswordFocused$.next(true);
    }
    this.oldPassword!.nativeElement.onblur = (e) => {
      this._isRepeatPasswordFocused$.next(false);
    }
  }
  

}
