import { LandingService } from 'src/app/shared/services/LandingAndBidder.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import { LoaderComponent } from '../components/loader/loader.component';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { AdminUserPrivilege } from '../enums/AdminUserPrivilege';
import { SessionInfoDto } from '../models/SessionInfoDto';
import { ErrorService } from './error.service';
import { NavigationService } from './navigation.service';
import { RoutingService } from './routing.service';
import { UserService } from './user.service';
import { DashboardRedirectionService } from './dashboard-redirection.service';
import { UserPasswordFirstTimeComponent } from '../components/user/user-password-first-time/user-password-first-time.component';
import { ApplicationListenerService } from './application-listener.service';
import { ApplicationUtils } from '../util/ApplicationUtils';
import { ApplicationStartupCacheService } from './application-startup-cache.service';
import { UserEntityInfoDto } from '../models/user/UserEntityInfoDto';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  loginModal$ = new BehaviorSubject(false);
  errorMsg: string = "";

  appLoader?: LoaderComponent

  constructor(
    private landingService: LandingService,
    private dataRedirection: DashboardRedirectionService,
    private httpClient: HttpClient,
    private userService: UserService,
    private modalService: NgbModal,
    private router: Router,
    private errorService: ErrorService,
    private navigationService: NavigationService,
    private routingService: RoutingService,
    private applicationListenerService: ApplicationListenerService,
    private applicationStartupCacheService: ApplicationStartupCacheService
  ) {


  }

  isUserLoggedIn() {
    let user = localStorage.getItem('username')
    return !(user === null)
  }

  async logout(appLoader?: LoaderComponent) {
    let email = this.userService.getUserEntity().primaryEmailId;
    await firstValueFrom(this.userService.logout(email));
    
    localStorage.clear();
    this.modalService.dismissAll();
    this.errorService.setResetDialogFlag();
    this.userService.clear();
    this.dataRedirection.clearSessionCookies();
    appLoader?.closeLoaderForcefully();

    // DOMAIN CHANGES
    //this.router.navigate([AuctionwareUtil.getBaseUrl()]);
    this.router.navigate(["/"]);
  }

  handleServerResponse(data: any, _isSaveButtonEnable$: BehaviorSubject<boolean>) {

    let sessionInfo = data.sessionInfoDto as SessionInfoDto;
    let currentUser: UserEntityInfoDto = sessionInfo.userEntityInfoDto!;
    _isSaveButtonEnable$.next(false);
    this.navigationService.emitChange(currentUser);
    this.userService.setUser(sessionInfo);
    this.modalService.dismissAll();

    // Initialize Firestore Listeners
    this.initializeListenerAndDoRouting(sessionInfo);
  }

  public initializeListenerAndDoRouting(sessionInfo: SessionInfoDto) {
    let currentUser: UserEntityInfoDto = sessionInfo.userEntityInfoDto!;

    this.applicationStartupCacheService.loadApplicationStartupDto();

    if (ApplicationUtils.checkIfRoleIsAssigned(currentUser?.userPrivileges!, AdminUserPrivilege.SUPER_ADMIN)) {
    } else {
      this.applicationListenerService.loadAndListenBidderRegistrationDataOfAuctionHouse();
      this.applicationListenerService.loadApplicationDataAfterLogin();
    }

    if (sessionInfo?.userEntityInfoDto!.forcePasswordChange) {
      this.appLoader?.closeLoaderForcefully();
      this.modalService.open(UserPasswordFirstTimeComponent, {
        backdrop: 'static', keyboard: false, centered: true
      });
    } else {
      this.routingService.doRouting(this.userService.getSessionInfo!);
    }
  }

  authenticate(username?: string, password?: string, otpId?: string): Observable<any> {
    //const headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(encodeURIComponent(username!) + ':' + password) });

    if (!otpId) {
      otpId = "";
    }

    let headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(username + ':' + password + ':' + otpId) });
    headers.append('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post<any>('authenticate', null, { headers, observe: 'response' }).pipe(
      map(
        apiResponseDto => {
          if (apiResponseDto.body.code == ApplicationConstants.SUCCESS_CODE) {
            // session info only when authentication is successful.
            let sessionInfo = <SessionInfoDto>apiResponseDto.body.data.sessionInfoDto;
            this.userService.setUser(sessionInfo);
            localStorage.setItem('AUC_SESSION_ID', apiResponseDto.body.data.sessionID);
          }
          return apiResponseDto.body;
        }
      )
    )
  }

  doAuthentication(
    userName: string,
    password: string,
    appLoader: LoaderComponent,
    _isSaveButtonEnable$: BehaviorSubject<boolean>,
    otpId?: string
  ) {
    this.appLoader = appLoader;
    this.authenticate(userName, password, otpId).subscribe({
      next: (apiResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.handleServerResponse(apiResponseDto.data, _isSaveButtonEnable$);
        } else {
          localStorage.clear();
          //this.notificationService.notifyDataLoading(ApplicationUtils.getNotificationEvent(false));
          appLoader.closeLoaderForcefully();
          _isSaveButtonEnable$.next(false)
          this.userService.clear();
          if (apiResponseDto.code == "USER-107") {
            this.errorMsg = "Email Id Not Registered";
          } else if (apiResponseDto.code == "USER-142") {
            this.errorMsg = "Account Disabled";
          }
          else if (apiResponseDto.code == "USER-144") {
            this.errorMsg = "You are not authorized to login";
          } else {
            this.errorMsg = "Wrong Credentials";
          }

        }
      },
      error: (error) => {
        _isSaveButtonEnable$.next(false)
        appLoader.closeLoaderForcefully();
        this.errorMsg = "Wrong Credentials";
        localStorage.clear();
      }
    });
  }
}
