import { Injectable } from '@angular/core';
import { OrganizationUiDto } from '../models/OrganizationUiDto';
import { ErrorService } from './error.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, firstValueFrom, tap } from 'rxjs';
import { ApplicationConstants } from '../util/ApplicationConstants';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { UserService } from './user.service';
import { UserEntityInfoDto } from '../models/user/UserEntityInfoDto';
import { CompanyUiDto } from '../models/CompanyUiDto';
import { PlantUiDto } from '../models/PlantUiDto';
import { OnboardingCountry } from '../models/OnboardingCountry';
import { OnboardingWorkflow } from '../models/OnboardingWorkflow';
import { AWFDefineUiDto } from '../models/approval-workflow/AWFDefineUiDto';
import { ApprovalWorkflowUiDto } from '../models/approval-workflow/ApprovalWorkflowUiDto';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private organizationUiDto?: OrganizationUiDto;
  private companyUiDtos: CompanyUiDto[] = [];
  private plantUiDtos: PlantUiDto[] = [];
  private userEntityInfoDtos: UserEntityInfoDto[] = [];
  private approvalWorkflowUiDtos: ApprovalWorkflowUiDto[] = [];

  private _organizationUiDto$ = new BehaviorSubject<OrganizationUiDto | undefined>(undefined);
  private _plantUiDtos$ = new BehaviorSubject<PlantUiDto[]>([]);
  private _userEntityInfoDtos$ = new BehaviorSubject<UserEntityInfoDto[]>([]);
  private _companyUiDtos$ = new BehaviorSubject<CompanyUiDto[]>([]);
  private _selectedCompanyUiDto$ = new BehaviorSubject<CompanyUiDto | undefined>(undefined);
  private _approvalWorkflowUiDtos$ = new BehaviorSubject<ApprovalWorkflowUiDto[]>([]);

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private errorService: ErrorService
  ) { }

  get getOrganizationUiDto() { return this.organizationUiDto; }
  get getOrganizationUiDto$() { return this._organizationUiDto$.asObservable(); }
  get getCompanyUiDtos() { return this.companyUiDtos; }
  get getCompanyUiDtos$() { return this._companyUiDtos$.asObservable(); }
  get getSelectedCompanyUiDto$() { return this._selectedCompanyUiDto$.asObservable(); };
  get getPlantUiDtos() { return this.plantUiDtos; }
  get getPlantUiDtos$() { return this._plantUiDtos$.asObservable(); }
  get getUserEntityInfoDtos() { return this.userEntityInfoDtos; }
  get getUserEntityInfoDtos$() { return this._userEntityInfoDtos$.asObservable(); }
  get getApprovalWorkflowUiDtos$() { return this._approvalWorkflowUiDtos$.asObservable(); }
  get getOnboardingCountries() { return countries; };
  get getOnboardingWorkflows() { return workflows; };


  getCompanyName(companyCode: string) {
    if (this.companyUiDtos && this.companyUiDtos.length > 0) {
      let filterCompany = this.companyUiDtos.filter(item => item.companyCode == companyCode);
      if (filterCompany) {
        return filterCompany[0].companyName;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  getOrgCode() {
    if (this.organizationUiDto) {
      return this.organizationUiDto.orgCode;
    } else {
      return "";
    }
  }

  updateOrganizationUiDto(organizationUiDto: OrganizationUiDto) {
    this.organizationUiDto = organizationUiDto;
    this._organizationUiDto$.next(this.organizationUiDto);
  }

  updateCompanyUiDto(companyUiDto: CompanyUiDto) {
    let index = this.companyUiDtos.findIndex(item => item.companyCode == companyUiDto.companyCode);

    if (index != undefined && index > -1) {
      Object.assign(this.companyUiDtos[index], companyUiDto);
    } else {
      this.companyUiDtos.push(companyUiDto);
    }

    this._companyUiDtos$.next(this.companyUiDtos);
  }

  setCompanyUiDtos(companyUiDtos: CompanyUiDto[]) {
    this.companyUiDtos = companyUiDtos;
    this._companyUiDtos$.next(this.companyUiDtos);
  }

  setCurrentCompanyUiDto(companyUiDto?: CompanyUiDto) {
    this._selectedCompanyUiDto$.next(companyUiDto);
  }

  updatePlantUiDto(plantUiDto: PlantUiDto) {
    let index = this.plantUiDtos.findIndex(item => item.plantCode == plantUiDto.plantCode);

    if (index != undefined && index > -1) {
      Object.assign(this.plantUiDtos[index], plantUiDto);
    } else {
      this.plantUiDtos.push(plantUiDto);
    }

    this._plantUiDtos$.next(this.plantUiDtos);
  }

  setPlantUiDtos(plantUiDtos: PlantUiDto[]) {
    this.plantUiDtos = plantUiDtos;
    this._plantUiDtos$.next(this.plantUiDtos);
  }

  updateUserEntityInfoDto(userEntityInfoDto: UserEntityInfoDto) {
    let index = this.userEntityInfoDtos.findIndex(item => item.userId == userEntityInfoDto.userId);

    if (index != undefined && index > -1) {
      Object.assign(this.userEntityInfoDtos[index], userEntityInfoDto);
    } else {
      this.userEntityInfoDtos.push(userEntityInfoDto);
    }

    this._userEntityInfoDtos$.next(this.userEntityInfoDtos);
  }

  setUserEntityInfoDtos(userEntityInfoDtos: UserEntityInfoDto[]) {
    this.userEntityInfoDtos = userEntityInfoDtos;
    this._userEntityInfoDtos$.next(this.userEntityInfoDtos);
  }

  setApprovalWorkflowsList(approvalWorkflowUiDtos: ApprovalWorkflowUiDto[]) {
    this.approvalWorkflowUiDtos = approvalWorkflowUiDtos;
    this._approvalWorkflowUiDtos$.next(this.approvalWorkflowUiDtos);
  }

  async loadOrganizationUiDto() {
    let userEntityDto = this.userService.getUserEntity() as UserEntityInfoDto;
    try {
      let apiResponseDto = await firstValueFrom(this.fetchOrganizationUiDto(userEntityDto.orgCode!));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.updateOrganizationUiDto(apiResponseDto.data!);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadCompanyUiDtosByOrgCode() {
    let userEntityDto = this.userService.getUserEntity() as UserEntityInfoDto;
    try {
      let apiResponseDto = await firstValueFrom(this.fetchCompanyUiDtosByOrgCode(userEntityDto.orgCode!));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setCompanyUiDtos(apiResponseDto.data as CompanyUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadPlantUiDto(orgCode: string, companyCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPlantUiDto(orgCode, companyCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setPlantUiDtos(apiResponseDto.data as PlantUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadPlantUiDtosByOrgCode(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPlantUiDtosByOrgCode(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setPlantUiDtos(apiResponseDto.data as PlantUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadOnboardingUsersList(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchOnboardingUsers(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setUserEntityInfoDtos(apiResponseDto.data as UserEntityInfoDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadOnboardingApprovalWorkflowsList(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getApprovalWorkflowsList(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setApprovalWorkflowsList(apiResponseDto.data as ApprovalWorkflowUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // API CALLS
  fetchLandingOrganizationUiDto(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`landing/orgDetails`).pipe(
      tap(_ => console.log("Get landing organizations")),
      catchError(this.errorService.handleError<any>("Error while getting landing organizations")));
  }

  private fetchOrganizationUiDto(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`organizations/${orgCode}`).pipe(
      tap(_ => console.log("Get organizations")),
      catchError(this.errorService.handleError<any>("Error while getting organizations")));
  }

  saveOrganizationUiDto(organizationUiDto: OrganizationUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`organizations`, organizationUiDto).pipe(
      tap(_ => console.log("Save organizations")),
      catchError(this.errorService.handleError<any>("Error while saving organizations")));
  }

  private fetchCompanyUiDtosByOrgCode(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`companies/${orgCode}`).pipe(
      tap(_ => console.log("Get companies")),
      catchError(this.errorService.handleError<any>("Error while getting companies")));
  }

  fetchCompanyUiDto(orgCode: string, companyCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`companies/${orgCode}/${companyCode}`).pipe(
      tap(_ => console.log("Get company by companyCode " + companyCode)),
      catchError(this.errorService.handleError<any>("Error while getting company by companyCode " + companyCode)));
  }

  saveCompanyUiDto(companyUiDto: CompanyUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`companies`, companyUiDto).pipe(
      tap(_ => console.log("Save companies")),
      catchError(this.errorService.handleError<any>("Error while saving companies")));
  }

  private fetchPlantUiDto(orgCode: string, companyCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`plants/${orgCode}/${companyCode}`).pipe(
      tap(_ => console.log("Get plants")),
      catchError(this.errorService.handleError<any>("Error while getting plants")));
  }

  private fetchPlantUiDtosByOrgCode(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`plants/${orgCode}`).pipe(
      tap(_ => console.log("Get plants")),
      catchError(this.errorService.handleError<any>("Error while getting plants")));
  }

  savePlantUiDto(plantUiDto: PlantUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`plants`, plantUiDto).pipe(
      tap(_ => console.log("Save plants")),
      catchError(this.errorService.handleError<any>("Error while saving plants")));
  }

  private fetchOnboardingUsers(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`internal-users/${orgCode}`).pipe(
      tap(_ => console.log("Get users")),
      catchError(this.errorService.handleError<any>("Error while getting users")));
  }

  saveUserEntityInfoDto(userEntityInfoDto: UserEntityInfoDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`internal-users`, userEntityInfoDto).pipe(
      tap(_ => console.log("Save internal-users")),
      catchError(this.errorService.handleError<any>("Error while saving internal-users")));
  }

  getApprovalDefinedUsers(awfDefineUiDto: AWFDefineUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`approval-workflows/preview`, awfDefineUiDto).pipe(
      tap(_ => console.log("Get awfDefineUiDto")),
      catchError(this.errorService.handleError<any>("Error while getting awfDefineUiDto")));
  }

  saveAppliedApprovalWorkflow(approvalWorkflowUiDto: ApprovalWorkflowUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`approval-workflows/applied`, approvalWorkflowUiDto).pipe(
      tap(_ => console.log("Save approvalWorkflowUiDto")),
      catchError(this.errorService.handleError<any>("Error while saving approvalWorkflowUiDto")));
  }

  getApprovalWorkflowsList(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`approval-workflows/configured/${orgCode}`).pipe(
      tap(_ => console.log("Get approvalWorkflowsList")),
      catchError(this.errorService.handleError<any>("Error while getting approvalWorkflowsList")));
  }
}

const countries: OnboardingCountry[] = [
  {
    name: "Australia",
    taxOne: 'Australian Business Number (ABN)',
    taxOneLengthMin: 11,
    taxOneLengthMax: 11,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Brazil",
    taxOne: 'Cadastro Nacional da Pessoa Jurídica (CNPJ)',
    taxOneLengthMin: 14,
    taxOneLengthMax: 14,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Argentina",
    taxOne: 'Clave Única de Identificación Tributaria (CUIT)',
    taxOneLengthMin: 11,
    taxOneLengthMax: 11,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Bahrain",
    taxOne: 'Commercial Registration Number (CR)',
    taxOneLengthMin: 3,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Canada",
    taxOne: 'Business Number (BN)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Numeric',
    taxTwo: 'GST/HST Number',
    taxTwoLengthMin: 15,
    taxTwoLengthMax: 15,
    taxTwoType: 'Alphanumeric'
  },
  {
    name: "France",
    taxOne: 'Numéro SIRET (Système d\'Identification du Répertoire des Etablissements)',
    taxOneLengthMin: 14,
    taxOneLengthMax: 14,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Germany",
    taxOne: 'Umsatzsteuer-Identifikationsnummer (USt-IdNr)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Ghana",
    taxOne: 'Taxpayer Identification Number (TIN)',
    taxOneLengthMin: 11,
    taxOneLengthMax: 11,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "India",
    taxOne: 'Permanent Account Number (PAN)',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Alphanumeric',
    taxTwo: 'GSTIN (Goods and Services Tax Identification Number)',
    taxTwoLengthMin: 15,
    taxTwoLengthMax: 15,
    taxTwoType: 'Alphanumeric'
  },
  {
    name: "Indonesia",
    taxOne: 'Nomor Pokok Wajib Pajak (NPWP)',
    taxOneLengthMin: 15,
    taxOneLengthMax: 15,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Ireland",
    taxOne: 'VAT Registration Number',
    taxOneLengthMin: 8,
    taxOneLengthMax: 9,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Kenya",
    taxOne: 'Personal Identification Number (PIN)',
    taxOneLengthMin: 11,
    taxOneLengthMax: 11,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Kuwait",
    taxOne: 'Commercial Registration Number',
    taxOneLengthMin: 6,
    taxOneLengthMax: 8,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Malaysia",
    taxOne: 'Business Registration Number (BRN)',
    taxOneLengthMin: 8,
    taxOneLengthMax: 12,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Netherlands",
    taxOne: 'BTW-nummer (VAT Number)',
    taxOneLengthMin: 14,
    taxOneLengthMax: 14,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Nigeria",
    taxOne: 'Taxpayer Identification Number (TIN)',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Oman",
    taxOne: 'Commercial Registration Number',
    taxOneLengthMin: 3,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Philippines",
    taxOne: 'Tax Identification Number (TIN)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Qatar",
    taxOne: 'Commercial Registration Number',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Saudi Arabia",
    taxOne: 'Commercial Registration Number',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: 'VAT Registration Number',
    taxTwoLengthMin: 10,
    taxTwoLengthMax: 10,
    taxTwoType: 'Numeric'
  },
  {
    name: "Singapore",
    taxOne: 'Unique Entity Number (UEN)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 10,
    taxOneType: 'Alphanumeric',
    taxTwo: 'GST Registration Number (GRN)',
    taxTwoLengthMin: 10,
    taxTwoLengthMax: 10,
    taxTwoType: 'Alphanumeric'
  },
  {
    name: "South Africa",
    taxOne: 'Value-Added Tax (VAT) Number',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Spain",
    taxOne: 'Número de Identificación Fiscal (NIF)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 11,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Thailand",
    taxOne: 'Tax Identification Number (TIN)',
    taxOneLengthMin: 13,
    taxOneLengthMax: 13,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "United Arab Emirates",
    taxOne: 'Tax Registration Number (TRN)',
    taxOneLengthMin: 15,
    taxOneLengthMax: 15,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "United Kingdom",
    taxOne: 'Company Unique Taxpayer Reference (UTR)',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "United States",
    taxOne: 'Employer Identification Number (EIN)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  }
];

const workflows: OnboardingWorkflow[] = [
  {
    name: "Approval of PR",
    budgetDependence: false,
    code: "APPROVAL_PR"
  },
  {
    name: "Creation of PQ",
    budgetDependence: false,
    code: "CREATE_PQ"
  },
  {
    name: "Creation of RFQ",
    budgetDependence: false,
    code: "CREATE_RFQ"
  },
  {
    name: "Creation of Auction",
    budgetDependence: false,
    code: "CREATE_AUCTION"
  },
  {
    name: "Opening - Technical of PQ",
    budgetDependence: false,
    code: "TECHNICAL_OPENING_PQ"
  },
  {
    name: "Opening - Technical of RFQ",
    budgetDependence: false,
    code: "TECHNICAL_OPENING_RFQ"
  },
  {
    name: "Opening - Technical of Auction",
    budgetDependence: false,
    code: "TECHNICAL_OPENING_AUCTION"
  },
  {
    name: "Opening of Comparison Sheet of RFQ",
    budgetDependence: false,
    code: "CS_OPENING_RFQ"
  },
  {
    name: "Opening of Comparison Sheet of Auction",
    budgetDependence: false,
    code: "CS_OPENING_AUCTION"
  },
  {
    name: "Creation of PO from Catalogue",
    budgetDependence: true,
    code: "CREATE_PO_CATALOGUE"
  },
  {
    name: "Creation of PO from RFQ",
    budgetDependence: true,
    code: "CREATE_PO_RFQ"
  },
  {
    name: "Creation of PO from Auction",
    budgetDependence: true,
    code: "CREATE_PO_AUCTION"
  },
  {
    name: "Acceptance of Invoice",
    budgetDependence: true,
    code: "ACCEPT_INVOICE"
  },
  {
    name: "Forwarding Invoice to Account Payable",
    budgetDependence: true,
    code: "FW_INVOICE_ACC_PAYABLE"
  }
];
