
import { MobileWrapper } from '../../../shared/models/user/MobileWrapper';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AddGlobalBidderModalComponent } from 'src/app/app/layouts/admin/add-global-bidder-modal/add-global-bidder-modal.component';
import { BidderDetailsComponent } from 'src/app/shared/components/user/bidder-details/bidder-details.component';
import { BidderDto } from 'src/app/shared/models/BidderDto';
import { CategoryDto, CategoryParentWrapperDto } from 'src/app/shared/models/CategoryDto';
import { OrganizationUiDto } from 'src/app/shared/models/OrganizationUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';

import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-bidders-list',
  templateUrl: './bidders-list.component.html',
  styleUrls: ['./bidders-list.component.sass']
})
export class BiddersListComponent implements OnInit {

  bidderEntityDtos: BidderDto[] = [];
  filteredBidderEntityDtos: BidderDto[] = [];

  isLoading: boolean = false;
  isModelLoading: boolean = false;
  ctrlSearch: FormControl = new FormControl('')

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _showErrorMsg$ = new BehaviorSubject<string>("");

  selectedSubCategoryName?: string;
  selectedSubCategoryLevelOneName?: string;

  selectedBidderDto?: BidderDto;

  selectedCategoryIds: (string | undefined)[] = [];

  masterCategories: CategoryParentWrapperDto[] = [];

  auctionCategoriesSubscription$?: Subscription;

  _filteredBidderEntityDtos$ = new BehaviorSubject<BidderDto[]>([]);

  organizationUiDto?: OrganizationUiDto;

  constructor(
    private onboardingService: OnboardingService,
    private adminDashboardService: AdminDashboardService,
    private ngbModal: NgbModal
  ) { }

  ngOnInit(): void {
    this.ctrlSearch.valueChanges.subscribe((val) => {
      this.search(val);
    })

    this.adminDashboardService._bidderLoadDataEvent$.next(true);

    this.adminDashboardService._bidderLoadDataEvent$.subscribe(data => {
      if (data == true) {
        this.loadBidders();
      }
    })


    this.onboardingService.getOrganizationUiDto$.subscribe(org => {
      this.organizationUiDto = org!;
      if (org) {
        this.loadBidders();
      }
    })

    this.auctionCategoriesSubscription$ = this.adminDashboardService.getMasterCategories$.subscribe((dataList) => {
      if (dataList) {
        this.masterCategories = dataList;
      } else {
        this.masterCategories = [];
      }
    })
  }

  openBidderDetailsModal(bidder: BidderDto) {
    let modalRef = this.ngbModal.open(BidderDetailsComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    })
    modalRef.componentInstance.bidderEntityDto = bidder;;
  }

  clearSearch() {
    this.ctrlSearch.reset();
  }

  handleValidSubmit() {
    this._showErrorMsg$.next("");
    this._showErrorToast$.next(false);
    this.isModelLoading = false;
    let temp = ApplicationUtils.clone(this.selectedBidderDto);
    temp!.notificationActive = !this.selectedBidderDto?.notificationActive;
    this.isModelLoading = true;
    this._isSaveButtonEnable$.next(false);
    this.adminDashboardService.manageBidderNotification(temp).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.adminDashboardService._bidderLoadDataEvent$.next(true);
          this._showSuccessToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isModelLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this._isSaveButtonEnable$.next(true);
            this.closeModal();
          }, 2000)
        } else {

          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isModelLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this._showErrorMsg$.next("Error while adding global bidder. Try again.");
        this.isModelLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })

  }

  loadBidders() {
    if (!this.organizationUiDto?.orgCode) {
      return;
    }
    this.isLoading = true
    this.adminDashboardService.getBidderListData(this.organizationUiDto?.orgCode!).subscribe(apiResponseDto => {
      this.isLoading = false;
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.bidderEntityDtos = apiResponseDto.data as BidderDto[];
        this.filteredBidderEntityDtos = this.bidderEntityDtos;
        this._filteredBidderEntityDtos$.next(this.bidderEntityDtos);
        this.adminDashboardService.setGlobalBidderCount(this.bidderEntityDtos.length);
      }
    })
  }

  search(searchText: string) {
    let tempBidderEntityDtos: BidderDto[] = [];

    tempBidderEntityDtos = [...this.bidderEntityDtos ?? []];

    if (searchText.trim() != '') {
      let filteredBidderEntityDtos = tempBidderEntityDtos.filter((item) => item.name?.toLowerCase().includes(searchText.trim().toLowerCase()) || item.companyName?.toLowerCase()?.includes(searchText.trim())
        || item.primaryEmailId?.toString().includes(searchText) || this.getPrimaryMobileNo(item.mobileList!)?.includes(searchText) || (item.address) && (item.address?.country) && (item.address?.country!.toLowerCase().includes(searchText.trim().toLowerCase())));
      this.filteredBidderEntityDtos = filteredBidderEntityDtos;
      this._filteredBidderEntityDtos$.next(filteredBidderEntityDtos);
    } else {
      this.filteredBidderEntityDtos = tempBidderEntityDtos;
      this._filteredBidderEntityDtos$.next(tempBidderEntityDtos);
    }
  }

  isAllowToShow(type: string) {
    let toReturn = false;

    // check bidder is global bidder
    if ("GLOBAL_BIDDER" == type) {
      toReturn = true;
    }
    return toReturn;
  }

  getCountryCode(mobileList: MobileWrapper[]) {
    if (mobileList && mobileList.length > 0) {
      let mobileWrapper = mobileList.filter(item => item.primary)[0];
      if (mobileWrapper && mobileWrapper.countryCode) {
        if (mobileList && mobileList.length > 0) {
          let mobileWrapper = mobileList.filter(item => item.primary)[0];
          if (mobileWrapper && mobileWrapper.countryCode) {
            return mobileWrapper?.countryCode?.countryCode!.replace('(', '').replace(')', '');
          }
        }
      }


    }
    return '+1';
  }

  getPrimaryMobileNo(mobileList: MobileWrapper[]) {
    if (mobileList && mobileList.length > 0) {
      let mobileWrapper = mobileList.filter(item => item.primary)[0];
      if (mobileWrapper) {
        return mobileWrapper.mobileNo;
      }
    }
    return "";
  }

  closeModal() {
    this.ngbModal.dismissAll()
  }

  openNotificationModal(content: any, selectedBidderDto: BidderDto) {
    this.closeModal()
    this.ngbModal.open(content, {
      size: 'sm', backdrop: 'static', keyboard: false, centered: true
    });

    this.selectedBidderDto = selectedBidderDto;
  }

  openAddBidderModal() {
    this.closeModal()
    this.ngbModal.open(AddGlobalBidderModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openEditBidderModal(item: BidderDto) {
    this.closeModal()
    let modalRef = this.ngbModal.open(AddGlobalBidderModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.selectedBidder = item;
  }

}
