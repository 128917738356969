<app-new-admin-data></app-new-admin-data>
<div class="bg-white-new">
  <div class="container position-relative">
    <!-- <div class=" ai-icon-class" >
      <button class="btn btn-sm btn-new-class " (click)="openAiDescription()">
        <img src="assets/icons/file-ai.svg" class="svg-icon-class-md c-pointer">
      </button>
    </div> -->
    <!-- <div class="notification border border-2 border-danger rounded p-2 mb-2" *ngIf="countPendingRegistration > 0">
      <span class="me-4"><img src="assets/icons/notifications.gif" width="32"></span>
      <span class="fs-4 font-weight-bold me-2">{{countPendingRegistration}}</span>
      <span class="fs-6">registration(s) pending for approval.</span>
      <button class="btn btn-link" (click)="openPendingRegistrationsModal()">Click to View Details</button>
    </div>

    <div class="notification border border-2 border-danger rounded p-2" *ngIf="notAwardLotsCount">
      <span class="me-4"><img src="assets/icons/notifications.gif" width="32"></span>
      <span class="fs-4 font-weight-bold me-2">{{notAwardLotsCount}}</span>
      <span class="fs-6">Lot(s) pending for award.</span>
      <button class="btn btn-link" (click)="openNotAwardAuctionModal(NotAwardAuction)">Click to View Details</button>
    </div> -->

    <!-- AUCTION HOUSE DETAILS -->
    <!-- <app-admin-auction-house-section></app-admin-auction-house-section>

    <hr class="hr my-3" />

    <div>
      <div class="row">
        <div class="col-md-5">
          <h5 class="heading-h5">CLIENT SETTINGS</h5>
        </div>

        <div class="col-md-7 col-12 text-md-end text-start mt-2 mt-md-0">
          <span class="span-class me-2">{{(isShowAuctionHouseSetting$ |async) ? 'Hide Details' : 'Show Details'}}
          </span>
          <span class="form-switch c-pointer">
            <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" [checked]="isShowAuctionHouseSetting$ | async"
              (click)="showOrHideAuctionHouseSetting()" /></span>
        </div>
      </div>

      

    </div> -->

    <!-- <hr class="hr my-3" />
    <div class="col-12 my-2" *ngIf="renderView$ | async">
      <div class=" container py-3">
        <div class="col-12 text-center">
          <button class="btn  btn-sm me-3 rounded py-1 button-width-change mb-2 zoom shadow"
            [ngClass]="this.currentViewTabName == 'AUCTION' ? 'selected-tiles' : 'btn-outline-secondary'"
            (click)="toggleCurrentViewTab('AUCTION')">
            <div class="row">
              <div class="col-3 text-start">
              </div>
              <div class="col-6 text-center ">
                <h5 class="heading-h5" [ngClass]="{'text-white': this.currentViewTabName == 'AUCTION'}">
                  {{countAllAuctions}}</h5>
              </div>
              <div class=" col-12 text-center ">
                <h6 class="heading-h6 position-relative text-capitalize"
                  [ngClass]="{'text-white': this.currentViewTabName == 'AUCTION'}">
                Sourcing</h6>
              </div>
            </div>
          </button>

       
          <button class="btn  btn-sm me-3 rounded py-1 button-width-change mb-2 zoom shadow"
            [ngClass]="this.currentViewTabName == 'IN_HOUSE_ADMIN' ? 'selected-tiles' : 'btn-outline-secondary'"
            (click)="toggleCurrentViewTab('IN_HOUSE_ADMIN')">
            <div class="row">
              <div class="col-3 text-start">
              </div>
              <div class="col-6 text-center ">
                <h5 class="heading-h5" [ngClass]="{'text-white': this.currentViewTabName == 'IN_HOUSE_ADMIN'}">
                  {{countAdmins + countAgents}}</h5>
              </div>
              <div class=" col-12 text-center">
                <h6 class="heading-h6 position-relative text-capitalize"
                  [ngClass]="{'text-white': this.currentViewTabName == 'IN_HOUSE_ADMIN'}">
                Internal  Users
                </h6>
              </div>
            </div>
          </button>

       
          <button class="btn  btn-sm me-3 rounded py-1 button-width-change mb-2 zoom shadow"
            [ngClass]="this.currentViewTabName == 'BIDDERS' ? 'selected-tiles' : 'btn-outline-secondary'"
            (click)="toggleCurrentViewTab('BIDDERS')">
            <div class="row">
              <div class="col-3 text-start">
              </div>
              <div class="col-6 text-center ">
                <h5 class="heading-h5" [ngClass]="{'text-white': this.currentViewTabName == 'BIDDERS'}">{{adminDashboardService._bidderCount$ | async}}
                </h5>
              </div>
              <div class=" col-12  text-center">
                <h6 class="heading-h6 position-relative text-capitalize"
                  [ngClass]="{'text-white': this.currentViewTabName == 'BIDDERS'}">
                 Bidders</h6>
              </div>
            </div>
          </button>

      
          <button class="btn  btn-sm me-3 rounded py-1 button-width-change mb-2 zoom shadow"
            [ngClass]="this.currentViewTabName == 'POST_AUCTION' ? 'selected-tiles' : 'btn-outline-secondary'"
            (click)="toggleCurrentViewTab('POST_AUCTION')">
            <div class="row">
              <div class="col-3 text-start">
              </div>
              <div class="col-6 text-center ">
                <h5 class="heading-h5" [ngClass]="{'text-white': this.currentViewTabName == 'POST_AUCTION'}">
                  {{countPostAuction}}</h5>
              </div>
              <div class=" col-12 text-center">
                <h6 class="heading-h6 position-relative text-capitalize"
                  [ngClass]="{'text-white': this.currentViewTabName == 'POST_AUCTION'}">
                  Post Sourcing</h6>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
    <hr class="hr my-3" /> -->
    <!-- View -->
    <!-- <ng-container [ngTemplateOutlet]="currentViewTabName == 'AUCTION' ? auctions
      : currentViewTabName == 'IN_HOUSE_ADMIN' ? inHouseAdmin
      : currentViewTabName == 'POST_AUCTION' ? postAuction
      : currentViewTabName == 'BIDDERS' ? globalBidder
      : currentViewTabName == 'AUCTION_HOUSE_SETTING' ? auctionHouseSetting: auctions">
    </ng-container> -->
  </div>




  <!-- Messaging Floating Button -->
  <!-- <div class="messagingContainer" *ngIf="selectedStreamingAuction?.liveStreaming">
    <span class="unread-notification" *ngIf="isNewMessagesAvailable$ | async"></span>
    <div class="card messaging shadow" *ngIf="isShowMessagingModal">
      <div class="card-body">
        <app-auction-stream-messaging [auctionId]="selectedStreamingAuction!.auctionId" [isHost]="true"></app-auction-stream-messaging>
      </div>
    </div>
    <div class="fabMessaging shadow c-pointer" (click)="toggleShowMessagingModal()">
      <img src="assets/icons/messaging.png" height="32" width="32">
    </div>
  </div> -->


  <!-- <div class="my-5">
    <div class="col-12" *ngIf="auctionHouse?.bannerImage">
      <img src="/downloadLandingBlob?fileId={{auctionHouse?.bannerImage!.fileId}}" alt="" class="auction-house-banner-image">
    </div>
    <div class="col-12 text-center my-5" *ngIf="!auctionHouse?.bannerImage" (click)="uploadBannerImage()">
    </div>

    <div class="icon-class22 ">
      <button class="btn btn-sm btn-new-class rounded-circle" (click)="openHouseSetting()" *ngIf="currentViewTabName != 'AUCTION_HOUSE_SETTING'" >
        <fa-icon [icon]="['fas', 'gear']" class=" fa-lg-class"></fa-icon>
      </button>
      <span class="material-symbols-outlined text-primary" (click)="openHouseSetting()" *ngIf="currentViewTabName != 'AUCTION_HOUSE_SETTING'">settings</span>
    </div>
    <div class="icon-class2">
      <button class="btn btn-sm btn-new-class rounded-circle" (click)="uploadBannerImage()" *ngIf="currentViewTabName == 'AUCTION_HOUSE_SETTING'" >
        <fa-icon [icon]="['fas', 'pen']" class=" fa-lg-class"></fa-icon>
      </button>
       <span class="material-symbols-outlined text-primary" (click)="uploadBannerImage()" *ngIf="currentViewTabName == 'AUCTION_HOUSE_SETTING'">edit</span>
    </div>
  </div> -->


  <ng-template #auctions>
    <app-admin-auctions-section (openAuctionHouseSetting)="openAuctionHouseSetting()"></app-admin-auctions-section>
  </ng-template>

  <ng-template #auctioneer>
    <app-admin-auctioneer-section></app-admin-auctioneer-section>
  </ng-template>

  <ng-template #adminList>
    <app-admin-admin-list-section></app-admin-admin-list-section>
  </ng-template>

  <ng-template #auctionHouseSetting>
  
  </ng-template>

  <ng-template #inHouseAdmin>
    <app-admin-admin-list-section></app-admin-admin-list-section>
    <app-admin-auctioneer-section></app-admin-auctioneer-section>
  </ng-template>

  <ng-template #postAuction>
    <app-admin-post-sourcing-section></app-admin-post-sourcing-section>
  </ng-template>

  <ng-template #globalBidder>
    <app-bidders-list></app-bidders-list>
  </ng-template>

</div>

<app-loader #appLoader></app-loader>




<!-- 
<ng-template #NotAwardAuction>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>NOT AWARD AUCTIONS</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-class">
      <div class="m-3">
        <table class="table table-bordered mb-0">
          <thead class="table-dark">
            <tr>
              <th scope="col" ngbAutoFocus>#</th>
              <th scope="col">Auction No.</th>
              <th scope="col">Auction Name</th>
              <th class="text-center" scope="col">No. of Lots</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of notAwardAuctionDtos; index as i">
              <td>{{i + 1}}</td>
              <td>{{item?.auctionSequenceNo}}</td>
              <td>{{item?.auctionName}}</td>
              <td class="text-center">{{item?.noOfLots}}</td>
              <td>
                <button class="btn btn-sm btn-new-class" (click)="navigateToPostAuction(item?.auctionId!)">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template> -->








<!-- Old Code -->

<!-- <div class="container">
  <h2>Admin Dashboard</h2>

  <div>

    <div class="d-flex justify-content-between">
      <div class="flex-grow-1 border rounded m-2 text-center bg-secondary c-pointer"
        [ngClass]="{'selected': currentStatus ==1}" (click)="changeStatus(1)">
        <div class="m-1">
          Agents
          <div>12</div>
        </div>
      </div>
      <div class="flex-grow-1 border rounded m-2 text-center bg-secondary" [ngClass]="{'selected': currentStatus ==2}"
        (click)="changeStatus(2)">
        <div class="m-1">
          Admin
          <div>2</div>
        </div>
      </div>
    </div>

    <div class="my-5" *ngIf="currentStatus ==1">
     <div class="row mb-3">
      <div class="col-md-9 text-center text-md-start">
        <h3>Agents</h3>
      </div>
      <div class="col-md-3 text-center text-md-end">
        <input type="search" class="form-control">

      </div>
     </div>
      <div class="border ">
        <div class="m-3">
          <table class="table">
            <thead class="table-primary">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Company Name</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr>
                <th scope="row">1</th>
                <td>Mark Otto</td>
                <td>mark@auctionext.com</td>
                <td>Adventure Works</td>
                <td>
                  <div class="form-check form-switch c-pointer">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked />
                    <label class="form-check-label" for="flexSwitchCheckDefault"><span
                        class="badge text-bg-success">Active</span></label>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>John White</td>
                <td>john@auctionext.com</td>
                <td>Adventure Works</td>
                <td>
                  <div class="form-check form-switch c-pointer">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                    <label class="form-check-label" for="flexSwitchCheckDefault"><span
                        class="badge text-bg-danger">Inactive</span></label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="my-5" *ngIf="currentStatus ==2">
      <div class="row mb-3">
        <div class="col-md-4 text-center text-md-start">
          <h3>Admins</h3>
        </div>
        <div class="text-end mb-2 col-md-5  text-center text-md-end">
          <button type="button" class="btn btn-new-class"
            (click)="openAdminUserInviteModal(inviteNewAdminModal)">Invite New Admin</button>
        </div>
        <div class="col-md-3 text-center text-md-end">
          <input type="search" class="form-control">

        </div>
       </div>
      <div class="border ">
        <div class="m-3">
          <table class="table">
            <thead class="table-primary">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Company Name</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr>
                <th scope="row">1</th>
                <td>Mark Otto</td>
                <td>mark@auctionext.com</td>
                <td>Adventure Works</td>
                <td>
                  <div class="form-check form-switch c-pointer">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked />
                    <label class="form-check-label" for="flexSwitchCheckDefault"><span
                        class="badge text-bg-success">Active</span></label>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>John White</td>
                <td>john@auctionext.com</td>
                <td>Adventure Works</td>
                <td>
                  <div class="form-check form-switch c-pointer">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                    <label class="form-check-label" for="flexSwitchCheckDefault"><span
                        class="badge text-bg-danger">Inactive</span></label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>



      <section>
        <div class=" my-5">
          <div class="border p-2">
            <div class="my-3 border-bottom">
              <div class="row my-3">
                <div class="col-md-6 text-center text-md-start">
                  <h6>Admin History</h6>
                </div>
                <div class="col-md-6 text-center text-md-end">
                  <button class="btn btn-sm btn-dark me-1">REFRESH</button>
                  <button class="btn btn-sm btn-dark me-2" (click)="openAddAdminModal(addAdmin)">ADD ADMIN</button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead class="table-primary">
                  <tr>
                    <th>#</th>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>MOBILE NO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="font-weight-normal">1</td>
                    <td class="font-weight-normal">Neha</td>
                    <td class="font-weight-normal"> test@gmail.com
                    </td>
                    <td class="font-weight-normal"> 00000000000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <div>Temporary Section - To be removed</div>
      <div>On first successful login - admin user navigate to password reset</div>
      <div class="text-start"><button type="button" class="btn btn-new-class" (click)="navigateToAuctions()">Admin
          Login</button></div>
    </div>
  </div>
</div>


<ng-template #bidderDetailsModal>
  Test
</ng-template>
<ng-template #inviteNewAdminModal>
  <app-admin-user-invite></app-admin-user-invite>
</ng-template>


<ng-template #addAdmin>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Add Admin</h2>
        </div>
        <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <form class="needs-validation" novalidate>
        <div class="col-12">
          <div class="mb-2">
            <label for="Email" class="form-label">Email</label>
            <input name="" type="text" placeholder="" class="form-control" />
          </div>
        </div>
        <div class="col-12">
          <div class="mb-2">
            <label for="Password" class="form-label">Password</label>
            <input name="" type="text" placeholder="" class="form-control" />
          </div>
        </div>
        <div class="col-12">
          <div class="mb-2">
            <label for="Confirm Password" class="form-label">Confirm Password</label>
            <input name="" type="text" placeholder="" class="form-control" />
          </div>
        </div>
        <div class="d-grid gap-2 my-3">
          <button type="button" class="btn btn-new-class">Add</button>
        </div>
      </form>
    </div>
  </div>
</ng-template> -->
