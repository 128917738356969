import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { AddAuctionModalComponent } from '../add-auction-modal/add-auction-modal.component';
import { AddRfxModalComponent } from '../../rfx/add-rfx-modal/add-rfx-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProjectUiDto } from 'src/app/shared/models/ProjectUiDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { OrganizationUiDto } from 'src/app/shared/models/OrganizationUiDto';
import { UserService } from 'src/app/shared/services/user.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { FileService } from 'src/app/shared/services/file.service';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { TreeNode } from 'primeng/api';
import { EventEnum } from 'src/app/shared/enums/EventEnum';
import { ServerDataFetchService } from 'src/app/shared/services/server-data-fetch.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { PurchaseRequestStatus } from 'src/app/shared/enums/rfx/PurchaseRequestStatus';

interface TableTreeData {
  name: string
  code: string
  type: string
  status: string
}

interface PrimeNgTableTree {
  data: TableTreeData,
  children: PrimeNgTableTree[]
}

@Component({
  selector: 'app-admin-projects',
  templateUrl: './admin-projects.component.html',
  styleUrls: ['./admin-projects.component.sass']
})
export class AdminProjectsComponent implements OnInit, OnDestroy {
  organizationUiDto?: OrganizationUiDto;
  projectUiDtosList: ProjectUiDto[] = [];
  tableTreeData: PrimeNgTableTree[] = [];

  formGroup: FormGroup;

  documentTypes = ['application/pdf'];

  isDataLoading: boolean = true;
  isLoading: boolean = false;
  isUploadLoading: boolean = false;
  currentFile?: File | null;
  fileUploadError: boolean = false;

  currentEventCode: string = ''
  isNavLoading: boolean = false;

  selectedDocuments: FileInfoDto[] = [];
  selectedProjectUiDto?: ProjectUiDto;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');
  _fileDisplayName$ = new BehaviorSubject<string | null>("");
  _fileSize$ = new BehaviorSubject<string | null>("");

  projectUiDtosListSubscription$?: Subscription;
  organizationUiDtoSubscription$?: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder,
    private onboardingService: OnboardingService,
    private userService: UserService,
    private fileService: FileService,
    private serverDataFetchService: ServerDataFetchService,
    private dataRedirectionService: DashboardRedirectionService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService
  ) {
    this.formGroup = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.organizationUiDtoSubscription$ = this.onboardingService.getOrganizationUiDto$.subscribe((data) => {
      if (data) {
        this.organizationUiDto = data;
        this.fetchAllProjects();
      }
    });

    this.projectUiDtosListSubscription$ = this.adminSourcingEventsDataHolderService.getProjectUiDtosList$.subscribe((data) => {
      if (data) {
        this.projectUiDtosList = data;
        this.refreshProjectsTable();
      } else {
        this.projectUiDtosList = [];
      }
    });
  }

  get fc() { return this.formGroup.controls; }

  async fetchAllProjects() {
    let userEntityInfoDto = this.userService.getUserEntity();

    this.isDataLoading = true;
    await this.adminSourcingEventsDataHolderService.loadAllProjects(this.organizationUiDto?.orgCode!);
    await this.onboardingService.loadPlantUiDto(userEntityInfoDto?.orgCode!, userEntityInfoDto?.companyCode!);
    this.isDataLoading = false;
  }

  refreshProjectsTable() {
    this.projectUiDtosList = this.projectUiDtosList.sort((a, b) => Date.parse(b.createdDate!) - Date.parse(a.createdDate!));
    let tempProjectUiDtos = this.projectUiDtosList.map(item => {
      return {
        eventName: item.name,
        eventCode: item.projectCode,
        eventType: 'PROJECT',
        status: '',
        events: item.eventsUiDtos,
      }
    });

    this.tableTreeData = tempProjectUiDtos.map(dto => this.convertDtoToTree(dto));
  }

  openAddRFXModal(rfxType: string, tableTreeData?: TableTreeData) {
    let modalRef = this.ngbModal.open(AddRfxModalComponent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.type = rfxType;

    if (tableTreeData) {
      let projectUiDto = this.projectUiDtosList.find(item => item.projectCode == tableTreeData.code);
      modalRef.componentInstance.projectUiDto = projectUiDto;
    }
  }

  openAddAuctionModal(tableTreeData?: TableTreeData) {
    let errorCodeDtos: any[] = []//this.auctionValidationService.doAuctionHouseValidation(this.organizationUiDto!);
    if (errorCodeDtos.length > 0) {
      let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        centered: true,
      });
      modalRef.componentInstance.auctionValidationErrorCodeDtoList = errorCodeDtos;
      return;
    }

    let modalRef = this.ngbModal.open(AddAuctionModalComponent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });

    if (tableTreeData) {
      let projectUiDto = this.projectUiDtosList.find(item => item.projectCode == tableTreeData.code);
      modalRef.componentInstance.projectUiDto = projectUiDto;
    }
  }

  openAddProjectModal(content: any, projectNode?: TableTreeData) {
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.isLoading = false;

    this.formGroup.reset();
    this.selectedDocuments = [];
    this.selectedProjectUiDto = undefined;

    if (projectNode) {
      let projectUiDto = this.projectUiDtosList.find(item => item.projectCode == projectNode.code)!;
      this.selectedProjectUiDto = projectUiDto;
      
      this.selectedDocuments = projectUiDto.fileInfoDtos ?? [];
      this.formGroup.controls['name'].patchValue(projectUiDto.name);
      this.formGroup.controls['description'].patchValue(projectUiDto.description);
      this.formGroup.updateValueAndValidity();
    }

    this.ngbModal.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.ngbModal.dismissAll();
    }
  }

  convertDtoToTree(dto: any): PrimeNgTableTree {
    const finalDto: TableTreeData = { name: dto.eventName, code: dto.eventCode, type: dto.eventType, status: dto.status }

    return {
      data: finalDto,
      children: dto.events ? dto.events.map((childDto: any) => this.convertDtoToTree(childDto)) : []
    };
  }

  removeSelectedDocument(fileId: string) {
    let index = this.selectedDocuments.findIndex(item => item.fileId == fileId);
    this.selectedDocuments.splice(index, 1);
  }

  chooseFile(event: any) {
    this._showErrorToast$.next(false);
    this.currentFile = event.target.files[0];

    if (!this.documentTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this._errorMsg$.next("Extension not supported")
      this._showErrorToast$.next(true);
      this.currentFile = null;
      this._fileDisplayName$.next(null);
      return;
    }

    this._fileDisplayName$.next(this.getFileName(this.currentFile?.name!) as string);
    this._fileSize$.next(this.currentFile?.size + "");

    this.uploadFile();
  }

  uploadFile() {
    this.fileUploadError = false;
    this.isUploadLoading = true;
    this._errorMsg$.next("");

    let metaData = {
      'mimeType': this.currentFile?.type,
      'version': 0,
      'publicApi': false,
      'dataType': 'Rectangle'
    };

    let formData = new FormData();
    formData.append("file", this.currentFile!);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;
          this.isUploadLoading = false;

          let currentFileInfoDto = new FileInfoDto();
          currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
          currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
          currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
          currentFileInfoDto.dataType = fileSaveResponseDto.dataType;
          currentFileInfoDto.fileSize = this.currentFile?.size.toString();
          currentFileInfoDto.fileType = this.currentFile?.type;
          currentFileInfoDto.uploadDate = new Date().toDateString();

          this.selectedDocuments.push(currentFileInfoDto);
        }
      } else {
        this.fileUploadError = true;
        this.isUploadLoading = false;
      }
    })
  }

  mergeProjectUiDto() {
    let userEntityInfoDto = this.userService.getUserEntity();
    let formValue = this.formGroup.getRawValue();

    let projectUiDto = new ProjectUiDto();

    if (this.selectedProjectUiDto) {
      projectUiDto = ApplicationUtils.clone(this.selectedProjectUiDto) as ProjectUiDto;
    }

    projectUiDto.orgCode = userEntityInfoDto.orgCode;
    projectUiDto.name = formValue.name;
    projectUiDto.description = formValue.description;

    if (!projectUiDto.fileInfoDtos) {
      projectUiDto.fileInfoDtos = [];
    }

    projectUiDto.fileInfoDtos = this.selectedDocuments;

    return projectUiDto;
  }

  addProject() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched()
      return;
    }

    this._showErrorToast$.next(false);
    this._errorMsg$.next("");

    let projectUiDto = this.mergeProjectUiDto()
    this.isLoading = true;

    this.adminSourcingEventsDataHolderService.saveProjectDetails(projectUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as ProjectUiDto;
          this.adminSourcingEventsDataHolderService.updateProjectsUiDtosList(data);

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal()
          }, 2000)
        } else {
          this._errorMsg$.next(apiResponseDto.message ?? "Error while saving Project. Try again.");
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this._errorMsg$.next("Error while saving Project. Try again.");
        this._showErrorToast$.next(true);
        this.isLoading = false;

      }
    })
  }

  async openEventStagePage(eventNode: TreeNode, projectNode: TreeNode) {
    let projectUiDto = this.projectUiDtosList.find(item => item.projectCode == projectNode.data.code)!;
    let eventsUiDto = projectUiDto.eventsUiDtos?.find(item => item.eventCode == eventNode.data.code);

    if (eventsUiDto) {
      this.currentEventCode = eventsUiDto.eventCode!;
      this.isNavLoading = true;

      if (eventsUiDto.eventType == EventEnum.AUCTION) {
        await this.serverDataFetchService.loadAuctionForAdminSync(eventsUiDto.eventId!);
        await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(eventsUiDto.eventId!);

        this.dataRedirectionService.storeNavigationContext(
          ApplicationConstants.ADMIN,
          ApplicationConstants.AUCTION_CREATOR_PAGE,
          ApplicationConstants.AUCTION_TAB
        );
        this.dataRedirectionService.setUpdateBreadcrumbs(true);

      } else {
        await this.serverDataFetchService.loadRfxForAdminSync(eventsUiDto.eventId!);
        await this.serverDataFetchService.loadRfxQuestionnaireWrapperDtoOfRfxIdForAdminSync(eventsUiDto.eventId!);

        this.dataRedirectionService.storeNavigationContext(
          ApplicationConstants.ADMIN,
          ApplicationConstants.RFX_CREATOR_PAGE,
          ApplicationConstants.RFX_TAB
        );
        this.dataRedirectionService.setUpdateBreadcrumbs(true);
      }
    }

    this.isNavLoading = false;
    this.currentEventCode = '';
  }

  formatPrStatus(status: string) {
    if (status == PurchaseRequestStatus.APPROVAL_PENDING) {
      return 'Approval Pending';
    } else if (status == PurchaseRequestStatus.APPROVAL_APPROVED) {
      return 'Approval Approved';
    } else if (status == PurchaseRequestStatus.COMPLETED) {
      return 'Completed';
    } else if (status == PurchaseRequestStatus.SUBMITTED) {
      return 'Submitted';
    }
    return ''
  }

  formatBytes(size: any) {
    return ApplicationUtils.formatBytes(size);
  }

  getFileName(fileName: string) {
    let extensionName = fileName?.slice(fileName.lastIndexOf('.'), fileName.length)
    let name = fileName?.slice(0, fileName.lastIndexOf('.'));
    let finalFileName = ApplicationUtils.removeSpecialCharacters(name!) as string
    return finalFileName + "" + extensionName;
  }

  ngOnDestroy(): void {
    if (this.projectUiDtosListSubscription$) {
      this.projectUiDtosListSubscription$.unsubscribe();
    }
    if (this.organizationUiDtoSubscription$) {
      this.organizationUiDtoSubscription$.unsubscribe();
    }
  }

}
