<div class="">
  <div class="border rounded bg-white" [ngClass]="{'border-danger border-2': haveAuctionDetailsErrors()}">
    <header class="p-2">
      <div class="row ">
        <div class="col-md-6">
          <h5 class="heading-h5"> DETAILS</h5>
        </div>
        <div class="col-md-6 text-md-end">
          <button class="btn btn-new-class btn-sm px-4 " (click)="openauctionDetailModal(auctionDetailModal)">
            <!-- <img src="assets/icons/edit_dark.svg" class="svg-icon-class c-pointer"> -->
            EDIT
          </button>
        </div>
      </div>
    </header>
    <hr class="hr" />
    <div class="col-12">
      <div class="row mx-0">
        <div class="col-12 div-class py-2">
          Auction Information
        </div>

        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-danger' : !auctionEntityDto?.auctionName}">
          Title
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.auctionName}}
        </div>
        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-danger' : !auctionEntityDto?.companyId}">
          Company Name
        </div>
        <div class="col-8 div-class py-2">
          {{getCompanyName(auctionEntityDto?.companyId)}}
        </div>
        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2 ">
          Bidding Method
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.auctionBiddingMethod == "REVERSE_AUCTION" ? 'Reverse Auction' : 'Forward Auction'}}
        </div>
        <hr class="hr" />

        <div class="col-4 font-weight-normal py-2 ">
          Type
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.auctionType}}
        </div>

        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-danger' : !auctionEntityDto?.currency}">
          Currency
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.currency?.name}}
        </div>

        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-danger' : !auctionEntityDto?.estSpend}">
          Est Spend
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(auctionEntityDto?.estSpend)}}
        </div>

        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-danger' : !auctionEntityDto?.estSaving}">
          Est Saving
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(auctionEntityDto?.estSaving)}}
        </div>

        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-danger' : !auctionEntityDto?.timeZone}">
          Timezone
        </div>
        <div class="col-8 div-class py-2">
          {{getTimeZone(auctionEntityDto?.timeZone!)}}
        </div>

        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-danger' : !auctionEntityDto?.region}">
          Region
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.region!}}
        </div>

        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-danger' : !auctionEntityDto?.costCenters}">
          Cost Centers
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.costCenters!}}
        </div>

        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2 " [ngClass]="{'text-danger' : !auctionEntityDto?.bidderApproval}">
          Bidder Approval
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.bidderApproval}}
        </div>
        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2 " [ngClass]="{'text-danger' : !auctionEntityDto?.startDate || !auctionEntityDto?.startTime}">
          Starting On
        </div>
        <div class="col-8 div-class py-2">
          {{getDisplayDate()}}
        </div>
        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2">
          Soft Close
        </div>
        <div class="col-8 div-class py-2" *ngIf="auctionEntityDto?.antiSnipe">
          {{auctionEntityDto?.antiSnipe}} minutes
        </div>
        <hr class="hr" hidden />
        <div class="col-4 font-weight-normal py-2" hidden>
          Premium Auction
        </div>
        <div class="col-8 div-class py-2" hidden>
          {{auctionEntityDto?.premiumAuction? 'Enable' : 'Disable'}}
        </div>
        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2">
          Buy It Now (Pre Offer)
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.buyItNowEnable? 'Enable' : 'Disable'}}
          <!-- <span class="form-switch  ms-3">
            <input #toggleRefBuyItNow class="form-check-input c-pointer" type="checkbox" id="flexSwitchCheckChecked"
            (click)="openActivateModal(enableDisabledBuyItNow, toggleRefBuyItNow, 'BUY-IT-NOW')" [checked]="auctionEntityDto?.buyItNowEnable" />
          </span> -->
        </div>
        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2">
          Display Rank - Market Price
        </div>
        <div class="col-8 div-class py-2">
          <span *ngIf="auctionEntityDto?.rankCurrentPriceConfig">
            {{getRankPriceConfigName(auctionEntityDto?.rankCurrentPriceConfig!)}}
          </span>
        </div>
        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2">
          Show Bidder History
        </div>
        <div class="col-8 div-class py-2">
          {{getShowBidderHistory(auctionEntityDto?.showBidderHistory!)}}
        </div>
        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2">
          Mask Bidder Details
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.maskBidderDetails? 'Enable' : 'Disable'}}
          <!-- <span class="form-switch  ms-3">
            <input #toggleRefBuyItNow class="form-check-input c-pointer" type="checkbox" id="flexSwitchCheckChecked"
            (click)="openActivateModal(enableDisabledBuyItNow, toggleRefBuyItNow, 'BUY-IT-NOW')" [checked]="auctionEntityDto?.buyItNowEnable" />
          </span> -->
        </div>

        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2">
          Allow Edit While Auction is Upcoming
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.allowEditWhileUpcoming ? 'Enable' : 'Disable'}}
        </div>

        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2">
          Allow Edit While Auction is Running
        </div>
        <div class="col-8 div-class py-2">
          {{auctionEntityDto?.allowEditWhileRunning ? 'Enable' : 'Disable'}}
        </div>

        <hr class="hr" *ngIf="deploymentConfigurationDto?.enableBlockchain" />
        <div class="col-4 font-weight-normal py-2"  *ngIf="deploymentConfigurationDto?.enableBlockchain">
          Blockchain Enable
        </div>
        <div class="col-8 div-class py-2" *ngIf="deploymentConfigurationDto?.enableBlockchain">
          {{auctionEntityDto?.enableBlockchain? 'Enable' : 'Disable'}}
        </div>
        <hr class="hr" />
        <div class="row ">
          <hr class="hr" />
          <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-danger' : !auctionEntityDto?.auctionImage}">
            Auction Image
          </div>
          <div class="col-8 div-class py-2 text-start">
            <img alt="" src="/downloadLandingBlob?fileId={{this.auctionEntityDto?.auctionImage?.fileId}}"
              class="modal-logo-image" width="50" height="50" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #auctionDetailModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2> AUCTION DETAILS </h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup"
          (keyup.enter)="handleSubmit()">
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="auctionName" placeholder="Auction Title"
              formControlName="auctionName"
              [ngClass]="{'is-invalid': fc.auctionName.invalid && (fc.auctionName.dirty || fc.auctionName.touched)}">
            <label for="auctionName">Auction Title</label>
            <div *ngIf="isSubmitted || fc.auctionName.invalid && (fc.auctionName.dirty || fc.auctionName.touched)">
              <p class="text-danger" *ngIf="fc.auctionName.errors?.required">Required</p>
            </div>
          </div>
          <div class="form-floating mb-3">
            <select   class="form-control form-select"
            formControlName="companyName" id="companyName"
            [ngClass]="{'is-invalid': fc.companyName.invalid && (fc.companyName.dirty || fc.companyName.touched)}">
            <option value="" disabled>Select . . .</option>
            <option [value]="item.companyCode"
              *ngFor="let item of (onboardService.getCompanyUiDtos$ | async)">{{item.companyName}}</option>
          </select>
            <label for="companyName">Company Name</label>
            <div *ngIf="isSubmitted || fc.companyName.invalid && (fc.companyName.dirty || fc.companyName.touched)">
              <p class="text-danger" *ngIf="fc.companyName.errors?.required">Required
              </p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select name="Select Timezone" id="auctionBiddingMethod" class="form-control form-select" formControlName="auctionBiddingMethod" (change)="onAuctionBiddingMethodChange()"
              [ngClass]="{'is-invalid': fc.auctionBiddingMethod.invalid && (fc.auctionBiddingMethod.dirty || fc.auctionBiddingMethod.touched)}">
              <option value="FORWARD_AUCTION">Forward Auction</option>
              <option value="REVERSE_AUCTION">Reverse Auction</option>
            </select>
            <label for="auctionBiddingMethod">Auction Bidding Method</label>
            <div *ngIf="isSubmitted || fc.auctionBiddingMethod.invalid && (fc.auctionBiddingMethod.dirty || fc.auctionBiddingMethod.touched)">
              <p class="text-danger" *ngIf="fc.auctionBiddingMethod.errors?.required">Required
              </p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select name="Select Timezone" id="auctionType" class="form-control form-select" formControlName="auctionType"
              [ngClass]="{ 'is-invalid': fc.auctionType.invalid || fc.auctionType.invalid && (fc.auctionType.dirty || fc.auctionType.touched)}">
              <option value="" disabled>Select . . .</option>
              <option value="Reserve">Reserve</option>
              <option value="Absolute">Absolute</option>
              <option value="Subject to Confirmation">Subject to Confirmation</option>
            </select>
            <label for="auctionType">Auction Type</label>
            <div *ngIf="isSubmitted || fc.auctionType.invalid && (fc.auctionType.dirty || fc.auctionType.touched)">
              <p class="text-danger" *ngIf="fc.auctionType.errors?.required">Required</p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select (change)="onChangeCurrency($event)" id="currency" name="Select Timezone" class="form-control form-select"
              formControlName="currency"
              [ngClass]="{'is-invalid': fc.currency.invalid || fc.currency.invalid && (fc.currency.dirty || fc.currency.touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="item.name" [selected]="item.name == selectedCurrency?.name"
                *ngFor="let item of currencies">{{item.name}}</option>
            </select>

            <label for="currency">Currency {{selectedCurrency?.symbol}}</label>
            <div *ngIf="isSubmitted || fc.currency.invalid && (fc.currency.dirty || fc.currency.touched)">
              <p class="text-danger" *ngIf="fc.currency.errors?.required">Required</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-floating">
                <input type="text" id="estSpend" class="form-control" formControlName="estSpend" (blur)="formatEstSpendValue()"
                  [ngClass]="{'is-invalid': fc.estSpend.invalid && (fc.estSpend.dirty || fc.estSpend.touched)}">
                <label for="estSpend">Est Spend</label>
                <div *ngIf="isSubmitted || fc.estSpend.invalid && (fc.estSpend.dirty || fc.estSpend.touched)">
                  <p class="text-danger" *ngIf="fc.estSpend.errors?.required">Required</p>
                  <p class="text-danger" *ngIf="invalidPriceValidator(fc.estSpend)">Invalid</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3">
              <div class="form-floating">
                <input type="text" id="estSaving" class="form-control" formControlName="estSaving" (blur)="formatEstSavingValue()"
                  [ngClass]="{'is-invalid': fc.estSaving.invalid && (fc.estSaving.dirty || fc.estSaving.touched)}">
                <label for="estSaving">Est Saving</label>
                <div *ngIf="isSubmitted || fc.estSaving.invalid && (fc.estSaving.dirty || fc.estSaving.touched)">
                  <p class="text-danger" *ngIf="fc.estSaving.errors?.required">Required</p>
                  <p class="text-danger" *ngIf="invalidPriceValidator(fc.estSaving)">Invalid</p>
                </div>
              </div>
            </div>
          </div>


          <div class="form-floating mb-3">
            <select name="Select Timezone" id="timeZone" class="form-control form-select" formControlName="timeZone"
              [ngClass]="{'is-invalid': fc.timeZone.invalid || fc.timeZone.invalid && (fc.timeZone.dirty || fc.timeZone.touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="item?.locale" *ngFor="let item of timezones">{{item?.fullName}}</option>
            </select>
            <label for="timeZone">Select Timezone</label>
            <div *ngIf="isSubmitted || fc.timeZone.invalid && (fc.timeZone.dirty || fc.timeZone.touched)">
              <p class="text-danger" *ngIf="fc.timeZone.errors?.required">Required</p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select name="Select Region" id="region" class="form-control form-select" formControlName="region"
              [ngClass]="{'is-invalid': fc.region.invalid && (fc.region.dirty || fc.region.touched)}">
              <option value="" disabled>Select . . .</option>
              <option value="North America">North America</option>
              <option value="Latin America (LATAM)">Latin America (LATAM)</option>
              <option value="Europe, Middle East, and Africa (EMEA)">Europe, Middle East, and Africa (EMEA)</option>
              <option value="Asia-Pacific (APAC)">Asia-Pacific (APAC)</option>
              <option value="Commonwealth of Independent States (CIS)">Commonwealth of Independent States (CIS)</option>
              <option value="Sub-Saharan Africa">Sub-Saharan Africa</option>
            </select>

            <label for="region">Region</label>
            <div *ngIf="isSubmitted || fc.region.invalid && (fc.region.dirty || fc.region.touched)">
              <p class="text-danger" *ngIf="fc.region.errors?.required">Required</p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select name="Select Cost Centers" class="form-control form-select" id="costCenters" formControlName="costCenters"
              [ngClass]="{'is-invalid': fc.costCenters.invalid && (fc.costCenters.dirty || fc.costCenters.touched)}">
              <option value="" disabled>Select . . .</option>
              <option value="Human Resources (HR)">Human Resources (HR)</option>
              <option value="Finance and Accounting">Finance and Accounting</option>
              <option value="Legal">Legal</option>
              <option value="Executive Management">Executive Management</option>
              <option value="Information Technology (IT)">Information Technology (IT)</option>
              <option value="Facilities Management">Facilities Management</option>
              <option value="Procurement/Purchasing">Procurement/Purchasing</option>
              <option value="Research and Development (R&D)">Research and Development (R&D)</option>
              <option value="Quality Assurance (QA)">Quality Assurance (QA)</option>
              <option value="Marketing">Marketing</option>
              <option value="Sales Support">Sales Support</option>
              <option value="Manufacturing/Production">Manufacturing/Production</option>
              <option value="Logistics and Transportation">Logistics and Transportation</option>
              <option value="Customer Service/Support">Customer Service/Support</option>
              <option value="Training and Development">Training and Development</option>
              <option value="Corporate Communications">Corporate Communications</option>
              <option value="Environmental, Health, and Safety (EHS)">Environmental, Health, and Safety (EHS)</option>
              <option value="Corporate Social Responsibility (CSR)">Corporate Social Responsibility (CSR)</option>
              <option value="Internal Audit">Internal Audit</option>
              <option value="Risk Management">Risk Management</option>
            </select>

            <label for="costCenters">Cost Centers</label>
            <div *ngIf="isSubmitted || fc.costCenters.invalid && (fc.costCenters.dirty || fc.costCenters.touched)">
              <p class="text-danger" *ngIf="fc.costCenters.errors?.required">Required</p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select name="Bidder Approval" class="form-control form-select" formControlName="bidderApproval" id="bidderApproval"
              [ngClass]="{'is-invalid': fc.bidderApproval.invalid || fc.bidderApproval.invalid && (fc.bidderApproval.dirty || fc.bidderApproval.touched)}">
              <option value="" disabled>Select . . .</option>
              <option value="Auto Approve">Auto Approve</option>
              <option value="Approve Manually">Approve Manually</option>
            </select>
            <label for="bidderApproval">Bidder Approval</label>
            <div
              *ngIf="isSubmitted || fc.bidderApproval.invalid && (fc.bidderApproval.dirty || fc.bidderApproval.touched)">
              <p class="text-danger" *ngIf="fc.bidderApproval.errors?.required">Required</p>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class=" mb-3 input-group">
                <span class="border  border-end-0 pt-3 px-1"
                [ngClass]="{'border-danger': fc.startDate.invalid || fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)}">
                  <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
                </span>

              <div class="form-floating">

                <input type="text" ngbDatepicker [minDate]="minDate" #startDate="ngbDatepicker" (click)="startDate.toggle()"
                class="form-control border-start-0" id="startDate" placeholder="Start Date" formControlName="startDate"
                [ngClass]="{'is-invalid': fc.startDate.invalid || fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)}">
                <label for="startDate">Start Date</label>
              </div>
            </div>
              <div *ngIf="isSubmitted || fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)">
                <p class="text-danger" *ngIf="fc.startDate.errors?.required">Required</p>
              </div>
            </div>
            <div class="col-6">
              <div class=" mb-3 input-group">
                <span class="border  border-end-0 pt-3 px-1" [ngClass]="{'border-danger': fc.startTime.invalid || fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)}">
                  <img src="assets/icons/time_light.svg" class="svg-icon-class c-pointer">
                </span>
              <div class="form-floating">

                <input type="text" class="form-control border-start-0" ngbPopover [autoClose]="'outside'" placement="top-right"
                  triggers="manual" #p1="ngbPopover" (click)="openTimepicker(p1, startTimeModal)" id="startTime"
                  placeholder="Start Time" formControlName="startTime"
                  [ngClass]="{'is-invalid': fc.startTime.invalid || fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)}">
                <label for="startTime">Start Time</label>
              </div>
            </div>
              <div *ngIf="isSubmitted || fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)">
                <p class="text-danger" *ngIf="fc.startTime.errors?.required">Required</p>
                <p class="text-danger" *ngIf="fc.startTime.errors?.pattern">Invalid</p>
              </div>
            </div>
          </div>
          <div class="mb-3 input-group">
            <div class="form-floating">
              <select placeholder="Soft Close" id="antiSnipe" class="form-control form-select" formControlName="antiSnipe">
                <option value="" disabled>Select . . .</option>
                <option [value]="1">1</option>
                <option [value]="2">2</option>
                <option [value]="3">3</option>
                <option [value]="4">4</option>
                <option [value]="5">5</option>
                <option [value]="6">6</option>
                <option [value]="7">7</option>
                <option [value]="8">8</option>
                <option [value]="9">9</option>
                <option [value]="10">10</option>
                <option [value]="11">11</option>
                <option [value]="12">12</option>
                <option [value]="13">13</option>
                <option [value]="14">14</option>
                <option [value]="15">15</option>
              </select>
              <label for="antiSnipe">Soft Close(Number Of minutes)</label>
            </div><button type="button" class="btn btn-grey border">Minutes</button>
          </div>

          <div *ngIf="fc.antiSnipe.invalid &&  (fc.antiSnipe.dirty || fc.antiSnipe.touched)">
            <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fc.antiSnipe.errors?.min">Invalid</p>
            <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fc.antiSnipe.errors?.max">Invalid</p>
            <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fc.antiSnipe.errors?.pattern">Invalid</p>
          </div>

          <div class="row" hidden>
            <div class="col-md-5">Premium Auction :</div>
            <div class="col-md-2 col-6">
              <input type="radio" formControlName="premiumAuction" id="t1" [value]="true">
              <label class="ms-3">Yes</label><br>
            </div>
            <div class="col-md-2 col-6">
              <input type="radio" formControlName="premiumAuction" id="t2" [value]="false">
              <label class="ms-3">No</label><br>
            </div>
          </div>

          <div class="row mt-3 mb-3" *ngIf="fc.auctionBiddingMethod.value == 'FORWARD_AUCTION'">
            <div class="col-md-5">Buy It Now (Pre Offer) :</div>
            <div class="col-md-2 col-6">
              <input type="radio" formControlName="preOffer" id="t5" [value]="true">
              <label class="ms-3">Yes</label><br>
            </div>
            <div class="col-md-2 col-6">
              <input type="radio" formControlName="preOffer" id="t6" [value]="false">
              <label class="ms-3">No</label><br>
            </div>
          </div>

          <div class="mb-3 input-group">
            <div class="form-floating">
              <select placeholder="Display Rank - Market Price" id="rankCurrentPriceConfig" class="form-control form-select" formControlName="rankCurrentPriceConfig" (change)="changeRankConfig()"
              [ngClass]="{'is-invalid': fc.rankCurrentPriceConfig.invalid || fc.rankCurrentPriceConfig.invalid && (fc.rankCurrentPriceConfig.dirty || fc.rankCurrentPriceConfig.touched)}">
                <option value="" disabled>Select . . .</option>
                <option *ngFor="let item of rankPriceConfigs" [value]="item">{{getRankPriceConfigName(item)}}</option>
              </select>
              <label for="rankCurrentPriceConfig">Display Rank - Market Price</label>
            </div>
          </div>

          <div class="row mt-3" *ngIf="isAllowToShowBidderHistoryConfig">
            <div class="col-md-12">Show Bidder History To Bidder :</div>
            <div class="col-md-12">
              <div class="row mt-2">
                <div class="col-md-3">
                  <input type="radio" formControlName="showBidderHistory" id="m1" [value]="'ALWAYS'">
                  <label class="ms-3">Always</label><br>
                </div>
                <div class="col-md-3">
                  <input type="radio" formControlName="showBidderHistory" id="m2" [value]="'NEVER'">
                  <label class="ms-3">Never</label><br>
                </div>
                <div class="col-md-6">
                  <input type="radio" formControlName="showBidderHistory" id="m3" [value]="'AFTER_AUCTION_CLOSE'">
                  <label class="ms-3">After Auction Close </label><br>
                </div>
            </div>
          </div>
          </div>

          <div class="row mt-3 mb-3" >
            <div class="col-md-5">Mask Bidder Details:</div>
            <div class="col-md-2 col-6">
              <input type="radio" formControlName="maskBidderDetails" id="m11" [value]="true">
              <label class="ms-3">Yes</label><br>
            </div>
            <div class="col-md-2 col-6">
              <input type="radio" formControlName="maskBidderDetails" id="m22" [value]="false">
              <label class="ms-3">No</label><br>
            </div>
          </div>

          <div class="row mt-3 mb-3" >
            <div class="col-md-8">Allow Edit While Auction is Upcoming:</div>
            <div class="col-md-2 col-6">
              <input type="radio" formControlName="allowEditWhileUpcoming" id="allowEditWhileUpcoming" [value]="true" (click)="openEditingConfirmModal(true, 'LIVE_WAIT')">
              <label class="ms-3">Yes</label><br>
            </div>
            <div class="col-md-2 col-6">
              <input type="radio" formControlName="allowEditWhileUpcoming" id="allowEditWhileUpcoming1" [value]="false" (click)="openEditingConfirmModal(false, 'LIVE_WAIT')">
              <label class="ms-3">No</label><br>
            </div>
          </div>

          <div class="row mt-3 mb-3" >
            <div class="col-md-8">Allow Edit While Auction is Running:</div>
            <div class="col-md-2 col-6">
              <input type="radio" formControlName="allowEditWhileRunning" id="allowEditWhileRunning" [value]="true" (click)="openEditingConfirmModal(true, 'LIVE')">
              <label class="ms-3">Yes</label><br>
            </div>
            <div class="col-md-2 col-6">
              <input type="radio" formControlName="allowEditWhileRunning" id="allowEditWhileRunning1" [value]="false" (click)="openEditingConfirmModal(false, 'LIVE')">
              <label class="ms-3">No</label><br>
            </div>
          </div>

          <div class="row mt-3" *ngIf="deploymentConfigurationDto?.enableBlockchain">
            <div class="col-md-5">Blockchain Enable :</div>
            <div class="col-md-2 col-6">
              <input type="radio" formControlName="enableBlockchain" id="t15" [value]="true">
              <label class="ms-3">Yes</label><br>
            </div>
            <div class="col-md-2 col-6">
              <input type="radio" formControlName="enableBlockchain" id="t16" [value]="false">
              <label class="ms-3">No</label><br>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-6">
              <div class="form-floating mt-3">
                <input type="text" class="form-control" (click)="auctionImg.click()"
                  [value]="this._fileDisplayName$ | async" id="auctionImg" />
                <input #auctionImg name="profile_pic" onclick="this.value=null;" type="file" class="d-none"
                  (change)="chooseFile($event)" />
                <label for="auctionImg">{{auctionEntityDto?.auctionImage ? 'Replace Image' : 'Add Image'}}</label>
                <div *ngIf="isSubmitted && !(_fileDisplayName$ |async)">
                  <p class="text-danger">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row  mt-3" *ngIf="imageUrl">
                <div class="col-6">
                  <img alt="" [src]="imageUrl"
                    [ngClass]="isActiveImage ? 'modal-logo-image-large' : 'modal-logo-image'" />
                </div>
                <div class="col-6 mt-2 text-end" >
                  <button class="btn btn-sm btn-new-class me-2"  (click)="openViewImageModal(imageUrl)">
                    <img src="assets/icons/view_dark.svg" class="svg-icon-class c-pointer" *ngIf="!isActiveImage">
                    <img src="assets/icons/zoomout_dark.svg" class="svg-icon-class c-pointer" *ngIf="isActiveImage">
                  </button>

                  <span  (click)="deleteImage()" [ngClass]="{'disabled' : isLoading }">
                    <img src="assets/icons/delete_danger.svg"  class="svg-icon-class c-pointer">
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="py-2" >
            <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
            <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" [message]="'Auction is updated successfully.'"></app-message-toast>
          </div>
          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class" (click)="handleSubmit()"
              [disabled]="!(_isSaveButtonEnable$ |async)">SAVE</button>
          </div>
          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner" *ngIf="isLoading"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>


<!-- NgbTimePicker -->
<ng-template #startTimeModal>
  <ngb-timepicker [(ngModel)]="startTime" [meridian]="true"></ngb-timepicker>
  <button class="btn btn-new-class btn-sm" (click)="setTime('startTime')">SAVE</button>
</ng-template>

<ng-template #allowEditingConfirmModalTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Change Configuration</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="resetEditingConfig()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p>
            Do you want to {{selectedEditingConfig ? 'enable' : 'disable'}} editing?
          </p>
        </div>

        <div class="text-end mb-3">
          <button type="button" class="btn btn-new-class btn-sm me-3" (click)="resetEditingConfig()">
            CANCEL
          </button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="updateEditingConfig()">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
