<div class="border rounded bg-white">
  <header class="p-2">
    <div class="row ">
      <div class="col-md-4 ">
        <h5 class="heading-h5" ngbAutoFocus>  IMAGES</h5>
      </div>
      <div class="col-md-8 text-md-end">
        <button class="btn btn-new-class btn-sm px-4 me-3" (click)="openSortingModal(sortingModal)"
          *ngIf="subcategoryEntityDto?.subcategoryImages && subcategoryEntityDto?.subcategoryImages!.length > 1" [disabled]="!isAllowToEdit()">
          <!-- <img src="assets/icons/reorder_dark.svg" class=" c-pointer svg-icon-class "> -->
          REORDER
        </button>
        <button class="btn btn-new-class btn-sm px-4 " (click)="openAddModal(addModal)" [disabled]="!isAllowToEdit()">
          <!-- <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-small"> -->
          ADD
        </button>
      </div>
    </div>
  </header>
  <hr class="hr" />
  <div class="col-12 div-class py-2 px-3">
   List of images to appear in sequence on listing page
  </div>
  <hr class="hr" />
  <div class="col-12 my-3 text-center" *ngIf="!subcategoryEntityDto?.subcategoryImages || subcategoryEntityDto?.subcategoryImages!.length == 0">
    <h3 class="heading-new text-dark">No image uploaded.</h3>
  </div>
<div class="multiple-list-scroll">
  <div class="row p-2" *ngFor="let item of subcategoryEntityDto?.subcategoryImages; index as i">
    <div class="col-10 " #subcategoryImagesListRef>
      <img [src]="getImageUrl(item)" class="creator-image-class" alt="image">
      <div class="e-spinner" *ngIf="isDataLoading && currentIndex == i" [ngClass]="{'inline-spinner' : isDataLoading}">
      </div>
    </div>
    <div class="col-2  text-end mt-3" *ngIf="isAllowToDeleteLastImage()">
      <img src="assets/icons/delete_danger.svg" (click)="deleteImage(i)"
      class="svg-icon-class c-pointer" [ngClass]="{'disabled': !isAllowToEdit()}">
    </div>
    <hr class="hr  mt-2"  *ngIf = "i != (subcategoryEntityDto?.subcategoryImages)!.length - 1" />
  </div>
</div>

</div>



<!-- <ul #lotImagesListRef class="scroll-images">
  <li class="loc-caption auction-image" title="location 3">
    <div class="bg-secondary me-3">
      <div class="p-3">

      </div>
      <div class="col-12 text-end">
        <button class="btn btn-outline-primary btn-sm rounded-circle" (click)="deleteImage(i)">
          <fa-icon [icon]="['fas', 'trash-can']" class=" fa-lg"></fa-icon>
        </button>
      </div>
    </div>
  </li>
</ul> -->
<ng-template #addModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>SUBCATEGORY {{ctrlFileType.value}}S</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" novalidate>
        <div class="row mb-3">
          <div class="col-6">
            <input type="radio" [formControl]="ctrlFileType" value="IMAGE" id="ctrlFileType">
            Images
          </div>
          <div class="col-6">
            <input type="radio" [formControl]="ctrlFileType" value="VIDEO" id="ctrlFileType">
            Videos
          </div>
        </div>
        <div class="form-floating mb-3" *ngIf="ctrlFileType.value == 'IMAGE'">
          <input readonly type="text" (click)="subcategoryImageRef.click()" id="multiFiles"
            value="{{currentMultipleFilesPath.length}} files selected" class="form-control" />
          <input #subcategoryImageRef multiple type="file" id="multiFilesUpload" onclick="this.value=null" (change)="chooseMultipleFiles($event)"
            class="d-none form-control" />
          <label for="ctrlFileType">Upload Image</label>
        </div>
        <div class="col-md-12" style="overflow: auto; white-space: nowrap;" *ngIf="ctrlFileType.value == 'IMAGE'">
          <div class="e-formimage me-2 mb-3" [ngClass]="{'ee-active': activeImage == i}"
            *ngFor="let item of currentMultipleFilesPath; index as i">
            <span class="ee-image">
              <img alt="" [src]="item" />
            </span>
            <button class="btn btn-new-class btn-sm mx-2 mt-3" [disabled]="isLoading"
               (click)="openViewImageModal(item)">
              <img src="assets/icons/view_dark.svg"  class="svg-icon-class c-pointer" *ngIf="!(activeImage == i)">
              <img src="assets/icons/zoomout_dark.svg"  class="svg-icon-class c-pointer" *ngIf="activeImage == i">
            </button>
            <span [ngClass]="{'disabled' : isLoading }"
              (click)="removeMultiImage(i)">
              <img src="assets/icons/delete_danger.svg"  class="svg-icon-class c-pointer mt-3">
            </span>
          </div>
        </div>
        <div class="form-floating mb-3" *ngIf="ctrlFileType.value == 'VIDEO'">
          <input type="text" class="form-control" placeholder="Video Url" id="ctrlVideoUrl" [formControl]="ctrlVideoUrl" />
          <label for="ctrlVideoUrl">Upload Embed URL of the Video</label>
        </div>
        <div class="mb-3">
          <app-message-toast *ngIf="(_showSuccessToast$ | async) && ctrlFileType.value == 'VIDEO'" [message]="'Event video linked successfully.'"></app-message-toast>
          <app-message-toast *ngIf="(_showSuccessToast$ | async) && ctrlFileType.value == 'IMAGE'" [message]="currentMultipleFiles.length > 1 ? 'Event Images saved successfully.' : 'Event Image saved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
        </div>

        <div class="mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class" [disabled]="isLoading || !(_isImageSelected$ | async) || !isAllowToEdit()"
            (click)="uploadMultiFiles()" *ngIf="ctrlFileType.value == 'IMAGE'">ADD</button>
          <button type="button" class="btn btn-new-class" [disabled]="isLoading || !isAllowToEdit()"
            (click)="uploadVideoFile()" *ngIf="ctrlFileType.value == 'VIDEO'">
            ADD
          </button>
        </div>
      </form>
    </div>
   </div>

  </div>
</ng-template>

<ng-template #sortingModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Images</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top" style="overflow-y: hidden !important;">
      <div class="m-3" style="height: 400px; overflow-x: hidden;" cdkDropList (cdkDropListDropped)="drop($event)">
        <div class="row droplist" *ngFor="let item of subcategoryImages; index as i" cdkDrag
          [cdkDragDisabled]="isLoading">
          <div class="col-6" title="location 3">
            <div class="me-3">
              <div class="p-3">
                <img [src]="getImageUrl(item)" class="carousal-image" alt="image">
              </div>
              <!-- <div class="col-12 text-end">
                <button class="btn btn-outline-primary btn-sm rounded-circle" (click)="deleteImage(i)">
                  <fa-icon [icon]="['fas', 'trash-can']" class=" fa-lg"></fa-icon>
                </button>
              </div> -->
            </div>
          </div>
          <div class="col-6 text-end mt-4">
            <img src="assets/icons/reorder_light.svg" class=" c-pointer svg-icon-class ms-2">
          </div>
          <hr class="hr my-1" *ngIf="!(i == subcategoryImages.length -1) ">
        </div>


      </div>
      <div class="mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>
      <div class="mb-3 mx-2">
        <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Subcategory Images saved successfully.'"></app-message-toast>
        <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
          [message]="'Error while saving images. Try again.'"></app-message-toast>
      </div>

      <div class="d-grid gap-2 px-3 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="btn btn-new-class" [disabled]="isLoading || !isAllowToEdit()"
          (click)="saveSubcategoryImages()">UPDATE</button>
      </div>
    </div>
  </div>
</ng-template>

