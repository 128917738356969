<div class="container my-5">
  <div class="row align-items-center">
    <div class="col-md-3  text-start mb-2">
      <div class="mb-2 input-group">
        <input #search placeholder="Search" type="text" id="ctrlSearchPR"
          class="form-control border-end-0 rounded-0 border bg-white" />
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
    </div>
    <div class="col-md-5 text-start mb-2">
    </div>
    <div class="col-md-4 mb-2 text-md-end">
      <button class="btn btn-sm btn-primary-clr-class mt-2 mt-sm-0" (click)="openAddProjectModal(addProjectModalTemplate)">
        Add Projects
      </button>
    </div>
  </div>
  <div class="card bg-white">
    <p-treeTable [value]="tableTreeData" [scrollable]="true" [paginator]="true" [rows]="50"
      [rowsPerPageOptions]="[50, 100]" [loading]="isDataLoading" [tableStyle]="{'min-width':'50rem'}">
      <ng-template pTemplate="header">
        <tr>
          <th>Event Code</th>
          <th>Name</th>
          <th>Type</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr [ttRow]="rowNode">
          <td>
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            {{ rowData.code }}
          </td>
          <td>{{ rowData.name }}</td>
          <td>{{ rowData.type }}</td>
          <td *ngIf="rowData.type != 'PR'">
            <div class="" [ngClass]="{'border-left-success' : rowData?.status! == 'LIVE', 'border-left-grey' : rowData?.status! == 'DRAFT' , 'border-left-danger' : rowData?.status! == 'CLOSE'
            , 'border-left-primary' : rowData?.status! == 'LIVE_WAIT'}">
                <span class="ms-2">{{ rowData.status }}</span>
              </div>
          </td>
          <td *ngIf="rowData.type == 'PR'">
            <div class="" [ngClass]="{'border-left-success' : rowData?.status! == 'COMPLETED', 'border-left-grey' : rowData?.status! == 'APPROVAL_PENDING' , 'border-left-info' : rowData?.status! == 'APPROVAL_APPROVED'
            , 'border-left-primary' : rowData?.status! == 'SUBMITTED'}">
                <span class="ms-2">{{ formatPrStatus(rowData.status) }}</span>
              </div>
            </td>
          <td>
            <div class="animate-dropdown" ngbDropdown #menuDropdown="ngbDropdown" container="body"
              placement="bottom-right" *ngIf="rowData.type == 'PROJECT'">
              <span ngbDropdownToggle data-bs-toggle="dropdown">
                <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class-small">
              </span>
              <ul ngbDropdownMenu class="shadow mt-1 ">
                <li ngbDropdownItem (click)="openAddProjectModal(addProjectModalTemplate, rowData)">
                  View
                </li>
                <hr class="hr my-2">
                <li ngbDropdownItem (click)="openAddRFXModal('PQ', rowData)">
                  PQ
                </li>
                <hr class="hr my-2">
                <li ngbDropdownItem (click)="openAddRFXModal('RFQ', rowData)">
                  RFI
                </li>
                <hr class="hr my-2">
                <li ngbDropdownItem (click)="openAddRFXModal('RFQ', rowData)">
                  RFP
                </li>
                <hr class="hr my-2">
                <li ngbDropdownItem (click)="openAddRFXModal('RFQ', rowData)">
                  RFQ
                </li>
                <hr class="hr my-2">
                <li ngbDropdownItem (click)="openAddAuctionModal(rowData)">
                  Auction
                </li>
              </ul>
            </div>
            <div *ngIf="rowData.type != 'PROJECT' && rowData.type != 'PR'">
              <a class="link-class" (click)="openEventStagePage(rowNode.node, rowNode.parent)"
                *ngIf="currentEventCode != rowData.code">View</a>
              <div class="spinner-border text-primary" role="status" *ngIf="rowData.code == currentEventCode && isNavLoading">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-treeTable>
  </div>
</div>

<ng-template #addProjectModalTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>{{selectedProjectUiDto ? 'EDIT' : 'ADD'}} PROJECT</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="addProject()">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="name" formControlName="name"
              [ngClass]="{'is-invalid': fc['name'].invalid && (fc['name'].dirty || fc['name'].touched)}">
            <label for="name">Name</label>
            <div *ngIf="fc['name'].invalid && (fc['name'].dirty || fc['name'].touched)">
              <p class="text-danger" *ngIf="fc['name'].errors?.['required']">Required
              </p>
            </div>
          </div>

          <label for="description">Description</label>
          <div class="mb-3">
            <textarea type="text" class="form-control" id="description" formControlName="description" rows="3"
              [ngClass]="{'is-invalid': fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)}"></textarea>
            <div *ngIf="fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)">
              <p class="text-danger" *ngIf="fc['description'].errors?.['required']">Required
              </p>
            </div>
          </div>

          <div class="col-12 mb-3">
            <label for="file-upload" class="custom-file-upload">
              <div>
                <img src="assets/icons/cloud-upload.svg" class="svg-icon-class-md c-pointer me-2">
              </div>
              Drag or drop here or browse file to upload
            </label>
            <input id="file-upload" type="file" onclick="this.value=null;" (change)="chooseFile($event)" />
          </div>

          <div class="col-12 mb-3">
            <div class="row medium-grey-font px-2" *ngFor="let document of selectedDocuments; index as i">
              <div class="col-8">
                <img src="assets/icons/file_danger.svg" class="svg-icon-class c-pointer">
                {{document.displayName}}
              </div>
              <div class="col-3 text-end">
                {{formatBytes(document.fileSize)}}
              </div>
              <div class="col-1 text-end">
                <img src="assets/icons/delete_dark.svg" class="svg-icon-class-small c-pointer"
                  (click)="removeSelectedDocument(document.fileId!)">
              </div>
            </div>

            <div class="mb-3" *ngIf="isUploadLoading">
              <div class="e-spinner"></div>
            </div>
          </div>

          <div class="my-3">
            <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async">
            </app-message-toast>
            <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
              message="Project {{selectedProjectUiDto ? 'updated' : 'created'}} successfully.">
            </app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class" (click)="addProject()">
              {{selectedProjectUiDto ? 'UPDATE' : 'SAVE'}}
            </button>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

        </form>
      </div>
    </div>
  </div>
</ng-template>
