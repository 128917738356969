<div class="model-body ">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>
          Financial Questionnaire
        </h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
          (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="header-divider"></div>
  <div class="model-body-top">
    <div class="m-3 height-of-modal">
      <div class="row">
        <div class="col-md-3 border-end mb-3">
          <div class="row mb-2">
            <div class="col-5">
              Section
            </div>
            <div class="col-7 text-end">
              <button class="btn btn-sm btn-new-class">
                Add New Section
              </button>
            </div>
          </div>
          <div>
            <div class=" row">
              <div class="medium-font col-8 border-end py-1">
                Select Section
              </div>
              <div class="col-4 text-center medium-font py-1">
                Re-order
              </div>
            </div>
            <div class="row py-1 align-items-center" [ngClass]="isTechnicalActive? 'active': ''">
              <div class="col-8  medium-font">
                Computer
              </div>
              <div class="col-4 text-center">
                <img src="assets/icons/reorder_dark.svg" class="svg-icon-class-small c-pointer">
              </div>
            </div>
            <div class="row align-items-center py-1" [ngClass]="!isTechnicalActive? 'active': ''">
              <div class="col-8 border-end medium-font">
                Networking
              </div>
              <div class="col-4 text-center">
                <img src="assets/icons/reorder_light.svg" class="svg-icon-class-small c-pointer">
              </div>
            </div>
            <div class="row align-items-center py-1" [ngClass]="!isTechnicalActive? 'active': ''">
              <div class="col-8 border-end medium-font">
                Peripherals
              </div>
              <div class="col-4 text-center">
                <img src="assets/icons/reorder_light.svg" class="svg-icon-class-small c-pointer">
              </div>
            </div>
          </div>
          <div class="mt-2 text-end">
            <button class="btn btn-sm btn-outline-primary">
              Add Section
            </button>
          </div>
          <div class=" text-center mt-5">
            <button class="btn btn-sm btn-new-class">
              <span class="small-font text-white">
                Save as template
              </span>
            </button>
          </div>
        </div>
        <div class="col-md-9 mb-3">
          <div class="row ">
            <div class="col-md-6 mb-2">
              <div class="row">
                <div class="col-10 pe-0">
                  <div class="input-group">
                    <div class="form-floating">
                      <input class="form-control border-end-0 " id="computer">
                      <label for="computer">Computer</label>
                    </div>
                    <span class="input-group-text bg-white border-start-0 ">
                      <a class="link-class-small" (click)="openFinEditModal(finEditModal)">Edit</a>
                    </span>
                  </div>
                </div>
                <!-- <div class="col-1 mt-2">
                  <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                </div> -->
              </div>
            </div>
            <div class="col-md-6 text-md-end mb-2">
              <span class="medium-font me-2">All Item View</span>
              <span class="form-switch c-pointer">
                <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" />
              </span>
            </div>
          </div>
          <div class="hr-class my-2"></div>
          <div class="row mb-3">
            <div class="col-12">
              <span class="me-2">
                <a class="link-class-small">Previous</a>
              </span>
              <span class="me-2 medium-font">
                Question 1 to 12
              </span>
              <span class="me-2">
                <a class="link-class-small">Next</a>
              </span>
              <span>
                <span class="me-1 badge small-badge-grey">
                  Draft
                </span>
                <span class="medium-font me-2">
                  10
                </span>
                <span class="me-1 badge small-badge-primary">
                  Published
                </span>
                <span class="medium-font">
                  2
                </span>
              </span>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-7 mb-3">
              <span class="medium-font me-2">
                10
              </span>
              <span class="me-1 badge small-badge-grey">
                Draft
              </span>
            </div>
            <div class="col-md-5 text-md-end mb-3">
              <button class="btn btn-sm btn-outline-primary me-3">
                Import Items
              </button>
              <button class="btn btn-sm btn-new-class">
                Add New Item
              </button>
            </div>
          </div>
         <app-financial-question></app-financial-question>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #finEditModal>
  <div class="model-body ">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 class="modal-heading">
            Edit Items
          </h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="header-divider"></div>
    <div class="model-body-top">
      <div class="m-3 height-of-modal">
        <div class="col-12">
          <div class="row mb-2">
            <div class="col-md-6">
              <div class="row">
                <div class="col-10 pe-0">
                  <div class="input-group">
                    <div class="form-floating">
                      <input class="form-control border-end-0 " id="computer">
                      <label for="computer">Computer</label>
                    </div>
                    <span class="input-group-text bg-white border-start-0 ">
                      <a class="link-class-small" (click)="openFinEditModal(finEditModal)">Edit</a>
                    </span>
                  </div>
                </div>
                <!-- <div class="col-1 mt-2">
                    <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                  </div> -->
              </div>
            </div>
            <div class="col-md-6 text-md-end">
              <span class="medium-font me-2">All Item View</span>
              <span class="form-switch c-pointer">
                <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" />
              </span>
            </div>
          </div>
          <div class="hr-class my-2"></div>
          <div class="row mb-3">
            <div class="col-md-7">
              <span class="medium-font me-2">
                10
              </span>
              <span class="me-1 badge small-badge-grey">
                Draft
              </span>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-11 pe-0">
              <div class="input-group">
                <div class="form-floating">
                  <input class="form-control " id="itemname">
                  <label for="itemname">Item Name
                  </label>
                </div>
              </div>
            </div>
            <div class="col-1 mt-2">
              <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-11 pe-0">
              <div class="input-group">
                <div class="form-floating">
                  <input class="form-control " id="itemDescrition">
                  <label for="itemDescrition">Item description
                  </label>
                </div>
              </div>
            </div>
            <div class="col-1 mt-2">
              <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
            </div>
          </div>
          <div class="mb-3">
            <p class="medium-grey-font">
              Set Parameters
            </p>
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-6">
                    <div div class="row">
                      <div class="medium-font col-8">Response Mandatory?</div>
                      <div class="col-4">
                        <span class="form-switch c-pointer">
                          <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="medium-font col-8">No Response</div>
                      <div class="col-4">
                        <span class="form-switch c-pointer">
                          <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="medium-font col-8">Bidder Comments Required?</div>
                      <div class="col-4">
                        <span class="form-switch c-pointer">
                          <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="medium-font col-8">Disqualify for no response</div>
                      <div class="col-4">
                        <span class="form-switch c-pointer">
                          <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div>
                  <a class="link-class">Click to upload Sample</a>
                </div>
                <div>
                  <a class="link-class">newdoc.pdf</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 w-50" *ngIf="!isSelectOption">
            <div ngbDropdown class="dropdown " #statusNgbDropdown1="ngbDropdown" [autoClose]="true">
              <button class="btn select-box-btn-class  text-start dropdown-toggle mb-2 rounded-0"
                type="button" data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
                <div class="row">
                  <div class="col-9">
                    <span class="medium-grey-font">Select response type</span>
                  </div>
                  <div class="col-3 text-end">
                    <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-small">
                  </div>
                </div>
              </button>
              <ul class="dropdown-menu text-start shadow mt-5 dropdown-width-class " ngbDropdownMenu>
                <li>
                  test
                </li>
              </ul>
            </div>
          </div>
          <div class="row" *ngIf="isSelectOption">
            <div class="col-md-4 text-center">
              <div class="mb-4 ">
                Quantity
              </div>
              <div class="input-group">
                <div class="form-floating">
                  <input class="select-box-btn-tech-ques1 ">
                </div>
              </div>
            </div>
            <div class="col-md-4 text-center">
              <div class="mb-4 ">
                Unit of Measurement(Uom)
              </div>
              <div class="input-group">
                <div class="form-floating">
                  <input class="select-box-btn-tech-ques1 ">
                </div>
              </div>
            </div>
            <div class="col-md-4 text-center">
              <div class="mb-4">
                Tax Rate
              </div>
              <div class="input-group">
                <div class="form-floating">
                  <input class="select-box-btn-tech-ques1 ">
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center mt-3">
            <button class="btn btn-sm btn-outline-primary me-3">
              Save as draft
            </button>
            <button class="btn btn-sm btn-new-class">
              Publish
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>