import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AddressDto } from 'src/app/shared/models/Address';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-onboarding-plants',
  templateUrl: './onboarding-plants.component.html',
  styleUrls: ['./onboarding-plants.component.sass']
})
export class OnboardingPlantsComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  ctrlSearch: FormControl = new FormControl('')

  isDataLoading: boolean = true;
  isLoading: boolean = false;
  isShowPlantView: boolean = false;

  shippingLatitude?: number;
  shippingLongitude?: number;
  billingLatitude?: number;
  billingLongitude?: number;
  shippingCountryShortName?: string
  billingCountryShortName?: string

  selectedPlantUiDto?: PlantUiDto
  selectedCompanyUiDto?: CompanyUiDto;
  selectedBillingAddress?: AddressDto;
  selectedShippingAddress?: AddressDto;
  plantUiDtos: PlantUiDto[] = [];
  filteredPlantEntityDtos: PlantUiDto[] = [];

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _filteredPlantEntityDtos$ = new BehaviorSubject<PlantUiDto[]>([]);

  plantsListSubscription$?: Subscription;
  selectedCompanySubscription$?: Subscription;

  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  zoom = 12;
  positionBilling?: google.maps.LatLngLiteral;
  positionShipping?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  markerBilling = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }
  markerShipping = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }

  constructor(
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private changeDetectRef: ChangeDetectorRef,
    private onboardingService: OnboardingService,
  ) {
    this.formGroup = this.fb.group({
      plantName: ['', Validators.required],
      plantCode: ['', Validators.required],
      accountTitle: ['', Validators.required],
      accNumber: ['', Validators.required],
      accHolderName: ['', Validators.required],
      accType: ['', Validators.required],
      accCode: ['', Validators.required],
      swift: ['', Validators.required],
      iban: ['', Validators.required],
      shippingAddress: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: ['', this.validBillingLocation()],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: ['', Validators.pattern(Pattern.zipCode)],
      }),
      billingAddress: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: ['', this.validBillingLocation()],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: ['', Validators.pattern(Pattern.zipCode)],
      }),
    })
  }

  ngOnInit(): void {
    this.loadPlantUiDto();

    this.ctrlSearch.valueChanges.subscribe((val) => {
      this.search(val);
    })

    this.plantsListSubscription$ = this.onboardingService.getPlantUiDtos$.subscribe(data => {
      if (data) {
        this.plantUiDtos = data;
        this.refreshPlantsList();

        if (this.selectedPlantUiDto) {
          this.selectedPlantUiDto = this.plantUiDtos.find(item => item.plantCode == this.selectedPlantUiDto?.plantCode);
        }
      } else {
        this.plantUiDtos = [];
        this.filteredPlantEntityDtos = this.plantUiDtos;
        this._filteredPlantEntityDtos$.next(this.filteredPlantEntityDtos);
      }
    })

    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;
        this.refreshPlantsList();
        
      } else {
        this.selectedCompanyUiDto = undefined;
      }
    })
  }

  get fc(): any { return this.formGroup.controls; }

  async loadPlantUiDto() {
    let orgCode = this.onboardingService.getOrgCode();
    this.isDataLoading = true;
    await this.onboardingService.loadPlantUiDtosByOrgCode(orgCode!);
    this.isDataLoading = false;
  }

  refreshPlantsList() {
    if (this.selectedCompanyUiDto) {
      this.filteredPlantEntityDtos = this.plantUiDtos.filter(item => item.companyCode == this.selectedCompanyUiDto?.companyCode);
      this._filteredPlantEntityDtos$.next(this.filteredPlantEntityDtos);
    } else {
      this.filteredPlantEntityDtos = this.plantUiDtos;
      this._filteredPlantEntityDtos$.next(this.filteredPlantEntityDtos);
    }
  }

  validShippingLocation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValidLocation = this.shippingLatitude != undefined;
      return control.value && !isValidLocation ? { address: { value: control.value } } : null;
    }
  }

  validBillingLocation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValidLocation = this.billingLatitude != undefined;
      return control.value && !isValidLocation ? { address: { value: control.value } } : null;
    }
  }
  
  search(searchText: string) {
    let plantUiDtos = [...this.plantUiDtos ?? []];

    if (searchText.trim() != '') {
      let filteredPlantEntityDtos = plantUiDtos.filter((item) => item.plantName?.toLowerCase().includes(searchText.trim().toLowerCase()) || item.plantCode?.toLowerCase()?.includes(searchText.trim()));
      this.filteredPlantEntityDtos = filteredPlantEntityDtos;
    } else {
      this.filteredPlantEntityDtos = plantUiDtos;
    }

    this._filteredPlantEntityDtos$.next(this.filteredPlantEntityDtos);
  }

  populatePlantDetails() {
    this.formGroup.controls['plantCode'].patchValue(this.selectedPlantUiDto?.plantCode);
    this.formGroup.controls['plantName'].patchValue(this.selectedPlantUiDto?.plantName);
    this.formGroup.controls['accountTitle'].patchValue(this.selectedPlantUiDto?.accountTitle);
    this.formGroup.controls['accNumber'].patchValue(this.selectedPlantUiDto?.accNumber);
    this.formGroup.controls['accHolderName'].patchValue(this.selectedPlantUiDto?.accHolderName);
    this.formGroup.controls['accType'].patchValue(this.selectedPlantUiDto?.accType);
    this.formGroup.controls['accCode'].patchValue(this.selectedPlantUiDto?.accCode);
    this.formGroup.controls['swift'].patchValue(this.selectedPlantUiDto?.swift);
    this.formGroup.controls['iban'].patchValue(this.selectedPlantUiDto?.iban);

    if (this.selectedPlantUiDto?.billingAddress && this.selectedPlantUiDto.billingAddress.length > 0) {
      this.selectedBillingAddress = this.selectedPlantUiDto.billingAddress[0];

      this.billingLatitude = parseFloat(this.selectedBillingAddress.latitude!);
      this.billingLongitude = parseFloat(this.selectedBillingAddress.longitude!);
      this.billingCountryShortName = this.selectedBillingAddress.countryShortName;

      this.positionBilling = { lat: this.billingLatitude, lng: this.billingLongitude }
      this.markerBilling.label.text = this.selectedBillingAddress.searchAddress!;
      this.markerBilling.title = this.selectedBillingAddress.searchAddress!;
      this.markerBilling.info = this.selectedBillingAddress.searchAddress!;

      this.formGroup.controls['billingAddress'].patchValue(this.selectedBillingAddress);
    }

    if (this.selectedPlantUiDto?.shippingAddress && this.selectedPlantUiDto.shippingAddress.length > 0) {
      this.selectedShippingAddress = this.selectedPlantUiDto.shippingAddress[0];

      this.shippingLatitude = parseFloat(this.selectedShippingAddress.latitude!);
      this.shippingLongitude = parseFloat(this.selectedShippingAddress.longitude!);
      this.shippingCountryShortName = this.selectedShippingAddress.countryShortName;

      this.positionShipping = { lat: this.shippingLatitude, lng: this.shippingLongitude }
      this.markerShipping.label.text = this.selectedShippingAddress.searchAddress!;
      this.markerShipping.title = this.selectedShippingAddress.searchAddress!;
      this.markerShipping.info = this.selectedShippingAddress.searchAddress!;

      this.formGroup.controls['shippingAddress'].patchValue(this.selectedShippingAddress);
    }

    this.formGroup.updateValueAndValidity();
    this.changeDetectRef.detectChanges();
  }

  viewPlantDetails(plantUiDto: PlantUiDto) {
    this.isShowPlantView = true;
    this.selectedPlantUiDto = plantUiDto;
    this.populatePlantDetails()
  }

  backToAllPlantsList() {
    this.isShowPlantView = false;
    this.selectedPlantUiDto = undefined;
    this.selectedBillingAddress = undefined;
    this.selectedShippingAddress = undefined;
  }

  openPlantDetail(content: any) {
    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);
    this._isSaveButtonEnable$.next(false);

    this.ngbModal.open(content, { size: 'xl', backdrop: 'static', keyboard: false, centered: true })
  }

  // openAddBillingAddress(content: any) {
  //   this.isLoading = false;
  //   this._showErrorMsg$.next("")
  //   this._showErrorToast$.next(false);
  //   this._isSaveButtonEnable$.next(false);

  //   this.populatePopulateDetails();
  //   this.ngbModal.open(content, { size: 'md', backdrop: 'static', keyboard: false, centered: true })
  // }

  // openAddShippingAddress(content: any) {
  //   this.isLoading = false;
  //   this._showErrorMsg$.next("")
  //   this._showErrorToast$.next(false);
  //   this._isSaveButtonEnable$.next(false);

  //   this.populatePopulateDetails();
  //   this.ngbModal.open(content, { size: 'md', backdrop: 'static', keyboard: false, centered: true })
  // }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  handleBillingAddressChange(address: Address) {
    this.billingLatitude = address.geometry.location.lat();
    this.billingLongitude = address.geometry.location.lng();

    let city = ApplicationUtils.getAddressByType(address, 'locality');
    if (!city) {
      city = ApplicationUtils.getAddressByType(address, 'neighborhood');
    }
    let state = ApplicationUtils.getAddressByType(address, 'administrative_area_level_1');
    let country = ApplicationUtils.getAddressByType(address, 'country');
    this.billingCountryShortName = ApplicationUtils.getAddressShortNameByType(address, 'country');

    let zip = ApplicationUtils.getAddressByType(address, 'postal_code');
    let addressPart1 = ApplicationUtils.getAddressByType(address, 'street_number');
    let addressPart2 = ApplicationUtils.getAddressByType(address, 'route');

    let addressLine1 = address.formatted_address;
    if (addressPart1 == '' || addressPart2 == '') {
      if (address.formatted_address.includes(',')) {
        addressLine1 = address.formatted_address.split(',')[0];
      }
    } else {
      addressLine1 = addressPart1 + " " + addressPart2;
    }

    this.formGroup.controls['billingAddress'].get('addressLine1')?.patchValue(addressLine1);
    this.formGroup.controls['billingAddress'].get('searchAddress')?.patchValue(address.formatted_address);
    this.formGroup.controls['billingAddress'].get('city')?.patchValue(city);
    this.formGroup.controls['billingAddress'].get('state')?.patchValue(state);
    this.formGroup.controls['billingAddress'].get('country')?.patchValue(country);
    this.formGroup.controls['billingAddress'].get('zipCode')?.patchValue(zip);
    this.formGroup.updateValueAndValidity();

    this.changeDetectRef.detectChanges();
  }

  handleShippingAddressChange(address: Address) {
    this.shippingLatitude = address.geometry.location.lat();
    this.shippingLongitude = address.geometry.location.lng();

    let city = ApplicationUtils.getAddressByType(address, 'locality');
    if (!city) {
      city = ApplicationUtils.getAddressByType(address, 'neighborhood');
    }
    let state = ApplicationUtils.getAddressByType(address, 'administrative_area_level_1');
    let country = ApplicationUtils.getAddressByType(address, 'country');
    this.shippingCountryShortName = ApplicationUtils.getAddressShortNameByType(address, 'country');

    let zip = ApplicationUtils.getAddressByType(address, 'postal_code');
    let addressPart1 = ApplicationUtils.getAddressByType(address, 'street_number');
    let addressPart2 = ApplicationUtils.getAddressByType(address, 'route');

    let addressLine1 = address.formatted_address;
    if (addressPart1 == '' || addressPart2 == '') {
      if (address.formatted_address.includes(',')) {
        addressLine1 = address.formatted_address.split(',')[0];
      }
    } else {
      addressLine1 = addressPart1 + " " + addressPart2;
    }

    this.formGroup.controls['shippingAddress'].get('addressLine1')?.patchValue(addressLine1);
    this.formGroup.controls['shippingAddress'].get('searchAddress')?.patchValue(address.formatted_address);
    this.formGroup.controls['shippingAddress'].get('city')?.patchValue(city);
    this.formGroup.controls['shippingAddress'].get('state')?.patchValue(state);
    this.formGroup.controls['shippingAddress'].get('country')?.patchValue(country);
    this.formGroup.controls['shippingAddress'].get('zipCode')?.patchValue(zip);
    this.formGroup.updateValueAndValidity();

    this.changeDetectRef.detectChanges();
  }

  mergePlantUiDto() {
    let formValue = this.formGroup.getRawValue();
    let plantUiDto = ApplicationUtils.clone(this.selectedPlantUiDto) as PlantUiDto;

    plantUiDto.plantName = formValue.plantName;
    plantUiDto.plantCode = formValue.plantCode;
    plantUiDto.accountTitle = formValue.accountTitle;
    plantUiDto.accNumber = formValue.accNumber;
    plantUiDto.accHolderName = formValue.accHolderName;
    plantUiDto.accType = formValue.accType;
    plantUiDto.accCode = formValue.accCode;
    plantUiDto.swift = formValue.swift;
    plantUiDto.iban = formValue.iban;

    if (!plantUiDto.shippingAddress) {
      plantUiDto.shippingAddress = [];
    }

    if (!plantUiDto.billingAddress) {
      plantUiDto.billingAddress = [];
    }

    let billingAddress = new AddressDto();
    billingAddress.addressType = formValue.billingAddress.addressType;
    billingAddress.searchAddress = formValue.billingAddress.searchAddress;
    billingAddress.addressLine1 = formValue.billingAddress.addressLine1;
    billingAddress.addressLine2 = formValue.billingAddress.addressLine2;
    billingAddress.city = formValue.billingAddress.city;
    billingAddress.state = formValue.billingAddress.state;
    billingAddress.country = formValue.billingAddress.country;
    billingAddress.zipCode = formValue.billingAddress.zipCode;
    billingAddress.mobileNo = formValue.billingAddress.mobileNo;
    billingAddress.latitude = this.billingLatitude?.toString();
    billingAddress.longitude = this.billingLongitude?.toString();
    billingAddress.countryShortName = this.billingCountryShortName;

    let shippingAddress = new AddressDto();
    shippingAddress.addressType = formValue.shippingAddress.addressType;
    shippingAddress.searchAddress = formValue.shippingAddress.searchAddress;
    shippingAddress.addressLine1 = formValue.shippingAddress.addressLine1;
    shippingAddress.addressLine2 = formValue.shippingAddress.addressLine2;
    shippingAddress.city = formValue.shippingAddress.city;
    shippingAddress.state = formValue.shippingAddress.state;
    shippingAddress.country = formValue.shippingAddress.country;
    shippingAddress.zipCode = formValue.shippingAddress.zipCode;
    shippingAddress.mobileNo = formValue.shippingAddress.mobileNo;
    shippingAddress.latitude = this.shippingLatitude?.toString();
    shippingAddress.longitude = this.shippingLongitude?.toString();
    shippingAddress.countryShortName = this.shippingCountryShortName;

    if (plantUiDto.billingAddress.length > 0) {
      billingAddress.id = plantUiDto.billingAddress[0].id;
      shippingAddress.id = plantUiDto.shippingAddress[0].id;

      Object.assign(plantUiDto.billingAddress[0], billingAddress);
      Object.assign(plantUiDto.shippingAddress[0], shippingAddress);
    } else {
      billingAddress.id = UUID.UUID().toString();
      shippingAddress.id = UUID.UUID().toString();

      plantUiDto.shippingAddress.push(shippingAddress);
      plantUiDto.billingAddress.push(billingAddress);
    }

    return plantUiDto;
  }

  saveOnboardingPlants() {
    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);
    this._showErrorMsg$.next("");
    this._showErrorToast$.next(false);

    if (this.formGroup.invalid) {
      return;
    }

    let plantUiDto = this.mergePlantUiDto()

    this.onboardingService.savePlantUiDto(plantUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;

          let newPlantDto = apiResponseDto.data as PlantUiDto;
          this.onboardingService.updatePlantUiDto(newPlantDto)

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this._isSaveButtonEnable$.next(true);
            this.closeModal();
          }, 2000)
        } else {
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving Plant");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })
  }

  
  openMarkerInfo(marker: MapMarker) {
    this.info.open(marker)
  }

  ngOnDestroy(): void {
    if (this.plantsListSubscription$) {
      this.plantsListSubscription$.unsubscribe();
    }
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }
  }
}
