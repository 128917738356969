import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PreferenceCategoryDto } from '../../models/user/PreferenceCategoryDto';
import { TreeNode } from 'primeng/api';
import { CategoryDto, CategoryParentWrapperDto } from '../../models/CategoryDto';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { Subscription, debounceTime, Subject } from 'rxjs';
import { TreeSelectionMode } from '../../enums/TreeSelectionMode';

@Component({
  selector: 'app-categories-tree-view',
  templateUrl: './categories-tree-view.component.html',
  styleUrls: ['./categories-tree-view.component.sass']
})
export class CategoriesTreeViewComponent implements OnInit, OnDestroy {
  @Input() selectionMode: TreeSelectionMode = TreeSelectionMode.single;
  @Output() onSelectedCategory = new EventEmitter<PreferenceCategoryDto>();
  @Output() onSelectedCategories = new EventEmitter<PreferenceCategoryDto[]>();

  loading: boolean = false;
  nodes!: TreeNode[];
  originalNodes!: TreeNode[];
  selectedFiles?: TreeNode | TreeNode[];

  masterCategories: CategoryParentWrapperDto[] = [];

  masterCategoriesSubscription$?: Subscription;

  private searchSubject: Subject<string> = new Subject<string>();
  private expandedNodes: string[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private adminService: AdminDashboardService
  ) { }

  ngOnInit() {
    this.loading = true;

    this.masterCategoriesSubscription$ = this.adminService.getMasterCategories$.subscribe((dataList) => {
      if (dataList) {
        this.masterCategories = dataList;
        this.originalNodes = this.convertToTreeNodes(this.masterCategories);
        this.nodes = [...this.originalNodes];

        this.loading = false;
        this.cd.markForCheck();
      } else {
        this.masterCategories = [];
      }
    });

    this.searchSubject.pipe(debounceTime(300)).subscribe(query => {
      this.filterNodes(query);
    });
  }

  convertToTreeNodes(data: CategoryParentWrapperDto[]): TreeNode[] {
    return data.map(parentDto => this.createTreeNode(parentDto.categoryDto, parentDto));
  }

  createTreeNode(category: CategoryDto, wrapper: any): TreeNode {
    const children = this.getChildren(wrapper);
    const node: TreeNode = {
      key: category.keyId,
      label: category.titleName,
      leaf: !children.length,
      data: wrapper,
      children: children.map(childWrapper => this.createTreeNode(childWrapper.categoryDto, childWrapper))
    };

    if (this.expandedNodes.includes(node.key!)) {
      node.expanded = true;
    }

    return node;
  }

  getChildren(wrapper: any): any[] {
    if (wrapper.categoryLevelOneWrapperDtos) {
      return wrapper.categoryLevelOneWrapperDtos;
    }
    if (wrapper.categoryLevelTwoWrapperDtos) {
      return wrapper.categoryLevelTwoWrapperDtos;
    }
    if (wrapper.categoryLevelThreeWrapperDtos) {
      return wrapper.categoryLevelThreeWrapperDtos;
    }
    if (wrapper.categoryLevelFourWrapperDtos) {
      return wrapper.categoryLevelFourWrapperDtos;
    }
    return [];
  }

  onSearchInput(event: any) {
    const query = event.target.value.toLowerCase();
    this.searchSubject.next(query);
  }

  onNodeSelect(event: any) {
    const node = event.node;
    if (!node.leaf) {
      event.originalEvent.preventDefault();
      this.selectedFiles = undefined;
      this.onSelectedCategory.emit(undefined);
      return;
    }
  
    if (this.selectionMode === TreeSelectionMode.single) {
      this.selectedFiles = node;
      this.emitSelectedCategory(node);
    } else {
      const selectedNodes = this.selectedFiles as TreeNode[];
      this.selectedFiles = selectedNodes.filter(n => n.leaf);
      this.emitSelectedCategories(this.selectedFiles);
    }
  }

  onNodeUnselect(event: any) {
    if (this.selectionMode === TreeSelectionMode.single) {
      this.selectedFiles = undefined;
      this.onSelectedCategory.emit(undefined);
    } else {
      const selectedNodes = this.selectedFiles as TreeNode[];
      this.selectedFiles = selectedNodes.filter(n => n.leaf);
      this.emitSelectedCategories(this.selectedFiles);
    }
  }

  emitSelectedCategory(node: TreeNode) {
    const parentNode4 = node.parent;
    const parentNode3 = parentNode4?.parent;
    const parentNode2 = parentNode3?.parent;
    const parentNode1 = parentNode2?.parent;

    const preferenceCategory = new PreferenceCategoryDto();
    preferenceCategory.categoryName = parentNode1?.label;
    preferenceCategory.categoryLevelOneName = parentNode2?.label;
    preferenceCategory.categoryLevelTwoName = parentNode3?.label;
    preferenceCategory.categoryLevelThreeName = parentNode4?.label;
    preferenceCategory.categoryLevelFourName = node.label;
    preferenceCategory.categoriesId = `${parentNode1?.key}:${parentNode2?.key}:${parentNode3?.key}:${parentNode4?.key}:${node?.key}`;

    this.onSelectedCategory.emit(preferenceCategory);
  }

  emitSelectedCategories(nodes: TreeNode[]) {
    const preferenceCategories: PreferenceCategoryDto[] = nodes.map(node => {
      const parentNode4 = node.parent;
      const parentNode3 = parentNode4?.parent;
      const parentNode2 = parentNode3?.parent;
      const parentNode1 = parentNode2?.parent;

      const preferenceCategory = new PreferenceCategoryDto();
      preferenceCategory.categoryName = parentNode1?.label;
      preferenceCategory.categoryLevelOneName = parentNode2?.label;
      preferenceCategory.categoryLevelTwoName = parentNode3?.label;
      preferenceCategory.categoryLevelThreeName = parentNode4?.label;
      preferenceCategory.categoryLevelFourName = node.label;
      preferenceCategory.categoriesId = `${parentNode1?.key}:${parentNode2?.key}:${parentNode3?.key}:${parentNode4?.key}:${node?.key}`;

      return preferenceCategory;
    });

    this.onSelectedCategories.emit(preferenceCategories);
  }

  filterNodes(query: string) {
    if (query) {
      const filteredNodes = this.filterTree(query, this.originalNodes);
      this.nodes = filteredNodes;
    } else {
      this.nodes = [...this.originalNodes];
    }
    this.cd.markForCheck();
  }

  filterTree(query: string, nodes: TreeNode[]): TreeNode[] {
    return nodes
      .map(node => {
        const copyNode: TreeNode = { ...node, children: [] };
        if (this.nodeContainsQuery(node, query)) {
          copyNode.children = node.children;
          this.expandParents(node);
          return copyNode;
        } else if (node.children) {
          const filteredChildren = this.filterTree(query, node.children);
          if (filteredChildren.length) {
            copyNode.children = filteredChildren;
            return copyNode;
          }
        }
        return null;
      })
      .filter(node => node !== null) as TreeNode[];
  }

  nodeContainsQuery(node: TreeNode, query: string): boolean {
    return node.label!.toLowerCase().includes(query);
  }

  expandParents(node: TreeNode) {
    if (node.parent) {
      node.parent.expanded = true;
      this.expandedNodes.push(node.parent.key as string);
      this.expandParents(node.parent);
    }
  }

  ngOnDestroy(): void {
    if (this.masterCategoriesSubscription$) {
      this.masterCategoriesSubscription$.unsubscribe();
    }
  }
}
