<div class="border rounded bg-white">
  <header class=" p-2">
    <div class="row ">
      <div class="col-md-4">
        <h5 class="heading-h5" ngbAutoFocus>DOCUMENTS</h5>
      </div>
      <div class="col-md-8 text-md-end ">
        <button class="btn btn-new-class btn-sm px-4 me-3" *ngIf="auctionEntityDto?.auctionDocs && auctionEntityDto?.auctionDocs!.length > 1"
        (click)="openSortingModal(sortingDocumentModal)" [disabled]="!isAllowToEdit()">
        <!-- <img src="assets/icons/reorder_dark.svg" class=" c-pointer svg-icon-class"> -->
        REORDER
        </button>
        <button class="btn btn-new-class btn-sm px-4 "  (click)="openAddModal(addDocumentModal)" [disabled]="!isAllowToEdit()">
          <!-- <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-small"> -->
          ADD
        </button>
      </div>
    </div>
  </header>
  <hr class="hr" />
  <div class="col-12 mb-3">
    <div class=" div-class p-2">
      List of documents
    </div>
    <hr class="hr" />
    <div class="col-12 my-3 text-center" *ngIf="!auctionEntityDto?.auctionDocs || auctionEntityDto?.auctionDocs!.length == 0">
      <h3 class="heading-new text-dark">No document uploaded.</h3>
    </div>
   <div class="multiple-list-scroll">
    <div class="row mx-2" *ngFor="let item of auctionEntityDto?.auctionDocs; index as i">
      <div class="col-2 text-start py-2" (click)="openAddModal(addDocumentModal, item)">
        <img src="assets/icons/file_light.svg" class=" c-pointer svg-icon-class ms-2">
      </div>
      <div class="col-8  div-class py-2 c-pointer" (click)="openAddModal(addDocumentModal, item)">
        {{item?.displayName}}
      </div>
      <div class="col-2 text-end py-2">
           <img src="assets/icons/delete_danger.svg" (click)="deleteAuctionDocument(i)"
           class="svg-icon-class c-pointer" [ngClass]="{'disabled' : !isAllowToEdit()}">
      </div>
      <div class="e-spinner" *ngIf="isDataLoading && currentIndex == i" [ngClass]="{'inline-spinner' : isDataLoading}">
      </div>
      <hr class="hr"  *ngIf = "i != (auctionEntityDto?.auctionDocs)!.length - 1"/>
    </div>
   </div>
  </div>
</div>


<ng-template #addDocumentModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 *ngIf="!isEditDocument">ADD DOCUMENT</h2>
          <h2 *ngIf="isEditDocument">EDIT DOCUMENT</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" novalidate (keyup.enter)="handleValidSubmit()">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="auctionDocTitle" placeholder="Document Name"
            [formControl]="auctionDocTitle">
          <label for="auctionDocTitle">Document Name</label>
        </div>

        <div class="row align-items-center">
          <div class="col-6">
            <div class="form-floating">
              <input id="documentDoc" type="text" (click)="documentDoc.click()" [value]="_fileDisplayName$ |async"
                class="form-control"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && !(_fileDisplayName$ |async)}" />
              <input #documentDoc onclick="this.value=null;" type="file" (change)="chooseFile($event)" class="d-none" />
              <label for="documentAdd" *ngIf="!(currentFile || currentFileInfoDto)">Add Document</label>
              <label for="documentReplace" *ngIf="currentFile || currentFileInfoDto">Replace Document</label>

              <div>
                <p class="text-danger" *ngIf="(_validationErrorPresent$ |async) && !(_fileDisplayName$ |async)">Required
                </p>
              </div>

            </div>
          </div>
          <div class="col-6" *ngIf="currentFileInfoDto || currentFile">
            <div class="ms-3">
              <button class="btn btn-sm btn-new-class me-2" *ngIf="currentFileInfoDto" (click)="previewAuctionDoc('SERVER')" ngbTooltip="{{currentFileInfoDto.displayName}}">
                <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class">
              </button>
              <button class="btn btn-sm btn-new-class me-2" *ngIf="currentFile && !currentFileInfoDto" (click)="previewAuctionDoc('LOCAL')">
                <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class ">
              </button>
              <span [ngClass]="{'disabled':_isSaveButtonDisable$ |async}"
                (click)="removeAuctionDoc()">
                <img src="assets/icons/delete_danger.svg"
                class="svg-icon-class c-pointer">
              </span>
            </div>
          </div>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Document saved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
        </div>

        <div class="mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class" (click)="handleValidSubmit()" [disabled]="!isAllowToEdit()">{{isEditDocument? 'EDIT' : 'ADD'}}</button>
        </div>
      </form>
    </div>
   </div>
  </div>
</ng-template>

<ng-template #sortingDocumentModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus>DOCUMENTS</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" novalidate>
        <div class="col-12 my-3"  style="max-height: 400px; overflow-x: hidden; overflow-y: auto;" cdkDropList (cdkDropListDropped)="drop($event)">
          <div class="row droplist mb-2" *ngFor="let item of auctionDocuments; index as i" cdkDrag
          [cdkDragDisabled]="_isSaveButtonDisable$ | async">
          <div class="drag-placeholder col-12" *cdkDragPlaceholder></div>
            <div class="col-2">
                <img src="assets/icons/file_light.svg" class=" c-pointer svg-icon-class ms-2">
            </div>
            <div class="col-6  font-weight-normal ">
              {{item?.displayName}}
            </div>
            <div class="col-4 text-end ">
              <img src="assets/icons/reorder_light.svg" class=" c-pointer svg-icon-class">
              <!-- <button class="btn btn-outline-primary btn-sm rounded-circle c-pointer  top-margin-modal">
                <fa-icon [icon]="['fas', 'trash']" class=" fa-lg"></fa-icon>
              </button> -->
            </div>
            <hr class="hr my-1" *ngIf="!(i == auctionDocuments.length -1) ">
          </div>

        </div>
        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Document saved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
        </div>
        <div class="mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>
        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class" (click)="handleValidSubmit()" [disabled]="!isAllowToEdit()">UPDATE</button>
        </div>
      </form>
    </div>
   </div>
  </div>
</ng-template>
