<div class=" p-2" id="bottom">
  <div class="col-12 ">
    <div class="row justify-content-between">
      <div class="col-md-4">
        <h5 class="heading-h5 text-behind-svg-icon me-2 ">LOT SETUP</h5>
        <h5 class="heading-h5 text-behind-svg-icon" ngbTooltip="{{auctionLotEntityDto?.title}}" container="body">
          <span
            *ngIf="(_isLotInfoHide$ | async) && auctionLotEntityDto?.title">({{truncateString(auctionLotEntityDto?.title,
            90)}})</span>
        </h5>
        <span class="ms-3"
          [ngClass]="{ 'badge-draft-class' : getLotStatus() == 'DRAFT', 'badge-warning-class' : getLotStatus() == 'UPCOMING', 'badge-primary-class' : getLotStatus() == 'RUNNING', 'badge-danger-class' : getLotStatus() == 'CLOSED'}">
          {{getLotStatus()}}
        </span>
      </div>
      <div class="col-md-8 text-md-end">
        <span class="me-3" *ngIf=" (screenWidth > 768) && !this.isSingleLot">
          <span class="span-class me-2">
            Show Table View
          </span>
          <span class="form-switch ">
            <input class="form-check-input c-pointer" type="checkbox" id="tableView" (click)="changeLotView()"
              [checked]="_isShowTableView$ |async" /></span>
        </span>
        <span class="">
          <button class="btn-outline-primary btn btn-sm me-3" (click)="openLotDetailsOne('ADD')"
            *ngIf="!(_isShowTableView$ |async)" [disabled]="!isAllowToEdit()">
            ADD
          </button>
          <button class="btn-outline-primary btn btn-sm me-3"
            (click)="openAllLotsActivationModal(enableAllLotsModal)" [disabled]="!isAllowToEdit()">
            ACTIVATE ALL LOTS
          </button>
         
          <span *ngIf="!auctionEntityDto?.singleLot">

            <button class="btn-outline-primary btn-sm btn mt-2 mt-md-0"
              *ngIf="!(auctionEntityDto?.status == 'DISCARD') && auctionLotEntityDtos && auctionLotEntityDtos!.length > 1"
              (click)="openAuctionLotDiscardModel(discardAuctionLotModal)" [disabled]="!isAllowToEdit()">
              DISCARD
            </button>
          </span>
          <button class="btn-new-class btn-sm btn mt-2 ms-3 mt-md-0"
          (click)="openDisableUserModal(enableDisabledAuctionLot)">
          {{auctionLotEntityDto?.active ? 'ACTIVE' : 'INACTIVE'}}
        </button>

        </span>
      </div>
      <!-- <div class="col-md-6 mt-2 mt-md-0 text-start text-md-end">
        <div class="btn-group top-margin">
          <button class="btn btn-sm btn-outline-primary" [disabled]="!isSingleLot" *ngIf="this.deploymentConfigurationDto?.singleLotAllowed"
            [ngClass]="{'selected' : isSingleLot }" (click)="changeLotType()">SINGLE LOT</button>
          <button class="btn btn-sm btn-outline-primary" [ngClass]="{'selected' : !(isSingleLot) }" *ngIf="this.deploymentConfigurationDto?.multiLotAllowed"
            (click)="changeLotType()">MULTI LOT</button>
        </div>
        <fa-icon [icon]="['fas', 'file']" class="text-primary fa-lg-bold ms-3 c-pointer" *ngIf="this.deploymentConfigurationDto?.multiLotAllowed && !(isSingleLot)"
          (click)="openCSVFile(modalCSVFile)"></fa-icon>
      </div> -->
    </div>
  </div>
  <hr class="hr mt-2" />
  <div>
    <div *ngIf="!(_isLotInfoHide$ | async) && !(_isShowTableView$ | async)">
      <div class="col-12  my-3">

        <app-lot-sequence-carousel [isShowValidation]="true"></app-lot-sequence-carousel>

        <!-- <div class="row mx-0 align-items-center justify-content-center"
          *ngIf="this.deploymentConfigurationDto?.multiLotAllowed && !(auctionEntityDto?.singleLot)">
          <div class="col-md-1 col-2 p-0 text-md-end">
            <button class="scroll-left-icon-bidder" (click)="previousLot()">
              <img src="assets/icons/leftarrow2_dark.svg" class="svg-icon-class c-pointer">
            </button>
          </div>
          <div class="col-8 col-md-9 mt-4 ms-md-5 ">
            <swiper #swiperLots class="lot-swiper" [virtual]="true" [config]="swiperConfig"
              (slideChange)="onSlideChange()">
              <ng-template swiperSlide *ngFor="let item of auctionLotEntityDtos; index as i">
                <button class="btn px-3 py-2 btn-sm me-2 mt-2"
                  [ngClass]="{'selected' : auctionLotEntityDto?.lotId == item?.lotId, 'danger-border':haveAnyLotErrors(item.lotId!), 'secondary-border' : !haveAnyLotErrors(item.lotId!)}"
                  (click)="changeCurrentLot(item.lotId!, i)">
                  <span [ngClass]="haveAnyLotErrors(item.lotId!) ? '' : ''"> {{item?.lotSequence}}</span>
                </button>
              </ng-template>
            </swiper>
          </div>
          <div class="col-md-1 col-2 p-0 text-md-end">
            <button class="scroll-right-icon-bidder" (click)="nextLot()">
              <img src="assets/icons/rightarrow2_dark.svg" class="svg-icon-class c-pointer">
            </button>
          </div>
        </div> -->

        <div class="col-md-10 m-auto">

          <div class="row my-3 justify-content-end align-items-center"
            *ngIf="this.deploymentConfigurationDto?.multiLotAllowed">

            <div class="col-md-auto text-md-end mb-2 mb-md-0 ps-3 ps-md-0" *ngIf="!auctionEntityDto?.singleLot">
              <div class="dropdown" ngbDropdown>
                <button ngbDropdownToggle class="btn btn-new-class dropdown-toggle" type="button"
                  data-bs-toggle="dropdown" aria-expanded="false" ngbTooltip="{{selectedLotDropdown}}" placement="top"
                  container="body">
                  <div class="">
                    <img src="assets/icons/sort_dark.svg" class="svg-icon-class c-pointer">
                    {{truncateString(selectedLotDropdown, 50)}}
                  </div>
                </button>
                <ul class="dropdown-menu scroll-area-lg mt-4" ngbDropdownMenu>
                  <li class="c-pointer" *ngFor="let item of auctionLotEntityDtos"
                    (click)="changeCurrentLotById(item?.lotId!)">
                    <a class="dropdown-item" ngbDropdownItem>{{item?.lotSequence}} - {{item?.title? item?.title : '
                      '}}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3 text-md-end">
              <!-- <div class="mb-2 col-6 form-floating" *ngIf="!auctionEntityDto?.singleLot">
                  <select #selectedLot class="form-control bg-secondary form-select"
                    (change)="changeCurrentLotById(selectedLot.value)">
                    <option *ngFor="let item of auctionLotEntityDtos" [value]="item?.lotId"
                      [selected]="auctionLotEntityDto?.lotId == item?.lotId">
                      {{item?.lotSequence}} - {{item?.title}}
                    </option>
                  </select>
                  <label for="floatingInput">Select</label>
                </div> -->

              <!-- <div class=" input-group mb-2 mb-md-0">
                <input id="typeahead-basic" type="text"
                  class="form-control font-weight-normal border-end-0 rounded-0 border bg-white"
                  [(ngModel)]="selectedValue" placeholder="Search by keywords" [ngbTypeahead]="search"
                  (ngModelChange)="changeCurrentLotBySearch()" />
                <span class="input-group-append">
                  <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                    <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
                  </button>
                </span>
              </div> -->
              <!-- <div class="mb-2 mb-md-0 input-group" *ngIf="!auctionEntityDto?.singleLot">
                <input id="typeahead-basic" type="text" class="form-control font-weight-normal border-end-0 rounded-0 border bg-white" [(ngModel)]="selectedValue" placeholder="Search by keywords" [ngbTypeahead]="search" (ngModelChange)="changeCurrentLotBySearch()"/>
              </div> -->

            </div>
          </div>
        </div>
        <!-- <hr class="hr" *ngIf="!auctionEntityDto?.singleLot" />
        <div class="col-10 m-auto">
          <div class="row my-2">
            <div class="col-md-5 text-start mt-2 mb-2 mb-md-0" *ngIf="auctionLotEntityDto">
              <span
                [ngClass]="{ 'badge-draft-class' : getLotStatus() == 'DRAFT', 'badge-warning-class' : getLotStatus() == 'UPCOMING', 'badge-primary-class' : getLotStatus() == 'RUNNING', 'badge-danger-class' : getLotStatus() == 'CLOSED'}">
                {{getLotStatus()}}
              </span>
            </div>
            <div class="col-md-7 text-start text-md-end" *ngIf="auctionLotEntityDto">
              <div class="text-md-end text-start">
                <span class="span-class">Bidders can see/ search only active lots </span>
                <span class="form-switch mx-3 ">
                  <input class="form-check-input mt-2  c-pointer" type="checkbox"
                    [ngClass]="!auctionEntityDto?.singleLot ? 'mt-md-1' : 'mt-md-0'" id="flexSwitchCheckChecked1"
                    [formControl]="lotActiveCheckbox" (click)="openDisableUserModal(enableDisabledAuctionLot)" />
                </span>
                <span class="mt-3 mt-md-0 span-class">
                  <b class="me-3">{{auctionLotEntityDto.active ? 'Active' :
                    'Inactive'}}</b>
                </span>


                <button class="btn-outline-primary btn-sm btn mt-2 mt-md-0"
                  *ngIf="!(auctionEntityDto?.status == 'DISCARD') && auctionLotEntityDtos && auctionLotEntityDtos!.length > 1"
                  (click)="openAuctionLotDiscardModel(discardAuctionLotModal)" [disabled]="!isAllowToEdit()">
                  DISCARD
                </button>
              </div>
            </div>
          </div>
        </div>

      </div> -->

        <div class="col-10 m-auto mt-2" *ngIf="auctionLotEntityDtos && auctionLotEntityDtos!.length > 0">
          <div class="row">
            <div class=" col-md-6 ">
              <div class="border  rounded bg-white" [ngClass]="{'border-danger border-2' : haveLotDetailsErrors()}">
                <header class="p-2">
                  <div class="row ">
                    <div class="col-md-6">
                      <h5 class="heading-h5"> DETAILS</h5>
                    </div>
                    <div class="col-md-6 text-md-end">
                      <button class="btn btn-new-class btn-sm px-4 " (click)="openLotDetailsOne('EDIT')"
                        [disabled]="!isAllowToEdit()">
                        <!-- <img src="assets/icons/edit_dark.svg" class="svg-icon-class c-pointer"> -->
                        EDIT
                      </button>
                    </div>
                  </div>
                </header>
                <hr class="hr" />

                <div class="col-12 div-class py-2 px-3">
                  Lot Information
                </div>
                <div class="row px-3">
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    #
                  </div>
                  <div class="col-8 div-class py-2">
                    {{auctionLotEntityDto?.lotSequence}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-danger':!auctionLotEntityDto?.title}">
                    Title
                  </div>
                  <div class="col-8 div-class py-2">
                    {{auctionLotEntityDto?.title}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Description
                  </div>
                  <div class="col-8 div-class py-2">
                    {{truncateText(auctionLotEntityDto?.description)}}
                    <div class="text-end btn-link ms-3 c-pointer"
                      (click)="openShowMoreModal(auctionLotEntityDto?.title!, auctionLotEntityDto?.description!)"
                      *ngIf="auctionLotEntityDto?.description && auctionLotEntityDto?.description!.length > 101">SHOW
                      MORE
                    </div>
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2"
                    [ngClass]="{'text-danger':!auctionLotEntityDto?.endDate || !auctionLotEntityDto?.endTime || haveAnyErrorInEndDate()}">
                    Ending on
                  </div>
                  <div class="col-8 div-class py-2">
                    {{getDisplayDate(auctionLotEntityDto?.endDate, auctionLotEntityDto?.endTime)}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Address
                  </div>
                  <div class="col-8 div-class py-2">
                    {{getSelectAddressFromAuctionEntityDto(auctionLotEntityDto?.address!)}}
                  </div>

                </div>
              </div>
            </div>
            <div class=" col-md-6 mt-2 mt-md-0">
              <app-auction-lot-category></app-auction-lot-category>
            </div>
            <div class=" col-md-6 mt-2">
              <app-auction-lot-bid-details></app-auction-lot-bid-details>
            </div>
            <div class=" col-md-6 mt-2">
              <div class="border rounded bg-white">
                <header class="p-2">
                  <div class="row ">
                    <div class="col-md-6">
                      <h5 class="heading-h5"> OTHER SETTING</h5>
                    </div>
                    <div class="col-md-6 text-md-end">
                      <button class="btn btn-new-class btn-sm px-4 " (click)="openLotModal(pickupAndInspectionModal)"
                        [disabled]="!isAllowToEdit()">
                        <!-- <img src="assets/icons/edit_dark.svg" class="svg-icon-class c-pointer"> -->
                        EDIT
                      </button>
                    </div>
                  </div>
                </header>
                <hr class="hr" />
                <div class="col-12 div-class py-2 px-3">
                  Additional Optional Information
                </div>
                <hr class="hr" />
                <div class="row px-3">
                  <div class="col-4 font-weight-normal py-2">
                    Inspection Detail
                  </div>
                  <div class="col-8 div-class py-2">
                    <p class="p-class animate-show-more" #inspectionDetailRef
                      [innerHTML]="auctionLotEntityDto?.inspectionDetails ?? '' | safe: 'html'"
                      [ngClass]="{'hide-description': !(isShowInspectionDetail$ |async) && inspectionDetailRef.scrollHeight > 120}">
                    </p>
                    <div class="bold-border my-2" *ngIf="inspectionDetailRef.scrollHeight > 120"></div>
                    <div class="col-12 text-end btn-link" (click)="showOrHideInspectionDetail()"
                      *ngIf="inspectionDetailRef.scrollHeight > 120">
                      {{(isShowInspectionDetail$ |async) ? 'HIDE MORE' : 'SHOW MORE'}}
                    </div>

                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Pickup Detail
                  </div>
                  <div class="col-8 div-class py-2">
                    <p class="p-class animate-show-more" #pickUpDetailsRef
                      [innerHTML]="auctionLotEntityDto?.pickUpDetails ?? '' | safe: 'html'"
                      [ngClass]="{'hide-description': !(isShowPickUpDetails$ |async) && pickUpDetailsRef.scrollHeight > 120}">
                    </p>
                    <div class="bold-border my-2" *ngIf="pickUpDetailsRef.scrollHeight > 120"></div>
                    <div class="col-12 text-end btn-link" (click)="showOrHidePickUpDetails()"
                      *ngIf="pickUpDetailsRef.scrollHeight > 120">
                      {{(isShowPickUpDetails$ |async) ? 'HIDE MORE' : 'SHOW MORE'}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" col-md-6 mt-2">
              <div class="border rounded bg-white">
                <header class="p-2">
                  <div class="row ">
                    <div class="col-md-6">
                      <h5 class="heading-h5" ngbAutoFocus> FEATURES</h5>
                    </div>
                    <div class="col-md-6 text-md-end">
                      <button *ngIf="auctionLotEntityDto?.lotFeatures &&  auctionLotEntityDto?.lotFeatures!.length > 1"
                        class="btn btn-new-class btn-sm px-4 me-3" (click)="openLotFeatureSorting(lotFeatureSorting)"
                        [disabled]="!isAllowToEdit()">

                        <!-- <img src="assets/icons/reorder_dark.svg" class="svg-icon-class c-pointer"> -->
                        REORDER
                      </button>
                      <button class="btn btn-new-class btn-sm px-4 " (click)="openLotFeature('ADD', 0)"
                        [disabled]="!isAllowToEdit()">
                        <!-- <img src="assets/icons/addnew_dark.svg" class="svg-icon-class c-pointer"> -->
                        ADD
                      </button>
                    </div>
                  </div>
                </header>
                <hr class="hr" />
                <div class="col-12 div-class py-2 px-3">
                  List of features to appear in sequence on listing page
                </div>
                <hr class="hr" />
                <div class="col-12 my-3 text-center"
                  *ngIf="!auctionLotEntityDto?.lotFeatures || auctionLotEntityDto?.lotFeatures!.length == 0">
                  <h5 class="heading-new text-dark">Features not added yet.</h5>
                </div>
                <div class="multiple-list-scroll">
                  <div class="row p-2" *ngFor="let lotFeature of auctionLotEntityDto?.lotFeatures; index as i">
                    <div class="col-md-10" (click)="openLotFeature('EDIT', i)">
                      <div class="btn-group mb-3">
                        <div class="btn-new-landing float-start" container="body" placement="top"
                          ngbTooltip="{{lotFeature?.featureKey}}"
                          [ngClass]="{'overflow-class-small': lotFeature?.featureKey!.length > 30}">
                          {{lotFeature?.featureKey}}
                        </div>
                        <div class="btn-new-landing-2 me-2  float-start" ngbTooltip="{{lotFeature?.featureValue}}"
                          container="body" placement="top"
                          [ngClass]="{'overflow-class-small': lotFeature?.featureValue!.length > 30}">
                          {{lotFeature?.featureValue}}</div>
                      </div>
                    </div>
                    <div class="col-md-2 text-end">
                      <img src="assets/icons/delete_danger.svg" (click)="removeLotFeature(i)"
                        class="svg-icon-class c-pointer">
                    </div>
                    <div class="m-3" *ngIf="isLotFeaturesLoading && i == deleteIndex">
                      <div class="e-spinner"></div>
                    </div>
                    <div class="m-3" *ngIf="(_showErrorToast$ | async) && (i == deleteIndex)">
                      <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
                        [message]="_errorMsg$ |async"></app-message-toast>
                    </div>

                    <hr class="hr" *ngIf="i != (auctionLotEntityDto?.lotFeatures)!.length - 1" />
                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-6 mt-2 ">
              <app-auction-premium-supplier></app-auction-premium-supplier>
            </div>
            <div class=" col-md-6 mt-2">
              <app-auction-lot-images></app-auction-lot-images>
            </div>

          </div>
        </div>
      </div>
      <div *ngIf="!(_isLotInfoHide$ | async) && (_isShowTableView$ | async)">
        <app-bulk-lots-verification></app-bulk-lots-verification>
      </div>
    </div>
  </div>

  <ng-template #lotFeatureSorting>
    <div class="model-body">
      <header class="m-2">
        <div class="row justify-content-between">
          <div class="col-9 d-flex align-items-center">
            <h2>LOT FEATURES</h2>
          </div>
          <div class="col-auto text-end" [ngClass]="{'disabled': isLotFeaturesLoading}">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
          </div>
        </div>
      </header>
      <div class="page-divider"></div>
      <div class="m-3">
        <div class="mb-3">
          Please ensure currect and relevant feature is captured as this information will be shown on auction listing as
          is
        </div>
        <form class="needs-validation" novalidate>
          <div class="col-12 text-center" style="max-height: 400px; overflow-x: hidden; overflow-y: auto;" cdkDropList
            (cdkDropListDropped)="drop($event)">
            <div class="mb-3 droplist" *ngFor="let lotFeature of lotFeatures; index as i" cdkDrag
              [cdkDragDisabled]="isLotFeaturesLoading">
              <div class="row justify-content-center">

                <div class="col-auto">
                  <div class="btn-new-landing float-start" container="body" placement="top"
                    ngbTooltip="{{lotFeature?.featureKey}}"
                    [ngClass]="{'overflow-class-xsmall': lotFeature?.featureKey!.length > 30}">
                    {{lotFeature?.featureKey}}
                  </div>
                  <div class="btn-new-landing-2 me-2 float-start" ngbTooltip="{{lotFeature?.featureValue}}"
                    container="body" placement="top"
                    [ngClass]="{'overflow-class-xsmall': lotFeature?.featureValue!.length > 30}">
                    {{lotFeature?.featureValue}}</div>
                </div>
                <div class="col-1">
                  <img src="assets/icons/reorder_light.svg" class="svg-icon-class c-pointer me-2">
                  <!-- <img src="assets/icons/delete_danger.svg"  class="svg-icon-class c-pointer" (click)="removeLotFeature(i)"> -->
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <app-message-toast *ngIf="_showSuccessToast$ | async"
              [message]="'Lot saved successfully.'"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="_errorMsg$ |async"></app-message-toast>
          </div>
          <div class="m-3" *ngIf="isLotFeaturesLoading">
            <div class="e-spinner"></div>
          </div>
          <div class="d-grid gap-2" *ngIf="!isLotFeaturesLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class" (click)="saveLotFeature()"
              [disabled]="!isAllowToEdit()">UPDATE</button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <ng-template #pickupAndInspectionModal>
    <div class="model-body">
      <header class="m-2">
        <div class="row justify-content-between">
          <div class="col-9 d-flex align-items-center">
            <h2>Pickup & Inspection Details</h2>
          </div>
          <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
          </div>
        </div>
      </header>
      <div class="page-divider"></div>
      <div class="m-3">
        <form [formGroup]="formGroupOthers">
          <textarea class="form-control mt-3" formControlName="pickup" placeholder="pickup"
            (input)="checkUserInput()"></textarea>
          <textarea class="form-control mt-3" formControlName="inspection" placeholder="inspection"
            (input)="checkUserInput()"></textarea>
        </form>
      </div>
      <div class="m-3">
        <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Lot saved successfully.'"></app-message-toast>
        <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
          [message]="_errorMsg$ |async"></app-message-toast>
      </div>
      <div class="m-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>
      <div class="d-grid gap-2 m-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="btn btn-new-class"
          [disabled]="!(this._isSaveButtonEnable$ | async) || !isAllowToEdit()"
          (click)="savePickupAndInspection()">SAVE</button>
      </div>
    </div>
  </ng-template>

  <ng-template #enableDisabledAuctionLot>
    <div class="model-body">
      <header class="m-2">
        <div class="row justify-content-between">
          <div class="col-9 d-flex align-items-center">
            <h2>{{lotActiveStatus ? 'DISABLE' : 'ENABLE'}} LOT </h2>
          </div>
          <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
          </div>
        </div>
      </header>
      <div class="page-divider"></div>
      <div class="m-3">
        <div class="">
          <p>Do you want to {{lotActiveStatus ? 'disable' : 'enable' }} Lot</p>
        </div>
        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ | async" [isError]="false"
            message="Lot {{auctionLotEntityDto?.active ? 'enabled' : 'disabled'}} successfully.">
          </app-message-toast>
        </div>
        <div class="text-end mb-3" *ngIf="auctionLotEntityDto && !isLoading&& !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-sm me-3" (click)="closeModal()">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary"
            (click)="markAuctionLotActiveOrInActive('ENABLE')" *ngIf="!auctionLotEntityDto?.active"
            [disabled]="!(_isSaveButtonEnable$ | async) || !isAllowToEdit()">ENABLE</button>
          <button type="button" class="btn btn-sm btn-outline-primary"
            (click)="markAuctionLotActiveOrInActive('DISABLE')" *ngIf="auctionLotEntityDto?.active"
            [disabled]="!(_isSaveButtonEnable$ | async) || !isAllowToEdit()">DISABLE</button>
        </div>
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #discardAuctionLotModal>
    <div class="model-body">
      <header class="m-2">
        <div class="row justify-content-between">
          <div class="col-9 d-flex align-items-center">
            <h2>Auction Lot Discard</h2>
          </div>
          <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
          </div>
        </div>
      </header>
      <div class="page-divider"></div>
      <div class="m-3">
        <div class="">
          <p>Are you sure you want to discard the selected lot? you will not be able to work on this lot once discarded.
          </p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="Lot discarded successfully."></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>

        <div class="text-end mb-3" *ngIf="auctionEntityDto && !isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-sm me-3" (click)="closeModal()">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="discardAuctionLot()"
            [disabled]="!isAllowToEdit()">OK</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #enableAllLotsModal>
    <div class="model-body">
      <header class="m-2">
        <div class="row justify-content-between">
          <div class="col-9 d-flex align-items-center">
            <h2>ENABLE LOTS</h2>
          </div>
          <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
          </div>
        </div>
      </header>
      <div class="page-divider"></div>
      <div class="m-3">
        <div class="">
          <p>Do you want to enable all lots?</p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ | async" [isError]="false"
            message="Lots enabled successfully."></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-sm me-3" (click)="closeModal()">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="activateAllLots()">ENABLE</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #bulkLotModal>
    <div class="model-body">
      <header class="m-2">
        <div class="row justify-content-between">
          <div class="col-9 d-flex align-items-center">
            <h2>Add Bulk Lots</h2>
          </div>
          <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
            <img src="assets/icons/close_light.svg" class="svg-icon-class c-pointer" ngbAutoFocus
              (click)="closeModal()">
          </div>
        </div>
      </header>
      <div class="page-divider"></div>
      <div class="p-3">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="ctrlNoOfLots" placeholder="No of Lots"
            [formControl]="ctrlNoOfLots">
          <label for="ctrlNoOfLots">
            Specify the tentative number of lots?</label>
          <div *ngIf="ctrlNoOfLots.invalid && (ctrlNoOfLots.dirty || ctrlNoOfLots.touched)">
            <p class="text-danger" *ngIf="ctrlNoOfLots.errors?.['required']">Required</p>
            <p class="text-danger" *ngIf="ctrlNoOfLots.errors?.['pattern']">Invalid</p>
          </div>
        </div>

      </div>

      <div class="m-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
          [message]="_errorMsg$ |async"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ | async" [isError]="false"
          message="Excel Sheet downloaded successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button [disabled]="!ctrlNoOfLots.valid" type="button" class="btn btn-new-class btn-sm me-2"
          (click)="generateBulkLotsExcel()">CREATE</button>
      </div>

    </div>
  </ng-template>

  <ng-template #uploadBulkLotModal>
    <div class="model-body">
      <header class="m-2">
        <div class="row justify-content-between">
          <div class="col-9 d-flex align-items-center">
            <h2>Upload Bulk Lots</h2>
          </div>
          <div class="col-auto text-end" [style.pointer-events]="isLoading ? 'none' : 'auto'">
            <img src="assets/icons/close_light.svg" class="svg-icon-class c-pointer" ngbAutoFocus
              (click)="closeModal()">
          </div>
        </div>
      </header>
      <div class="page-divider"></div>
      <div class="p-3">
        <div class="row mt-3 align-items-center">
          <div class="col-9">
            <div class="form-floating">
              <input type="text" class="form-control" id="selectedBulkLotsFileName" (click)="bulkUploadSheetRef.click()"
                [value]="selectedBulkLotsFileName" [disabled]="isLoading" />
              <input #bulkUploadSheetRef onclick="this.value=null;" type="file" class="d-none"
                id="selectedBulkLotsFileName1" (change)="chooseBulkLotsFile($event)" />
              <label for="selectedBulkLotsFileName">{{selectedBulkUploadFile ? 'Replace Excel Sheet' : 'Select Excel
                Sheet'}} {{'('+auctionEntityDto?.sequenceNo+'.xlsx)'}}</label>
              <div *ngIf="isSubmittedBulkLotsFile && !selectedBulkUploadFile">
                <p class="text-danger">Required</p>
              </div>
            </div>
          </div>
          <div class="col-2 text-end">
            <span (click)="removeSelectedBulkLotsFile()" [ngClass]="{'disabled' : isLoading }"
              *ngIf="selectedBulkUploadFile">
              <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
            </span>
          </div>

          <div class="mt-3 text-start">
            <app-message-toast *ngIf="_showSuccessToast$ | async"
              [message]="'Offline sheet data is being processed successfully, and lots have been created successfully.Please fill in the remaining attributes to mark them as active.'"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
              [message]="_errorMsg$ |async"></app-message-toast>
          </div>

          <div class="m-3" *ngIf="isLoading">
            <div class="d-inline-flex align-items-center">
              <p class="me-3 fs-6">Lot creation in progress</p>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <p class="fs-6">No. of Lots created: <span class="fs-5 fw-bold">{{auctionEntityDto?.notOfLots}}</span></p>
            <!-- <div class="e-spinner"></div> -->
          </div>
        </div>
        <div class="d-grid gap-2 mt-1" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-sm me-2" (click)="uploadBulkLotsExcel()"
            [disabled]="!isAllowToEdit()">UPLOAD</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #bulkLotErrorModel>
    <div class="model-body">
      <header class="m-2">
        <div class="row justify-content-between">
          <div class="col-9 d-flex align-items-center">
            <h2>LOT VALIDATION</h2>
          </div>
          <div class="col-auto text-end">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
          </div>
        </div>
      </header>
      <div class="page-divider"></div>
      <div class="m-3">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>

              <th scope="col">Msg</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of bulkLotList; index as i">
              <td>{{getBulkLotIndex(item)}}</td>
              <td>
                {{getBulkLotMsg(item)}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mx-3 mb-3 text-center" *ngIf="this.isErrorInBulkLotUpload(bulkLotList)">
        <app-message-toast [isError]="true"
          [message]="'There was an error while processing the offline Excel sheet. Please correct the errors and upload it again.'"></app-message-toast>
      </div>

      <div class="mx-3 mb-3 text-end">
        <button type="button" class="btn btn-new-class" (click)="closeModal()" ngbAutoFocus>OK</button>
      </div>
    </div>
  </ng-template>