import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { EmailTemplateDto } from 'src/app/shared/models/user/EmailTemplateDto';
import { FirestoreListenerService } from 'src/app/shared/services/firestore-listener.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AngularEditorComponent } from 'src/assets/plugins/angular-editor/public-api';
import { SuperAdminDashboardService } from 'src/app/shared/services/super-admin-dashboard.service';
import { EmailTemplateService } from 'src/app/shared/services/email-template.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { Router } from '@angular/router';
import { OrganizationUiDto } from 'src/app/shared/models/OrganizationUiDto';

@Component({
  selector: 'app-email-template-config',
  templateUrl: './email-template-config.component.html',
  styleUrls: ['./email-template-config.component.sass']
})
export class EmailTemplateConfigComponent implements OnInit {
  @ViewChild("bodyEditor") bodyEditor?: AngularEditorComponent;

  isEditMode: boolean = false;
  isLoading: boolean = false;
  templateStatus: boolean = false;

  formGroup: FormGroup;
  ctrlEmailTest: FormControl = new FormControl('', Validators.required);
  ctrlToggleTemplateStatus: FormControl = new FormControl(true, Validators.required);

  emailTemplates: Array<EmailTemplateDto> = [];
  selectedEmailTemplate?: EmailTemplateDto;
  selectedOrganizationUiDto?: OrganizationUiDto;

  _errorMsg$ = new BehaviorSubject<string>('');
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  activeTemplateId$ = new BehaviorSubject<string>('');

  constructor(
    private superAdminService: SuperAdminDashboardService,
    private firestoreService: FirestoreListenerService,
    private emailTemplateService: EmailTemplateService,
    private userService: UserService,
    private fb: FormBuilder,
    private ngbModal: NgbModal,
    private router: Router
  ) {
    this.formGroup = this.fb.group({
      subject: new FormControl('', [Validators.required]),
      body: new FormControl('', Validators.required),
    })
  }

  ngOnInit(): void {
    this.superAdminService.getSelectedOrganization$.subscribe(data => {
      if (data) {
        this.selectedOrganizationUiDto = data;
        this.firestoreService.listenEmailTemplate(data.orgCode!);
      }
    })

    this.superAdminService.getEmailTemplatesList$.subscribe(data => {
      if (data && data.length > 0) {
        this.emailTemplates = data;
        this.emailTemplates.sort((a, b) => a.templateName!.localeCompare(b.templateName!));
        if (this.selectedEmailTemplate) {
          this.selectedEmailTemplate = this.emailTemplates.filter(item => item.templateId == this.selectedEmailTemplate?.templateId)[0];
        } else {
          this.selectedEmailTemplate = this.emailTemplates.find(item => item.templateId == "BIDDER_APPLICATION_REGISTRATION_INCOMPLETE");
        }

        this.activeTemplateId$.next(this.selectedEmailTemplate?.templateId!)
        this.ctrlToggleTemplateStatus.patchValue(this.selectedEmailTemplate?.enable);
      } else {
        this.emailTemplates = [];
      }
    })

  }

  ngAfterViewInit(): void {
    ApplicationUtils.clearTextFormatOnPaste('bodyEditor')
  }

  navigateToHome() {
    this.router.navigate(['SuperAdmin/Dashboard'], { skipLocationChange: true })
  }

  populateData() {
    this.formGroup.reset();
    this.formGroup.controls['subject'].setValue(this.selectedEmailTemplate?.subject);
    this.formGroup.controls['body'].setValue(this.selectedEmailTemplate?.body);
  }

  changeTemplate(template: EmailTemplateDto) {
    this.selectedEmailTemplate = template;
    this.activeTemplateId$.next(this.selectedEmailTemplate?.templateId!);
    this.ctrlToggleTemplateStatus.patchValue(this.selectedEmailTemplate?.enable);
    this.populateData();
  }

  toggleEditMode() {
    this.isEditMode = !this.isEditMode;

    if (this.isEditMode) {
      this.populateData();
    }
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  openModal(content: any) {
    this.ctrlEmailTest.reset();
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    })
  }

  openChangeStatusModal(content: any) {
    let emailTemplate: EmailTemplateDto = ApplicationUtils.clone(this.selectedEmailTemplate);
    this.templateStatus = emailTemplate.enable ?? false;

    let modalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });

    modalRef.result.then(() => {
      this.ctrlToggleTemplateStatus.patchValue(this.selectedEmailTemplate?.enable);
    })
  }

  resetTemplate() {
    let defaultTemplate = this.emailTemplateService.emailTemplates.find(item => item.templateId == this.selectedEmailTemplate?.templateId);
    this.formGroup.controls['subject'].patchValue(defaultTemplate?.subject);
    this.formGroup.controls['body'].patchValue(defaultTemplate?.body);
    this.saveEmailTemplate();
  }

  resetAllTemplates() {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    this.isLoading = true;

    if (this.emailTemplates.length > 0) {
      let countUpdatedTemplate = 0;

      this.emailTemplateService.emailTemplates.forEach(defaultTemplate => {
        let oldTemplate = this.emailTemplates.find(item => item.templateId == defaultTemplate?.templateId);
        let updatedTemplate: EmailTemplateDto ;

        if(oldTemplate){
         updatedTemplate = ApplicationUtils.clone(oldTemplate);
        }else{
          updatedTemplate = new EmailTemplateDto();
        }

        updatedTemplate.documentId = defaultTemplate.templateId +" : "+this.selectedOrganizationUiDto!.orgCode;
        updatedTemplate.orgCode = this.selectedOrganizationUiDto?.orgCode;
        updatedTemplate.templateId = defaultTemplate.templateId;
        updatedTemplate.templateName = defaultTemplate.templateName;
        updatedTemplate.subject = defaultTemplate.subject;
        updatedTemplate.body = defaultTemplate.body;
        updatedTemplate.description = defaultTemplate.description;
        updatedTemplate.applicationSpecific = defaultTemplate?.applicationSpecific;

        this.firestoreService.saveEmailTemplate(updatedTemplate!)
          .then(() => {
            console.log("Email Template Updated: " + updatedTemplate.templateName);
            countUpdatedTemplate += 1;

            if (this.emailTemplates.length == countUpdatedTemplate) {
              this._showSuccessToast$.next(true);
              this.isLoading = false;
              setTimeout(() => {
                this._showSuccessToast$.next(false);
                this.closeModal();
              }, 2000);
            }
          }).catch((err) => {
            console.error(err);
            this.isLoading = false;
            this._showErrorToast$.next(true);
            this._errorMsg$.next("Error while resetting all templates.")
          });
      })
    } else {
      this.emailTemplateService.saveEmailTemplatesToFirestore(this.selectedOrganizationUiDto?.orgCode!);

      this._showSuccessToast$.next(true);
      this.isLoading = false;
      setTimeout(() => {
        this._showSuccessToast$.next(false);
        this.closeModal();
      }, 2000);
    }
  }

  saveEmailTemplate() {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    if (this.formGroup.invalid) {
      this.formGroup.markAsTouched();
      return;
    }

    let emailTemplate: EmailTemplateDto = ApplicationUtils.clone(this.selectedEmailTemplate);
    emailTemplate.subject = this.formGroup.controls['subject'].value;
    emailTemplate.body = this.formGroup.controls['body'].value;

    this.isLoading = true;

    this.firestoreService.saveEmailTemplate(emailTemplate)
      .then(() => {
        this._showSuccessToast$.next(true);
        this.isLoading = false;
        setTimeout(() => {
          this.isEditMode = false;
          this._showSuccessToast$.next(false);
          this.closeModal();
        }, 2000);

      }).catch((err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while updating template.")
      });

  }

  changeEmailTemplateStatus(action: string) {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    this.isLoading = true;

    let emailTemplate: EmailTemplateDto = ApplicationUtils.clone(this.selectedEmailTemplate);
    emailTemplate.enable = action == 'ENABLE';

    this.firestoreService.saveEmailTemplate(emailTemplate)
      .then(() => {
        this._showSuccessToast$.next(true);
        this.isLoading = false;

        setTimeout(() => {
          this._showSuccessToast$.next(false);
          this.closeModal();
        }, 2000);

      }).catch((err) => {
        console.error(err);
        this.isLoading = false;
        this._errorMsg$.next("Error while changing email status. Try again.");
        this._showErrorToast$.next(true);
      });
  }

  sendTestEmail() {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    if (this.ctrlEmailTest.invalid) {
      this.ctrlEmailTest.markAsTouched();
      return;
    }

    this.isLoading = true;

    let emailId = this.ctrlEmailTest.value;
    let orgCode = this.selectedOrganizationUiDto?.orgCode;

    this.emailTemplateService.sendTestEmail(emailId, orgCode!, this.selectedEmailTemplate?.templateId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)

        } else {
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._errorMsg$.next("Error while sending test email. Try again.");
        this._showErrorToast$.next(false);
      }
    })
  }

}
