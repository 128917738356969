<div class="">
  <div class="bg-white">
    <div class="row mt-4 mt-md-0">

      <div class="col-md-4 px-4">
        <div class="row  height-30rem">
          <div class="col-12">
            <!-- <img src="assets/logo/procurext-logo.svg" alt="Procurext" class="logo-procure" /> -->
            <img src="/downloadLandingBlob?fileId={{organizationUiDto?.logo?.fileId}}" [alt]="organizationUiDto?.name" class="logo-procure" />
          </div>
          <div class="col-10 mx-auto text-center">
          <div>
            <ng-container [ngTemplateOutlet]="isShowOtpVerify ? otpVerifyScreen : loginScreen"></ng-container>
          </div>
          <ng-template #loginScreen>
            <form class="needs-validation" novalidate [formGroup]="formGroupLogin" (keyup.enter)="loginWithPassword()">
             
              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="welcomeEmail" formControlName="emailId"
                  placeholder="name@example.com"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && formGroup.controls['emailId'].invalid}" />

                <label for="welcomeEmail">Email address</label>
                <div>
                  <p class="text-danger"
                    *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['emailId'].invalid  && (!this.formGroup.controls['emailId'].value)">
                    Required</p>
                  <p class="text-danger"
                    *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['emailId'].invalid  && (this.formGroup.controls['emailId'].value)">
                    Invalid email address</p>
                </div>
              </div>
              <div class="div-class my-2 text-start">
                <a class="link-class" (click)="showOtpVerifyScreen()">Login with one time password (OTP)</a>
              </div>
          
              <div class="input-group">
                <div class="form-floating">
                  <input #password [type]="isPasswordVisible ? 'text' : 'password'" class="form-control border-end-0"
                    id="welcomePassword" formControlName="password" placeholder="name@example.com"
                    [ngClass]="{'border-danger': (_validationErrorPresent$ |async) && formGroup.controls['password'].invalid}">
                  <label for="welcomePassword">Password</label>
                </div>
               
                <span class="border border-start-0 bg-color-filed input-group-text "
                  [ngClass]="{'border-danger': (_isPasswordFocused$|async)}"
                  (click)="togglePasswordVisibility()">
                  <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer" *ngIf="!isPasswordVisible">
                  <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer"
                    *ngIf="isPasswordVisible">
                </span>
              </div>
              <div class="mb-3">
                <p class="text-danger"
                  *ngIf="(_validationErrorPresent$ |async) &&  this.formGroup.controls['password'].invalid &&  (!this.formGroup.controls['password'].value)">
                  Required</p>
                <p class="text-danger"
                  *ngIf="(_validationErrorPresent$ |async) &&  this.formGroup.controls['password'].invalid &&  (this.formGroup.controls['password'].value)">
                  Password must be more than 8 characters, containing at least one capital letter, one lowercase letter,
                  one number and one special character.</p>
              </div>
          
              <div class="my-3">
                <app-message-toast *ngIf="authService.errorMsg" [isError]="true"
                  [message]="authService.errorMsg"></app-message-toast>
              </div>
          
              <div>
                <div class="mt-3 row align-items-center">
                  <div class="col-6 text-start">
                    <a class="link-class" (click)="openForgotPasswordModal(forgotPassword)">Forgot Password?</a>
                  </div>
                  <div class="col-6 text-end">
                    <button class="btn btn-sm btn-primary-clr-class" (click)="loginWithPassword()">Login</button>
                  </div>
                </div>
              </div>
            </form>
          </ng-template>
          
          <ng-template #otpVerifyScreen>
            <form class="needs-validation" novalidate [formGroup]="formGroupOtp" (keyup.enter)="loginWithOtp()">
              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="emailId" formControlName="emailId"
                  placeholder="name@example.com"
                  [ngClass]="{'is-invalid': fcOtp['emailId'].invalid && (fcOtp['emailId'].dirty || fcOtp['emailId'].touched)}" />

                <label for="emailId">Email address</label>
                <div class="text-start" *ngIf="fcOtp['emailId'].invalid && (fcOtp['emailId'].dirty || fcOtp['emailId'].touched)">
                  <p class="text-danger" *ngIf="fcOtp['emailId'].errors?.['required']">
                    Required
                  </p>
                  <p class="text-danger" *ngIf="fcOtp['emailId'].errors?.['pattern']">
                    Invalid email address
                  </p>
                </div>
              </div>
              <div class="div-class my-2 text-start">
                <a class="link-class" (click)="hideOtpVerifyScreen()">Login with password</a>
              </div>

              <div class="form-floating mb-1">
                <input type="text" id="otp" class="form-control" formControlName="userOtp"
                  [ngClass]="{'is-invalid': fcOtp['userOtp'].invalid && (fcOtp['userOtp'].dirty || fcOtp['userOtp'].touched)}" />
                <label for="userOtp">Enter OTP</label>
                <div class="text-start" *ngIf="fcOtp['userOtp'].invalid && (fcOtp['userOtp'].dirty || fcOtp['userOtp'].touched)">
                  <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fcOtp['userOtp'].errors?.['required']">
                    Required
                  </p>
                </div>
              </div>

              <div class="my-3">
                <app-message-toast *ngIf="authService.errorMsg" [isError]="true"
                  [message]="authService.errorMsg"></app-message-toast>
              </div>

            <div class="mt-3 row align-items-center">
              <div class="col-6 text-start">
                <span *ngIf="isCountdownRunning">Resend OTP in <b>{{ countdown }}s</b></span>
                <button *ngIf="!isCountdownRunning" class="btn btn-sm btn-primary-clr-class" 
                [disabled]="disableResendButton" (click)="resendOTP()">Resend OTP</button>
              </div>

              <div class="col-6 text-end">
                <button class="btn btn-sm btn-primary-clr-class" (click)="loginWithOtp()">Login</button>
              </div>
            </div>

            </form>
          </ng-template>
        </div>
          <!-- <div class="col-10 mx-auto text-center">
            <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="welcomeEmail" formControlName="emailId"
                  placeholder="name@example.com"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && formGroup.controls['emailId'].invalid}" />

                <label for="welcomeEmail">Email address</label>
                <div>
                  <p class="text-danger"
                    *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['emailId'].invalid  && (!this.formGroup.controls['emailId'].value)">
                    Required</p>
                  <p class="text-danger"
                    *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['emailId'].invalid  && (this.formGroup.controls['emailId'].value)">
                    Invalid email address</p>
                </div>
              </div>
              <div class="div-class my-2 text-start">
                <a class="link-class" (click)="showOtpVerifyScreen()">Login with one time password (OTP)</a>
              </div>
              <div class="div-class my-2 text-start">
                <a class="link-class" (click)="hideOtpVerifyScreen()">Login with password</a>
              </div>
              <div class="input-group">
                <div class="form-floating">
                  <input #password [type]="isPasswordVisible ? 'text' : 'password'" class="form-control border-end-0"
                    id="welcomePassword" formControlName="password" placeholder="name@example.com"
                    [ngClass]="{'border-danger': (_validationErrorPresent$ |async) && formGroup.controls['password'].invalid}">
                  <label for="welcomePassword">Password</label>
                </div>
               
                <span class="border border-start-0 bg-color-filed input-group-text "
                  [ngClass]="{'border-danger': (_isPasswordFocused$|async)}"
                  (click)="togglePasswordVisibility()">
                  <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer" *ngIf="!isPasswordVisible">
                  <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer"
                    *ngIf="isPasswordVisible">
                </span>
              </div>
              <div class="mb-3">
                <p class="text-danger"
                  *ngIf="(_validationErrorPresent$ |async) &&  this.formGroup.controls['password'].invalid &&  (!this.formGroup.controls['password'].value)">
                  Required</p>
                <p class="text-danger"
                  *ngIf="(_validationErrorPresent$ |async) &&  this.formGroup.controls['password'].invalid &&  (this.formGroup.controls['password'].value)">
                  Password must be more than 8 characters, containing at least one capital letter, one lowercase letter,
                  one number and one special character.</p>
              </div>

              <div class="my-3">
                <app-message-toast *ngIf="authService.errorMsg" [isError]="true" [message]="authService.errorMsg"></app-message-toast>
              </div>

              <div>
                <div class="mt-3 row">
                  <div class="col-6 text-start"><a class="link-class" (click)="openForgotPasswordModal(forgotPassword)">Forgot
                      Password?</a></div>
                  <div class="col-6 text-end">
                    <button class="btn btn-sm btn-primary-clr-class" (click)="handleValidSubmit()">Login</button>
                  </div>

                </div>
              
              </div>
            </form>
          </div> -->
          <div class="col-12  position-relative mb-2">
            <div class="position-of-left-text">
              Please Note
              <p class="small-grey-font">If you want to change the role of associated with your ID, please contact your
                admin or
                 <!-- <span>
                  <a target="_blank" href="https://trufetech.com/">
                    support@procurext.com</a>
                </span> -->
                <span>
                  <a target="_blank" href="https://trufe.com/">
                    support@trufe.com</a>
                </span>
              </p>
              <div class="mb-2 mt-2">
                <div class=" text-center">
                  <a href="https://www.linkedin.com/company/30802?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A30802%2Cidx%3A2-1-2%2CtarId%3A1485343370926%2Ctas%3Apkc%20advi"
                    target="_blank">
                    <img src="assets/icons/new-linkedIn.svg" class="svg-icon-class-md me-3 c-pointer">
                  </a>
                  <a href="https://twitter.com/PKCAdvisory/" target="_blank">
                    <img src="assets/icons/new-twitter.svg" class="svg-icon-class-md me-3 c-pointer">
                  </a>
                  <a href="https://www.facebook.com/PKCAdvisory/" target="_blank">
                    <img src="assets/icons/new-facebook.svg" class="svg-icon-class-md  me-2 c-pointer">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-8">
        
        <div class="position-relative">
          <img src="assets/images/Background Image.png" alt="" class="welcome-page">
          <div class="bg-class-header ">
          </div>
          <div class="text-position">
            <div class="text-white col-12 text-center">
              <div class="text-white letter-spacing-class display-6">Welcome to {{organizationUiDto?.name}}</div>
              <div class="div-class mt-3">
                <div class="text-white font-size-20">
                  A complete Blockchain and AI - ML <br>
                  backed Source - Procure - Pay solution
                </div>
              </div>
            </div>
          </div>
          
          <div class="position-of-bottom-text">
            <span class="text-white">Copyright © {{year}}
                <!-- <a target="_blank" href="https://trufetech.com/">
                <span class="  text-white ps-1 pt-1 btn-link text-capitalize">Trufe</span></a> -->
                <a target="_blank" href="https://trufetech.com.com/">
                  <span class="  text-white ps-1 pt-1 btn-link text-capitalize">Trufe</span></a>
                <span class="text-white mx-1">|</span>
            </span>
            <span>
              <span class="btn-link-live c-pointer text-capitalize" (click)="openPrivacyModal(privacyModal)">Privacy Policy</span>
              <span class="text-white mx-1">|</span> <span class="btn-link-live c-pointer text-capitalize" (click)="openTCModal(tcModal)">Terms of Use</span>
            </span>
          </div>

          
        </div>
        
      </div>
    </div>
  </div>

</div>
<ng-template #loginModal>
  <app-user-login></app-user-login>
</ng-template>
<ng-template #forgotPassword>
  <app-user-password-reset></app-user-password-reset>
</ng-template>

<ng-template #privacyModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>PRIVACY POLICY</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="scroll-container">
      <div #privacyHeader></div>
      <div class="m-3" style="white-space: normal;">
        <p [innerHTML]="privacyAndTermsOfUseDto?.privacyPolicy ?? '' | safe: 'html'"></p>
      </div>
    </div>
    <button class="btn btn-sm btn-outline-primary p-3 floating-button" (click)="moveToTop(privacyHeader)">
      <img src="assets/icons/arrowupward.svg" class="svg-icon-class c-pointer">
    </button>
  </div>
</ng-template>

<ng-template #tcModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>TERMS OF USE</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="scroll-container">
      <div #termsHeader></div>
      <div class="m-3" style="white-space: normal;">
        <p [innerHTML]="privacyAndTermsOfUseDto?.termsOfUse ?? '' | safe: 'html'"></p>
      </div>
      <button class="btn btn-sm btn-outline-primary p-3 floating-button" (click)="moveToTop(termsHeader)">
        <img src="assets/icons/arrowupward.svg" class="svg-icon-class c-pointer">
      </button>
    </div>
  </div>
</ng-template>
<app-loader #appLoader></app-loader>
