<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>UPLOAD BANNER IMAGE</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
<div class="model-body-top">
  <div class="m-3">
    <form>
      <div class="row">
        <div class="col-md-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="bannerImg" placeholder="Banner Image"
              (click)="bannerImg.click()" [value]="_fileDisplayName$ | async"
              [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && !(_fileDisplayName$ |async)}">
            <input #bannerImg type="file" onclick="this.value=null" (change)="chooseFile($event)" class="d-none" />
            <label for="bannerImg">Upload Banner Image</label>
            <div>
              <p class="e-fielderror e-fielderror-required"
                *ngIf="(_validationErrorPresent$ |async) && !(_fileDisplayName$ |async)">Required</p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <img alt="" [src]="imageUrl" *ngIf="!currentFileInfoDto"
            [ngClass]="(this._isImageActive$ | async) ? 'modal-logo-image-large' : 'modal-logo-image '" />
          <img alt="" src="/downloadLandingBlob?fileId={{currentFileInfoDto!.fileId}}"
            [ngClass]="(this._isImageActive$ | async) ? 'modal-logo-image-large' : 'modal-logo-image '"
            *ngIf="currentFileInfoDto" />
        </div>
        <div class="col-md-3 mt-3 text-end" *ngIf="currentFileInfoDto || currentFile">
          <button class="btn btn-sm btn-outline-dark me-2" (click)="imageActive()"  (click)="openViewImageModal(imageUrl)">
            <img src="assets/icons/view_dark.svg"  class="svg-icon-class c-pointer"   *ngIf="!(this._isImageActive$ | async)">
            <img src="assets/icons/zoomout_dark.svg"  class="svg-icon-class c-pointer" *ngIf="(this._isImageActive$ | async)">
          </button>
          <span (click)="removeProfileImage()"
            [ngClass]="{'disabled' : isLoading }">
            <img src="assets/icons/delete_danger.svg"  class="svg-icon-class c-pointer">
          </span>
        </div>
      </div>
      <div class="mb-3">
        <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Landing Page Hero image saved successfully.'"></app-message-toast>
        <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
      </div>
      <div class="mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>
      <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="btn btn-new-class" [disabled]="_isSaveButtonDisable$ |async"
          (click)="handleValidSubmit()">ADD</button>
      </div>
    </form>
  </div>
</div>
</div>