<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2 ngbAutoFocus>REPORT</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading$ | async}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="d-grid gap-2 mb-3" *ngIf="isLoading$ | async">
    <div class="e-spinner"></div>
  </div>
  <div class="model-body-top">
    <div class="m-4" *ngIf="!(isShowPdfView$ | async)">
      <div class="col-12 text-end" *ngIf="!(isLoading$ | async)">
        <button class="btn btn-new-class btn-sm me-3" (click)="generatePdf()">PDF</button>
        <button class="btn btn-new-class btn-sm " (click)="downloadAuctionReportData()">Excel</button>
      </div>
      <div class="container">
        <div class="row mb-2 mt-3" *ngIf="!(isLoading$ | async)">
          <div class="col-md-2 col-3">
            <img src="/downloadLandingBlob?fileId={{auctionReportWrapper?.companyDetailsDto?.companyLogo?.fileId}}" class="user-profile-image border"  alt="">
          </div>
          <div class="col-md-4 col-9">
          <h5 class="heading-h5 text-uppercase">  {{auctionReportWrapper?.companyDetailsDto?.companyName}}</h5>
          </div>
          <div class="col-md-6 text-md-end div-class">
            {{auctionReportWrapper?.companyDetailsDto?.address?.addressLine1}},
            {{auctionReportWrapper?.companyDetailsDto?.address?.addressLine2 ? auctionReportWrapper?.companyDetailsDto?.address?.addressLine2 + ', ' : ''}}<br>
            {{auctionReportWrapper?.companyDetailsDto?.address?.city}},<br>
            {{auctionReportWrapper?.companyDetailsDto?.address?.state}},
            {{auctionReportWrapper?.companyDetailsDto?.address?.country}} - {{auctionReportWrapper?.companyDetailsDto?.address?.zipCode}}
          </div>
        </div>
        <hr class="hr my-3">
        <h5 class=" heading-h5 text-center col-12 mb-2">Auction Details</h5>
        <div class="row justify-content-between">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 bold-font mb-2">
                Auction ID
              </div>
              <div class="col-md-7 mb-2">
                {{auctionReportWrapper?.auctionIndex}}
              </div>
              <div class="col-md-5 bold-font mb-2">
                Auction Name
              </div>
              <div class="col-md-7 mb-2">
                {{auctionReportWrapper?.auctionName}}
              </div>
              <div class="col-md-5 bold-font mb-2">
                Owner Name
              </div>
              <div class="col-md-7 mb-2">
                {{auctionReportWrapper?.listingContactName}}
              </div>
              <div class="col-md-5 bold-font mb-2">
                Auction Type
              </div>
              <div class="col-md-7 mb-2">
                {{auctionReportWrapper?.auctionBiddingMethod == "REVERSE_AUCTION" ? 'Reverse Auction' : 'Forward Auction'}}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 bold-font mb-2">
                No Of Lots
              </div>
              <div class="col-md-7 mb-2">
                {{auctionReportWrapper?.noOfLots}}
              </div>
              <div class="col-md-5 bold-font mb-2">
               Start Date Of Auction
              </div>
              <div class="col-md-7 mb-2">
                {{auctionReportWrapper?.startDate}}
              </div>
              <div class="col-md-5 bold-font mb-2">
                Currency
              </div>
              <div class="col-md-7 mb-2">
                {{auctionReportWrapper?.currency?.symbol}}
              </div>
            </div>
          </div>
        </div>
        <hr class="hr my-2">
        <h5 class=" heading-h5 text-center col-12 mb-2">Lot Details</h5>
        <div *ngFor="let lotWrapperReportDto of auctionReportWrapper?.lotWrapperReportDtoList">
          <div class="row justify-content-between">
            <div class="col-md-6">

              <div class="row">
                <div class="col-md-5 bold-font mb-2">
                 Lot ID
                </div>
                <div class="col-md-7 mb-2">
                 {{lotWrapperReportDto.lotIndex}}
                </div>
                <div class="col-md-5 bold-font mb-2">
                  Lot Name
                 </div>
                 <div class="col-md-7 mb-2">
                  {{lotWrapperReportDto.lotName}}
                 </div>
                 <div class="col-md-5 bold-font mb-2">
                  Starting Bid Price
                 </div>
                 <div class="col-md-7 mb-2">
                  {{getFormattedPrice(auctionReportWrapper?.currency!, lotWrapperReportDto.startingBidPrice)}}
                 </div>
                <div class="col-md-5 bold-font mb-2" *ngIf="lotWrapperReportDto.reservePrice! > 0">
                  Reserve Price
                </div>
                <div class="col-md-7 mb-2" *ngIf="lotWrapperReportDto.reservePrice! > 0">
                  {{getFormattedPrice(auctionReportWrapper?.currency!, lotWrapperReportDto.reservePrice)}}
                </div>
                <div class="col-md-5 bold-font mb-2">
                  End Date Of Lot
                </div>
                <div class="col-md-7 mb-2">
                  {{lotWrapperReportDto?.endDate}}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-5 bold-font mb-2">
                  Current Price
                </div>
                <div class="col-md-7 mb-2">
                  {{getFormattedPrice(auctionReportWrapper?.currency!, lotWrapperReportDto.currentBidPrice)}}
                </div>
                <div class="col-md-5 bold-font mb-2">
                 Saving Incurred
                </div>
                <div class="col-md-7 mb-2">
                  {{getFormattedPrice(auctionReportWrapper?.currency!, lotWrapperReportDto.savingIncurredValue)}}
                </div>
                <div class="col-md-5 bold-font mb-2">
                  Saving Incurred %
                </div>
                <div class="col-md-7 mb-2">
                  {{lotWrapperReportDto.savingIncurredPercent}}
                </div>
                <div class="col-md-5 bold-font mb-2">
                  Lot Time
                </div>
                <div class="col-md-7 mb-2">
                  {{lotWrapperReportDto?.liveTime}}
                </div>
              </div>
            </div>
          </div>

          <hr class="hr my-2">

          <h5 class=" heading-h5 text-center mb-2">Ranking</h5>

          <div class="table-responsive" style="overflow-x:auto;">
            <table class="table table-bordered align-middle">
              <thead class="table-dark">
                <tr>
                  <th class="font-weight-bold">Rank </th>
                  <th class="font-weight-bold">Company</th>
                  <th class="font-weight-bold">Supplier</th>
                  <!-- <th class="font-weight-bold">Paddle No</th> -->
                  <th class="font-weight-bold">Contact Number</th>
                  <th class="font-weight-bold">Supplier Email</th>
                  <!-- <th class="font-weight-bold">Company Name</th> -->
                  <th class="font-weight-bold">No of Bids Submitted </th>
                  <!-- <th class="font-weight-bold" *ngIf="lotWrapperReportDto.reservePrice! > 0 ">Reserve price</th>
                  <th class="font-weight-bold">Starting price</th> -->
                  <th class="font-weight-bold">Last Bid Value</th>
                  <th class="font-weight-bold">Saving Value</th>
                  <th class="font-weight-bold">Saving %</th>
                </tr>
              </thead>
              <tbody>
                <tr class="" *ngFor="let item of lotWrapperReportDto.supplierRankWrapperDtoList">
                  <td scope="row">{{item?.rankIndex}}</td>
                  <td style="min-width: 150px;">{{item?.companyName}}</td>
                  <td style="min-width: 150px;">{{item?.supplierName}}</td>
                  <!-- <td style="min-width: 150px;">{{item?.paddleNo}}</td> -->
                  <td style="min-width: 150px;">{{item?.contactNo}}</td>
                  <td style="min-width: 150px;">{{item?.supplierEmailId}}</td>
                  <!-- <td style="min-width: 150px;">{{item?.companyName}}</td> -->
                  <td style="min-width: 150px;">{{item?.noOfBids}}</td>
                  <!-- <td style="min-width: 150px;" scope="row" *ngIf="lotWrapperReportDto.reservePrice! > 0 "> -->
                    <!-- {{getFormattedPrice(selectedAuction.currency!, lotWrapperReportDto.reservePrice)}}</td> -->
                  <!-- <td style="min-width: 150px;" scope="row"> -->
                    <!-- {{getFormattedPrice(selectedAuction.currency!, lotWrapperReportDto.startingBidPrice)}}</td> -->
                  <td style="min-width: 150px;">{{getFormattedPrice(auctionReportWrapper?.currency!, item?.lastBidPrice)}}</td>
                  <td style="min-width: 150px;">
                    {{getFormattedPrice(auctionReportWrapper?.currency!, item?.savingValue)}}</td>
                  <td>
                    {{item?.savingPercent}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr class="hr my-2">
          <h5 class=" heading-h5 text-center mb-2">Bidding History</h5>
          <div class="table-responsive">
            <table class="table table-bordered align-middle">
              <thead class="table-dark">
                <tr>
                  <!-- <th class="font-weight-bold">Supplier Name</th>
                  <th class="font-weight-bold"> Paddle No</th> -->
                  <th class="font-weight-bold">Company Name</th>
                  <!-- <th class="font-weight-bold" *ngIf="lotWrapperReportDto.reservePrice! > 0 ">Reserve price</th> -->
                  <!-- <th class="font-weight-bold">Starting price</th> -->
                  <th class="font-weight-bold">Bid Value</th>
                  <th class="font-weight-bold">Bid Date and Time</th>
                  <th class="font-weight-bold">Last Bid of Supplier</th>
                  <th class="font-weight-bold">Lot Last Bid</th>
                  <th class="font-weight-bold">{{getReductionOrIncreaseMsg(auctionReportWrapper?.auctionBiddingMethod!)}} Based on Starting Price</th>
                  <th class="font-weight-bold">{{getReductionOrIncreaseMsg(auctionReportWrapper?.auctionBiddingMethod!)}} Based on Starting Price % </th>
                  <!-- <th class="font-weight-bold">{{getReductionOrIncreaseMsg(auctionReportWrapper?.auctionBiddingMethod!)}} Cumulative</th>
                  <th class="font-weight-bold">{{getReductionOrIncreaseMsg(auctionReportWrapper?.auctionBiddingMethod!)}} Cumulative % </th> -->
                </tr>
              </thead>
              <tbody>
                <tr class="" *ngFor="let item of lotWrapperReportDto.biddingWrapperDtoList">

                  <!-- <td style="min-width: 200px;">{{item?.supplierName}}</td>
                  <td style="min-width: 150px;">{{item?.paddleNo}}</td> -->
                  <td style="min-width: 150px;">{{item?.companyName}}</td>
                  <!-- <td style="min-width: 150px;" scope="row" *ngIf="lotWrapperReportDto.reservePrice! > 0 ">
                    {{getFormattedPrice(selectedAuction.currency!, lotWrapperReportDto.reservePrice)}}</td> -->
                  <!-- <td style="min-width: 150px;" scope="row">
                    {{getFormattedPrice(selectedAuction.currency!, lotWrapperReportDto.startingBidPrice)}}</td> -->
                  <td style="min-width: 150px;" >
                    {{getFormattedPrice(auctionReportWrapper?.currency!, item?.bidValue)}}</td>
                  <td style="min-width: 250px;">{{item?.bidDateAndTime}}</td>
                  <td style="min-width: 200px;">{{item?.lastBidOfSupplier? 'YES' : 'NO'}}</td>
                  <td style="min-width: 150px;">{{item?.lotLastBid? 'YES' : 'NO'}}</td>
                  <td style="min-width: 250px;">
                    {{getFormattedPrice(auctionReportWrapper?.currency!, item?.reductionOrIncreaseBasedOnStartingPrice)}}</td>

                  <td style="min-width: 280px;">
                    {{item?.reductionOrIncreaseBasedOnStartingPricePercent}}

                  </td>
                  <!-- <td style="min-width: 200px;">
                    {{getFormattedPrice(selectedAuction.currency!, item?.reductionOrIncreaseCumulative)}}
                  </td>
                  <td style="min-width: 200px;">{{item?.reductionOrIncreaseCumulativePercent}}</td> -->
                </tr>
              </tbody>
            </table>
          </div>

          <div id="chart" echarts [options]="chartOption[lotWrapperReportDto.lotIndex!]"
            *ngIf="chartOption[lotWrapperReportDto.lotIndex!] && lotWrapperReportDto.biddingWrapperDtoList?.length"></div>
        </div>

      </div>

    </div>

    <div class="m-3" *ngIf="isShowPdfView$ | async">
      <app-generate-lot-report-pdf [auctionReportWrapper]="auctionReportWrapper" (pdfExport)="pdfExport()">
      </app-generate-lot-report-pdf>
    </div>
  </div>
</div>
