<div class="container mt-5">
  
 

      <div class="border bg-white p-2 mb-3">
        <div class="d-grid gap-2 mb-3" *ngIf="isDataLoading">
          <div class="e-spinner" *ngIf="isDataLoading"></div>
        </div>
        <div class="row mb-3">
          <div class="col-md-5">
            <h5 class="heading-h5 me-3">CLIENT PORTAL:</h5>
            <h5 class="heading-h5 text-capitalize ">
              {{organizationUiDto?.name}}
             
            </h5>
          </div>
          <div class="col-md-7 text-md-end">
              <button class="btn btn-new-class btn-sm px-4 mb-2" (click)="openEditOrgProfile(editOrgModal)">
                EDIT
              </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6  mb-2">
            <div class="col-12 div-class py-2">
              Org Profile Information
             </div>
            <div class="row">
              <div class="col-4 font-weight-normal py-2">
                Org Name
              </div>
              <div class="col-8 div-class py-2">
                {{organizationUiDto?.name}}
              </div>
              <hr class="hr" />
              <div class="col-4 font-weight-normal py-2">
                Corporate Website Link
              </div>
              <div class="col-8 div-class py-2">
                <a [href]="organizationUiDto?.corporateUrl" target="_blank"><span
                  class="btn-link text-lowercase">{{organizationUiDto?.corporateUrl}}</span></a>
              </div>
              <hr class="hr" />
              <div class="col-4 font-weight-normal py-2">
                Support Email
              </div>
              <div class="col-8 div-class py-2">
                {{organizationUiDto?.supportEmail}}
              </div>
              <hr class="hr" />
              <div class="col-4 font-weight-normal py-2">
                Contact Number
              </div>
              <div class="col-8 div-class py-2">
                  {{organizationUiDto?.phoneNo}}
              </div>
              <hr class="hr" />
              <div class="col-4 font-weight-normal py-2">
                Organisation Logo
              </div>
              <div class="col-8 div-class py-2">
                <img src="/downloadLandingBlob?fileId={{organizationUiDto?.logo?.fileId}}" class="logo-image"
                [alt]="organizationUiDto?.name" *ngIf="organizationUiDto?.logo">
              </div>
              <hr class="hr" />
              <div class="col-4 font-weight-normal py-2">
                Description
              </div>
              <div class="col-8 div-class py-2">
                <p class="p-class animate-show-more" #descriptionRef
                [innerHTML]="organizationUiDto?.description ?? '' | safe: 'html'"
                [ngClass]="{'hide-description': !isShowDescription}"></p>
              <div class="bold-border my-2" *ngIf="descriptionRef.scrollHeight > 120"></div>
              <div class="col-12 text-end ">
                <span class="btn-link" (click)="showOrHideDescription()" *ngIf="descriptionRef.scrollHeight > 120">
                  {{isShowDescription ? 'HIDE MORE' : 'SHOW MORE'}}
                </span>
              </div>
              </div>
            </div>
            <!-- <div class="div-class">
              Corporate url
              <a [href]="organizationUiDto?.corporateUrl" target="_blank"><span
                  class="btn-link text-lowercase">{{organizationUiDto?.corporateUrl}}</span></a>
            </div>
            Description
            <p class="p-class animate-show-more" #descriptionRef
              [innerHTML]="organizationUiDto?.description ?? '' | safe: 'html'"
              [ngClass]="{'hide-description': !isShowDescription}"></p>
            <div class="bold-border my-2" *ngIf="descriptionRef.scrollHeight > 120"></div>
            <div class="col-12 text-end ">
              <span class="btn-link" (click)="showOrHideDescription()" *ngIf="descriptionRef.scrollHeight > 120">
                {{isShowDescription ? 'HIDE MORE' : 'SHOW MORE'}}
              </span>
            </div> -->
           
          </div>
          <div class="col-md-6 text-md-center text-start mb-2">
          <div class="mb-2">
            Banner Image
           </div>
          <img src="/downloadLandingBlob?fileId={{organizationUiDto?.bannerImage?.fileId}}" class="hero-image"
            [alt]="organizationUiDto?.name" *ngIf="organizationUiDto?.bannerImage"
            [ngClass]="{'img-portrait': organizationUiDto?.bannerImage?.dataType == 'Portrait'}">
          <!-- <img src="assets/logo/auctionext-2.png" class="logo-image"> -->
          </div>
        </div>
      </div>
      <div class="row">
        
      <div class="col-md-6 mb-3">
        <div class="card p-2 bg-white ">
          <div class=" row">
            <div class="col-4">
              <h5 class="heading-h5 mt-1">LEGAL</h5>
            </div>
            <div class="col-8 text-end">
              <button (click)="openPrivacyAndTermsEditModal()" class="btn btn-new-class btn-sm px-4 ms-3">
                ADD
              </button>
            </div>
          </div>
          <hr class="hr my-2" />
          <div class=" mt-4">
            <div class="e-spinner" *ngIf="isPrivacyLoading"></div>
            <ngb-accordion #acc="ngbAccordion">
              <ngb-panel [id]="privacyTerms.uniqueId!"
                title="{{privacyTerms.name!}} {{privacyTerms.footerPolicy ? ' - (Footer Policy)' : ''}}"
                *ngFor="let privacyTerms of organizationUiDto?.privacyAndTermsOfUseList; index as i;">
                <ng-template ngbPanelContent>
                  <div class="row">
                    <div class="col-md-5 mb-2">
                      <h5 class="heading-h5">Privacy Policy</h5>
                      <div class="text-center" *ngIf="!privacyTerms?.privacyPolicy">
                        <h3 class=" heading-new">Privacy not specified</h3>
                      </div>
                      <p class="p-class animate-show-more" #privacyPolicyRef
                        [innerHTML]="privacyTerms?.privacyPolicy ?? '' | safe: 'html'"
                        [ngClass]="{'hide-description': privacyTerms?.privacyPolicy && !(isShowPrivacyPolicy$ |async)}">
                      </p>
                      <div class="bold-border my-2" *ngIf="privacyPolicyRef.scrollHeight > 120"></div>
                      <div class="col-12 text-end btn-link" (click)="showOrHidePrivacyPolicy()"
                        *ngIf="privacyPolicyRef.scrollHeight > 120">
                        {{(isShowPrivacyPolicy$ |async) ? 'HIDE MORE' : 'SHOW MORE'}}
                      </div>
                    </div>
                    <div class="col-md-5 mb-2">

                      <h5 class="heading-h5">Terms and Conditions </h5>
                      <div class="text-center" *ngIf="!privacyTerms?.termsOfUse">
                        <h3 class=" heading-new text-dark">Terms Of Use not specified</h3>
                      </div>
                      <p class="p-class animate-show-more" #termsOfUseRef
                        [innerHTML]="privacyTerms?.termsOfUse ?? '' | safe: 'html'"
                        [ngClass]="{'hide-description': privacyTerms?.termsOfUse && !(isShowTermsOfUse$ |async)}"></p>
                      <div class="bold-border my-2" *ngIf="termsOfUseRef.scrollHeight > 120"></div>
                      <div class="col-12 text-end btn-link" (click)="showOrHideTermsOfUse()"
                        *ngIf="termsOfUseRef.scrollHeight > 120">
                        {{(isShowTermsOfUse$ |async) ? 'HIDE MORE' : 'SHOW MORE'}}
                      </div>
                    </div>
                    <div class="col-md-2 text-md-end">
                      <button (click)="openPrivacyAndTermsEditModal(privacyTerms?.uniqueId)"
                        class="btn btn-new-class btn-sm me-3">
                        EDIT
                        <!-- <img src="assets/icons/edit_dark.svg" class=" c-pointer svg-icon-class"> -->
                      </button>
                      <span (click)="openConfirmationModel(confirmationTemplate, 'Policy', i)"
                        *ngIf="organizationUiDto?.privacyAndTermsOfUseList!.length > 1">
                        <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>

        </div>
      </div>
      <div class="col-md-6 mb-3 ">
        <app-color-configuration></app-color-configuration>
      </div>
      </div>
    
</div>

<ng-template #editOrgModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Edit Org Profile</h2>
        </div>
        <div class="col-auto text-end">
          <span [ngClass]="{'disabled' : isLoading }">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
              (click)="closeModal()"></span>
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form [formGroup]="formGroup" class="needs-validation" novalidate (keyup.enter)="saveOrgProfile()">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="name" placeholder="name" formControlName="name"
              [ngClass]="{'is-invalid': fc.name.invalid && (fc.name.dirty || fc.name.touched)}">
            <label for="name">Org Name</label>
            <div class="invalid-feedback" *ngIf="fc.name.invalid && (fc.name.dirty || fc.name.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.name.errors?.required">Required</p>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="corporateUrl" placeholder="Url" formControlName="corporateUrl"
              [ngClass]="{'is-invalid': fc.corporateUrl.invalid && (fc.corporateUrl.dirty || fc.corporateUrl.touched)}">
            <label for="corporateUrl">Corporate Website Link</label>
            <div class="invalid-feedback" *ngIf="fc.corporateUrl.invalid && (fc.corporateUrl.dirty || fc.corporateUrl.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.corporateUrl.errors?.required">Required</p>
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.corporateUrl.errors?.pattern">Invalid</p>
            </div>
          </div>

          <div class="mb-3">
            <div class="row mb-2">
              <div class="col-md-12">
                <label for="descriptionEditor" class="text-area-label">Description (To be displayed on Home Page)
                </label>
              </div>
            </div>
            <div class="form-group">
              <angular-editor #descriptionEditor formControlName="description" class="form-control"
                id="descriptionEditor" [config]="config"></angular-editor>
            </div>
          </div>

          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="supportEmail" placeholder="supportEmail"
              formControlName="supportEmail"
              [ngClass]="{'is-invalid': fc.supportEmail.invalid && (fc.supportEmail.dirty || fc.supportEmail.touched)}">
            <label for="supportEmail">Support Email (To be displayed on Home Page)</label>
            <div class="invalid-feedback"
              *ngIf=" fc.supportEmail.invalid && (fc.supportEmail.dirty || fc.supportEmail.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.supportEmail.errors?.required">Required</p>
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.supportEmail.errors?.pattern">Invalid</p>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-md-6">
              <div class="input-group mb-3">
                <span class="input-group-addon">
                  <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                    [countryCode$]="countryCode$"></app-country-code-select>
                </span>
                <div class="form-floating">
                  <input type="text" class="form-control" id="phoneNo" placeholder="phoneNo" formControlName="phoneNo"
                    [ngClass]="{'is-invalid': fc.phoneNo.invalid && (fc.phoneNo.dirty || fc.phoneNo.touched)}">
                  <label for="phoneNo">Contact Number (To be displayed on Home Page)</label>
                  <div class="invalid-feedback"
                    *ngIf="fc.phoneNo.invalid && (fc.phoneNo.dirty || fc.phoneNo.touched)">
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.phoneNo.errors?.required">Required</p>
                    <p class="text-danger" *ngIf="fc.phoneNo.errors?.pattern">Invalid</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <select (change)="onChangeCurrency($event)" name="Select Timezone" class="form-control form-select"
                  formControlName="currency" id="currency"
                  [ngClass]="{'is-invalid': fc.currency.invalid && (fc.currency.dirty || fc.currency.touched)}">
                  <option value="" disabled>Select . . .</option>
                  <option [value]="item.name" [selected]="item.name == selectedCurrency?.name"
                    *ngFor="let item of currencies">{{item.name}}</option>
                </select>
      
                <label for="currency">Currency {{selectedCurrency?.symbol}}</label>
                <div *ngIf="isSubmitted || fc.currency.invalid && (fc.currency.dirty || fc.currency.touched)">
                  <p class="text-danger" *ngIf="fc.currency.errors?.required">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label class="text-area-label"> Organisation Logo</label>
              <div class="form-floating">
                <input type="text" class="form-control" (click)="auctionHouseLogo.click()" [value]="logoFileName" />
                <input #auctionHouseLogo name="logo" onclick="this.value=null;" type="file" class="d-none"
                  id="auctionHouseLogo" (change)="chooseFile($event, 'LOGO')" />
                <label for="auctionHouseLogo">{{isEdit ? 'Click to Replace Image' : 'Click to Add Image'}}</label>                
                <div class="invalid-feedback" *ngIf="fc.logo.invalid && (fc.logo.dirty || fc.logo.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.logo.errors?.required">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row mt-3 align-items-center" *ngIf="imageUrlLogo">
                <div class="col-6 col-md-7">
                  <img alt="" [src]="imageUrlLogo"
                    [ngClass]="isLogoActive ? 'modal-logo-image-large' : 'modal-logo-image'" />
                </div>
                <div class="col-2 me-md-2 mt-3">
                  <button class="btn btn-sm btn-new-class" (click)="openViewImageModal(imageUrlLogo)">
                    <span> <img src="assets/icons/view_dark.svg" class=" c-pointer svg-icon-class"
                        *ngIf="!isLogoActive"></span>
                    <span> <img src="assets/icons/zoomout_dark.svg" class=" c-pointer svg-icon-class"
                        *ngIf="isLogoActive"></span>
                  </button>
                </div>
                <div class="col-2 mt-3">
                  <span (click)="deleteImage('LOGO')" [ngClass]="{'disabled' : isLoading }">
                    <span> <img src="assets/icons/delete_danger.svg" class=" c-pointer svg-icon-class"></span>
                  </span>
                </div>

              </div>
            </div>
          </div>

          <!-- BANNER IMAGE -->
          <div class="row my-3">
            <div class="col-md-6">
              <label class="text-area-label">Hero Image</label>
              <div class="form-floating">
                <input type="text" class="form-control" (click)="auctionHouseBanner.click()" [value]="bannerFileName" />
                <input #auctionHouseBanner name="banner" onclick="this.value=null;" type="file" class="d-none"
                  id="bannerFileName" (change)="chooseFile($event, 'BANNER')" />
                <label for="bannerFileName">{{isEdit ? 'Click to Replace Image' : 'Click to Add Image'}}</label>
                <div *ngIf="fc.bannerImage.invalid && (fc.bannerImage.dirty || fc.bannerImage.touched)">
                  <p class="text-danger" *ngIf="fc.bannerImage.errors?.required">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <div class="row mt-3 align-items-center" *ngIf="imageUrlBanner">
                <div class="col-6 col-md-7">
                  <img alt="" [src]="imageUrlBanner"
                    [ngClass]="isBannerActive ? 'modal-logo-image-large' : 'modal-logo-image'" />
                </div>

                <div class="col-2 me-md-2 mt-3">
                  <button class="btn btn-sm btn-new-class" (click)="openViewImageModal(imageUrlBanner)">
                    <span> <img src="assets/icons/view_dark.svg" class=" c-pointer svg-icon-class"
                        *ngIf="!isBannerActive"></span>
                    <span> <img src="assets/icons/zoomout_dark.svg" class=" c-pointer svg-icon-class"
                        *ngIf="isBannerActive"></span>
                  </button>
                </div>
                <div class="col-2 mt-3">
                  <span (click)="deleteImage('BANNER')" [ngClass]="{'disabled' : isLoading }">
                    <span> <img src="assets/icons/delete_danger.svg" class=" c-pointer svg-icon-class"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Auction House Saved'"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="(this._showErrorMsg$ | async)">
            </app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner" *ngIf="isLoading"></div>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class c-pointer" (click)="saveOrgProfile()">
              UPDATE</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #confirmationTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus>Confirmation</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-2">
        <div class="mb-2">
          <p>Are you sure you want to delete {{selectedType}} ?</p>
        </div>

        <div class="col-12 text-end">
          <button type="button" class="btn btn-new-class btn-sm me-3" (click)="closeModal()">CANCEL</button>
          <button *ngIf="selectedType == 'Policy'" type="button" class="btn btn-sm btn-outline-primary"
            (click)="deletePrivacyAndTerms()">CONFIRM</button>
        </div>

      </div>
    </div>
  </div>
</ng-template>