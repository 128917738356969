import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { logEvent, getAnalytics } from '@angular/fire/analytics';
import { Timestamp, collection, query, where, onSnapshot, Unsubscribe, Firestore } from '@angular/fire/firestore';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { UserAuctionRegistrationsNotificationComponent } from 'src/app/shared/components/user-auction-registrations-notification/user-auction-registrations-notification.component';
import { UserAuctionEligibleStatus } from 'src/app/shared/enums/UserAuctionEligibleStatus';
import { OrganizationUiDto } from 'src/app/shared/models/OrganizationUiDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { NotAwardAuctionDto } from 'src/app/shared/models/user/NotAwardAuctionDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-new-admin-data',
  templateUrl: './new-admin-data.component.html',
  styleUrls: ['./new-admin-data.component.sass']
})
export class NewAdminDataComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedSidebarView: string = "DASHBOARD_PAGE";

  isShowInternalPage: boolean = false;
  internalPageView: string = ''
  countPendingRegistration: number = 0;

  organizationUiDto?: OrganizationUiDto;

  eventType: string = "AUCTION";

  collectionAuctions: string = "AUCTIONS";
  auctionListenerUnsubscribe: Unsubscribe | undefined;

  collectionRfx: string = "RFX";
  rfxListenerUnsubscribe: Unsubscribe | undefined;

  userRegistrationsSubscription$?: Subscription;
  updateBreadcrumbsSubscription$?: Subscription
  allAuctionsLoadedSubscription$?: Subscription;
  currentOrganizationSubscription$?: Subscription;

  @ViewChild('appLoader', { static: false }) appLoader?: LoaderComponent;

  constructor(
    private dataRedirectionService: DashboardRedirectionService,
    private adminSourcingEventService: AdminSourcingEventsDataHolderService,
    private ngbModal: NgbModal,
    public adminDashboardService: AdminDashboardService,
    private onboardingService: OnboardingService,
    private catalogueService: CatalogueService,
    private firestore: Firestore,
  ) { }

  ngOnInit(): void {

    this.adminDashboardService.loadGlobalBiddersCount();

    this.userRegistrationsSubscription$ = this.adminDashboardService.getAllUserAuctionRegistrationsList$.subscribe((data) => {
      if (data) {
        let awaitingApprovals = data.filter(item => item.userAuctionRegistrationStatus == UserAuctionEligibleStatus.AWAITING_APPROVAL);
        this.countPendingRegistration = awaitingApprovals.length;
      }
    })

    this.allAuctionsLoadedSubscription$ = this.adminSourcingEventService.getAllEventsDataLoaded$.subscribe(data => {
      if (data) {
        // this.dataRedirectionService.handleDashboardAppNavigation();
      }
    })

    this.updateBreadcrumbsSubscription$ = this.dataRedirectionService.getUpdateBreadcrumbs$.subscribe(data => {
      if (data) {
        let pageName = sessionStorage.getItem('PAGE_NAME')!;

        if (pageName) {
          if (pageName == ApplicationConstants.SOURCING_PAGE ||
            pageName == ApplicationConstants.ANALYTICS_PAGE ||
            pageName == ApplicationConstants.DASHBOARD_PAGE ||
            pageName == ApplicationConstants.IN_HOUSE_USER_PAGE ||
            pageName == ApplicationConstants.PURCHASE_REQUEST_PAGE ||
            pageName == ApplicationConstants.BIDDER_NETWORK_PAGE ||
            pageName == ApplicationConstants.ADMIN_CATALOGUE_PAGE ||
            pageName == ApplicationConstants.APPROVED_PAGE ||
            pageName == ApplicationConstants.PROJECTS_PAGE ||
            pageName == ApplicationConstants.ADMIN_PURCHASE_ORDER_PAGE ||
            pageName == ApplicationConstants.ADMIN_INVOICE_PAGE ||
            pageName == ApplicationConstants.ONBOARDING_PAGE ||
            pageName == ApplicationConstants.MATERIAL_PAGE) {
            this.isShowInternalPage = false;
            this.selectedSidebarView = pageName;
          } else {
            if (pageName == ApplicationConstants.AUCTION_CREATOR_PAGE) {
              this.eventType = "AUCTION";
            } else if (pageName == ApplicationConstants.RFX_CREATOR_PAGE) {
              this.eventType = "RFX";
            }

            this.isShowInternalPage = true;
            this.internalPageView = pageName;
          }
        } else {
          this.isShowInternalPage = false;
          this.selectedSidebarView = 'DASHBOARD_PAGE';
          sessionStorage.setItem('PAGE_NAME', 'DASHBOARD_PAGE');
        }
      }
    })
  }

  ngAfterViewInit(): void {
    this.currentOrganizationSubscription$ = this.onboardingService.getOrganizationUiDto$.subscribe((data) => {
      if (data) {
        this.organizationUiDto = data;
        this.attachAuctionChangesListener();
        this.attachRfxChangesListener();

        if (this.organizationUiDto) {
          this.adminSourcingEventService.loadSourcingEventDtos();
          this.appLoader?.closeLoaderIcon(ApplicationConstants.FETCHING_DATA);

          // if (!this.auctionHouse?.privacyAndTermsOfUseList || this.auctionHouse?.privacyAndTermsOfUseList.length == 0) {
          //   this.isShowAuctionHouseSetting$.next(true);
          // }
        }
      }
    })

    // this.adminDashboardDataSubscription$ = this.adminDashboardService.getAdminDashboardData$.subscribe((data) => {
    //   if (data) {
    //     this.adminUIWrapper = data;
    //     this.countAdmins = this.adminUIWrapper.adminList?.length ?? 0
    //     this.countAgents = this.adminUIWrapper.agentList?.length ?? 0
    //   }
    // })
  }

  toggleSidebarView(tabView: string, dropdown?: NgbDropdown) {
    this.selectedSidebarView = tabView;
    if (dropdown) {
      dropdown.close();
    }

    if (this.selectedSidebarView == ApplicationConstants.DASHBOARD_PAGE) {
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.DASHBOARD_PAGE
      );
    } else if (this.selectedSidebarView == ApplicationConstants.ANALYTICS_PAGE) {
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.ANALYTICS_PAGE
      );
    } else if (this.selectedSidebarView == ApplicationConstants.SOURCING_PAGE) {
      this.adminSourcingEventService.loadSourcingEventDtos();
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.SOURCING_PAGE
      );
    } else if (this.selectedSidebarView == ApplicationConstants.IN_HOUSE_USER_PAGE) {
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.IN_HOUSE_USER_PAGE
      );
    } else if (this.selectedSidebarView == ApplicationConstants.BIDDER_NETWORK_PAGE) {
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.BIDDER_NETWORK_PAGE
      );
    } else if (this.selectedSidebarView == ApplicationConstants.ONBOARDING_PAGE) {
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.ONBOARDING_PAGE
      );
    } else if (this.selectedSidebarView == ApplicationConstants.ADMIN_CATALOGUE_PAGE) {
      this.catalogueService.updateSelectedCatalogueL1EntityDto(undefined);
      this.catalogueService.updateSelectedCatalogueL2EntityDto(undefined);

      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.ADMIN_CATALOGUE_PAGE
      );
    } else if (this.selectedSidebarView == ApplicationConstants.APPROVED_PAGE) {
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.APPROVED_PAGE
      );
    } else if (this.selectedSidebarView == ApplicationConstants.PROJECTS_PAGE) {
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.PROJECTS_PAGE
      );
    } else if (this.selectedSidebarView == ApplicationConstants.ADMIN_PURCHASE_ORDER_PAGE) {
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.ADMIN_PURCHASE_ORDER_PAGE
      );
    } else if (this.selectedSidebarView == ApplicationConstants.ADMIN_INVOICE_PAGE) {
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.ADMIN_INVOICE_PAGE
      );
    }
    else if (this.selectedSidebarView == ApplicationConstants.PURCHASE_REQUEST_PAGE) {
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        ApplicationConstants.PURCHASE_REQUEST_PAGE
      );
    } else {
      this.dataRedirectionService.storeNavigationContext(
        ApplicationConstants.ADMIN,
        this.selectedSidebarView
      );
    }

    // else if (this.currentViewTabName == AuctionExtConstant.POST_AUCTION) {
    //   let selectedAuction = this.adminSourcingEventService.getSelectedSourcingEvent();
    //   this.dataRedirectionService.storeNavigationContext(
    //     AuctionExtConstant.ADMIN,
    //     AuctionExtConstant.AUCTION_CREATOR_PAGE,
    //     AuctionExtConstant.POST_AUCTION_TAB,
    //     `${selectedAuction?.sequenceNo}`
    //   );
    // }


    // this.dataRedirectionService.handleDashboardAppNavigation();
    this.dataRedirectionService.setUpdateBreadcrumbs(true);
  }

  closeModal() {
    this.ngbModal.dismissAll()
  }



  attachAuctionChangesListener() {
    let lastFetchTimestamp = Timestamp.now();

    logEvent(getAnalytics(), "attachAuctionChangesListener");

    let orgCode = this.onboardingService.getOrgCode();
    if (orgCode) {
      let auctionRef = collection(this.firestore, this.collectionAuctions);
      let auctionQuery = query(auctionRef,
        where('orgCode', '==', orgCode),
        where('updateTimeUtc', '>', lastFetchTimestamp));

      this.auctionListenerUnsubscribe = onSnapshot(auctionQuery, documentSnapshots => {
        let auctionsList = documentSnapshots.docChanges().map(change => change.doc.data() as AuctionEntityDto);

        logEvent(getAnalytics(), 'attachAuctionChangesListener Data Received size : ' + auctionsList.length);
        console.log("attachAuctionChangesListener Data Received size: " + auctionsList.length);

        if (auctionsList && auctionsList.length > 0) {

          auctionsList.forEach(updatedAuctionDto => {
            // Changed Firestore timestamp to server timestamp
            let timestamp = updatedAuctionDto.updateTimeUtc as Timestamp;
            updatedAuctionDto.updateTimeUtc = (timestamp.seconds * 1000).toString();

          })
          this.adminSourcingEventService.updateAuctionsChangesFromFirestore(auctionsList!)
        }
      });
    }
  }



  attachRfxChangesListener() {
    let lastFetchTimestamp = Timestamp.now();

    logEvent(getAnalytics(), "attachRfxChangesListener");

    let orgCode = this.onboardingService.getOrgCode();
    if (orgCode) {
      let rfxRef = collection(this.firestore, this.collectionRfx);
      let rfxQuery = query(rfxRef,
        where('orgCode', '==', orgCode),
        where('updateTimeUtc', '>', lastFetchTimestamp));

      this.rfxListenerUnsubscribe = onSnapshot(rfxQuery, documentSnapshots => {
        let rfxList = documentSnapshots.docChanges().map(change => change.doc.data() as RfxUiDto);

        logEvent(getAnalytics(), 'attachRfxChangesListener Data Received size : ' + rfxList.length);
        console.log("attachRfxChangesListener Data Received size: " + rfxList.length);

        if (rfxList && rfxList.length > 0) {

          rfxList.forEach(updatedAuctionDto => {
            // Changed Firestore timestamp to server timestamp
            let timestamp = updatedAuctionDto.updateTimeUtc as Timestamp;
            updatedAuctionDto.updateTimeUtc = (timestamp.seconds * 1000).toString();

          })
          this.adminSourcingEventService.updateRfxChangesFromFirestore(rfxList!)
        }
      });
    }
  }



  ngOnDestroy(): void {
    if (this.userRegistrationsSubscription$) {
      this.userRegistrationsSubscription$.unsubscribe();
    }
    if (this.updateBreadcrumbsSubscription$) {
      this.updateBreadcrumbsSubscription$.unsubscribe();
    }
    if (this.allAuctionsLoadedSubscription$) {
      this.allAuctionsLoadedSubscription$.unsubscribe();
    }
    if (this.currentOrganizationSubscription$) {
      this.currentOrganizationSubscription$.unsubscribe();
    }
    if (this.auctionListenerUnsubscribe) {
      this.auctionListenerUnsubscribe();
    }
    // if (this.rfxListenerUnsubscribe) {
    //   this.rfxListenerUnsubscribe();
    // }
  }
}
