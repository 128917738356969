<div class="container">
  <div class="">
    <div class="p-2">
      <div class="row align-items-center">
        <div class="col-md-3  text-start mb-2">
          <div class="mb-2 input-group">
            <input #search placeholder="Search" type="text" id="ctrlSearchPR" [formControl]="ctrlSearch"
              class="form-control border-end-0 rounded-0 border bg-white" />
            <span class="input-group-append">
              <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
              </button>
            </span>
          </div>
        </div>
        <div class="col-md-5 text-start mb-2">
        </div>
        <div class="col-md-4 mb-2 text-md-end">
          <button class="btn btn-sm btn-new-class mt-2 mt-sm-0" *ngIf="isShowCompanyView"
            (click)="backToAllCompaniesList()"> BACK
          </button>
          <button class="btn btn-sm btn-new-class mt-2 mt-sm-0" *ngIf="!isShowCompanyView"
            (click)="openCompanyDetail(editCompanyDetail)"> ADD
          </button>
        </div>
      </div>
      <div class="col-12">
        <ng-container *ngTemplateOutlet="isShowCompanyView ? companyDetailsTemplate : companiesListTemplate">
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #companiesListTemplate>
  <div class="table-responsive">
    <table class="table table-bordered medium-font mb-0 align-middle">
      <thead class="table-dark">
        <tr class="text-center">
          <th class="text-center"><span>SNo</span></th>
          <th class=""><span>Company Name</span></th>
          <!-- <th class=""><span>Allocated Budget</span></th>
          <th>Un Allocated Budget</th> -->
          <th class=""><span>Plants</span></th>
          <th class=""><span>Users</span></th>
          <th class=""><span>Action</span></th>
        </tr>
      </thead>

      <tbody class="bg-white">
        <tr *ngIf="isDataLoading" class="align-middle">
          <td colspan="10" class="text-center">
            <div class="e-spinner"></div>
          </td>
        </tr>
        <tr *ngIf="!isDataLoading && companiesList.length == 0">
          <td colspan="10" class="text-center">
            <h5 class="heading-new">No Data Found</h5>
          </td>
        </tr>
        <tr class="text-center align-middle" *ngFor="let item of _filteredCompanies$ | async; index as i;">
          <td class="">
            {{i+1}}
          </td>
          <td class="">
            {{item.companyName}}-({{item.companyCode}})
          </td>
          <!-- <td class="">
            {{0}} N
          </td>
          <td class="">
            {{0}} N
          </td> -->
          <td class="">
            <a class="link-class" (click)="navigateInternalPage('ONBOARDING_PLANT', item)">
              {{item.plantUiDtos?.length || 0}}
            </a>
          </td>
          <td class="">
            <a class="link-class" (click)="navigateInternalPage('ONBOARDING_USERS', item)">
              {{item.usersCount || 0}}
            </a>
          </td>
          <td class="">
            <div class="animate-dropdown" ngbDropdown #menuDropdown="ngbDropdown" container="body"
              placement="bottom-right">
              <span ngbDropdownToggle data-bs-toggle="dropdown">
                <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class-small">
              </span>
              <ul ngbDropdownMenu class="shadow mt-1 ">
                <li ngbDropdownItem (click)="viewCompanyDetails(item)">
                  View
                </li>
                <hr class="hr my-2">
                <li ngbDropdownItem (click)="openUserDetail(addUserDetailTemplate, item)">
                  Create User
                </li>
                <hr class="hr my-2">
                <li ngbDropdownItem (click)="openPlantDetail(addPlantDetailsTemplate, item)">
                  Create Plant
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #companyDetailsTemplate>
  <div class="container py-3">
    <div class="col-12">
      <div class="border rounded bg-white mb-3">
        <header class="p-2">
          <div class="row ">
            <div class="col-md-6">
              <h5 class="heading-h5">COMPANY DETAILS</h5>
            </div>
            <div class="col-md-6 text-md-end">
              <button class="btn btn-new-class btn-sm px-4 " (click)="openCompanyDetail(editCompanyDetail)">
                EDIT
              </button>
            </div>
          </div>
        </header>
        <hr class="hr" />
        <div class="col-12">
          <div class="row mx-0">
            <div class="col-12 div-class py-2">
              Company Information
            </div>
            <hr class="hr" />
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-4 font-weight-normal py-2">
                    Company Name
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedCompanyUiDto?.companyName}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Primary
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedCompanyUiDto?.primaryCompany ? 'Yes' : 'No'}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Industry Sector
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedCompanyUiDto?.industrySector}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Country
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedCompanyUiDto?.country}}
                  </div>
                  <hr class="hr" />

                  <div class="col-4 font-weight-normal py-2">
                    Phone
                  </div>
                  <div class="col-8 div-class py-2">
                    {{getCountryCode(selectedCompanyUiDto?.phoneCountryCode)}}{{selectedCompanyUiDto?.phoneNumber}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Tax Id 1
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedCompanyUiDto?.taxId1}}
                  </div>
                  <hr class="hr" />

                  <div class="col-4 font-weight-normal py-2 ">
                    Time Zone Locale
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedCompanyUiDto?.timeZone}}
                  </div>
                  <hr class="hr" />

                  <div class="col-4 font-weight-normal py-2 ">
                    Fiscal Yr Start
                  </div>
                  <div class="col-8 div-class py-2">
                    {{getFormattedFinancialYear(selectedCompanyUiDto?.fiscalYrStart!)}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2 ">
                    Plants
                  </div>
                  <div class="col-8 div-class py-2">
                    <a class="link-class" (click)="navigateInternalPage('ONBOARDING_PLANT')">
                      <b>View</b>
                    </a>
                  </div>
                  <hr class="hr" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-4 font-weight-normal py-2">
                    Company Code
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedCompanyUiDto?.companyCode}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Legal Type
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedCompanyUiDto?.legalType}}
                  </div>

                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Region
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedCompanyUiDto?.region}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2 ">
                    Branch
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedCompanyUiDto?.branch}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Fax
                  </div>
                  <div class="col-8 div-class py-2">
                    {{getCountryCode(selectedCompanyUiDto?.faxCountryCode)}}{{selectedCompanyUiDto?.faxNumber}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Tax Id 2
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedCompanyUiDto?.taxId2}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2 ">
                    Timezone
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedCompanyUiDto?.timeZone}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2 ">
                    Fiscal Yr End
                  </div>
                  <div class="col-8 div-class py-2">
                    {{getFormattedFinancialYear(selectedCompanyUiDto?.fiscalYrEnd!)}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2 ">
                    Users
                  </div>
                  <div class="col-8 div-class py-2">
                    <a class="link-class" (click)="navigateInternalPage('ONBOARDING_USERS')">
                      <b>View</b>
                    </a>
                  </div>
                  <hr class="hr" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <div class="border rounded bg-white mb-3">
            <header class="p-2">
              <div class="row ">
                <div class="col-md-6">
                  <h5 class="heading-h5">BILLING ADDRESS</h5>
                </div>
                <div class="col-md-6 text-md-end">
                  <!-- <button class="btn btn-new-class btn-sm px-4 " (click)="openAddBillingAddress(billingAddress)">
                    ADD
                  </button> -->
                </div>
              </div>
            </header>
            <hr class="hr" />
            <div class=" p-2 mb-1">
              <div class="row mx-0">
                <div class="col-12 div-class py-2">
                  Billing Address Info SNo.
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Title
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedBillingAddress?.addressType}}
                </div>
                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Address Line 1
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedBillingAddress?.addressLine1}}
                </div>
                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Address Line 2
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedBillingAddress?.addressLine2}}
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  City
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedBillingAddress?.city}}
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  State
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedBillingAddress?.state}}
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Country
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedBillingAddress?.country}}
                </div>

                <hr class="hr" />
                <div class="bg-secondary text-center border rounded" [ngClass]="{'py-5': billingLatitude == undefined}">
                  <google-map height="32vh" width="100%" [options]="options" [center]="positionBilling!" [zoom]="zoom"
                    *ngIf="billingLatitude != undefined">
                    <map-marker #billingMarker="mapMarker" [position]="positionBilling!" [title]="markerBilling.title"
                      [options]="markerBilling.options" (mapClick)="openMarkerInfo(billingMarker)">
                    </map-marker>
                    <map-info-window>{{ markerBilling.info }}</map-info-window>
                  </google-map>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="border rounded bg-white mb-3">
            <header class="p-2">
              <div class="row ">
                <div class="col-md-6">
                  <h5 class="heading-h5">SHIPPING ADDRESS</h5>
                </div>
                <div class="col-md-6 text-md-end">
                  <!-- <button class="btn btn-new-class btn-sm px-4 " (click)="openAddShippingAddress(shippingAddress)">
                    ADD
                  </button> -->
                </div>
              </div>
            </header>
            <hr class="hr" />
            <div class="p-2 mb-1">
              <div class="row mx-0">
                <div class="col-12 div-class py-2">
                  Billing Address Info SNo.
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Title
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedShippingAddress?.addressType}}
                </div>
                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Address Line 1
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedShippingAddress?.addressLine1}}
                </div>
                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Address Line 2
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedShippingAddress?.addressLine2}}
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  City
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedShippingAddress?.city}}
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  State
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedShippingAddress?.state}}
                </div>

                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Country
                </div>
                <div class="col-8 div-class py-2">
                  {{selectedShippingAddress?.country}}
                </div>

                <hr class="hr" />
                <div class="bg-secondary text-center border rounded"
                  [ngClass]="{'py-5': shippingLatitude == undefined}">
                  <google-map height="32vh" width="100%" [options]="options" [center]="positionShipping!" [zoom]="zoom"
                    *ngIf="shippingLatitude != undefined">
                    <map-marker #shippingMarker="mapMarker" [position]="positionShipping!"
                      [title]="markerShipping.title" [options]="markerShipping.options"
                      (mapClick)="openMarkerInfo(shippingMarker)">
                    </map-marker>
                    <map-info-window>{{ markerShipping.info }}</map-info-window>
                  </google-map>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editCompanyDetail>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Company Detail</h2>
        </div>
        <div class="col-auto text-end">
          <span [ngClass]="{'disabled' : isLoading }">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
              (click)="closeModal()"></span>
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="saveCompany()">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="companyName" formControlName="companyName">
            <label for="companyName">Company Name</label>
            <div *ngIf="fc['companyName'].invalid && (fc['companyName'].dirty || fc['companyName'].touched)">
              <p class="text-danger" *ngIf="fc['companyName'].errors?.['required']">Required
              </p>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="companyCode" formControlName="companyCode">
            <label for="companyCode">Company Code</label>
            <div *ngIf="fc['companyCode'].invalid && (fc['companyCode'].dirty || fc['companyCode'].touched)">
              <p class="text-danger" *ngIf="fc['companyCode'].errors?.['required']">Required
              </p>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-8">Is Primary:</div>
            <div class="col-md-2 col-6">
              <input type="radio" id="isPrimaryYes" [value]="true" formControlName="primaryCompany">
              <label class="ms-3" for="isPrimaryYes">Yes</label><br>
            </div>
            <div class="col-md-2 col-6">
              <input type="radio" id="isPrimaryNo" [value]="false" formControlName="primaryCompany">
              <label class="ms-3" for="isPrimaryNo">No</label><br>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <select name="Select Region" class="form-control form-select" id="legalType"
                  formControlName="legalType">
                  <option value="" disabled>Select . . .</option>
                  <option value="Limited Liability Company (LLC)">Limited Liability Company (LLC)</option>
                  <option value="Limited Liability Partnership (LLP)">Limited Liability Partnership (LLP)</option>
                  <option value="Private Limited">Private Limited</option>
                  <option value="Public Limited">Public Limited</option>
                  <option value="Sole Proprietary">Sole Proprietary</option>
                  <option value="One Person Company">One Person Company</option>
                  <option value="Non-profit">Non-profit</option>
                  <option value="Government">Government</option>
                </select>
                <label for="legalType">Legal Type</label>

                <div *ngIf="fc['legalType'].invalid && (fc['legalType'].dirty || fc['legalType'].touched)">
                  <p class="text-danger" *ngIf="fc['legalType'].errors?.['required']">Required
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <select name="Select Region" class="form-control form-select" id="indsSec"
                  formControlName="industrySector">
                  <option value="" disabled>Select . . .</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Energy and Natural Resources">Energy and Natural Resources</option>
                  <option value="Consumer Industries">Consumer Industries</option>
                  <option value="Service Industries">Service Industries</option>
                  <option value="Government">Government</option>
                  <option value="Financial Services">Financial Services</option>
                </select>
                <label for="indsSec">Industry Sector</label>

                <div
                  *ngIf="fc['industrySector'].invalid && (fc['industrySector'].dirty || fc['industrySector'].touched)">
                  <p class="text-danger" *ngIf="fc['industrySector'].errors?.['required']">Required
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <select name="Select Region" class="form-control form-select" formControlName="region" id="region">
                  <option value="" disabled>Select . . .</option>
                  <option value="North America">North America</option>
                  <option value="Latin America (LATAM)">Latin America (LATAM)</option>
                  <option value="Europe, Middle East, and Africa (EMEA)">Europe, Middle East, and Africa (EMEA)
                  </option>
                  <option value="Asia-Pacific (APAC)">Asia-Pacific (APAC)</option>
                  <option value="Sub-Saharan Africa">Sub-Saharan Africa</option>
                </select>
                <label for="region">Region</label>
                <div *ngIf="fc['region'].invalid && (fc['region'].dirty || fc['region'].touched)">
                  <p class="text-danger" *ngIf="fc['region'].errors?.['required']">Required
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <select name="Select Region" class="form-control form-select" id="country" formControlName="country"
                  (change)="setCountryValidation()">
                  <option value="" disabled>Select . . .</option>
                  <option [value]="item.name" *ngFor="let item of onboardingCountries;">
                    {{item.name}}
                  </option>
                </select>
                <label for="country">Country</label>
                <div *ngIf="fc['country'].invalid && (fc['country'].dirty || fc['country'].touched)">
                  <p class="text-danger" *ngIf="fc['country'].errors?.['required']">Required
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <span class="input-group-addon">
                  <app-country-code-select #countryCodePhone (countryCodeEvent)="handlePhoneCountryCode($event)"
                    [countryCode$]="countryCodePhone$">
                  </app-country-code-select>
                </span>
                <div class="form-floating">
                  <input type="text" class="form-control" id="mobileNo" placeholder="Phone"
                    formControlName="phoneNumber">
                  <label for="mobileNo">Phone</label>
                  <div *ngIf="fc['phoneNumber'].invalid && (fc['phoneNumber'].dirty || fc['phoneNumber'].touched)">
                    <p class="text-danger" *ngIf="fc['phoneNumber'].errors?.['required']">Required
                    </p>
                    <p class="text-danger" *ngIf="fc['phoneNumber'].errors?.['pattern']">Invalid
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <span class="input-group-addon">
                  <app-country-code-select #countryCodeFax (countryCodeEvent)="handleFaxCountryCode($event)"
                    [countryCode$]="countryCodeFax$">
                  </app-country-code-select>
                </span>
                <div class="form-floating">
                  <input type="text" class="form-control" id="faxNo" placeholder="Phone" formControlName="faxNumber">
                  <label for="faxNo">Fax</label>
                  <div *ngIf="fc['faxNumber'].invalid && (fc['faxNumber'].dirty || fc['faxNumber'].touched)">
                    <p class="text-danger" *ngIf="fc['faxNumber'].errors?.['pattern']">Invalid
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="selectedOnboardingCountry">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="textIdOne" formControlName="taxId1">
                <label for="textIdOne">{{selectedOnboardingCountry.taxOne}}</label>
                <div *ngIf="fc['taxId1'].invalid && (fc['taxId1'].dirty || fc['taxId1'].touched)">
                  <p class="text-danger" *ngIf="fc['taxId1'].errors?.['required']">Required</p>
                  <p class="text-danger" *ngIf="fc['taxId1'].errors?.['pattern']">Invalid</p>
                  <p class="text-danger" *ngIf="fc['taxId1'].errors?.['minlength']">Invalid</p>
                  <p class="text-danger" *ngIf="fc['taxId1'].errors?.['maxlength']">Invalid</p>
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="selectedOnboardingCountry && selectedOnboardingCountry.taxTwo">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="textIdTwo" formControlName="taxId2">
                <label for="textIdTwo">{{selectedOnboardingCountry.taxTwo}}</label>
                <div *ngIf="fc['taxId2'].invalid && (fc['taxId2'].dirty || fc['taxId2'].touched)">
                  <p class="text-danger" *ngIf="fc['taxId2'].errors?.['required']">Required</p>
                  <p class="text-danger" *ngIf="fc['taxId2'].errors?.['pattern']">Invalid</p>
                  <p class="text-danger" *ngIf="fc['taxId2'].errors?.['minlength']">Invalid</p>
                  <p class="text-danger" *ngIf="fc['taxId2'].errors?.['maxlength']">Invalid</p>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <select name="Select Branch" class="form-control form-select" formControlName="branch" id="branch">
                  <option value="" disabled>Select . . .</option>
                  <option [value]="true">Yes</option>
                  <option [value]="false">No</option>
                </select>
                <label for="branch">Branch</label>
                <div *ngIf="fc['branch'].invalid && (fc['branch'].dirty || fc['branch'].touched)">
                  <p class="text-danger" *ngIf="fc['branch'].errors?.['required']">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <select name="Select Timezone" class="form-control form-select" id="timeZone" formControlName="timeZone"
                  [ngClass]="{'is-invalid': fc['timeZone'].invalid && (fc['timeZone'].dirty || fc['timeZone'].touched)}">
                  <option value="" disabled>Select . . .</option>
                  <option [value]="item?.locale" *ngFor="let item of timezones">{{item?.fullName}}</option>
                </select>
                <label for="timeZone">Timezone</label>
                <div *ngIf="fc['timeZone'].invalid && (fc['timeZone'].dirty || fc['timeZone'].touched)">
                  <p class="text-danger" *ngIf="fc['timeZone'].errors?.['required']">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input ngbDatepicker #fiscalYrStart="ngbDatepicker" type="text" class="form-control" id="fiscalYrStart"
                  formControlName="fiscalYrStart" (click)="fiscalYrStart.toggle()"
                  [ngClass]="{'is-invalid': fc['fiscalYrStart'].invalid && (fc['fiscalYrStart'].dirty || fc['fiscalYrStart'].touched)}">
                <label for="fiscalYrStart">Fiscal Yr Start</label>
                <div *ngIf="fc['fiscalYrStart'].invalid && (fc['fiscalYrStart'].dirty || fc['fiscalYrStart'].touched)">
                  <p class="text-danger" *ngIf="fc['fiscalYrStart'].errors?.['required']">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input ngbDatepicker #fiscalYrEnd="ngbDatepicker" type="text" class="form-control" id="fiscalYrEnd"
                  formControlName="fiscalYrEnd" (click)="fiscalYrEnd.toggle()"
                  [ngClass]="{'is-invalid': fc['fiscalYrEnd'].invalid && (fc['fiscalYrEnd'].dirty || fc['fiscalYrEnd'].touched)}">
                <label for="fiscalYrEnd">Fiscal Yr End</label>
                <div *ngIf="fc['fiscalYrEnd'].invalid && (fc['fiscalYrEnd'].dirty || fc['fiscalYrEnd'].touched)">
                  <p class="text-danger" *ngIf="fc['fiscalYrEnd'].errors?.['required']">Required</p>
                </div>
              </div>
            </div>
            <div class="my-2">
              Billing Address
            </div>
            <div class="row" formGroupName="billingAddress">
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="Title" placeholder="Title"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('addressType')?.invalid && (fc['billingAddress'].get('addressType')?.dirty || fc['billingAddress'].get('addressType')?.touched)}"
                    formControlName="addressType">
                  <label for="Title">Title</label>
                  <div
                    *ngIf="fc['billingAddress'].get('addressType')?.invalid && (fc['billingAddress'].get('addressType')?.dirty || fc['billingAddress'].get('addressType')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('addressType')?.errors?.['required']">
                      Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input ngx-google-places-autocomplete #placesRef="ngx-places" type="text" class="form-control"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('searchAddress')?.invalid && (fc['billingAddress'].get('searchAddress')?.dirty || fc['billingAddress'].get('searchAddress')?.touched)}"
                    id="searchAddress" placeholder="Search" formControlName="searchAddress"
                    (onAddressChange)="handleBillingAddressChange($event)">
                  <label for="searchAddress">Search Address</label>
                  <div
                    *ngIf="fc['billingAddress'].get('searchAddress')?.invalid && (fc['billingAddress'].get('searchAddress')?.dirty || fc['billingAddress'].get('searchAddress')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('searchAddress')?.errors?.['required']">
                      Required</p>
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('searchAddress')?.errors?.['address']">
                      Invalid</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="addressLine1" placeholder="Address Line 1"
                    formControlName="addressLine1"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('addressLine1')?.invalid && (fc['billingAddress'].get('addressLine1')?.dirty || fc['billingAddress'].get('addressLine1')?.touched)}">
                  <label for="addressLine1"> Address Line 1</label>
                  <div
                    *ngIf="fc['billingAddress'].get('addressLine1')?.invalid && (fc['billingAddress'].get('addressLine1')?.dirty || fc['billingAddress'].get('addressLine1')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('addressLine1')?.errors?.['required']">
                      Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="adressLineTwo" formControlName="addressLine2">
                  <label for="adressLineTwo">Address Line 2</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="city" placeholder="City" formControlName="city"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('city')?.invalid && (fc['billingAddress'].get('city')?.dirty || fc['billingAddress'].get('city')?.touched)}">
                  <label for="city">City</label>
                  <div
                    *ngIf="fc['billingAddress'].get('city')?.invalid && (fc['billingAddress'].get('city')?.dirty || fc['billingAddress'].get('city')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('city')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="state" placeholder="State" formControlName="state"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('state')?.invalid && (fc['billingAddress'].get('state')?.dirty || fc['billingAddress'].get('state')?.touched)}">
                  <label for="state">State</label>
                  <div
                    *ngIf="fc['billingAddress'].get('state')?.invalid && (fc['billingAddress'].get('state')?.dirty || fc['billingAddress'].get('state')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('state')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="country" placeholder="Country" formControlName="country"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('country')?.invalid && (fc['billingAddress'].get('country')?.dirty || fc['billingAddress'].get('country')?.touched)}">
                  <label for="country">Country</label>
                  <div
                    *ngIf="fc['billingAddress'].get('country')?.invalid && (fc['billingAddress'].get('country')?.dirty || fc['billingAddress'].get('country')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('country')?.errors?.['required']">Required
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="zipCode" placeholder="Zip" formControlName="zipCode"
                    [ngClass]="{'is-invalid': fc['billingAddress'].get('zipCode')?.invalid && (fc['billingAddress'].get('zipCode')?.dirty || fc['billingAddress'].get('zipCode')?.touched)}">
                  <label for="zipCode">Zip</label>
                  <div
                    *ngIf="fc['billingAddress'].get('zipCode')?.invalid && (fc['billingAddress'].get('zipCode')?.dirty || fc['billingAddress'].get('zipCode')?.touched)">
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('zipCode')?.errors?.['required']">Required
                    </p>
                    <p class="text-danger" *ngIf="fc['billingAddress'].get('zipCode')?.errors?.['pattern']">Invalid</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="my-2">
              Shipping Address
            </div>
            <div class="row" formGroupName="shippingAddress">
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="Title" placeholder="Title"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('addressType')?.invalid && (fc['shippingAddress'].get('addressType')?.dirty || fc['shippingAddress'].get('addressType')?.touched)}"
                    formControlName="addressType">
                  <label for="Title">Title</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('addressType')?.invalid && (fc['shippingAddress'].get('addressType')?.dirty || fc['shippingAddress'].get('addressType')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('addressType')?.errors?.['required']">
                      Required
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input ngx-google-places-autocomplete #placesRef="ngx-places" type="text" class="form-control"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('searchAddress')?.invalid && (fc['shippingAddress'].get('searchAddress')?.dirty || fc['shippingAddress'].get('searchAddress')?.touched)}"
                    id="searchAddress" placeholder="Search" formControlName="searchAddress"
                    (onAddressChange)="handleShippingAddressChange($event)">
                  <label for="searchAddress">Search Address</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('searchAddress')?.invalid && (fc['shippingAddress'].get('searchAddress')?.dirty || fc['shippingAddress'].get('searchAddress')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('searchAddress')?.errors?.['required']">
                      Required</p>
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('searchAddress')?.errors?.['address']">
                      Invalid</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="addressLine1" placeholder="Address Line 1"
                    formControlName="addressLine1"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('addressLine1')?.invalid && (fc['shippingAddress'].get('addressLine1')?.dirty || fc['shippingAddress'].get('addressLine1')?.touched)}">
                  <label for="addressLine1"> Address Line 1</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('addressLine1')?.invalid && (fc['shippingAddress'].get('addressLine1')?.dirty || fc['shippingAddress'].get('addressLine1')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('addressLine1')?.errors?.['required']">
                      Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="adressLineTwo" formControlName="addressLine2">
                  <label for="adressLineTwo">Address Line 2</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="city" placeholder="City" formControlName="city"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('city')?.invalid && (fc['shippingAddress'].get('city')?.dirty || fc['shippingAddress'].get('city')?.touched)}">
                  <label for="city">City</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('city')?.invalid && (fc['shippingAddress'].get('city')?.dirty || fc['shippingAddress'].get('city')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('city')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="state" placeholder="State" formControlName="state"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('state')?.invalid && (fc['shippingAddress'].get('state')?.dirty || fc['shippingAddress'].get('state')?.touched)}">
                  <label for="state">State</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('state')?.invalid && (fc['shippingAddress'].get('state')?.dirty || fc['shippingAddress'].get('state')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('state')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="country" placeholder="Country" formControlName="country"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('country')?.invalid && (fc['shippingAddress'].get('country')?.dirty || fc['shippingAddress'].get('country')?.touched)}">
                  <label for="country">Country</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('country')?.invalid && (fc['shippingAddress'].get('country')?.dirty || fc['shippingAddress'].get('country')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('country')?.errors?.['required']">Required
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="zipCode" placeholder="Zip" formControlName="zipCode"
                    [ngClass]="{'is-invalid': fc['shippingAddress'].get('zipCode')?.invalid && (fc['shippingAddress'].get('zipCode')?.dirty || fc['shippingAddress'].get('zipCode')?.touched)}">
                  <label for="zipCode">Zip</label>
                  <div
                    *ngIf="fc['shippingAddress'].get('zipCode')?.invalid && (fc['shippingAddress'].get('zipCode')?.dirty || fc['shippingAddress'].get('zipCode')?.touched)">
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('zipCode')?.errors?.['required']">Required
                    </p>
                    <p class="text-danger" *ngIf="fc['shippingAddress'].get('zipCode')?.errors?.['pattern']">Invalid</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" message="Company Saved"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="(this._showErrorMsg$ | async)">
            </app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="col-12 text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class c-pointer" (click)="saveCompany()">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #billingAddress>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Billing Address</h2>
        </div>
        <div class="col-auto text-end">
          <span [ngClass]="{'disabled' : isLoading }">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
              (click)="closeModal()"></span>
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate>
          <div class="col-md-12">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="title">
              <label for="title">Title</label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="searchAddress">
              <label for="searchAddress">Search Address</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="adressLineOne">
              <label for="adressLineOne">Address Line 1</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="adressLineTwo">
              <label for="adressLineTwo">Address Line 2</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="city">
              <label for="city">City</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="state">
              <label for="state">State</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="country">
              <label for="country">Country</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="zip">
              <label for="zip">Zip</label>
            </div>
          </div>
          <div class="col-12 text-end mb-3">
            <button type="button" class="btn btn-new-class c-pointer">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #shippingAddress>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Shipping Address</h2>
        </div>
        <div class="col-auto text-end">
          <span [ngClass]="{'disabled' : isLoading }">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
              (click)="closeModal()"></span>
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate>
          <div class="col-md-12">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="title">
              <label for="title">Title</label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="searchAddress">
              <label for="searchAddress">Search Address</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="adressLineOne">
              <label for="adressLineOne">Address Line 1</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="adressLineTwo">
              <label for="adressLineTwo">Address Line 2</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="city">
              <label for="city">City</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="state">
              <label for="state">State</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="country">
              <label for="country">Country</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="zip">
              <label for="zip">Zip</label>
            </div>
          </div>
          <div class="col-12 text-end mb-3">
            <button type="button" class="btn btn-new-class c-pointer">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #addUserDetailTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>User Detail</h2>
        </div>
        <div class="col-auto text-end">
          <span [ngClass]="{'disabled' : isLoading }">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
              (click)="closeModal()"></span>
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroupUser" (keyup.enter)="saveUser()">
          <div class="row">
            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="firstName" placeholder="First Name"
                  formControlName="firstName"
                  [ngClass]="{'is-invalid': uc['firstName'].invalid && (uc['firstName'].dirty || uc['firstName'].touched)}">
                <label for="firstName">First Name</label>
                <div *ngIf="uc['firstName'].invalid && (uc['firstName'].dirty || uc['firstName'].touched)">
                  <p class="text-danger" *ngIf="uc['firstName'].errors?.['required']">Required
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="lastName" placeholder="Last Name" formControlName="lastName"
                  [ngClass]="{'is-invalid': uc['lastName'].invalid && (uc['lastName'].dirty || uc['lastName'].touched)}">
                <label for="lastName">Last Name</label>
                <div *ngIf="uc['lastName'].invalid && (uc['lastName'].dirty || uc['lastName'].touched)">
                  <p class="text-danger" *ngIf="uc['lastName'].errors?.['required']">Required
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="primaryEmailId" placeholder="name@example.com"
                  formControlName="primaryEmailId"
                  [ngClass]="{'is-invalid': uc['primaryEmailId'].invalid && (uc['primaryEmailId'].dirty || uc['primaryEmailId'].touched)}">
                <label for="primaryEmailId">Email</label>
                <div
                  *ngIf="uc['primaryEmailId'].invalid && (uc['primaryEmailId'].dirty || uc['primaryEmailId'].touched)">
                  <p class="text-danger" *ngIf="fc['primaryEmailId'].errors?.['required']">Required</p>
                  <p class="text-danger" *ngIf="fc['primaryEmailId'].errors?.['pattern']">
                    Invalid email address
                  </p>
                  <p class="text-danger" *ngIf="fc['primaryEmailId'].value && fc['primaryEmailId'].value.length > 50">
                    Maximum Length Exceed
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="empCode" formControlName="empCode" placeholder="Employee Code">
                <label for="empCode">Employee Code</label>
              </div>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select name="Select Designation" class="form-control form-select" formControlName="designation"
              id="designation"
              [ngClass]="{'is-invalid': uc['designation'].invalid && (uc['designation'].dirty || uc['designation'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="designation?.name" *ngFor="let designation of applicationStartupDto?.cacheDesignationsDtos;">
                {{designation?.name}}
              </option>
            </select>
            <label for="designation">Designation</label>
            <div *ngIf="uc['designation'].invalid && (uc['designation'].dirty || uc['designation'].touched)">
              <p class="text-danger" *ngIf="uc['designation'].errors?.['required']">Required
              </p>
            </div>
          </div>

          <!-- <div class="form-floating mb-3">
            <p-multiSelect [options]="(selectedCompanyUiDto?.plantUiDtos ?? [])" formControlName="plantCodes"
              optionLabel="plantName" optionValue="plantCode"></p-multiSelect>
          </div> -->

          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Onboarding User Saved'">
            </app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="(this._showErrorMsg$ | async)">
            </app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="col-12 text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class c-pointer" (click)="saveUser()">Add</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addPlantDetailsTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Plant Detail</h2>
        </div>
        <div class="col-auto text-end">
          <span [ngClass]="{'disabled' : isLoading }">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
              (click)="closeModal()"></span>
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroupPlants" (keyup.enter)="saveOnboardingPlants()">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="plantName" formControlName="plantName"
              [ngClass]="{'is-invalid': pc['plantName'].invalid && (pc['plantName'].dirty || pc['plantName'].touched)}">
            <label for="plantName">Plant Name</label>
            <div class="invalid-feedback"
              *ngIf="pc['plantName'].invalid && (pc['plantName'].dirty || pc['plantName'].touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="pc['plantName'].errors?.['required']">Required</p>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="plantCode" formControlName="plantCode"
              [ngClass]="{'is-invalid': pc['plantCode'].invalid && (pc['plantCode'].dirty || pc['plantCode'].touched)}">
            <label for="plantCode">Plant Id</label>
            <div class="invalid-feedback"
              *ngIf="pc['plantCode'].invalid && (pc['plantCode'].dirty || pc['plantCode'].touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="pc['plantCode'].errors?.['required']">Required</p>
            </div>
          </div>
          <div class="row">

            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="accountTitle" formControlName="accountTitle"
                  [ngClass]="{'is-invalid': pc['accountTitle'].invalid && (pc['accountTitle'].dirty || pc['accountTitle'].touched)}">
                <label for="accountTitle">Account Title</label>
                <div class="invalid-feedback"
                  *ngIf="pc['accountTitle'].invalid && (pc['accountTitle'].dirty || pc['accountTitle'].touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="pc['accountTitle'].errors?.['required']">Required
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="accNumber" formControlName="accNumber"
                  [ngClass]="{'is-invalid': pc['accNumber'].invalid && (pc['accNumber'].dirty || pc['accNumber'].touched)}">
                <label for="accNumber">Account Number</label>
                <div class="invalid-feedback"
                  *ngIf="pc['accNumber'].invalid && (pc['accNumber'].dirty || pc['accNumber'].touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="pc['accNumber'].errors?.['required']">Required
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="accHolderName" formControlName="accHolderName"
                  [ngClass]="{'is-invalid': pc['accHolderName'].invalid && (pc['accHolderName'].dirty || pc['accHolderName'].touched)}">
                <label for="accHolderName">Account Holder Name</label>
                <div class="invalid-feedback"
                  *ngIf="pc['accHolderName'].invalid && (pc['accHolderName'].dirty || pc['accHolderName'].touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="pc['accHolderName'].errors?.['required']">
                    Required
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="accType" formControlName="accType"
                  [ngClass]="{'is-invalid': pc['accType'].invalid && (pc['accType'].dirty || pc['accType'].touched)}">
                <label for="accType">Account Type</label>
                <div class="invalid-feedback"
                  *ngIf="pc['accType'].invalid && (pc['accType'].dirty || pc['accType'].touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="pc['accType'].errors?.['required']">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="accCode" formControlName="accCode"
                  [ngClass]="{'is-invalid': pc['accCode'].invalid && (pc['accCode'].dirty || pc['accCode'].touched)}">
                <label for="accCode">Account Code</label>
                <div class="invalid-feedback"
                  *ngIf="pc['accCode'].invalid && (pc['accCode'].dirty || pc['accCode'].touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="pc['accCode'].errors?.['required']">Required</p>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="swift" formControlName="swift"
                  [ngClass]="{'is-invalid': pc['swift'].invalid && (pc['swift'].dirty || pc['swift'].touched)}">
                <label for="swift">Swift</label>
                <div class="invalid-feedback" *ngIf="pc['swift'].invalid && (pc['swift'].dirty || pc['swift'].touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="pc['swift'].errors?.['required']">Required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="iban" formControlName="iban"
                  [ngClass]="{'is-invalid': pc['iban'].invalid && (pc['iban'].dirty || pc['iban'].touched)}">
                <label for="iban">Iban</label>
                <div class="invalid-feedback" *ngIf="pc['iban'].invalid && (pc['iban'].dirty || pc['iban'].touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="pc['iban'].errors?.['required']">Required</p>
                </div>
              </div>
            </div>
            <div class="my-2">
              Billing Address
            </div>
            <div class="row" formGroupName="billingAddress">
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="Title" placeholder="Title"
                    [ngClass]="{'is-invalid': pc['billingAddress'].get('addressType')?.invalid && (pc['billingAddress'].get('addressType')?.dirty || pc['billingAddress'].get('addressType')?.touched)}"
                    formControlName="addressType">
                  <label for="Title">Title</label>
                  <div
                    *ngIf="pc['billingAddress'].get('addressType')?.invalid && (pc['billingAddress'].get('addressType')?.dirty || pc['billingAddress'].get('addressType')?.touched)">
                    <p class="text-danger" *ngIf="pc['billingAddress'].get('addressType')?.errors?.['required']">
                      Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input ngx-google-places-autocomplete #placesRef="ngx-places" type="text" class="form-control"
                    [ngClass]="{'is-invalid': pc['billingAddress'].get('searchAddress')?.invalid && (pc['billingAddress'].get('searchAddress')?.dirty || pc['billingAddress'].get('searchAddress')?.touched)}"
                    id="searchAddress" placeholder="Search" formControlName="searchAddress"
                    (onAddressChange)="handleBillingAddressChange($event)">
                  <label for="searchAddress">Search Address</label>
                  <div
                    *ngIf="pc['billingAddress'].get('searchAddress')?.invalid && (pc['billingAddress'].get('searchAddress')?.dirty || pc['billingAddress'].get('searchAddress')?.touched)">
                    <p class="text-danger" *ngIf="pc['billingAddress'].get('searchAddress')?.errors?.['required']">
                      Required</p>
                    <p class="text-danger" *ngIf="pc['billingAddress'].get('searchAddress')?.errors?.['address']">
                      Invalid</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="addressLine1" placeholder="Address Line 1"
                    formControlName="addressLine1"
                    [ngClass]="{'is-invalid': pc['billingAddress'].get('addressLine1')?.invalid && (pc['billingAddress'].get('addressLine1')?.dirty || pc['billingAddress'].get('addressLine1')?.touched)}">
                  <label for="addressLine1"> Address Line 1</label>
                  <div
                    *ngIf="pc['billingAddress'].get('addressLine1')?.invalid && (pc['billingAddress'].get('addressLine1')?.dirty || pc['billingAddress'].get('addressLine1')?.touched)">
                    <p class="text-danger" *ngIf="pc['billingAddress'].get('addressLine1')?.errors?.['required']">
                      Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="adressLineTwo" formControlName="addressLine2">
                  <label for="adressLineTwo">Address Line 2</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="city" placeholder="City" formControlName="city"
                    [ngClass]="{'is-invalid': pc['billingAddress'].get('city')?.invalid && (pc['billingAddress'].get('city')?.dirty || pc['billingAddress'].get('city')?.touched)}">
                  <label for="city">City</label>
                  <div
                    *ngIf="pc['billingAddress'].get('city')?.invalid && (pc['billingAddress'].get('city')?.dirty || pc['billingAddress'].get('city')?.touched)">
                    <p class="text-danger" *ngIf="pc['billingAddress'].get('city')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="state" placeholder="State" formControlName="state"
                    [ngClass]="{'is-invalid': pc['billingAddress'].get('state')?.invalid && (pc['billingAddress'].get('state')?.dirty || pc['billingAddress'].get('state')?.touched)}">
                  <label for="state">State</label>
                  <div
                    *ngIf="pc['billingAddress'].get('state')?.invalid && (pc['billingAddress'].get('state')?.dirty || pc['billingAddress'].get('state')?.touched)">
                    <p class="text-danger" *ngIf="pc['billingAddress'].get('state')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="country" placeholder="Country" formControlName="country"
                    [ngClass]="{'is-invalid': pc['billingAddress'].get('country')?.invalid && (pc['billingAddress'].get('country')?.dirty || pc['billingAddress'].get('country')?.touched)}">
                  <label for="country">Country</label>
                  <div
                    *ngIf="pc['billingAddress'].get('country')?.invalid && (pc['billingAddress'].get('country')?.dirty || pc['billingAddress'].get('country')?.touched)">
                    <p class="text-danger" *ngIf="pc['billingAddress'].get('country')?.errors?.['required']">Required
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="zipCode" placeholder="Zip" formControlName="zipCode"
                    [ngClass]="{'is-invalid': pc['billingAddress'].get('zipCode')?.invalid && (pc['billingAddress'].get('zipCode')?.dirty || pc['billingAddress'].get('zipCode')?.touched)}">
                  <label for="zipCode">Zip</label>
                  <div
                    *ngIf="pc['billingAddress'].get('zipCode')?.invalid && (pc['billingAddress'].get('zipCode')?.dirty || pc['billingAddress'].get('zipCode')?.touched)">
                    <p class="text-danger" *ngIf="pc['billingAddress'].get('zipCode')?.errors?.['required']">Required
                    </p>
                    <p class="text-danger" *ngIf="pc['billingAddress'].get('zipCode')?.errors?.['pattern']">Invalid</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="my-2">
              Shipping Address
            </div>
            <div class="row" formGroupName="shippingAddress">
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="Title" placeholder="Title"
                    [ngClass]="{'is-invalid': pc['shippingAddress'].get('addressType')?.invalid && (pc['shippingAddress'].get('addressType')?.dirty || pc['shippingAddress'].get('addressType')?.touched)}"
                    formControlName="addressType">
                  <label for="Title">Title</label>
                  <div
                    *ngIf="pc['shippingAddress'].get('addressType')?.invalid && (pc['shippingAddress'].get('addressType')?.dirty || pc['shippingAddress'].get('addressType')?.touched)">
                    <p class="text-danger" *ngIf="pc['shippingAddress'].get('addressType')?.errors?.['required']">
                      Required
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input ngx-google-places-autocomplete #placesRef="ngx-places" type="text" class="form-control"
                    [ngClass]="{'is-invalid': pc['shippingAddress'].get('searchAddress')?.invalid && (pc['shippingAddress'].get('searchAddress')?.dirty || pc['shippingAddress'].get('searchAddress')?.touched)}"
                    id="searchAddress" placeholder="Search" formControlName="searchAddress"
                    (onAddressChange)="handleShippingAddressChange($event)">
                  <label for="searchAddress">Search Address</label>
                  <div
                    *ngIf="pc['shippingAddress'].get('searchAddress')?.invalid && (pc['shippingAddress'].get('searchAddress')?.dirty || pc['shippingAddress'].get('searchAddress')?.touched)">
                    <p class="text-danger" *ngIf="pc['shippingAddress'].get('searchAddress')?.errors?.['required']">
                      Required</p>
                    <p class="text-danger" *ngIf="pc['shippingAddress'].get('searchAddress')?.errors?.['address']">
                      Invalid</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="addressLine1" placeholder="Address Line 1"
                    formControlName="addressLine1"
                    [ngClass]="{'is-invalid': pc['shippingAddress'].get('addressLine1')?.invalid && (pc['shippingAddress'].get('addressLine1')?.dirty || pc['shippingAddress'].get('addressLine1')?.touched)}">
                  <label for="addressLine1"> Address Line 1</label>
                  <div
                    *ngIf="pc['shippingAddress'].get('addressLine1')?.invalid && (pc['shippingAddress'].get('addressLine1')?.dirty || pc['shippingAddress'].get('addressLine1')?.touched)">
                    <p class="text-danger" *ngIf="pc['shippingAddress'].get('addressLine1')?.errors?.['required']">
                      Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="adressLineTwo" formControlName="addressLine2">
                  <label for="adressLineTwo">Address Line 2</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="city" placeholder="City" formControlName="city"
                    [ngClass]="{'is-invalid': pc['shippingAddress'].get('city')?.invalid && (pc['shippingAddress'].get('city')?.dirty || pc['shippingAddress'].get('city')?.touched)}">
                  <label for="city">City</label>
                  <div
                    *ngIf="pc['shippingAddress'].get('city')?.invalid && (pc['shippingAddress'].get('city')?.dirty || pc['shippingAddress'].get('city')?.touched)">
                    <p class="text-danger" *ngIf="pc['shippingAddress'].get('city')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="state" placeholder="State" formControlName="state"
                    [ngClass]="{'is-invalid': pc['shippingAddress'].get('state')?.invalid && (pc['shippingAddress'].get('state')?.dirty || pc['shippingAddress'].get('state')?.touched)}">
                  <label for="state">State</label>
                  <div
                    *ngIf="pc['shippingAddress'].get('state')?.invalid && (pc['shippingAddress'].get('state')?.dirty || pc['shippingAddress'].get('state')?.touched)">
                    <p class="text-danger" *ngIf="pc['shippingAddress'].get('state')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="country" placeholder="Country" formControlName="country"
                    [ngClass]="{'is-invalid': pc['shippingAddress'].get('country')?.invalid && (pc['shippingAddress'].get('country')?.dirty || pc['shippingAddress'].get('country')?.touched)}">
                  <label for="country">Country</label>
                  <div
                    *ngIf="pc['shippingAddress'].get('country')?.invalid && (pc['shippingAddress'].get('country')?.dirty || pc['shippingAddress'].get('country')?.touched)">
                    <p class="text-danger" *ngIf="pc['shippingAddress'].get('country')?.errors?.['required']">Required
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="zipCode" placeholder="Zip" formControlName="zipCode"
                    [ngClass]="{'is-invalid': pc['shippingAddress'].get('zipCode')?.invalid && (pc['shippingAddress'].get('zipCode')?.dirty || pc['shippingAddress'].get('zipCode')?.touched)}">
                  <label for="zipCode">Zip</label>
                  <div
                    *ngIf="pc['shippingAddress'].get('zipCode')?.invalid && (pc['shippingAddress'].get('zipCode')?.dirty || pc['shippingAddress'].get('zipCode')?.touched)">
                    <p class="text-danger" *ngIf="pc['shippingAddress'].get('zipCode')?.errors?.['required']">Required
                    </p>
                    <p class="text-danger" *ngIf="pc['shippingAddress'].get('zipCode')?.errors?.['pattern']">Invalid</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async"
              [message]="'Onboarding Plant Saved'"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="(this._showErrorMsg$ | async)">
            </app-message-toast>
          </div>
          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>
          <div class="col-12 text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class c-pointer" (click)="saveOnboardingPlants()">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
