<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>STATUS VALIDATION</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
 <div class="model-body-top">
  <div #termsHeader></div>
  <div class="m-3">
    <h6>Only the Selected and Complete Event will go LIVE.</h6>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Event Name</th>
          <th scope="col">
            <input type="checkbox" id="ctrlAllSelect" [formControl]="ctrlAllSelect">
          </th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of subcategoryIdsList; index as i">
          <td>{{getSubcategoryById(item)?.subcategorySequence}}</td>
          <td>{{getSubcategoryById(item)?.title}}</td>
          <td>
            <input type="checkbox" id="isSubcategorySelected" (change)="selectSubcategoryForActive(item)" [checked]="isSubcategorySelected(item)"
              [disabled]="haveAnyError(item)" />
          </td>
          <td class="font-weight-bold" [ngClass]="haveAnyError(item) ? 'text-warning' : 'text-success'">
            {{haveAnyError(item) ? 'Incomplete' : 'Complete'}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- <div class="my-3">
          <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="Auction Discard Successfully"></app-message-toast>
        </div> -->

  <!-- <div class="mx-3 mb-3 text-end" *ngIf="isLoading">
    <div class="e-spinner"></div>
  </div>
 -->
  <div class="mx-3 mb-3 text-end">
    <button type="button" class="btn btn-new-class" (click)="onButtonClick()" ngbAutoFocus>OK</button>
  </div>
 </div>
</div>