import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { InvoiceRequestDto } from '../../models/InvoiceRequestDto';
import { Observable } from 'rxjs';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { AuctionHouseDto } from '../../models/user/AuctionHouseDto';
import { AwardedBidderDto, AwardedLot } from '../../models/AwardedBidderDto';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { Currency } from '../../models/Currency';
import { CountryCodeDto } from '../../models/CountryCodeDto';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { OrganizationUiDto } from '../../models/OrganizationUiDto';
import { OnboardingService } from '../../services/onboarding.service';

@Component({
  selector: 'app-invoice-preview',
  templateUrl: './invoice-preview.component.html',
  styleUrls: ['./invoice-preview.component.sass']
})
export class InvoicePreviewComponent implements OnInit {
  @Input() awardedBidder!: AwardedBidderDto
  @Input() invoiceRequestDto$ = new Observable<InvoiceRequestDto|null>()
  @Input() selectedAuction!: AuctionEntityDto

  @ViewChild('invoice') invoiceRef?: ElementRef<HTMLElement>

  organizationUiDto?: OrganizationUiDto
  invoiceRequest?: InvoiceRequestDto

  currentDate: string = new Date().toDateString()
  totalPrice: number = 0

  awardedLots: AwardedLot[] = [];
  currency: Currency = Currency.default()

  constructor(
    private onboardingService: OnboardingService,
    private adminService: AdminDashboardService
  ) {}

  ngOnInit(): void {
    this.onboardingService.getOrganizationUiDto$.subscribe((data) => {
      if (data) {
        this.organizationUiDto = data;
      }
    })

    this.invoiceRequestDto$.subscribe(invoiceRequest => {
      if (invoiceRequest) {
        this.invoiceRequest = invoiceRequest;
        this.awardedLots = [];
        this.awardedBidder?.awardedLots?.forEach(item => {
          if (invoiceRequest.lotIds?.includes(item.lotId!)) {
            this.awardedLots.push(item);
          }
        })

        this.awardedLots!.sort((a, b) => Number(a.lotSequenceNo!) - Number(b.lotSequenceNo!))

        this.totalPrice = this.awardedLots.map(item => item.bidPrice!).reduce((prev, next) => prev + next);
        this.currency = this.awardedLots[0].currency!;
      }
    })
  }

  formattedPrice(price?: any) {
    return ApplicationUtils.getFormattedPrice(this.currency?.locale, price);
  }

  formattedMobileNo(mobileNo: any, countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '') + mobileNo;
    }
    return '+1' + mobileNo;
  }

  getCompanyFileId(){
    if(this.organizationUiDto&& this.organizationUiDto?.logo){
      return this.organizationUiDto?.logo?.fileId;
    }
    return "";
    
  }
}
