<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Admin User Activity</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <div class="container my-4">
        <div class="col-12 text-center mb-3">
          <!-- AUCTION -->
          <button class="btn  btn-sm me-3 rounded py-1 button-width-change-activiy py-2  mb-2 zoom shadow"
            [ngClass]="this.currentViewTabName == 'TODAY' ? 'selected-tiles' : 'btn-outline-secondary'"
            (click)="toggleCurrentViewTab('TODAY')">

              <div class=" col-12 text-center ">
                <span class=" position-relative"
                  [ngClass]="{'text-white': this.currentViewTabName == 'TODAY'}">
                  <b>Today</b></span>
            </div>
          </button>
          <button class="btn  btn-sm me-3 rounded py-1 button-width-change-activiy py-2  mb-2 zoom shadow"
            [ngClass]="this.currentViewTabName == 'LAST_WEEK' ? 'selected-tiles' : 'btn-outline-secondary'"
            (click)="toggleCurrentViewTab('LAST_WEEK')">

              <div class=" col-12 text-center ">
                <span class=" position-relative"
                  [ngClass]="{'text-white': this.currentViewTabName == 'LAST_WEEK'}">
                  <b>Last 7 Days</b> </span>
            </div>
          </button>
          <button class="btn  btn-sm me-3 rounded py-1 button-width-change-activiy py-2  mb-2 zoom shadow"
            [ngClass]="this.currentViewTabName == 'LAST_MONTH' ? 'selected-tiles' : 'btn-outline-secondary'"
            (click)="toggleCurrentViewTab('LAST_MONTH')">

              <div class=" col-12 text-center ">
                <span class=" position-relative"
                  [ngClass]="{'text-white': this.currentViewTabName == 'LAST_MONTH'}">
                  <b>Last 31 Days</b>
                </span>
            </div>
          </button>

        </div>

        <div class="row">
          <div class="col-md-12 offset-md-1">
            <h4>Latest Activities</h4>
            <div class="my-3 me-5" *ngIf="isLoading">
              <div class="e-spinner"></div>
            </div>
            <ul class="timeline" >
              <li class="c-default" *ngFor="let item of userActivityList;" [ngClass]="{'blue-color' : item?.actionType == 'LOGGED_IN', 'red-color': item?.actionType == 'LOGGED_OUT','yellow-color': item?.actionType != 'LOGGED_OUT' && item?.actionType != 'LOGGED_IN' }">
                <h6 class="heading-h6 mb-0 text-info text-capitalize"
                [ngClass]="{'text-info' : item?.actionType == 'LOGGED_IN', 'text-danger': item?.actionType == 'LOGGED_OUT','text-dark': item?.actionType != 'LOGGED_OUT' && item?.actionType != 'LOGGED_IN' }">
                  {{item?.actionLabel}} <span *ngIf="isAllowToShowBadge(item?.actionType!)" class="badge" [ngClass]="getBadgeClass(item?.actionType!)">{{getBadgeValue(item?.actionType!)}}</span>
                </h6>
                <div *ngIf="item?.subActionLabel">
                  <span class="span-class" >
                    {{item?.subActionLabel}}
                  </span>
                </div>
                <div>
                  <small>
                    <img src="assets/icons/time_light.svg" class="svg-icon-class-Xsmall me-1 position-relative" style="margin-bottom: 2px;">
                    {{getCreatedDate(item?.updateTimeUtc!)}}</small>
                </div>
              </li>

              <!-- <li class="yellow-color">
                <h6 class="heading-h6 mb-0 text-info text-capitalize" >Auction Created </h6>
                <div>
                  <span class="span-class">First Forward Auction</span>
                </div>
                <div>
                  <small>
                    <img src="assets/icons/time_light.svg" class="svg-icon-class-Xsmall me-1 position-relative" style="margin-bottom: 2px;">
                    21 Feb, 2024 10AM</small>
                </div>
              </li> -->
              <!-- <li class="yellow-color">
                <h6 class="heading-h6 mb-0 text-info text-capitalize" >Mark Auction <span class="badge bg-success ms-2 p-1">Active</span></h6>
                <div>
                  <span class="span-class">First Forward Auction</span>
                </div>
                <div>
                  <small>
                    <img src="assets/icons/time_light.svg" class="svg-icon-class-Xsmall me-1 position-relative" style="margin-bottom: 2px;">
                    21 Feb, 2024 10AM</small>
                </div>
              </li>

              <li class="yellow-color">
                <h6 class="heading-h6 mb-0 text-info text-capitalize" >Registration  <span class="badge bg-success ms-2 p-1">Approved</span></h6>
                <div>
                  <span class="span-class">Amtag</span>
                </div>
                <div>
                  <small>
                    <img src="assets/icons/time_light.svg" class="svg-icon-class-Xsmall me-1 position-relative" style="margin-bottom: 2px;">
                    21 Feb, 2024 10AM</small>
                </div>
              </li>
              <li class="yellow-color">
                <h6 class="heading-h6 mb-0 text-info text-capitalize">bid  <span class="badge bg-danger">Rejected</span></h6>
                <div>
                  <span class="span-class">Amtag</span>
                </div>
                <div>
                  <small>
                    <img src="assets/icons/time_light.svg" class="svg-icon-class-Xsmall me-1 position-relative" style="margin-bottom: 2px;">
                    21 Feb, 2024 10AM</small>
                </div>
              </li>
              <li class="yellow-color">
                <h6 class="heading-h6 mb-0 text-info text-capitalize">Admin Created</h6>
                <div>
                  <span class="span-class">Nehaadmin</span>
                </div>
                <div>
                  <small>
                    <img src="assets/icons/time_light.svg" class="svg-icon-class-Xsmall me-1 position-relative" style="margin-bottom: 2px;">
                    21 Feb, 2024 10AM</small>
                </div>
              </li>
              <li class="yellow-color">
                <h6 class="heading-h6 mb-0 text-info text-capitalize">Auctioneer Created</h6>
                <div>
                  <span class="span-class">NewAuctioneer</span>
                </div>
                <div>
                  <small>
                    <img src="assets/icons/time_light.svg" class="svg-icon-class-Xsmall me-1 position-relative" style="margin-bottom: 2px;">
                    21 Feb, 2024 10AM</small>
                </div>
              </li>
              <li  class="red-color">
                <div class="text-danger">
                  <h6 class="heading-h6 mb-0 text-danger text-capitalize">Logout</h6>
                </div>
                <div>
                  <small>
                    <img src="assets/icons/time_light.svg" class="svg-icon-class-Xsmall me-1 position-relative" style="margin-bottom: 2px;">
                    21 Feb, 2024 10AM</small>
                </div>
              </li> -->
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
