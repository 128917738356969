<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Select Category</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <div class="d-grid gap-2 mb-3" *ngIf="loadingData">
        <div class="e-spinner"></div>
      </div>

      <p class="mb-2">
        Please choose the categories for which you'd like to receive email notifications when auctions within those
        categories commence
      </p>

      <app-categories-tree-view [selectionMode]="TreeSelectionMode.single"
        (onSelectedCategory)="onCategorySelectedChange($event)"></app-categories-tree-view>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" [message]="'Preference categories saved successfully.'"></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="col-12 text-end my-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="btn btn-new-class  btn-sm me-3" (click)="closeModal()">SKIP</button>
        <button type="button" class="btn btn-new-class btn-sm" *ngIf="!isGlobalBidder" (click)="saveCategories()">SAVE</button>
        <button type="button" class="btn btn-new-class btn-sm" *ngIf="isGlobalBidder" (click)="emitSelectedCategories()">SAVE</button>
      </div>
    </div>
  </div>
</div>
