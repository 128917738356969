import { Component, Input, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, lastValueFrom, Subscription } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { AdminUserPrivilege } from 'src/app/shared/enums/AdminUserPrivilege';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';

import { FileService } from 'src/app/shared/services/file.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Pattern } from 'src/app/shared/util/Patterns';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AddressDto } from 'src/app/shared/models/Address';
import { UUID } from 'angular2-uuid';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MobileWrapper } from 'src/app/shared/models/user/MobileWrapper';
import { EmailWrapper } from 'src/app/shared/models/user/EmailWrapper';
import { VerificationWorkflowWrapper } from 'src/app/shared/models/VerificationWorkflowWrapper';
import { CategoryDto } from 'src/app/shared/models/CategoryDto';
import { LandingService } from 'src/app/shared/services/LandingAndBidder.service';
import { PreferenceCategoryDto } from 'src/app/shared/models/user/PreferenceCategoryDto';

import { UserEntityInfoDto } from 'src/app/shared/models/user/UserEntityInfoDto';
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.sass']
})
export class UserDetailsComponent implements OnInit, OnDestroy {

  @Input() userId?: string;

  ctrlMobileNo: FormControl = new FormControl('', [Validators.required, Validators.pattern(Pattern.mobile)]);
  ctrlEmail: FormControl = new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]);
  ctrlOtp: FormControl = new FormControl('', Validators.required);

  preferenceCategories: PreferenceCategoryDto[] = []
  userDto?: UserEntityInfoDto | UserEntityInfoDto;
  formGroup: FormGroup;
  profileImage?: FileInfoDto;
  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  fileUploadError: boolean = false;
  errorMsg: string | undefined;
  currentFile?: File;
  isLoading: boolean = false;
  isUserProfileLoading: boolean = false;
  isSubmitted: boolean = false;

  otpId?: string;
  selectedWrapper?: MobileWrapper | EmailWrapper;
  selectedWrapperType?: string;
  isProfileEditMode: boolean = false;

  selectedCountryCode = CountryCodeDto.default();
  selectedFaxNoCountryCode = CountryCodeDto.default();
  latitude?: number;
  longitude?: number;
  countryShortName?: string

  currentFileInfoDto?: FileInfoDto
  imageUrl?: string | ArrayBuffer | SafeUrl | null;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToastProfile$ = new BehaviorSubject<boolean>(false);
  _showErrorToastProfile$ = new BehaviorSubject<boolean>(false);
  countryCode$ = new BehaviorSubject<CountryCodeDto | null>(null);
  countryCodeForFaxNo$ = new BehaviorSubject<CountryCodeDto | null>(null);

  userEntitySubscription$?: Subscription;
  userByUserIdSubscription$?: Subscription;

  addEmailModalRef?: NgbModalRef
  addMobileModalRef?: NgbModalRef
  deleteModalRef?: NgbModalRef

  zoom = 12;
  infoContent = ''
  position?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  marker = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }

  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private fileService: FileService,
    private landingService: LandingService
  ) {
    this.formGroup = this.formBuilder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      designation: new FormControl(''),
      companyCode: new FormControl('', Validators.required),
      companyUrl: new FormControl(''),

      // For Admin
      primaryEmailId: new FormControl({ value: '', disabled: true }),
    })
  }

  ngOnInit(): void {
    if (this.userId == null) {
      this.userEntitySubscription$ = this.userService.getUserEntity$.subscribe((data) => {
        if (data) {
          this.userDto = data as UserEntityInfoDto;
          this.populateUserDetails(this.userDto);
        }
      })
    } else {
      this.userByUserIdSubscription$ = this.userService.getUserByUserId(this.userId).subscribe(data => {
        if (data) {
          this.userDto = data.data as UserEntityInfoDto;
          this.populateUserDetails(this.userDto);
        }
      })
    }
  }

  get fc(): any { return this.formGroup.controls; }

  populateUserDetails(userDto: UserEntityInfoDto) {
    this.formGroup.controls['primaryEmailId'].patchValue(userDto.primaryEmailId);
    this.formGroup.controls['firstName'].patchValue(userDto.firstName);
    this.formGroup.controls['lastName'].patchValue(userDto.lastName);
    this.formGroup.controls['designation'].patchValue(userDto.designation);
    this.formGroup.controls['companyCode'].patchValue(userDto.companyCode);
    this.formGroup.controls['companyUrl'].patchValue(userDto.companyUrl);

  
    this.profileImage = this.userDto?.profileImage;


    if (this.profileImage) {
      this.loadImage();
    }

    if (this.userId) {
      this.formGroup.disable()
    }
  }

  loadImage() {
    this.imageUrl = `/downloadLandingBlob?fileId=${this.profileImage?.fileId}`;
  }

  onEditProfileMode() {
    this.isProfileEditMode = true;
  }

  toggleEditProfileMode() {
    this.isProfileEditMode = false;
  }

  chooseFile(event: any) {
    this._showErrorToastProfile$.next(false);
    this.errorMsg = "";
    this.currentFile = event.target.files[0];

    if (!this.imageTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      this._showErrorToastProfile$.next(true);
      this.currentFile = undefined;
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(this.currentFile!);
    reader.onload = () => {
      this.imageUrl = reader.result?.toString();
    }
  }

  async uploadFile() {
    this.isUserProfileLoading = true;
    this.fileUploadError = false;
    this.errorMsg = "";

    let metaData = {
      'mimeType': this.currentFile!.type,
      'version': 0,
      'publicApi': true,
      'dataType': 'Square'
    };

    let formData = new FormData();
    formData.append("file", this.currentFile!);
    formData.append('metaData', JSON.stringify(metaData));

    let apiResponseDto = await lastValueFrom(this.fileService.uploadFile(formData));
    if (apiResponseDto) {
      let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;
      if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.fileUploadError = false;
        this.currentFileInfoDto = new FileInfoDto();
        this.currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
        this.currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
        this.currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
        this.currentFileInfoDto.fileSize = this.currentFile!.size.toString();
        this.currentFileInfoDto.fileType = this.currentFile!.type;
        this.currentFileInfoDto.uploadDate = new Date().toDateString();
      }
    } else {
      this.isUserProfileLoading = false;
      this.fileUploadError = true;
    }
  }


  populateDetails() {
    let formValue = this.formGroup.value;
    this.userDto!.firstName = formValue.firstName;
    this.userDto!.lastName = formValue.lastName;
    this.userDto!.companyUrl = formValue.companyUrl;
    this.userDto!.companyCode = formValue.companyCode;
    this.userDto!.designation = formValue.designation;
  }

  async handleValidSubmit() {
    this._showErrorToastProfile$.next(false);
    this.errorMsg = "";

    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    this.isUserProfileLoading = true;

    this.populateDetails();

    if (this.currentFile) {
      await this.uploadFile();
    }

    if (this.currentFileInfoDto != null) {
      this.userDto!.profileImage = this.currentFileInfoDto;
    }

    this.userService.updateUserDetails(this.userDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as UserEntityInfoDto;

          if (!this.userId) {
            this.userService.setUserDto(data);
          }

          this.isUserProfileLoading = false;
          this._showSuccessToastProfile$.next(true);
          this.errorMsg = "Details Updated Successfully";
          setTimeout(() => {
            this._showSuccessToastProfile$.next(false);
            this.closeUserDetailsModal();
          }, 2000)
        } else {
          this.isUserProfileLoading = false;
          this.errorMsg = apiResponseDto.message;
          this._showErrorToastProfile$.next(true);

        }
      },
      error: (err) => {
        this.isUserProfileLoading = false;
        this.errorMsg = "Error while updating details";
        this._showErrorToastProfile$.next(true);
      }
    })
  }

  handleCountryCodeEvent(countryCodeDto: CountryCodeDto) {
    this.selectedCountryCode = countryCodeDto;

    let mobileNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + mobileNoLength + "}"

    this.formGroup.controls['mobileNo'].clearValidators();
    this.formGroup.controls['mobileNo'].setValidators([Validators.required, Validators.pattern(pattern)]);
    this.formGroup.controls['mobileNo'].updateValueAndValidity();
  }


  closeUserDetailsModal() {
    this.userDto = null!;
    this.currentFile = null!;
    this.currentFileInfoDto = null!;
    this.imageUrl = null;
    this.profileImage = null!;
    this.modalService.dismissAll();
  }

  closeModal(modalRef?: NgbModalRef) {
    modalRef?.close();

    this.selectedCountryCode = CountryCodeDto.default();
    this.countryCode$.next(this.selectedCountryCode);

    this.ctrlEmail.reset();
    this.ctrlMobileNo.reset();
    this.ctrlOtp.reset();
    this.otpId = undefined;
    this.isLoading = false;
  }

  openContactModal(content: any) {
    this.ctrlMobileNo.reset();
    this.errorMsg = '';
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    this.selectedCountryCode = CountryCodeDto.default();
    this.countryCode$.next(this.selectedCountryCode);

    this.addMobileModalRef = this.modalService.open(content, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });
  }

  openEmailModal(content: any) {
    this.ctrlEmail.reset();
    this.ctrlOtp.reset();
    this.errorMsg = '';
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    this.addEmailModalRef = this.modalService.open(content, {
      centered: true,
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
    });
  }

  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker)
  }

  getCountryCode(countryCode: CountryCodeDto) {
    return ApplicationUtils.getCountryCode(countryCode);
  }

  addContactNo() {
    this.errorMsg = '';
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    if (this.ctrlMobileNo.invalid || this.ctrlMobileNo.value.trim() == '') {
      this.ctrlMobileNo.markAsTouched();
      return;
    }

    let mobileNo = this.ctrlMobileNo.value.trim();
    let isMobileNumberExists = this.userDto?.mobileList?.find(item => item.mobileNo == mobileNo && item.countryCode?.countryCode == this.selectedCountryCode.countryCode)

    if (isMobileNumberExists) {
      this._showErrorToast$.next(true);
      this.errorMsg = 'Mobile number already exists!';
      return;
    }

    let verificationWorkflowWrapper = new VerificationWorkflowWrapper();
    verificationWorkflowWrapper.newMobileNo = mobileNo;
    verificationWorkflowWrapper.countryCodeDto = this.selectedCountryCode;
    verificationWorkflowWrapper.workflowName = 'MOBILE';

    this.verifyOTPEmailMobileVerificationWorkflow(verificationWorkflowWrapper)
  }

  addEmailId() {
    this.errorMsg = '';
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    if (this.ctrlEmail.invalid || this.ctrlEmail.value.trim() == '') {
      this.ctrlEmail.markAsTouched();
      return;
    }

    let emailId = this.ctrlEmail.value.trim();
    let isEmailIdExists = this.userDto?.emailList?.find(item => item.email == emailId);

    if (isEmailIdExists) {
      this._showErrorToast$.next(true);
      this.errorMsg = 'Email id already exists!';
      return;
    }

    this.generateOtpForEmail();
  }

  verifyEmailId() {
    this.errorMsg = '';
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    if (this.ctrlOtp.invalid || this.ctrlOtp.value.trim() == '') {
      this.ctrlOtp.markAsTouched();
      return;
    }

    let verificationWorkflowWrapper = new VerificationWorkflowWrapper();
    verificationWorkflowWrapper.newEmailId = this.ctrlEmail.value.trim();
    verificationWorkflowWrapper.workflowName = 'EMAIL';
    verificationWorkflowWrapper.otpId = this.otpId;
    verificationWorkflowWrapper.userEnteredOTP = this.ctrlOtp.value.trim();

    this.verifyOTPEmailMobileVerificationWorkflow(verificationWorkflowWrapper);
  }

  cancelEmailVerify() {
    this.otpId = undefined;
    this.errorMsg = '';
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this.ctrlOtp.reset();
  }

  openDeleteConfirmModal(content: TemplateRef<any>, wrapper: MobileWrapper | EmailWrapper, type: string) {
    this.errorMsg = '';
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    this.selectedWrapperType = type;
    this.selectedWrapper = wrapper;

    this.deleteModalRef = this.modalService.open(content, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });
  }

  deleteEmailOrMobile() {
    let userDto: UserEntityInfoDto = ApplicationUtils.clone(this.userDto);

    if (this.selectedWrapperType == 'EMAIL') {
      let index = userDto.emailList!.findIndex(item => item.email == (this.selectedWrapper as EmailWrapper).email);
      if (index > -1) {
        userDto.emailList!.splice(index, 1);
      }
    } else {
      let index = userDto.mobileList!.findIndex(item => item.mobileNo == (this.selectedWrapper as MobileWrapper).mobileNo
        && item.countryCode?.countryCode == (this.selectedWrapper as MobileWrapper).countryCode?.countryCode);
      if (index > -1) {
        userDto.mobileList!.splice(index, 1);
      }
    }

    this.isLoading = true;

    this.userService.updateUserDetails(userDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as UserEntityInfoDto;

          if (!this.userId) {
            this.userService.setUserDto(data);
          }

          this.isLoading = false;
          this._showSuccessToast$.next(true);

          setTimeout(() => {
            this.otpId = undefined;
            this._showSuccessToast$.next(false);
            this.deleteModalRef?.close();
          }, 2000)
        } else {
          this.isLoading = false;
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.errorMsg = "Error while updating details";
        this._showErrorToast$.next(true);
      }
    })
  }

  generateOtpForEmail() {
    this.isLoading = true;

    let verificationWorkflowWrapper = new VerificationWorkflowWrapper();
    verificationWorkflowWrapper.newEmailId = this.ctrlEmail.value.trim();
    verificationWorkflowWrapper.workflowName = 'EMAIL';

    this.userService.generateOTPEmailMobileVerificationWorkflow(verificationWorkflowWrapper).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as VerificationWorkflowWrapper;
          this.otpId = data.otpId;
          this.isLoading = false;
        } else {
          this.errorMsg = apiResponseDto.message!;
          this.isLoading = false;
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.errorMsg = "Error While OTP Generate";
      }
    })
  }

  verifyOTPEmailMobileVerificationWorkflow(verificationWorkflowWrapper: VerificationWorkflowWrapper) {
    this.isLoading = true;

    this.userService.verifyOTPEmailMobileVerificationWorkflow(verificationWorkflowWrapper).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as UserEntityInfoDto;

          if (!this.userId) {
            this.userService.setUserDto(data);
          }

          this.isLoading = false;
          this._showSuccessToast$.next(true);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            if (verificationWorkflowWrapper.workflowName == 'EMAIL') {
              this.addEmailModalRef?.close();
            } else {
              this.addMobileModalRef?.close();
            }
          }, 2000)
        } else {
          this.isLoading = false;
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.errorMsg = "Error while updating details";
        this._showErrorToast$.next(true);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.userEntitySubscription$) {
      this.userEntitySubscription$.unsubscribe()
    }
    if (this.userByUserIdSubscription$) {
      this.userByUserIdSubscription$.unsubscribe()
    }
  }

}
