import { UserRegistrationStatus } from "../enums/UserRegistrationStatus";
import { AddressDto } from "./Address";
import { CountryCodeDto } from "./CountryCodeDto";
import { FileInfoDto } from "./FileInfoDto";
import { EmailWrapper } from "./user/EmailWrapper";
import { MobileWrapper } from "./user/MobileWrapper";
import { PreferenceCategoryDto } from "./user/PreferenceCategoryDto";

export class BidderDto {
  emailList?: EmailWrapper[];
  mobileList?: MobileWrapper[];
  address?: AddressDto

  companyName?: string;
  companyUrl?: string;
  designation?: string;
  primaryEmailId?: string;
  bidderType?: string

  userId?: string;
  password?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  profileImage?: FileInfoDto;
  signatureName?: string;
  userPrivileges?: string[];
  faxNo?: string;
  faxNoCountryCode?: CountryCodeDto;

  preferenceCategories?: PreferenceCategoryDto[]
  category?: string

  registrationTime? : string;
  registrationStatus?: UserRegistrationStatus;
  active: boolean = true;
  notificationActive: boolean = true;
  forcePasswordChange?: boolean;
  version?: number;
  registrationAuctionHouseId?: string;
}
