import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileInfoDto } from '../../models/FileInfoDto';
import { FileService } from '../../services/file.service';
import { SafeUrl } from '@angular/platform-browser';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { FileSaveResponseDto } from '../../models/FileSaveResponseDto';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { CatalogueL1EntityDto } from '../../models/CatalogueL1EntityDto';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { CatalogueL2EntityDto } from '../../models/CatalogueL2EntityDto';
import heic2any from 'src/assets/plugins/heic2any/dist/heic2any';
import { CatalogueLineItemsEntityDto } from '../../models/CatalogueLineItemsEntityDto';
import { DashboardRedirectionService } from '../../services/dashboard-redirection.service';
import { CatalogueService } from '../../services/catalogue.service';

@Component({
  selector: 'app-catlogue',
  templateUrl: './catlogue.component.html',
  styleUrls: ['./catlogue.component.sass']
})
export class CatlogueComponent implements OnInit {
  isHover: boolean = false;


  currentFileInfoDto?: FileInfoDto;
  currentFile?: File | null;

  fileUploadError: boolean = false;
  imageUrl?: string | ArrayBuffer | SafeUrl | null;
  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  imageType?: string;
  _fileDisplayName$ = new BehaviorSubject<string | null>('');
  _fileUploaded$ = new BehaviorSubject<boolean>(false);
  isLoading$ = new BehaviorSubject<boolean>(false);
  _fileSize$ = new BehaviorSubject<string | null>("");

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');

  isSubmitted: boolean = false;
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);

  ctrlCategoryL1Name: FormControl = new FormControl('');
  ctrlCategoryL2Name: FormControl = new FormControl('');
  ctrlCategoryLineItemsName: FormControl = new FormControl('');

  catalogueL1List?: CatalogueL1EntityDto[] = [];
  filterCatalogueL1List$ = new BehaviorSubject<CatalogueL1EntityDto[]>([]);
  selectedCatalogueL1?: CatalogueL1EntityDto;

  catalogueL2List?: CatalogueL2EntityDto[] = [];
  filterCatalogueL2List$ = new BehaviorSubject<CatalogueL2EntityDto[]>([]);
  selectedCatalogueL2?: CatalogueL2EntityDto;

  catalogueLineItemsList?: CatalogueLineItemsEntityDto[] = [];
  filterCatalogueLineItemsList$ = new BehaviorSubject<CatalogueLineItemsEntityDto[]>([]);
  selectedCatalogueLineItems?: CatalogueLineItemsEntityDto;

  ctrlSearchCatalogue: FormControl = new FormControl('');
  isLoadingList$ = new BehaviorSubject<boolean>(false);

  selectedPageName?: string = 'CATALOGUE_L1';

  @ViewChild('imageRef') imageRef?: HTMLInputElement;
  updateBreadcrumbsSubscription$?: Subscription;

  constructor(
    private dataRedirectionService: DashboardRedirectionService,
    private adminDashboardService: AdminDashboardService,
    private catalogueService: CatalogueService,
    private formBuilder: FormBuilder,
    private fileService: FileService,
    private ngbModal: NgbModal,) {

  }


  ngOnInit(): void {
    this.loadCatalogueL1List();
    this.ctrlSearchCatalogue.valueChanges.subscribe((value) => {
      if (this.selectedPageName == "CATALOGUE_L1") {
        this.searchCatalogueL1(value)
      } else {
        this.searchCatalogueL2(value)
      }

    });

    this.updateBreadcrumbsSubscription$ = this.dataRedirectionService.getUpdateBreadcrumbs$.subscribe(data => {
      if (data) {
        let pageName = sessionStorage.getItem('PAGE_NAME')!;

        if (pageName == ApplicationConstants.ADMIN_CATALOGUE_PAGE) {
          let CATALOGUE_L1 = sessionStorage.getItem('CATALOGUE_L1');
          if (!CATALOGUE_L1) {
            this.navigateToCatalogueL1();
          }

        }
      }
    })
  }

  openAddCatalogueModal2(content: any) {

    this.clearL2();
    this.ngbModal.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }
  openAddCatalogueModal1(content: any) {
    this.clearL1()
    this.ngbModal.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }




  // LOAD DATA
  loadCatalogueL1List() {
    this.isLoadingList$.next(true);
    this.adminDashboardService._getCatalogueL1List().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let catalogueL1List = apiResponseDto.data as CatalogueL1EntityDto[];
          this.catalogueL1List = catalogueL1List;
          this.searchCatalogueL1("");
          this.isLoadingList$.next(false);
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoadingList$.next(false);
      }
    })
  }


  // LOAD DATA
  async loadCatalogueL2List(categoryL1Id: string) {
    this.isLoadingList$.next(true);
    await this.adminDashboardService._getCatalogueL2List(categoryL1Id!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let catalogueL2List = apiResponseDto.data as CatalogueL2EntityDto[];
          this.catalogueL2List = catalogueL2List;
          this.searchCatalogueL2("");
          this.isLoadingList$.next(false);
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoadingList$.next(false);
      }
    })
  }


  // LOAD DATA
  async loadCatalogueLineItemsList(categoryL1Id: string, categoryL2Id: string) {
    this.isLoadingList$.next(true);
    await this.adminDashboardService.getCatalogueLineItemsList(categoryL1Id, categoryL2Id).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let catalogueLineItemsList = apiResponseDto.data as CatalogueL2EntityDto[];
          this.catalogueLineItemsList = catalogueLineItemsList;
          this.searchCatalogueLineItems("");
          this.isLoadingList$.next(false);
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoadingList$.next(false);
      }
    })
  }



  async navigateToCatalogueL2(categoryL1: CatalogueL1EntityDto) {
    this.selectedCatalogueL1 = categoryL1;
    this.catalogueService.updateSelectedCatalogueL1EntityDto(categoryL1);

    this.selectedPageName = "CATALOGUE_L2";
    await this.loadCatalogueL2List(categoryL1.categoryL1Id!);
    sessionStorage.setItem("CATALOGUE_L1", categoryL1.name!);
    this.dataRedirectionService.setUpdateBreadcrumbs(true);
  }

  async navigateToCatalogueL1() {
    this.selectedPageName = "CATALOGUE_L1";
  }

  navigateToLineCatalogue(catalogueL2EntityDto: CatalogueL2EntityDto) {
    this.selectedCatalogueL2 = catalogueL2EntityDto;
    this.catalogueService.updateSelectedCatalogueL2EntityDto(catalogueL2EntityDto);

    this.dataRedirectionService.storeNavigationContext(
      ApplicationConstants.SUPERADMIN,
      ApplicationConstants.ADMIN_LINE_CATALOGUE_PAGE
    );
    this.dataRedirectionService.setUpdateBreadcrumbs(true);
  }

  searchCatalogueL1(value: string) {
    let tempList = ApplicationUtils.clone(this.catalogueL1List) as CatalogueL1EntityDto[];
    if (value == "") {
      this.filterCatalogueL1List$.next(tempList)
    } else {
      tempList = tempList.filter(item => item.name?.trim().toLowerCase().includes(value.trim().toLowerCase()));
      this.filterCatalogueL1List$?.next(tempList);
    }
  }

  searchCatalogueL2(value: string) {
    let tempList = ApplicationUtils.clone(this.catalogueL2List) as CatalogueL2EntityDto[];
    if (value == "") {
      this.filterCatalogueL2List$.next(tempList)
    } else {
      tempList = tempList.filter(item => item.name?.trim().toLowerCase().includes(value.trim().toLowerCase()));
      this.filterCatalogueL2List$?.next(tempList);
    }
  }

  searchCatalogueLineItems(value: string) {
    let tempList = ApplicationUtils.clone(this.catalogueLineItemsList) as CatalogueL2EntityDto[];
    if (value == "") {
      this.filterCatalogueLineItemsList$.next(tempList)
    } else {
      tempList = tempList.filter(item => item.name?.trim().toLowerCase().includes(value.trim().toLowerCase()));
      this.filterCatalogueLineItemsList$?.next(tempList);
    }
  }

  selectCatalogueL1(categoryL1: CatalogueL1EntityDto, content: any, event: any) {
    this._showErrorToast$.next(false);

    if (event) {
      event.stopPropagation();
    }

    this.clearL1()
    this.selectedCatalogueL1 = categoryL1;
    this.populateL1InitialDetails()

    this.ngbModal.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  selectCatalogueL2(categoryL2: CatalogueL2EntityDto, content: any, event: any) {
    this._showErrorToast$.next(false);
    
    if (event) {
      event.stopPropagation();
    }

    this.clearL2()
    this.selectedCatalogueL2 = categoryL2;
    this.populateL2InitialDetails()

    this.ngbModal.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  selectCatalogueLineItems(categoryLineItems: CatalogueLineItemsEntityDto, content: any) {
    this.clearL1()
    this.selectedCatalogueLineItems = categoryLineItems;
    this.populateLineItemsInitialDetails()

    this.ngbModal.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  populateL1InitialDetails() {
    this.ctrlCategoryL1Name.setValue(this.selectedCatalogueL1?.name);

    if (this.selectedCatalogueL1?.fileInfoDto) {
      this.currentFileInfoDto = this.selectedCatalogueL1?.fileInfoDto;
      this._fileDisplayName$.next(this.selectedCatalogueL1?.fileInfoDto?.displayName!)
      this._fileSize$.next(this.selectedCatalogueL1?.fileInfoDto!.fileSize!)
    }
  }

  populateL2InitialDetails() {
    this.ctrlCategoryL2Name.setValue(this.selectedCatalogueL2?.name);

    if (this.selectedCatalogueL2?.fileInfoDto) {
      this.currentFileInfoDto = this.selectedCatalogueL2?.fileInfoDto;
      this._fileDisplayName$.next(this.selectedCatalogueL2?.fileInfoDto?.displayName!)
      this._fileSize$.next(this.selectedCatalogueL2?.fileInfoDto!.fileSize!)
    }
  }

  populateLineItemsInitialDetails() {
    this.ctrlCategoryL1Name.setValue(this.selectedCatalogueL1?.name);

    if (this.selectedCatalogueL1?.fileInfoDto) {
      this.currentFileInfoDto = this.selectedCatalogueL1?.fileInfoDto;
      this._fileDisplayName$.next(this.selectedCatalogueL1?.fileInfoDto?.displayName!)
      this._fileSize$.next(this.selectedCatalogueL1?.fileInfoDto!.fileSize!)
    }
  }

  clearL1() {
    this.selectedCatalogueL1 = undefined;
    this.ctrlCategoryL1Name.reset();
    this.deleteFile();
    this.isLoading$.next(false);
  }

  clearL2() {
    this.selectedCatalogueL2 = undefined;
    this.ctrlCategoryL1Name.reset();
    this.deleteFile();
    this.isLoading$.next(false);
  }


  handleSubmitCatalogueL1() {
    this.isSubmitted = true;

    if (!this._fileDisplayName$.value) {
      return;
    }

    this._showErrorToast$.next(false);
    this._errorMsg$.next('');
    this.isLoading$.next(true);
    this._isSaveButtonEnable$.next(false);

    if (this.currentFile) {
      this.uploadFileL1(this.currentFile!);
    } else {
      this.addCatalogueL1();
    }
  }

  handleSubmitCatalogueL2() {
    this.isSubmitted = true;

    if (!this._fileDisplayName$.value) {
      return;
    }

    this._showErrorToast$.next(false);
    this._errorMsg$.next('');
    this.isLoading$.next(true);
    this._isSaveButtonEnable$.next(false);

    if (this.currentFile) {
      this.uploadFileL2(this.currentFile!);
    } else {
      this.addCatalogueL2();
    }
  }


  mergeCatalogueL1() {

    if (!this.selectedCatalogueL1 || !(this.selectedCatalogueL1.categoryL1Id)) {
      this.selectedCatalogueL1 = new CatalogueL1EntityDto();
    }

    this.selectedCatalogueL1!.name = this.ctrlCategoryL1Name.value;
    this.selectedCatalogueL1!.fileInfoDto = this.currentFileInfoDto;
  }

  mergeCatalogueL2() {

    if (!this.selectedCatalogueL2 || !(this.selectedCatalogueL2.categoryL1Id)) {
      this.selectedCatalogueL2 = new CatalogueL2EntityDto();
    }
    this.selectedCatalogueL2!.categoryL1Id = this.selectedCatalogueL1?.categoryL1Id;
    this.selectedCatalogueL2!.name = this.ctrlCategoryL2Name.value;
    this.selectedCatalogueL2!.fileInfoDto = this.currentFileInfoDto;
  }

  deleteFile() {
    this._fileDisplayName$.next('');
    this._fileSize$.next('');
    this.currentFile = null;

  }


  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  addCatalogueL1() {
    this._showErrorToast$.next(false);
    this._errorMsg$.next('');


    if (this.ctrlCategoryL1Name.invalid) {
      return;
    }


    this.isLoading$.next(true);
    this._isSaveButtonEnable$.next(false);


    this.mergeCatalogueL1();

    this.adminDashboardService
      .saveCatalogueL1(this.selectedCatalogueL1!)
      .subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
            this._showSuccessToast$.next(true);
            this.isLoading$.next(false);
            this._isSaveButtonEnable$.next(true);
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.closeModal();
              this.loadCatalogueL1List();
            }, 2000);

            // this.navigateToCreatorDashboard(data.auctionId!);

          } else {
            this._showErrorToast$.next(true);
            this._errorMsg$.next(apiResponseDto.message!);
            this.isLoading$.next(false);
            this._isSaveButtonEnable$.next(true);
          }
        },
        error: (error) => {
          console.error(error);
          this._showErrorToast$.next(true);
          this._errorMsg$.next('Error while saving Catalogue1. Try again.');
          this.isLoading$.next(false);
          this._isSaveButtonEnable$.next(true);
        },
      });
  }

  addCatalogueL2() {
    this._showErrorToast$.next(false);
    this._errorMsg$.next('');


    if (this.ctrlCategoryL1Name.invalid) {
      return;
    }


    this.isLoading$.next(true);
    this._isSaveButtonEnable$.next(false);


    this.mergeCatalogueL2();

    this.adminDashboardService
      .saveCatalogueL2(this.selectedCatalogueL2!)
      .subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
            this._showSuccessToast$.next(true);
            this.isLoading$.next(false);
            this._isSaveButtonEnable$.next(true);
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.closeModal();
              this.loadCatalogueL2List(this.selectedCatalogueL1?.categoryL1Id!);
            }, 2000);

            // this.navigateToCreatorDashboard(data.auctionId!);

          } else {
            this._showErrorToast$.next(true);
            this._errorMsg$.next(apiResponseDto.message!);
            this.isLoading$.next(false);
            this._isSaveButtonEnable$.next(true);
          }
        },
        error: (error) => {
          console.error(error);
          this._showErrorToast$.next(true);
          this._errorMsg$.next('Error while saving Catalogue2. Try again.');
          this.isLoading$.next(false);
          this._isSaveButtonEnable$.next(true);
        },
      });
  }



  uploadFileL1(currentFile: File) {
    this.isLoading$.next(true);
    this.fileUploadError = false;
    this._errorMsg$.next('');

    let metaData = {
      mimeType: currentFile.type,
      version: 0,
      publicApi: true,
      dataType: this.imageType,
    };

    let formData = new FormData();
    formData.append('file', currentFile);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe((apiResponseDto) => {
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;

          this.currentFileInfoDto = new FileInfoDto();
          this.currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
          this.currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.fileSize = currentFile.size.toString();
          this.currentFileInfoDto.fileType = currentFile.type;
          this.currentFileInfoDto.dataType = fileSaveResponseDto.dataType;
          this.currentFileInfoDto.uploadDate = new Date().toDateString();

          this.addCatalogueL1();
        }
      } else {
        this.isLoading$.next(false);
        this.fileUploadError = true;
      }
    });
  }

  uploadFileL2(currentFile: File) {
    this.isLoading$.next(true);
    this.fileUploadError = false;
    this._errorMsg$.next('');

    let metaData = {
      mimeType: currentFile.type,
      version: 0,
      publicApi: true,
      dataType: this.imageType,
    };

    let formData = new FormData();
    formData.append('file', currentFile);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe((apiResponseDto) => {
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;

          this.currentFileInfoDto = new FileInfoDto();
          this.currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
          this.currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.fileSize = currentFile.size.toString();
          this.currentFileInfoDto.fileType = currentFile.type;
          this.currentFileInfoDto.dataType = fileSaveResponseDto.dataType;
          this.currentFileInfoDto.uploadDate = new Date().toDateString();

          this.addCatalogueL2();
        }
      } else {
        this.isLoading$.next(false);
        this.fileUploadError = true;
      }
    });
  }

  async chooseFile(event: any) {
    this._showErrorToast$.next(false);
    this.currentFile = event.target.files[0];

    if (!this.imageTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this._errorMsg$.next("Extension not supported");
      this._showErrorToast$.next(true);
      this.currentFile = null;
      this.imageRef?.setAttribute('value', '');

      return;
    }


    if (/image\/hei(c|f)/.test(this.currentFile!.type)) {
      let jpgBlob = await heic2any({ blob: this.currentFile!, toType: 'image/jpeg', quality: 0 });

      //Change the name of the file according to the new format
      let newName = this.currentFile?.name.replace(/\.[^/.]+$/, ".jpg");

      //Convert blob back to file
      let newFile: any = jpgBlob;
      newFile.lastModified = new Date();
      newFile.name = newName;
      this.currentFile = <File>newFile;
    }

    let reader = new FileReader();
    reader.readAsDataURL(this.currentFile!);
    reader.onload = (e) => {
      let size = (this.currentFile?.size! / 1024) / 1024;

      // Create New Image
      var newImage = new Image();
      newImage.src = e.target!.result as string;

      newImage.onload = (el) => {
        this.imageType = newImage.width > newImage.height ? 'Rectangle' : 'Portrait';
        if (size > 1) {
          var canvas = document.createElement("canvas");
          canvas.width = newImage.width;
          canvas.height = newImage.height;

          var ctx = canvas.getContext("2d");
          ctx?.drawImage(el.target as CanvasImageSource, 0, 0, canvas.width, canvas.height);
          var srcEncoded;
          if (size >= 5) {
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.1);
          } else {
            // size less then 5 MB
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.5);
          }

          ApplicationUtils.base64toFile(srcEncoded, this.currentFile!.name, this.currentFile!.type)
            .then((file: File) => {
              this.currentFile = file;
            })
        }
      }
      this.imageUrl = reader.result?.toString();
      this._fileDisplayName$.next(this.currentFile?.name as string);
      this._fileSize$.next(this.currentFile?.size + "");
    }
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }
}
