import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingService } from '../../services/LandingAndBidder.service';
import { CategoryDto, CategoryLevelOneWrapperDto, CategoryParentWrapperDto } from '../../models/CategoryDto';
// import { TreeItem, TreeviewConfig, TreeviewItem, TreeviewComponent } from '@charmedme/ngx-treeview';
import { UserService } from '../../services/user.service';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';

import { ApplicationConstants } from '../../util/ApplicationConstants';
import { BehaviorSubject } from 'rxjs';

import { ApplicationUtils } from '../../util/ApplicationUtils';
import { GlobalBidderEntityDto } from '../../models/GlobalBidderEntityDto';
import { TreeNode } from 'primeng/api';
import { PreferenceCategoryDto } from '../../models/user/PreferenceCategoryDto';
import { TreeSelectionMode } from '../../enums/TreeSelectionMode';
import { UserEntityInfoDto } from '../../models/user/UserEntityInfoDto';


@Component({
  selector: 'app-user-category-selection',
  templateUrl: './user-category-selection.component.html',
  styleUrls: ['./user-category-selection.component.sass']
})
export class UserCategorySelectionComponent implements OnInit, AfterViewInit {
  @Input() userId?: string;
  @Input() userDto!: UserEntityInfoDto  | GlobalBidderEntityDto;
  @Input() isGlobalBidder: boolean = false;
  @Output() onSelectedCategories = new EventEmitter<PreferenceCategoryDto>;

  finalSelectedCategory?: PreferenceCategoryDto
  TreeSelectionMode: typeof TreeSelectionMode = TreeSelectionMode;

  errorMsg: string | undefined;
  isLoading: boolean = false;
  loadingData: boolean = false;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  constructor(
    private activeModal: NgbActiveModal,
    private landingService: LandingService,
    private userService: UserService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
  }

  onFilterChange(event: any) {
    console.log(event);
    // let tempMasterList = ApplicationUtils.clone(this.masterCategories) as CategoryDto[];
    // let filterCategoryList = tempMasterList.filter(item => item.category!.trim().toLowerCase().includes(event.trim().toLowerCase()) ||
    //   item.subcategory!.trim().toLowerCase().includes(event.trim().toLowerCase()) ||
    //   item.subcategoryLevelOne!.trim().toLowerCase().includes(event.trim().toLowerCase())).map(item => item);

    // if (event.trim() != '') {
    //   this.treeItemsCategories = this.buildFilterCategoriesTree(filterCategoryList);
    // } else {
    //   this.treeItemsCategories = this.buildCategoriesTree()
    // }


    // this.ngxTreeview?.onFilterTextChange(event);
  }

  onCategorySelectedChange(preferenceCategory?: PreferenceCategoryDto) {
    this.finalSelectedCategory = preferenceCategory;
  }


  closeModal() {
    this.activeModal.close();
  }

  emitSelectedCategories() {
    this.onSelectedCategories.emit(this.finalSelectedCategory);
    this.closeModal();
  }

  saveCategories() {
    if (!this.finalSelectedCategory) {
      this.errorMsg = "Please select at least one category!";
      this._showErrorToast$.next(true);
      return;
    }

    this.errorMsg = "";
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    this.isLoading = true;

    this.userService.subcategoryPreference([this.finalSelectedCategory]).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as UserEntityInfoDto;

          this.userService.setUserDto(data);


          this.isLoading = false;
          this._showSuccessToast$.next(true);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal()
          }, 2000)
        } else {
          this.isLoading = false;


          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.errorMsg = "Error while saving preference categories!";
        this._showErrorToast$.next(true);
      }
    })
  }

  // buildCategoriesTree(): TreeviewItem[] {
  //   const tree: TreeviewItem[] = [];

  //   const categoryMap: { [key: string]: TreeviewItem } = {};

  //   // Helper function to get or create a category node
  //   function getCategoryNode(category: string, isChecked: boolean): TreeviewItem {
  //     if (!categoryMap[category]) {
  //       const newNode = new TreeviewItem({
  //         text: category,
  //         value: category,
  //         children: [],
  //         checked: isChecked
  //       });
  //       categoryMap[category] = newNode;
  //       tree.push(newNode);
  //     }
  //     return categoryMap[category];
  //   }

  //   let selectedMasterCategories = this.userDto ? this.masterCategories.filter(item => (this.userDto.preferenceCategories ?? []).includes(item.id!)) : [];

  //   // Populate the tree
  //   this.masterCategories.forEach(categoryDto => {
  //     let selectedCategory = selectedMasterCategories.find(item => item.category == categoryDto.category);
  //     let isCategorySelected = selectedCategory != undefined;

  //     const categoryNode = getCategoryNode(categoryDto.category!, isCategorySelected);
  //     // categoryNode.value++;
  //   });

  //   return tree;
  // }

  // buildSubCategoriesTree(): TreeviewItem[] {
  //   const tree: TreeviewItem[] = [];

  //   const categoryMap: { [key: string]: TreeviewItem } = {};
  //   const subcategoryMap: { [key: string]: TreeviewItem } = {};

  //   // Helper function to get or create a category node
  //   function getCategoryNode(category: string): TreeviewItem {
  //     if (!categoryMap[category]) {
  //       const newNode = new TreeviewItem({
  //         text: category,
  //         value: category,
  //         children: [],
  //         checked: false
  //       });

  //       newNode.correctChecked();

  //       categoryMap[category] = newNode;
  //       tree.push(newNode);
  //     }
  //     return categoryMap[category];
  //   }

  //   // Helper function to add a subcategory node
  //   function addSubcategoryNode(categoryNode: TreeviewItem, subcategory: string): TreeviewItem {
  //     if (!subcategoryMap[subcategory]) {
  //       const newNode = new TreeviewItem({
  //         text: subcategory,
  //         value: subcategory,
  //         children: [],
  //         checked: false
  //       });

  //       subcategoryMap[subcategory] = newNode;

  //       if (categoryNode.children) {
  //         categoryNode.children.push(newNode);
  //       } else {
  //         categoryNode.children = [newNode];
  //       }

  //       categoryNode.correctChecked();
  //     }

  //     return subcategoryMap[subcategory];
  //   }

  //   // Helper function to add a subcategory level one node
  //   function addSubcategoryLevelOneNode(subcategoryNode: TreeviewItem, subcategoryLevelOne: string, categoryId: string, checked: boolean): void {
  //     const newNode = new TreeviewItem({
  //       text: subcategoryLevelOne,
  //       value: categoryId,
  //       checked: checked
  //     });

  //     if (subcategoryNode.children) {
  //       subcategoryNode.children.push(newNode);
  //     } else {
  //       subcategoryNode.children = [newNode];
  //     }

  //     subcategoryNode.correctChecked();
  //   }

  //   let categories = this.masterCategories.filter(item => this.selectedCategories.includes(item.category!));

  //   // Populate the tree
  //   categories.forEach(categoryDto => {
  //     let isSubCategory2Selected = this.userDto ? (this.userDto.preferenceCategories ?? []).includes(categoryDto.id!) : false;

  //     const categoryNode = getCategoryNode(categoryDto.category!);

  //     if (categoryDto.subcategory) {
  //       const subcategoryNode = addSubcategoryNode(categoryNode, categoryDto.subcategory);
  //       if (categoryDto.subcategoryLevelOne) {
  //         addSubcategoryLevelOneNode(subcategoryNode, categoryDto.subcategoryLevelOne, categoryDto.id!, isSubCategory2Selected);
  //       }
  //     }
  //   });

  //   return tree;
  // }

  // buildFilterCategoriesTree(filteredMasterCategories: CategoryDto[]): TreeviewItem[] {
  //   const tree: TreeviewItem[] = [];

  //   const categoryMap: { [key: string]: TreeviewItem } = {};

  //   // Helper function to get or create a category node
  //   function getCategoryNode(category: string, isChecked: boolean): TreeviewItem {
  //     if (!categoryMap[category]) {
  //       const newNode = new TreeviewItem({
  //         text: category,
  //         value: category,
  //         children: [],
  //         checked: isChecked
  //       });
  //       categoryMap[category] = newNode;
  //       tree.push(newNode);
  //     }
  //     return categoryMap[category];
  //   }

  //   let selectedMasterCategories = filteredMasterCategories.filter(item => (this.userDto.preferenceCategories ?? []).includes(item.id!));

  //   // Populate the tree
  //   filteredMasterCategories.forEach(categoryDto => {
  //     let selectedCategory = selectedMasterCategories.find(item => item.category == categoryDto.category);
  //     let isCategorySelected = selectedCategory != undefined;

  //     const categoryNode = getCategoryNode(categoryDto.category!, isCategorySelected);
  //     // categoryNode.value++;
  //   });

  //   return tree;
  // }
}
