<div class="container p-3">
  <div class="col-12">
    <div class="row align-items-center">
      <div class="col-11">
        <div class="me-3">
          <span class="me-3">Company:</span>
          <span>{{comFinancialWrapperDto?.companyName}}</span>
        </div>

        <div>
          <span class="me-3">Event Name</span>
          <span>{{subcategorySubmissionDto?.subcategoryName}}</span>
        </div>
      </div>
      <div class="col-1 text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>

    <div class="row align-items-center mb-3">
      <div class="col-4">
        <span class="me-3">
          <a class="c-pointer" [ngClass]="{'disabled' : isDisabledPrev()}" (click)="prevQuestion()">Previous</a>
        </span>
        <span class="me-3">Q {{currentQuestionIndex + 1}} to {{financialQuestionTemplates.length}}</span>
        <span class="me-3">
          <a class="c-pointer" [ngClass]="{'disabled' : isDisabledNext()}" (click)="nextQuestion()">Next</a>
        </span>
      </div>
      <div class="col-4 text-center">
        Total Score {{82}}/{{100}}
      </div>
    </div>

    <div class="d-grid gap-2 my-3" *ngIf="isLoading">
      <div class="e-spinner"></div>
    </div>

    <div class="">
      <div class="row mb-3">
        <div class="col-11 pe-0">
          <div class="input-group">
            <span class="input-group-text border-end-0">
              {{selectedFinancialQuestion?.preSequenceText}}.{{selectedFinancialQuestion?.sequenceNo}}
            </span>
            <div class="form-floating">
              <input class="form-control border-start-0" id="ctrlQuestionText" [formControl]="ctrlQuestionText">
              <label for="ctrlQuestionText">Item Name
                <span class="ms-2 badge badge-white-class">
                  Mandatory
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-11 pe-0">
          <div class="input-group">
            <div class="form-floating">
              <input class="form-control" id="ctrlQuestionDescription" [formControl]="ctrlQuestionDescription">
              <label for="ctrlQuestionDescription">Item description</label>
            </div>
          </div>
        </div>
        <div class="col-1 mt-2">
        </div>
      </div>

      <div class="mb-3">
        <div class="row">
          <div class="col-md-6">
            <div class="mt-2">
              <span class="badge-blue-class px-3 py-1 badge me-3">Qty - {{selectedFinancialQuestion?.quantity}}</span>
              <span class="badge-blue-class px-3 py-1 badge me-3">UoM - {{selectedFinancialQuestion?.uom}}</span>
              <span class="badge-blue-class px-3 py-1 badge me-3">
                Tax - {{selectedFinancialQuestion?.tax ? selectedFinancialQuestion?.tax + '%' : 'NA'}}
              </span>
            </div>

            <div class="row mt-3">
              <div class="mb-2 col-5">
                Item Bid Amount is:
              </div>
              <div class="col-7 mb-2">
                {{getFormattedPrice(selectedUserRfxQuestionsEntityDto?.bidderResponse!)}}
              </div>
              <div class="mb-3 col-5">
                Item Bid Amount in words:
              </div>
              <div class="mb-3 col-7">
                {{rfxEntityDto?.currency?.symbol}}
                {{getAmountInWords(selectedUserRfxQuestionsEntityDto?.bidderResponse)}}
                (in words)
              </div>

              <div class="col-12 mb-2">
                <b>Additional Prices: </b>
              </div>

              <div class="col-12">
                <div class="row" *ngFor="let item of selectedUserRfxQuestionsEntityDto?.additionalPrices">
                  <div class="mb-2 col-5">
                    {{item?.label}}:
                  </div>
                  <div class="mb-2 col-7">
                    {{getFormattedPrice(item?.additionalPrice!)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2 row">
              <div class="div-class col-6 col-md-4">Total Price</div>
              <div class="col-md-8 col-6">
                {{getFormattedPrice(totalBidPrice)}}
              </div>
            </div>
            <div class="mb-2 row">
              <div class="div-class col-6 col-md-4">Tax Value</div>
              <div class="col-md-8 col-6">
                {{getFormattedPrice(totalTaxAmount)}}
              </div>
            </div>
            <div class="mb-2 row">
              <div class="div-class col-6 col-md-4">Additional</div>
              <div class="col-md-8 col-6">
                {{getFormattedPrice(totalAdditionalAmount)}}
              </div>
            </div>
            <hr class="hr my-2">
            <div class="text-blue">
              Total Bid Amount:
              {{getFormattedPrice(totalFinalAmount)}}<br>
              {{subcategorySubmissionDto?.currency?.symbol}} {{getTotalFinalAmountInWords()}} (in words)
            </div>
          </div>

        </div>


        <div class="input-group">
          <div class="form-floating">
            <input class=" form-control" id="ctrlBidderComments" [formControl]="ctrlBidderComments">
            <label for="ctrlBidderComments">
              <div class="row">
                <div class="col-7">
                  Comment
                </div>
              </div>

            </label>
          </div>
        </div>
      </div>

      <!-- <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            [message]="'Question saved successfully.'"></app-message-toast>
        </div>

        <div class="d-grid gap-2 my-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="col-12 text-end">
          <button class="btn btn-sm btn-new-class" *ngIf="!isLoading && !(_showSuccessToast$ | async)"
            (click)="saveQuestionTemplate()">
            Save
          </button>
        </div> -->

    </div>
  </div>
</div>