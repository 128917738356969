<div class="border rounded bg-white">
  <header class="p-2">
    <div class="row ">
      <div class="col-md-6 ">
        <h5 class="heading-h5" ngbAutoFocus> EMAIL NOTIFICATIONS</h5>
      </div>
      <div class="col-md-6 text-md-end ">
        <button class="btn btn-new-class btn-sm px-4  gap-2 mr-5" (click)="openPremiumSupplierModal(addPremiumSupplierModal, false)" [disabled]="!isAllowToEdit()">
          <!-- <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-class "> -->
          ADD
        </button>

        <button class="btn btn-new-class btn-sm px-4 edit-btn-margin"
          *ngIf="auctionPremiumSuppliersDto && auctionPremiumSuppliersDto.emailList!.length > 0"
          (click)="openPremiumSupplierModal(addPremiumSupplierModal, true)" [disabled]="!isAllowToEdit()">
          <!-- <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-class "> -->
          EDIT
        </button>

      </div>
    </div>
  </header>
  <hr class="hr" />
  <div class="col-12 mb-3">
    <div class=" div-class p-2">
      List of emailIds to be notified
    </div>
    <hr class="hr" />
    <div class="col-12 my-3 text-center" *ngIf="!auctionPremiumSuppliersDto ||  auctionPremiumSuppliersDto.emailList!.length == 0">
      <h3 class="heading-new text-dark">No emailIds yet.</h3>
    </div>
   <div class="multiple-list-scroll" *ngIf="auctionPremiumSuppliersDto && auctionPremiumSuppliersDto.emailList!.length > 0">
    <div class="c-pointer">
    <div class="col-12 p-3">
      <div *ngFor="let emailId of auctionPremiumSuppliersDto.emailList; index as i">

        <div class="row m-2" >
          <div class="col-md-4">
           <p>{{emailId}}</p>
          </div>
          <div class="col-md-8 text-end">
             <img src="assets/icons/delete_danger.svg" class=" c-pointer svg-icon-class" (click)="deleteEmailId(emailId)" [ngClass]="{'disabled': !isAllowToEdit()}">
          </div>
         </div>
         <div class="d-grid gap-2 mb-3" *ngIf="isLoading &&  selectedIndex == i" >
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>
         <hr/>
      </div>
     
    </div>
  </div>
   </div>
  </div>
</div>

<ng-template #addPremiumSupplierModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>{{isEdit? 'EDIT' : 'ADD'}} EMAIL ID's</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
           (click)="closeModal()" >
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p class="my-2">
          Enter Comma Separated  Email Id's.
        </p>
        
          <div class="form-floating mb-3">
            <input type="email" class="form-control" [formControl]="emailIdsFormGroup"  id="emailIdsFormGroup"  placeholder="name@example.com" />
            <label for="emailIdsFormGroup">Email Id's</label>
          </div>
          
          <div class="my-3">
            <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
              [message]="_showErrorMsg$ |async"></app-message-toast>
            <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
              message="{{isEdit? 'Email Ids Edited Successfully' : 'Email Ids Added Successfully.'}}"></app-message-toast>
          </div>
    
          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
          </div>
    
          <div class=" d-grid gap-2 mb-3" *ngIf=" !isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class" (click)="saveAuctionPremiumSuppliers()" [disabled]="!isAllowToEdit()">{{isEdit? 'EDIT' : 'ADD'}}</button>
          </div>
        
      </div>
     
    </div>

  </div>
</ng-template>
