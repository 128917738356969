<div class="mx-2" >
  <div class="row my-2">
    <div class="col-md-6">
      <h4 class="heading-h5" >GENERATED INVOICE LIST</h4>
    </div>
  <div class="col-md-6 text-end">
   <div class="row justify-content-end">
    <div class="col-2">
      <button class="btn btn-new-class btn-sm me-2 mt-1" (click)="loadInvoiceData()">
        <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class">
      </button>
     </div>
     <div class="col-6">
      <div class="input-group" >
        <input type="text" placeholder="Search" [formControl]="ctrlSearch" id="ctrlSearch"
          class="form-control border-end-0 rounded-0 border bg-white" />
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button" >
            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
     </div>
   </div>
  </div>
  </div>
  <div class="table-responsive">
    <table class="table table-bordered medium-font  align-middle">
      <thead class="table-dark">
        <tr>
          <th class=""><span >Lot Name</span></th>
          <th class=""><span >Invoice Date</span></th>
          <th class=""> <span >Download Invoice</span></th>
          <th class=""> <span >Payment Status</span></th>
          <th class=""> <span >Action</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isLoading">
          <td colspan="5">
            <div class="d-grid gap-2 mb-3">
              <div class="e-spinner"></div>
            </div>
          </td>
        </tr>
        <tr *ngFor="let item of invoiceList$ | async; index as i" >
          <td>
            <span class="me-1 " *ngIf="currentIndex != i">
              # {{item?.lotInfoDtos![0].lotSequenceNo}}
              {{item?.lotInfoDtos![0].lotName}} </span>
            <span class="ms-3 btn-link" *ngIf="item?.lotInfoDtos!.length > 1 && currentIndex != i" (click)="showLotsMore(i)">
              {{item?.lotInfoDtos!.length - 1}} more
            </span>

            <div *ngIf="currentIndex == i">
              <div *ngFor="let lot of item.lotInfoDtos; index as i">
                <span>
                  # {{lot.lotSequenceNo}}
              {{lot.lotName}}
                </span>
              </div>
            </div>
            <!-- <p *ngFor="let lot of item.lotInfoDtos; index as i">
              <span>
                <span class="me-1 ">
                  # {{lot?.lotSequenceNo}}
                </span> {{lot?.lotName}}
              </span>
          </p> -->
          </td>
          <td>{{item.invoiceDateInStr}}</td>
          <td (click)="downloadInvoice(item.invoiceFileInfo?.fileId!)">
            <button class="btn btn-new-class btn-sm ms-3">
             Download <fa-icon [icon]="['fas', 'download']" class=" fa-lg ms-2 c-pointer"></fa-icon>
            </button>
          </td>
          <td>
            <span [ngClass]="{'bg-warning': item.status == 'PENDING', 'bg-success': item.status == 'PAID',  'bg-info': item.status == 'PARTIALLY_PAID',
            'bg-danger': item.status == 'NO_SHOW'}"
             class="badge p-2 ms-3" >
            <ng-container [ngTemplateOutlet]="templatePop"
                  [ngTemplateOutletContext]="{item:item, title: getStatus(item.status!)}"></ng-container>
            </span>
          </td>

          <td>
            <div ngbDropdown container="body" placement="bottom-right">
              <span ngbDropdownToggle data-bs-toggle="dropdown">
                <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class">
              </span>
              <ul ngbDropdownMenu class="text-center shadow">
                <li ngbDropdownItem class="c-pointer" (click)="openConfirmationModel(confirmationTemplate,'PAID', item?.lotInfoDtos!)">PAID</li>
                <li><hr class="dropdown-divider"></li>

                <li ngbDropdownItem class="c-pointer" (click)="openConfirmationModel(confirmationTemplate,'NO_SHOW', item?.lotInfoDtos!)">NO SHOW</li>
              </ul>
            </div>
          </td>

        </tr>
        <tr *ngIf="!isLoading && (invoiceList$ | async)?.length == 0">
          <td colspan="5">
            <div class="text-center">
              <h5 class="heading-new text-dark text-capitalize">NO INVOICES AVAILABLE</h5>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row justify-content-between" *ngIf="(invoiceList$ | async)!.length > 0">
    <div class="col">
      <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
        <span class="span-class">Show rows per page </span>
        <span ngbDropdownToggle data-bs-toggle="dropdown">
          <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
        </span>
        <ul ngbDropdownMenu class="text-center shadow">
          <li ngbDropdownItem class="c-pointer" (click)="changePageSize(50)">50</li>
          <li>
            <hr class="dropdown-divider " >
          </li>
          <li ngbDropdownItem class="c-pointer" (click)="changePageSize(100)">100</li>
        </ul>
      </div>
    </div>
    <div class="col-auto">
      <ngb-pagination [collectionSize]="invoiceList.length!" [(page)]="page" [pageSize]="pageSize"
        (pageChange)="paginateList()">
      </ngb-pagination>
    </div>
  </div>
</div>


<ng-template #confirmationTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Change Payment Status Model</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="m-3">
      <div class="">
        <p>Do you want to change payment status:  {{getStatus(selectedStatus!)}}</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="_errorMsg$ |async">
        </app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ | async" [isError]="false"
          message="Invoice payment status changed successfully. ">
        </app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isPaymentLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isPaymentLoading&& !(_showSuccessToast$ | async)">
        <button type="button" class="btn btn-new-class btn-sm me-2" (click)="closeModal()">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="updatePaymentStatus()">CONFIRM</button>
      </div>

    </div>
   </div>
  </div>
</ng-template>

<ng-template #templatePop let-item="item" let-title="title">
  <span [ngbPopover]="popContent" triggers="mouseenter:mouseleave" container="body" placement="top" [popoverTitle]="'LOT STATUS'">
    {{title}} <img class="ms-1" src="assets/icons/info_dark.svg" triggers="mouseenter:mouseleave" height="18px" width="18px">
  </span>
  <ng-template #popContent>
    <ul >
      <li *ngFor="let lotInfoDto of item?.lotInfoDtos">
        #{{lotInfoDto?.lotSequenceNo}} : {{getStatus(lotInfoDto?.status)}}
      </li>
    </ul>

  </ng-template>
</ng-template>
