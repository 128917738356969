import { TranslateService } from '../services/translate.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { AuctionValidationErrorCodeDto } from '../models/user/AuctionValidationErrorCodeDto';
import { CurrencyPipe, DatePipe } from "@angular/common";
import { NgbDateStruct, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import * as moment from 'moment-timezone';
import { Timestamp } from '@angular/fire/firestore';
import { Currency } from '../models/Currency';
import { SHA256  } from "crypto-js";
import { CountryCodeDto } from '../models/CountryCodeDto';
import { RfxUiDto } from '../models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from '../models/rfx/RfxSubcategoryUiDto';
import { RfxTypeEnum } from '../enums/rfx/RfxTypeEnum';
import { ContractAlgoEnum } from '../enums/rfx/ContractAlgoEnum';

export class ApplicationUtils {

  static domainName?: string;

  static setDomainName(domainName: string){
      this.domainName = domainName;
  }

  static bytesToSize(bytes: number) {
    let i: number;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)) + "");
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }


  static formatBytes(bytes : any , decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static getSubDomainName() {
    // let basePath = window.location.host;
    // console.log(basePath);
    return this.domainName;
  }

  static getNgbPickerDate(date: string) {
    let dateParts = date?.split('/')!;
    return {
      year: Number.parseInt(dateParts[2]),
      month: Number.parseInt(dateParts[1]),
      day: Number.parseInt(dateParts[0])
    };
  }

  static getNgbPickerTime(time: string) {
    let timeParts = time?.split(':')!;
    return {
      hour: Number.parseInt(timeParts[0]),
      minute: Number.parseInt(timeParts[1]),
      second: 0
    };
  }

  static getDateInMMMddyyyy(date: string, datePipe: DatePipe) {
    let d = date.split("T")[0].split("-");
    let newDate = new Date(d[1] + '/' + d[2] + '/' + d[0]);
    let xyz = datePipe.transform(newDate, 'MMM dd yyyy');
    return xyz;
  }

  static buildMap(obj: any) {
    let map = new Map();
    if (obj != null) {
      Object.keys(obj).forEach(key => {
        map.set(key, obj[key]);
      });
      // map = new Map(Object.keys(obj).map((key) => [key, obj[key]]));
    }
    return map;
  }

  static getTimeFromNgTimePicker(datePipe: DatePipe, ngbTime: NgbTimeStruct): string {
    let newDate = new Date();
    newDate.setHours(ngbTime.hour, ngbTime.minute, ngbTime.second);
    return datePipe.transform(newDate, 'hh:mm a')!;
  }

  static getDateFromNgDatePicker(datePipe: DatePipe, ngbDate: NgbDateStruct) {
    let date = new Date();
    date.setDate(ngbDate.day);
    date.setMonth(ngbDate.month - 1);
    date.setFullYear(ngbDate.year);
    return datePipe.transform(date, 'dd/MM/yyyy');
  }

  static convertedDate(date: string, time: string): Date | undefined {
    try {
      let d = date.split("/");
      let newDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);
      newDate.setHours(Number.parseInt(time?.split(':')[0]), Number.parseInt(time?.split(':')[1]));
      return newDate;
    } catch (e) {
      let result = (e as Error).message;
      console.log(result);
    }
    return undefined;
  }

  static convertedOnlyDate(date: string): Date | undefined {
    try {
      let d = date.split("/");
      let newDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);
      return newDate;
    } catch (e) {
      let result = (e as Error).message;
      console.log(result);
    }
    return undefined;
  }

  static convertedDateFrom12h(date: string, timeIn12h: string): Date | undefined {
    try {
      let d = date.split("/");
      let newDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);
      let newTime = this.convertTime12to24(timeIn12h).split(':');
      newDate.setHours(Number.parseInt(newTime[0]), Number.parseInt(newTime[1]), Number.parseInt(newTime[2]));
      return newDate;
    } catch (e) {
      let result = (e as Error).message;
      console.log(result);
    }
    return undefined;
  }

  static convertTime12to24(time12h: string) {
    let isShowSeconds = true;

    const [time, modifier] = time12h.split(' ');
    let [hours, minutes, seconds] = time.split(':');

    if (!seconds) {
      isShowSeconds = false;
      seconds = '00';
    }

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = (parseInt(hours, 10) + 12).toString();
    }

    if (isShowSeconds) {
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return `${hours}:${minutes}`;
    }
  }

  static convertTime24to12(time24h: string, datePipe: DatePipe) {
    let [hours, minutes] = time24h.split(':');

    let date = new Date();
    date.setHours(Number(hours), Number(minutes), 0, 0);

    return datePipe.transform(date, 'hh:mm a');
  }

  static clone(obj: any) {
    if (obj == null || typeof (obj) != 'object')
      return obj;

    var temp = new obj.constructor();
    for (var key in obj)
      temp[key] = this.clone(obj[key]);

    return temp;
  }

  /**
   *  [type]
   *  Zip Code      => postal_code
   *  State         => administrative_area_level_1
   *  City          => administrative_area_level_2, administrative_area_level_3
   *  Locality      => locality
   *  Country       => country
   * */
  static getAddressByType(address: Address, type: string): string {
    let toReturn = '';
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] == type) {
          toReturn = address.address_components[i].long_name;
        }
      }
    }
    return toReturn;
  }

  static getAddressShortNameByType(address: Address, type: string): string {
    let toReturn = '';
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] == type) {
          toReturn = address.address_components[i].short_name;
        }
      }
    }
    return toReturn;
  }

  static truncateString(input: string, maxLength: number) {
    let formattedInput = input.replace(/<\/?[^>]+(>|$)/g, "");
    formattedInput = formattedInput.replace(/&#[0-9]+;/g, "");
    formattedInput = formattedInput.replace(/&nbsp;/g, ' ');
    formattedInput = formattedInput.replace(/&amp;/g, '&');

    if (formattedInput.length > maxLength) {
      return formattedInput.substring(0, maxLength) + '...';
    }
    return formattedInput;
  }

  static removeSpecialCharacters(inputString: string): string {
    const regex = /[^a-zA-Z0-9_ -]/g;
    const cleanedString = inputString.replace(regex, '');
    return cleanedString;
}


  static getDisplayDay(date: string, datePipe: DatePipe): string | undefined | null {
    try {
      let d = date.split("/");
      let newDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);
      return datePipe.transform(newDate, 'EEE');
    } catch (e) {
      let result = (e as Error).message;
      console.log(result);
    }
    return '';
  }

  static getDateInMMMdd(date: string, datePipe: DatePipe) {
    let d = date.split("/");
    let newDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    return datePipe.transform(newDate, 'MMM dd');
  }

  static getDisplayYear(date: string) {
    let d = date.split("/");
    let newDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    return newDate.getFullYear();
  }

  static getDisplayTimeInHHmm(time: string, datePipe: DatePipe) {
    let date = new Date();
    date.setHours(Number.parseInt(time?.split(':')[0]), Number.parseInt(time?.split(':')[1]));
    return datePipe.transform(date, 'hh:mm');
  }

  static getDisplayMeridiem(time: string, datePipe: DatePipe) {
    let date = new Date();
    date.setHours(Number.parseInt(time?.split(':')[0]), Number.parseInt(time?.split(':')[1]));
    return datePipe.transform(date, 'a');
  }

  static base64toFile(base64: any, filename: string, mimeType: string) {
    return (fetch(base64)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  // Format Currency
  static resetPriceFormat(price: string | number): number {
    if (price == undefined) 0;
    return Number((price + "").replace(/[^0-9.-]+/g, "")) || 0;
  }

  static getFormattedPrice(locale?: string, price?: string | number) {
    if (price == undefined) return '0';
    if (locale == undefined) {
      locale = 'en-US'
    }
    let resetPrice = this.resetPriceFormat(price);
    return Number((resetPrice + "")).toLocaleString(locale);
  }

  static getFormattedPriceWithSymbol(currency: Currency, price?: string | number) {
    let toReturn = '0';

    if (currency == undefined) {
      currency = Currency.default()
    }

    if (price == undefined) {
      toReturn = Number(0).toLocaleString(currency.locale, {
        style: 'currency', currency: currency.code, maximumFractionDigits: 0
      })
    } else {
      let resetPrice = this.resetPriceFormat(price);
      toReturn = Number((resetPrice + "")).toLocaleString(currency.locale, {
        style: 'currency', currency: currency.code, maximumFractionDigits: 0
      });
    }

    return toReturn;
  }

  /**
   *Replace Message with Server param values only when message has PARAM(1,2) placeholder and PARAM(1,2) values are provided
   * @param serverApiResponseDto
   * @param translate
   * @returns
   */
  static populateMessage(auctionValidationErrorCodeDto: AuctionValidationErrorCodeDto, translate: TranslateService) {
    let msg: string;
    if (auctionValidationErrorCodeDto.code == ApplicationConstants.SUCCESS_CODE) {
      msg = translate.transform("200");
    } else {
      msg = translate.transform(auctionValidationErrorCodeDto.code);
      if (msg.includes('PARAM1') && auctionValidationErrorCodeDto.param1) {
        msg = msg.replace('PARAM1', auctionValidationErrorCodeDto.param1);
      }
      if (msg.includes('PARAM2') && auctionValidationErrorCodeDto.param2) {
        msg = msg.replace('PARAM2', auctionValidationErrorCodeDto.param2);
      }
    }
    return msg;
  }

  // Countdown Timer
  static getCountdownTimerDays(date: string, timeZone: string) {
    let remainingTime = this.getRemainingTime(date, timeZone);
    if (remainingTime <= 0) return 0;
    return Math.floor(remainingTime / (1000 * 3600 * 24));
  }

  static getCountdownTimerHours(date: string, timeZone: string) {
    let remainingTime = this.getRemainingTime(date, timeZone);
    if (remainingTime <= 0) return 0;
    return Math.floor(Math.abs(remainingTime / (1000 * 3600) % 24));
  }

  static getCountdownTimerMinutes(date: string, timeZone: string) {
    let remainingTime = this.getRemainingTime(date, timeZone);
    if (remainingTime <= 0) return 0;
    return Math.floor(Math.abs(remainingTime / (1000 * 60) % 60));
  }

  static getCountdownTimerSeconds(date: string, timeZone: string) {
    let remainingTime = this.getRemainingTime(date, timeZone);
    if (remainingTime <= 0) return 0;
    return Math.floor(Math.abs(remainingTime / (1000) % 60));
  }

  static getRemainingTime(endDate: string, timezone: string) {
    let utcMills = this.getUTCEPCTimeStamp();
    let endDateMills = this.getAuctionEndDateUnixMills(endDate, timezone);
    return endDateMills - utcMills;
  }

  static getUTCEPCTimeStamp(): number {
    return Date.parse(new Date().toISOString());
  }

  static getAuctionEndDateUnixMills(endDate: string, timezone: string) {
    let momentformat = this.convertMomentFormat(endDate);
    return moment.tz(momentformat, timezone).utc().valueOf()
  }

  static convertMomentFormat(dateToConvert: string) {
    let final = '';
    if (dateToConvert) {
      let str1 = dateToConvert.split('/').join('-');
      let str2 = str1.replace(' ', 'T')
      let str3 = str2.substring(0, 16);
      let year = str3.substring(6, 10);
      let month = str3.substring(3, 5);
      let day = str3.substring(0, 2);
      let lastStr = str3.substring(11, 16);
      final = year + "-" + month + "-" + day + " " + lastStr;
    }
    return final;
  }

  static isTimeGreaterThenCurrentTime(endDate: string, timezone: string){
    let toReturn = false;
    let utcMills = this.getUTCEPCTimeStamp();
    let endDateMills = this.getAuctionEndDateUnixMills(endDate, timezone);

    if(endDateMills >= utcMills){
      toReturn = true;
    }
    return toReturn;
  }

  static getUtcTime(utcTime: any) {
    let utcTimeInSeconds = (utcTime as Timestamp).seconds;
    const utcMoment = moment.unix(utcTimeInSeconds);
    return utcMoment.format('YYYY-MM-DD HH:mm:ss');
  }

  static getLocalTime(utcTime: any, timezone: string, isFirestoreTimestamp: boolean) {
    let utcTimeInSeconds;

    if (isFirestoreTimestamp) {
      utcTimeInSeconds = (utcTime as Timestamp).seconds;
    } else {
      utcTimeInSeconds = utcTime / 1000;
    }

    const utcMoment = moment.unix(utcTimeInSeconds);
    const localMoment = utcMoment.tz(timezone);
    return localMoment.format('ddd D MMM YYYY h:mm:ss A');
  }

  static getDisplayDateBidder(date?: string, time?: string, timezone?: string) {
    let toReturn = '';
    if (date && time && timezone) {
      let convertedDate = ApplicationUtils.convertedDate(date, time);
      toReturn = ApplicationUtils.getLocalTime(convertedDate!.getTime(), timezone!, false);
    }
    return toReturn;
  }

  static getDisplayDateAdmin(date?: string, time?: string) {
    let toReturn = '';
    if (date && time) {
      let convertedDate = ApplicationUtils.convertedDate(date, time);
      const utcMoment = moment.unix(convertedDate!.getTime()/1000);
      toReturn = utcMoment.format('ddd D MMM YYYY h:mm:ss A');
    }
    return toReturn;
  }

  static getSortDisplayDateAdmin(date?: string, time?: string) {
    let toReturn = '';
    if (date && time) {
      let convertedDate = ApplicationUtils.convertedDate(date, time);
      const utcMoment = moment.unix(convertedDate!.getTime()/1000);
      toReturn = utcMoment.format('DD MMM YYYY');
    }
    return toReturn;
  }

  static getDisplayDateAdminNew(date?: string, time?: string) {
    let toReturn = '';
    if (date && time) {
      let convertedDate = ApplicationUtils.convertedDateFrom12h(date, time);
      const utcMoment = moment.unix(convertedDate!.getTime()/1000);
      toReturn = utcMoment.format('ddd D MMM YYYY h:mm:ss A');
    }
    return toReturn;
  }

  static clearTextFormatOnPaste(editorId: any) {
    let element = document.getElementById(editorId);
    if (element) {
      element?.addEventListener('paste', function (e: any) {
        e.preventDefault();

        const text = e.clipboardData
          ? (e.originalEvent || e).clipboardData.getData('text/plain')
          : '';

        if (document.queryCommandSupported('insertText')) {
          document.execCommand('insertText', false, text);
        } else {
          // Insert text at the current position of caret
          const range = document.getSelection()!.getRangeAt(0);
          range.deleteContents();

          const textNode = document.createTextNode(text);
          range.insertNode(textNode);
          range.selectNodeContents(textNode);
          range.collapse(false);

          const selection = window.getSelection();
          selection?.removeAllRanges();
          selection?.addRange(range);
        }
      });
    }
  }

  static convertNumberToWords(num: number): string {
    // Array of words for numbers 0 to 19
    const units: string[] = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

    // Array of words for tens multiples
    const tens: string[] = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    // Array of words for thousands multiples
    const thousands: string[] = ['', 'thousand', 'million', 'billion', 'trillion'];

    // Function to convert a three-digit number to words
    const convertThreeDigitNumberToWords = (num: number): string => {
      let words = '';

      if (num >= 100) {
        words += units[Math.floor(num / 100)] + ' hundred ';
        num %= 100;
      }

      if (num >= 20) {
        words += tens[Math.floor(num / 10)] + ' ';
        num %= 10;
      }

      if (num > 0) {
        words += units[num] + ' ';
      }

      return words.trim();
    };

    // Handle the special case of zero
    if (num === 0) {
      return units[0];
    }

    let words = '';
    let thousandsIndex = 0;

    while (num > 0) {
      const threeDigits = num % 1000;

      if (threeDigits !== 0) {
        words = convertThreeDigitNumberToWords(threeDigits) + thousands[thousandsIndex] + ' ' + words;
      }

      num = Math.floor(num / 1000);
      thousandsIndex++;
    }

    return words.trim();
  }

  static getUrlExtension(url: any) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  static getYoutubeId(url: any) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  static isYoutubeUrl(url: any) {
    if (url) {
      var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (url.match(regExp)) {
        return true;
      }
    }
    return false;
  }

  static isVimeoUrl(url: string) {
    if (url) {
      var regExp = /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/;
      if (url.match(regExp)) {
        return true;
      }
    }
    return false;
  }

  static getVimeoId(url: any) {
    var match = /vimeo.*\/(\d+)/i.exec(url);
    if (match) {
      return match[1];
    }
    return null;
  }

  static getVideoThumbnailFromUrl(url: string) {
    if (this.isYoutubeUrl(url)) {
      return `https://img.youtube.com/vi/${this.getYoutubeId(url)}/0.jpg`;
    } else if (this.isVimeoUrl(url)) {
      return `https://vumbnail.com/${this.getVimeoId(url)}.jpg`;
    } else {
      return '/assets/images/video_thumbnail.svg';
    }
  }

  static isValidColor(strColor: string){
    var s = new Option().style;
    s.color = strColor;
    return s.color !== '';
  }

  static getNumberValue(value: number | undefined ): number{
    if(value == undefined){
       return 0;
    }else {
      return Number(value);
    }
  }

  static getRandomLightColorHex(): string {
    const r = Math.floor(Math.random() * 128) + 128; // Generates a random value between 128 and 255 for red
    const g = Math.floor(Math.random() * 128) + 128; // Generates a random value between 128 and 255 for green
    const b = Math.floor(Math.random() * 128) + 128; // Generates a random value between 128 and 255 for blue

    const hex = (c: number) => c.toString(16).padStart(2, '0'); // Convert to hexadecimal format
    return `#${hex(r)}${hex(g)}${hex(b)}`;
  }

  static encryptSHA256String(data: string) {
    return SHA256(data);
  }

  static getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

  static ordinalSuffixOf(i: number) {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }

  static getPercentage(value: number, percent: number) {
    let percentageValue = value * percent / 100;
    return parseFloat(percentageValue.toFixed(2));
  }

  static getPercentageOfValues(value1: number, value2: number, isReverse?: boolean) {
    let percentageValue = isReverse ? Number(value1) - Number(value2) : Number(value2) - Number(value1);
    let percentage = (percentageValue / value1) * 100;
    return parseFloat(percentage.toFixed(2));
  }

  static resetDecimalNumber(value: number) {
    return parseFloat(value.toFixed(2));
  }

  static alphabetsList() {
    let alphabets = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase();
    return alphabets.split('');
  }

  static checkIfRoleIsAssigned(userPrivileges: string[], privilege: string ):boolean {
    if (!userPrivileges || userPrivileges.length == 0) {
      return false;
    }
    return userPrivileges.includes(privilege);
  }

}
