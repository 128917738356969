import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AddressDto } from 'src/app/shared/models/Address';
import { ApplicationStartupDto } from 'src/app/shared/models/ApplicationStartupDto';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { OnboardingCountry } from 'src/app/shared/models/OnboardingCountry';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { TimeZoneDto } from 'src/app/shared/models/TimeZoneDto';
import { UserEntityInfoDto } from 'src/app/shared/models/user/UserEntityInfoDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationStartupCacheService } from 'src/app/shared/services/application-startup-cache.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-onboarding-companies',
  templateUrl: './onboarding-companies.component.html',
  styleUrls: ['./onboarding-companies.component.sass']
})
export class OnboardingCompaniesComponent implements OnInit, OnDestroy {
  @Output() onNavigateInternalPage = new EventEmitter<string>;

  formGroup: FormGroup;
  formGroupPlants: FormGroup;
  formGroupUser: FormGroup;
  ctrlSearch: FormControl = new FormControl('')

  workflowMode: string = '' // COMPANY, PLANT, USER
  isDataLoading: boolean = false;
  isLoading: boolean = false;
  isShowCompanyView: boolean = false;
  newCompanyCode?: string;
  newPlantCode?: string;

  shippingLatitude?: number;
  shippingLongitude?: number;
  billingLatitude?: number;
  billingLongitude?: number;
  shippingCountryShortName?: string
  billingCountryShortName?: string

  applicationStartupDto?: ApplicationStartupDto;
  selectedCompanyUiDto?: CompanyUiDto;
  selectedBillingAddress?: AddressDto;
  selectedShippingAddress?: AddressDto;
  selectedOnboardingCountry?: OnboardingCountry;
  companiesList: CompanyUiDto[] = []
  filteredCompanies: CompanyUiDto[] = []
  timezones: Array<TimeZoneDto> = [];
  onboardingCountries: Array<OnboardingCountry> = [];

  phoneCountryCode = CountryCodeDto.default();
  faxCountryCode = CountryCodeDto.default();

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");
  countryCodePhone$ = new BehaviorSubject<CountryCodeDto | null>(null);
  countryCodeFax$ = new BehaviorSubject<CountryCodeDto | null>(null);
  _filteredCompanies$ = new BehaviorSubject<CompanyUiDto[]>([]);

  selectedCompanySubscription$?: Subscription
  companiesSubscription$?: Subscription
  masterTimeZoneSubscription$?: Subscription;

  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  zoom = 12;
  positionBilling?: google.maps.LatLngLiteral;
  positionShipping?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  markerBilling = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }
  markerShipping = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }

  constructor(
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private changeDetectRef: ChangeDetectorRef,
    private onboardingService: OnboardingService,
    private adminDashboardService: AdminDashboardService,
    private appStartupService: ApplicationStartupCacheService
  ) {
    this.formGroup = this.fb.group({
      companyCode: ['', Validators.required],
      companyName: ['', Validators.required],
      primaryCompany: [false, Validators.required],
      legalType: ['', Validators.required],
      industrySector: ['', Validators.required],
      region: ['', Validators.required],
      country: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      faxNumber: [''],
      taxId1: [''],
      taxId2: [''],
      branch: ['', Validators.required],
      timeZone: ['', Validators.required],
      fiscalYrStart: ['', Validators.required],
      fiscalYrEnd: ['', Validators.required],
      shippingAddress: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: ['', this.validBillingLocation()],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: ['', Validators.pattern(Pattern.zipCode)],
      }),
      billingAddress: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: ['', this.validBillingLocation()],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: ['', Validators.pattern(Pattern.zipCode)],
      }),
    });

    this.formGroupPlants = this.fb.group({
      plantName: ['', Validators.required],
      plantCode: ['', Validators.required],
      accountTitle: ['', Validators.required],
      accNumber: ['', Validators.required],
      accHolderName: ['', Validators.required],
      accType: ['', Validators.required],
      accCode: ['', Validators.required],
      swift: ['', Validators.required],
      iban: ['', Validators.required],
      shippingAddress: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: ['', this.validBillingLocation()],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: ['', Validators.pattern(Pattern.zipCode)],
      }),
      billingAddress: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: ['', this.validBillingLocation()],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: ['', Validators.pattern(Pattern.zipCode)],
      }),
    })

    this.formGroupUser = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      designation: ['', Validators.required],
      empCode: [''],
      primaryEmailId: ['', [Validators.required, Validators.pattern(Pattern.email)]],
      plantCodes: [[]]
    })
  }

  ngOnInit(): void {
    this.onboardingCountries = this.onboardingService.getOnboardingCountries;
    this.applicationStartupDto = this.appStartupService.getApplicationStartupDto;

    this.loadCompanyUiDto();

    this.masterTimeZoneSubscription$ = this.adminDashboardService.getMasterTimezones$.subscribe((data) => {
      if (data) {
        this.timezones = data;
      }
    });

    this.companiesSubscription$ = this.onboardingService.getCompanyUiDtos$.subscribe(data => {
      if (data) {
        this.companiesList = data;
        this.filteredCompanies = this.companiesList;
        this._filteredCompanies$.next(this.filteredCompanies);

        if (this.selectedCompanyUiDto) {
          this.selectedCompanyUiDto = this.companiesList.find(item => item.companyCode == this.selectedCompanyUiDto?.companyCode);
        }
      } else {
        this.companiesList = [];
        this.filteredCompanies = this.companiesList;
        this._filteredCompanies$.next(this.filteredCompanies);
      }
    })

    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;
        this.isShowCompanyView = true;
      } else {
        this.selectedCompanyUiDto = undefined;
        this.isShowCompanyView = false;
      }
    })

    this.ctrlSearch.valueChanges.subscribe((val) => {
      this.search(val);
    })
  }

  get fc() { return this.formGroup.controls; }
  get pc() { return this.formGroupPlants.controls; }
  get uc() { return this.formGroupUser.controls; }

  validShippingLocation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValidLocation = this.shippingLatitude != undefined;
      return control.value && !isValidLocation ? { address: { value: control.value } } : null;
    }
  }

  validBillingLocation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValidLocation = this.billingLatitude != undefined;
      return control.value && !isValidLocation ? { address: { value: control.value } } : null;
    }
  }

  async loadCompanyUiDto() {
    this.isDataLoading = true;
    await this.onboardingService.loadCompanyUiDtosByOrgCode();
    this.isDataLoading = false;
  }

  search(searchText: string) {
    let companiesList = [...this.companiesList ?? []];

    if (searchText.trim() != '') {
      let filteredCompanies = companiesList.filter((item) => item.companyName?.toLowerCase().includes(searchText.trim().toLowerCase()) || item.companyCode?.toLowerCase()?.includes(searchText.trim()));
      this.filteredCompanies = filteredCompanies;
    } else {
      this.filteredCompanies = companiesList;
    }

    this._filteredCompanies$.next(this.filteredCompanies);
  }

  populateCompanyDetails() {
    this.formGroup.controls['companyName'].patchValue(this.selectedCompanyUiDto?.companyName);
    this.formGroup.controls['companyCode'].patchValue(this.selectedCompanyUiDto?.companyCode);
    this.formGroup.controls['primaryCompany'].patchValue(this.selectedCompanyUiDto?.primaryCompany);
    this.formGroup.controls['legalType'].patchValue(this.selectedCompanyUiDto?.legalType);
    this.formGroup.controls['industrySector'].patchValue(this.selectedCompanyUiDto?.industrySector);
    this.formGroup.controls['region'].patchValue(this.selectedCompanyUiDto?.region);
    this.formGroup.controls['country'].patchValue(this.selectedCompanyUiDto?.country);
    this.formGroup.controls['phoneNumber'].patchValue(this.selectedCompanyUiDto?.phoneNumber);
    this.formGroup.controls['faxNumber'].patchValue(this.selectedCompanyUiDto?.faxNumber);
    this.formGroup.controls['taxId1'].patchValue(this.selectedCompanyUiDto?.taxId1);
    this.formGroup.controls['taxId2'].patchValue(this.selectedCompanyUiDto?.taxId2);
    this.formGroup.controls['branch'].patchValue(this.selectedCompanyUiDto?.branch);
    this.formGroup.controls['timeZone'].patchValue(this.selectedCompanyUiDto?.timeZone);

    this.handlePhoneCountryCode(this.selectedCompanyUiDto?.phoneCountryCode ?? CountryCodeDto.default());
    this.handleFaxCountryCode(this.selectedCompanyUiDto?.faxCountryCode ?? CountryCodeDto.default());
    this.countryCodePhone$.next(this.phoneCountryCode);
    this.countryCodeFax$.next(this.faxCountryCode);

    if (this.selectedCompanyUiDto?.fiscalYrStart) {
      let fiscalYrStart = ApplicationUtils.getNgbPickerDate(this.selectedCompanyUiDto?.fiscalYrStart!);
      this.formGroup.controls['fiscalYrStart'].patchValue(fiscalYrStart);
    }

    if (this.selectedCompanyUiDto?.fiscalYrEnd) {
      let fiscalYrEnd = ApplicationUtils.getNgbPickerDate(this.selectedCompanyUiDto?.fiscalYrEnd!);
      this.formGroup.controls['fiscalYrEnd'].patchValue(fiscalYrEnd);
    }

    if (this.selectedCompanyUiDto?.billingAddress && this.selectedCompanyUiDto.billingAddress.length > 0) {
      this.selectedBillingAddress = this.selectedCompanyUiDto.billingAddress[0];

      this.billingLatitude = parseFloat(this.selectedBillingAddress.latitude!);
      this.billingLongitude = parseFloat(this.selectedBillingAddress.longitude!);
      this.billingCountryShortName = this.selectedBillingAddress.countryShortName;

      this.positionBilling = { lat: this.billingLatitude, lng: this.billingLongitude }
      this.markerBilling.label.text = this.selectedBillingAddress.searchAddress!;
      this.markerBilling.title = this.selectedBillingAddress.searchAddress!;
      this.markerBilling.info = this.selectedBillingAddress.searchAddress!;

      this.formGroup.controls['billingAddress'].patchValue(this.selectedBillingAddress);
    }

    if (this.selectedCompanyUiDto?.shippingAddress && this.selectedCompanyUiDto.shippingAddress.length > 0) {
      this.selectedShippingAddress = this.selectedCompanyUiDto.shippingAddress[0];

      this.shippingLatitude = parseFloat(this.selectedShippingAddress.latitude!);
      this.shippingLongitude = parseFloat(this.selectedShippingAddress.longitude!);
      this.shippingCountryShortName = this.selectedShippingAddress.countryShortName;

      this.positionShipping = { lat: this.shippingLatitude, lng: this.shippingLongitude }
      this.markerShipping.label.text = this.selectedShippingAddress.searchAddress!;
      this.markerShipping.title = this.selectedShippingAddress.searchAddress!;
      this.markerShipping.info = this.selectedShippingAddress.searchAddress!;

      this.formGroup.controls['shippingAddress'].patchValue(this.selectedShippingAddress);
    }

    this.setCountryValidation();

    this.formGroup.updateValueAndValidity();
    this.changeDetectRef.detectChanges();
  }

  setCountryValidation() {
    let selectedCountry = this.formGroup.controls['country'].value;
    if (selectedCountry) {
      let onboardingCountry = this.onboardingCountries.find(item => item.name == selectedCountry)!;
      this.selectedOnboardingCountry = onboardingCountry;

      let validators1 = onboardingCountry.taxOneType == 'Numeric' ? [Validators.required, Validators.pattern(Pattern.onlyNumber)] : [Validators.required];
      this.fc['taxId1'].setValidators([...validators1, Validators.minLength(onboardingCountry.taxOneLengthMin!), Validators.maxLength(onboardingCountry.taxOneLengthMax!)]);
      this.fc['taxId1'].updateValueAndValidity();

      if (onboardingCountry?.taxTwo) {
        let validators2 = onboardingCountry.taxTwoType == 'Numeric' ? [Validators.required, Validators.pattern(Pattern.onlyNumber)] : [Validators.required];
        this.fc['taxId2'].setValidators([...validators2, Validators.minLength(onboardingCountry.taxTwoLengthMin!), Validators.maxLength(onboardingCountry.taxTwoLengthMax!)]);
        this.fc['taxId2'].updateValueAndValidity();
      } else {
        this.fc['taxId2'].clearValidators();
        this.fc['taxId2'].updateValueAndValidity();
      }
    } else {
      this.selectedOnboardingCountry = undefined;
    }

    this.changeDetectRef.detectChanges();
  }

  viewCompanyDetails(companyUiDto: CompanyUiDto) {
    this.isShowCompanyView = true;
    this.selectedCompanyUiDto = companyUiDto;
    this.onboardingService.setCurrentCompanyUiDto(this.selectedCompanyUiDto);
    this.populateCompanyDetails()
  }

  backToAllCompaniesList() {
    this.isShowCompanyView = false;
    this.selectedCompanyUiDto = undefined;
    this.selectedBillingAddress = undefined;
    this.selectedShippingAddress = undefined;
    this.onboardingService.setCurrentCompanyUiDto(this.selectedCompanyUiDto);
  }

  openCompanyDetail(content: any) {
    this.workflowMode = 'COMPANY';

    if (!this.selectedCompanyUiDto) {
      this.formGroup.reset();

      this.newCompanyCode = 'C' + (this.companiesList.length + 1).toString().padStart(2, "0");
      this.fc['companyCode'].patchValue(this.newCompanyCode);
      this.fc['companyCode'].updateValueAndValidity();

      this.handlePhoneCountryCode(CountryCodeDto.default())
    }

    this.setCountryValidation();

    this.ngbModal.open(content, { size: 'xl', backdrop: 'static', keyboard: false, centered: true })
  }

  // openAddBillingAddress(content: any) {
  //   this.ngbModal.open(content, { size: 'md', backdrop: 'static', keyboard: false, centered: true })
  // }

  // openAddShippingAddress(content: any) {
  //   this.ngbModal.open(content, { size: 'md', backdrop: 'static', keyboard: false, centered: true })
  // }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  openPlantDetail(content: any, companyUiDto: CompanyUiDto) {
    this.workflowMode = 'PLANT';
    this.selectedCompanyUiDto = companyUiDto;

    this.formGroupPlants.reset();
    this.newPlantCode = 'P' + ((this.selectedCompanyUiDto.plantUiDtos ?? []).length + 1).toString().padStart(2, "0");
    this.pc['plantCode'].patchValue(this.newPlantCode);
    this.pc['plantCode'].updateValueAndValidity();

    this.ngbModal.open(content, { size: 'xl', backdrop: 'static', keyboard: false, centered: true })
  }

  openUserDetail(content: any, companyUiDto: CompanyUiDto) {
    this.workflowMode = 'USER';
    this.selectedCompanyUiDto = companyUiDto;

    this.formGroupUser.reset();
    this.ngbModal.open(content, { size: 'lg', backdrop: 'static', keyboard: false, centered: true })
  }

  navigateInternalPage(page: string, companyUiDto?: CompanyUiDto) {
    if (companyUiDto) {
      this.selectedCompanyUiDto = companyUiDto;
      this.onboardingService.setCurrentCompanyUiDto(this.selectedCompanyUiDto);
    }
    this.onNavigateInternalPage.emit(page);
  }

  openMarkerInfo(marker: MapMarker) {
    this.info.open(marker)
  }

  handlePhoneCountryCode(countryCodeDto: CountryCodeDto) {
    this.phoneCountryCode = countryCodeDto;

    let mobileNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + mobileNoLength + "}"

    if (this.workflowMode == 'COMPANY') {
      this.formGroup.controls['phoneNumber'].clearValidators();
      this.formGroup.controls['phoneNumber'].setValidators([Validators.required, Validators.pattern(pattern)]);
      this.formGroup.controls['phoneNumber'].updateValueAndValidity();
    } else if (this.workflowMode == 'PLANT') {
      this.formGroupPlants.controls['phoneNumber'].clearValidators();
      this.formGroupPlants.controls['phoneNumber'].setValidators([Validators.required, Validators.pattern(pattern)]);
      this.formGroupPlants.controls['phoneNumber'].updateValueAndValidity();
    }
  }

  handleFaxCountryCode(countryCodeDto: CountryCodeDto) {
    this.faxCountryCode = countryCodeDto;

    let mobileNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + mobileNoLength + "}"

    if (this.workflowMode == 'COMPANY') {
      this.formGroup.controls['faxNumber'].clearValidators();
      this.formGroup.controls['faxNumber'].setValidators([Validators.pattern(pattern)]);
      this.formGroup.controls['faxNumber'].updateValueAndValidity();
    } else if (this.workflowMode == 'PLANT') {
      this.formGroupPlants.controls['faxNumber'].clearValidators();
      this.formGroupPlants.controls['faxNumber'].setValidators([Validators.pattern(pattern)]);
      this.formGroupPlants.controls['faxNumber'].updateValueAndValidity();
    }
  }

  handleBillingAddressChange(address: Address) {
    this.billingLatitude = address.geometry.location.lat();
    this.billingLongitude = address.geometry.location.lng();

    let city = ApplicationUtils.getAddressByType(address, 'locality');
    if (!city) {
      city = ApplicationUtils.getAddressByType(address, 'neighborhood');
    }
    let state = ApplicationUtils.getAddressByType(address, 'administrative_area_level_1');
    let country = ApplicationUtils.getAddressByType(address, 'country');
    this.billingCountryShortName = ApplicationUtils.getAddressShortNameByType(address, 'country');

    let zip = ApplicationUtils.getAddressByType(address, 'postal_code');
    let addressPart1 = ApplicationUtils.getAddressByType(address, 'street_number');
    let addressPart2 = ApplicationUtils.getAddressByType(address, 'route');

    let addressLine1 = address.formatted_address;
    if (addressPart1 == '' || addressPart2 == '') {
      if (address.formatted_address.includes(',')) {
        addressLine1 = address.formatted_address.split(',')[0];
      }
    } else {
      addressLine1 = addressPart1 + " " + addressPart2;
    }

    if (this.workflowMode == 'COMPANY') {
      this.formGroup.controls['billingAddress'].get('addressLine1')?.patchValue(addressLine1);
      this.formGroup.controls['billingAddress'].get('searchAddress')?.patchValue(address.formatted_address);
      this.formGroup.controls['billingAddress'].get('city')?.patchValue(city);
      this.formGroup.controls['billingAddress'].get('state')?.patchValue(state);
      this.formGroup.controls['billingAddress'].get('country')?.patchValue(country);
      this.formGroup.controls['billingAddress'].get('zipCode')?.patchValue(zip);
      this.formGroup.updateValueAndValidity();
    } else if (this.workflowMode == 'PLANT') {
      this.formGroupPlants.controls['billingAddress'].get('addressLine1')?.patchValue(addressLine1);
      this.formGroupPlants.controls['billingAddress'].get('searchAddress')?.patchValue(address.formatted_address);
      this.formGroupPlants.controls['billingAddress'].get('city')?.patchValue(city);
      this.formGroupPlants.controls['billingAddress'].get('state')?.patchValue(state);
      this.formGroupPlants.controls['billingAddress'].get('country')?.patchValue(country);
      this.formGroupPlants.controls['billingAddress'].get('zipCode')?.patchValue(zip);
      this.formGroupPlants.updateValueAndValidity();
    }

    this.changeDetectRef.detectChanges();
  }

  handleShippingAddressChange(address: Address) {
    this.shippingLatitude = address.geometry.location.lat();
    this.shippingLongitude = address.geometry.location.lng();

    let city = ApplicationUtils.getAddressByType(address, 'locality');
    if (!city) {
      city = ApplicationUtils.getAddressByType(address, 'neighborhood');
    }
    let state = ApplicationUtils.getAddressByType(address, 'administrative_area_level_1');
    let country = ApplicationUtils.getAddressByType(address, 'country');
    this.shippingCountryShortName = ApplicationUtils.getAddressShortNameByType(address, 'country');

    let zip = ApplicationUtils.getAddressByType(address, 'postal_code');
    let addressPart1 = ApplicationUtils.getAddressByType(address, 'street_number');
    let addressPart2 = ApplicationUtils.getAddressByType(address, 'route');

    let addressLine1 = address.formatted_address;
    if (addressPart1 == '' || addressPart2 == '') {
      if (address.formatted_address.includes(',')) {
        addressLine1 = address.formatted_address.split(',')[0];
      }
    } else {
      addressLine1 = addressPart1 + " " + addressPart2;
    }

    if (this.workflowMode == 'COMPANY') {
      this.formGroup.controls['shippingAddress'].get('addressLine1')?.patchValue(addressLine1);
      this.formGroup.controls['shippingAddress'].get('searchAddress')?.patchValue(address.formatted_address);
      this.formGroup.controls['shippingAddress'].get('city')?.patchValue(city);
      this.formGroup.controls['shippingAddress'].get('state')?.patchValue(state);
      this.formGroup.controls['shippingAddress'].get('country')?.patchValue(country);
      this.formGroup.controls['shippingAddress'].get('zipCode')?.patchValue(zip);
      this.formGroup.updateValueAndValidity();
    } else if (this.workflowMode == 'PLANT') {
      this.formGroupPlants.controls['shippingAddress'].get('addressLine1')?.patchValue(addressLine1);
      this.formGroupPlants.controls['shippingAddress'].get('searchAddress')?.patchValue(address.formatted_address);
      this.formGroupPlants.controls['shippingAddress'].get('city')?.patchValue(city);
      this.formGroupPlants.controls['shippingAddress'].get('state')?.patchValue(state);
      this.formGroupPlants.controls['shippingAddress'].get('country')?.patchValue(country);
      this.formGroupPlants.controls['shippingAddress'].get('zipCode')?.patchValue(zip);
      this.formGroupPlants.updateValueAndValidity();
    }
    this.changeDetectRef.detectChanges();
  }

  mergeCompanyUiDto() {
    let formValue = this.formGroup.getRawValue();
    let companyUiDto = new CompanyUiDto();

    if (this.selectedCompanyUiDto) {
      companyUiDto = ApplicationUtils.clone(this.selectedCompanyUiDto) as CompanyUiDto;
    }

    let organizationUiDto = this.onboardingService.getOrganizationUiDto;

    companyUiDto.orgCode = organizationUiDto?.orgCode;
    companyUiDto.companyCode = formValue.companyCode;
    companyUiDto.companyName = formValue.companyName;
    companyUiDto.primaryCompany = formValue.primaryCompany;
    companyUiDto.legalType = formValue.legalType;
    companyUiDto.industrySector = formValue.industrySector;
    companyUiDto.region = formValue.region;
    companyUiDto.country = formValue.country;
    companyUiDto.phoneCountryCode = this.phoneCountryCode;
    companyUiDto.phoneNumber = formValue.phoneNumber;
    companyUiDto.faxCountryCode = this.faxCountryCode;
    companyUiDto.faxNumber = formValue.faxNumber;
    companyUiDto.taxId1 = formValue.taxId1;
    companyUiDto.taxId2 = formValue.taxId2;
    companyUiDto.branch = formValue.branch == 'true' ? true : false;
    companyUiDto.timeZone = formValue.timeZone;
    companyUiDto.fiscalYrStart = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, formValue.fiscalYrStart)!;
    companyUiDto.fiscalYrEnd = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, formValue.fiscalYrEnd)!;

    if (!companyUiDto.shippingAddress) {
      companyUiDto.shippingAddress = [];
    }

    if (!companyUiDto.billingAddress) {
      companyUiDto.billingAddress = [];
    }

    let billingAddress = new AddressDto();
    billingAddress.addressType = formValue.billingAddress.addressType;
    billingAddress.searchAddress = formValue.billingAddress.searchAddress;
    billingAddress.addressLine1 = formValue.billingAddress.addressLine1;
    billingAddress.addressLine2 = formValue.billingAddress.addressLine2;
    billingAddress.city = formValue.billingAddress.city;
    billingAddress.state = formValue.billingAddress.state;
    billingAddress.country = formValue.billingAddress.country;
    billingAddress.zipCode = formValue.billingAddress.zipCode;
    billingAddress.mobileNo = formValue.billingAddress.mobileNo;
    billingAddress.latitude = this.billingLatitude?.toString();
    billingAddress.longitude = this.billingLongitude?.toString();
    billingAddress.countryShortName = this.billingCountryShortName;

    let shippingAddress = new AddressDto();
    shippingAddress.addressType = formValue.shippingAddress.addressType;
    shippingAddress.searchAddress = formValue.shippingAddress.searchAddress;
    shippingAddress.addressLine1 = formValue.shippingAddress.addressLine1;
    shippingAddress.addressLine2 = formValue.shippingAddress.addressLine2;
    shippingAddress.city = formValue.shippingAddress.city;
    shippingAddress.state = formValue.shippingAddress.state;
    shippingAddress.country = formValue.shippingAddress.country;
    shippingAddress.zipCode = formValue.shippingAddress.zipCode;
    shippingAddress.mobileNo = formValue.shippingAddress.mobileNo;
    shippingAddress.latitude = this.shippingLatitude?.toString();
    shippingAddress.longitude = this.shippingLongitude?.toString();
    shippingAddress.countryShortName = this.shippingCountryShortName;

    if (companyUiDto.billingAddress.length > 0) {
      billingAddress.id = companyUiDto.billingAddress[0].id;
      shippingAddress.id = companyUiDto.shippingAddress[0].id;

      Object.assign(companyUiDto.billingAddress[0], billingAddress);
      Object.assign(companyUiDto.shippingAddress[0], shippingAddress);
    } else {
      billingAddress.id = UUID.UUID().toString();
      shippingAddress.id = UUID.UUID().toString();

      companyUiDto.shippingAddress.push(shippingAddress);
      companyUiDto.billingAddress.push(billingAddress);
    }

    return companyUiDto;
  }

  saveCompany() {
    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    let companyUiDto = this.mergeCompanyUiDto()
    this.isLoading = true;

    this.onboardingService.saveCompanyUiDto(companyUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let newCompanyUiDto = apiResponseDto.data as CompanyUiDto;
          this.onboardingService.updateCompanyUiDto(newCompanyUiDto)

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving Company");
        this.isLoading = false;
      }
    });
  }

  mergePlantUiDto() {
    let formValue = this.formGroupPlants.getRawValue();

    let organizationUiDto = this.onboardingService.getOrganizationUiDto;

    let plantUiDto = new PlantUiDto();
    plantUiDto.orgCode = organizationUiDto?.orgCode;
    plantUiDto.companyCode = this.selectedCompanyUiDto?.companyCode;
    plantUiDto.plantName = formValue.plantName;
    plantUiDto.plantCode = formValue.plantCode;
    plantUiDto.accountTitle = formValue.accountTitle;
    plantUiDto.accNumber = formValue.accNumber;
    plantUiDto.accHolderName = formValue.accHolderName;
    plantUiDto.accType = formValue.accType;
    plantUiDto.accCode = formValue.accCode;
    plantUiDto.swift = formValue.swift;
    plantUiDto.iban = formValue.iban;

    if (!plantUiDto.shippingAddress) {
      plantUiDto.shippingAddress = [];
    }

    if (!plantUiDto.billingAddress) {
      plantUiDto.billingAddress = [];
    }

    let billingAddress = new AddressDto();
    billingAddress.id = UUID.UUID().toString();
    billingAddress.addressType = formValue.billingAddress.addressType;
    billingAddress.searchAddress = formValue.billingAddress.searchAddress;
    billingAddress.addressLine1 = formValue.billingAddress.addressLine1;
    billingAddress.addressLine2 = formValue.billingAddress.addressLine2;
    billingAddress.city = formValue.billingAddress.city;
    billingAddress.state = formValue.billingAddress.state;
    billingAddress.country = formValue.billingAddress.country;
    billingAddress.zipCode = formValue.billingAddress.zipCode;
    billingAddress.mobileNo = formValue.billingAddress.mobileNo;
    billingAddress.latitude = this.billingLatitude?.toString();
    billingAddress.longitude = this.billingLongitude?.toString();
    billingAddress.countryShortName = this.billingCountryShortName;

    let shippingAddress = new AddressDto();
    shippingAddress.id = UUID.UUID().toString();
    shippingAddress.addressType = formValue.shippingAddress.addressType;
    shippingAddress.searchAddress = formValue.shippingAddress.searchAddress;
    shippingAddress.addressLine1 = formValue.shippingAddress.addressLine1;
    shippingAddress.addressLine2 = formValue.shippingAddress.addressLine2;
    shippingAddress.city = formValue.shippingAddress.city;
    shippingAddress.state = formValue.shippingAddress.state;
    shippingAddress.country = formValue.shippingAddress.country;
    shippingAddress.zipCode = formValue.shippingAddress.zipCode;
    shippingAddress.mobileNo = formValue.shippingAddress.mobileNo;
    shippingAddress.latitude = this.shippingLatitude?.toString();
    shippingAddress.longitude = this.shippingLongitude?.toString();
    shippingAddress.countryShortName = this.shippingCountryShortName;

    plantUiDto.shippingAddress.push(shippingAddress);
    plantUiDto.billingAddress.push(billingAddress);

    return plantUiDto;
  }

  saveOnboardingPlants() {
    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);

    if (this.formGroupPlants.invalid) {
      this.formGroupPlants.markAllAsTouched();
      return;
    }

    let plantUiDto = this.mergePlantUiDto()
    this.isLoading = true;

    this.onboardingService.savePlantUiDto(plantUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let newPlantUiDto = apiResponseDto.data as PlantUiDto;
          this.onboardingService.updatePlantUiDto(newPlantUiDto)

          this.loadCompanyUiDto();

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving Plant");
        this.isLoading = false;
      }
    });
  }

  mergeUserEntityInfoDto() {
    let formValue = this.formGroupUser.getRawValue();

    let organizationUiDto = this.onboardingService.getOrganizationUiDto;

    let userEntityInfoDto = new UserEntityInfoDto();
    userEntityInfoDto.orgCode = organizationUiDto?.orgCode;
    userEntityInfoDto.companyCode = this.selectedCompanyUiDto?.companyCode;
    userEntityInfoDto.domainName = organizationUiDto?.adminDomainName;
    userEntityInfoDto.firstName = formValue.firstName;
    userEntityInfoDto.lastName = formValue.lastName;
    userEntityInfoDto.primaryEmailId = formValue.primaryEmailId;
    userEntityInfoDto.designation = formValue.designation;
    userEntityInfoDto.empCode = formValue.empCode;
    userEntityInfoDto.plantCodes = formValue.plantCodes;

    return userEntityInfoDto;
  }

  saveUser() {
    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);

    if (this.formGroupUser.invalid) {
      this.formGroupUser.markAllAsTouched();
      return;
    }

    let userEntityInfoDto = this.mergeUserEntityInfoDto()
    this.isLoading = true;

    this.onboardingService.saveUserEntityInfoDto(userEntityInfoDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let newUserEntityInfoDto = apiResponseDto.data as UserEntityInfoDto;
          this.onboardingService.updateUserEntityInfoDto(newUserEntityInfoDto)

          this.loadCompanyUiDto();

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving User");
        this.isLoading = false;
      }
    });
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

  getFormattedFinancialYear(date: string) {
    let convertedDate = ApplicationUtils.convertedOnlyDate(date);
    return this.datePipe.transform(convertedDate, 'dd MMM');
  }

  ngOnDestroy(): void {
    if (this.companiesSubscription$) {
      this.companiesSubscription$.unsubscribe();
    }
    if (this.masterTimeZoneSubscription$) {
      this.masterTimeZoneSubscription$.unsubscribe();
    }
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }
  }

}
