<div class="container my-5">
  <div class="col-12">
    <h5 class="heading-h5">
      SPEND ANALYTICS
    </h5>
  </div>
  <div class="col-12 my-3">
    <div class="row">
      <div class="col-lg-2 text-center px-0">
        <div class="div-class">
          Spend in View
        </div>
        <div class="fs-5">
          {{organizationUiDto?.currencyDto?.symbol}}80M
        </div>
      </div>
      <div class="col-lg-2 text-center px-0">
        <div class="div-class">
          Categories
        </div>
        <div class="fs-5">
          81
        </div>
      </div>
      <div class="col-lg-2 text-center px-0">
        <div class="div-class">
          PO Compliance
        </div>
        <div class="fs-5">
         95%
        </div>
      </div>
      <div class="col-lg-2 text-center px-0">
        <div class="div-class">
          Suppliers
        </div>
        <div class="fs-5">
          11K
        </div>
      </div>
      <div class="col-lg-2 text-center px-0">
        <div class="div-class">
          Item Count
        </div>
        <div class="fs-5">
          235K
        </div>
      </div>
      <div class="col-lg-2 text-center px-0">
        <div class="div-class">
          Avg Payment Terms
        </div>
        <div class="fs-5">
          123K
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-3">
    <div class="row">
    <div class="col-lg-7 ">
      <div class="row">
        <div class="col-lg-6 mb-2">
          <div class="card bg-white p-2">
            <h5 class="heading-h5">Spend By Category Type</h5>
            <div>
              <div echarts [options]="chartOptionCategory" class="chart"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-2">
          <div class="card bg-white p-2">
            <h5 class="heading-h5">Spend By Plant</h5>
            <div>
              <div echarts [options]="chartOptionGeography" class="chart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="col-lg-5 mb-2">
        <div class="">
          <h5 class="heading-h5">Recent Saving Recipes</h5>
          <div>
            <h6 class="heading-h6 text-dark">Last 5 Recipes</h6>
          </div>
          <div class="overflow-card">
            <div class="card mb-2 bg-white p-2">
              <div class="row">
                <div class="col-md-8">
                  <b>BRAKE DISK FOR CAMPER</b>
                </div>
                <div class="col-md-4 text-md-end">
                7/4/24
                </div>
                <div class="col-md-4  mt-2">
                  Addressable Spend
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4 text-md-center mt-2">
                  Estimated Saving
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4 mt-2  text-md-end">
                  Category
                  <div>
                    <b>
                      MRO
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-2 bg-white p-2">
              <div class="row">
                <div class="col-md-8">
                  <b>SENSOR NITROGEN OXIDE-A054H971-T.LOADER</b>
                </div>
                <div class="col-md-4 text-md-end">
                7/6/24
                </div>
                <div class="col-md-4  mt-2">
                  Addressable Spend
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4  text-md-center mt-2">
                  Estimated Saving
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4 mt-2  text-md-end">
                  Category
                  <div>
                    <b>
                    MRO
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-2 bg-white p-2">
              <div class="row">
                <div class="col-md-8">
                  <b>CLUTCH CYLINDER-T.HYVA</b>
                </div>
                <div class="col-md-4 text-md-end">
                8/6/24
                </div>
                <div class="col-md-4  mt-2">
                  Addressable Spend
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4  text-md-center mt-2">
                  Estimated Saving
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4 mt-2  text-md-end">
                  Category
                  <div>
                    <b>
                      MRO
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 mt-3">
        <div class="col-12 mt-3">
          <h5 class="heading-h5">
            SAVING RECIPES
          </h5>
        </div>
        <div class="card bg-white p-2 mb-2">
          <h5 class="heading-h5">Set Your Saving Goal</h5>
          <div class="">
            <div echarts [options]="chartOptionSetGoal" class="chart"></div>
          </div>
        </div>
        
          <div class="card bg-white p-2">
            <h5 class="heading-h5">Saving By Category Type</h5>
            <div>
              <div echarts [options]="chartOptionCategory" class="chart"></div>
            </div>
          </div>
      </div>
      <div class="col-lg-5 mb-2 mt-3">
        <div class="">
          <h5 class="heading-h5">Recent Saving Recipes</h5>
          <div>
            <h6 class="heading-h6 text-dark">Last 5 Recipes</h6>
          </div>
          <div class="scroll-card-multilot">
            <div class="card mb-2 bg-white p-2">
              <div class="row">
                <div class="col-md-8">
                  <b>CYLINDER HEAD GASKET -3977063 -JCB 432ZX</b>
                </div>
                <div class="col-md-4 text-md-end">
                7/4/24
                </div>
                <div class="col-md-4  mt-2">
                  Addressable Spend
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4  text-md-center mt-2">
                  Estimated Saving
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4 mt-2  text-md-end">
                  Category
                  <div>
                    <b>
                      MRO
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-2 bg-white p-2">
              <div class="row">
                <div class="col-md-8">
                  <b>ENGINE GASKET UPPER-4089649   -JCB 432ZX</b>
                </div>
                <div class="col-md-4 text-md-end">
                7/4/24
                </div>
                <div class="col-md-4  mt-2">
                  Addressable Spend
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4  text-md-center mt-2">
                  Estimated Saving
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4 mt-2  text-md-end">
                  Category
                  <div>
                    <b>
                      MRO
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-2 bg-white p-2">
              <div class="row">
                <div class="col-md-8">
                  <b>HOSE ASSY 3/8'XM16 - 629/21600-JCB 432ZX</b>
                </div>
                <div class="col-md-4 text-md-end">
                7/6/24
                </div>
                <div class="col-md-4  mt-2">
                  Addressable Spend
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4  text-md-center mt-2">
                  Estimated Saving
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4 mt-2  text-md-end">
                  Category
                  <div>
                    <b>
                    MRO
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-2 bg-white p-2">
              <div class="row">
                <div class="col-md-8">
                  <b>COVER RUBBER -335/C8487-HM980HD</b>
                </div>
                <div class="col-md-4 text-md-end">
                7/4/24
                </div>
                <div class="col-md-4  mt-2">
                  Addressable Spend
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4  text-md-center mt-2">
                  Estimated Saving
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4 mt-2  text-md-end">
                  Category
                  <div>
                    <b>
                      MRO
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-2 bg-white p-2">
              <div class="row">
                <div class="col-md-8">
                  <b>DIAPHRAGM - 550/43402 -JCB 432ZX</b>
                </div>
                <div class="col-md-4 text-md-end">
                7/4/24
                </div>
                <div class="col-md-4  mt-2">
                  Addressable Spend
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4  text-md-center mt-2">
                  Estimated Saving
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4 mt-2  text-md-end">
                  Category
                  <div>
                    <b>
                      MRO
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-2 bg-white p-2">
              <div class="row">
                <div class="col-md-8">
                  <b>M.S FLATE-20X6</b>
                </div>
                <div class="col-md-4 text-md-end">
                8/6/24
                </div>
                <div class="col-md-4  mt-2">
                  Addressable Spend
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4  text-md-center mt-2">
                  Estimated Saving
                  <div>
                    <b>{{organizationUiDto?.currencyDto?.symbol}}10M</b>
                  </div>
                </div>
                <div class="col-md-4 mt-2  text-md-end">
                  Category
                  <div>
                    <b>
                      MRO
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
