<div>
  <div class="row ">
    <div class="col-md-6 mb-2 mt-2" *ngIf="!fromPostAuction">
      <h5 class="heading-h5">BIDDING</h5>
    </div>
    <hr class="hr mb-3 mt-1" *ngIf="!fromPostAuction" />
    <div class="col-12">
      <div class="row mx-0 align-items-center justify-content-center" *ngIf="!auctionEntityDto?.singleLot && !fromPostAuction">
        <app-lot-sequence-carousel [isShowValidation]="false" [isShowActiveLotsOnly]="true" (selectedLotEvent)="selectedLotEvent($event)"></app-lot-sequence-carousel>

        <!-- <div class="col-md-1 col-2 p-0 text-md-end">
          <button class="scroll-left-icon-bidder" (click)="previousLot()">
            <img src="assets/icons/leftarrow2_dark.svg" class=" c-pointer svg-icon-class">
          </button>
        </div>
        <div class="col-8 col-md-9 mt-4 ms-md-5 ">

          <swiper #swiperLots class="lot-swiper" [virtual]="true" [config]="swiperConfig" (slideChange)="onSlideChange()">
            <ng-template swiperSlide *ngFor="let item of lotEntityDtoList; index as i">
              <button class="btn px-3 py-2 btn-sm me-2 mt-2"
              [ngClass]="selectedLot?.lotId == item.lotId ? 'selected-button' : 'secondary-border'"
                (click)="getLotBidsHistoryData(item, i)">
                <span class="span-class">{{item.lotSequence}}</span>
              </button>
            </ng-template>
          </swiper>
        </div>
        <div class="col-md-1 col-2 p-0 text-md-end">
          <button class="scroll-right-icon-bidder " (click)="nextLot()">
            <img src="assets/icons/rightarrow2_dark.svg" class=" c-pointer svg-icon-class">
          </button>
        </div> -->
      </div>
       <div class="row justify-content-between">

        <div class="col-md-4 mb-2 mt-3">
          <h2 class="overflow-class" ngbTooltip="{{selectedLot?.title}}" container="body" placement="top-left" *ngIf="!fromPostAuction">
            #{{selectedLot?.lotSequence}} {{truncateString(selectedLot?.title, 90)}}
          </h2>
          <h5 class="heading-h5" *ngIf="fromPostAuction">BIDDING</h5>
        </div>
        <div class="col-md-auto text-end mt-3">
          <div class="row">
            <div class="col-auto text-end mb-2" *ngIf="!auctionEntityDto?.singleLot && !fromPostAuction">
              <div ngbDropdown>
                <button ngbDropdownToggle class="btn btn-new-class" type="button" ngbTooltip="{{selectedLot?.title}}"
                  container="body">
                  <img src="assets/icons/sort_dark.svg" class="svg-icon-class c-pointer">
                  #{{selectedLot?.lotSequence}} {{truncateString(selectedLot?.title, 50)}}
                </button>
                <ul ngbDropdownMenu class="mt-4 scroll-area-lg">
                  <li *ngFor="let item of lotEntityDtoList; index as i" (click)="getLotBidsHistoryData(item)">
                    <a ngbDropdownItem>{{item?.lotSequence}} - {{item?.title}}</a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div class="col-auto text-end">
              <div class="mb-2  input-group">
                <input #search placeholder="Search" type="text" placeholder="Search"
                  (keyup)="searchBidsHistory(search.value)"
                  class="form-control border-end-0 rounded-0 border bg-white" />
                <span class="input-group-append">
                  <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                    <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
                  </button>
                </span>
              </div>
            </div> -->
          </div>
        </div>
       </div>

    </div>
    <hr class="hr mb-3 mt-2" *ngIf="!fromPostAuction" />
    <div class="col-12">
      <div class="row" *ngIf="!fromPostAuction">
        <!-- Highest Bid -->
        <div class="mb-2" [ngClass]="selectedLot?.buyItNowEnable ? 'col-md-4' : 'col-md-6'">
          <div class="border rounded bg-white">
            <header class="p-2">
              <div class="row ">
                <div class="col-12 mt-2">
                  <h5 class="heading-h5"> {{this.reverseAuction ? 'LOW BID' : 'HIGH BID'}}</h5>
                </div>
              </div>
            </header>
            <hr class="hr" />
            <div class=" font-weight-normal p-2">
              {{this.reverseAuction ? 'Current lowest Bid for this Lot' : 'Current Highest Bid for this Lot'}}
            </div>
            <hr class="hr" />
            <div class="height-change">
            <div *ngIf="!(bidderHistoryWrapper$|async)?.highestBidBidderUserId">
              <div class="row justify-content-center">
                <div class="col-auto my-3">
                  <h5 class="heading-new text-dark">No Bid Available</h5>
                </div>
              </div>
            </div>

            <div class="col-12 mb-3 py-1" *ngIf="(bidderHistoryWrapper$|async)?.highestBidBidderUserId">

              <div class="row justify-content-center">
                <div class="col-2 p-0 mt-2">
                  <img
                    src="/downloadLandingBlob?fileId={{(bidderHistoryWrapper$|async)?.highestBidBidderProfileDto!.fileId}}"
                    alt="" class="profile-image-md"
                    *ngIf="(bidderHistoryWrapper$|async)?.highestBidBidderProfileDto != null ">
                  <img src="assets/images/avatar05.jpg" alt="" class="profile-image-md"
                    *ngIf="(bidderHistoryWrapper$|async)?.highestBidBidderProfileDto == null ">
                </div>
                <div class="col-md-6 col-9 p-0">
                  <div class="">
                    <h5 class="heding-h4">
                      {{(bidderHistoryWrapper$|async)?.highestBidBidderCompanyName}}</h5>
                  </div>
                  <div>
                    <b
                      class="b-class">{{getFormattedPrice(auctionEntityDto?.currency!, (bidderHistoryWrapper$|async)?.highestBidPrice)}}</b>
                  </div>
                  <div class="div-class">
                    {{getDisplayDate((bidderHistoryWrapper$|async)?.highestBidDate!,
                    (bidderHistoryWrapper$|async)?.highestBidTime!)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <!-- Highest Proxy Bid -->
        <div class="mb-2" [ngClass]="selectedLot?.buyItNowEnable ? 'col-md-4' : 'col-md-6'">
          <div class="border rounded bg-white">
            <header class="p-2">
              <div class="row ">
                <div class="col-12 mt-2">
                  <h5 class="heading-h5"> CURRENT PROXY BID</h5>
                </div>
              </div>
            </header>
            <hr class="hr" />
            <div class=" font-weight-normal p-2">
              Current Highest Proxy Bid for this Lot
            </div>
            <hr class="hr" />
           <div class="height-change">
            <div *ngIf="!(bidderHistoryWrapper$|async)?.highestMaxBidPrice">
              <div class="row justify-content-center">
                <div class="col-auto my-3">
                  <h5 class=" heading-new text-dark">No Proxy Bid Available</h5>
                </div>
              </div>
            </div>

            <div class="col-12 mb-3 py-1" *ngIf="(bidderHistoryWrapper$|async)?.highestMaxBidPrice">

              <div class="row justify-content-center">
                <div class="col-2 p-0 mt-2">
                  <img
                    src="/downloadLandingBlob?fileId={{(bidderHistoryWrapper$|async)?.highestMaxBidBidderProfileDto!.fileId}}"
                    alt="" class="profile-image-md"
                    *ngIf="(bidderHistoryWrapper$|async)?.highestMaxBidBidderProfileDto != null ">
                  <img src="assets/images/avatar05.jpg" alt="" class="profile-image-md"
                    *ngIf="(bidderHistoryWrapper$|async)?.highestMaxBidBidderProfileDto == null ">
                </div>
                <div class="col-md-6 col-9 p-0">
                  <div class="">
                    <h5 class="heding-h4">
                      {{(bidderHistoryWrapper$|async)?.highestMaxBidBidderCompanyName}}
                    </h5>
                  </div>
                  <div>
                    <b
                      class="b-class">{{getFormattedPrice(auctionEntityDto?.currency!, (bidderHistoryWrapper$|async)?.highestMaxBidPrice)}}</b>
                  </div>
                  <div class="div-class">
                    {{getDisplayDate((bidderHistoryWrapper$|async)?.highestMaxBidDate!,
                      (bidderHistoryWrapper$|async)?.highestMaxBidTime!)}}
                  </div>
                </div>
              </div>
            </div>
           </div>

          </div>
        </div>

        <!-- Highest Current Offer -->
        <div class="mb-2 col-md-4" *ngIf="selectedLot?.buyItNowEnable">
          <div class="border rounded bg-white" [ngClass]="{'border-success border-2' : isMaxOfferAlloted()}">
            <header class="p-2">
              <div class="row ">
                <div class="col-12 mt-2">
                  <h5 class="heading-h5"> PROXY OFFER</h5>
                </div>
              </div>
            </header>
            <hr class="hr" />
            <div class=" font-weight-normal p-2">
              Current Highest Offer for this Lot
            </div>
            <hr class="hr" />
            <div *ngIf="!currentOfferEntityDto?.bidderName">
              <div class="row justify-content-center">
                <div class="col-auto my-3">
                  <h5 class=" heading-new text-dark">No Offers Placed</h5>
                </div>
              </div>
            </div>
            <div class="col-12 mb-3 py-1" *ngIf="currentOfferEntityDto?.bidderName">
              <div class="row justify-content-center">
                <div class="col-2 p-0 mt-2">
                  <img src="/downloadLandingBlob?fileId={{currentOfferEntityDto?.profileImage?.fileId}}" alt=""
                    class="profile-image-large" *ngIf="currentOfferEntityDto?.profileImage != null">
                  <img src="assets/images/avatar05.jpg" alt="" class="profile-image-large"
                    *ngIf="currentOfferEntityDto?.profileImage == null">
                </div>
                <div class="col-md-6 col-9 p-0">
                  <div class="">
                    <h5 class="heding-h4">{{currentOfferEntityDto?.bidderName}}</h5>
                  </div>
                  <div>
                    <b class="b-class">{{getFormattedPrice(auctionEntityDto?.currency!,
                      currentOfferEntityDto?.offerPrice)}}</b>
                  </div>
                  <div class="div-class">
                    {{getDisplayDateFromISO(currentOfferEntityDto?.makeOfferTimeStamp)}}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class = "my-3">
      <ngb-accordion   [activeIds]="auctionEntityDto?.status == 'LIVE_WAIT' ? [] : ['toggle-1']">
        <ngb-panel id="toggle-1" title="Bids Details" >
          <ng-template ngbPanelContent>
            <div class="col-12">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <h5 class="heading-h5 mb-0">BIDS</h5>
                </div>
                <div class="col-md-8 text-end mt-3 mt-md-0">
                  <div class="row float-end pe-md-2 align-items-center">
                  <div class="col-auto text-end me-3">
                    <span class="span-class">All Bids</span>
                    <span class="form-switch  mx-2">
                      <input class="form-check-input c-pointer" type="checkbox" id="check1" [checked]="showMaxBid"
                        (change)="changeShowMaxBid()" /> </span>
                    <span class="span-class">Proxy Bids</span>
                  </div>
                    <div class="col-auto text-end mb-1 mt-2 mt-md-0 ps-3 ps-md-0 ">
                      <button class="btn btn-new-class me-4" (click)="refreshData()">
                        <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class">
                      </button>
                      <button class="btn btn-new-class  px-0 " (click)="toggleChartView()" *ngIf="(bidderHistoryWrapper$|async)?.userAuctionBidsHistoryDtos!.length > 0">
                        <img src="assets/icons/chart_dark.svg" class=" c-pointer svg-icon-class" *ngIf="!isShowChartView">
                        <img src="assets/icons/list_dark.svg" class=" c-pointer svg-icon-class" *ngIf="isShowChartView">
                      </button>
                      <button class="btn btn-new-class pe-0 mx-3" (click)="getBlockchainTransactions()" *ngIf="deploymentConfigurationDto?.enableBlockchain && auctionEntityDto?.enableBlockchain">
                        <img src="assets/icons/blockchain.png" class=" c-pointer svg-icon-class" *ngIf="!isShowBlockchainView">
                        <img src="assets/icons/list_dark.svg" class=" c-pointer svg-icon-class" *ngIf="isShowBlockchainView">
                      </button>
                    </div>
                    <div class="col-auto text-end mt-2 mt-md-0" *ngIf="!isMaskBidderDetails">
                      <span><button class="btn btn-new-class  btn-sm mb-1"
                        *ngIf="(bidderHistoryWrapper$|async)?.userAuctionBidsHistoryDtos!.length > 0"
                        (click)="downloadBidHistoryData()">
                        <img src="assets/icons/filecsv_dark.svg" class=" c-pointer svg-icon-class"> <span
                          class="span-class-live">EXPORT</span>
                        <span class="spinner-border spinner-border-sm ms-2 loader-spinner" role="status" aria-hidden="true"
                          *ngIf="isExportLoading"></span>
                      </button></span>
                    </div>
                    <div class="col-auto text-end mt-2 mt-md-1">
                      <div class="mb-2  input-group">
                        <input #search placeholder="Search" type="text"  [formControl]="ctrlBidHistorySearch"
                          class="form-control border-end-0 rounded-0 border bg-white" />
                        <span class="input-group-append">
                          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="hr mt-2">
              <div class="col-12 p-2" *ngIf="(bidderHistoryWrapper$|async)?.userAuctionBidsHistoryDtos!.length > 0 && !isShowChartView && !isShowBlockchainView">
                <div class="m-3 table-responsive">
                  <table class="table table-bordered medium-font  mb-0">
                    <thead class="table-dark">
                      <tr>
                        <th class=" "><span>Bid</span></th>
                        <th class=" "> <span>Proxy Bid</span></th>
                        <th class=" "><span>Bid Type</span></th>
                        <th class=" " *ngIf="isShowBiddersRank">
                          <span>Rank</span>
                        </th>
                        <th class=" "> <span>Name</span></th>
                        <th class=" "><span>Company Name</span></th>
                        <th class=" "><span>Paddle No</span></th>
                        <th class=" "> <span>Bid On</span></th>
                        <th class=" "> <span>Action</span></th>
                      </tr>

                    </thead>
                    <tbody>
                      <tr *ngIf="isLoading">
                        <td colspan="8">
                          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
                            <div class="e-spinner" *ngIf="isLoading"></div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngFor="let bidHistory of _filteredUserBidsHistory$ | async; index as j" [ngClass]="{'border border-success border-2': bidHistory?.lotAllocated}">
                        <td class="text-center">
                          <div class="row">
                            <div class="col-5 d-md-block d-none">
                              <img src="assets/images/avatar05.jpg" [alt]="" class="profile-image-sm mt-0"
                                *ngIf="!bidHistory?.profileImage ">
                              <img src="/downloadLandingBlob?fileId={{bidHistory?.profileImage!.fileId}}"
                                [alt]="bidHistory?.name" class="profile-image-md mt-0" *ngIf="bidHistory?.profileImage ">
                            </div>
                            <div class="col-7 text-end">
                              <strong
                                [ngStyle]="{'text-decoration' : bidHistory.activeBid == false ? 'line-through' : 'none', 'text-decoration-color': bidHistory.activeBid == false ? 'red': 'black'}"
                                *ngIf="bidHistory.bidType!.toLowerCase() != 'MAX BID'.toLowerCase();else elseBlock">
                                {{getFormattedPrice(bidHistory?.currency!,bidHistory?.bidPrice)}}
                              </strong>
                            </div>
                          </div>
                        </td>
                        <td>
                          <strong *ngIf="bidHistory.bidType!.toLowerCase() == 'MAX BID'.toLowerCase();else elseBlock">
                            {{getFormattedPrice(bidHistory?.currency!, bidHistory?.maxBidPrice)}}
                          </strong>
                        </td>
                        <td class="">
                          <strong>
                            {{bidHistory?.bidType == "Max Bid"  ? "Proxy Bid" : bidHistory?.bidType }}
                          </strong>
                        </td>
                        <td class="font-weight-normal" *ngIf="isShowBiddersRank">
                          {{bidHistory?.rank! > 0 ? bidHistory?.rank : ''}}
                        </td>
                        <td class="font-weight-normal">{{bidHistory?.name}}</td>

                        <td class="font-weight-normal">
                          {{ bidHistory?.companyName ? bidHistory?.companyName : '-'}}
                          <!-- {{bidHistory?.companyName}} -->
                        </td>
                        <td class="font-weight-normal">#{{bidHistory?.paddleNo}}</td>
                        <td class="font-weight-normal">
                            <div class="col-8 ">
                              {{getDisplayDate(bidHistory?.bidDate, bidHistory?.bidTime)}}
                            </div>
                           </td>
                           <td>
                            <div class=" dropdown" ngbDropdown container="body" placement="bottom-right" *ngIf="!isMaskBidderDetails">
                              <span ngbDropdownToggle data-bs-toggle="dropdown">
                                <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class">
                              </span>
                              <ul class="dropdown-menu shadow  mt-4" ngbDropdownMenu >
                                <li  *ngIf="isHighestBidEligibleToReject(bidHistory)">
                                  <a class="dropdown-item c-pointer"
                                    (click)="openRejectBidModal(bidHistory, rejectBidTemp)" ngbDropdownItem>
                                    REJECT BID
                                  </a>
                                  <hr class="dropdown-divider " >
                                </li>

                                <li >
                                  <a class="dropdown-item c-pointer" (click)="openBidderDetailsModal(bidHistory)"  ngbDropdownItem>
                                    BIDDER DETAILS
                                  </a>
                                  <hr class="dropdown-divider " >
                                </li>

                                <li class="c-pointer" >
                                  <a class="dropdown-item c-pointer" (click)="openShowBidderDetailsModal(showbidDetails, bidHistory)"  ngbDropdownItem>
                                    BID DETAILS
                                  </a>
                                  <hr class="dropdown-divider " >
                                </li>

                                <li *ngIf="isAllowToAllocate(bidHistory, j)" class="c-pointer">
                                  <a class="dropdown-it
                                  em c-pointer" (click)="openLotAllocatedConfirmationModal(confirmationLotAllocate, bidHistory)" ngbDropdownItem>
                                  AWARD
                                  </a>
                                  <hr class="dropdown-divider " >
                                </li>

                                <li *ngIf="bidHistory?.lotAllocated && bidHistory.activeBid " class="c-pointer">
                                  <a class="dropdown-it
                                  em c-pointer" (click)="openLotAllocatedConfirmationModal(confirmationLotAllocate, bidHistory)" ngbDropdownItem>
                                  REVERT
                                  </a>
                                  <hr class="dropdown-divider " >
                                </li>
                                <li>

                                <li *ngIf="bidHistory?.lotAllocated && bidHistory.activeBid" class="c-pointer">
                                  <button  ngbDropdownItem (click)="openAllocationDetailsModal(allocationDetails, bidHistory)"
                                  >AWARD DETAILS</button>
                                  <hr class="dropdown-divider " >
                                </li>



                                <li class="c-pointer" *ngIf="deploymentConfigurationDto?.enableBlockchain && auctionEntityDto?.enableBlockchain && bidHistory?.blockHash">
                                  <button  ngbDropdownItem (click)="openBlockChainDetailsModal(bidHistory?.blockHash!)"
                                  >BLOCK CHAIN DETAILS</button>
                                  <hr class="dropdown-divider " >
                                </li>

                                <li class="c-pointer" *ngIf="bidHistory?.lotAllocated && bidHistory.activeBid">
                                  <button  ngbDropdownItem (click)="openBiddersAwardedLots(bidHistory?.userId!)"
                                  >GENERATE INVOICE</button>
                                  <hr class="dropdown-divider " >
                                </li>

                                <li class="c-pointer" *ngIf="bidHistory?.lotAllocated && bidHistory.activeBid">
                                  <button  ngbDropdownItem (click)="openGeneratedInvoiceData(bidHistory?.userId!)"
                                  >GENERATED INVOICE</button>
                                </li>
                              </ul>
                            </div>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12 mt-2" *ngIf="(_filteredUserBidsHistory$ | async)!.length > 0">
                  <div class="row justify-content-between">
                    <div class="col-md-6 mb-2">
                      <div class="row">
                        <div class="col-md-4 col-sm-5 col-6 pe-0">
                          <span class="span-class">Show rows per page </span>
                        </div>
                        <div class="col-6 ps-0">
                          <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                            <span ngbDropdownToggle data-bs-toggle="dropdown">
                              <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
                            </span>
                            <ul ngbDropdownMenu class="text-center">
                              <li ngbDropdownItem (click)="changePageSizeOfBidderHistory(50)">
                                50
                              </li>
                              <hr class="hr" />
                              <li ngbDropdownItem (click)="changePageSizeOfBidderHistory(100)">
                                100
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <ngb-pagination [collectionSize]="_filteredUserBidsHistory.length"
                        [(page)]="page" [pageSize]="pageSize" (pageChange)="paginateBidderHistoryList()">
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 p-2" *ngIf="isShowChartView">
                <div echarts [options]="chartOption" class="demo-chart" *ngIf="chartOption"></div>
              </div>

              <!-- Blockchain View -->
              <div class="col-12 p-2" *ngIf="isShowBlockchainView">
                <ng-container *ngTemplateOutlet="blockchainTransactionsView"></ng-container>
              </div>

              <div class="col-12"
                *ngIf="!isLoading && !((bidderHistoryWrapper$|async)?.userAuctionBidsHistoryDtos!.length > 0)">
                <div class="row justify-content-center">
                  <div class="col-auto my-3">
                    <h3 class="heading-new text-dark">No Bids Available</h3>
                  </div>
                </div>
              </div>

            </div>
          </ng-template>
        </ngb-panel>
        </ngb-accordion>
      </div>

        <ngb-accordion  [activeIds]="auctionEntityDto?.status == 'LIVE_WAIT' ? ['toggle-2'] : []">
          <ngb-panel id="toggle-2" title="Offer Details">
            <ng-template ngbPanelContent>
              <div class="col-12">
                <div class="row align-items-center">
                  <div class="col-md-4 ps-2">
                    <h5 class="heading-h5">OFFERS</h5>
                  </div>
                  <div class="col-md-8 text-md-end ">
                    <div class="row justify-content-md-end pe-md-4 align-items-center">
                      <div class="col-1">
                      </div>
                      <div class="col-md-3 col-6 text-end"   *ngIf="userOfferEntityDtoList && userOfferEntityDtoList!.length > 0">
                        <span>  <button class="btn btn-new-class btn-sm me-2"
                          *ngIf="userOfferEntityDtoList && userOfferEntityDtoList!.length > 0"
                          (click)="downloadUserOfferData()">
                          <img src="assets/icons/filecsv_dark.svg" class=" c-pointer svg-icon-class"> <span
                            class="span-class-live">EXPORT</span>
                          <span class="spinner-border spinner-border-sm ms-2 loader-spinner" role="status" aria-hidden="true"
                            *ngIf="isOfferExportLoading"></span>
                        </button></span>
                      </div>
                      <div class="col-md-1 col-4 text-md-end mt-2 mb-2">
                        <span>  <button class="btn btn-new-class  me-3" (click)="refreshData()">
                          <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class">
                        </button></span>
                      </div>


                      <div class="col-md-6 text-md-end mt-2  ps-2 ">
                        <div class="mb-2  input-group">
                          <input #search placeholder="Search" type="text" placeholder="Search" id="search"
                            (keyup)="searchUserOffer(search.value)"
                            class="form-control border-end-0 rounded-0 border bg-white" />
                          <span class="input-group-append">
                            <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                              <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-6 mb-2 text-md-end">

                    <button class="btn btn-new-class  me-2" (click)="refreshData()">
                      <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class">
                    </button>
                  </div>

                  <button class="btn btn-new-class btn-sm "
                    *ngIf="(bidderHistoryWrapper$|async)?.userAuctionBidsHistoryDtos!.length > 0"
                    (click)="downloadBidHistoryData()">
                    <img src="assets/icons/filecsv_dark.svg" class=" c-pointer svg-icon-class"> <span
                      class="span-class-live">EXPORT</span>
                    <span class="spinner-border spinner-border-sm ms-2 loader-spinner" role="status" aria-hidden="true"
                      *ngIf="isExportLoading"></span>
                  </button>

                  <div class="col-md-6 mb-2 text-md-end">
                    <div class="mb-2  input-group">
                      <input #search placeholder="Search" type="text" placeholder="Search"
                        (keyup)="searchUserOffer(search.value)"
                        class="form-control border-end-0 rounded-0 border bg-white" />
                      <span class="input-group-append">
                        <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                          <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
                        </button>
                      </span>
                    </div>
                  </div> -->


                </div>
                <hr class="hr mt-2">

                <div class="col-12 p-2" *ngIf="userOfferEntityDtoList && userOfferEntityDtoList.length > 0">
                  <div class="m-3 table-responsive">
                    <table class="table table-bordered medium-font  mb-0">
                      <thead class="table-dark">
                        <tr>
                          <th class="">NAME</th>
                          <th class="">EMAIL ID</th>
                          <th class="">MOBILE NO</th>
                          <th class="">OFFER PRICE</th>
                          <th class="">OFFER TIMESTAMP</th>
                          <th class="">STATUS</th>
                          <th class="">ACTION</th>
                        </tr>
                      </thead>

                        <tbody>
                          <tr *ngIf="isBidDetailsLoading">
                            <td colspan="7">
                              <div class="e-spinner" *ngIf="isBidDetailsLoading"></div>
                            </td>
                          </tr>
                          <tr *ngFor="let userOffer of userOfferEntityDtoList; index as i" [ngClass]="userOffer?.status == 'ACCEPTED' ? 'border border-success border-2': ''">
                            <td class="text-center">
                              <div class="row">
                                <div class="col-3">
                                  <img src="assets/images/avatar05.jpg" [alt]="" class="profile-image-md"
                                    *ngIf="!userOffer?.profileImage">
                                  <img src="/downloadLandingBlob?fileId={{userOffer?.profileImage!.fileId}}"
                                    [alt]="userOffer?.bidderName" class="profile-image-md" *ngIf="userOffer?.profileImage">
                                </div>
                                <div class="col-9 text-end mt-3 overflow-class-small">
                                  {{userOffer?.bidderName}}
                                </div>
                              </div>
                            </td>
                            <td class="overflow-class-small">{{userOffer?.bidderEmailId}}</td>
                            <td>{{getCountryCode(userOffer?.countryCode)}} {{userOffer?.bidderContactNo}}</td>
                            <td>{{getFormattedPrice(auctionEntityDto?.currency!, userOffer?.offerPrice)}}</td>
                            <td>{{userOffer?.offerTimeStampStr}}</td>
                            <td><strong>{{userOffer?.status}}</strong></td>
                            <td>
                              <div class="col-2 text-end " >
                                <div ngbDropdown class="d-inline-block dropdown" container="body" placement="bottom-end">
                                  <span ngbDropdownToggle>
                                    <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class">
                                  </span>
                                  <div>
                                  <ul class="dropdown-menu shadow " ngbDropdownMenu aria-labelledby="dropdownBasic1" >
                                    <li class="dropdown-item c-pointer" *ngIf="selectedLot?.lotAllocated && userOffer.status == 'ACCEPTED'" ngbDropdownItem (click)="openUserOfferAllocationDetailsModal(allocationUserOfferDetails, userOffer)">
                                      AWARD DETAILS</li>
                                      <li *ngIf="selectedLot?.lotAllocated! && userOffer.status == 'ACCEPTED'">
                                        <hr class="dropdown-divider ">
                                      </li>
                                      <li class="dropdown-item c-pointer"  *ngIf="selectedLot?.lotAllocated! && userOffer.status == 'ACCEPTED'" ngbDropdownItem (click)="openOfferConfirmModal(confirmationOffer,userOffer )">
                                        REVERT
                                      </li>
                                      <li *ngIf="selectedLot?.lotAllocated! && userOffer.status == 'ACCEPTED'">
                                        <hr class="dropdown-divider ">
                                      </li>
                                    <li class="dropdown-item c-pointer " ngbDropdownItem [disabled]="selectedLot?.lotAllocated!"
                                    (click)="openOfferConfirmModal(confirmationOffer, userOffer)"><span class="span-class">ACCEPT OFFER</span>

                                    </li>
                                  </ul>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                  <div class="col-12 mt-2" *ngIf="userOfferEntityDtoList && userOfferEntityDtoList.length > 0">
                    <div class="row justify-content-between">
                      <div class="col-md-6 mb-2">
                        <div class="row">
                          <div class="col-md-4 col-sm-5 col-6 pe-0">
                            <span class="span-class">Show rows per page </span>
                          </div>
                          <div class="col-6 ps-0">
                            <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                              <span ngbDropdownToggle data-bs-toggle="dropdown">
                                <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
                              </span>
                              <ul ngbDropdownMenu class="text-center">
                                <li ngbDropdownItem (click)="changePageSizeOfOfferDataList(50)">
                                  50
                                </li>
                                <hr class="hr" />
                                <li ngbDropdownItem (click)="changePageSizeOfOfferDataList(100)">
                                  100
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <ngb-pagination [collectionSize]="bidderHistoryWrapper?.offerEntityDtoList?.length!" [(page)]="page"
                      [pageSize]="pageSize" (pageChange)="paginateUserOfferDataList()">
                      </ngb-pagination>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-12"
                  *ngIf="!isLoading && !(userOfferEntityDtoList && userOfferEntityDtoList.length > 0)">
                  <div class="row justify-content-center">
                    <div class="col-auto my-3">
                      <h5 class="heading-new text-dark">No Offers Available</h5>
                    </div>
                  </div>
                </div>
                <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
                  <div class="e-spinner" *ngIf="isLoading"></div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
          </ngb-accordion>

          <div *ngIf="showGeneratedView" class="mt-3">
            <app-generated-invoice [selectedAuctionHouseId]="auctionEntityDto?.orgCode" [selectedAuctionId]="auctionEntityDto?.auctionId" [selectedUserId]="awardUserId"></app-generated-invoice>
          </div>

    </div>


    <!-- <div *ngFor="let item of lotEntityDtoList; index as i">
        <div class="col-12 bg-secondary py-4 my-2">
          <div class="row ms-2 me-1">
            <div class="col-md-6 text-start ">
              <h5>{{item?.lotSequence}}# {{item?.title}} / BIDDING DASHBOARD</h5>
            </div>
            <div class="col-md-6 text-start text-md-end">
              <button class="btn btn-sm rounded" (click)="toggleBidding(item?.lotId!)"
                [ngClass]="item?.active ? 'btn-outline-success' : 'btn-outline-danger'">
                {{item?.active ? 'ENABLE' : 'DISABLE'}} BIDDING
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 mt-2">
            <div class="border rounded">
              <header class="bg-secondary p-1">
                <div class="row ">
                  <div class="col-6 mt-2">
                    HIGH BID
                  </div>
                </div>
              </header>
              <div class="border-top"></div>
              <div class="row justify-content-center my-3 w-100"
                *ngIf="!(bidderHistoryWrapper$|async)?.highestBidBidderName">
                <div class="col-6">
                  <h5>BIDDING IS NOT YET OPEN</h5>
                </div>
              </div>
              <div class="row mx-3 my-3" *ngIf="(bidderHistoryWrapper$|async)?.highestBidBidderName">
                <div class="col-6">
                  <div class="row">
                    <div class="col-4">
                      <img
                        src="/downloadLandingBlob?fileId={{(bidderHistoryWrapper$|async)?.highestBidBidderProfileDto!.fileId}}"
                        alt="" class="profile-image-large"
                        *ngIf="(bidderHistoryWrapper$|async)?.highestBidBidderProfileDto != null">
                      <img src="assets/images/avatar05.jpg" alt="" class="profile-image-large"
                        *ngIf="(bidderHistoryWrapper$|async)?.highestBidBidderProfileDto == null">
                    </div>
                    <div class="col-8">
                      <div class=" mt-2">
                        <h3>{{getFormattedPrice((bidderHistoryWrapper$|async)?.currency!,
                          (bidderHistoryWrapper$|async)?.highestBidPrice)}}
                        </h3>
                      </div>
                      <div>
                        MARCH 23 2023 02 PM
                        {{getDisplayDate((bidderHistoryWrapper$|async)?.highestBidDate, (bidderHistoryWrapper$|async)?.highestBidTime)}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mt-2">
                    <h5>{{(bidderHistoryWrapper$|async)?.highestBidBidderName}}</h5>
                  </div>
                  <div>
                    {{(bidderHistoryWrapper$|async)?.highestBidBidderEmailId}}
                  </div>
                  <div>
                    {{(bidderHistoryWrapper$|async)?.highestBidBidderMobileNo}}
                  </div>
                </div>
                <div class="col-4">
                  <div class="mt-2">
                    <h5>RESERVE PRICE</h5>
                  </div>
                  <div class="font-weight-bold">{{getFormattedPrice(auctionEntityDto?.currency!, item?.reservePrice)}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          Proxy Bid
          <div class="col-6 mt-2">
            <div class="border rounded">
              <header class="bg-secondary p-1">
                <div class="row ">
                  <div class="col-6 mt-2">
                    PROXY BID
                  </div>
                </div>
              </header>
              <div class="border-top"></div>
              <div class="row justify-content-center my-3 w-100"
                *ngIf="!(bidderHistoryWrapper$|async)?.highestMaxBidBidderName">
                <div class="col-6">
                  <h5>BIDDING IS NOT YET OPEN</h5>
                </div>
              </div>
              <div class="row mx-3 my-3" *ngIf="(bidderHistoryWrapper$|async)?.highestMaxBidBidderName">
                <div class="col-6">
                  <div class="row ">
                    <div class="col-4">
                      <img
                        src="/downloadLandingBlob?fileId={{(bidderHistoryWrapper$|async)?.highestMaxBidBidderProfileDto!.fileId}}"
                        alt="" class="profile-image-large"
                        *ngIf="(bidderHistoryWrapper$|async)?.highestMaxBidBidderProfileDto != null">
                      <img src="assets/images/avatar05.jpg" alt="" class="profile-image-large"
                        *ngIf="(bidderHistoryWrapper$|async)?.highestMaxBidBidderProfileDto == null">
                    </div>
                    <div class="col-8">
                      <div class=" mt-2">
                        <h3>{{getFormattedPrice((bidderHistoryWrapper$|async)?.currency!,
                          (bidderHistoryWrapper$|async)?.highestMaxBidPrice)}}
                        </h3>
                      </div>
                      <div>
                        MARCH 23 2023 02 PM
                        {{getDisplayDate((bidderHistoryWrapper$|async)?.highestMaxBidDate,
                        (bidderHistoryWrapper$|async)?.highestMaxBidTime)}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mt-2">
                    <h5>{{(bidderHistoryWrapper$|async)?.highestMaxBidBidderName}}</h5>
                  </div>
                  <div>
                    # {{(bidderHistoryWrapper$|async)?.highestMaxBidSequenceNo}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        Proxy Bid
        <div class="col-12 mt-2">
          <div class="border rounded">
            <header class="bg-secondary p-1">
              <div class="row ">
                <div class="col-6 mt-2">
                  PROXY BID
                </div>
              </div>
            </header>
            <div class="border-top"></div>
            <div class="row justify-content-center my-3 w-100" *ngIf="!IsUpcomingEnable">
              <div class="col-3">
                <h5>BIDDING IS NOT YET OPEN</h5>
              </div>
            </div>
            <div class="col-12" *ngIf="IsUpcomingEnable">
              <div class="m-3 table-responsive ">
                <table class="table table-bordered medium-font  mb-0">
                  <thead class="table-dark">
                    <tr>
                      <th class=" font-weight-bold">BID</th>
                      <th class=" font-weight-bold">NAME </th>
                      <th class=" font-weight-bold">BIDDER</th>
                      <th class=" font-weight-bold">BID ON</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">
                        <div class="row">
                          <div class="col-3">
                            <img
                              src="/downloadLandingBlob?fileId={{(bidderHistoryWrapper$|async)?.highestMaxBidBidderProfileDto!.fileId}}"
                              alt="" class="profile-image-large"
                              *ngIf="(bidderHistoryWrapper$|async)?.highestMaxBidBidderProfileDto != null">
                            <img src="assets/images/avatar05.jpg" alt="" class="profile-image-large"
                              *ngIf="(bidderHistoryWrapper$|async)?.highestMaxBidBidderProfileDto == null">
                          </div>
                          <div class="col-9 text-end mt-3">
                            {{getFormattedPrice((bidderHistoryWrapper$|async)?.currency!,
                            (bidderHistoryWrapper$|async)?.highestMaxBidPrice)}}
                          </div>
                        </div>
                      </td>
                      <td>{{(bidderHistoryWrapper$|async)?.highestMaxBidBidderName}}</td>
                      <td># 1008</td>
                      <td>
                        <div class="row">
                          <div class="col-8 mt-2">
                            {{getDisplayDate((bidderHistoryWrapper$|async)?.highestMaxBidDate,
                            (bidderHistoryWrapper$|async)?.highestMaxBidTime)}}
                          </div>
                          <div class="col-4 text-end">
                            <button class="btn btn-new-class btn-sm ">
                              <fa-icon [icon]="['fas', 'gear']" class=" fa-lg"></fa-icon>
                               <img src="assets/icons/settings_dark.svg"  class="svg-icon-class c-pointer">
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="col-12 bg-secondary py-2 px-3">
                  <div class="row">
                    <div class="col-6">
                      <b>8 RECORDS: SHOWING 1-50</b>
                    </div>
                    <div class="col-6 text-end">
                      Pagination
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        Bid History
        <div class="col-12 mt-2">
          <div class="border rounded">
            <header class="bg-secondary p-1">
              <div class="row ">
                <div class="col-md-6 mt-2">
                  BID HISTORY
                </div>
                <div class="col-9 col-md-4">
                  <div class="mb-2 form-floating">
                    <input #search placeholder="Search" type="text" class="form-control"
                      (keyup)="filterByName(search.value)" />
                    <label for="floatingInput" class="form-label">Search</label>
                  </div>
                </div>
                <div class="col-md-2 col-3  mt-3">
                  <fa-icon [icon]="['fas', 'download']" *ngIf="isShowDownloadBidHistoryButton()"
                    (click)="downloadBidHistoryData()" class="me-3 fa-lg"></fa-icon>
                  <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class" (click)="refreshData()">
                </div>
              </div>
            </header>
            <div class="border-top"></div>
            <div class="row justify-content-center my-3 w-100"
              *ngIf="!(bidderHistoryWrapper$|async)?.userAuctionBidsHistoryDtos">
              <div class="col-3">
                <h5>BIDDING IS NOT YET OPEN</h5>
              </div>
            </div>
            <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
              <div class="e-spinner" *ngIf="isLoading"></div>
            </div>
            <div class="col-12" *ngIf="(bidderHistoryWrapper$|async)?.userAuctionBidsHistoryDtos!.length > 0">
              <div class="m-3 table-responsive">
                <table class="table table-bordered mb-0">
                  <thead class="table-dark">
                    <tr>
                      <th class=" font-weight-bold">BID</th>
                      <th class=" font-weight-bold">PROXY BID</th>
                      <th class=" font-weight-bold">BID TYPE</th>
                      <th class=" font-weight-bold">NAME </th>
                      <th class=" font-weight-bold">BIDDER</th>
                      <th class=" font-weight-bold">BID ON</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bidHistory of _currentUserAuctionBidsHistoryDtos$ | async; index as j">
                      <td class="text-center">
                        <div class="row">
                          <div class="col-5">
                            <img src="assets/images/avatar05.jpg" [alt]="" class="profile-image-md"
                              *ngIf="!bidHistory?.profileImage">
                            <img src="/downloadLandingBlob?fileId={{bidHistory?.profileImage!.fileId}}"
                              [alt]="bidHistory?.name" class="profile-image-md" *ngIf="bidHistory?.profileImage">
                          </div>
                          <div class="col-7 text-end mt-3">
                            <strong
                              [ngStyle]="{'text-decoration' : bidHistory.activeBid == false ? 'line-through' : 'none', 'text-decoration-color': bidHistory.activeBid == false ? 'red': 'black'}"
                              *ngIf="bidHistory.bidType!.toLowerCase() != 'PROXY BID'.toLowerCase();else elseBlock">
                              {{getFormattedPrice(bidHistory?.currency!,bidHistory?.bidPrice)}}
                            </strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <strong *ngIf="bidHistory.bidType!.toLowerCase() == 'PROXY BID'.toLowerCase();else elseBlock">
                          {{getFormattedPrice(bidHistory?.currency!, bidHistory?.maxBidPrice)}}
                        </strong>
                      </td>
                      <td class="pt-3">
                        <strong>{{bidHistory?.bidType}}</strong>
                      </td>
                      <td class="pt-3">{{bidHistory?.name}}</td>
                      <td class="pt-3"># {{bidHistory?.paddleNo}}</td>
                      <td>
                        <div class="row">
                          <div class="col-8 pt-3">
                            {{getDisplayDate(bidHistory?.bidDate, bidHistory?.bidTime)}}
                          </div>
                          <div class="col-4 text-end dropdown">
                            <button class="btn btn-outline-primary btn-sm rounded-circle" data-bs-toggle="dropdown">
                              <fa-icon [icon]="['fas', 'gear']" class=" fa-lg"></fa-icon>
                            </button>
                            <ul class="dropdown-menu">
                              <li><a class="dropdown-item c-pointer" *ngIf="isHighestBidEligibleToReject(bidHistory)"
                                  (click)="openRejectBidModal(bidHistory, rejectBidTemp)">REJECT BID</a></li>
                              <li><a class="dropdown-item c-pointer" (click)="openBidderDetailsModal(bidHistory)">BIDDER
                                  DETAILS</a></li>
                              <li><a class="dropdown-item c-pointer"
                                  (click)="openShowBidderDetailsModal(showbidDetails, bidHistory)"> BID DETAILS</a></li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="col-12 bg-secondary py-2 px-3">
                  <div class="row justify-content-between">
                    <div class="col-9">
                      <b>{{(bidderHistoryWrapper$|async)?.userAuctionBidsHistoryDtos?.length ?? 0}} RECORDS: SHOWING
                        1-50</b>
                    </div>
                    <div class="col-auto">
                      <ngb-pagination
                        [collectionSize]="(bidderHistoryWrapper$ | async)?.userAuctionBidsHistoryDtos?.length!"
                        [(page)]="page" [pageSize]="pageSize" (pageChange)="paginateBidderHistoryList()">
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        Reserved Price Bid History
        <div class="col-12 mt-2" *ngIf="auctionEntityDto?.buyItNowEnable">
          <div class="border rounded">
            <header class="bg-secondary p-1">
              <div class="row ">
                <div class="col-md-6 mt-2">
                  RESERVED PRICE BID HISTORY
                </div>
                <div class="col-md-4 col-9">
                  <div class="mb-2 form-floating">
                    <input #search placeholder="Search" type="text" class="form-control"
                      (keyup)="filterUserOfferByName(search.value)" />
                    <label for="floatingInput" class="form-label">Search</label>
                  </div>
                </div>
                <div class="col-3 col-md-2 mt-3">
                  <fa-icon [icon]="['fas', 'download']" *ngIf="isShowDownloadBidderOfferButton()"
                    (click)="downloadBiderOfferData()" class="me-3 fa-lg"></fa-icon>
                  <fa-icon [icon]="['fas', 'arrows-rotate']" class="fa-lg" (click)="refreshData()"></fa-icon>
                </div>
              </div>
            </header>
            <div class="border-top"></div>
            <div class="row justify-content-center my-3 w-100" *ngIf="!userOfferEntityDtoList">
              <div class="col-3">
                <h5>NO OFFER PLACED YET</h5>
              </div>
            </div>
            <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
              <div class="e-spinner" *ngIf="isLoading"></div>
            </div>
            <div class="col-12" *ngIf="userOfferEntityDtoList!.length > 0">
              <div class="m-3 table-responsive">
                <table class="table table-bordered mb-0">
                  <thead class="table-dark">
                    <tr>
                      <th class=" font-weight-bold">NAME</th>
                      <th class=" font-weight-bold">EMAIL ID</th>
                      <th class=" font-weight-bold">MOBILE NO</th>
                      <th class=" font-weight-bold">OFFER PRICE</th>
                      <th class=" font-weight-bold">OFFER TIMESTAMP</th>
                      <th class=" font-weight-bold">STATUS</th>
                      <th class=" font-weight-bold">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let userOffer of userOfferEntityDtoList; index as j">
                      <td class="text-center">
                        <div class="row">
                          <div class="col-3">
                            <img src="assets/images/avatar05.jpg" [alt]="" class="profile-image-md"
                              *ngIf="!userOffer?.profileImage">
                            <img src="/downloadLandingBlob?fileId={{userOffer?.profileImage!.fileId}}"
                              [alt]="userOffer?.bidderName" class="profile-image-md" *ngIf="userOffer?.profileImage">
                          </div>
                          <div class="col-9 text-end mt-3">
                            {{userOffer?.bidderName}}
                          </div>
                        </div>
                      </td>
                      <td>{{userOffer?.bidderEmailId}}</td>
                      <td class="pt-3">{{userOffer?.bidderContactNo}}</td>
                      <td class="pt-3">{{getFormattedPrice(auctionEntityDto?.currency!, userOffer.offerPrice)}}</td>
                      <td class="pt-3">{{userOffer?.offerTimeStampStr}}</td>
                      <td class="pt-3"><strong>{{userOffer?.status}}</strong></td>
                      <td>
                        <button class="btn btn-outline-primary btn-sm rounded-circle" data-bs-toggle="dropdown">
                          <fa-icon [icon]="['fas', 'gear']" class=" fa-lg"></fa-icon>
                        </button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item c-pointer"
                              (click)="openConfirmationModal(confirmationOffer, userOffer)">Accept Offer</a></li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="col-12 bg-secondary py-2 px-3">
                  <div class="row justify-content-between">
                    <div class="col-9">
                      <b>{{bidderHistoryWrapper?.offerEntityDtoList?.length ?? 0}} RECORDS: SHOWING 1-50</b>
                    </div>
                    <div class="col-auto">
                      <ngb-pagination [collectionSize]="bidderHistoryWrapper?.offerEntityDtoList?.length!"
                        [(page)]="page" [pageSize]="pageSize" (pageChange)="paginateUserOfferDataList()">
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->


<ng-template #elseBlock><strong>-</strong></ng-template>

<ng-template #showbidDetails>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>BID DETAILS</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="row p-3">
      <div class="col-md-6 mb-2 ">
       <div class="row">
        <div class="col-4">
          <b>
            Name:
          </b>
        </div>
        <div class="div-class col-8">
          <div class="overflow-class-detail">
            {{selectedUserAuctionBidsHistoryDto?.name}}
          </div>
        </div>
       </div>
      </div>
      <div class="col-md-6 mb-2 ">
        <div class="row">
          <div class="col-4">
            <b>
              Email Id:
            </b>
          </div>
          <div class="div-class col-8">
            <div class="overflow-class-detail">
              {{selectedUserAuctionBidsHistoryDto?.companyEmailId}}
            </div>
            </div>
         </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="row">
          <div class="col-4">
            <b>
              Bidder:
            </b>
          </div>
          <div class="div-class col-8">{{selectedUserAuctionBidsHistoryDto?.paddleNo}}</div>
         </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="row">
          <div class="col-4">
            <b>
              Mobile No:
            </b>
          </div>
          <div class="div-class col-8">{{getCountryCode(selectedUserAuctionBidsHistoryDto?.countryCode)}} {{selectedUserAuctionBidsHistoryDto?.companyMobileNo}}</div>
         </div>
      </div>
    </div>
    <div class="col-12 text-end p-3">
      <span class="span-class">ALL BIDS</span>
      <span class="form-switch  mx-3">
         <input class="form-check-input c-pointer" type="checkbox" [checked]="showMaxBidModel"
          (change)="changeShowBid()" /> </span>
      <span class="span-class">
        PROXY BIDS
      </span>
    </div>
    <div class="table-responsive">
      <div class="m-3">
        <table class="table table-bordered medium-font  mb-0">
          <thead class="table-dark">
            <tr>
              <th class="">BID</th>
              <th class="">PROXY BID</th>
              <th class="">BID TYPE</th>
              <th class="">BID ON</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="isBidDetailsLoading">
              <td colspan="4">
                <div class="e-spinner" *ngIf="isBidDetailsLoading"></div>
              </td>
            </tr>
            <!-- <tr>
              <div class="col-12 text-center" *ngIf="(!(_filteredUserLotBidsData$ | async) && showMaxBidModel) || (showMaxBidModel && (_filteredUserLotBidsData$ | async)?.length == 0)">
                <h3 class="heading-new text-dark" >
                  No Proxy Bid Available
                </h3>
              </div>
               <div class="col-12 text-center" *ngIf="(!(_filteredUserLotBidsData$ | async) && !showMaxBidModel) || (!showMaxBidModel && (_filteredUserLotBidsData$ | async)?.length == 0)">
                <h3 class="heading-new text-dark" >
                  No Bid Available
                </h3>
               </div>
            </tr> -->
            <tr *ngIf="(!(_filteredUserLotBidsData$ | async) && showMaxBidModel) || (showMaxBidModel && (_filteredUserLotBidsData$ | async)?.length == 0)">
              <td colspan="8" class="text-center">
                <h3 class="heading-new text-dark">  No Proxy Bid Available</h3>
            </td>
          </tr>

            <tr *ngIf="(!(_filteredUserLotBidsData$ | async) && !showMaxBidModel) || (!showMaxBidModel && (_filteredUserLotBidsData$ | async)?.length == 0)">
              <td colspan="8" class="text-center">
                <h3 class="heading-new text-dark">  No Bid Available</h3>
            </td>
          </tr>
            <tr *ngFor="let item  of (_filteredUserLotBidsData$ | async)  ">
              <td class="text-center">
                <div class="row">
                  <div class="col-md-6 ">
                    <img *ngIf="!item.profile" src="assets/images/avatar05.jpg" class="profile-image-md">
                    <img *ngIf="item.profile" src="/downloadLandingBlob?fileId={{item.profile!.fileId}}"
                      class="profile-image-sm mt-0">
                  </div>
                  <div class="col-md-6 text-md-end ">
                    <span *ngIf="item?.bidPrice"
                      [ngStyle]="{'text-decoration' : item.active == false ? 'line-through' : 'none', 'text-decoration-color': item.active == false ? 'red': 'black'}">
                      {{getFormattedPrice(item?.currency!, item?.bidPrice)}}</span>
                  </div>
                </div>
              </td>
              <td>
                <span *ngIf="item?.bucketPrice"
                  [ngStyle]="{'text-decoration' : item.active == false ? 'line-through' : 'none', 'text-decoration-color': item.active == false ? 'red': 'black'}">{{getFormattedPrice(item?.currency!,
                  item?.bucketPrice)}} </span>
              </td>
              <td class="">
                {{item?.bidType}}
              </td>
              <td>
                {{getDisplayDate(item.bidDate, item.bidTime)}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

   </div>

  </div>
</ng-template>

<ng-template #rejectBidTemp>
  <div class="modal-body p-0">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Reject Bid</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isRejectBidLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-2">
      <p class="mt-2" ngbAutoFocus>Do you Want to Reject Bid!</p>
      <div class="my-3">
        <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async">
        </app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
          [message]="'Bid rejected successfully.'"></app-message-toast>
      </div>
      <div class="d-grid gap-2 mb-3" *ngIf="isRejectBidLoading">
        <div class="e-spinner"></div>
      </div>
      <div class="text-end mb-3" *ngIf="!isRejectBidLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="btn btn-new-class btn-sm me-3" (click)="closeModal()">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary btn-sm " (click)="rejectBid()">CONFIRM</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmationOffer>
  <div class="my-2">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2> {{isLotAllocated ? 'REVERT' : 'ACCEPT'}}  OFFER</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isOfferLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()"
            [ngClass]="{'disabled': isOfferLoading}">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <span *ngIf="!isLotAllocated">
        Do you want to Accept Offer Price {{getFormattedPrice(auctionEntityDto?.currency!, selectedUserOffer?.offerPrice)}}? Once accepted the Lot will be Sold.
      </span>
      <span *ngIf="isLotAllocated">
        Do you want to Revert Offer Price {{getFormattedPrice(auctionEntityDto?.currency!, selectedUserOffer?.offerPrice)}}? The Sold status of the Lot will be revoked.
      </span>

    </div>

    <div class="m-3">
      <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
      <app-message-toast *ngIf="_showSuccessToast$ | async" [isError]="false" message="{{isLotAllocated ? 'Offer reverted successfully. Sold revert.' : 'Offer successfully accepted. Lot Sold.'}} "></app-message-toast>
    </div>

    <div class="d-grid gap-2 mb-3" *ngIf="isOfferLoading">
      <div class="e-spinner"></div>
    </div>

    <div class="m-3">
      <div class="text-end my-3" *ngIf="!isOfferLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="btn btn-new-class btn-sm px-3 me-2" (click)="closeModal()">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary px-3" *ngIf="!selectedLot?.lotAllocated" (click)="acceptOffer()">ACCEPT</button>
        <button type="button" class="btn btn-sm btn-outline-primary px-3" *ngIf="selectedLot?.lotAllocated" (click)="rejectOfferLotAllocation()">REVERT</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmationLotAllocate>
  <div class="my-2">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus> {{isLotAllocated ? 'REVERT' : 'AWARD'}}  </h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLotAllocationLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()"
            [ngClass]="{'disabled': isLoading}">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <span *ngIf="!isLotAllocated">
        Do you want to Award Bid Price {{getFormattedPrice(auctionEntityDto?.currency!,
          selectedBidsHistoryForAllocate?.bidPrice)}}? Once accepted the Lot will be Sold.
      </span>
      <span *ngIf="isLotAllocated">
        Do you want to Reject the Allocation? The Sold status of the Lot will be revoked.
      </span>
    </div>
    <div class="m-3">
      <app-message-toast *ngIf="(_showErrorToast$ | async)" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
      <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
          [message]="isLotAllocated ? 'Lot rejected successfully. Sold revert.' : 'Lot is awarded successfully. Lot Sold.'">
      </app-message-toast>
    </div>

    <div class="d-grid gap-2 mb-3" *ngIf="isLotAllocationLoading">
      <div class="e-spinner" *ngIf="isLotAllocationLoading" [ngClass]="{'center-spinner' : isLotAllocationLoading}"></div>
    </div>

    <div class="m-3">
      <div class="text-end my-3" *ngIf="!isLotAllocationLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="btn btn-new-class btn-sm px-3 me-3" (click)="closeModal()">Cancel</button>
        <button type="button" class="btn btn-sm btn-outline-primary px-3" (click)="lotAllocate()" *ngIf="!isLotAllocated">Award</button>
        <button type="button" class="btn btn-sm btn-outline-primary px-3" (click)="rejectLotAllocation()" *ngIf="isLotAllocated">Revert</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #allocationUserOfferDetails>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>OFFERS ALLOCATION DETAILS</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">

      <div class="row m-3">
        <div class="col-3 mb-1">Name</div>
        <div class="col-7 mb-1">{{selectedUserOffer?.bidderName}}</div>
        <hr class="hr my-2" />
        <div class="col-3 mb-1">Email Id</div>
        <div class="col-7 mb-1">{{selectedUserOffer?.bidderEmailId}}</div>
        <hr class="hr my-2" />
        <div class="col-3 mb-1">Phone</div>
        <div class="col-7 mb-1">{{getCountryCode(selectedUserOffer?.countryCode)}} {{selectedUserOffer?.bidderContactNo}}</div>
        <hr class="hr my-2" />
        <div class="col-3 mb-1">Offer Price</div>
        <div class="col-7 mb-1">{{getFormattedPrice(auctionEntityDto?.currency!, selectedUserOffer?.offerPrice)}}</div>
        <hr class="hr my-2" />
        <div class="col-3 mb-1">Offer Timestamp</div>
        <div class="col-7 mb-1">{{getDisplayDateFromISO(selectedUserOffer?.makeOfferTimeStamp)}}</div>
        <hr class="hr my-2" />
        <div class="col-3 mb-1">Status</div>
        <div class="col-7 mb-1">{{selectedUserOffer?.status}}</div>
        <hr class="hr my-2" />
        <div class="col-3 mb-1">Awarded By EmailId</div>
        <div class="col-7 mb-1">{{lotAllocationEntity?.allocatedByEmailId}}</div>
        <hr class="hr my-2" />
        <div class="col-3 mb-1">Award Timestamp</div>
        <div class="col-7 mb-1">{{getDisplayDateFromISO(lotAllocationEntity?.allocatedUtcTime)}}</div>

        </div>

    </div>

</div>
</ng-template>

<ng-template #allocationDetails>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2> ALLOCATION DETAILS</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="row m-3">
      <div class="col-3 mb-1">Name</div>
      <div class="col-7 mb-1">{{selectedUserAuctionBidsHistoryDto?.name}}</div>
      <hr class="hr my-2" />
      <div class="col-3 mb-1">Email Id</div>
      <div class="col-7 mb-1">{{selectedUserAuctionBidsHistoryDto?.emailId!}}</div>
      <hr class="hr my-2" />
      <div class="col-3 mb-1">Phone</div>
      <div class="col-7 mb-1">{{getCountryCode(selectedUserAuctionBidsHistoryDto?.countryCode)}} {{selectedUserAuctionBidsHistoryDto?.mobileNo}}</div>
      <hr class="hr my-2" />
      <div class="col-3 mb-1">Offer Price</div>
      <div class="col-7 mb-1">{{getFormattedPrice(auctionEntityDto?.currency!, selectedUserAuctionBidsHistoryDto?.bidPrice)}}</div>
      <hr class="hr my-2" />
      <div class="col-3 mb-1">Offer Timestamp</div>
      <div class="col-7 mb-1">{{getDisplayDateFromISO(selectedUserAuctionBidsHistoryDto?.utcTime)}}</div>
      <hr class="hr my-2" />
      <div class="col-3 mb-1">Status</div>
      <div class="col-7 mb-1">AWARDED</div>
      <hr class="hr my-2" />
      <div class="col-3 mb-1">Awarded By EmailId</div>
      <div class="col-7 mb-1">{{lotAllocationEntity?.allocatedByEmailId}}</div>
      <hr class="hr my-2" />
      <div class="col-3 mb-1">Award Timestamp</div>
      <div class="col-7 mb-1">{{getDisplayDateFromISO(lotAllocationEntity?.allocatedUtcTime)}}</div>

      </div>
   </div>


</div>
</ng-template>

<ng-template #blockchainTransactionsView>
  <div class="m-3 table-responsive">
    <table class="table table-bordered medium-font  mb-0">
      <thead class="table-dark">
        <tr>
          <th class=" "><span>Txn Hash</span></th>
          <th class=""> <span>Block</span></th>
          <th class=""><span>Age</span></th>
          <th class=""> <span>[Txn Fee]</span></th>
        </tr>

      </thead>
      <tbody>
        <tr *ngIf="(_filteredPolygonTxnList$ | async)?.length == 0">
          <td colspan="4">
            <div class="text-center">
              <h5 class="heading-new text-dark">NO BLOCKCHAIN TRANSACTION AVAILABLE</h5>
            </div>
          </td>
        </tr>
        <tr class="" *ngFor="let polygonTxn of _filteredPolygonTxnList$|async; index as i">
          <td class="c-pointer btn-link" (click)="openBlockChainDetailsModal(polygonTxn.hash)">{{polygonTxn?.hash}}</td>
          <td class="">{{polygonTxn?.blockNumber}}</td>
          <td class="" container="body" placement="top" [ngbTooltip]="displayTimestamp(polygonTxn?.timeStamp)">
            {{timeAgoDate(polygonTxn?.timeStamp)}}</td>
          <td class="">{{getTransactionFees(polygonTxn)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12 mt-2" *ngIf="(_filteredPolygonTxnList$|async)!.length > 0">
    <div class="row justify-content-between">
      <div class="col-md-6 mb-2">
        <div class="row">
          <div class="col-md-4 col-sm-5 col-6 pe-0">
            <span class="span-class">Show rows per page </span>
          </div>
          <div class="col-6 ps-0">
            <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
              <span ngbDropdownToggle data-bs-toggle="dropdown">
                <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
              </span>
              <ul ngbDropdownMenu class="text-center">
                <li ngbDropdownItem (click)="changePageSizeOfBlockchainTransactions(50)">
                  50
                </li>
                <hr class="hr" />
                <li ngbDropdownItem (click)="changePageSizeOfBlockchainTransactions(100)">
                  100
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <ngb-pagination [collectionSize]="(_filteredPolygonTxnList$|async)!.length" [(page)]="page" [pageSize]="pageSize"
          (pageChange)="paginateBlockchainTransactions()">
        </ngb-pagination>
      </div>
    </div>
  </div>
</ng-template>
