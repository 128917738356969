<div class="p-2 mt-5">
  <div class="row align-items-center">

    <div class="col-md-3  text-start mb-2">

      <div class="mb-2 input-group">
        <input #search placeholder="Search" type="text" id="ctrlSearchPR" [formControl]="ctrlSearchPR"
          class="form-control border-end-0 rounded-0 border bg-white" />
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
    </div>
    <div class="col-md-5 text-start mb-2">
    </div>
  </div>

  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-bordered medium-font mb-0 align-middle">
        <thead class="table-dark">
          <tr class="text-center">
            <th class=" "><span>SNo</span></th>
            <th class=" "><span>PO Number</span></th>
            <th class=" "><span>Invoice Number</span></th>
            <th class=" "><span>Company Name</span></th>
            <th class=" "><span>Vendor Name</span></th>
            <th class=" "><span>Vendor EmailId</span></th>
            <th class=" "><span>Payment Status</span></th>
            <th class=" "><span>Action</span></th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr *ngIf="isDataLoading" class="align-middle">
            <td colspan="10" class="text-center">
              <div class="e-spinner"></div>
            </td>
          </tr>

          <tr *ngIf="(filteredInvoicesList$ | async)!.length == 0 && !isDataLoading">
            <td class="text-center" colspan="10">
              <h5 class="heading-new"> No Data Available</h5>
            </td>
          </tr>

          <tr class="text-center align-middle" *ngFor="let item of filteredInvoicesList$| async; index as i">

            <td class="">
              {{i + 1}}
            </td>
            <td class="">
              {{item.purchaseOrderNumber}}
            </td>
            <td class="">
              {{item.invoiceNumber}}
            </td>
            <td>
              {{item.buyerCompanyName}}
            </td>
            <td>
              {{item.vendorCompanyName}}
            </td>
            <td>
              {{item.vendorEmailId}}
            </td>
            <td class="">
              {{item.paymentStatus}}
            </td>
            <td>
              <button class="btn btn-sm btn-primary-clr-class-small" *ngIf="item.paymentStatus == 'PENDING'"
                (click)="openApprovalWorkflowModal(item, approvalWorkflowTemplate)">Approve</button>

              <button class="btn btn-sm btn-primary-clr-class-small" *ngIf="item.paymentStatus == 'PAID'"
                (click)="openApprovalWorkflowModal(item, approvalWorkflowTemplate)">VIEW</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row justify-content-between mt-3" *ngIf="tempPurchaseInvoices.length > 0">
      <div class="col">
        <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
          <span class="span-class">Show rows per page </span>
          <span ngbDropdownToggle data-bs-toggle="dropdown">
            <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
          </span>
          <ul ngbDropdownMenu class="text-center shadow">
            <li ngbDropdownItem class="c-pointer" (click)="changePageSize(50)">50</li>
            <li>
              <hr class="dropdown-divider ">
            </li>
            <li ngbDropdownItem class="c-pointer" (click)="changePageSize(100)">100</li>
          </ul>
        </div>
      </div>
      <div class="col-auto">
        <ngb-pagination [collectionSize]="tempPurchaseInvoices.length!" [(page)]="page" [pageSize]="pageSize"
          (pageChange)="doPaginate()">
        </ngb-pagination>
      </div>
    </div>

  </div>
</div>


<ng-template #approveConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Purchase Invoice Approval</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(approvalModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p>Are you sure you want to {{approveRequest == 'APPROVE' ? 'approve' : 'reject' }}?</p>
        <div class="d-grid gap-2 mb-3" *ngIf="isApprovalLoading">
          <div class="e-spinner"></div>
        </div>
        <div class="my-3">
          <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async">
          </app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            [message]="'PR approved successfully.'">
          </app-message-toast>
        </div>

        <div class="col-12 text-end mt-3" *ngIf="!isApprovalLoading && !(_showSuccessToast$ | async)">
          <button class="btn btn-sm btn-primary-clr-class" (click)="approvePurchaseInvoice()">OK</button>
        </div>
      </div>

    </div>
  </div>
</ng-template>


<ng-template #approvalWorkflowTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Purchase Invoice Approval</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="row">
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseInvoiceDto?.purchaseOrderNumber}}
            <div class="small-font">
              Purchase Order Number
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseInvoiceDto?.invoiceNumber}}
            <div class="small-font">
              Invoice Number
            </div>
          </div>

          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseInvoiceDto?.buyerCompanyName}}
            <div class="small-font">
              Company Name
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseInvoiceDto?.vendorCompanyName}}
            <div class="small-font">
              Vendor Name
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseInvoiceDto?.vendorEmailId}}
            <div class="small-font">
              Vendor EmailId
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseInvoiceDto?.paymentStatus}}
            <div class="small-font">
              Payment Status
            </div>
          </div>

          <div class="table-responsive my-3">
            <table class="table table-bordered medium-font mb-0 align-middle">
              <thead class="table-dark">
                <tr class="text-center">
                  <th class=" "><span>SNo</span></th>
                  <th class=" "><span>Item Name</span></th>
                  <th class=" "><span>Item Description</span></th>
                  <th class=" "><span>Quantity</span></th>
                  <th class=" "><span>Uom</span></th>
                  <th class=" "><span>tax</span></th>
                  <th class=" "><span>unitPrice</span></th>
                  <th class=" "><span>Unit Price Qty Total</span></th>
                  <th class=" "><span>Unit Price Qty Tax Total</span></th>
                  <th class=" "><span>Unit Price Qty Tax Additional Prices Total</span></th>
                </tr>
              </thead>

              <tbody class="bg-white">

                <tr class="text-center align-middle"
                  *ngFor="let item of selectedPurchaseInvoiceDto?.invoiceOrderItems; index as i">

                  <td class="">
                    {{i + 1}}
                  </td>
                  <td class="">
                    {{item.itemName}}
                  </td>
                  <td class="">
                    {{item.itemDescription}}
                  </td>
                  <td>
                    {{item.quantity}}
                  </td>
                  <td>
                    {{item.uom}}
                  </td>
                  <td>
                    {{item.tax}}
                  </td>
                  <td class="">
                    {{getFormattedPrice(selectedPurchaseInvoiceDto!.currency!, item.unitPrice)}}
                  </td>
                  <td class="">
                    {{getFormattedPrice(selectedPurchaseInvoiceDto!.currency!, item.unitPriceQtyTotal)}}
                  </td>
                  <td class="">
                    {{getFormattedPrice(selectedPurchaseInvoiceDto!.currency!, item.unitPriceQtyTaxTotal)}}
                  </td>
                  <td class="">
                    {{getFormattedPrice(selectedPurchaseInvoiceDto!.currency!,
                    item.unitPriceQtyTaxAdditionalPricesTotal)}}
                  </td>

                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div class="col-12">
          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>
          <div class="row" *ngIf="!isLoading && appWorkflowUiDtoList.length > 0">
            <div class="col-md-4">
              <div class="card bg-white ">
                <div class="py-2 bg-grey1 outline-border">
                  <div class="row px-2">
                    <div class="">
                      <b>
                        Project Executive - Sourcing
                      </b>
                    </div>
                  </div>
                  <hr class="hr my-1">

                </div>
                <div class="p-2">
                  <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-8 div-class">
                        {{appWorkflowUiDtoList[0].name}}<br>
                        {{appWorkflowUiDtoList[0].emailId}}
                      </div>
                      <div class="col-md-4 text-end">
                        <span [ngClass]="getApprovalWorkflowStatusClass(appWorkflowUiDtoList[0].status)">
                          <b>{{appWorkflowUiDtoList[0].status | titlecase}}</b>

                        </span>
                        <div class="small-font">
                          {{appWorkflowUiDtoList[0].approvedDate}}
                        </div>
                      </div>
                      <div class="overflow-class-small small-font">
                        {{appWorkflowUiDtoList[0].comment}}
                      </div>
                    </div>

                    <div class="col-12 mt-3" *ngIf="isShowApprovedButton(0)">
                      <label for="ctrlComments">Comments</label>
                      <div class="mb-3">
                        <textarea type="text" class="form-control" id="ctrlComments" [formControl]="ctrlComments"
                          rows="3">
                        </textarea>
                      </div>
                    </div>

                    <div class="col-12 text-end" *ngIf="isShowApprovedButton(0)">
                      <a class="text-danger me-3"
                        (click)="openPrApprovalConfirmModal(approveConfirmModal, 'REJECT')">Reject</a>
                      <a class="text-success"
                        (click)="openPrApprovalConfirmModal(approveConfirmModal, 'APPROVE')">Approve</a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card bg-white ">
                <div class="py-2 bg-grey1 outline-border">
                  <div class="row px-2">
                    <div class="">
                      <b> Project Manager</b>
                    </div>
                  </div>
                  <hr class="hr my-1">
                </div>
                <div class="p-2">
                  <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-8 div-class">
                        {{appWorkflowUiDtoList[1].name}}<br>
                        {{appWorkflowUiDtoList[1].emailId}}
                      </div>
                      <div class="col-md-4 text-end">
                        <span [ngClass]="getApprovalWorkflowStatusClass(appWorkflowUiDtoList[1].status)">
                          <b>{{appWorkflowUiDtoList[1].status | titlecase}}</b>
                        </span>
                        <div class="small-font">
                          {{appWorkflowUiDtoList[1].approvedDate}}
                        </div>
                      </div>
                      <div class="overflow-class-small small-font" *ngIf="appWorkflowUiDtoList[1].comment">
                        {{appWorkflowUiDtoList[1].comment}}
                      </div>
                    </div>
                    <div class="col-12 mt-3" *ngIf="isShowApprovedButton(1)">
                      <label for="ctrlComments">Comments</label>
                      <div class="mb-3">
                        <textarea type="text" class="form-control" id="ctrlComments" [formControl]="ctrlComments"
                          rows="3">
                        </textarea>
                      </div>
                    </div>

                    <div class="col-12 text-end" *ngIf="isShowApprovedButton(1)">
                      <a class="text-danger me-3"
                        (click)="openPrApprovalConfirmModal(approveConfirmModal, 'REJECT')">Reject</a>
                      <a class="text-success"
                        (click)="openPrApprovalConfirmModal(approveConfirmModal, 'APPROVE')">Approve</a>
                    </div>
                  </div>
 
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card bg-white ">
                <div class="py-2 bg-grey1">
                  <div class="row px-2">
                    <div class="">
                      <b>
                        Chief Procurement Officer
                      </b>
                    </div>
                  </div>
                  <hr class="hr my-1">

                </div>
                <div class="p-2">
                  <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-8 div-class">
                        {{appWorkflowUiDtoList[2].name}}<br>
                        {{appWorkflowUiDtoList[2].emailId}}
                      </div>
                      <div class="col-md-4 text-end">
                        <span [ngClass]="getApprovalWorkflowStatusClass(appWorkflowUiDtoList[2].status)">
                         <b> {{appWorkflowUiDtoList[2].status | titlecase}}</b>
                        </span>
                        <div class="small-font">
                          {{appWorkflowUiDtoList[2].approvedDate}}
                        </div>
                      </div>
                      <div class="overflow-class-small small-font" *ngIf="appWorkflowUiDtoList[2].comment">
                        {{appWorkflowUiDtoList[2].comment}}
                      </div>
                    </div>
                    <div class="col-12 mt-3" *ngIf="isShowApprovedButton(2)">
                      <label for="ctrlComments">Comments</label>
                      <div class="mb-3">
                        <textarea type="text" class="form-control" id="ctrlComments" [formControl]="ctrlComments"
                          rows="3">
                        </textarea>
                      </div>
                    </div>

                    <div class="col-12 text-end" *ngIf="isShowApprovedButton(2)">
                      <a class="text-danger me-3"
                        (click)="openPrApprovalConfirmModal(approveConfirmModal, 'REJECT')">Reject</a>
                      <a class="text-success"
                        (click)="openPrApprovalConfirmModal(approveConfirmModal, 'APPROVE')">Approve</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>