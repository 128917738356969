<div class="container my-4">
  <div class="col-12 p-2">
    <div class="table-responsive">
      <table class="table table-bordered medium-font mb-0">
        <thead class="table-dark">
          <tr class="text-center">
            <th class=""><span>SNo</span></th>
            <th class=""><span>PO Number</span></th>
            <th class=""><span>Vendor Name</span></th>
            <th class=""><span>Total Items</span></th>
            <th class=""><span>Action</span></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr *ngIf="isDataLoading" class="align-middle">
            <td colspan="9" class="text-center">
              <div class="e-spinner"></div>
            </td>
          </tr>

          <tr *ngIf="!isDataLoading && purchaseOrdersList?.length == 0">
            <td class="text-center" colspan="9">
              <h5 class="heading-new"> No Data Available</h5>
            </td>
          </tr>

          <tr class="text-center align-middle" *ngFor="let item of filteredPOsList$|async; index as i">
            <td class="">{{i+1}}</td>
            <td class="">{{item.purchaseOrderNumber}}</td>
            <td class="">{{item.vendorCompanyName}}</td>
            <td class="">{{item.purchaseOrderItems?.length}}</td>
            <td class="">
              <button class="btn btn-sm btn-primary-clr-class-small" [disabled]="item?.status == 'APPROVED'"
                (click)="openPoApprovalModal(item, approveModalTemplate)">
                Approve
              </button>
              <!-- <button class="btn btn-sm btn-primary-clr-class-small" *ngIf="item?.status == 'APPROVED'"
                (click)="openPurchaseOrderPdfModal(item, purchaseOrderPdfTemplate)">
                Create PO
              </button> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #approveModalTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>PO Approval</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p>Are you sure you want to approve</p>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async"
            [message]="'Purchase Order approved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>

        <div class="col-12 text-end mt-3" *ngIf="!(_showSuccessToast$ | async) && !isLoading">
          <button class="btn btn-sm btn-primary-clr-class" (click)="markToApprove()">OK</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #purchaseOrderPdfTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2></h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div *ngIf="isLoading">
          <div class="d-grid gap-2 mb-3">
            <div class="e-spinner"></div>
          </div>
        </div>

        <div class="container">
          <div class="col-12 text-end" *ngIf="!isLoading">
            <!-- <button class="btn btn-sm btn-primary-clr-class my-3 me-3" (click)="">View All PO</button> -->
            <button class="btn btn-sm btn-dark-clr-class my-3" (click)="generatePdf()">PDF</button>
          </div>

          <div class="col-12" id="pdfContent">
            <div class="row align-items-center">
              <div class="col-6">
                <h4 class="heading-h4">
                  Purchase Order
                </h4>
              </div>
              <div class="col-6 text-end">
                <img src="/downloadLandingBlob?fileId={{selectedRfxPurchaseOrderDto?.buyerLogo?.fileId}}" [alt]="selectedRfxPurchaseOrderDto?.buyerCompanyName"
                  class="application-logo | img-fluid my-1 ms-md-5" />
              </div>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-md-6 mb-3 ">
                  <div class="card p-2 bg-white ro-height ">
                    <div class=" row">
                      <div class="col-12 text-center">
                        <h5 class="heading-h5 mt-1">Our Details</h5>
                      </div>

                    </div>
                    <hr class="hr my-2" />
                    <div class="mb-2">
                      <b>{{selectedRfxPurchaseOrderDto?.buyerCompanyName}}</b>
                    </div>
                    <div class="div-class mb-2">
                      {{selectedRfxPurchaseOrderDto?.buyerAddress?.addressLine1}},
                      {{selectedRfxPurchaseOrderDto?.buyerAddress?.addressLine2 ? selectedRfxPurchaseOrderDto?.buyerAddress?.addressLine2 + ',' : ''}}
                      {{selectedRfxPurchaseOrderDto?.buyerAddress?.city}},
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">State</div>
                      <div class=" col-7">
                        {{selectedRfxPurchaseOrderDto?.buyerAddress?.state}}
                      </div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">Pincode</div>
                      <div class=" col-7">
                        {{selectedRfxPurchaseOrderDto?.buyerAddress?.zipCode}}
                      </div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">Mobile No.</div>
                      <div class=" col-7">
                        {{getFormattedMobileNo(selectedRfxPurchaseOrderDto?.buyerMobileNo)}}
                      </div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">Email</div>
                      <div class=" col-7">
                        {{selectedRfxPurchaseOrderDto?.buyerEmailId}}
                      </div>
                    </div>
                    <!-- <div class="div-class row mb-2">
                      <div class=" col-5">Gst No.</div>
                      <div class=" col-7">17AAGCA9080R1ZW</div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">PAN</div>
                      <div class=" col-7">AAGCA9080R</div>
                    </div> -->


                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="card p-2 bg-white ro-height ">
                    <div class=" row">
                      <div class="col-12 text-center">
                        <h5 class="heading-h5 mt-1">Vendor Details</h5>
                      </div>

                    </div>
                    <hr class="hr my-2" />

                    <div class="mb-2">
                      <b>{{selectedRfxPurchaseOrderDto?.vendorCompanyName}}</b>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">Vendor Code</div>
                      <div class=" col-7">{{selectedRfxPurchaseOrderDto?.vendorCode}}</div>
                    </div>
                    <div class="div-class">
                      {{selectedRfxPurchaseOrderDto?.vendorAddress?.addressLine1}},
                      {{selectedRfxPurchaseOrderDto?.vendorAddress?.addressLine2 || ''}}
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">City</div>
                      <div class=" col-7">
                        {{selectedRfxPurchaseOrderDto?.vendorAddress?.city}}
                      </div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">State</div>
                      <div class=" col-7">
                        {{selectedRfxPurchaseOrderDto?.vendorAddress?.state}}
                      </div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">Pincode</div>
                      <div class=" col-7">
                        {{selectedRfxPurchaseOrderDto?.vendorAddress?.zipCode}}
                      </div>
                    </div>
                    <!-- <div class="div-class row mb-2">
                      <div class=" col-5">Gst No.</div>
                      <div class=" col-7">07AADCI9220N1ZA</div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">PAN</div>
                      <div class=" col-5">AADCI9220N1</div>
                    </div> -->


                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <div class="table-responsive">
                <table class="table table-bordered medium-font mb-0">
                  <thead class="table-dark">
                    <tr class="text-center">
                      <th class="" colspan="6"><span>PO Details</span></th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr class="text-center align-middle">
                      <td class="">
                        <b>PO Number</b>
                      </td>
                      <td class="">
                        {{selectedRfxPurchaseOrderDto?.purchaseOrderNumber}}
                      </td>
                      <td class="">
                        <b>PO Date</b>
                      </td>
                      <td class="">
                        {{selectedRfxPurchaseOrderDto?.purchaseOrderDate}}
                      </td>
                      <td class="">
                        <b>Expected Delivery Date</b>
                      </td>
                      <td class="">
                        {{selectedRfxPurchaseOrderDto?.estimatedDeliveryDate}}
                      </td>
                    </tr>


                  </tbody>
                </table>
              </div>
              <div class="table-responsive mt-3">
                <table class="table table-bordered medium-font mb-0">
                  <thead class="table-dark">
                    <tr class="text-center">
                      <th class=""><span>SNo</span></th>
                      <th class=""><span>Item</span></th>
                      <th class=""><span>UoM</span></th>
                      <th class=""><span>Qty</span></th>
                      <th class=""><span>Tax</span></th>
                      <th class=""><span>Unit Price</span></th>
                      <th class=""><span>Subtotal</span></th>
                      <th class=""><span>Tax Amount</span></th>
                      <th class=""><span>Additional</span></th>
                      <th class=""><span>Total Amount</span></th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr class="text-center align-middle"
                      *ngFor="let item of selectedRfxPurchaseOrderDto?.purchaseOrderItems; index as i">
                      <td class="">{{i+1}}</td>
                      <td class="">{{item.itemName}}</td>
                      <td class="">{{item.uom}}</td>
                      <td class="">{{item.quantity}}</td>
                      <td class="">{{item.tax}}</td>
                      <td class="">
                        {{getFormattedPrice(selectedRfxPurchaseOrderDto?.currency!, item.unitPrice)}}
                      </td>
                      <td class="">
                        {{getFormattedPrice(selectedRfxPurchaseOrderDto?.currency!, item.unitPriceQtyTotal)}}
                      </td>
                      <td class="">
                        {{getFormattedPrice(selectedRfxPurchaseOrderDto?.currency!, (item.unitPriceQtyTaxTotal! -
                        item.unitPriceQtyTotal!))}}
                      </td>
                      <td class="">{{getTotalAdditionalPrices(item)}}</td>
                      <td class="">
                        {{getFormattedPrice(selectedRfxPurchaseOrderDto?.currency!,
                        item.unitPriceQtyTaxAdditionalPricesTotal)}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-end">
                <div class="col-md-6 ">
                  <div class="table-responsive mt-3">
                    <table class="table table-bordered medium-font mb-0">
                      <thead class="table-dark">
                        <tr class="text-center">
                          <th class="" colspan="2">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody class="">

                        <tr class="text-center align-middle bg-highlight font-weight-bold">
                          <td class="">
                            <b>Total</b>
                          </td>
                          <td>
                            <b>{{getTotalBidPrice()}}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-template>